import { 
    REJECTNAME_CREATE_FAIL,
    REJECTNAME_CREATE_REQUEST,
    REJECTNAME_CREATE_RESET,
    REJECTNAME_CREATE_SUCCESS,
    REJECTNAME_DELETE_FAIL,
    REJECTNAME_DELETE_REQUEST,
    REJECTNAME_DELETE_RESET,
    REJECTNAME_DELETE_SUCCESS,
    REJECTNAME_LIST_FAIL,
    REJECTNAME_LIST_REQUEST, 
    REJECTNAME_LIST_RESET, 
    REJECTNAME_LIST_SUCCESS,
    REJECTNAME_UPDATE_FAIL,
    REJECTNAME_UPDATE_REQUEST,
    REJECTNAME_UPDATE_RESET,
    REJECTNAME_UPDATE_SUCCESS,

} from "../constants/rejectNameConstants"





export const rejectNameListReducer = (state={names:[]}, action) => {
    switch(action.type){
        case REJECTNAME_LIST_REQUEST:
            return {loading: true}
        case REJECTNAME_LIST_SUCCESS:
            return {loading: false, names: action.payload}
        case REJECTNAME_LIST_FAIL:
            return {loading: false, error: action.payload}
        case REJECTNAME_LIST_RESET:
            return {}
        default: return state
    }
}

export const rejectNameCreateReducer = (state={}, action) => {
    switch(action.type){
        case REJECTNAME_CREATE_REQUEST:
            return {loading: true}
        case REJECTNAME_CREATE_SUCCESS:
            return {loading: false, success: true}
        case REJECTNAME_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case REJECTNAME_CREATE_RESET:
            return {}
        default: return state
    }
}

export const rejectNameUpdateReducer = (state={}, action) => {
    switch(action.type){
        case REJECTNAME_UPDATE_REQUEST:
            return {loading: true}
        case REJECTNAME_UPDATE_SUCCESS:
            return {loading: false, success: true}
        case REJECTNAME_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case REJECTNAME_UPDATE_RESET:
            return {}
        default: return state
    }
}

export const rejectNameDeleteReducer = (state={}, action) => {
    switch(action.type){
        case REJECTNAME_DELETE_REQUEST:
            return {loading: true}
        case REJECTNAME_DELETE_SUCCESS:
            return {loading: false, success: true}
        case REJECTNAME_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case REJECTNAME_DELETE_RESET:
            return {}
        default: return state
    }
}