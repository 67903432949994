import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Button,
    Center,
    Heading,
    HStack,
    Spacer,
    Spinner,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { getRateList, deleteRate } from '../../../actions/thailand_post_actions/thpRateActions'
import Error from '../../../components/Error'
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal'

const PostRateListScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const types = ['ems', 'air', 'surface']
    const { loading, error, rates } = useSelector(state=>state.thailandPost.RateList)
    const { loading: loadingDelete, error: errorDelete, success: successDelete} = useSelector(state=>state.thailandPost.RateDelete)

    useEffect(()=>{
        dispatch(getRateList())
    },[dispatch, successDelete])


    return (
        <Box>
            <Box my='4'>
                <Center><Heading>Thailand Post Rates {(loading || loadingDelete) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Heading></Center>
                {error && <Error error={error} /> }
                {errorDelete && <Error error={errorDelete} /> }
                <Button onClick={()=>navigate('/thailandpostratecreate')}>Add Rate</Button>
            </Box>
            <Box>
                {rates && rates.length === 0 ? 
                <>
                    <Box bg='red.200' w='100%' p='3' textAlign={'center'} borderRadius={'15'}> No Rate To Show </Box>
                </> :
                <Box>
                { rates && rates.map((r)=>(
                    <Box key={r._id} borderTop='1px' p='3' my='2' maxW={'1200px'} overflow={'scroll'}>
                        <HStack>
                            <Text fontSize={'2xl'} mb='2'>{r.country}</Text>
                            <Spacer />
                            {/* <Button 
                                size='sm'
                                colorScheme={'blue'}
                                onClick={()=>navigate('/thailandpostrateedit', {state: r})}
                            >Edit</Button> */}
                            <ConfirmDeleteModal deleteFunction={deleteRate} id={r._id}  width='70px' lefticon={null} />
                        </HStack>
                        
                        <Table variant='striped' colorScheme='teal' size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>kg</Th>
                                    {[...Array(30).keys()].map(n=>
                                        <Th key={n}>{n+1}</Th>
                                    )}
                                </Tr>

                            </Thead>
                            <Tbody>
                                {types.map((t,i)=>(
                                    <Tr key={i}>
                                        <Td>{t}</Td>
                                        {[...Array(30).keys()].map((a)=>(
                                            // <Td key={a}>{a}</Td>
                                            <Td key={a}>{r.rate.hasOwnProperty(a) ? r.rate[a][t] : 0}</Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                ))}
                </Box>
                }
            </Box>
        </Box>
    )
}

export default PostRateListScreen