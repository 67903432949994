import axios from 'axios'
import {
    RATE_CREATE_REQUEST,
    RATE_CREATE_SUCCESS,
    RATE_CREATE_FAIL,
    RATE_LIST_REQUEST,
    RATE_LIST_SUCCESS,
    RATE_LIST_FAIL,
    RATE_BYID_REQUEST,
    RATE_BYID_SUCCESS,
    RATE_BYID_FAIL,
    RATE_EDIT_REQUEST,
    RATE_EDIT_SUCCESS,
    RATE_EDIT_FAIL,
    RATE_DELETE_REQUEST,
    RATE_DELETE_SUCCESS,
    RATE_DELETE_FAIL,
    RATE_NAMES_REQUEST,
    RATE_NAMES_SUCCESS,
    RATE_NAMES_FAIL,
    RATE_BYAGENT_REQUEST,
    RATE_BYAGENT_SUCCESS,
    RATE_BYAGENT_FAIL,
    RATE_MY_REQUEST,
    RATE_MY_SUCCESS,
    RATE_MY_FAIL,
    RATE_AGENTNAMES_REQUEST,
    RATE_AGENTNAMES_SUCCESS,
    RATE_AGENTNAMES_FAIL,
    RATE_TRANSFER_REQUEST,
    RATE_TRANSFER_SUCCESS,
    RATE_TRANSFER_FAIL,
    RATE_STATUS_REQUEST,
    RATE_STATUS_SUCCESS,
    RATE_STATUS_FAIL,
    RATE_SHIPMENTQTY_REQUEST,
    RATE_SHIPMENTQTY_SUCCESS,
    RATE_SHIPMENTQTY_FAIL,
    RATE_USER_REQUEST,
    RATE_USER_SUCCESS,
    RATE_USER_FAIL,
    RATE_DISCOUNT_REQUEST,
    RATE_DISCOUNT_SUCCESS,
    RATE_DISCOUNT_FAIL,
} from '../constants/rateConstants'


export const createRate = (newRate) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/rates`, newRate, config)
        dispatch({type: RATE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: RATE_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listRate = (active) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/rates?active=${active}`, config)
        dispatch({type: RATE_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: RATE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const namesRate = () => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_NAMES_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/rates/names`, config)
        dispatch({type: RATE_NAMES_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: RATE_NAMES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const getByIdRate = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/rates/${id}`, config)
        dispatch({type: RATE_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: RATE_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const editRate = (id, editedAddress) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_EDIT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/rates/${id}`, editedAddress, config)
        dispatch({type: RATE_EDIT_SUCCESS})
    } catch (error) {
        dispatch({
            type: RATE_EDIT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deleteRate = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/rates/${id}`, config)
        dispatch({type: RATE_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: RATE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getRateByAgent = (agentId) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_BYAGENT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/rates/agent/${agentId}`, config)
        dispatch({type: RATE_BYAGENT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: RATE_BYAGENT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getRateMy = () => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/rates/myrate`, config)
        dispatch({type: RATE_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: RATE_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const getRateAgents = (rateId) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_AGENTNAMES_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/rates/agentnames/${rateId}`, config)
        dispatch({type: RATE_AGENTNAMES_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: RATE_AGENTNAMES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const trasferRate = (rateData) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_TRANSFER_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/rates/transfer`, rateData, config)
        dispatch({type: RATE_TRANSFER_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: RATE_TRANSFER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const changeRateStatus = (id, status) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_STATUS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/rates/status/${id}`, {status}, config)
        dispatch({type: RATE_STATUS_SUCCESS})
    } catch (error) {
        dispatch({
            type: RATE_STATUS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const shipmentQty = (month, active) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_SHIPMENTQTY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/rates/shipment?month=${month}&active=${active}`, config)
        dispatch({type: RATE_SHIPMENTQTY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: RATE_SHIPMENTQTY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const updateUserRate = (userId, rateId) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_USER_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/rates/update`, {userId, rateId}, config)
        dispatch({type: RATE_USER_SUCCESS})
    } catch (error) {
        dispatch({
            type: RATE_USER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const setDiscountRate = (data) => async (dispatch, getState) => {
    try {
        dispatch({type: RATE_DISCOUNT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/rates/discount`, data, config)
        dispatch({type: RATE_DISCOUNT_SUCCESS})
    } catch (error) {
        dispatch({
            type: RATE_DISCOUNT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

