
import {
    DOMESTICINVOICE_SHIPMENTS_REQUEST,
    DOMESTICINVOICE_SHIPMENTS_SUCCESS,
    DOMESTICINVOICE_SHIPMENTS_FAIL,
    DOMESTICINVOICE_SHIPMENTS_RESET,
    DOMESTICINVOICE_LIST_REQUEST,
    DOMESTICINVOICE_LIST_SUCCESS,
    DOMESTICINVOICE_LIST_FAIL,
    DOMESTICINVOICE_LIST_RESET,
    DOMESTICINVOICE_CREATE_REQUEST,
    DOMESTICINVOICE_CREATE_SUCCESS,
    DOMESTICINVOICE_CREATE_FAIL,
    DOMESTICINVOICE_CREATE_RESET,
    DOMESTICINVOICE_BYID_REQUEST,
    DOMESTICINVOICE_BYID_SUCCESS,
    DOMESTICINVOICE_BYID_FAIL,
    DOMESTICINVOICE_BYID_RESET,
    DOMESTICINVOICE_DELETE_REQUEST,
    DOMESTICINVOICE_DELETE_SUCCESS,
    DOMESTICINVOICE_DELETE_FAIL,
    DOMESTICINVOICE_DELETE_RESET,
    DOMESTICINVOICE_ADDSHIPMENTS_REQUEST,
    DOMESTICINVOICE_ADDSHIPMENTS_SUCCESS,
    DOMESTICINVOICE_ADDSHIPMENTS_FAIL,
    DOMESTICINVOICE_ADDSHIPMENTS_RESET,
    DOMESTICINVOICE_REMOVESHIPMENTS_REQUEST,
    DOMESTICINVOICE_REMOVESHIPMENTS_SUCCESS,
    DOMESTICINVOICE_REMOVESHIPMENTS_FAIL,
    DOMESTICINVOICE_REMOVESHIPMENTS_RESET,
    DOMESTICINVOICE_MYLIST_REQUEST,
    DOMESTICINVOICE_MYLIST_SUCCESS,
    DOMESTICINVOICE_MYLIST_FAIL,
    DOMESTICINVOICE_MYLIST_RESET,

} from '../../constants/domestic_constants/domesticInoviceConstants'

export const domesticShipmentsForInvoiceReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICINVOICE_SHIPMENTS_REQUEST:
            return {loading: true}
        case DOMESTICINVOICE_SHIPMENTS_SUCCESS:
            return {loading: false, shipments: action.payload}
        case DOMESTICINVOICE_SHIPMENTS_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICINVOICE_SHIPMENTS_RESET:
            return {success: false}
        default: return state
    }
}


export const domesticInvoiceListReducer = (state={invoices:[]}, action) => {
    switch(action.type){
        case DOMESTICINVOICE_LIST_REQUEST:
            return {loading: true}
        case DOMESTICINVOICE_LIST_SUCCESS:
            return {
                loading: false, 
                invoices: action.payload.invoices,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case DOMESTICINVOICE_LIST_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICINVOICE_LIST_RESET:
            return {}
        default: return state
    }
}

export const domesticInvoiceMyListReducer = (state={invoices:[]}, action) => {
    switch(action.type){
        case DOMESTICINVOICE_MYLIST_REQUEST:
            return {loading: true}
        case DOMESTICINVOICE_MYLIST_SUCCESS:
            return {
                loading: false, 
                invoices: action.payload.invoices,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case DOMESTICINVOICE_MYLIST_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICINVOICE_MYLIST_RESET:
            return {}
        default: return state
    }
}

export const domesticInvoiceCreateReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICINVOICE_CREATE_REQUEST:
            return {loading: true}
        case DOMESTICINVOICE_CREATE_SUCCESS:
            return {loading: false, success: true, invoiceId: action.payload}
        case DOMESTICINVOICE_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICINVOICE_CREATE_RESET:
            return {}
        default: return state
    }
}

export const domesticInvoiceByIdReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICINVOICE_BYID_REQUEST:
            return {loading: true}
        case DOMESTICINVOICE_BYID_SUCCESS:
            return {loading: false, invoice: action.payload}
        case DOMESTICINVOICE_BYID_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICINVOICE_BYID_RESET:
            return {}
        default: return state
    }
}

export const domesticInvoiceDeleteReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICINVOICE_DELETE_REQUEST:
            return {loading: true}
        case DOMESTICINVOICE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case DOMESTICINVOICE_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICINVOICE_DELETE_RESET:
            return {}
        default: return state
    }
}


export const domesticInvoiceAddShipmentReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICINVOICE_ADDSHIPMENTS_REQUEST:
            return {loading: true}
        case DOMESTICINVOICE_ADDSHIPMENTS_SUCCESS:
            return {loading: false, success: true}
        case DOMESTICINVOICE_ADDSHIPMENTS_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICINVOICE_ADDSHIPMENTS_RESET:
            return {}
        default: return state
    }
}

export const domesticInvoiceRemoveShipmentReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICINVOICE_REMOVESHIPMENTS_REQUEST:
            return {loading: true}
        case DOMESTICINVOICE_REMOVESHIPMENTS_SUCCESS:
            return {loading: false, success: true}
        case DOMESTICINVOICE_REMOVESHIPMENTS_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICINVOICE_REMOVESHIPMENTS_RESET:
            return {}
        default: return state
    }
}