import React, {useState} from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
    Text,
    Spacer,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Box,
    Textarea,
  } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'

const ConfirmDeleteModal = ({width, deleteFunction, id, variant, size, lefticon, disable}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [show, setShow] = useState(false)
    const [note, setNote] = useState('')

    const handleClose = () => {
        setShow(false)
    }

    const handleConfirm = () => {        
        if(location.pathname.split('/')[1] === 'shipment'){            
            const shipmentNote = {
                note
            }
            //console.log(id, shipmentNote)
            dispatch(deleteFunction(id, shipmentNote))
        } else {
            dispatch(deleteFunction(id))
        }
        setShow(false)
    }

    return (
        <div>
            <Button onClick={()=>setShow(true)} disabled={disable} variant={variant} colorScheme='red' w={width} size={size} leftIcon={lefticon}>
                Delete
            </Button>

            <Modal isOpen={show} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Confirm Delete</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                
                {location.pathname.split('/')[1] === 'shipment' ?
                
                    <Box my='3' >
                        <Box my='3'>Shipment delete note.</Box>
                        <Textarea 
                            h='100px'
                            value={note}
                            onChange={e=>setNote(e.target.value)}
                        />
                    </Box>
                    :
                    <Text>Click yes to delete.</Text>
                }
                </ModalBody>

                <ModalFooter>
                <Button size='sm' w='100px' colorScheme='blue' onClick={handleClose}>
                    Cancel
                </Button>
                <Spacer />
                <Button size='sm' w='100px' colorScheme='red' onClick={handleConfirm}>Yes</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>

        </div>
    )
}

ConfirmDeleteModal.defaultProps = {
    width: '200px',
    variant: 'solid',
    size: 'sm',
    lefticon: <MdClose />,
    disable: false,
}

export default ConfirmDeleteModal