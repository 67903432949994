export const DHLACCOUNT_LIST_REQUEST = 'DHLACCOUNT_LIST_REQUEST'
export const DHLACCOUNT_LIST_SUCCESS = 'DHLACCOUNT_LIST_SUCCESS'
export const DHLACCOUNT_LIST_FAIL = 'DHLACCOUNT_LIST_FAIL'
export const DHLACCOUNT_LIST_RESET = 'DHLACCOUNT_LIST_RESET'

export const DHLACCOUNT_CREATE_REQUEST = 'DHLACCOUNT_CREATE_REQUEST'
export const DHLACCOUNT_CREATE_SUCCESS = 'DHLACCOUNT_CREATE_SUCCESS'
export const DHLACCOUNT_CREATE_FAIL = 'DHLACCOUNT_CREATE_FAIL'
export const DHLACCOUNT_CREATE_RESET = 'DHLACCOUNT_CREATE_RESET'

export const DHLACCOUNT_UPDATE_REQUEST = 'DHLACCOUNT_UPDATE_REQUEST'
export const DHLACCOUNT_UPDATE_SUCCESS = 'DHLACCOUNT_UPDATE_SUCCESS'
export const DHLACCOUNT_UPDATE_FAIL = 'DHLACCOUNT_UPDATE_FAIL'
export const DHLACCOUNT_UPDATE_RESET = 'DHLACCOUNT_UPDATE_RESET'

export const DHLACCOUNT_DELETE_REQUEST = 'DHLACCOUNT_DELETE_REQUEST'
export const DHLACCOUNT_DELETE_SUCCESS = 'DHLACCOUNT_DELETE_SUCCESS'
export const DHLACCOUNT_DELETE_FAIL = 'DHLACCOUNT_DELETE_FAIL'
export const DHLACCOUNT_DELETE_RESET = 'DHLACCOUNT_DELETE_RESET'