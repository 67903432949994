import React, {useRef} from 'react'
import ReactToPrint from 'react-to-print'
import Barcode from 'react-barcode'
import {
    Box,
    HStack,
    Spacer,
    Text,
    Button,
} from '@chakra-ui/react'
import { MdLocalPrintshop } from 'react-icons/md'

const Label = ({preshipment}) => {
    const componentRef = useRef()
    const pageStyle = `
    @page {
        size: 3.25in 3.9in;
        margin: 1mm 2mm;
    }
    `;

    return (
        <>
        {preshipment && 
            <Box>
            <HStack mt='2'>
              <Box></Box>
              <Spacer />
              <Box>
                <ReactToPrint
                  trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                  content={() => componentRef.current}
                  pageStyle={()=>pageStyle}
                />
              </Box>
            </HStack>
            <Box w='320px' mx='auto' p={'2'}>
              <Box ref={componentRef} >
                {preshipment.boxes.map((b,i)=>(
                  <Box key={i} w={'302px'} h={'365px'} border={'1px'} borderColor={'gray.500'} borderRadius={5} mb={1}>
                    <Text fontSize='2rem' pt='5px' lineHeight={'23px'}>{preshipment.code}</Text>
                    <Box ps={2}>
                      <Barcode value={preshipment.code} margin={0} height={20} width={2} displayValue={false} />
                    </Box>
                    <Box px='2' lineHeight={'20px'} fontWeight={600} className='page-break'>
                      <Text>{preshipment.agentId.sale.addressId.name}</Text>
                      <Text>A - {preshipment.agentId.name} - {preshipment.agentId.agentCode}</Text>
                      <Text>S - {preshipment.agentId.sale.name} - {preshipment.agentId.sale.saleCode}</Text>

                      <Box w='100%' borderBottom={'1px'}></Box>

                      <Box>
                        <Text>{preshipment.addressId.name}</Text>
                        <Text>{preshipment.addressId.address}</Text>
                        {preshipment.addressId.address2 && <Text>{preshipment.addressId.address2}</Text>}
                        {preshipment.addressId.address3 && <Text>{preshipment.addressId.address3}</Text>}
                        <Text>{preshipment.addressId.city} {preshipment.addressId.country} {preshipment.addressId.postalcode}</Text>
                        <Text>{preshipment.addressId.phone}</Text>
                      </Box>

                      <HStack w='100%' borderY={'1px'}>
                        <Text>{preshipment.createdAt.substring(0,10)}</Text>
                        <Spacer />
                        <Text>{preshipment.shipType && preshipment.shipType.toUpperCase()}</Text>
                      </HStack>

                      <HStack>
                        <Text>{i+1} of {preshipment.boxes.length}</Text>
                        <Spacer />
                        <Text>{b.dimensions.length}x{b.dimensions.width}x{b.dimensions.height}</Text>
                        <Spacer />
                        <Text>{b.weight} kg</Text>                                            
                      </HStack>

                      <Box w='100%' borderBottom={'1px'}></Box>
                      <HStack fontWeight={'900'}>
                        {preshipment.charges && preshipment.charges.map((c,i)=>(
                          <Box key={i}>{c.name}, </Box>
                        ))}
                      </HStack>
                      <Box w='100%' borderBottom={'1px'}></Box>
                      <Text fontWeight='800' fontSize='sm'>{preshipment.note}</Text>
                      <Text color='red' fontWeight={'800'} as='samp' >Warning !!! estimated data !!</Text>
                    </Box>
                  </Box>  
                ))}
              </Box> 
            </Box>              
          </Box>
        }
        </>
    )
}

export default Label

// <Box key={i} >
//   <Heading size='4xl' pt='10px'>{preshipment.code}</Heading>
//   <Box>
//     <Barcode value={preshipment.code} height={30} width={3} />
//   </Box>
//     <Box px='2' >
//     <Text fontSize='2xl' fontWeight='600'>A - {preshipment.agentId.name} - {preshipment.agentId.agentCode}</Text>
//     <Text fontSize='2xl' fontWeight='600'>S - {preshipment.agentId.sale.name} - {preshipment.agentId.sale.saleCode}</Text>
//     <Box w='100%' borderBottom={'1px'}></Box>
//     <Box fontWeight='900' style={{'letterSpacing': '2px'}}>
//       <Text>{preshipment.addressId.name}</Text>
//       <Text>{preshipment.addressId.address}</Text>
//       {preshipment.addressId.address2 && <Text>{preshipment.addressId.address2}</Text>}
//       {preshipment.addressId.address3 && <Text>{preshipment.addressId.address3}</Text>}
//       <Text>{preshipment.addressId.city} {preshipment.addressId.country} {preshipment.addressId.postalcode}</Text>
//       <Text>{preshipment.addressId.phone}</Text>
//     </Box>
//     <HStack w='100%' borderY={'1px'}>
//       <Text fontSize='2xl'>{preshipment.createdAt.substring(0,10)}</Text>
//       <Spacer />
//       <Text fontSize='2xl'>{preshipment.shipType && preshipment.shipType.toUpperCase()}</Text>
//     </HStack>
//     <HStack>
//       <Box>
//         <Text>{preshipment.productCode === 'D' ? 'Document' : 'Box'}</Text>
//         <Text fontSize='2xl'>{i+1} of {preshipment.boxes.length}</Text>
//       </Box>
//       <Spacer />
//       <Box>
//         <Text>Size</Text>
//         <Text fontSize='2xl'>{b.dimensions.length}x{b.dimensions.width}x{b.dimensions.height}</Text>
//       </Box>
//       <Spacer />
//       <Box>
//         <Text>Weight</Text>
//         <Text fontSize='2xl'>{b.weight} kg</Text>
//       </Box>                        
//     </HStack>
//     <Box w='100%' borderBottom={'1px'}></Box>
//     <HStack fontWeight={'900'}>
//       {preshipment.charges && preshipment.charges.map((c,i)=>(
//         <Box key={i}>{c.name}, </Box>
//       ))}
//     </HStack>
//     <Box mb='30px' w='100%' borderBottom={'1px'}></Box>
//       <Text fontWeight='800'>{preshipment.note}</Text>
//       <Text color='red' fontWeight={'800'} as='samp' >Warning!!! Data on this label are estimated</Text>
//     </Box>
// </Box> 