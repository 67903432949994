import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    Center,
    Button,
    Select,
    Stack,
    Box,
    Heading,
    Input,
    Spinner,
    HStack,
  } from '@chakra-ui/react'
  import { listAgent } from '../actions/userActions'
  import { getCostShipment, editAgentShipment } from '../actions/shipmentActions'
  import Error from '../components/Error'
import { SHIPMENT_CHANGEAGENT_RESET } from '../constants/shipmentConstants'

const ShipmentChangeAgentModal = ({shipmentId, disable}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [warning, setWarning] = useState(false)
    const [agentId, setAgentId] = useState('')
    const [agentSearchKeyword, setAgentSearchKeyword] = useState('') 

    const {shipment} = useSelector(state=>state.shipment.ById)
    const {loading, error, agents} = useSelector(state=>state.user.AgentList)
    const {loading: costLoading, error: costError, cost} = useSelector(state=>state.shipment.GetCost)
    const {loading: saveLoading, error: saveError, success} = useSelector(state=>state.shipment.ChangeAgent)
    

    useEffect(()=>{
        if(success){
            setAgentId('')
            setAgentSearchKeyword('')
            setWarning(false)
            setShow(false)
        }
    },[success])
    
    useEffect(()=>{
        if(show){
            dispatch(listAgent(1, '1000', '', ''))
        }   
        // return()=>{
        //     dispatch({type: SHIPMENT_CHANGEAGENT_RESET})
        // }      
    },[show, dispatch ])

    const handleSearchAgent = (e) => {
        if(e.key === 'Enter'){
            setAgentId('')
            dispatch(listAgent(1, '1000', agentSearchKeyword, ''))
        }
        return
    }

    const onClose = () => {
        setAgentId('')
        setAgentSearchKeyword('')
        setWarning(false)        
        setShow(false)
        dispatch({type: SHIPMENT_CHANGEAGENT_RESET})
    }

    const handleSubmit = () => {
        if(agentId === ''){
            setWarning(true)            
            return
        }
        setWarning(false)
        const newAgent = {
            shipmentId,
            agentId,
            cost
        }
        //console.log(newAgent)
        dispatch(editAgentShipment(newAgent))
    }

    return (
      <>
        <Button size='xs' disabled={disable} colorScheme={'orange'} onClick={()=>setShow(true) } >Change</Button>
  
        <Modal closeOnOverlayClick={false} size='xl' isOpen={show} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody py={'7'}>
                        
            {error && <Error error={error} />}
            {costError && <Error error={costError} />}
            {saveError && <Error error={saveError} />}
                <Stack spacing='5'>
                    <HStack>
                    <Center><Heading>Change Agent</Heading></Center>
                    {(loading || costLoading || saveLoading) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                    </HStack>
                        
                        <Stack spacing={'0'}>
                            <Box fontSize={'14px'}>Search Agent</Box>
                            <Input 
                                type='text'
                                size='sm'
                                placeholder='Search Agent'
                                value={agentSearchKeyword}
                                onChange={e=> setAgentSearchKeyword(e.target.value)}
                                onKeyPress={handleSearchAgent}
                            />
                        </Stack>

                        <Stack spacing={'0'}>
                            <Box fontSize='14px'>Agent List</Box>
                            <Select isInvalid={warning} size='sm' placeholder='Agent List' onChange={e=>{
                                setAgentId(e.target.value)
                                dispatch(getCostShipment({
                                    agentId: e.target.value,
                                    totalWeight: shipment.totalWeight,
                                    productCode: shipment.productCode,
                                    countryCode: shipment.customerDetails.receiverDetails.postalAddress.countryCode
                                }))                                
                                }}>
                                {agents && agents.map((ag, idx)=>(
                                    <option key={idx} value={ag._id}>{ag.name} - {ag.agentCode}</option>
                                ))}
                            </Select>
                        </Stack>
                        <Box bg='yellow' p='2' fontWeight={'500'}>Cost for selected Agent - {agentId === '' ? '0' : cost}</Box>
                        <Button 
                            size='sm'
                            width='100%'
                            colorScheme={'green'}
                            onClick={handleSubmit}
                        >Save</Button>
                    
                </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
}

ShipmentChangeAgentModal.defaultProps = {
    disable: false,
}

export default ShipmentChangeAgentModal