import React, {useEffect} from 'react'
import { Box, Center, Heading, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import Error from '../components/Error'
import {useDispatch, useSelector} from 'react-redux'
import { getZoneList, deleteZone } from '../actions/zoneActions'
import ZoneCreateModal from '../Modals/ZoneCreateModal'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'


const ZoneListScreen = () => {
    const dispatch = useDispatch()

    const { loading, success, zones, error } = useSelector(state=>state.zone.List)
    const {success: successAdd} = useSelector(state=>state.zone.Create)
    const {success: successUpdate, error: errorUpdate} = useSelector(state=>state.zone.Update)
    const {success: successDelete, error: errorDelete} = useSelector(state=>state.zone.Delete)

    useEffect(()=>{
        dispatch(getZoneList())
    },[dispatch, successAdd, successUpdate, successDelete])

    return (
        <Center my='4'>
            <Box boxShadow={'lg'} w='700px' p='3'>
                {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                {error && <Error error={error} /> }
                {errorUpdate && <Error error={errorUpdate} /> }
                {errorDelete && <Error error={errorDelete} /> }

                <ZoneCreateModal />
                
                <Center><Heading>Zone List</Heading></Center>
                <Box mt={4}>
                  <Table variant='striped' size='sm' colorScheme='twitter' my='4'>
                      <Thead>
                          <Tr>
                          <Th>No</Th>
                          <Th>Name</Th>
                          <Th>Zone Number</Th>
                          <Th>Delete</Th>
                          </Tr>
                      </Thead>
                      <Tbody>
                          {success && zones.map((z, idx)=>(
                          <Tr key={idx}>
                              <Td>{idx+1}</Td>
                              <Td>{z.name}</Td>
                              <Td>{z.zoneNumber}</Td>
                              <Td><ConfirmDeleteModal width={'60px'} lefticon={null} id={z._id} deleteFunction={deleteZone} /></Td>
                          </Tr>
                          ))}
                      </Tbody>
                  </Table>
                </Box>              
                
            </Box>
        </Center>
    )
}

export default ZoneListScreen