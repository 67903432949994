import { 
    useBreakpointValue,
    useDisclosure, 
    Button, 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalCloseButton, 
    ModalBody,
    SimpleGrid,
    GridItem,
    Table,
    Thead,
    Tbody,
    Th,
    Td,
    Tr,
    Box,
    Center,
    CircularProgress,
 } from '@chakra-ui/react'
 import useWindowSize from '../components/WindowSize'

const AdminDashboardDataViewModal = ({dashboardData}) => {
    const colSpan = useBreakpointValue({base: 4, md: 1})
    const size = useWindowSize()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const getPrevMonths = (num) => {
        const current = new Date()
        current.setMonth(current.getMonth()-num);
        const month = current.toLocaleString('default', { month: 'long' });
        return month
    }
    return (
        <div>
            <Button onClick={onOpen} size='xs' variant={'ghost'}>View</Button>

            <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Month-{dashboardData.month}, ID-{dashboardData._id}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SimpleGrid columns={4} columnGap={3} rowGap={3} my='3'>

                            <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Table size='xs' colorScheme={'gray.500'}  >
                                <Thead>
                                    <Tr>
                                    <Th>Total</Th>
                                    <Th isNumeric>Qty</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                    <Td>Pre-Ships</Td>
                                    <Td isNumeric>{dashboardData.monthPreShips}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>Shipments</Td>
                                    <Td isNumeric>{dashboardData.monthShipments}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>Invoices</Td>
                                    <Td isNumeric>{dashboardData.monthInvoices}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>Payments</Td>
                                    <Td isNumeric>{dashboardData.monthPayments}</Td>
                                    </Tr>
                                </Tbody>
                                </Table>
                            </Box>
                            </GridItem>

                            <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Table size='xs' colorScheme={'gray.500'}  >
                                <Thead>
                                    <Tr>
                                    <Th>Shipments</Th>
                                    <Th isNumeric>Qty</Th>
                                    <Th isNumeric>Value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                    <Td>Total</Td>
                                    <Td isNumeric>{dashboardData.monthShipments}</Td>
                                    <Td isNumeric>{dashboardData.shipments.totalValue.toLocaleString()}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>Invoiced</Td>
                                    <Td isNumeric>{dashboardData.shipments.totalInvoicedQty}</Td>
                                    <Td isNumeric>{dashboardData.shipments.totalInvoicedValue.toLocaleString()}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>Uninvoiced</Td>
                                    <Td isNumeric>{dashboardData.monthShipments -dashboardData.shipments.totalInvoicedQty}</Td>
                                    <Td isNumeric>{(dashboardData.shipments.totalValue - dashboardData.shipments.totalInvoicedValue).toLocaleString()}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>Paid</Td>
                                    <Td isNumeric>{dashboardData.shipments.totalPaidQty}</Td>
                                    <Td isNumeric>{dashboardData.shipments.totalPaidValue.toLocaleString()}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>Unpaid</Td>
                                    <Td isNumeric>{dashboardData.monthShipments -dashboardData.shipments.totalPaidQty}</Td>
                                    <Td isNumeric>{(dashboardData.shipments.totalValue - dashboardData.shipments.totalPaidValue).toLocaleString()}</Td>
                                    </Tr>
                                </Tbody>
                                </Table>
                            </Box>
                            </GridItem>

                            <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Table size='xs' colorScheme={'gray.500'}  >
                                <Thead>
                                    <Tr>
                                    <Th>Invoices</Th>
                                    <Th isNumeric>Qty</Th>
                                    <Th isNumeric>Value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                    <Td>Total</Td>
                                    <Td isNumeric>{dashboardData.monthInvoices}</Td>
                                    <Td isNumeric>{dashboardData.invoices.totalValue.toLocaleString()}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>Paid</Td>
                                    <Td isNumeric>{dashboardData.invoices.totalPaidInvoiceQty}</Td>
                                    <Td isNumeric>{dashboardData.invoices.totalPaidInvoiceValue.toLocaleString()}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>Unpaid</Td>
                                    <Td isNumeric>{dashboardData.monthInvoices-dashboardData.invoices.totalPaidInvoiceQty}</Td>
                                    <Td isNumeric>{(dashboardData.invoices.totalValue-dashboardData.invoices.totalPaidInvoiceValue).toLocaleString()}</Td>
                                    </Tr>
                                </Tbody>
                                </Table>
                            </Box>
                            </GridItem>

                            <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Table size='xs' colorScheme={'gray.500'}  >
                                <Thead>
                                    <Tr>
                                    <Th>Payments</Th>
                                    <Th isNumeric>Qty</Th>
                                    <Th isNumeric>Value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                    <Td>Total</Td>
                                    <Td isNumeric>{dashboardData.monthPayments}</Td>
                                    <Td isNumeric>{dashboardData.payments.totalValue.toLocaleString()}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>Approved</Td>
                                    <Td isNumeric>{dashboardData.payments.totalPaidPaymentQty}</Td>
                                    <Td isNumeric>{dashboardData.payments.totalPaidPaymentValue.toLocaleString()}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>Unapproved</Td>
                                    <Td isNumeric>{dashboardData.monthPayments-dashboardData.payments.totalPaidPaymentQty}</Td>
                                    <Td isNumeric>{(dashboardData.payments.totalValue-dashboardData.payments.totalPaidPaymentValue).toLocaleString()}</Td>
                                    </Tr>
                                </Tbody>
                                </Table>
                            </Box>
                            </GridItem>

                            <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Table size='xs' colorScheme={'gray.500'}  >
                                <Thead>
                                    <Tr>
                                    <Th>V Plus</Th>
                                    <Th isNumeric>Qty</Th>
                                    <Th isNumeric>Value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                    <Td>{getPrevMonths(0)}</Td>
                                    <Td isNumeric>{dashboardData.vplus.thisMonthVPlusCount}</Td>
                                    <Td isNumeric>{dashboardData.vplus.thisMonthVPlusValue.toLocaleString()}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>{getPrevMonths(1)}</Td>
                                    <Td isNumeric>{dashboardData.vplus.lastMonthVPlusCount}</Td>
                                    <Td isNumeric>{dashboardData.vplus.lastMonthVPlusValue.toLocaleString()}</Td>
                                    </Tr>
                                    <Tr>
                                    <Td>{getPrevMonths(2)}</Td>
                                    <Td isNumeric>{dashboardData.vplus.prevMonthVPlusCount}</Td>
                                    <Td isNumeric>{dashboardData.vplus.prevMonthVPlusValue.toLocaleString()}</Td>
                                    </Tr>
                                </Tbody>
                                </Table>
                            </Box>
                            </GridItem>


                            <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Table size='xs' colorScheme={'gray.500'}  >
                                <Thead>
                                    <Tr>
                                    <Th>Top Agents</Th>
                                    <Th isNumeric>Shipments</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {dashboardData.topAgents.map((a, i)=>(
                                    <Tr key={i}>
                                        <Td>{a.agent.name}</Td>
                                        <Td isNumeric>{a.count}</Td>
                                    </Tr>
                                    ))}
                                </Tbody>
                                </Table>
                            </Box>
                            </GridItem>

                            <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Table size='xs' colorScheme={'gray.500'}  >
                                <Thead>
                                    <Tr>
                                    <Th>Top Sales</Th>
                                    <Th isNumeric>Shipments</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {dashboardData.topSales.map((s, i)=>(
                                    <Tr key={i}>
                                        <Td>{s.sale.name}</Td>
                                        <Td isNumeric>{s.count}</Td>
                                    </Tr>
                                    ))}
                                </Tbody>
                                </Table>
                            </Box>
                            </GridItem>

                            <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h={size.width > 760 ? '200px' : 'full'} overflow={'auto'}>
                                <Table size='xs' colorScheme={'gray.500'}  >
                                <Thead>
                                    <Tr>
                                    <Th>Agent Balance</Th>
                                    <Th>%</Th>
                                    <Th isNumeric>Value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {dashboardData.agentBillingDetail.map((a,i)=>(
                                    <Tr key={i}>
                                        <Td>{a.agent.name}</Td>
                                        <Td>
                                        <Center>
                                            <CircularProgress value={a.percentage} size='22px' thickness='15px' color='red.500' />                        
                                        </Center>
                                        </Td>
                                        <Td isNumeric>{a.totalUnPaid.toLocaleString()}</Td>
                                    </Tr>
                                    ))}
                                </Tbody>
                                </Table>
                            </Box>
                            </GridItem>

                            <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h={size.width > 760 ? '200px' : 'full'} overflow={'auto'}>
                                <Table size='xs' colorScheme={'gray.500'}  >
                                <Thead>
                                    <Tr>
                                    <Th>Sale Balance</Th>
                                    <Th>%</Th>
                                    <Th isNumeric>Value</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {dashboardData.saleBillingDetail.map((s,i)=>(
                                    <Tr key={i}>
                                        <Td>{s.sale.name}</Td>
                                        <Td>
                                        <Center>
                                            <CircularProgress value={s.percentage} size='22px' thickness='15px' color='red.500' />                        
                                        </Center>
                                        </Td>
                                        <Td isNumeric>{s.totalUnPaid.toLocaleString()}</Td>
                                    </Tr>
                                    ))}
                                </Tbody>
                                </Table>
                            </Box>
                            </GridItem>

                        </SimpleGrid>

                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
  )
}

export default AdminDashboardDataViewModal