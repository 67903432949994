import React, {useEffect} from 'react'
import { Box, Center, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import Error from '../components/Error'
import {useDispatch, useSelector} from 'react-redux'
import { getChargesList, deleteCharges } from '../actions/chargesActions'
import ChargesCreateModal from '../Modals/ChargesCreateModal'
import ChargesEditModal from '../Modals/ChargesEditModal'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'


const ChargesListScreen = () => {
    const dispatch = useDispatch()

    const { loading, charges, error } = useSelector(state=>state.charges.List)
    const {success: successAdd} = useSelector(state=>state.charges.Create)
    const {success: successUpdate, error: errorUpdate} = useSelector(state=>state.charges.Update)
    const {success: successDelete, error: errorDelete} = useSelector(state=>state.charges.Delete)

    useEffect(()=>{
        dispatch(getChargesList())
    },[dispatch, successAdd, successUpdate, successDelete])

    return (
        <Center my='4'>
            <Box boxShadow={'lg'} w='700px' p='3'>
                {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                {error && <Error error={error} /> }
                {errorUpdate && <Error error={errorUpdate} /> }
                {errorDelete && <Error error={errorDelete} /> }

                <ChargesCreateModal />
                

                <Box>
                  <Table variant='striped' size='sm' colorScheme='teal' my='4'>
                      <Thead>
                          <Tr>
                          <Th>No</Th>
                          <Th>Name</Th>
                          <Th>Edit</Th>
                          <Th>Delete</Th>
                          </Tr>
                      </Thead>
                      <Tbody>
                          {charges && charges.map((ch, idx)=>(
                          <Tr key={idx}>
                              <Td>{idx+1}</Td>
                              <Td>{ch.name}</Td>
                              <Td><ChargesEditModal charges={ch} /></Td>
                              <Td><ConfirmDeleteModal width={'60px'} lefticon={null} id={ch._id} deleteFunction={deleteCharges} /></Td>
                          </Tr>
                          ))}
                      </Tbody>
                  </Table>
                </Box>              
                
            </Box>
        </Center>
    )
}

export default ChargesListScreen