import {
    PRESHIPMENT_CREATE_REQUEST,
    PRESHIPMENT_CREATE_SUCCESS,
    PRESHIPMENT_CREATE_FAIL,
    PRESHIPMENT_CREATE_RESET,
    PRESHIPMENT_LIST_REQUEST,
    PRESHIPMENT_LIST_SUCCESS,
    PRESHIPMENT_LIST_FAIL,
    PRESHIPMENT_LIST_RESET,
    PRESHIPMENT_CANCEL_REQUEST,
    PRESHIPMENT_CANCEL_SUCCESS,
    PRESHIPMENT_CANCEL_FAIL,
    PRESHIPMENT_CANCEL_RESET,
    PRESHIPMENT_BYID_REQUEST,
    PRESHIPMENT_BYID_SUCCESS,
    PRESHIPMENT_BYID_FAIL,
    PRESHIPMENT_BYID_RESET,
    PRESHIPMENT_ADD_REMOVE_ITEM_REQUEST,
    PRESHIPMENT_ADD_REMOVE_ITEM_SUCCESS,
    PRESHIPMENT_ADD_REMOVE_ITEM_FAIL,
    PRESHIPMENT_ADD_REMOVE_ITEM_RESET,
    PRESHIPMENT_SAVE_DHL_SHIPPER_REQUEST,
    PRESHIPMENT_SAVE_DHL_SHIPPER_SUCCESS,
    PRESHIPMENT_SAVE_DHL_SHIPPER_FAIL,
    PRESHIPMENT_SAVE_DHL_SHIPPER_RESET,
    PRESHIPMENT_ITEM_QTY_REQUEST,
    PRESHIPMENT_ITEM_QTY_SUCCESS,
    PRESHIPMENT_ITEM_QTY_FAIL,
    PRESHIPMENT_ITEM_QTY_RESET,
    PRESHIPMENT_BOXSIZE_REQUEST,
    PRESHIPMENT_BOXSIZE_SUCCESS,
    PRESHIPMENT_BOXSIZE_FAIL,
    PRESHIPMENT_BOXSIZE_RESET,
    SHIPMENT_CREATE_REQUEST,
    SHIPMENT_CREATE_SUCCESS,
    SHIPMENT_CREATE_FAIL,
    SHIPMENT_CREATE_RESET,
    SHIPMENT_GET_COST_REQUEST,
    SHIPMENT_GET_COST_SUCCESS,
    SHIPMENT_GET_COST_FAIL,
    SHIPMENT_GET_COST_RESET,
    SHIPMENT_LIST_REQUEST,
    SHIPMENT_LIST_SUCCESS,
    SHIPMENT_LIST_FAIL,
    SHIPMENT_LIST_RESET,
    SHIPMENT_BYID_REQUEST,
    SHIPMENT_BYID_SUCCESS,
    SHIPMENT_BYID_FAIL,
    SHIPMENT_BYID_RESET,
    PRESHIPMENT_BOXEDIT_REQUEST,
    PRESHIPMENT_BOXEDIT_SUCCESS,
    PRESHIPMENT_BOXEDIT_FAIL,
    PRESHIPMENT_BOXEDIT_RESET,
    SHIPMENT_CANCEL_REQUEST,
    SHIPMENT_CANCEL_SUCCESS,
    SHIPMENT_CANCEL_FAIL,
    SHIPMENT_CANCEL_RESET,
    SHIPMENT_GETCONTACTFOR_PRESHIP_REQUEST,
    SHIPMENT_GETCONTACTFOR_PRESHIP_SUCCESS,
    SHIPMENT_GETCONTACTFOR_PRESHIP_FAIL,
    SHIPMENT_GETCONTACTFOR_PRESHIP_RESET,
    PRESHIPMENT_SAVECONTACT_REQUEST,
    PRESHIPMENT_SAVECONTACT_SUCCESS,
    PRESHIPMENT_SAVECONTACT_FAIL,
    PRESHIPMENT_SAVECONTACT_RESET,
    SHIPMENT_ADDNOTE_REQUEST,
    SHIPMENT_ADDNOTE_SUCCESS,
    SHIPMENT_ADDNOTE_FAIL,
    SHIPMENT_ADDNOTE_RESET,
    SHIPMENT_DELETENOTE_REQUEST,
    SHIPMENT_DELETENOTE_SUCCESS,
    SHIPMENT_DELETENOTE_FAIL,
    SHIPMENT_DELETENOTE_RESET,
    SHIPMENT_FORINVOICE_REQUEST,
    SHIPMENT_FORINVOICE_SUCCESS,
    SHIPMENT_FORINVOICE_FAIL,
    SHIPMENT_FORINVOICE_RESET,
    SHIPMENT_TRACKING_REQUEST,
    SHIPMENT_TRACKING_SUCCESS,
    SHIPMENT_TRACKING_FAIL,
    SHIPMENT_TRACKING_RESET,
    SHIPMENT_MY_REQUEST,
    SHIPMENT_MY_SUCCESS,
    SHIPMENT_MY_FAIL,
    SHIPMENT_MY_RESET,
    SHIPMENT_RETURNED_REQUEST,
    SHIPMENT_RETURNED_SUCCESS,
    SHIPMENT_RETURNED_FAIL,
    SHIPMENT_RETURNED_RESET,
    PRESHIPMENT_AGENT_RECEIVER_UPDATE_REQUEST,
    PRESHIPMENT_AGENT_RECEIVER_UPDATE_SUCCESS,
    PRESHIPMENT_AGENT_RECEIVER_UPDATE_FAIL,
    PRESHIPMENT_AGENT_RECEIVER_UPDATE_RESET,
    SHIPMENT_SCANNED_REQUEST,
    SHIPMENT_SCANNED_SUCCESS,
    SHIPMENT_SCANNED_FAIL,
    SHIPMENT_SCANNED_RESET,
    SHIPMENT_GET_SCANNED_REQUEST,
    SHIPMENT_GET_SCANNED_SUCCESS,
    SHIPMENT_GET_SCANNED_FAIL,
    SHIPMENT_GET_SCANNED_RESET,
    PRESHIPMENT_NOTE_UPDATE_REQUEST,
    PRESHIPMENT_NOTE_UPDATE_SUCCESS,
    PRESHIPMENT_NOTE_UPDATE_FAIL,
    PRESHIPMENT_NOTE_UPDATE_RESET,
    PRESHIPMENT_SAVESHIPPER_REQUEST,
    PRESHIPMENT_SAVESHIPPER_SUCCESS,
    PRESHIPMENT_SAVESHIPPER_FAIL,
    PRESHIPMENT_SAVESHIPPER_RESET,
    SHIPMENT_ADDCHARGE_REQUEST,
    SHIPMENT_ADDCHARGE_SUCCESS,
    SHIPMENT_ADDCHARGE_FAIL,
    SHIPMENT_ADDCHARGE_RESET,
    SHIPMENT_SALEBILLING_REQUEST,
    SHIPMENT_SALEBILLING_SUCCESS,
    SHIPMENT_SALEBILLING_FAIL,
    SHIPMENT_USERSHIPCOUNT_REQUEST,
    SHIPMENT_USERSHIPCOUNT_SUCCESS,
    SHIPMENT_USERSHIPCOUNT_FAIL,
    SHIPMENT_DELETED_LIST_REQUEST,
    SHIPMENT_DELETED_LIST_SUCCESS,
    SHIPMENT_DELETED_LIST_FAIL,
    SHIPMENT_DELETED_LIST_RESET,
    SHIPMENT_SHIPANDSCAN_REQUEST,
    SHIPMENT_SHIPANDSCAN_SUCCESS,
    SHIPMENT_SHIPANDSCAN_FAIL,
    SHIPMENT_DAILYREPORT_REQUEST,
    SHIPMENT_DAILYREPORT_SUCCESS,
    SHIPMENT_DAILYREPORT_FAIL,
    SHIPMENT_CHANGEAGENT_REQUEST,
    SHIPMENT_CHANGEAGENT_SUCCESS,
    SHIPMENT_CHANGEAGENT_FAIL,
    SHIPMENT_CHANGEAGENT_RESET,
    SHIPMENT_STATUS_REQUEST,
    SHIPMENT_STATUS_SUCCESS,
    SHIPMENT_STATUS_FAIL,
    SHIPMENT_STATUS_RESET,
    PRESHIPMENT_CURRENCY_UPDATE_REQUEST,
    PRESHIPMENT_CURRENCY_UPDATE_SUCCESS,
    PRESHIPMENT_CURRENCY_UPDATE_FAIL,
    PRESHIPMENT_CURRENCY_UPDATE_RESET,
    SHIPMENT_STATUS_SALE_REQUEST,
    SHIPMENT_STATUS_SALE_SUCCESS,
    SHIPMENT_STATUS_SALE_FAIL,
    SHIPMENT_STATUS_SALE_RESET,
    SHIPMENT_STATUS_EACH_REQUEST,
    SHIPMENT_STATUS_EACH_SUCCESS,
    SHIPMENT_STATUS_EACH_FAIL,
    SHIPMENT_STATUS_EACH_RESET,
    SHIPMENT_STATUS_MY_REQUEST,
    SHIPMENT_STATUS_MY_SUCCESS,
    SHIPMENT_STATUS_MY_FAIL,
    SHIPMENT_STATUS_MY_RESET,
    SHIPMENT_AGENTBILLING_REQUEST,
    SHIPMENT_AGENTBILLING_SUCCESS,
    SHIPMENT_AGENTBILLING_FAIL,
    SHIPMENT_MYAGENTBILLING_REQUEST,
    SHIPMENT_MYAGENTBILLING_SUCCESS,
    SHIPMENT_MYAGENTBILLING_FAIL,
    SHIPMENT_UPDATE_COST_REQUEST,
    SHIPMENT_UPDATE_COST_SUCCESS,
    SHIPMENT_UPDATE_COST_FAIL,
    SHIPMENT_UPDATE_COST_RESET,
    PRESHIPMENT_ITEM_SORTING_REQUEST,
    PRESHIPMENT_ITEM_SORTING_SUCCESS,
    PRESHIPMENT_ITEM_SORTING_FAIL,
    PRESHIPMENT_ITEM_SORTING_RESET,
    PRESHIPMENT_VPLUS_REQUEST,
    PRESHIPMENT_VPLUS_SUCCESS,
    PRESHIPMENT_VPLUS_FAIL,
    PRESHIPMENT_VPLUS_RESET,
    SHIPMENT_VPLUS_REQUEST,
    SHIPMENT_VPLUS_SUCCESS,
    SHIPMENT_VPLUS_FAIL,
    SHIPMENT_VPLUS_RESET,
    PRESHIPMENT_SHIPTYPE_REQUEST,
    PRESHIPMENT_SHIPTYPE_SUCCESS,
    PRESHIPMENT_SHIPTYPE_FAIL,
    PRESHIPMENT_SHIPTYPE_RESET,
    PRESHIPMENT_SPECIALROUTE_REQUEST,
    PRESHIPMENT_SPECIALROUTE_SUCCESS,
    PRESHIPMENT_SPECIALROUTE_FAIL,
    PRESHIPMENT_SPECIALROUTE_RESET,
    SHIPMENT_UPDATE_AWB_REQUEST,
    SHIPMENT_UPDATE_AWB_SUCCESS,
    SHIPMENT_UPDATE_AWB_FAIL,
    SHIPMENT_UPDATE_AWB_RESET,
} from '../constants/shipmentConstants'


export const preshipmentCreateReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_CREATE_REQUEST:
            return {loading: true}
        case PRESHIPMENT_CREATE_SUCCESS:
            return {loading: false, success: true, preShipment: action.payload}
        case PRESHIPMENT_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_CREATE_RESET:
            return {}
        default: return state
    }
}

export const preshipmentListReducer = (state={preshipments:[]}, action) => {
    switch(action.type){
        case PRESHIPMENT_LIST_REQUEST:
            return {loading: true}
        case PRESHIPMENT_LIST_SUCCESS:
            return {loading: false, preshipments: action.payload}
        case PRESHIPMENT_LIST_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_LIST_RESET:
            return {}
        default: return state
    }
}

export const preshipmentCancelReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_CANCEL_REQUEST:
            return {loading: true}
        case PRESHIPMENT_CANCEL_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_CANCEL_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_CANCEL_RESET:
            return {}
        default: return state
    }
}

export const preshipmentByIdReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_BYID_REQUEST:
            return {loading: true}
        case PRESHIPMENT_BYID_SUCCESS:
            return {loading: false, preshipment: action.payload}
        case PRESHIPMENT_BYID_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_BYID_RESET:
            return {}
        default: return state
    }
}

export const preshipmentAddRemoveItemReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_ADD_REMOVE_ITEM_REQUEST:
            return {loading: true}
        case PRESHIPMENT_ADD_REMOVE_ITEM_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_ADD_REMOVE_ITEM_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_ADD_REMOVE_ITEM_RESET:
            return {}
        default: return state
    }
}

export const preshipmentItemQtyReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_ITEM_QTY_REQUEST:
            return {loading: true}
        case PRESHIPMENT_ITEM_QTY_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_ITEM_QTY_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_ITEM_QTY_RESET:
            return {}
        default: return state
    }
}

export const preshipmentBoxsizeReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_BOXSIZE_REQUEST:
            return {loading: true}
        case PRESHIPMENT_BOXSIZE_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_BOXSIZE_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_BOXSIZE_RESET:
            return {}
        default: return state
    }
}

export const preshipmentBoxEditReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_BOXEDIT_REQUEST:
            return {loading: true}
        case PRESHIPMENT_BOXEDIT_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_BOXEDIT_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_BOXEDIT_RESET:
            return {}
        default: return state
    }
}

export const preshipmentSaveContactReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_SAVECONTACT_REQUEST:
            return {loading: true}
        case PRESHIPMENT_SAVECONTACT_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_SAVECONTACT_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_SAVECONTACT_RESET:
            return {}
        default: return state
    }
}

export const preshipmentCurrencyReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_CURRENCY_UPDATE_REQUEST:
            return {loading: true}
        case PRESHIPMENT_CURRENCY_UPDATE_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_CURRENCY_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_CURRENCY_UPDATE_RESET:
            return {}
        default: return state
    }
}

export const preshipmentSaveShipperReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_SAVESHIPPER_REQUEST:
            return {loading: true}
        case PRESHIPMENT_SAVESHIPPER_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_SAVESHIPPER_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_SAVESHIPPER_RESET:
            return {}
        default: return state
    }
}

export const preshipmentSaveDHLShipperReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_SAVE_DHL_SHIPPER_REQUEST:
            return {loading: true}
        case PRESHIPMENT_SAVE_DHL_SHIPPER_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_SAVE_DHL_SHIPPER_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_SAVE_DHL_SHIPPER_RESET:
            return {}
        default: return state
    }
}

export const preshipmentItemSortingReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_ITEM_SORTING_REQUEST:
            return {loading: true}
        case PRESHIPMENT_ITEM_SORTING_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_ITEM_SORTING_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_ITEM_SORTING_RESET:
            return {}
        default: return state
    }
}


export const preshipmentVplusReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_VPLUS_REQUEST:
            return {loading: true}
        case PRESHIPMENT_VPLUS_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_VPLUS_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_VPLUS_RESET:
            return {}
        default: return state
    }
}

export const preshipmentSpecialRouteReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_SPECIALROUTE_REQUEST:
            return {loading: true}
        case PRESHIPMENT_SPECIALROUTE_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_SPECIALROUTE_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_SPECIALROUTE_RESET:
            return {}
        default: return state
    }
}

export const preshipmentShipTypeReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_SHIPTYPE_REQUEST:
            return {loading: true}
        case PRESHIPMENT_SHIPTYPE_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_SHIPTYPE_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_SHIPTYPE_RESET:
            return {}
        default: return state
    }
}


export const shipmentCreateReducer = (state={success: false}, action) => {
    switch(action.type){
        case SHIPMENT_CREATE_REQUEST:
            return {loading: true}
        case SHIPMENT_CREATE_SUCCESS:
            return {loading: false, success: true, shipment: action.payload}
        case SHIPMENT_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_CREATE_RESET:
            return {success: false}
        default: return state
    }
}

export const shipmentGetCostReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_GET_COST_REQUEST:
            return {loading: true}
        case SHIPMENT_GET_COST_SUCCESS:
            return {loading: false, cost: action.payload}
        case SHIPMENT_GET_COST_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_GET_COST_RESET:
            return {}
        default: return state
    }
}

export const shipmentListReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case SHIPMENT_LIST_REQUEST:
            return {loading: true}
        case SHIPMENT_LIST_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload.shipments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case SHIPMENT_LIST_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_LIST_RESET:
            return {}
        default: return state
    }
}

export const shipmentDeletedListReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case SHIPMENT_DELETED_LIST_REQUEST:
            return {loading: true}
        case SHIPMENT_DELETED_LIST_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload.shipments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case SHIPMENT_DELETED_LIST_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_DELETED_LIST_RESET:
            return {}
        default: return state
    }
}

export const shipmentMyReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case SHIPMENT_MY_REQUEST:
            return {loading: true}
        case SHIPMENT_MY_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload.shipments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case SHIPMENT_MY_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_MY_RESET:
            return {}
        default: return state
    }
}

export const shipmentByIdReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_BYID_REQUEST:
            return {loading: true}
        case SHIPMENT_BYID_SUCCESS:
            return {loading: false, shipment: action.payload}
        case SHIPMENT_BYID_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_BYID_RESET:
            return {}
        default: return state
    }
}

export const shipmentForInvoiceReducer = (state={shipments: []}, action) => {
    switch(action.type){
        case SHIPMENT_FORINVOICE_REQUEST:
            return {loading: true}
        case SHIPMENT_FORINVOICE_SUCCESS:
            return {loading: false, shipments: action.payload}
        case SHIPMENT_FORINVOICE_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_FORINVOICE_RESET:
            return {}
        default: return state
    }
}


export const shipmentCancelReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_CANCEL_REQUEST:
            return {loading: true}
        case SHIPMENT_CANCEL_SUCCESS:
            return {loading: false, success: true}
        case SHIPMENT_CANCEL_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_CANCEL_RESET:
            return {success: false}
        default: return state
    }
}

export const shipmentAddNoteReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_ADDNOTE_REQUEST:
            return {loading: true}
        case SHIPMENT_ADDNOTE_SUCCESS:
            return {loading: false, success: true}
        case SHIPMENT_ADDNOTE_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_ADDNOTE_RESET:
            return {}
        default: return state
    }
}

export const shipmentAddChargeReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_ADDCHARGE_REQUEST:
            return {loading: true}
        case SHIPMENT_ADDCHARGE_SUCCESS:
            return {loading: false, success: true}
        case SHIPMENT_ADDCHARGE_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_ADDCHARGE_RESET:
            return {}
        default: return state
    }
}

export const shipmentDeleteNoteReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_DELETENOTE_REQUEST:
            return {loading: true}
        case SHIPMENT_DELETENOTE_SUCCESS:
            return {loading: false, success: true}
        case SHIPMENT_DELETENOTE_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_DELETENOTE_RESET:
            return {}
        default: return state
    }
}

export const shipmentTrackingReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_TRACKING_REQUEST:
            return {loading: true}
        case SHIPMENT_TRACKING_SUCCESS:
            return {loading: false, trackingDetail: action.payload}
        case SHIPMENT_TRACKING_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_TRACKING_RESET:
            return {}
        default: return state
    }
}


export const shipmentGetContactsForPreshipReducer = (state={contacts:[]}, action) => {
    switch(action.type){
        case SHIPMENT_GETCONTACTFOR_PRESHIP_REQUEST:
            return {loading: true}
        case SHIPMENT_GETCONTACTFOR_PRESHIP_SUCCESS:
            return {
                loading: false, 
                contacts: action.payload
            }
        case SHIPMENT_GETCONTACTFOR_PRESHIP_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_GETCONTACTFOR_PRESHIP_RESET:
            return {}
        default: return state
    }
}

export const shipmentReturnedReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_RETURNED_REQUEST:
            return {loading: true}
        case SHIPMENT_RETURNED_SUCCESS:
            return {loading: false, success: true}
        case SHIPMENT_RETURNED_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_RETURNED_RESET:
            return {success: false}
        default: return state
    }
}

export const shipmentScannedReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_SCANNED_REQUEST:
            return {loading: true}
        case SHIPMENT_SCANNED_SUCCESS:
            return {loading: false, success: true}
        case SHIPMENT_SCANNED_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_SCANNED_RESET:
            return {success: false}
        default: return state
    }
}

export const preshipmentEditReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_AGENT_RECEIVER_UPDATE_REQUEST:
            return {loading: true}
        case PRESHIPMENT_AGENT_RECEIVER_UPDATE_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_AGENT_RECEIVER_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_AGENT_RECEIVER_UPDATE_RESET:
            return {success: false}
        default: return state
    }
}

export const preshipmentNoteReducer = (state={}, action) => {
    switch(action.type){
        case PRESHIPMENT_NOTE_UPDATE_REQUEST:
            return {loading: true}
        case PRESHIPMENT_NOTE_UPDATE_SUCCESS:
            return {loading: false, success: true}
        case PRESHIPMENT_NOTE_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case PRESHIPMENT_NOTE_UPDATE_RESET:
            return {success: false}
        default: return state
    }
}


export const shipmentScannedListReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case SHIPMENT_GET_SCANNED_REQUEST:
            return {loading: true}
        case SHIPMENT_GET_SCANNED_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload
            }
        case SHIPMENT_GET_SCANNED_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_GET_SCANNED_RESET:
            return {}
        default: return state
    }
}

export const shipmentSaleBillingReducer = (state={billingDetail:[]}, action) => {
    switch(action.type){
        case SHIPMENT_SALEBILLING_REQUEST:
            return {loading: true}
        case SHIPMENT_SALEBILLING_SUCCESS:
            return {
                loading: false, 
                billingDetail: action.payload,
            }
        case SHIPMENT_SALEBILLING_FAIL:
            return {loading: false, error: action.payload}
        default: return state
    }
}

export const shipmentAgentBillingReducer = (state={billingDetail:[]}, action) => {
    switch(action.type){
        case SHIPMENT_AGENTBILLING_REQUEST:
            return {loading: true}
        case SHIPMENT_AGENTBILLING_SUCCESS:
            return {
                loading: false, 
                billingDetail: action.payload,
            }
        case SHIPMENT_AGENTBILLING_FAIL:
            return {loading: false, error: action.payload}
        default: return state
    }
}

export const shipmentMyAgentBillingReducer = (state={billingDetail:[]}, action) => {
    switch(action.type){
        case SHIPMENT_MYAGENTBILLING_REQUEST:
            return {loading: true}
        case SHIPMENT_MYAGENTBILLING_SUCCESS:
            return {
                loading: false, 
                billingDetail: action.payload,
            }
        case SHIPMENT_MYAGENTBILLING_FAIL:
            return {loading: false, error: action.payload}
        default: return state
    }
}

export const userShipCountReducer = (state={countDetail:[]}, action) => {
    switch(action.type){
        case SHIPMENT_USERSHIPCOUNT_REQUEST:
            return {loading: true}
        case SHIPMENT_USERSHIPCOUNT_SUCCESS:
            return {
                loading: false, 
                countDetail: action.payload,
            }
        case SHIPMENT_USERSHIPCOUNT_FAIL:
            return {loading: false, error: action.payload}
        default: return state
    }
}

export const shipAndScanReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case SHIPMENT_SHIPANDSCAN_REQUEST:
            return {loading: true}
        case SHIPMENT_SHIPANDSCAN_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload,
            }
        case SHIPMENT_SHIPANDSCAN_FAIL:
            return {loading: false, error: action.payload}
        default: return state
    }
}

export const shipmentDailyReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case SHIPMENT_DAILYREPORT_REQUEST:
            return {loading: true}
        case SHIPMENT_DAILYREPORT_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload,
            }
        case SHIPMENT_DAILYREPORT_FAIL:
            return {loading: false, error: action.payload}
        default: return state
    }
}

export const shipmentChangeAgentReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_CHANGEAGENT_REQUEST:
            return {loading: true}
        case SHIPMENT_CHANGEAGENT_SUCCESS:
            return {
                loading: false, 
                success: true,
            }
        case SHIPMENT_CHANGEAGENT_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_CHANGEAGENT_RESET:
            return {}
        default: return state
    }
}

export const shipmentStatusReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case SHIPMENT_STATUS_REQUEST:
            return {loading: true}
        case SHIPMENT_STATUS_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload,
            }
        case SHIPMENT_STATUS_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_STATUS_RESET:
            return {}
        default: return state
    }
}

export const saleShipmentStatusReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case SHIPMENT_STATUS_SALE_REQUEST:
            return {loading: true}
        case SHIPMENT_STATUS_SALE_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload,
            }
        case SHIPMENT_STATUS_SALE_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_STATUS_SALE_RESET:
            return {}
        default: return state
    }
}

export const eachShipmentStatusReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case SHIPMENT_STATUS_EACH_REQUEST:
            return {loading: true}
        case SHIPMENT_STATUS_EACH_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload,
            }
        case SHIPMENT_STATUS_EACH_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_STATUS_EACH_RESET:
            return {}
        default: return state
    }
}

export const myShipmentStatusReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case SHIPMENT_STATUS_MY_REQUEST:
            return {loading: true}
        case SHIPMENT_STATUS_MY_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload,
            }
        case SHIPMENT_STATUS_MY_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_STATUS_MY_RESET:
            return {}
        default: return state
    }
}

export const shipmentUpdateCostReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_UPDATE_COST_REQUEST:
            return {loading: true}
        case SHIPMENT_UPDATE_COST_SUCCESS:
            return {loading: false, success: true}
        case SHIPMENT_UPDATE_COST_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_UPDATE_COST_RESET:
            return {}
        default: return state
    }
}

export const shipmentUpdateAWBReducer = (state={}, action) => {
    switch(action.type){
        case SHIPMENT_UPDATE_AWB_REQUEST:
            return {loading: true}
        case SHIPMENT_UPDATE_AWB_SUCCESS:
            return {loading: false, success: true}
        case SHIPMENT_UPDATE_AWB_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_UPDATE_AWB_RESET:
            return {}
        default: return state
    }
}

export const shipmentVplusReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case SHIPMENT_VPLUS_REQUEST:
            return {loading: true}
        case SHIPMENT_VPLUS_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload,
            }
        case SHIPMENT_VPLUS_FAIL:
            return {loading: false, error: action.payload}
        case SHIPMENT_VPLUS_RESET:
            return {}
        default: return state
    }
}