import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    Stack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Spinner,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    HStack,
    Center,
  } from '@chakra-ui/react'
import { saveContactPreshipment } from '../actions/shipmentActions'
import { MdMenuBook } from 'react-icons/md'
import { fromAddress } from '../actions/addressActions'
import Error from '../components/Error'
import { PRESHIPMENT_SAVECONTACT_RESET } from '../constants/shipmentConstants'
import { ADDRESS_FROM_RESET } from '../constants/addressConstants'

const ShipmentFromAddressModal = ({disabled}) => {
    const params = useParams()
    const preId = params.id
    const dispatch = useDispatch()
    const [keyword, setKeyword] = useState('')
    const [show, setShow] = useState(false)
    const {loading, error, addresses} = useSelector(state=>state.address.From)
    const {loading: loadingSaveContact, error: errorSaveContact, success} = useSelector(state=>state.preshipment.SaveContact)

    useEffect(()=>{ 
        if(success){
            setShow(false)
            dispatch({type: PRESHIPMENT_SAVECONTACT_RESET})
        } 
        if(show){
            dispatch(fromAddress())
        }      
        return()=>{
            dispatch({type: ADDRESS_FROM_RESET})
        }
      },[show, success, dispatch])

    const handleClose = () => {
        setKeyword('')
        setShow(false)
    }

    const handleSearchFrom = () => {
        dispatch(fromAddress(keyword))
    }

    const handleSubmit = (id) => {
        const contactData = {
          preId: preId,
          contact: id
        }
        dispatch(saveContactPreshipment(contactData))
        //console.log(contactData)
    }
    return (
        <>
            
            <Button 
                onClick={()=>setShow(true)} 
                size='sm' 
                colorScheme='blue' 
                leftIcon={<MdMenuBook />}
                disabled={disabled}
            >
                From Address
            </Button>
            <Modal isOpen={show} onClose={handleClose} size='5xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent h={'90vh'} overflowX="auto">

                <ModalHeader mx='auto'>From Address List                
                {(loading || loadingSaveContact) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}          
                </ModalHeader>
                <Center>
                    <HStack mt='3' w='95%'>
                        <Input 
                            type='text'
                            size='sm'
                            placeholder='search name, company, email, address, phone'
                            value={keyword}
                            onChange={e=>setKeyword(e.target.value)}
                            onKeyPress={(e)=>{
                                if(e.key === 'Enter'){
                                    dispatch(fromAddress(e.target.value))
                                }
                            }}
                        />
                        <Button
                            size='sm'
                            w='100px'
                            colorScheme={'green'}
                            onClick={handleSearchFrom}
                        >Find</Button>
                    </HStack>
                </Center>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={4}>                        
                        {error && <Error error={error} />}
                        {errorSaveContact && <Error error={errorSaveContact} />}

                        <Table size='sm' variant='striped' colorScheme='yellow' my='4'>
                            <Thead>
                                <Tr>
                                <Th>Name</Th>
                                <Th>Company</Th>
                                <Th>Address</Th>
                                <Th>Phone</Th>
                                <Th>Options</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {addresses && addresses.map((add,index)=>(
                                <Tr key={index}>
                                    <Td>{add.name}</Td>
                                    <Td>{add.company}</Td>
                                    <Td>{add.address}</Td>
                                    <Td>{add.phone}</Td>
                                    <Td>
                                        <Button 
                                            colorScheme='green' 
                                            size='sm'
                                            onClick={()=>handleSubmit(add._id)}
                                            >Select
                                        </Button>
                                    </Td>
                                </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Stack>
                </ModalBody>
            </ModalContent>
            </Modal>
        </>
    )
}

export default ShipmentFromAddressModal