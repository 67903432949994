import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    Box,
    Heading,
    HStack,
    Spinner,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody
} from '@chakra-ui/react'
import Error from '../../components/Error'
import {myShipmentsStatus } from '../../actions/shipmentActions'
import { statusCodes } from '../../data/statusCodes'
import ShipmentTrackingModal from '../../Modals/ShipmentTrackingModal'


const SaleShipmentStatusScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {loading, error, shipments} = useSelector( state=> state.shipment.MyShipmentStatus)
    useEffect(()=>{
        dispatch( myShipmentsStatus() )             
    },[dispatch])

    return (
        <Box>
            <HStack my='3'>  
                <Heading>My Shipment Status</Heading>            
                <Box>{loading && <Spinner label='Please wait' speed='0.1s' thickness='5px' /> }</Box>                
            </HStack>

            {error && <Error error={error} />}
            
            <Table size='sm' variant='striped' colorScheme={'black'} my='4'>
                <Thead>
                    <Tr>
                    <Th>No</Th>
                    <Th>Status Description</Th>
                    <Th>Total</Th>
                    <Th>Detail List</Th>
                    </Tr>
                </Thead>

                <Tbody>
                {shipments && shipments.map((s,idx)=>(
                    <Tr key={idx}>
                        <Td>{idx+1}</Td>
                        <Td>{statusCodes[s.statusCode]}</Td>                      
                        <Td>{s.total}</Td>                      
                        <Td>
                            <Table size='sm' variant='striped' colorScheme='blue'>
                                <Thead>
                                    <Tr>
                                    <Th>AWB</Th>
                                    <Th>Agent</Th>
                                    <Th>Receiver</Th>
                                    <Th>Ship Date</Th>
                                    <Th>Tracking</Th>
                                    </Tr>
                                </Thead>

                                <Tbody>
                                    {s.awbList.map((a,idx)=>(
                                        <Tr key={idx}>
                                            <Td>
                                                <Box fontWeight={'500'} className='txt' onClick={()=>navigate(`/myshipments/${a._id}`)}>
                                                    {a.awb}
                                                </Box>
                                            </Td>
                                            <Td>{a.agent.name}</Td>
                                            <Td>{a.customerDetails.receiverDetails.contactInformation.fullName}-{a.customerDetails.receiverDetails.postalAddress.countryCode}</Td>
                                            <Td>{a.createdAt.substring(0,10)}</Td>
                                            <Td><ShipmentTrackingModal awb={a.awb} variant='link' text='Track' width={'70px'} /></Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>  
                        </Td>                      
                    </Tr>
                    ))}
                </Tbody>
            </Table>  
        </Box>
    )
}

export default SaleShipmentStatusScreen