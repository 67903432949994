import React, {useState, useEffect, useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Stack,
    InputGroup,
    InputLeftAddon,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Spacer,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useToast
  } from '@chakra-ui/react'
  import { MdSave, MdClose, MdAddBox } from 'react-icons/md'
  import { AGENTRATE_CREATE_RESET } from '../constants/agentRateConstants'
  import { createAgentRate } from '../actions/agentRateActions'

const AgentRateAddModal = () => {
    const dispatch = useDispatch()
    const toast = useToast()
    const [show, setShow] = useState(false)
    const [name, setName] = useState('')
    const [doc, setDoc] = useState('')
    const [p0, setP0] = useState('')
    const [p5, setP5] = useState('')
    const [p10, setP10] = useState('')
    const [p15, setP15] = useState('')
    const [p20, setP20] = useState('')
    const [p25, setP25] = useState('')
    const [p30, setP30] = useState('')
    const [p70, setP70] = useState('')
    const [p300, setP300] = useState('')
    const [isError, setIsError] = useState([])

    const {loading: createLoading, error: createError, success: createSuccess} = useSelector(state=>state.agentRate.Create)

    const handleSetStates = useCallback(()=>{        
        setName('')
        setDoc('')
        setP0('')
        setP5('')
        setP10('')
        setP15('')
        setP20('')
        setP25('')
        setP30('')
        setP70('')
        setP300('')
        setIsError([])
        dispatch({type: AGENTRATE_CREATE_RESET})   
        setShow(false) 
    },[dispatch])

    useEffect(()=>{
        if(createSuccess){
            toast({
                status: 'success',
                position: 'top',
                title: 'success !',
                duration: 5000,
                isClosable: true,
            })    
            handleSetStates()                      
        }
    },[createSuccess, handleSetStates, toast])

    const handleSubmit = () => {
        const emptyCheck = {
            name,
            doc,
            p0,
            p5,
            p10,
            p15,
            p20,
            p25,
            p30,
            p70,
            p300,
        }
        for (const [key, value] of Object.entries(emptyCheck)) {
            if(value === ''){
                isError.push(key)
                setIsError([...isError])
            }
        }
        if(isError.length === 0){
            const newAgentRate = {
                name,
                doc,
                p0,
                p5,
                p10,
                p15,
                p20,
                p25,
                p30,
                p70,
                p300
            }
            //console.log(newAgentRate)
            dispatch(createAgentRate(newAgentRate))
        } else {
            return
        }
    }

    const handleClose = () => {
        handleSetStates()
        setShow(false)        
    }

    return (
    <>
        <Button size='sm' colorScheme='green' onClick={()=>setShow(true)} leftIcon={<MdAddBox />}>Add New</Button>

        <Modal isOpen={show} onClose={handleClose} size='md' closeOnOverlayClick={false} minW='800px'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>{createLoading ? <Spinner label='Please wait' speed='0.1s' thickness='5px' /> : 'Add New Agent Rate' }</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Stack spacing={4}>

                    {createError && 
                        <Alert status='error' variant='left-accent'>
                        <AlertIcon />
                        <AlertTitle mr={2}>Adding New Agent Rate Error</AlertTitle>
                        <AlertDescription>{createError}</AlertDescription>
                        </Alert>
                    }

                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='Name' w='20%' minW='150px' />
                        <Input 
                            type='text' 
                            value={name} 
                            onChange={e=>setName(e.target.value)}
                            isInvalid={isError.indexOf('name') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'name'))}
                            maxLength={30}
                        />                        
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='Doc' w='20%' minW='150px' />
                        <Input 
                            type='number' 
                            value={doc} 
                            onChange={e=>setDoc(e.target.value)}
                            isInvalid={isError.indexOf('doc') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'doc'))}
                            maxLength={5}
                        />                        
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='0.5-5' w='20%' minW='150px' />
                        <Input 
                            type='number' 
                            value={p0} 
                            onChange={e=>setP0(e.target.value)}
                            isInvalid={isError.indexOf('p0') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'p0'))}
                            maxLength={5}
                        />                        
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='5.1-10' w='20%' minW='150px' />
                        <Input 
                            type='number' 
                            value={p5} 
                            onChange={e=>setP5(e.target.value)}
                            isInvalid={isError.indexOf('p5') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'p5'))}
                            maxLength={5}
                        />                        
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='10.1-15' w='20%' minW='150px' />
                        <Input 
                            type='number' 
                            value={p10} 
                            onChange={e=>setP10(e.target.value)}
                            isInvalid={isError.indexOf('p10') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'p10'))}
                            maxLength={5}
                        />                        
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='15.1-20' w='20%' minW='150px' />
                        <Input 
                            type='number' 
                            value={p15} 
                            onChange={e=>setP15(e.target.value)}
                            isInvalid={isError.indexOf('p15') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'p15'))}
                            maxLength={5}
                        />                        
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='20.1-25' w='20%' minW='150px' />
                        <Input 
                            type='number' 
                            value={p20} 
                            onChange={e=>setP20(e.target.value)}
                            isInvalid={isError.indexOf('p20') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'p20'))}
                            maxLength={5}
                        />                        
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='25.1-30' w='20%' minW='150px' />
                        <Input 
                            type='number' 
                            value={p25} 
                            onChange={e=>setP25(e.target.value)}
                            isInvalid={isError.indexOf('p25') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'p25'))}
                            maxLength={5}
                        />                        
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='30.1-70' w='20%' minW='150px' />
                        <Input 
                            type='number' 
                            value={p30} 
                            onChange={e=>setP30(e.target.value)}
                            isInvalid={isError.indexOf('p30') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'p30'))}
                            maxLength={5}
                        />                        
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='70.1-300' w='20%' minW='150px' />
                        <Input 
                            type='number' 
                            value={p70} 
                            onChange={e=>setP70(e.target.value)}
                            isInvalid={isError.indexOf('p70') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'p70'))}
                            maxLength={5}
                        />                        
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='300.1-1000' w='20%' minW='150px' />
                        <Input 
                            type='number' 
                            value={p300} 
                            onChange={e=>setP300(e.target.value)}
                            isInvalid={isError.indexOf('p300') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'p300'))}
                            maxLength={5}
                        />                        
                    </InputGroup>




                </Stack>
            </ModalBody>

            <ModalFooter>
            <Button colorScheme='blue' w='100px' size='sm' onClick={handleClose} leftIcon={<MdClose />}>
                Close
            </Button>
            <Spacer />
            <Button colorScheme='green' w='100px' size='sm' leftIcon={<MdSave />} onClick={handleSubmit}>Save</Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    </>
    )
}

export default AgentRateAddModal