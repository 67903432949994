import axios from 'axios'
import {
    INVOICE_ADDSHIPMENTS_FAIL,
    INVOICE_ADDSHIPMENTS_REQUEST,
    INVOICE_ADDSHIPMENTS_SUCCESS,
    INVOICE_BYID_FAIL,
    INVOICE_BYID_REQUEST,
    INVOICE_BYID_SUCCESS,
    INVOICE_CREATE_FAIL,
    INVOICE_CREATE_REQUEST,
    INVOICE_CREATE_SUCCESS,
    INVOICE_DELETE_FAIL,
    INVOICE_DELETE_REQUEST,
    INVOICE_DELETE_SUCCESS,
    INVOICE_LIST_FAIL,
    INVOICE_LIST_REQUEST, 
    INVOICE_LIST_SUCCESS,
    INVOICE_MY_FAIL,
    INVOICE_MY_REQUEST,
    INVOICE_MY_SUCCESS,
    INVOICE_REMOVESHIPMENTS_FAIL,
    INVOICE_REMOVESHIPMENTS_REQUEST,
    INVOICE_REMOVESHIPMENTS_SUCCESS,
} from '../constants/invoiceConstants'


export const listInvoices = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: INVOICE_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/invoices?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: INVOICE_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: INVOICE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const saleFilterInvoices = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: INVOICE_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/invoices/salefilter`, {filterData}, config)
        dispatch({type: INVOICE_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: INVOICE_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myInvoices = (pageNumber = '', itemCount = '', keyword = '', date = '') => async (dispatch, getState) => {
    try {
        dispatch({type: INVOICE_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/invoices/myinvoices?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}&date=${date}`, config)
        dispatch({type: INVOICE_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: INVOICE_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const createInvoice = (invoiceData) => async (dispatch, getState) => {
    try {
        dispatch({type: INVOICE_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/invoices`, {invoiceData}, config)
        dispatch({type: INVOICE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: INVOICE_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getInvoiceById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: INVOICE_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/invoices/${id}`, config)
        dispatch({type: INVOICE_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: INVOICE_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const deleteInvoice = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: INVOICE_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/invoices/${id}`, config)
        dispatch({type: INVOICE_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: INVOICE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addShipmentsToInvoice = (id, shipmentIdList) => async (dispatch, getState) => {
    try {
        dispatch({type: INVOICE_ADDSHIPMENTS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/invoices/${id}`, {shipmentIdList}, config)
        dispatch({type: INVOICE_ADDSHIPMENTS_SUCCESS})
    } catch (error) {
        dispatch({
            type: INVOICE_ADDSHIPMENTS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const removeShipmentFromInvoice = (invId, newIdList) => async (dispatch, getState) => {
    try {
        dispatch({type: INVOICE_REMOVESHIPMENTS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/invoices/remove`, {invId, newIdList}, config)
        dispatch({type: INVOICE_REMOVESHIPMENTS_SUCCESS})
    } catch (error) {
        dispatch({
            type: INVOICE_REMOVESHIPMENTS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const filterInvoices = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: INVOICE_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/invoices/filter`, {filterData}, config)
        dispatch({type: INVOICE_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: INVOICE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}