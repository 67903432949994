import {
    ITEM_CREATE_REQUEST,
    ITEM_CREATE_SUCCESS,
    ITEM_CREATE_FAIL,
    ITEM_CREATE_RESET,
    ITEM_LIST_REQUEST,
    ITEM_LIST_SUCCESS,
    ITEM_LIST_FAIL,
    ITEM_LIST_RESET,
    ITEM_BYID_REQUEST,
    ITEM_BYID_SUCCESS,
    ITEM_BYID_FAIL,
    ITEM_BYID_RESET,
    ITEM_EDIT_REQUEST,
    ITEM_EDIT_SUCCESS,
    ITEM_EDIT_FAIL,
    ITEM_EDIT_RESET,
    ITEM_DELETE_REQUEST,
    ITEM_DELETE_SUCCESS,
    ITEM_DELETE_FAIL,
    ITEM_DELETE_RESET,
} from '../constants/itemConstants'

export const itemCreateReducer = (state={}, action) => {
    switch(action.type){
        case ITEM_CREATE_REQUEST:
            return {loading: true}
        case ITEM_CREATE_SUCCESS:
            return {loading: false, success: true, newItem: action.payload}
        case ITEM_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case ITEM_CREATE_RESET:
            return {}
        default: return state
    }
}

export const itemListReducer = (state={items:[]}, action) => {
    switch(action.type){
        case ITEM_LIST_REQUEST:
            return {loading: true}
        case ITEM_LIST_SUCCESS:
            return {
                loading: false, 
                items: action.payload.items,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case ITEM_LIST_FAIL:
            return {loading: false, error: action.payload}
        case ITEM_LIST_RESET:
            return {}
        default: return state
    }
}

export const itemEditReducer = (state={}, action) => {
    switch(action.type){
        case ITEM_EDIT_REQUEST:
            return {loading: true}
        case ITEM_EDIT_SUCCESS:
            return {loading: false, success: true}
        case ITEM_EDIT_FAIL:
            return {loading: false, error: action.payload}
        case ITEM_EDIT_RESET:
            return {}
        default: return state
    }
}


export const itemDeleteReducer = (state={}, action) => {
    switch(action.type){
        case ITEM_DELETE_REQUEST:
            return {loading: true}
        case ITEM_DELETE_SUCCESS:
            return {loading: false, success: true}
        case ITEM_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case ITEM_DELETE_RESET:
            return {}
        default: return state
    }
}

export const itemByIdReducer = (state={}, action) => {
    switch(action.type){
        case ITEM_BYID_REQUEST:
            return {loading: true}
        case ITEM_BYID_SUCCESS:
            return {loading: false, itemDetail: action.payload,
            }
        case ITEM_BYID_FAIL:
            return {loading: false, error: action.payload}
        case ITEM_BYID_RESET:
            return {}
        default: return state
    }
}
