import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Stack,
    Box,
    HStack,
    FormControl,
    FormLabel,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Spacer,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    InputGroup,
    InputLeftAddon,
  } from '@chakra-ui/react'
  import { MdSave, MdClose, MdAddBox } from 'react-icons/md'
  import { createItem } from '../actions/itemActions'
import { ITEM_CREATE_RESET } from '../constants/itemConstants'

const ItemAddModal = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [brandName, setBrandName] = useState('')
    const [name, setName] = useState('')
    const [thaiName, setThaiName] = useState('')
    const [description, setDescription] = useState('')
    const [ingredients, setIngredients] = useState('')
    const [hsCode, setHsCode] = useState('')
    const [manuName, setManuName] = useState('')
    const [manuAddress, setManuAddress] = useState('')
    const [manuCountry, setManuCountry] = useState('TH')
    const [price, setPrice] = useState(0)
    const [mfg, setMfg] = useState('')
    const [exp, setExp] = useState('')
    const [isError, setIsError] = useState([])
    const {loading, error, success} = useSelector(state=> state.item.Create)

    useEffect(()=>{
        if(success){
            setBrandName('')
            setName('')
            setThaiName('')
            setDescription('')
            setIngredients('')
            setManuName('')
            setManuAddress('')
            setManuCountry('TH')
            setPrice(0)
            setIsError([])
            dispatch({type: ITEM_CREATE_RESET})
            setShow(false)
            
        }
        return()=>{
            dispatch({type: ITEM_CREATE_RESET})
        }
    },[dispatch, success])

    const handleClose = () => {
        setBrandName('')
        setName('')
        setThaiName('')
        setDescription('')
        setIngredients('')
        setHsCode('')
        setManuName('')
        setManuAddress('')
        setManuCountry('TH')
        setPrice(0)
        setIsError([])
        dispatch({type: ITEM_CREATE_RESET})
        setShow(false)
        
    }
    const handleSubmit = () => {
        const newItem = {
            name,
            description,
            manuName,
            manuAddress,
            manuCountry,
            price,
        }
        for (const [key, value] of Object.entries(newItem)) {
            if(value === ''){
                isError.push(key)
                setIsError([...isError])
            }
        }
        if(isError.length === 0){
            newItem.brandName = brandName
            newItem.thaiName = thaiName
            newItem.ingredients = ingredients
            newItem.hsCode = hsCode
            newItem.mfg = mfg
            newItem.exp = exp
            // console.log(newItem)
            dispatch(createItem(newItem))
        } else {
            return
        }
    }
    return (
            <>
                <Button size='sm' colorScheme='green' onClick={()=>setShow(true)} leftIcon={<MdAddBox />}>Add New Item</Button>

                <Modal isOpen={show} onClose={handleClose} size='3xl' closeOnOverlayClick={false} minW='800px'>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{loading ? <Spinner label='Please wait' speed='0.1s' thickness='5px' /> : 'Add New Item' }</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            {error && 
                                <Alert status='error' variant='left-accent'>
                                <AlertIcon />
                                <AlertTitle mr={2}>Error saving new item.</AlertTitle>
                                <AlertDescription>{error}</AlertDescription>
                                </Alert>
                            }
                        <FormControl>
                            <FormLabel>Brand Name</FormLabel>
                            <Input 
                                type='text' 
                                size='sm'
                                value={brandName}
                                onChange={e=>setBrandName(e.target.value)}
                                maxLength={50}
                                
                                />
                        </FormControl>

                        <FormControl isRequired isInvalid={isError.indexOf('name') !== -1}>
                            <FormLabel>General Name <span style={{'fontSize': '10px', 'color': 'red'}}>({50-name.length} character left)</span></FormLabel>
                            <Input 
                                type='text' 
                                size='sm'
                                value={name}
                                onChange={e=>setName(e.target.value)}
                                onKeyPress={()=>setIsError(isError.filter(x => x !== 'name'))}
                                maxLength={50}
                                
                                />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Thai Name</FormLabel>
                            <Input 
                                type='text' 
                                size='sm'
                                value={thaiName}
                                onChange={e=>setThaiName(e.target.value)}                                
                                />
                        </FormControl>
                        <FormControl isRequired isInvalid={isError.indexOf('description') !== -1}>
                            <FormLabel>Description <span style={{'fontSize': '10px', 'color': 'red'}}>({120-description.length} character left)</span></FormLabel>
                            <Input 
                                as='textarea' 
                                size='sm'
                                height='60px'
                                value={description}
                                onChange={e=>setDescription(e.target.value)}
                                onKeyPress={()=>setIsError(isError.filter(x => x !== 'description'))}
                                maxLength={120}
                                
                                />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Ingredients/Others/Notes</FormLabel>
                            <Input 
                                as='textarea' 
                                size='sm'
                                height='100px'
                                value={ingredients}
                                onChange={e=>setIngredients(e.target.value)}
                                maxLength={150}
                                
                                />
                        </FormControl>
                         <FormControl >
                            <FormLabel>HS Code </FormLabel>
                            <Input 
                                type='text' 
                                size='sm'
                                value={hsCode}
                                onChange={e=>setHsCode(e.target.value)}
                                maxLength={20}
                                
                                />
                        </FormControl>
                        <HStack>
                            <Box w={'50%'} >
                                <FormControl>
                                    <FormLabel>MFG Date</FormLabel>
                                    <Input 
                                        type='date' 
                                        size='sm'
                                        value={mfg}
                                        onChange={e=>setMfg(e.target.value)}
                                        />
                                </FormControl>
                            </Box>
                            <Box w={'50%'} >
                                <FormControl>
                                    <FormLabel>EXP Date</FormLabel>
                                    <Input 
                                        type='date' 
                                        size='sm'
                                        value={exp}
                                        onChange={e=>setExp(e.target.value)}
                                        />
                                </FormControl>
                            </Box>
                        </HStack>
                        <FormControl isRequired isInvalid={isError.indexOf('manuName') !== -1}>
                            <FormLabel>Manu,Name</FormLabel>
                            <Input 
                                type='text' 
                                size='sm'
                                value={manuName}
                                onChange={e=>setManuName(e.target.value)}
                                onKeyPress={()=>setIsError(isError.filter(x => x !== 'manuName'))}
                                maxLength={50}
                                
                                />
                        </FormControl>
                        <FormControl isRequired isInvalid={isError.indexOf('manuAddress') !== -1}>
                            <FormLabel>Manu,Address</FormLabel>
                            <Input 
                                as='textarea' 
                                size='sm'
                                height='100px'
                                value={manuAddress}
                                onChange={e=>setManuAddress(e.target.value)}
                                onKeyPress={()=>setIsError(isError.filter(x => x !== 'manuAddress'))}
                                maxLength={100}
                                
                                />
                        </FormControl>
                        <FormControl isRequired isInvalid={isError.indexOf('manuCountry') !== -1}>
                            <FormLabel>Manu,Country</FormLabel>
                            <Input 
                                type='text' 
                                size='sm'
                                value={manuCountry}
                                onChange={e=>setManuCountry(e.target.value)}
                                onKeyPress={()=>setIsError(isError.filter(x => x !== 'manuCountry'))}
                                maxLength={2}
                                
                                />
                        </FormControl>

                        <FormControl>
                            <FormLabel>Price</FormLabel>
                            <InputGroup size='sm'>                        
                                <InputLeftAddon children='$ ' />
                                <Input 
                                    type='number' 
                                    size='sm'
                                    value={price}
                                    onChange={e=>setPrice(e.target.value)}
                                    maxLength={10}                                    
                                />                        
                            </InputGroup>
                        </FormControl>

                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                    <Button colorScheme='blue' w='200px' size='sm' onClick={handleClose} leftIcon={<MdClose />}>
                        Close
                    </Button>
                    <Spacer />
                    <Button 
                        colorScheme='green' 
                        isLoading={loading}
                        w='200px' size='sm' 
                        leftIcon={<MdSave />} 
                        onClick={handleSubmit}
                        >Save</Button>
                    </ModalFooter>
                </ModalContent>
                </Modal>
            </>
    )
}

export default ItemAddModal