import { 
    DOMESTICPAYMENT_APPROVE_FAIL,
    DOMESTICPAYMENT_APPROVE_REQUEST,
    DOMESTICPAYMENT_APPROVE_RESET,
    DOMESTICPAYMENT_APPROVE_SUCCESS,
    DOMESTICPAYMENT_BYID_FAIL,
    DOMESTICPAYMENT_BYID_REQUEST,
    DOMESTICPAYMENT_BYID_RESET,
    DOMESTICPAYMENT_BYID_SUCCESS,
    DOMESTICPAYMENT_CREATE_FAIL,
    DOMESTICPAYMENT_CREATE_REQUEST,
    DOMESTICPAYMENT_CREATE_RESET,
    DOMESTICPAYMENT_CREATE_SUCCESS,
    DOMESTICPAYMENT_DELETE_FAIL,
    DOMESTICPAYMENT_DELETE_REQUEST,
    DOMESTICPAYMENT_DELETE_RESET,
    DOMESTICPAYMENT_DELETE_SUCCESS,
    DOMESTICPAYMENT_LIST_FAIL,
    DOMESTICPAYMENT_LIST_REQUEST, 
    DOMESTICPAYMENT_LIST_RESET, 
    DOMESTICPAYMENT_LIST_SUCCESS,
    DOMESTICPAYMENT_MY_FAIL,
    DOMESTICPAYMENT_MY_REQUEST,
    DOMESTICPAYMENT_MY_RESET,
    DOMESTICPAYMENT_MY_SUCCESS,
} from '../../constants/domestic_constants/domesticPaymentConstants'

export const domesticPaymentListReducer = (state={payments:[]}, action) => {
    switch(action.type){
        case DOMESTICPAYMENT_LIST_REQUEST:
            return {loading: true}
        case DOMESTICPAYMENT_LIST_SUCCESS:
            return {
                loading: false, 
                payments: action.payload.payments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case DOMESTICPAYMENT_LIST_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICPAYMENT_LIST_RESET:
            return {}
        default: return state
    }
}

export const domesticPaymentMyReducer = (state={payments:[]}, action) => {
    switch(action.type){
        case DOMESTICPAYMENT_MY_REQUEST:
            return {loading: true}
        case DOMESTICPAYMENT_MY_SUCCESS:
            return {
                loading: false, 
                payments: action.payload.payments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case DOMESTICPAYMENT_MY_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICPAYMENT_MY_RESET:
            return {}
        default: return state
    }
}



export const domesticPaymentCreateReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICPAYMENT_CREATE_REQUEST:
            return {loading: true}
        case DOMESTICPAYMENT_CREATE_SUCCESS:
            return {loading: false, success: true}
        case DOMESTICPAYMENT_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICPAYMENT_CREATE_RESET:
            return {}
        default: return state
    }
}

export const domesticPaymentByIdReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICPAYMENT_BYID_REQUEST:
            return {loading: true}
        case DOMESTICPAYMENT_BYID_SUCCESS:
            return {loading: false, payment: action.payload}
        case DOMESTICPAYMENT_BYID_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICPAYMENT_BYID_RESET:
            return {}
        default: return state
    }
}

export const domesticPaymentApproveReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICPAYMENT_APPROVE_REQUEST:
            return {loading: true}
        case DOMESTICPAYMENT_APPROVE_SUCCESS:
            return {loading: false, success: true}
        case DOMESTICPAYMENT_APPROVE_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICPAYMENT_APPROVE_RESET:
            return {}
        default: return state
    }
}

export const domesticPaymentDeleteReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICPAYMENT_DELETE_REQUEST:
            return {loading: true}
        case DOMESTICPAYMENT_DELETE_SUCCESS:
            return {loading: false, success: true}
        case DOMESTICPAYMENT_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICPAYMENT_DELETE_RESET:
            return {}
        default: return state
    }
}