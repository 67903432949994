import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Spinner,
  Stack,
  HStack,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Input,
  Center,
  Heading,
} from '@chakra-ui/react'
import { myAccount } from '../../actions/userActions'
import UserChangePasswordModal from '../../Modals/UserChangePasswordModal'

const AgentAccountScreen = () => {
  const dispatch = useDispatch()

  const {loading, error, userDetail} = useSelector( state=> state.user.My)

  useEffect(()=>{
    dispatch(myAccount())   
  },[dispatch ])

  
  return (
    <Box mx='auto' mt='50px' w='700px'>
      <Stack spacing={4}>
        {loading && <Spinner label='Please wait' speed='0.1s' thickness='5px' /> }
        {error && 
          <Alert status='error' variant='left-accent'>
          <AlertIcon />
          <AlertTitle mr={2}>Error getting user details</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
          </Alert>
        }
        <Center><Heading>User Account Detail</Heading></Center>
        <Box h='50px'></Box>
        {userDetail  && 
          <Box>
            <Stack spacing={'4'}>
              <HStack>
                <Box w='200px'>Name</Box>
                <Input 
                  readOnly
                  size='sm'
                  value={userDetail.name}
                />
              </HStack>

              
              <HStack>
                <Box w='200px'>Phone</Box>
                <Input 
                  readOnly
                  size='sm'
                  value={userDetail.phone}
                />
              </HStack>

              <HStack>
                <Box w='200px'>Email</Box>
                <Input 
                  readOnly
                  size='sm'
                  value={userDetail.email}
                />
              </HStack>

              <HStack>
                <Box w='200px'>Created Date</Box>
                <Input 
                  readOnly
                  size='sm'
                  value={userDetail.createdAt.substring(0,10)}
                />
              </HStack>

              {userDetail && userDetail.accountType === 'agent' &&              
                <>
                <HStack>
                  <Box w='200px'>Account Balance</Box>
                  <Input 
                    readOnly
                    size='sm'
                    value={userDetail.balance}
                  />
                </HStack>

                <HStack>
                  <Box w='200px'>Sale</Box>
                  <Input 
                    readOnly
                    size='sm'
                    value={userDetail.sale.name}
                  />
                </HStack>

                <HStack>
                  <Box w='200px'>Total Shipments</Box>
                  <Input 
                    readOnly
                    size='sm'
                    value={userDetail.shipmentCount}
                  />
                </HStack>

                <HStack>
                  <Box w='200px'>Total Invoices</Box>
                  <Input 
                    readOnly
                    size='sm'
                    value={userDetail.invoiceCount}
                  />
                </HStack>
                </>
              }




            </Stack>

              

            <Box w='100px' h='100px' mt='50px' mx='auto'>
            <UserChangePasswordModal />
            </Box>


          </Box>        
        }
      </Stack>
    </Box>
  )
}

export default AgentAccountScreen