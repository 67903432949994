export const REJECTNAME_LIST_REQUEST = 'REJECTNAME_LIST_REQUEST'
export const REJECTNAME_LIST_SUCCESS = 'REJECTNAME_LIST_SUCCESS'
export const REJECTNAME_LIST_FAIL = 'REJECTNAME_LIST_FAIL'
export const REJECTNAME_LIST_RESET = 'REJECTNAME_LIST_RESET'

export const REJECTNAME_CREATE_REQUEST = 'REJECTNAME_CREATE_REQUEST'
export const REJECTNAME_CREATE_SUCCESS = 'REJECTNAME_CREATE_SUCCESS'
export const REJECTNAME_CREATE_FAIL = 'REJECTNAME_CREATE_FAIL'
export const REJECTNAME_CREATE_RESET = 'REJECTNAME_CREATE_RESET'

export const REJECTNAME_UPDATE_REQUEST = 'REJECTNAME_UPDATE_REQUEST'
export const REJECTNAME_UPDATE_SUCCESS = 'REJECTNAME_UPDATE_SUCCESS'
export const REJECTNAME_UPDATE_FAIL = 'REJECTNAME_UPDATE_FAIL'
export const REJECTNAME_UPDATE_RESET = 'REJECTNAME_UPDATE_RESET'

export const REJECTNAME_DELETE_REQUEST = 'REJECTNAME_DELETE_REQUEST'
export const REJECTNAME_DELETE_SUCCESS = 'REJECTNAME_DELETE_SUCCESS'
export const REJECTNAME_DELETE_FAIL = 'REJECTNAME_DELETE_FAIL'
export const REJECTNAME_DELETE_RESET = 'REJECTNAME_DELETE_RESET'