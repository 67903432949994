export const ITEM_CREATE_REQUEST = 'ITEM_CREATE_REQUEST'
export const ITEM_CREATE_SUCCESS = 'ITEM_CREATE_SUCCESS'
export const ITEM_CREATE_FAIL = 'ITEM_CREATE_FAIL'
export const ITEM_CREATE_RESET = 'ITEM_CREATE_RESET'

export const ITEM_LIST_REQUEST = 'ITEM_LIST_REQUEST'
export const ITEM_LIST_SUCCESS = 'ITEM_LIST_SUCCESS'
export const ITEM_LIST_FAIL = 'ITEM_LIST_FAIL'
export const ITEM_LIST_RESET = 'ITEM_LIST_RESET'

export const ITEM_EDIT_REQUEST = 'ITEM_EDIT_REQUEST'
export const ITEM_EDIT_SUCCESS = 'ITEM_EDIT_SUCCESS'
export const ITEM_EDIT_FAIL = 'ITEM_EDIT_FAIL'
export const ITEM_EDIT_RESET = 'ITEM_EDIT_RESET'

export const ITEM_DELETE_REQUEST = 'ITEM_DELETE_REQUEST'
export const ITEM_DELETE_SUCCESS = 'ITEM_DELETE_SUCCESS'
export const ITEM_DELETE_FAIL = 'ITEM_DELETE_FAIL'
export const ITEM_DELETE_RESET = 'ITEM_DELETE_RESET'

export const ITEM_BYID_REQUEST = 'ITEM_BYID_REQUEST'
export const ITEM_BYID_SUCCESS = 'ITEM_BYID_SUCCESS'
export const ITEM_BYID_FAIL = 'ITEM_BYID_FAIL'
export const ITEM_BYID_RESET = 'ITEM_BYID_RESET'
