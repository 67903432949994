import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useParams, useLocation } from 'react-router-dom'
// import AddressAddModal from '../Modals/AddressAddModal'
import SearchBox from '../components/SearchBox'
import Pagination from '../components/Pagination'
import {
  HStack,
  Box,
  Spacer,
  Spinner,
  Divider,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
  Heading,
  Stack,
} from '@chakra-ui/react'

import { listItem } from '../actions/itemActions'
import { ITEM_LIST_RESET } from '../constants/itemConstants'
import ItemAddModal from '../Modals/ItemAddModal'

const ItemListScreen = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const keyword = params.keyword || ''
  const pageNumber = params.pageNumber || 1
  const viewQty = [50, 100, 150, 200]
  const [itemCount, setItemCount] = useState(50)
  const {loading, error, items, pages, page} = useSelector(state=>state.item.List)
  const {success: createSuccess} = useSelector(state=>state.item.Create)
  useEffect(()=>{
    dispatch(listItem(pageNumber, itemCount, keyword))
    return()=>{
      dispatch({type: ITEM_LIST_RESET})
    }
  },[dispatch, createSuccess, pageNumber, itemCount, keyword])


  return (
    <Box>
      <Center mb='3'><Heading>Items</Heading></Center>
      <Divider />
        <HStack spacing='4' my='4'>
          <Box>
            {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
          </Box>
          <Box>
            <Select placeholder='Select' onChange={e=>setItemCount(e.target.value)} size='sm'>
              {viewQty.map((v,i)=>(
                <option key={i} value={v}>{v}</option>
              ))}
            </Select>
          </Box>
          <Box>
            <SearchBox />
          </Box>
          <Box>
            <ItemAddModal />
          </Box>
          <Spacer />
          <Box>
            <Pagination keyword = {keyword} pages={pages} page={page} path='item' />
          </Box>
        </HStack>
        
        <Divider />

        {error && 
          <Alert status='error' variant='left-accent'>
          <AlertIcon />
          <AlertTitle mr={2}>Error getting Item list</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
          </Alert>
        }

        <Table size='sm' variant='striped' colorScheme='green' my='4'>
          <Thead>
            <Tr>
              <Th>No</Th>
              <Th>Brand Name</Th>
              <Th>Name</Th>
              <Th>Description</Th>
              {/* <Th>Ingredients/Others</Th> */}
              <Th>HS Code</Th>
              <Th>Manufacturer</Th>
              {/* <Th>Manu,Country</Th> */}
              {/* <Th>Price</Th> */}
              <Th>Options</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items && items.map((item,index)=>(
              <Tr key={index}>
                <Td>{pageNumber === 1 ? (index+1) : (((Number(pageNumber)-1) * itemCount) + (index+1))}</Td>
                <Td>{item.brandName || ''}</Td>
                <Td>{item.name}</Td>
                <Td>{item.description}</Td>
                {/* <Td>{item.ingredients}</Td> */}
                <Td>{item.hsCode ? item.hsCode : '-'}</Td>
                <Td>
                  <Stack spacing={0}>
                    <Box fontWeight={800}>{item.manuName}</Box>
                    <Box>{item.manuAddress}</Box>
                    <Box fontWeight={600}>{item.manuCountry}</Box>
                  </Stack>
                </Td>
                {/* <Td>{item.price}</Td> */}
                <Td>
                  <Link to={`/item/${item._id}`} state={{redirect: location.pathname}}>Edit</Link>       
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
    </Box>
  )
}

export default ItemListScreen