import React, {useEffect} from 'react'
import { Box, Center, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import Error from '../components/Error'
import {useDispatch, useSelector} from 'react-redux'
import { getDHLAccountList, deleteDHLAccount } from '../actions/dhlAccountActions'
import DHLAccountCreateModal from '../Modals/DHLAccountCreateModal'
import DHLAccountEditModal from '../Modals/DHLAccountEditModal'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'


const DHLAccountScreen = () => {
    const dispatch = useDispatch()

    const { loading, accounts, error } = useSelector(state=>state.dhlAccount.List)
    const {success: successAdd} = useSelector(state=>state.dhlAccount.Create)
    const {success: successUpdate, error: errorUpdate} = useSelector(state=>state.dhlAccount.Update)
    const {success: successDelete, error: errorDelete} = useSelector(state=>state.dhlAccount.Delete)

    useEffect(()=>{
        dispatch(getDHLAccountList())
    },[dispatch, successAdd, successUpdate, successDelete])

    return (
        <Center my='4'>
            <Box boxShadow={'lg'} w='700px' p='3'>
                {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                {error && <Error error={error} /> }
                {errorUpdate && <Error error={errorUpdate} /> }
                {errorDelete && <Error error={errorDelete} /> }

                <DHLAccountCreateModal />
                

                <Box>
                  <Table variant='striped' colorScheme='teal' my='4'>
                      <Thead>
                          <Tr>
                          <Th>No</Th>
                          <Th>Account No</Th>
                          <Th>Name</Th>
                          <Th>Edit</Th>
                          <Th>Delete</Th>
                          </Tr>
                      </Thead>
                      <Tbody>
                          {accounts && accounts.map((ac, idx)=>(
                          <Tr key={idx}>
                              <Td>{idx+1}</Td>
                              <Td>{ac.account}</Td>
                              <Td>{ac.name}</Td>
                              <Td><DHLAccountEditModal acc={ac} /></Td>
                              <Td><ConfirmDeleteModal width={'60px'} lefticon={null} id={ac._id} deleteFunction={deleteDHLAccount} /></Td>
                          </Tr>
                          ))}
                      </Tbody>
                  </Table>
                </Box>              
                
            </Box>
        </Center>
    )
}

export default DHLAccountScreen