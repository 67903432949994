
import { useDispatch, useSelector } from 'react-redux'
import { HStack, Box, Spacer, Button, Menu, MenuButton, MenuList, MenuItem, Heading, Flex } from '@chakra-ui/react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { FaUser, FaUserAstronaut, FaUserSecret, FaUserTie } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import {logoutUser} from '../actions/userActions'
import AdminPanel from './AdminPanel'
import SideMenu from './SideMenu'

const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {user} = useSelector(state=> state.login)
  // admin #1A4D2E
  // sale #990000
  // agent #371B58
  // bg={user.isAdmin ? '#1A4D2E' : user.accountType === 'sale' ? '#990000' : user.accountType === 'agent' ? '#371B58' : '#323232'}
  return (
    <>
    {user &&
      <Box bg={'#323232'} h='70px' color='white' className='thing_to_stick' >
        <HStack px={'5%'} py={'2'}>
            
            <Flex color={user && 
              user.accountType === 'sale' ? 'blue.500' : 
              user.accountType === 'agent' ? 'orange' :
              user.accountType === 'user' && 'tomato'
            }>
              <Heading >
                  {user && user.isAdmin ? <Link to='/admindashboard'>SMV2</Link> : 'SMV2'}                  
              </Heading>
              <Box p='2' fontSize={'25px'}>
                  {user && user.accountType === 'user' ? <FaUser /> :
                  user.accountType === 'sale' ? <FaUserAstronaut /> : 
                  user.accountType === 'agent' ? <FaUserTie /> :
                  user.isAdmin && <FaUserSecret />
                  
                  }
                </Box>
              </Flex>
            <Spacer />

            {user.isAdmin && 
              <Box my='2'>
                <AdminPanel />
              </Box>
            }


            {(user.isAdmin || user.accountType === 'user') &&
            <Box display={['none', 'none', 'flex', 'flex']}>
              <Box >
                <Link to='/preshipment'>
                  <Button size='sm' colorScheme='White' >Pre-Ships</Button>
                </Link>
              </Box>

              <Box>
                <Menu >
                  <MenuButton size='sm' as={Button} colorScheme='white' rightIcon={<MdKeyboardArrowDown />}>
                    Shipments
                  </MenuButton>
                  <MenuList>                    
                    <MenuItem color='black' onClick={()=>navigate('/shipment')}>DHL</MenuItem>                    
                    <MenuItem color='black' onClick={()=>navigate('/thailandpost')}>POST</MenuItem>
                    <MenuItem color='black' onClick={()=>navigate('/domesticshipment')}>DOMESTIC</MenuItem>
                  </MenuList>
                </Menu>             
              </Box>
                      
              <Box>
                <Menu >
                  <MenuButton size='sm' as={Button} colorScheme='white' rightIcon={<MdKeyboardArrowDown />}>
                    Scan
                  </MenuButton>
                  <MenuList>                    
                    <MenuItem color='black' onClick={()=>navigate('/dailyscan')}>DHL</MenuItem> 
                    <MenuItem color='black' onClick={()=>navigate('/postscan')}>POST</MenuItem> 
                    <MenuItem color='black' onClick={()=>navigate('/domesticscan')}>DOMESTIC</MenuItem> 
                  </MenuList>
                </Menu>             
              </Box>

              <Box>
                <Menu >
                  <MenuButton size='sm' as={Button} colorScheme='white' rightIcon={<MdKeyboardArrowDown />}>
                    Others
                  </MenuButton>
                  <MenuList>                    
                    <MenuItem color='black' onClick={()=>navigate('/item')}>Items</MenuItem>
                    <MenuItem color='black' onClick={()=>navigate('/status')}>DHL Status</MenuItem> 
                    <MenuItem color='black' onClick={()=>navigate('/shipcount')}>Counts</MenuItem> 
                    <MenuItem color='black' onClick={()=>navigate('/address')}>Addresses</MenuItem> 
                    <MenuItem color='black' onClick={()=>navigate('/vplusreport')}>V Plus</MenuItem> 
                    <MenuItem color='black' onClick={()=>navigate('/preshipmentreport')}>Pre Report</MenuItem> 
                    <MenuItem color='black' onClick={()=>navigate('/user/register')}>Add Agent/Sale</MenuItem> 
                    <MenuItem color='black' onClick={()=>navigate('/user')}>User List</MenuItem> 
                    <MenuItem color='black' onClick={()=>navigate('/dhlaccount')}>DHL Account</MenuItem> 
                    <MenuItem color='black' onClick={()=>navigate('/rejectname')}>Reject Names</MenuItem> 
                    <MenuItem color='black' onClick={()=>navigate('/remotearea')}>Remote Area List</MenuItem> 
                  </MenuList>
                </Menu>             
              </Box>
            </Box>            
            }


            
              <Box>                
                <Menu>
                  <MenuButton as={Button} size='sm' colorScheme='white' rightIcon={<MdKeyboardArrowDown />}>
                  {user.name}
                  </MenuButton>
                  <MenuList>
                    {user.accountType !== 'agent' && 
                      <MenuItem color='black' onClick={()=>navigate('/myprofile')}>My Profile</MenuItem>
                    }   
                    {user.accountType === 'sale' && 
                    <MenuItem color='black' onClick={()=>navigate('/report')}>Daily Report</MenuItem>   
                    }
                    {user.accountType === 'agent' && 
                    <>
                    <MenuItem color='black' onClick={()=>navigate('/agentreport')}>Daily Report</MenuItem>   
                    <MenuItem color='black' onClick={()=>navigate('/mybalancelog')}>Balance Log</MenuItem>   
                    </>
                    }   
                              
                    <MenuItem color='black' onClick={()=>dispatch(logoutUser())}>
                        Logout
                    </MenuItem>
                  </MenuList>
                </Menu>             
              </Box>

            {(user.isAdmin || user.accountType === 'user') &&
              <Box display={['flex', 'flex', 'none', 'none']}>
                  <SideMenu /> 
              </Box>
            }
           
          </HStack>
      </Box> 
    }
  </>
  )
}

export default Header