const zones = {
    AF:"zone9",
AL:"zone8",
DZ:"zone9",
AS:"zone9",
AD:"zone7",
AO:"zone9",
AI:"zone9",
AG:"zone9",
AR:"zone9",
AM:"zone8",
AW:"zone9",
AU:"zone3",
AT:"zone7",
AZ:"zone8",
BS:"zone9",
BH:"zone8",
BD:"zone5",
BB:"zone9",
BY:"zone9",
BE:"zone7",
BZ:"zone9",
BJ:"zone9",
BM:"zone9",
BT:"zone5",
BO:"zone9",
XB:"zone9",
BA:"zone8",
BW:"zone9",
BR:"zone9",
BN:"zone3",
BG:"zone7",
BF:"zone9",
BI:"zone9",
KH:"zone3",
CM:"zone9",
CA:"zone6",
IC:"zone8",
CV:"zone9",
KY:"zone9",
CF:"zone9",
TD:"zone9",
CL:"zone9",
CN:"zone2",
CO:"zone9",
KM:"zone9",
CG:"zone9",
CD:"zone9",
CK:"zone9",
CR:"zone9",
CI:"zone9",
HR:"zone7",
CU:"zone9",
XC:"zone9",
CY:"zone7",
CZ:"zone7",
DK:"zone7",
DJ:"zone9",
DM:"zone9",
DO:"zone9",
EC:"zone9",
EG:"zone9",
SV:"zone9",
ER:"zone9",
EE:"zone7",
SZ:"zone9",
ET:"zone9",
FK:"zone9",
FO:"zone9",
FJ:"zone9",
FI:"zone7",
FR:"zone7",
GF:"zone9",
GA:"zone9",
GM:"zone9",
GE:"zone9",
DE:"zone7",
GH:"zone9",
GI:"zone8",
GR:"zone7",
GL:"zone9",
GD:"zone9",
GP:"zone9",
GU:"zone9",
GT:"zone9",
GG:"zone8",
GN:"zone9",
GW:"zone9",
GQ:"zone9",
GY:"zone9",
HT:"zone9",
HN:"zone9",
HK:"zone1",
HU:"zone7",
IS:"zone9",
IN:"zone5",
ID:"zone3",
IR:"zone9",
IQ:"zone9",
IE:"zone7",
IL:"zone8",
IT:"zone7",
JM:"zone9",
JP:"zone4",
JE:"zone8",
JO:"zone8",
KZ:"zone9",
KE:"zone9",
KI:"zone9",
KR:"zone4",
KP:"zone9",
KV:"zone8",
KW:"zone8",
KG:"zone9",
LA:"zone3",
LV:"zone7",
LB:"zone9",
LS:"zone9",
LR:"zone9",
LY:"zone9",
LI:"zone7",
LT:"zone7",
LU:"zone7",
MO:"zone1",
MG:"zone9",
MW:"zone9",
MY:"zone1",
MV:"zone9",
ML:"zone9",
MT:"zone7",
MP:"zone9",
MH:"zone9",
MQ:"zone9",
MR:"zone9",
MU:"zone9",
YT:"zone9",
MX:"zone6",
FM:"zone9",
MD:"zone9",
MC:"zone7",
MN:"zone9",
ME:"zone8",
MS:"zone9",
MA:"zone9",
MZ:"zone9",
MM:"zone3",
NA:"zone9",
NR:"zone9",
NP:"zone5",
NL:"zone7",
XN:"zone9",
NC:"zone9",
NZ:"zone3",
NI:"zone9",
NE:"zone9",
NG:"zone9",
NU:"zone9",
MK:"zone8",
NO:"zone8",
OM:"zone8",
PK:"zone5",
PW:"zone9",
PA:"zone9",
PG:"zone5",
PY:"zone9",
PE:"zone9",
PH:"zone3",
PL:"zone7",
PT:"zone7",
PR:"zone9",
QA:"zone8",
RE:"zone9",
RO:"zone7",
RU:"zone9",
RW:"zone9",
SH:"zone9",
WS:"zone9",
SM:"zone7",
ST:"zone9",
SA:"zone8",
SN:"zone9",
RS:"zone8",
SC:"zone9",
SL:"zone9",
SG:"zone1",
SK:"zone7",
SI:"zone7",
SB:"zone9",
SO:"zone9",
XS:"zone9",
ZA:"zone8",
SS:"zone9",
ES:"zone7",
LK:"zone5",
XY:"zone9",
XE:"zone9",
KN:"zone9",
LC:"zone9",
XM:"zone9",
VC:"zone9",
SD:"zone9",
SR:"zone9",
SE:"zone7",
CH:"zone7",
SY:"zone9",
PF:"zone9",
TW:"zone3",
TJ:"zone9",
TZ:"zone9",
TL:"zone9",
TG:"zone9",
TO:"zone9",
TT:"zone9",
TN:"zone9",
TR:"zone8",
TM:"zone9",
TC:"zone9",
TV:"zone9",
US:"zone6",
UG:"zone9",
UA:"zone9",
AE:"zone8",
GB:"zone7",
UY:"zone9",
UZ:"zone9",
VU:"zone9",
VA:"zone7",
VE:"zone9",
VN:"zone3",
VG:"zone9",
VI:"zone9",
YE:"zone9",
ZM:"zone9",
ZW:"zone9",
}

const countryNames = {
    TH:"Thailand",
    AF:"Afghanistan ",
AL:"Albania ",
DZ:"Algeria ",
AS:"American Samoa ",
AD:"Andorra ",
AO:"Angola ",
AI:"Anguilla ",
AG:"Antigua ",
AR:"Argentina ",
AM:"Armenia ",
AW:"Aruba ",
AU:"Australia ",
AT:"Austria ",
AZ:"Azerbaijan ",
BS:"Bahamas ",
BH:"Bahrain ",
BD:"Bangladesh ",
BB:"Barbados ",
BY:"Belarus ",
BE:"Belgium ",
BZ:"Belize ",
BJ:"Benin ",
BM:"Bermuda ",
BT:"Bhutan ",
BO:"Bolivia ",
XB:"Bonaire ",
BA:"Bosnia & Herzegovina",
BW:"Botswana ",
BR:"Brazil ",
BN:"Brunei ",
BG:"Bulgaria ",
BF:"Burkina Faso ",
BI:"Burundi ",
KH:"Cambodia ",
CM:"Cameroon ",
CA:"Canada ",
IC:"Canary Islands",
CV:"Cape Verde ",
KY:"Cayman Islands ",
CF:"Central African Rep",
TD:"Chad ",
CL:"Chile ",
CN:"China ",
CO:"Colombia ",
KM:"Comoros ",
CG:"Congo ",
CD:"Congo, DPR ",
CK:"Cook Islands ",
CR:"Costa Rica ",
CI:"Cote D Ivoire ",
HR:"Croatia ",
CU:"Cuba ",
XC:"Curacao ",
CY:"Cyprus ",
CZ:"Czech Rep",
DK:"Denmark ",
DJ:"Djibouti ",
DM:"Dominica ",
DO:"Dominican Rep. ",
EC:"Ecuador ",
EG:"Egypt ",
SV:"El Salvador ",
ER:"Eritrea ",
EE:"Estonia ",
SZ:"Eswatini ",
ET:"Ethiopia ",
FK:"Falkland Islands ",
FO:"Faroe Islands ",
FJ:"Fiji ",
FI:"Finland ",
FR:"France ",
GF:"French Guyana ",
GA:"Gabon ",
GM:"Gambia ",
GE:"Georgia ",
DE:"Germany ",
GH:"Ghana ",
GI:"Gibraltar ",
GR:"Greece ",
GL:"Greenland ",
GD:"Grenada ",
GP:"Guadeloupe ",
GU:"Guam ",
GT:"Guatemala ",
GG:"Guernsey ",
GN:"Guinea Rep. ",
GW:"Guinea-Bissau ",
GQ:"Guinea-Equatorial ",
GY:"Guyana (British)",
HT:"Haiti ",
HN:"Honduras ",
HK:"Hong Kong SAR China ",
HU:"Hungary ",
IS:"Iceland ",
IN:"India ",
ID:"Indonesia ",
IR:"Iran ",
IQ:"Iraq ",
IE:"Ireland, Rep",
IL:"Israel ",
IT:"Italy ",
JM:"Jamaica ",
JP:"Japan ",
JE:"Jersey ",
JO:"Jordan ",
KZ:"Kazakhstan ",
KE:"Kenya ",
KI:"Kiribati ",
KR:"Korea",
KP:"Korea,  D.P.R",
KV:"Kosovo ",
KW:"Kuwait ",
KG:"Kyrgyzstan ",
LA:"Laos ",
LV:"Latvia ",
LB:"Lebanon ",
LS:"Lesotho ",
LR:"Liberia ",
LY:"Libya ",
LI:"Liechtenstein ",
LT:"Lithuania ",
LU:"Luxembourg ",
MO:"Macau SAR China ",
MG:"Madagascar ",
MW:"Malawi ",
MY:"Malaysia ",
MV:"Maldives ",
ML:"Mali ",
MT:"Malta ",
MP:"Mariana Islands ",
MH:"Marshall Islands ",
MQ:"Martinique ",
MR:"Mauritania ",
MU:"Mauritius ",
YT:"Mayotte ",
MX:"Mexico ",
FM:"Micronesia ",
MD:"Moldova, Rep",
MC:"Monaco ",
MN:"Mongolia ",
ME:"Montenegro, Rep",
MS:"Montserrat ",
MA:"Morocco ",
MZ:"Mozambique ",
MM:"Myanmar ",
NA:"Namibia ",
NR:"Nauru, Rep. Of ",
NP:"Nepal ",
NL:"Netherlands",
XN:"Nevis ",
NC:"New Caledonia ",
NZ:"New Zealand ",
NI:"Nicaragua ",
NE:"Niger ",
NG:"Nigeria ",
NU:"Niue ",
MK:"North Macedonia ",
NO:"Norway ",
OM:"Oman ",
PK:"Pakistan ",
PW:"Palau ",
PA:"Panama ",
PG:"Papua New Guinea ",
PY:"Paraguay ",
PE:"Peru ",
PH:"Philippines, The ",
PL:"Poland ",
PT:"Portugal ",
PR:"Puerto Rico ",
QA:"Qatar ",
RE:"Reunion, Island",
RO:"Romania ",
RU:"Russian Federation ",
RW:"Rwanda ",
SH:"Saint Helena ",
WS:"Samoa ",
SM:"San Marino ",
ST:"Sao Tome And Principe ",
SA:"Saudi Arabia ",
SN:"Senegal ",
RS:"Serbia, Rep",
SC:"Seychelles ",
SL:"Sierra Leone ",
SG:"Singapore ",
SK:"Slovakia ",
SI:"Slovenia ",
SB:"Solomon Islands ",
SO:"Somalia ",
XS:"Somaliland, Rep",
ZA:"South Africa ",
SS:"South Sudan ",
ES:"Spain ",
LK:"Sri Lanka ",
XY:"St. Barthelemy ",
XE:"St. Eustatius ",
KN:"St. Kitts ",
LC:"St. Lucia ",
XM:"St. Maarten ",
VC:"St. Vincent ",
SD:"Sudan ",
SR:"Suriname ",
SE:"Sweden ",
CH:"Switzerland ",
SY:"Syria ",
PF:"Tahiti ",
TW:"Taiwan ",
TJ:"Tajikistan ",
TZ:"Tanzania ",
TL:"Timor-Leste ",
TG:"Togo ",
TO:"Tonga ",
TT:"Trinidad And Tobago ",
TN:"Tunisia ",
TR:"Turkey ",
TM:"Turkmenistan ",
TC:"Turks & Caicos ",
TV:"Tuvalu ",
US:"USA ",
UG:"Uganda ",
UA:"Ukraine ",
AE:"United Arab Emirates ",
GB:"United Kingdom ",
UY:"Uruguay ",
UZ:"Uzbekistan ",
VU:"Vanuatu ",
VA:"Vatican City ",
VE:"Venezuela ",
VN:"Vietnam ",
VG:"Virgin Islands-British ",
VI:"Virgin Islands-US ",
YE:"Yemen, Rep",
ZM:"Zambia ",
ZW:"Zimbabwe "
}

const zonePrint = [
{country: "Afghanistan (AF)",zone: "9"},
{country: "Albania (AL)",zone: "8"},
{country: "Algeria (DZ)",zone: "9"},
{country: "American Samoa (AS)",zone: "9"},
{country: "Andorra (AD)",zone: "7"},
{country: "Angola (AO)",zone: "9"},
{country: "Anguilla (AI)",zone: "9"},
{country: "Antigua (AG)",zone: "9"},
{country: "Argentina (AR)",zone: "9"},
{country: "Armenia (AM)",zone: "8"},
{country: "Aruba (AW)",zone: "9"},
{country: "Australia (AU)",zone: "3"},
{country: "Austria (AT)",zone: "7"},
{country: "Azerbaijan (AZ)",zone: "8"},
{country: "Bahamas (BS)",zone: "9"},
{country: "Bahrain (BH)",zone: "8"},
{country: "Bangladesh (BD)",zone: "5"},
{country: "Barbados (BB)",zone: "9"},
{country: "Belarus (BY)",zone: "9"},
{country: "Belgium (BE)",zone: "7"},
{country: "Belize (BZ)",zone: "9"},
{country: "Benin (BJ)",zone: "9"},
{country: "Bermuda (BM)",zone: "9"},
{country: "Bhutan (BT)",zone: "5"},
{country: "Bolivia (BO)",zone: "9"},
{country: "Bonaire (XB)",zone: "9"},
{country: "Bosnia & Herzegovina(BA)",zone: "8"},
{country: "Botswana (BW)",zone: "9"},
{country: "Brazil (BR)",zone: "9"},
{country: "Brunei (BN)",zone: "3"},
{country: "Bulgaria (BG)",zone: "7"},
{country: "Burkina Faso (BF)",zone: "9"},
{country: "Burundi (BI)",zone: "9"},
{country: "Cambodia (KH)",zone: "3"},
{country: "Cameroon (CM)",zone: "9"},
{country: "Canada (CA)",zone: "6"},
{country: "Canary Islands, The (IC)",zone: "8"},
{country: "Cape Verde (CV)",zone: "9"},
{country: "Cayman Islands (KY)",zone: "9"},
{country: "Central African Rep(CF)",zone: "9"},
{country: "Chad (TD)",zone: "9"},
{country: "Chile (CL)",zone: "9"},
{country: "China (CN) *1",zone: "2"},
{country: "China (CN) *2",zone: "3"},
{country: "Colombia (CO)",zone: "9"},
{country: "Comoros (KM)",zone: "9"},
{country: "Congo (CG)",zone: "9"},
{country: "Congo, DPR (CD)",zone: "9"},
{country: "Cook Islands (CK)",zone: "9"},
{country: "Costa Rica (CR)",zone: "9"},
{country: "Cote D Ivoire (CI)",zone: "9"},
{country: "Croatia (HR)",zone: "7"},
{country: "Cuba (CU)",zone: "9"},
{country: "Curacao (XC)",zone: "9"},
{country: "Cyprus (CY)",zone: "7"},
{country: "Czech Rep., The (CZ)",zone: "7"},
{country: "Denmark (DK)",zone: "7"},
{country: "Djibouti (DJ)",zone: "9"},
{country: "Dominica (DM)",zone: "9"},
{country: "Dominican Rep. (DO)",zone: "9"},
{country: "Ecuador (EC)",zone: "9"},
{country: "Egypt (EG)",zone: "9"},
{country: "El Salvador (SV)",zone: "9"},
{country: "Eritrea (ER)",zone: "9"},
{country: "Estonia (EE)",zone: "7"},
{country: "Eswatini (SZ)",zone: "9"},
{country: "Ethiopia (ET)",zone: "9"},
{country: "Falkland Islands (FK)",zone: "9"},
{country: "Faroe Islands (FO)",zone: "9"},
{country: "Fiji (FJ)",zone: "9"},
{country: "Finland (FI)",zone: "7"},
{country: "France (FR)",zone: "7"},
{country: "French Guyana (GF)",zone: "9"},
{country: "Gabon (GA)",zone: "9"},
{country: "Gambia (GM)",zone: "9"},
{country: "Georgia (GE)",zone: "9"},
{country: "Germany (DE)",zone: "7"},
{country: "Ghana (GH)",zone: "9"},
{country: "Gibraltar (GI)",zone: "8"},
{country: "Greece (GR)",zone: "7"},
{country: "Greenland (GL)",zone: "9"},
{country: "Grenada (GD)",zone: "9"},
{country: "Guadeloupe (GP)",zone: "9"},
{country: "Guam (GU)",zone: "9"},
{country: "Guatemala (GT)",zone: "9"},
{country: "Guernsey (GG)",zone: "8"},
{country: "Guinea Rep. (GN)",zone: "9"},
{country: "Guinea-Bissau (GW)",zone: "9"},
{country: "Guinea-Equatorial (GQ)",zone: "9"},
{country: "Guyana (British) (GY)",zone: "9"},
{country: "Haiti (HT)",zone: "9"},
{country: "Honduras (HN)",zone: "9"},
{country: "Hong Kong SAR China (HK)",zone: "1"},
{country: "Hungary (HU)",zone: "7"},
{country: "Iceland (IS)",zone: "9"},
{country: "India (IN)",zone: "5"},
{country: "Indonesia (ID)",zone: "3"},
{country: "Iran (IR)",zone: "9"},
{country: "Iraq (IQ)",zone: "9"},
{country: "Ireland, Rep. Of (IE)",zone: "7"},
{country: "Israel (IL)",zone: "8"},
{country: "Italy (IT)",zone: "7"},
{country: "Jamaica (JM)",zone: "9"},
{country: "Japan (JP)",zone: "4"},
{country: "Jersey (JE)",zone: "8"},
{country: "Jordan (JO)",zone: "8"},
{country: "Kazakhstan (KZ)",zone: "9"},
{country: "Kenya (KE)",zone: "9"},
{country: "Kiribati (KI)",zone: "9"},
{country: "Korea, Rep. Of (KR)",zone: "4"},
{country: "Korea,  D.P.R Of (KP)",zone: "9"},
{country: "Kosovo (KV)",zone: "8"},
{country: "Kuwait (KW)",zone: "8"},
{country: "Kyrgyzstan (KG)",zone: "9"},
{country: "Laos (LA)",zone: "3"},
{country: "Latvia (LV)",zone: "7"},
{country: "Lebanon (LB)",zone: "9"},
{country: "Lesotho (LS)",zone: "9"},
{country: "Liberia (LR)",zone: "9"},
{country: "Libya (LY)",zone: "9"},
{country: "Liechtenstein (LI)",zone: "7"},
{country: "Lithuania (LT)",zone: "7"},
{country: "Luxembourg (LU)",zone: "7"},
{country: "Macau SAR China (MO)",zone: "1"},
{country: "Madagascar (MG)",zone: "9"},
{country: "Malawi (MW)",zone: "9"},
{country: "Malaysia (MY)",zone: "1"},
{country: "Maldives (MV)",zone: "9"},
{country: "Mali (ML)",zone: "9"},
{country: "Malta (MT)",zone: "7"},
{country: "Mariana Islands (MP)",zone: "9"},
{country: "Marshall Islands (MH)",zone: "9"},
{country: "Martinique (MQ)",zone: "9"},
{country: "Mauritania (MR)",zone: "9"},
{country: "Mauritius (MU)",zone: "9"},
{country: "Mayotte (YT)",zone: "9"},
{country: "Mexico (MX)",zone: "6"},
{country: "Micronesia (FM)",zone: "9"},
{country: "Moldova, Rep. Of (MD)",zone: "9"},
{country: "Monaco (MC)",zone: "7"},
{country: "Mongolia (MN)",zone: "9"},
{country: "Montenegro, Rep Of (ME)",zone: "8"},
{country: "Montserrat (MS)",zone: "9"},
{country: "Morocco (MA)",zone: "9"},
{country: "Mozambique (MZ)",zone: "9"},
{country: "Myanmar (MM)",zone: "3"},
{country: "Namibia (NA)",zone: "9"},
{country: "Nauru, Rep. Of (NR)",zone: "9"},
{country: "Nepal (NP)",zone: "5"},
{country: "Netherlands, The (NL)",zone: "7"},
{country: "Nevis (XN)",zone: "9"},
{country: "New Caledonia (NC)",zone: "9"},
{country: "New Zealand (NZ)",zone: "3"},
{country: "Nicaragua (NI)",zone: "9"},
{country: "Niger (NE)",zone: "9"},
{country: "Nigeria (NG)",zone: "9"},
{country: "Niue (NU)",zone: "9"},
{country: "North Macedonia (MK)",zone: "8"},
{country: "Norway (NO)",zone: "8"},
{country: "Oman (OM)",zone: "8"},
{country: "Pakistan (PK)",zone: "5"},
{country: "Palau (PW)",zone: "9"},
{country: "Panama (PA)",zone: "9"},
{country: "Papua New Guinea (PG)",zone: "5"},
{country: "Paraguay (PY)",zone: "9"},
{country: "Peru (PE)",zone: "9"},
{country: "Philippines, The (PH)",zone: "3"},
{country: "Poland (PL)",zone: "7"},
{country: "Portugal (PT)",zone: "7"},
{country: "Puerto Rico (PR)",zone: "9"},
{country: "Qatar (QA)",zone: "8"},
{country: "Reunion, Island Of (RE)",zone: "9"},
{country: "Romania (RO)",zone: "7"},
{country: "Russian Federation (RU)",zone: "9"},
{country: "Rwanda (RW)",zone: "9"},
{country: "Saint Helena (SH)",zone: "9"},
{country: "Samoa (WS)",zone: "9"},
{country: "San Marino (SM)",zone: "7"},
{country: "Sao Tome And Principe (ST)",zone: "9"},
{country: "Saudi Arabia (SA)",zone: "8"},
{country: "Senegal (SN)",zone: "9"},
{country: "Serbia, Rep. Of (RS)",zone: "8"},
{country: "Seychelles (SC)",zone: "9"},
{country: "Sierra Leone (SL)",zone: "9"},
{country: "Singapore (SG)",zone: "1"},
{country: "Slovakia (SK)",zone: "7"},
{country: "Slovenia (SI)",zone: "7"},
{country: "Solomon Islands (SB)",zone: "9"},
{country: "Somalia (SO)",zone: "9"},
{country: "Somaliland, Rep Of (XS)",zone: "9"},
{country: "South Africa (ZA)",zone: "8"},
{country: "South Sudan (SS)",zone: "9"},
{country: "Spain (ES)",zone: "7"},
{country: "Sri Lanka (LK)",zone: "5"},
{country: "St. Barthelemy (XY)",zone: "9"},
{country: "St. Eustatius (XE)",zone: "9"},
{country: "St. Kitts (KN)",zone: "9"},
{country: "St. Lucia (LC)",zone: "9"},
{country: "St. Maarten (XM)",zone: "9"},
{country: "St. Vincent (VC)",zone: "9"},
{country: "Sudan (SD)",zone: "9"},
{country: "Suriname (SR)",zone: "9"},
{country: "Sweden (SE)",zone: "7"},
{country: "Switzerland (CH)",zone: "7"},
{country: "Syria (SY)",zone: "9"},
{country: "Tahiti (PF)",zone: "9"},
{country: "Taiwan (TW)",zone: "3"},
{country: "Tajikistan (TJ)",zone: "9"},
{country: "Tanzania (TZ)",zone: "9"},
{country: "Timor-Leste (TL)",zone: "9"},
{country: "Togo (TG)",zone: "9"},
{country: "Tonga (TO)",zone: "9"},
{country: "Trinidad And Tobago (TT)",zone: "9"},
{country: "Tunisia (TN)",zone: "9"},
{country: "Turkey (TR)",zone: "8"},
{country: "Turkmenistan (TM)",zone: "9"},
{country: "Turks & Caicos (TC)",zone: "9"},
{country: "Tuvalu (TV)",zone: "9"},
{country: "United States of America (US)",zone: "6"},
{country: "Uganda (UG)",zone: "9"},
{country: "Ukraine (UA)",zone: "9"},
{country: "United Arab Emirates (AE)",zone: "8"},
{country: "United Kingdom (GB)",zone: "7"},
{country: "Uruguay (UY)",zone: "9"},
{country: "Uzbekistan (UZ)",zone: "9"},
{country: "Vanuatu (VU)",zone: "9"},
{country: "Vatican City (VA)",zone: "7"},
{country: "Venezuela (VE)",zone: "9"},
{country: "Vietnam (VN)",zone: "3"},
{country: "Virgin Islands-British (VG)",zone: "9"},
{country: "Virgin Islands-US (VI)",zone: "9"},
{country: "Yemen, Rep. Of (YE)",zone: "9"},
{country: "Zambia (ZM)",zone: "9"},
{country: "Zimbabwe (ZW)",zone: "9"}

]

const allCountryList = [
{name:"Thailand",countryCode:"TH"},
{name:"Afghanistan",countryCode:"AF"},
{name:"Albania",countryCode:"AL"},
{name:"Algeria",countryCode:"DZ"},
{name:"American Samoa",countryCode:"AS"},
{name:"Andorra",countryCode:"AD"},
{name:"Angola",countryCode:"AO"},
{name:"Anguilla",countryCode:"AI"},
{name:"Antigua",countryCode:"AG"},
{name:"Argentina",countryCode:"AR"},
{name:"Armenia",countryCode:"AM"},
{name:"Aruba",countryCode:"AW"},
{name:"Australia",countryCode:"AU"},
{name:"Austria",countryCode:"AT"},
{name:"Azerbaijan",countryCode:"AZ"},
{name:"Bahamas",countryCode:"BS"},
{name:"Bahrain",countryCode:"BH"},
{name:"Bangladesh",countryCode:"BD"},
{name:"Barbados",countryCode:"BB"},
{name:"Belarus",countryCode:"BY"},
{name:"Belgium",countryCode:"BE"},
{name:"Belize",countryCode:"BZ"},
{name:"Benin",countryCode:"BJ"},
{name:"Bermuda",countryCode:"BM"},
{name:"Bhutan",countryCode:"BT"},
{name:"Bolivia",countryCode:"BO"},
{name:"Bonaire",countryCode:"XB"},
{name:"Bosnia & Herzegovina",countryCode:"BA"},
{name:"Botswana",countryCode:"BW"},
{name:"Brazil",countryCode:"BR"},
{name:"Brunei",countryCode:"BN"},
{name:"Bulgaria",countryCode:"BG"},
{name:"Burkina Faso",countryCode:"BF"},
{name:"Burundi",countryCode:"BI"},
{name:"Cambodia",countryCode:"KH"},
{name:"Cameroon",countryCode:"CM"},
{name:"Canada",countryCode:"CA"},
{name:"Canary Islands",countryCode:"IC"},
{name:"Cape Verde",countryCode:"CV"},
{name:"Cayman Islands",countryCode:"KY"},
{name:"Central African Rep",countryCode:"CF"},
{name:"Chad",countryCode:"TD"},
{name:"Chile",countryCode:"CL"},
{name:"China",countryCode:"CN"},
{name:"Colombia",countryCode:"CO"},
{name:"Comoros",countryCode:"KM"},
{name:"Congo",countryCode:"CG"},
{name:"Congo, DPR",countryCode:"CD"},
{name:"Cook Islands",countryCode:"CK"},
{name:"Costa Rica",countryCode:"CR"},
{name:"Cote D Ivoire",countryCode:"CI"},
{name:"Croatia",countryCode:"HR"},
{name:"Cuba",countryCode:"CU"},
{name:"Curacao",countryCode:"XC"},
{name:"Cyprus",countryCode:"CY"},
{name:"Czech Rep",countryCode:"CZ"},
{name:"Denmark",countryCode:"DK"},
{name:"Djibouti",countryCode:"DJ"},
{name:"Dominica",countryCode:"DM"},
{name:"Dominican Rep.",countryCode:"DO"},
{name:"Ecuador",countryCode:"EC"},
{name:"Egypt",countryCode:"EG"},
{name:"El Salvador",countryCode:"SV"},
{name:"Eritrea",countryCode:"ER"},
{name:"Estonia",countryCode:"EE"},
{name:"Eswatini",countryCode:"SZ"},
{name:"Ethiopia",countryCode:"ET"},
{name:"Falkland Islands",countryCode:"FK"},
{name:"Faroe Islands",countryCode:"FO"},
{name:"Fiji",countryCode:"FJ"},
{name:"Finland",countryCode:"FI"},
{name:"France",countryCode:"FR"},
{name:"French Guyana",countryCode:"GF"},
{name:"Gabon",countryCode:"GA"},
{name:"Gambia",countryCode:"GM"},
{name:"Georgia",countryCode:"GE"},
{name:"Germany",countryCode:"DE"},
{name:"Ghana",countryCode:"GH"},
{name:"Gibraltar",countryCode:"GI"},
{name:"Greece",countryCode:"GR"},
{name:"Greenland",countryCode:"GL"},
{name:"Grenada",countryCode:"GD"},
{name:"Guadeloupe",countryCode:"GP"},
{name:"Guam",countryCode:"GU"},
{name:"Guatemala",countryCode:"GT"},
{name:"Guernsey",countryCode:"GG"},
{name:"Guinea Rep.",countryCode:"GN"},
{name:"Guinea-Bissau",countryCode:"GW"},
{name:"Guinea-Equatorial",countryCode:"GQ"},
{name:"Guyana (British)",countryCode:"GY"},
{name:"Haiti",countryCode:"HT"},
{name:"Honduras",countryCode:"HN"},
{name:"Hong Kong SAR China",countryCode:"HK"},
{name:"Hungary",countryCode:"HU"},
{name:"Iceland",countryCode:"IS"},
{name:"India",countryCode:"IN"},
{name:"Indonesia",countryCode:"ID"},
{name:"Iran",countryCode:"IR"},
{name:"Iraq",countryCode:"IQ"},
{name:"Ireland, Rep",countryCode:"IE"},
{name:"Israel",countryCode:"IL"},
{name:"Italy",countryCode:"IT"},
{name:"Jamaica",countryCode:"JM"},
{name:"Japan",countryCode:"JP"},
{name:"Jersey",countryCode:"JE"},
{name:"Jordan",countryCode:"JO"},
{name:"Kazakhstan",countryCode:"KZ"},
{name:"Kenya",countryCode:"KE"},
{name:"Kiribati",countryCode:"KI"},
{name:"Korea",countryCode:"KR"},
{name:"Korea,  D.P.R",countryCode:"KP"},
{name:"Kosovo",countryCode:"KV"},
{name:"Kuwait",countryCode:"KW"},
{name:"Kyrgyzstan",countryCode:"KG"},
{name:"Laos",countryCode:"LA"},
{name:"Latvia",countryCode:"LV"},
{name:"Lebanon",countryCode:"LB"},
{name:"Lesotho",countryCode:"LS"},
{name:"Liberia",countryCode:"LR"},
{name:"Libya",countryCode:"LY"},
{name:"Liechtenstein",countryCode:"LI"},
{name:"Lithuania",countryCode:"LT"},
{name:"Luxembourg",countryCode:"LU"},
{name:"Macau SAR China",countryCode:"MO"},
{name:"Madagascar",countryCode:"MG"},
{name:"Malawi",countryCode:"MW"},
{name:"Malaysia",countryCode:"MY"},
{name:"Maldives",countryCode:"MV"},
{name:"Mali",countryCode:"ML"},
{name:"Malta",countryCode:"MT"},
{name:"Mariana Islands",countryCode:"MP"},
{name:"Marshall Islands",countryCode:"MH"},
{name:"Martinique",countryCode:"MQ"},
{name:"Mauritania",countryCode:"MR"},
{name:"Mauritius",countryCode:"MU"},
{name:"Mayotte",countryCode:"YT"},
{name:"Mexico",countryCode:"MX"},
{name:"Micronesia",countryCode:"FM"},
{name:"Moldova, Rep",countryCode:"MD"},
{name:"Monaco",countryCode:"MC"},
{name:"Mongolia",countryCode:"MN"},
{name:"Montenegro, Rep",countryCode:"ME"},
{name:"Montserrat",countryCode:"MS"},
{name:"Morocco",countryCode:"MA"},
{name:"Mozambique",countryCode:"MZ"},
{name:"Myanmar",countryCode:"MM"},
{name:"Namibia",countryCode:"NA"},
{name:"Nauru, Rep. Of",countryCode:"NR"},
{name:"Nepal",countryCode:"NP"},
{name:"Netherlands",countryCode:"NL"},
{name:"Nevis",countryCode:"XN"},
{name:"New Caledonia",countryCode:"NC"},
{name:"New Zealand",countryCode:"NZ"},
{name:"Nicaragua",countryCode:"NI"},
{name:"Niger",countryCode:"NE"},
{name:"Nigeria",countryCode:"NG"},
{name:"Niue",countryCode:"NU"},
{name:"North Macedonia",countryCode:"MK"},
{name:"Norway",countryCode:"NO"},
{name:"Oman",countryCode:"OM"},
{name:"Pakistan",countryCode:"PK"},
{name:"Palau",countryCode:"PW"},
{name:"Panama",countryCode:"PA"},
{name:"Papua New Guinea",countryCode:"PG"},
{name:"Paraguay",countryCode:"PY"},
{name:"Peru",countryCode:"PE"},
{name:"Philippines, The",countryCode:"PH"},
{name:"Poland",countryCode:"PL"},
{name:"Portugal",countryCode:"PT"},
{name:"Puerto Rico",countryCode:"PR"},
{name:"Qatar",countryCode:"QA"},
{name:"Reunion, Island",countryCode:"RE"},
{name:"Romania",countryCode:"RO"},
{name:"Russian Federation",countryCode:"RU"},
{name:"Rwanda",countryCode:"RW"},
{name:"Saint Helena",countryCode:"SH"},
{name:"Samoa",countryCode:"WS"},
{name:"San Marino",countryCode:"SM"},
{name:"Sao Tome And Principe",countryCode:"ST"},
{name:"Saudi Arabia",countryCode:"SA"},
{name:"Senegal",countryCode:"SN"},
{name:"Serbia, Rep",countryCode:"RS"},
{name:"Seychelles",countryCode:"SC"},
{name:"Sierra Leone",countryCode:"SL"},
{name:"Singapore",countryCode:"SG"},
{name:"Slovakia",countryCode:"SK"},
{name:"Slovenia",countryCode:"SI"},
{name:"Solomon Islands",countryCode:"SB"},
{name:"Somalia",countryCode:"SO"},
{name:"Somaliland, Rep",countryCode:"XS"},
{name:"South Africa",countryCode:"ZA"},
{name:"South Sudan",countryCode:"SS"},
{name:"Spain",countryCode:"ES"},
{name:"Sri Lanka",countryCode:"LK"},
{name:"St. Barthelemy",countryCode:"XY"},
{name:"St. Eustatius",countryCode:"XE"},
{name:"St. Kitts",countryCode:"KN"},
{name:"St. Lucia",countryCode:"LC"},
{name:"St. Maarten",countryCode:"XM"},
{name:"St. Vincent",countryCode:"VC"},
{name:"Sudan",countryCode:"SD"},
{name:"Suriname",countryCode:"SR"},
{name:"Sweden",countryCode:"SE"},
{name:"Switzerland",countryCode:"CH"},
{name:"Syria",countryCode:"SY"},
{name:"Tahiti",countryCode:"PF"},
{name:"Taiwan",countryCode:"TW"},
{name:"Tajikistan",countryCode:"TJ"},
{name:"Tanzania",countryCode:"TZ"},
{name:"Timor-Leste",countryCode:"TL"},
{name:"Togo",countryCode:"TG"},
{name:"Tonga",countryCode:"TO"},
{name:"Trinidad And Tobago",countryCode:"TT"},
{name:"Tunisia",countryCode:"TN"},
{name:"Turkey",countryCode:"TR"},
{name:"Turkmenistan",countryCode:"TM"},
{name:"Turks & Caicos",countryCode:"TC"},
{name:"Tuvalu",countryCode:"TV"},
{name:"USA",countryCode:"US"},
{name:"Uganda",countryCode:"UG"},
{name:"Ukraine",countryCode:"UA"},
{name:"United Arab Emirates",countryCode:"AE"},
{name:"United Kingdom",countryCode:"GB"},
{name:"Uruguay",countryCode:"UY"},
{name:"Uzbekistan",countryCode:"UZ"},
{name:"Vanuatu",countryCode:"VU"},
{name:"Vatican City",countryCode:"VA"},
{name:"Venezuela",countryCode:"VE"},
{name:"Vietnam",countryCode:"VN"},
{name:"Virgin Islands-British",countryCode:"VG"},
{name:"Virgin Islands-US",countryCode:"VI"},
{name:"Yemen, Rep",countryCode:"YE"},
{name:"Zambia",countryCode:"ZM"},
{name:"Zimbabwe",countryCode:"ZW"},
]
export {zones, countryNames, zonePrint, allCountryList}