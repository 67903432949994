import React from 'react'
import {
    Box,
    Grid,
    Stack,
    Text,
    HStack,
} from '@chakra-ui/react'
import { localAddresses } from '../../data/localAddresses'

const FromAndToDetail = ({preshipment}) => {
    return (
        <Box>
            {preshipment && 
                <Grid
                templateRows='repeat(1, 1fr)'
                templateColumns='repeat(2, 1fr)'
                gap={4}
                >
                    <Stack spacing={'0'}>
                        <Text fontSize={'2xl'} fontWeight={'700'}>From</Text>
                        <Text fontWeight={'400'}>{preshipment.contact.split(';')[0]}</Text>
                        <Text>{preshipment.contact.split(';')[0]}</Text>
                        <Text>{localAddresses[1].address}</Text>
                        <HStack>
                            <Text>{localAddresses[1].city}, {localAddresses[1].province}</Text>                        
                            <Text>{localAddresses[1].postalCode}</Text>
                        </HStack>
                        <Text>{localAddresses[1].country && localAddresses[1].country}</Text>
                        <Text>{preshipment.contact.split(';')[1]}</Text>
                        <Text>{preshipment.contact.split(';')[2]}</Text>
                    </Stack>

                    <Stack spacing={'0'}>
                        <Text fontSize={'2xl'} fontWeight={'700'}>Ship To</Text>
                        <Text fontWeight={'400'}>{preshipment.addressId.name}</Text>
                        <Text>{preshipment.addressId.company}</Text>
                        <Text>{preshipment.addressId.address}</Text>
                        {preshipment.addressId.address2 && <Text>{preshipment.addressId.address2}</Text>}
                        {preshipment.addressId.address3 && <Text>{preshipment.addressId.address3}</Text>}
                        <HStack>
                            <Text>{preshipment.addressId.city} </Text>
                            {preshipment.addressId.stateProvince && <Text>{preshipment.addressId.stateProvince} </Text>}
                            <Text>{preshipment.addressId.postalcode}</Text>
                        </HStack>
                        
                        <Text>{preshipment.addressId.country}</Text>
                        <Text>{preshipment.addressId.phone}</Text>
                        {preshipment.addressId.email && <Text>{preshipment.addressId.email}</Text>}
                    </Stack>
                </Grid>
            }
        </Box>
    )
}

export default FromAndToDetail