const shipTypes = [
    {
        code: 'dhl',
        name: 'DHL'
    },
    {
        code: 'air',
        name: 'AIR'
    },
    {
        code: 'surface',
        name: 'SURFACE'
    },
    {
        code: 'ems',
        name: 'EMS'
    },
    {
        code: 'domestic',
        name: 'DOMESTIC'
    }

]

export {shipTypes}