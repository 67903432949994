export const THPPAYMENT_LIST_REQUEST = 'THPPAYMENT_LIST_REQUEST'
export const THPPAYMENT_LIST_SUCCESS = 'THPPAYMENT_LIST_SUCCESS'
export const THPPAYMENT_LIST_FAIL = 'THPPAYMENT_LIST_FAIL'
export const THPPAYMENT_LIST_RESET = 'THPPAYMENT_LIST_RESET'

export const THPPAYMENT_MY_REQUEST = 'THPPAYMENT_MY_REQUEST'
export const THPPAYMENT_MY_SUCCESS = 'THPPAYMENT_MY_SUCCESS'
export const THPPAYMENT_MY_FAIL = 'THPPAYMENT_MY_FAIL'
export const THPPAYMENT_MY_RESET = 'THPPAYMENT_MY_RESET'

export const THPPAYMENT_CREATE_REQUEST = 'THPPAYMENT_CREATE_REQUEST'
export const THPPAYMENT_CREATE_SUCCESS = 'THPPAYMENT_CREATE_SUCCESS'
export const THPPAYMENT_CREATE_FAIL = 'THPPAYMENT_CREATE_FAIL'
export const THPPAYMENT_CREATE_RESET = 'THPPAYMENT_CREATE_RESET'

export const THPPAYMENT_BYID_REQUEST = 'THPPAYMENT_BYID_REQUEST'
export const THPPAYMENT_BYID_SUCCESS = 'THPPAYMENT_BYID_SUCCESS'
export const THPPAYMENT_BYID_FAIL = 'THPPAYMENT_BYID_FAIL'
export const THPPAYMENT_BYID_RESET = 'THPPAYMENT_BYID_RESET'

export const THPPAYMENT_APPROVE_REQUEST = 'THPPAYMENT_APPROVE_REQUEST'
export const THPPAYMENT_APPROVE_SUCCESS = 'THPPAYMENT_APPROVE_SUCCESS'
export const THPPAYMENT_APPROVE_FAIL = 'THPPAYMENT_APPROVE_FAIL'
export const THPPAYMENT_APPROVE_RESET = 'THPPAYMENT_APPROVE_RESET'

export const THPPAYMENT_DELETE_REQUEST = 'THPPAYMENT_DELETE_REQUEST'
export const THPPAYMENT_DELETE_SUCCESS = 'THPPAYMENT_DELETE_SUCCESS'
export const THPPAYMENT_DELETE_FAIL = 'THPPAYMENT_DELETE_FAIL'
export const THPPAYMENT_DELETE_RESET = 'THPPAYMENT_DELETE_RESET'