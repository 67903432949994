import {
    SHIPCOUNT_LIST_REQUEST,
    SHIPCOUNT_LIST_SUCCESS,
    SHIPCOUNT_LIST_FAIL,
    SHIPCOUNT_LIST_RESET
} from '../constants/shipCountConstants'

export const shipCountListReducer = (state={shipcounts:[]}, action) => {
    switch(action.type){
        case SHIPCOUNT_LIST_REQUEST:
            return {loading: true}
        case SHIPCOUNT_LIST_SUCCESS:
            return {
                loading: false, 
                shipcounts: action.payload.shipcounts,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case SHIPCOUNT_LIST_FAIL:
            return {loading: false, error: action.payload}
        case SHIPCOUNT_LIST_RESET:
            return {}
        default: return state
    }
}