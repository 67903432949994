export const AGENTRATE_CREATE_REQUEST = 'AGENTRATE_CREATE_REQUEST'
export const AGENTRATE_CREATE_SUCCESS = 'AGENTRATE_CREATE_SUCCESS'
export const AGENTRATE_CREATE_FAIL = 'AGENTRATE_CREATE_FAIL'
export const AGENTRATE_CREATE_RESET = 'AGENTRATE_CREATE_RESET'

export const AGENTRATE_LIST_REQUEST = 'AGENTRATE_LIST_REQUEST'
export const AGENTRATE_LIST_SUCCESS = 'AGENTRATE_LIST_SUCCESS'
export const AGENTRATE_LIST_FAIL = 'AGENTRATE_LIST_FAIL'
export const AGENTRATE_LIST_RESET = 'AGENTRATE_LIST_RESET'

export const AGENTRATE_EDIT_REQUEST = 'AGENTRATE_EDIT_REQUEST'
export const AGENTRATE_EDIT_SUCCESS = 'AGENTRATE_EDIT_SUCCESS'
export const AGENTRATE_EDIT_FAIL = 'AGENTRATE_EDIT_FAIL'
export const AGENTRATE_EDIT_RESET = 'AGENTRATE_EDIT_RESET'

export const AGENTRATE_DELETE_REQUEST = 'AGENTRATE_DELETE_REQUEST'
export const AGENTRATE_DELETE_SUCCESS = 'AGENTRATE_DELETE_SUCCESS'
export const AGENTRATE_DELETE_FAIL = 'AGENTRATE_DELETE_FAIL'
export const AGENTRATE_DELETE_RESET = 'AGENTRATE_DELETE_RESET'

export const AGENTRATE_BYID_REQUEST = 'AGENTRATE_BYID_REQUEST'
export const AGENTRATE_BYID_SUCCESS = 'AGENTRATE_BYID_SUCCESS'
export const AGENTRATE_BYID_FAIL = 'AGENTRATE_BYID_FAIL'
export const AGENTRATE_BYID_RESET = 'AGENTRATE_BYID_RESET'

export const AGENTRATE_NAMES_REQUEST = 'AGENTRATE_NAMES_REQUEST'
export const AGENTRATE_NAMES_SUCCESS = 'AGENTRATE_NAMES_SUCCESS'
export const AGENTRATE_NAMES_FAIL = 'AGENTRATE_NAMES_FAIL'
export const AGENTRATE_NAMES_RESET = 'AGENTRATE_NAMES_RESET'


export const AGENTRATE_TRANSFER_REQUEST = 'AGENTRATE_TRANSFER_REQUEST'
export const AGENTRATE_TRANSFER_SUCCESS = 'AGENTRATE_TRANSFER_SUCCESS'
export const AGENTRATE_TRANSFER_FAIL = 'AGENTRATE_TRANSFER_FAIL'
export const AGENTRATE_TRANSFER_RESET = 'AGENTRATE_TRANSFER_RESET'

export const AGENTRATE_AGENTNAMES_REQUEST = 'AGENTRATE_AGENTNAMES_REQUEST'
export const AGENTRATE_AGENTNAMES_SUCCESS = 'AGENTRATE_AGENTNAMES_SUCCESS'
export const AGENTRATE_AGENTNAMES_FAIL = 'AGENTRATE_AGENTNAMES_FAIL'
export const AGENTRATE_AGENTNAMES_RESET = 'AGENTRATE_AGENTNAMES_RESET'