export const THPRATE_CREATE_REQUEST = 'THPRATE_CREATE_REQUEST'
export const THPRATE_CREATE_SUCCESS = 'THPRATE_CREATE_SUCCESS'
export const THPRATE_CREATE_FAIL = 'THPRATE_CREATE_FAIL'
export const THPRATE_CREATE_RESET = 'THPRATE_CREATE_RESET'

export const THPRATE_LIST_REQUEST = 'THPRATE_LIST_REQUEST'
export const THPRATE_LIST_SUCCESS = 'THPRATE_LIST_SUCCESS'
export const THPRATE_LIST_FAIL = 'THPRATE_LIST_FAIL'
export const THPRATE_LIST_RESET = 'THPRATE_LIST_RESET'

export const THPRATE_BYID_REQUEST = 'THPRATE_BYID_REQUEST'
export const THPRATE_BYID_SUCCESS = 'THPRATE_BYID_SUCCESS'
export const THPRATE_BYID_FAIL = 'THPRATE_BYID_FAIL'
export const THPRATE_BYID_RESET = 'THPRATE_BYID_RESET'

export const THPRATE_DELETE_REQUEST = 'THPRATE_DELETE_REQUEST'
export const THPRATE_DELETE_SUCCESS = 'THPRATE_DELETE_SUCCESS'
export const THPRATE_DELETE_FAIL = 'THPRATE_DELETE_FAIL'
export const THPRATE_DELETE_RESET = 'THPRATE_DELETE_RESET'