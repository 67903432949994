import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { listInvoices } from '../actions/invoiceActions'
import Pagination from '../components/Pagination'
import SearchBox from '../components/SearchBox'
import {
    Box,
    HStack,
    Stack,
    Select,
    Divider,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Center,
    Heading,
    SimpleGrid,
    GridItem,
    useBreakpointValue,
    Flex,
} from '@chakra-ui/react'
import { filterInvoices } from '../actions/invoiceActions'
import { MdCheckCircle, MdCancel, MdIncompleteCircle } from 'react-icons/md'
import Error from '../components/Error'
import useWindowSize from '../components/WindowSize'
import MySpinner from '../components/MySpinner'
import Filter from '../components/Filter'


const InvoiceListScreen = () => {
    const headColSpan = useBreakpointValue({base: 2, md: 1})
    const size = useWindowSize()
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const keyword = params.keyword || ''
    const pageNumber = params.pageNumber || 1
    const [itemCount, setItemCount] = useState(50)    


    const {loading, error, invoices, pages, page} = useSelector(state=>state.invoice.List)

    useEffect(()=>{
        dispatch(listInvoices(pageNumber, itemCount, keyword))
    },[dispatch, pageNumber, itemCount, keyword])


    const checkApprovedPayments = (payments) => {
        if(payments.length === 0){
            return 0
        }
        var approvedTotal = 0
        payments.forEach(p=>{
            if(p.isApproved){
                approvedTotal = approvedTotal + p.amount
            }
        })
       
        return Number(approvedTotal)        
    }
    

    return (
        <Box mt='3'>
            <Stack>
                <Center mb='3'><Heading>DHL Invoices { loading && <MySpinner />}</Heading></Center>
                <Divider/>

                <SimpleGrid columns={2} columnGap={3} w='full' my={2}>
                    <GridItem colSpan={headColSpan} mb='4'>
                        <Box>
                        <Stack spacing={'0'}>
                            <SearchBox width={'full'} text='search invoice code' />
                        </Stack>
                        </Box>
                    </GridItem>

                    <GridItem colSpan={headColSpan} ml={{base: 0, md: 'auto'}}>
                        <HStack>
                        <Box>
                            <Button colorScheme={'green'} size='sm' onClick={()=>navigate('/createinvoice')}>New Invoice</Button>
                        </Box>
                        <Box>
                            <Select placeholder='Select' onChange={e=>setItemCount(e.target.value)} size='sm'>
                            {[50, 100, 150, 200].map((v,i)=>(
                                <option key={i} value={v}>{v}</option>
                            ))}
                            </Select>
                        </Box>
                        <Box>
                            <Pagination keyword = {keyword} pages={pages} page={page} path='invoice' />
                        </Box>
                        </HStack>
                    </GridItem>
                </SimpleGrid>

                <Filter filterFunction={filterInvoices} scanShow={false} invoiceShow={false} />
                

                 

                <Box my='3'>
                    {error && <Error error={error} /> }
                </Box>
                <Box>
                {size.width > 760 ? 
                    <Table size='sm' variant='striped' colorScheme='purple' my='4'>
                        <Thead>
                            <Tr>
                            <Th>No</Th>                          
                            <Th>Agent</Th>
                            <Th>Inovice</Th>
                            <Th isNumeric>Total Amount</Th>
                            <Th isNumeric>Balance</Th>
                            <Th isNumeric>Due Date</Th>
                            <Th isNumeric>Shipment Qty</Th>
                            <Th>Paid</Th>
                            <Th>Detail</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {invoices && invoices.map((iv, idx)=>(
                            <Tr key={idx}>
                                <Td>{pageNumber === 1 ? (idx+1) : (((Number(pageNumber)-1) * itemCount) + (idx+1))}</Td>
                                <Td>{iv.agent.name}</Td>
                                <Td>{iv.code}</Td>
                                <Td isNumeric>{iv.totalAmount.toLocaleString()}</Td>
                                <Td isNumeric color='red' fontWeight={'600'}>{iv.totalAmount - checkApprovedPayments(iv.payments)}</Td>
                                <Td isNumeric>{iv.dueDate.substring(0,10)}</Td>                              
                                <Td isNumeric>{iv.shipmentList.length}</Td>
                                {/* <Td><Box bg={iv.isPaid ? 'green' : (!iv.isPaid && iv.payments.length !== 0) ? 'orange' : 'red'} h='20px' w='20px'></Box></Td> */}
                                <Td>{iv.isPaid ? < MdCheckCircle size={20} color='green' /> : (!iv.isPaid && iv.payments.length !== 0) ? <MdIncompleteCircle size={20} color='orange' /> : < MdCancel size={20} color='red' />}</Td>
                                <Td>
                                <Link to={`/invoice/${iv._id}`}>
                                    <Button size='sm' variant={'link'} >Details</Button>
                                </Link>
                                </Td>
                            </Tr>
                            ))}
                        </Tbody>
                    </Table> 
                    :
                    <Table size='sm' variant='striped' colorScheme='purple' my='4'>
                        <Thead>
                            <Tr>
                            <Th>No</Th>                          
                            <Th>Summary</Th>
                            <Th>Detail</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {invoices && invoices.map((iv, idx)=>(
                            <Tr key={idx}>
                                <Td>{pageNumber === 1 ? (idx+1) : (((Number(pageNumber)-1) * itemCount) + (idx+1))}</Td>
                                <Td>
                                    <Stack spacing={0}>
                                        <Box>Agent - {iv.agent.name}</Box>
                                        <Box>ID - {iv.code}</Box>
                                        <Box>Total Amount - {iv.totalAmount.toLocaleString()}</Box>
                                        <Box color='red' fontWeight={'600'}>Balance - {iv.totalAmount - checkApprovedPayments(iv.payments)}</Box>
                                        <Box>Due Date - {iv.dueDate.substring(0,10)}</Box>
                                        <Box>AWB qty - {iv.shipmentList.length}</Box>
                                        <Flex>Paid - {iv.ispaid ? < MdCheckCircle size={20} color='green' /> : (!iv.isPaid && iv.payments.length !== 0) ? <MdIncompleteCircle size={20} color='orange' /> : < MdCancel size={20} color='red' /> }</Flex>
                                    </Stack>
                                </Td>
                                <Td>
                                <Link to={`/invoice/${iv._id}`}>
                                    <Button size='sm' variant={'link'} >Details</Button>
                                </Link>
                                </Td>
                            </Tr>
                            ))}
                        </Tbody>
                    </Table>
                }
                </Box>                
            </Stack>
        </Box>
    )
}

export default InvoiceListScreen