import {
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_REGISTER_RESET,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_RESET,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,
    USER_EDIT_REQUEST,
    USER_EDIT_SUCCESS,
    USER_EDIT_FAIL,
    USER_EDIT_RESET,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_DELETE_RESET,
    USER_BYID_REQUEST,
    USER_BYID_SUCCESS,
    USER_BYID_FAIL,
    USER_BYID_RESET,
    USER_SALES_NAMES_REQUEST,
    USER_SALES_NAMES_SUCCESS,
    USER_SALES_NAMES_FAIL,
    USER_SALES_NAMES_RESET,
    USER_FORGOT_PASSWORD_REQUEST,
    USER_FORGOT_PASSWORD_SUCCESS,
    USER_FORGOT_PASSWORD_FAIL,
    USER_FORGOT_PASSWORD_RESET,
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_FAIL,
    USER_RESET_PASSWORD_RESET,
    USER_AGENTLIST_REQUEST,
    USER_AGENTLIST_SUCCESS,
    USER_AGENTLIST_FAIL,
    USER_AGENTLIST_RESET,
    USER_SALELIST_REQUEST,
    USER_SALELIST_SUCCESS,
    USER_SALELIST_FAIL,
    USER_SALELIST_RESET,
    USER_MY_REQUEST,
    USER_MY_SUCCESS,
    USER_MY_FAIL,
    USER_MY_RESET,
    USER_MY_PASSWORD_REQUEST,
    USER_MY_PASSWORD_SUCCESS,
    USER_MY_PASSWORD_FAIL,
    USER_MY_PASSWORD_RESET,
    USER_ADDBALANCE_REQUEST,
    USER_ADDBALANCE_SUCCESS,
    USER_ADDBALANCE_FAIL,
    USER_ADDBALANCE_RESET,
    USER_MYAGENTS_REQUEST,
    USER_MYAGENTS_SUCCESS,
    USER_MYAGENTS_FAIL,
    USER_MYAGENTS_RESET,
    USER_MYAGENTDETAIL_REQUEST,
    USER_MYAGENTDETAIL_SUCCESS,
    USER_MYAGENTDETAIL_FAIL,
    USER_MYAGENTDETAIL_RESET,
    USER_CONFIRMPASSWORD_REQUEST,
    USER_CONFIRMPASSWORD_SUCCESS,
    USER_CONFIRMPASSWORD_FAIL,
    USER_CONFIRMPASSWORD_RESET,
    USER_ADDTHPDISCOUNT_REQUEST,
    USER_ADDTHPDISCOUNT_SUCCESS,
    USER_ADDTHPDISCOUNT_FAIL,
    USER_ADDTHPDISCOUNT_RESET,
    USER_DELETETHPDISCOUNT_REQUEST,
    USER_DELETETHPDISCOUNT_SUCCESS,
    USER_DELETETHPDISCOUNT_FAIL,
    USER_DELETETHPDISCOUNT_RESET
} from '../constants/userConstants'

export const registerReducer = (state={}, action) => {
    switch(action.type){
        case USER_REGISTER_REQUEST:
            return {loading: true}
        case USER_REGISTER_SUCCESS:
            return {loading: false, success: true}
        case USER_REGISTER_FAIL:
            return {loading: false, error: action.payload}
        case USER_REGISTER_RESET:
            return {}
        default: return state
    }
}

export const loginReducer = (state={user: null}, action) => {
    switch(action.type){
        case USER_LOGIN_REQUEST:
            return {loading: true}
        case USER_LOGIN_SUCCESS:
            return {loading: false, user: action.payload}
        case USER_LOGIN_FAIL:
            return {loading: false, error: action.payload}
        case USER_LOGIN_RESET:
            return {}
        default: return state
    }
}

export const userListReducer = (state={users: []}, action) => {
    switch(action.type){
        case USER_LIST_REQUEST:
            return {loading: true}
        case USER_LIST_SUCCESS:
            return {
                loading: false,
                users: action.payload.users,
                pages: action.payload.pages,
                page: action.payload.page            
            }
        case USER_LIST_FAIL:
            return {loading: false, error: action.payload}
        case USER_LIST_RESET:
            return {}
        default: return state
    }
}

export const userAgentListReducer = (state={agents: []}, action) => {
    switch(action.type){
        case USER_AGENTLIST_REQUEST:
            return {loading: true}
        case USER_AGENTLIST_SUCCESS:
            return {
                loading: false,
                agents: action.payload.agents,
                pages: action.payload.pages,
                page: action.payload.page            
            }
        case USER_AGENTLIST_FAIL:
            return {loading: false, error: action.payload}
        case USER_AGENTLIST_RESET:
            return {}
        default: return state
    }
}

export const userSaleListReducer = (state={sales: []}, action) => {
    switch(action.type){
        case USER_SALELIST_REQUEST:
            return {loading: true}
        case USER_SALELIST_SUCCESS:
            return {
                loading: false,
                sales: action.payload,          
            }
        case USER_SALELIST_FAIL:
            return {loading: false, error: action.payload}
        case USER_SALELIST_RESET:
            return {}
        default: return state
    }
}

export const userEditReducer = (state={}, action) => {
    switch(action.type){
        case USER_EDIT_REQUEST:
            return {loading: true}
        case USER_EDIT_SUCCESS:
            return {loading: false, success: true}
        case USER_EDIT_FAIL:
            return {loading: false, error: action.payload}
        case USER_EDIT_RESET:
            return {}
        default: return state
    }
}

export const userDeleteReducer = (state={}, action) => {
    switch(action.type){
        case USER_DELETE_REQUEST:
            return {loading: true}
        case USER_DELETE_SUCCESS:
            return {loading: false, success: true}
        case USER_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case USER_DELETE_RESET:
            return {}
        default: return state
    }
}

export const userByIdReducer = (state={}, action) => {
    switch(action.type){
        case USER_BYID_REQUEST:
            return {loading: true}
        case USER_BYID_SUCCESS:
            return {loading: false, userDetail: action.payload}
        case USER_BYID_FAIL:
            return {loading: false, error: action.payload}
        case USER_BYID_RESET:
            return {}
        default: return state
    }
}

export const userMyReducer = (state={}, action) => {
    switch(action.type){
        case USER_MY_REQUEST:
            return {loading: true}
        case USER_MY_SUCCESS:
            return {loading: false, userDetail: action.payload}
        case USER_MY_FAIL:
            return {loading: false, error: action.payload}
        case USER_MY_RESET:
            return {}
        default: return state
    }
}

export const userMyPasswordReducer = (state={}, action) => {
    switch(action.type){
        case USER_MY_PASSWORD_REQUEST:
            return {loading: true}
        case USER_MY_PASSWORD_SUCCESS:
            return {loading: false, success: true}
        case USER_MY_PASSWORD_FAIL:
            return {loading: false, error: action.payload}
        case USER_MY_PASSWORD_RESET:
            return {}
        default: return state
    }
}

export const userSalesNamesReducer = (state={salesnames: []}, action) => {
    switch(action.type){
        case USER_SALES_NAMES_REQUEST:
            return {loading: true}
        case USER_SALES_NAMES_SUCCESS:
            return {loading: false, salesnames: action.payload}
        case USER_SALES_NAMES_FAIL:
            return {loading: false, error: action.payload}
        case USER_SALES_NAMES_RESET:
            return {}
        default: return state
    }
}

export const userForgotPasswordReducer = (state={}, action) => {
    switch(action.type){
        case USER_FORGOT_PASSWORD_REQUEST:
            return {loading: true}
        case USER_FORGOT_PASSWORD_SUCCESS:
            return {loading: false, success: true}
        case USER_FORGOT_PASSWORD_FAIL:
            return {loading: false, error: action.payload}
        case USER_FORGOT_PASSWORD_RESET:
            return {}
        default: return state
    }
}

export const userResetPasswordReducer = (state={}, action) => {
    switch(action.type){
        case USER_RESET_PASSWORD_REQUEST:
            return {loading: true}
        case USER_RESET_PASSWORD_SUCCESS:
            return {loading: false, success: true}
        case USER_RESET_PASSWORD_FAIL:
            return {loading: false, error: action.payload}
        case USER_RESET_PASSWORD_RESET:
            return {}
        default: return state
    }
}

export const userAddBalanceReducer = (state={}, action) => {
    switch(action.type){
        case USER_ADDBALANCE_REQUEST:
            return {loading: true}
        case USER_ADDBALANCE_SUCCESS:
            return {loading: false, success: true}
        case USER_ADDBALANCE_FAIL:
            return {loading: false, error: action.payload}
        case USER_ADDBALANCE_RESET:
            return {}
        default: return state
    }
}

export const userMyAgentsReducer = (state={agents: []}, action) => {
    switch(action.type){
        case USER_MYAGENTS_REQUEST:
            return {loading: true}
        case USER_MYAGENTS_SUCCESS:
            return {
                loading: false,
                agents: action.payload           
            }
        case USER_MYAGENTS_FAIL:
            return {loading: false, error: action.payload}
        case USER_MYAGENTS_RESET:
            return {}
        default: return state
    }
}

export const userMyAgentDetailReducer = (state={}, action) => {
    switch(action.type){
        case USER_MYAGENTDETAIL_REQUEST:
            return {loading: true}
        case USER_MYAGENTDETAIL_SUCCESS:
            return {loading: false, myagent: action.payload}
        case USER_MYAGENTDETAIL_FAIL:
            return {loading: false, error: action.payload}
        case USER_MYAGENTDETAIL_RESET:
            return {}
        default: return state
    }
}

export const userConfirmPasswordReducer = (state={}, action) => {
    switch(action.type){
        case USER_CONFIRMPASSWORD_REQUEST:
            return {loading: true}
        case USER_CONFIRMPASSWORD_SUCCESS:
            return {loading: false, success: true}
        case USER_CONFIRMPASSWORD_FAIL:
            return {loading: false, error: action.payload}
        case USER_CONFIRMPASSWORD_RESET:
            return {}
        default: return state
    }
}

export const userAddThpDiscountReducer = (state={}, action) => {
    switch(action.type){
        case USER_ADDTHPDISCOUNT_REQUEST:
            return {loading: true}
        case USER_ADDTHPDISCOUNT_SUCCESS:
            return {loading: false, success: true}
        case USER_ADDTHPDISCOUNT_FAIL:
            return {loading: false, error: action.payload}
        case USER_ADDTHPDISCOUNT_RESET:
            return {}
        default: return state
    }
}

export const userDeleteThpDiscountReducer = (state={}, action) => {
    switch(action.type){
        case USER_DELETETHPDISCOUNT_REQUEST:
            return {loading: true}
        case USER_DELETETHPDISCOUNT_SUCCESS:
            return {loading: false, success: true}
        case USER_DELETETHPDISCOUNT_FAIL:
            return {loading: false, error: action.payload}
        case USER_DELETETHPDISCOUNT_RESET:
            return {}
        default: return state
    }
}
