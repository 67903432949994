import React from 'react'
import { Spinner } from '@chakra-ui/react'

const MySpinner = ({speed, thickness, size}) => {
  return (
    <Spinner speed={speed} thickness={thickness} size={size} />
  )
}


Spinner.defaultProps = {
    speed: '0.1s',
    thickness: '5px',
    size:'md'
}
export default MySpinner