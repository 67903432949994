import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getBalanceLogs } from '../actions/systemActions'
import { Error, MySpinner } from '../components'
import { Box, Text, HStack, Heading, Spacer, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
} from '@chakra-ui/react'

const BalanceLogScreen = () => {
  const dispatch = useDispatch()
  const { loading, error, success, logs } = useSelector(state=>state.system.balanceLog)

  useEffect(()=>{
    dispatch(getBalanceLogs())
  },[dispatch])

  const getInvId = (note) => {
    const a = note.split(' ')
    const Id = a[a.length - 1]
    return Id
  }

  return (
    <div>
      {loading && <MySpinner />}
      {error && <Error error={error} />}
      {success && 
        <Box p={4}>  
          <Center><Heading>BALANCE LOGS</Heading></Center>              
          {logs.map((l,i)=>(
            <Box key={l._id} border={'1px'} borderColor='gray.400' borderTopRadius="md" m={2} p={3}>
              <HStack mb={3}>
                <Heading size='xs' textTransform='uppercase'>
                  Name - 
                    <span style={{backgroundColor: '#8DDFCB', padding: '5px'}}>
                      <Link target='_blank' to={`/user/${l._id}`}>
                      {l.name}
                      </Link>
                    </span> | 
                  Agent Code - 
                    <span style={{backgroundColor: '#8DDFCB', padding: '5px'}}>
                      <Link target='_blank' to={`/user/${l._id}`}>
                      {l.agentCode ? l.agentCode : 'no code'}
                      </Link>
                    </span> | 
                  Sale - 
                    <span style={{backgroundColor: '#8DDFCB', padding: '5px'}}>
                      <Link target='_blank' to={`/user/${l.sale._id}`}>
                      {l.sale?.name}
                      </Link>
                    </span>
                </Heading>
                <Spacer />
                <Heading size='xs' textTransform='uppercase' bg={'blue.200'}  p={'2'}>
                  Current Balance - {l.balance ? l.balance : 0}
                </Heading>
              </HStack>
              <Box>
                <Accordion allowToggle size={'xs'}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex='1' textAlign='left'>
                          Balance Logs
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Table size='sm'>
                        <Thead>
                          <Tr>
                            <Th>No</Th>
                            <Th>Amount</Th>
                            <Th>Type</Th>
                            <Th>User</Th>
                            <Th>Note</Th>
                            <Th>Date</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {l.balanceLog.map((bl,idx)=>(
                            <Tr key={idx}>
                              <Td>{idx+1}</Td>
                              <Td>{bl.split(',')[0].split('-')[1]}</Td>
                              <Td bg={bl.split(',')[1].split('-')[1] === 'added' ? 'green.200' : bl.split(',')[1].split('-')[1] === 'deduct' ? 'red.200' : ''}>{bl.split(',')[1].split('-')[1]}</Td>
                              <Td>{bl.split(',')[2].split('-')[1]}</Td>
                              <Td>
                                {bl.split(',')[3].split('-')[1].includes('inv') ? <Link to={`/myinvoices/${getInvId(bl.split(',')[3].split('-')[1])}`} target='_blank'>
                                  <Text as='u' color='blue.400'>{bl.split(',')[3].split('-')[1]}</Text>
                                </Link> : <Text>{bl.split(',')[3].split('-')[1]}</Text>}
                              </Td>
                              <Td>{bl.split(',')[4].split('-').slice(1).join('-')}</Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            </Box>
          ))}
        </Box>
      }
    </div>
  )
}

export default BalanceLogScreen