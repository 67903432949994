import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useParams } from 'react-router-dom'
import SearchBox from '../components/SearchBox'
import Pagination from '../components/Pagination'
import {
  HStack,
  Stack,
  Box,
  Spacer,
  Spinner,
  Divider,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react'
import { listDeletedShipments } from '../actions/shipmentActions'
import { SHIPMENT_LIST_RESET } from '../constants/shipmentConstants'

const DeletedShipmentListScreen = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const keyword = params.keyword || ''
  const pageNumber = params.pageNumber || 1
  const [itemCount, setItemCount] = useState(50)
  const {loading, error, shipments, pages, page} = useSelector(state=>state.shipment.DeletedList)

  useEffect(()=>{
    dispatch(listDeletedShipments(pageNumber, itemCount, keyword))
    return()=>{
      dispatch({type: SHIPMENT_LIST_RESET})
    }
  },[dispatch,  pageNumber, itemCount, keyword])
 

  return (
    <Box>
        <Alert my='2' status='error' variant='left-accent'>
            <AlertIcon />
            <AlertTitle mr={2}>DELETED SHIPMENTS LIST</AlertTitle>
        </Alert>
        <HStack spacing='4' my='4'>
          <Box>
            <Stack spacing={'0'}>
              <SearchBox />
              <Box fontSize='13px' color='gray'>search: awb, precode, receiver, address</Box>
            </Stack>
          </Box>
          <Box>
            {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
          </Box>
          <Spacer />
          <Box>
            <Select placeholder='Select' onChange={e=>setItemCount(e.target.value)} size='sm'>
              {[50, 100, 150, 200].map((v,i)=>(
                <option key={i} value={v}>{v}</option>
              ))}
            </Select>
          </Box>
          <Box>
            <Pagination keyword = {keyword} pages={pages} page={page} path='deletedshipments' />
          </Box>
        </HStack>
        <Divider />

    
        
        <Divider />

        <Box my={'2'}>
          {error && 
            <Alert status='error' variant='left-accent'>
            <AlertIcon />
            <AlertTitle mr={2}>Error getting shipments</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
            </Alert>
          }
          
        </Box>

        <Table size='sm' variant='striped' colorScheme='red' my='4'>
          <Thead>
            <Tr>
              <Th>No</Th>
              <Th>AWB</Th>
              <Th>PreId</Th>
              <Th>Agent</Th>
              <Th>Deleted Date</Th>
              <Th>Deleted By</Th>
              <Th>Receiver</Th>
              <Th>Weight</Th>
              {/* <Th>Amount</Th> */}
              {/* <Th>Invoiced</Th> */}
              {/* <Th>Options</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {shipments && shipments.map((sm,index)=>(
              <Tr key={index}>
                <Td>{pageNumber === 1 ? (index+1) : (((Number(pageNumber)-1) * itemCount) + (index+1))}</Td>
                <Td>{sm.awb}</Td>
                <Td>{sm.preCode}</Td>
                <Td>{sm.agent.name}</Td>
                <Td>{sm.createdAt.substring(0,10)}</Td>
                <Td>{sm.deletedBy.name}</Td>
                <Td>{sm.customerDetails.receiverDetails.contactInformation.fullName} - {sm.customerDetails.receiverDetails.postalAddress.countryCode}</Td>
                <Td>{sm.totalWeight}</Td>
                {/* <Td>{sm.totalAmount.toLocaleString()}</Td> */}
                {/* <Td>{new Date(sm.createdAt).toLocaleString('en', { hour12: true, timeZone: 'UTC' })}</Td> */}
                {/* <Td>
                  <HStack>
                    {sm.isInvoiced ? < MdCheckCircle size={23} color='green' /> : < MdCancel size={23} color='red' />}
                    {sm.isReturned && < MdInfo size={23} color='tomato' />}
                  </HStack>
                </Td> */}
                {/* <Td>
                  <Link to={`/shipment/${sm._id}`} state={{redirect: location.pathname}}>Detail</Link>       
                </Td> */}
              </Tr>
            ))}
          </Tbody>
        </Table>
    </Box>
  )
}

export default DeletedShipmentListScreen