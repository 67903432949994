import { 
    REMOTEAREA_CREATE_FAIL,
    REMOTEAREA_CREATE_REQUEST,
    REMOTEAREA_CREATE_RESET,
    REMOTEAREA_CREATE_SUCCESS,
    REMOTEAREA_DELETE_FAIL,
    REMOTEAREA_DELETE_REQUEST,
    REMOTEAREA_DELETE_RESET,
    REMOTEAREA_DELETE_SUCCESS,
    REMOTEAREA_LIST_FAIL,
    REMOTEAREA_LIST_REQUEST, 
    REMOTEAREA_LIST_RESET, 
    REMOTEAREA_LIST_SUCCESS,
    REMOTEAREA_UPDATE_FAIL,
    REMOTEAREA_UPDATE_REQUEST,
    REMOTEAREA_UPDATE_RESET,
    REMOTEAREA_UPDATE_SUCCESS,

} from "../constants/remoteAreaConstants"





export const remoteAreaListReducer = (state={remoteareas:[]}, action) => {
    switch(action.type){
        case REMOTEAREA_LIST_REQUEST:
            return {loading: true}
        case REMOTEAREA_LIST_SUCCESS:
            return {loading: false, remoteareas: action.payload}
        case REMOTEAREA_LIST_FAIL:
            return {loading: false, error: action.payload}
        case REMOTEAREA_LIST_RESET:
            return {}
        default: return state
    }
}

export const remoteAreaCreateReducer = (state={}, action) => {
    switch(action.type){
        case REMOTEAREA_CREATE_REQUEST:
            return {loading: true}
        case REMOTEAREA_CREATE_SUCCESS:
            return {loading: false, success: true}
        case REMOTEAREA_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case REMOTEAREA_CREATE_RESET:
            return {}
        default: return state
    }
}

export const remoteAreaUpdateReducer = (state={}, action) => {
    switch(action.type){
        case REMOTEAREA_UPDATE_REQUEST:
            return {loading: true}
        case REMOTEAREA_UPDATE_SUCCESS:
            return {loading: false, success: true}
        case REMOTEAREA_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case REMOTEAREA_UPDATE_RESET:
            return {}
        default: return state
    }
}

export const remoteAreaDeleteReducer = (state={}, action) => {
    switch(action.type){
        case REMOTEAREA_DELETE_REQUEST:
            return {loading: true}
        case REMOTEAREA_DELETE_SUCCESS:
            return {loading: false, success: true}
        case REMOTEAREA_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case REMOTEAREA_DELETE_RESET:
            return {}
        default: return state
    }
}