export const ADDRESS_CREATE_REQUEST = 'ADDRESS_CREATE_REQUEST'
export const ADDRESS_CREATE_SUCCESS = 'ADDRESS_CREATE_SUCCESS'
export const ADDRESS_CREATE_FAIL = 'ADDRESS_CREATE_FAIL'
export const ADDRESS_CREATE_RESET = 'ADDRESS_CREATE_RESET'

export const ADDRESS_LIST_REQUEST = 'ADDRESS_LIST_REQUEST'
export const ADDRESS_LIST_SUCCESS = 'ADDRESS_LIST_SUCCESS'
export const ADDRESS_LIST_FAIL = 'ADDRESS_LIST_FAIL'
export const ADDRESS_LIST_RESET = 'ADDRESS_LIST_RESET'

export const ADDRESS_EDIT_REQUEST = 'ADDRESS_EDIT_REQUEST'
export const ADDRESS_EDIT_SUCCESS = 'ADDRESS_EDIT_SUCCESS'
export const ADDRESS_EDIT_FAIL = 'ADDRESS_EDIT_FAIL'
export const ADDRESS_EDIT_RESET = 'ADDRESS_EDIT_RESET'

export const ADDRESS_DELETE_REQUEST = 'ADDRESS_DELETE_REQUEST'
export const ADDRESS_DELETE_SUCCESS = 'ADDRESS_DELETE_SUCCESS'
export const ADDRESS_DELETE_FAIL = 'ADDRESS_DELETE_FAIL'
export const ADDRESS_DELETE_RESET = 'ADDRESS_DELETE_RESET'

export const ADDRESS_BYID_REQUEST = 'ADDRESS_BYID_REQUEST'
export const ADDRESS_BYID_SUCCESS = 'ADDRESS_BYID_SUCCESS'
export const ADDRESS_BYID_FAIL = 'ADDRESS_BYID_FAIL'
export const ADDRESS_BYID_RESET = 'ADDRESS_BYID_RESET'

export const ADDRESS_FROM_REQUEST = 'ADDRESS_FROM_REQUEST'
export const ADDRESS_FROM_SUCCESS = 'ADDRESS_FROM_SUCCESS'
export const ADDRESS_FROM_FAIL = 'ADDRESS_FROM_FAIL'
export const ADDRESS_FROM_RESET = 'ADDRESS_FROM_RESET'

export const ADDRESS_FROM_NAME_REQUEST = 'ADDRESS_FROM_NAME_REQUEST'
export const ADDRESS_FROM_NAME_SUCCESS = 'ADDRESS_FROM_NAME_SUCCESS'
export const ADDRESS_FROM_NAME_FAIL = 'ADDRESS_FROM_NAME_FAIL'
export const ADDRESS_FROM_NAME_RESET = 'ADDRESS_FROM_NAME_RESET'

export const DHL_ADDRESS_VALIDATE_REQUEST = 'DHL_ADDRESS_VALIDATE_REQUEST'
export const DHL_ADDRESS_VALIDATE_SUCCESS = 'DHL_ADDRESS_VALIDATE_SUCCESS'
export const DHL_ADDRESS_VALIDATE_FAIL = 'DHL_ADDRESS_VALIDATE_FAIL'
export const DHL_ADDRESS_VALIDATE_RESET = 'DHL_ADDRESS_VALIDATE_RESET'