
import {
    ACCOUNTING_SALE_REQUEST,
    ACCOUNTING_SALE_SUCCESS,
    ACCOUNTING_SALE_FAIL,
    ACCOUNTING_SALE_RESET,
    ACCOUNTING_SALE_DETAIL_REQUEST,
    ACCOUNTING_SALE_DETAIL_SUCCESS,
    ACCOUNTING_SALE_DETAIL_FAIL,
    ACCOUNTING_SALE_DETAIL_RESET,
} from '../../constants/accounting_constants/accountingConstants'


export const accountingSaleReducer = (state={}, action) => {
    switch(action.type){
        case ACCOUNTING_SALE_REQUEST:
            return {loading: true, error: null}
        case ACCOUNTING_SALE_SUCCESS:
            return {
                loading: false, 
                dataList: action.payload
            }
        case ACCOUNTING_SALE_FAIL:
            return {loading: false, error: action.payload}
        case ACCOUNTING_SALE_RESET:
            return {}
        default: return state
    }
}

export const accountingSaleDetailReducer = (state={}, action) => {
    switch(action.type){
        case ACCOUNTING_SALE_DETAIL_REQUEST:
            return {loading: true, error: null}
        case ACCOUNTING_SALE_DETAIL_SUCCESS:
            return {
                loading: false, 
                data: action.payload
            }
        case ACCOUNTING_SALE_DETAIL_FAIL:
            return {loading: false, error: action.payload}
        case ACCOUNTING_SALE_DETAIL_RESET:
            return {}
        default: return state
    }
}