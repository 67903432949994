import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import {useToast,HStack, Spacer, Divider, Spinner, Stack, FormControl, Input, FormLabel, Button, Text, Box, Icon, InputGroup, InputRightElement, Select } from '@chakra-ui/react'
import { MdSaveAlt, MdReply } from 'react-icons/md'
import { registerUser, getSalesNames } from '../actions/userActions'
import { namesRate } from '../actions/rateActions'
import { USER_REGISTER_RESET, USER_SALES_NAMES_RESET } from '../constants/userConstants'
import { RATE_NAMES_RESET } from '../constants/rateConstants'
import Error from '../components/Error'
import {ChooseFixedShipperForSaleModal} from '../Modals'


const UserRegisterScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [type, setType] = useState('')
    const [rate, setRate] = useState('')
    const [sale, setSale] = useState('')
    const [agentCode, setAgentCode] = useState('')
    const [saleCode, setSaleCode] = useState('')
    const [addressId, setAddressId] = useState('')
    const [shipper, setShipper] = useState(null)
    const [show, setShow] = useState(false)
    const [isError, setIsError] = useState([])
    const {user} = useSelector(state=>state.login)
    const {loading, error, success} = useSelector(state=>state.system.register)
    const {loading: namesRateLoading, error: namesRateError, ratenames} = useSelector(state=>state.rate.Names)
    const {loading: salesNamesLoading, error: salesNamesError, salesnames} = useSelector(state=>state.user.SalesNames)
    

    useEffect(()=>{
        if(success){
            navigate('/user')
            toast({
                status: 'success',
                position: 'top',
                title: 'ddddNew account created successfully-',
                duration: 500,
                isClosable: true,
            })  
        }
        return ()=>{
            dispatch({type: USER_REGISTER_RESET})
            dispatch({type: USER_SALES_NAMES_RESET})
            dispatch({type: RATE_NAMES_RESET})
        }
    },[success, toast, dispatch, navigate])

    const handleTypeSelect = (acctype) => {
        setIsError(isError.filter(x => x !== 'type'))
        setType(acctype)
        if(acctype === 'agent'){
            //dispatch rate list, agent rate list and sale list
            dispatch(namesRate())
            dispatch(getSalesNames())
        }
    }
    

    const handleClick = () => setShow(!show)
    const handleSubmit = () => {
        let newUser
        if(type === 'agent'){ 
            newUser = {
                name,
                email,
                password,
                type,
                agentCode,
                rate,
                sale,
            }
        } else if(type === 'sale') {
            newUser = {
                name,
                email,
                password,
                type,
                saleCode,
                addressId,
            }
        } else {
            newUser = {
                name,
                email,
                password,
                type
            }
        }
        for (const [key, value] of Object.entries(newUser)) {
            if(value === ''){
                isError.push(key)
                setIsError([...isError])
            }
        }

        if(isError.length === 0){
            newUser.phone = phone
            //console.log(newUser)
            dispatch(registerUser(newUser))
        } else {
            return
        }
    }
    return (
        <Box w='800px' maxW='100%' mx='auto' py='7' style={loading ? {'opacity': '0.3'} : {}} >
            <Text fontSize='3xl' align='center'>Register New User</Text>
            <Stack spacing={5} py='3'>

                {error && <Error error={error} />}
                {namesRateError && <Error error={namesRateError} />}
                {salesNamesError && <Error error={salesNamesError} />}
                <FormControl isRequired isInvalid={isError.indexOf('name') !== -1}>
                    <FormLabel>Name</FormLabel>
                    <Input 
                        type='text' 
                        size='sm'
                        value={name}
                        onChange={e=>setName(e.target.value)}
                        onKeyPress={()=>setIsError(isError.filter(x => x !== 'name'))}
                        maxLength={50}
                        
                        />
                </FormControl>
                
                <FormControl isRequired isInvalid={isError.indexOf('email') !== -1}>
                    <FormLabel>Email address</FormLabel>
                    <Input 
                        type='email'
                        size='sm' 
                        value={email}
                        onChange={e=>setEmail(e.target.value)}
                        onKeyPress={()=>setIsError(isError.filter(x => x !== 'email'))}
                        maxLength={40}
                        />
                </FormControl>
                
                <FormControl isRequired isInvalid={isError.indexOf('password') !== -1}>
                    <FormLabel>Password</FormLabel>
                    <InputGroup size='sm'>
                        <Input  
                            type={show ? 'text' : 'password'}
                            size='sm'
                            value={password}
                            onChange={e=>setPassword(e.target.value)}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'password'))}
                            maxLength={40}
                            />
                        <InputRightElement width='4.5rem'>
                            <Button  size='xs' onClick={handleClick}>
                                {show ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
                

                <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input 
                        type='text' 
                        size='sm'
                        value={phone}
                        onChange={e=>setPhone(e.target.value)}
                        onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault()}}} 
                        maxLength={20}
                        />
                </FormControl>
                {(loading || namesRateLoading || salesNamesLoading) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                <FormControl isRequired isInvalid={isError.indexOf('type') !== -1}>
                    <FormLabel>Account type</FormLabel>
                    <Select placeholder='Select' size='sm' defaultValue={type} onChange={e=>handleTypeSelect(e.target.value)}>
                        <option value='agent'>Agent</option>
                        <option value='sale'>Sale</option>
                        {user && user.isAdmin &&
                            <>
                            <option value='user'>User</option>
                            <option value='admin'>Admin</option>
                            </>
                        }
                    </Select>
                </FormControl>

                {type === 'sale' && 
                <Box>
                    <Box h='30px'>
                    <Divider />
                    </Box>
                    <FormControl isRequired isInvalid={isError.indexOf('saleCode') !== -1} mb={4}>
                        <FormLabel>Sale Code</FormLabel>
                        <Input 
                            type='text' 
                            size='sm'
                            value={saleCode}
                            onChange={e=>setSaleCode(e.target.value)}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'saleCode'))}
                            maxLength={20}
                            
                            />
                    </FormControl>
                    <Box p={3} border={'1px'} shadow={3} rounded={'2xl'}>
                        <Box mb={4}>
                            <ChooseFixedShipperForSaleModal 
                                setAddressId={setAddressId} 
                                setShipper={setShipper}
                            />
                        </Box>
                        {shipper && 
                        <Box>
                            <Stack>
                                <Box>{shipper.name}</Box> 
                                <Box>{shipper.company}</Box>
                                <Box>{shipper.address}</Box>
                                <Box>{shipper.phone}</Box>
                            </Stack>
                        </Box>
                        }
                    </Box>
                </Box>
                }

                {type === 'agent' && (
                    <>
                    <Box h='30px'>
                    <Divider />
                    </Box>
                    <FormControl isRequired isInvalid={isError.indexOf('agentCode') !== -1}>
                        <FormLabel>Agent Code</FormLabel>
                        <Input 
                            type='text' 
                            size='sm'
                            value={agentCode}
                            onChange={e=>setAgentCode(e.target.value)}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'agentCode'))}
                            maxLength={20}
                            
                            />
                    </FormControl>
                    <FormControl isRequired isInvalid={isError.indexOf('rate') !== -1}>
                        <FormLabel>Rate</FormLabel>
                        <Select size='sm' placeholder='Select' onChange={e=>{setRate(e.target.value);setIsError(isError.filter(x => x !== 'rate'))}}>
                            {ratenames && ratenames.map((rn,idx)=>(
                                <option key={idx} value={rn._id}>{rn.name}</option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl isRequired isInvalid={isError.indexOf('sale') !== -1}>
                    <FormLabel>Sale Person</FormLabel>
                    <Select size='sm' placeholder='Select' onChange={e=>{setSale(e.target.value);setIsError(isError.filter(x => x !== 'sale'))}}>
                        {salesnames && salesnames.map((sn,idx)=>(
                            <option key={idx} value={sn._id}>{sn.name}</option>
                        ))}
                    </Select>
                    </FormControl>
                    </>
                )}
                

                <Box mt='6'></Box>
                <HStack>
                <Link to='/user' >
                    <Button 
                        isLoading={loading}
                        size='sm'
                        w='200px'
                        colorScheme='blue'
                        leftIcon={<Icon as={MdReply} />} 
                    >Cancel</Button>
                </Link>
                             
                <Spacer />
                <Button 
                    isLoading={loading}
                    size='sm'
                    w='200px'
                    colorScheme='green'
                    leftIcon={<Icon as={MdSaveAlt} />} 
                    onClick={handleSubmit}
                >Submit</Button>
                </HStack>   
            </Stack>        
        </Box>
    )
}

export default UserRegisterScreen