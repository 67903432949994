import {
        DOMESTICRATE_CREATE_FAIL,
        DOMESTICRATE_CREATE_REQUEST,
        DOMESTICRATE_CREATE_RESET,
        DOMESTICRATE_CREATE_SUCCESS,
        DOMESTICRATE_LIST_FAIL,
        DOMESTICRATE_LIST_REQUEST,
        DOMESTICRATE_LIST_RESET,
        DOMESTICRATE_LIST_SUCCESS,
        DOMESTICRATE_DELETE_REQUEST,
        DOMESTICRATE_DELETE_SUCCESS,
        DOMESTICRATE_DELETE_FAIL,
        DOMESTICRATE_DELETE_RESET,

} from '../../constants/domestic_constants/domesticRateConstants'

export const domesticRateCreateReducer = (state={}, action) => {
    switch(action.type){
        case    DOMESTICRATE_CREATE_REQUEST:
            return {loading: true}
        case    DOMESTICRATE_CREATE_SUCCESS:
            return {loading: false, success: true}
        case    DOMESTICRATE_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case    DOMESTICRATE_CREATE_RESET:
            return {}
        default: return state
    }
}

export const domesticRateListReducer = (state={rates:[]}, action) => {
    switch(action.type){
        case    DOMESTICRATE_LIST_REQUEST:
            return {loading: true, success: false, error: false}
        case    DOMESTICRATE_LIST_SUCCESS:
            return {loading: false, success: true, rates: action.payload}
        case    DOMESTICRATE_LIST_FAIL:
            return {loading: false, success: false, error: action.payload}
        case    DOMESTICRATE_LIST_RESET:
            return {}
        default: return state
    }
}

export const domesticRateDeleteReducer = (state={}, action) => {
    switch(action.type){
        case    DOMESTICRATE_DELETE_REQUEST:
            return {loading: true}
        case    DOMESTICRATE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case    DOMESTICRATE_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case    DOMESTICRATE_DELETE_RESET:
            return {}
        default: return state
    }
}