import React, {useState, useRef} from 'react'
import { useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import {
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    HStack,
    Checkbox,
    Center,
    Grid,
    Text,
    Stack,
    Spacer,
  } from '@chakra-ui/react'

const PrintStickerModal = ({idx}) => {

    function addMonths(date, months){
        var result = new Date(date)
        result.setMonth(result.getMonth()+months)
        var dateString = result.toISOString()
        return dateString.substring(0,10)
    }
    

    const componentRef = useRef()
    const [show, setShow] = useState(false)

    const {preshipment} = useSelector(state=>state.preshipment.ById)
    const [item, setItem] = useState(preshipment.items[idx].item)
    const [weight, setWeight] = useState(preshipment.items[idx].weight)
    const [brandCheck, setBrandCheck] = useState(false)
    const [titleCheck, setTitleCheck] = useState(false)
    const [nameCheck, setNameCheck] = useState(true)
    const [shipByCheck, setShipByCheck] = useState(false)
    const [weightCheck, setWeightCheck] = useState(true)
    const [descriptionCheck, setDescriptionCheck] = useState(true)
    const [ingredientCheck, setIngredientCheck] = useState(true)
    const [manuCheck, setManuCheck] = useState(true)
    const [manuCountryCheck, setManuCountryCheck] = useState(false)
    const [mfg, setMfg] = useState(new Date().toISOString().substring(0,10))
    const [exp, setExp] = useState(addMonths(new Date().toISOString().substring(0,10), 4))
    const [dateCheck, setDateCheck] = useState(false)
    const [familyCheck, setFamilyCheck] = useState(false)
    const [homeMadeCheck, setHomeMadeCheck] = useState(false)
    const handleClose = () => {
        setShow(false)        
    }

    const pageStyle = `
    @page {
        size: 3.2in 4in;
        margin: 2mm 2mm;
    }
    html, body {
        font-size: 13px;
        font-weight: 400;
    }
    `;

    return (
    <>
        <Button size='xs' colorScheme='blue' onClick={()=>setShow(true)}>Print Sticker</Button>

        <Modal isOpen={show} onClose={handleClose} size='5xl' closeOnOverlayClick={false} minW='800px'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Print Sticker {item.code}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>

                <Grid templateColumns='repeat(2, 1fr)' gap={6}>
                <Stack spacing={'2'} >
                    <HStack>
                        <Checkbox size='lg' defaultChecked={titleCheck} onChange={()=>setTitleCheck(!titleCheck)} /> 
                        <Box fontWeight={'600'}>Item & Description</Box>
                    </HStack>
                    <Stack>
                        <HStack>
                            <Checkbox size='lg' defaultChecked={nameCheck} onChange={()=>setNameCheck(!nameCheck)} /> 
                            <Box fontWeight={'600'}>Name</Box>
                        </HStack>
                        <Input 
                            type={'text'}
                            size='sm'
                            value={item.name}
                            onChange={e=>setItem({...item, name: e.target.value})}
                        /> 
                    </Stack>
                    <Box w='100%' borderBottom={'1px'} color='teal'></Box>

                    <Stack>
                        <HStack>
                            <Checkbox size='lg' defaultChecked={brandCheck} onChange={()=>setBrandCheck(!brandCheck)} />  
                            <Box fontWeight={'600'}>Brand Name</Box>
                        </HStack>
                                          
                        <Input 
                            type={'text'}
                            size='sm'
                            value={item.brandName}
                            onChange={e=>setItem({...item, brandName: e.target.value})}
                        />
                    </Stack>
                    <Box w='100%' borderBottom={'1px'} color='teal'></Box>

                    <Stack>
                        <HStack>
                            <Checkbox size='lg' defaultChecked={descriptionCheck} onChange={()=>setDescriptionCheck(!descriptionCheck)} />
                            <Box fontWeight={'600'}>Description</Box>
                        </HStack>
                                           
                        <Input 
                            type={'text'}
                            size='sm'
                            value={item.description}
                            onChange={e=>setItem({...item, description: e.target.value})}
                        />
                    </Stack>
                    <Box w='100%' borderBottom={'1px'} color='teal'></Box>

                    <Stack>
                        <HStack>
                            <Checkbox size='lg' defaultChecked={ingredientCheck} onChange={()=>setIngredientCheck(!ingredientCheck)} />                     
                            <Box fontWeight={'600'}>Ingredient</Box>
                        </HStack>
                        
                        <Input 
                            type={'text'}
                            size='sm'
                            value={item.ingredients}
                            onChange={e=>setItem({...item, ingredients: e.target.value})}
                        />
                    </Stack>
                    <Box w='100%' borderBottom={'1px'} color='teal'></Box>

                    <Stack>
                        <HStack>
                            <Checkbox size='lg' defaultChecked={weightCheck} onChange={()=>setWeightCheck(!weightCheck)} /> 
                            <Box fontWeight={'600'}>Weight</Box>
                        </HStack>
                        <Input 
                            type={'text'}
                            size='sm'
                            value={weight}
                            onChange={e=>setWeight(e.target.value)}
                        /> 
                    </Stack>
                    <Box w='100%' borderBottom={'1px'} color='teal'></Box>


                    <Stack>
                        <HStack>
                            <Checkbox size='lg' defaultChecked={manuCheck} onChange={()=>setManuCheck(!manuCheck)} /> 
                            <Box fontWeight={'600'}>Manufactured</Box>
                        </HStack>
                        
                        <Stack>               
                            <Input 
                                type={'text'}
                                size='sm'
                                value={item.manuName}
                                onChange={e=>setItem({...item, manuName: e.target.value})}
                            />
                                            
                            <Input 
                                type={'text'}
                                size='sm'
                                value={item.manuAddress}
                                onChange={e=>setItem({...item, manuAddress: e.target.value})}
                            />
                        </Stack>    
                    </Stack>
                    <Box w='100%' borderBottom={'1px'} color='teal'></Box>
                    <Stack>
                        <HStack>
                            <Checkbox size='lg' defaultChecked={homeMadeCheck} onChange={()=>setHomeMadeCheck(!homeMadeCheck)} />                     
                            <Box fontWeight={'600'}>Home-made Goods</Box>
                        </HStack>
                    </Stack>
                    <Box w='100%' borderBottom={'1px'} color='teal'></Box>

                    <Stack>
                        <HStack>
                            <Checkbox size='lg' defaultChecked={shipByCheck} onChange={()=>setShipByCheck(!shipByCheck)} />                     
                            <Box fontWeight={'600'}>SHIPPER BY: SIAM MOEI CO.,LTD.</Box>
                        </HStack>
                    </Stack>
                    <Box w='100%' borderBottom={'1px'} color='teal'></Box>

                    <Stack>
                        <HStack>
                            <Checkbox size='lg' defaultChecked={familyCheck} onChange={()=>setFamilyCheck(!familyCheck)} />                     
                            <Box fontWeight={'600'}>Family Gifts</Box>
                        </HStack>
                    </Stack>
                    <Box w='100%' borderBottom={'1px'} color='teal'></Box>

                    <Stack>
                        <HStack>
                            <Checkbox size='lg' defaultChecked={manuCountryCheck} onChange={()=>setManuCountryCheck(!manuCountryCheck)} />                
                            <Box fontWeight={'600'}>Product of Thailand</Box>
                        </HStack>
                    </Stack>
                    <Box w='100%' borderBottom={'1px'} color='teal'></Box>


                    <Stack>
                        <HStack>
                            <Checkbox size='lg' defaultChecked={dateCheck} onChange={()=>setDateCheck(!dateCheck)} />    
                            <Box fontWeight={'600'}>MFG/EXP Date</Box>
                        </HStack>
                       
                        <HStack>              
                            <Input 
                                type={'date'}
                                size='sm'
                                value={mfg}
                                onChange={e=>setMfg(e.target.value)}
                            />
                                <Input 
                                type={'date'}
                                size='sm'
                                value={exp}
                                onChange={e=>setExp(e.target.value)}
                            />
                        </HStack>  
                    </Stack>
                </Stack>
                
                <Box>
                    <Center>
                        <Box mb='5'>
                            <ReactToPrint
                            trigger={() => <Button size='sm' w='100px' colorScheme='green' >Print</Button>}
                            content={() => componentRef.current}
                            pageStyle={()=>pageStyle}
                            />
                        </Box>
                    </Center>
                    
                    <Center boxShadow={'2xl'} p='3'>
                        <Box ref={componentRef}>
                            <Stack spacing={'0'} border='1px' fontSize='14px' fontWeight='600' borderRadius={'10'} minH={'3.9in'} w={'3.1in' } p='3' >
                                {titleCheck &&
                                <Center borderBottom={'1px'} ><Text fontSize='' >ITEM-{idx+1} DESCRIPTION</Text></Center>
                                }

                                <Text borderBottom={'1px'} fontSize='md' fontWeight={'600'}>{item.name.toUpperCase()}</Text>                                
                                <Text>{weight} &emsp; KGM</Text>
                                {item.thaiName &&
                                    <Box>
                                        <Text>{item.thaiName}</Text>
                                    </Box>                                
                                }                                
                                                         
                                {brandCheck && <Text>{item.brandName}</Text>}
                                {descriptionCheck && <Text>{item.description}</Text> }
                                {ingredientCheck && 
                                <>
                                    <Stack spacing={'0'}>
                                        <Text as='u'>Ingredient</Text>
                                        <Text>{item.ingredients}</Text>
                                    </Stack>
                                </>
                                }
                                
                                {manuCheck && 
                                <>
                                    <Stack spacing={'0'}>
                                        <Text as='u'>Manufactured</Text>
                                        <Text ps='2'>{item.manuName}</Text>
                                        <Text ps='2'>{item.manuAddress}</Text>
                                    </Stack>
                                </>
                                }
                                {shipByCheck && <Text>SHIPPER BY: SIAM MOEI CO.,LTD.</Text>}
                                {homeMadeCheck && <Text>Home-made Goods</Text>}
                                {familyCheck && <Text>Family Gifts</Text>}
                                {manuCountryCheck && <Text fontSize={'md'}>Product of Thailand</Text>}
                              
                                {dateCheck && <HStack><Text>MFG: {mfg}</Text><Spacer /><Text>EXP: {exp}</Text></HStack>}
                                
                            </Stack>
                        
                            
                            
                            
                        
                        </Box> 
                    </Center> 
                </Box>
            </Grid>
            </ModalBody>

            <ModalFooter>
                
            </ModalFooter>
        </ModalContent>
        </Modal>
    </>
    )
}

export default PrintStickerModal