import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ReactToPrint from 'react-to-print'
import { useNavigate } from 'react-router-dom'
import {
  HStack,  
  Input,
  Button,
  Box,
  Spacer,
  Spinner,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Center,
  Heading,
  Text,
} from '@chakra-ui/react'
import Error from '../../components/Error'
import { dailyShipments } from '../../actions/shipmentActions'

const AgentDailyReportScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const componentRef = useRef()
  const [date, setDate] = useState('')
  const {user} = useSelector(state=>state.login)
  const {loading, error, shipments} = useSelector(state=>state.shipment.Daily)
 
  const pageStyle = `
        @page {
            size: A4 !important;
            margin: 2mm 2mm;
        }
        html, body {
            font-size: 16px;
            font-weight: 400;
        }
        `;
        
  useEffect(()=>{
    if(user && user.accountType !== 'agent'){
        navigate('/')
    }
    dispatch(dailyShipments(date))
  },[dispatch, user, navigate, date])


  return (
    <Box>
        <HStack spacing='4' my='4'>          
          <Input 
            type='date'
            size='sm'
            w='200px'
            placeholder='Start'
            value={date}
            onChange={e=> setDate(e.target.value)}
        />
        <Spacer />
        <Box>
            {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
        </Box>
        <Spacer />
        <Box>   
            <ReactToPrint
                trigger={() => <Button size='sm' w='100px' colorScheme='blue' >Print</Button>}
                content={() => componentRef.current}
                pageStyle={()=>pageStyle}
            />
        </Box>
        </HStack>
        <Divider />

        <Box my={'2'}>
          {error && <Error error={error} />}
        </Box>

        <Box ref={componentRef}>
            <Center><Heading>{date === '' ? 'Today' : date}</Heading></Center>
            <Table size='sm' variant='striped' colorScheme='black' my='4'>
            <Thead>
                <Tr>
                <Th>No</Th>
                <Th>AWB</Th>
                <Th>Receiver</Th>
                <Th>Ship Date</Th>
                <Th>Weight</Th>
                <Th>Charges</Th>
                <Th isNumeric>Shipment Cost</Th>
                <Th isNumeric>Total Amount</Th>
                </Tr>
            </Thead>
            <Tbody>
                {shipments && shipments.map((sm,index)=>(
                <Tr key={index}>
                    <Td>{index+1}</Td>
                    <Td>{sm.awb}</Td>                   
                    <Td>
                        <Text fontWeight={'600'}>{sm.customerDetails.receiverDetails.contactInformation.fullName}</Text>
                        <Text fontSize={'xs'}>{sm.customerDetails.receiverDetails.postalAddress.addressLine1}</Text>
                        <Text fontSize={'xs'}>{sm.customerDetails.receiverDetails.postalAddress.cityName}, {sm.customerDetails.receiverDetails.postalAddress.countryCode}</Text>
                    </Td>
                    <Td>{sm.createdAt.substring(0,10)}</Td>
                    <Td>{sm.totalWeight}</Td>
                    <Td>
                      <Table  size='xs' variant='striped' colorScheme='black'>
                        <Thead>
                            <Tr>
                                <Th>Des</Th>
                                <Th isNumeric>Value</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sm.charges.map((ch, idx)=> (
                                <Tr key={idx}>
                                    <Td>{ch.name}</Td>
                                    <Td isNumeric>{ch.amount}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th>Total</Th>
                                <Th isNumeric>{sm.charges.reduce((acc, ch) => acc + ch.amount, 0)}</Th>
                            </Tr>
                        </Tfoot>
                      </Table>
                    </Td>
                    <Td isNumeric>{(sm.totalAmount - sm.charges.reduce((acc, c) => acc + c.amount, 0) ).toLocaleString()}</Td>
                    <Td isNumeric>{sm.totalAmount.toLocaleString()}</Td>
                </Tr>
                ))}
            </Tbody>
            </Table>
            <HStack>
                <Box></Box>
                <Spacer />
                <Box fontSize={'3xl'} pr='4'>
                    Total - {shipments && (shipments.reduce((acc, s) => acc + s.totalAmount, 0)).toLocaleString()}
                </Box>
            </HStack>
        </Box>
    </Box>
  )
}

export default AgentDailyReportScreen