import axios from 'axios'
import { 
    ZONE_CREATE_FAIL,
    ZONE_CREATE_REQUEST,
    ZONE_CREATE_SUCCESS,
    ZONE_DELETE_FAIL,
    ZONE_DELETE_REQUEST,
    ZONE_DELETE_SUCCESS,
    ZONE_LIST_FAIL,
    ZONE_LIST_REQUEST, 
    ZONE_LIST_SUCCESS,
    ZONE_RATE_FAIL,
    ZONE_RATE_REQUEST,
    ZONE_RATE_SUCCESS,
    ZONE_UPDATE_FAIL,
    ZONE_UPDATE_REQUEST,
    ZONE_UPDATE_SUCCESS,
} from '../constants/zoneConstants'

export const getZoneList = () => async (dispatch, getState) => {
    try {
        dispatch({type: ZONE_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/zone`, config)
        dispatch({type: ZONE_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ZONE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getZoneRate = () => async (dispatch, getState) => {
    try {
        dispatch({type: ZONE_RATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/zone/rate`, config)
        dispatch({type: ZONE_RATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ZONE_RATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addNewZone = (newZone) => async (dispatch, getState) => {
    try {
        dispatch({type: ZONE_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/zone`, newZone, config)
        dispatch({type: ZONE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ZONE_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateZone = (zoneDetail, zoneId) => async (dispatch, getState) => {
    try {
        dispatch({type: ZONE_UPDATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/zone/${zoneId}`, zoneDetail, config)
        dispatch({type: ZONE_UPDATE_SUCCESS})
    } catch (error) {
        dispatch({
            type: ZONE_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const deleteZone = (zoneId) => async (dispatch, getState) => {
    try {
        dispatch({type: ZONE_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/zone/${zoneId}`, config)
        dispatch({type: ZONE_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: ZONE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}
