const statusCodes = {
    "AA":"Shipment created",
    "ZZ":"No response",
    "3M":"3 Months over. Tracking number expired",
    "AD":"Agreed delivery",
    "AF":"Arrived facility",
    "AR":"Arrival in delivery facility",
    "BA":"Bad address",
    "BN":"Customer broker notified",
    "BR":"Broker release",
    "CA":"Closed on arrival",
    "CC":"Awaiting cnee collection",
    "CD":"Controllable clearance delay",
    "CM":"Customer moved",
    "CR":"Clearance release",
    "CS":"Closed shipment",
    "DD":"Delivered damaged",
    "DF":"Depart facility",
    "DS":"Authorized request received",
    "FD":"Forward destination (DD's expected)",
    "HP":"Held for payment",
    "IC":"In clearance processing",
    "MC":"Miscode",
    "MD":"Missed delivery cycle",
    "MS":"Mis-sort",
    "ND":"Not delivered",
    "NH":"Not home",
    "OH":"On hold",
    "OK":"Delivery",
    "PD":"Partial delivery",
    "PL":"Processed at location",
    "PU":"Shipment pick up",
    "PY":"Payment received",
    "RD":"Refused delivery",
    "RR":"Response received",
    "RT":"Returned to consignor",
    "SA":"Shipment acceptance",
    "SC":"Service changed",
    "SM":"Scheduled to depart",
    "SS":"Shipment stopped",
    "TP":"Forwarded to 3rd party - no DD's",
    "TR":"Record of transfer",
    "UD":"Uncontrollable clearance delay",
    "WC":"With delivering courier"
}

export {statusCodes}