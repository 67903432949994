import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Button,
    Center,
    Heading,
    Spinner,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react'
import { getRateList, deleteRate } from '../../actions/domestic_actions/domesticRateActions'
import Error from '../../components/Error'

const DomesticRateListScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { loading, error, success, rates } = useSelector(state=>state.domestic.RateList)
    const { loading: loadingDelete, error: errorDelete, success: successDelete} = useSelector(state=>state.domestic.RateDelete)

    useEffect(()=>{
        dispatch(getRateList())
    },[dispatch, successDelete])


    return (
        <Box>
            <Box my='4'>
                <Center><Heading>Domestic Rates {(loading || loadingDelete) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Heading></Center>
                {error && <Error error={error} /> }
                {errorDelete && <Error error={errorDelete} /> }
                <Button colorScheme='red' onClick={()=>navigate('/domesticrate/add')}>Add Rate</Button>
            </Box>
            

            <Box mb={'50px'}>
                {success && rates.length === 0 ? 
                <>
                  <Box bg='red.200' w='100%' p='3' textAlign={'center'} borderRadius={'15'}> No Rate To Show </Box>
                </> :
                success && 
                <Box>
                  <Table colorScheme='red' size='sm' >
                    <Thead>
                      <Tr>
                          <Th border='1px' borderColor='gray.200'>kg</Th>
                          {rates.map(rn=>
                              <Th key={rn._id} m={0} px={0} py={2} textAlign={'center'} border='1px' borderColor='gray.200'>{rn.carrier}
                                <Button mx={2} size='xs' style={{height: '20px'}} colorScheme='red' onClick={()=>dispatch(deleteRate(rn._id))}>x</Button>
                              </Th>
                          )}
                          <Th></Th>
                      </Tr>
 
                    </Thead>
                    <Tbody>
                      {[...Array(30).keys()].map((idx)=>(
                        <Tr key={idx} >
                            {[...Array(rates.length+1).keys()].map((a)=>(
                              <Td key={a} bg={idx%2 === 0 && 'red.100'} style={{width: '100px'}} border='1px' borderColor='gray.200' >{a === 0 ? idx+1 : (rates[a-1]?.rate[idx]?.value ?? 0)}</Td>
                            ))}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
                }
            </Box>



        </Box>
    )
}

export default DomesticRateListScreen