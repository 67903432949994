import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Box,
    Spinner,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Checkbox,
} from '@chakra-ui/react'
import { listInvoiceShipments } from '../actions/shipmentActions'
import { addShipmentsToInvoice } from '../actions/invoiceActions'
import { INVOICE_ADDSHIPMENTS_RESET } from '../constants/invoiceConstants'
import Marks from '../components/Marks'

const AddShipmentToInvoiceModal = ({agentId, invocieId, disabled}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [allCheck, setAllCheck] = useState(false)
    const [idList, setIdList] = useState([])
    const {loading: loadingShipments, error: errorShipments, shipments} = useSelector(state=>state.shipment.ForInvoice)
    const {loading: loadingAddShipment, error: errorAddShipment, success} = useSelector(state=>state.invoice.AddShipment)
    const untilDate = new Date().toISOString().substring(0,10)

    useEffect(()=>{        
        if(show){
            dispatch(listInvoiceShipments(agentId, untilDate))
        }
        if(success){
            dispatch({type: INVOICE_ADDSHIPMENTS_RESET})   
            setIdList([])
            setAllCheck(false)
            setShow(false) 
        }
    },[show, agentId, success, untilDate, dispatch])

    const handleClose = () => { 
        dispatch({type: INVOICE_ADDSHIPMENTS_RESET})   
        setIdList([])
        setAllCheck(false)
        setShow(false)
    }

    const handleCheckBox = async (shipmentId) =>{
        const index = idList.indexOf(shipmentId)
        if(index < 0){
            setIdList([...idList, shipmentId])
            if(idList.length === shipments.length-1){
                setAllCheck(true)
            }
        } else {
            const newIdList = idList
            newIdList.splice(index, 1)
            setIdList([...newIdList])
            if(idList.length === 0){
                setAllCheck(false)
            }
        }
    }

    const handleCheckAll = async () => {
        if(allCheck === false){
            const ids = shipments.map(s=>s._id)
            setIdList([...ids])
            setAllCheck(true)
        } else {
            setIdList([])
            setAllCheck(false)
        }
    }

    const handleSubmit = () => {
        if(idList.length !== 0) {
            dispatch(addShipmentsToInvoice(invocieId, idList))
        }
        return
    }


    return (
        <>
            <Button size='sm' w='150px' disabled={disabled} colorScheme={'yellow'} onClick={()=>setShow(true)} >Add Shipments</Button>

            <Modal isOpen={show} onClose={handleClose} size='5xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Shipments</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        {loadingShipments && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                        {loadingAddShipment && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                    </Box>
                    <Box  my='3' >
                        {errorShipments && 
                            <Alert status='error' variant='left-accent'>
                            <AlertIcon />
                            <AlertTitle mr={2}>Error getting shipments.</AlertTitle>
                            <AlertDescription>{errorShipments}</AlertDescription>
                            </Alert>
                        }
                        {errorAddShipment && 
                            <Alert status='error' variant='left-accent'>
                            <AlertIcon />
                            <AlertTitle mr={2}>Error adding shipments.</AlertTitle>
                            <AlertDescription>{errorAddShipment}</AlertDescription>
                            </Alert>
                        }
                    </Box>  

                    <Table size='md' variant='striped' colorScheme='yellow' mt='4'>
                        <Thead>
                            <Tr>
                            <Th><Checkbox 
                                size='lg' 
                                colorScheme='green'
                                onChange={handleCheckAll}
                                //onClick={()=>setAllCheck(!allCheck)}
                                disabled={shipments && shipments.length === 0}
                                isChecked={shipments && shipments.length === idList.length}
                                >All</Checkbox></Th>
                            <Th>No</Th>
                            <Th>AWB</Th>
                            <Th>Shipped Date</Th>
                            <Th>Total Weight</Th>
                            <Th>Total Amount</Th>
                            
                            </Tr>
                        </Thead>
                        <Tbody>
                            {shipments && shipments.map((s,idx)=>(
                            <Tr key={idx}>
                                <Td><Checkbox 
                                    size='lg' 
                                    colorScheme='green'
                                    onChange={()=>handleCheckBox(s._id)}
                                    isChecked={idList.indexOf(s._id) >= 0}
                                ></Checkbox></Td>
                                <Td>{idx+1}</Td>
                                <Td>
                                    {s.awb}
                                    {s.isReturned && <Marks bgColor='teal' text='R' />}  
                                    {s.isVplus && <Marks bgColor='purple' text='V' />} 
                                </Td>
                                <Td>{s.createdAt.substring(0,10)}</Td>
                                <Td>{s.totalWeight}</Td>
                                <Td>{s.totalAmount}</Td>
                                
                            </Tr>
                            ))}
                        </Tbody>
                    </Table>    
                </ModalBody>

                <ModalFooter>
                    <Button size='sm' w='150px' colorScheme={'green'} onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

AddShipmentToInvoiceModal.defaultProps = {
    disable: false,
}

export default AddShipmentToInvoiceModal