import axios from 'axios'
import {
    CLEAR_DATA_REQUEST,
    CLEAR_DATA_SUCCESS,
    CLEAR_DATA_FAIL,
    BALANCE_LOG_REQUEST,
    BALANCE_LOG_SUCCESS,
    BALANCE_LOG_FAIL,
    USER_LOG_REQUEST,
    USER_LOG_SUCCESS,
    USER_LOG_FAIL,
    GET_DATA_REQUEST,
    GET_DATA_SUCCESS,
    GET_DATA_FAIL,
    REMOTE_AREA_CHECK_REQUEST,
    REMOTE_AREA_CHECK_SUCCESS,
    REMOTE_AREA_CHECK_FAIL,
    SET_REMOTE_RATE_REQUEST,
    SET_REMOTE_RATE_SUCCESS,
    SET_REMOTE_RATE_FAIL,
    GET_REMOTE_RATE_REQUEST,
    GET_REMOTE_RATE_SUCCESS,
    GET_REMOTE_RATE_FAIL
} from '../constants/systemConstants'


export const removeData = (month) => async (dispatch, getState) => {
    try {
        dispatch({type: CLEAR_DATA_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/system/cleardata?month=${month}`, {}, config)
        dispatch({type: CLEAR_DATA_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: CLEAR_DATA_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getBalanceLogs = () => async (dispatch, getState) => {
    try {
        dispatch({type: BALANCE_LOG_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/system/balancelog`, config)
        dispatch({type: BALANCE_LOG_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: BALANCE_LOG_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getUserLogs = (date) => async (dispatch, getState) => {
    try {
        dispatch({type: USER_LOG_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/system/userlog?date=${date}`, config)
        dispatch({type: USER_LOG_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: USER_LOG_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getSystemData = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_DATA_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/system/data`, config)
        dispatch({type: GET_DATA_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: GET_DATA_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const checkUSRemoteArea = (dataToGet ) => async (dispatch, getState) => {
    try {
        const { countryCode, postalCode, selectedCity } = dataToGet
        dispatch({type: REMOTE_AREA_CHECK_REQUEST})
        const {data} = await axios.get(`/api/system/usremotearea?postalcode=${postalCode}&countrycode=${countryCode}&selectedcity=${selectedCity}`)
        dispatch({type: REMOTE_AREA_CHECK_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: REMOTE_AREA_CHECK_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const setRemoteRate = (data) => async (dispatch, getState) => {
    try {
        dispatch({type: SET_REMOTE_RATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/system/remoterate`, data, config)
        dispatch({type: SET_REMOTE_RATE_SUCCESS})
    } catch (error) {
        dispatch({
            type: SET_REMOTE_RATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getRemoteRate = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_REMOTE_RATE_REQUEST})
        const {login: {user}} = getState()
      
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/system/remoterate`, config)
        dispatch({type: GET_REMOTE_RATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: GET_REMOTE_RATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}