import React, {useState} from 'react'
import {
    Stack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'

const ViewDocumentModal = ({doc, fileName}) => {
    const [show, setShow] = useState(false)
    const handleClose = () => {
        setShow(false)
    }
    const base64toBlob = (data) => {

        const bytes = window.atob(data)
        //const bytes = Buffer.from(data, 'utf-8').toString('base64')
        let length = bytes.length
        let out = new Uint8Array(length)

        while (length--) {
            out[length] = bytes.charCodeAt(length)
        }

        return new Blob([out], { type: 'application/pdf' })
        
    }
    const url = URL.createObjectURL(base64toBlob(doc.content))
    return (
        <>
            <Button size='sm' w='' h='25px' colorScheme={'green'} onClick={()=>setShow(true)} >View</Button>

            <Modal isOpen={show} onClose={handleClose} size='5xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{doc.typeCode}</ModalHeader>
                <ModalCloseButton />
                <ModalBody style={{width: doc.typeCode === 'label' ? '500px' : ''}}>
                    <Stack spacing={'2'}>                        
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js"> */}
                            <Viewer fileUrl={url} />                            
                        </Worker>                        
                    </Stack>
                </ModalBody>
            </ModalContent>
            </Modal>
        </>
    )
}

export default ViewDocumentModal