import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Center,
    Heading,
    Spinner,
    Grid,
    Select,
    Stack,
    OrderedList,
    ListItem,
    Button,
    useToast
} from '@chakra-ui/react'
import { listRate, getRateAgents, trasferRate } from '../actions/rateActions'
import Error from '../components/Error'
import { MdDoubleArrow } from 'react-icons/md'
import { RATE_AGENTNAMES_RESET } from '../constants/rateConstants'

const RateTransferScreen = () => {
    const toast = useToast()
    const dispatch = useDispatch()
    const [oldRateId, setOldRateId] = useState('')
    const [newRateId , setNewRateId] = useState('')
    const [nextRateId, setNextRateId] = useState('')
    const {loading, error, rates} = useSelector(state=>state.rate.List)
    const {loading:loadingAgents, error:errorAgents, agentNames} = useSelector(state=>state.rate.Agents)
    const {loading:loadingTransfer, error:errorTransfer, success} = useSelector(state=>state.rate.Transfer)

    useEffect(()=>{
        dispatch(listRate(true))
        if(success){
            toast({
                title: 'Rate Transferred  successfully',
                position: 'top-right',
                status: 'success',
                duration: 3000,
                isClosable: true,
              })
            dispatch({type: RATE_AGENTNAMES_RESET})
        }
        
    },[dispatch, success, toast])

    const handleRateSelected = (e) => {
        if(e.target.value){
            dispatch(getRateAgents(e.target.value))
            setOldRateId(e.target.value)
        }
        return
    }

    const handleSubmit = () => {
        const rateData = {
            oldRateId,
            newRateId,
            nextRateId,
        }
        //console.log(oldRateId, newRateId)
        dispatch(trasferRate(rateData))
    }

    return (
        <Box mb='100px'>
            <Center><Heading py='4'> Rate Transfer {(loading || loadingAgents || loadingTransfer) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Heading></Center>
            <Box w='100%' borderBottom={'1px'}></Box>
            {error && <Error error={error} /> }
            {errorAgents && <Error error={errorAgents} /> }
            {errorTransfer && <Error error={errorTransfer} /> }


            {rates &&            
                <Box mt='80px'>
                    <Grid
                        templateRows='repeat(1, 1fr)'
                        templateColumns='repeat(3, 1fr)'
                        gap={4}
                        >
                        <Box>
                            <Stack spacing={3}>
                                <Select variant='filled' placeholder='Old Rate' onChange={(e)=>handleRateSelected(e)}>
                                    {rates.map((r, i)=> (
                                        <option key={i} value={r._id}>{r.name}</option>
                                    ))}
                                </Select>
                                <OrderedList ps='30px'>
                                    {agentNames && agentNames.map((n, i)=>(
                                        <ListItem key={i}>{n.name}</ListItem>
                                    ))}
                                </OrderedList>
                            </Stack>
                        </Box>
                        <Box>
                            <Center className='quadrat'><MdDoubleArrow size={'40px'} /><MdDoubleArrow size={'40px'} /><MdDoubleArrow size={'40px'} /></Center>
                        </Box>
                        <Box>
                            <Select variant='filled' placeholder='New Rate' onChange={(e)=>setNewRateId(e.target.value)}>
                                {rates.map((r, i)=> (
                                    <option key={i} value={r._id} disabled={oldRateId === r._id}>{r.name}</option>
                                ))}
                            </Select>
                            <Select  mt={4} variant='filled' placeholder='Next Month Rate' onChange={(e)=>setNextRateId(e.target.value)}>
                                {rates.map((r, i)=> (
                                    <option key={i} value={r._id}>{r.name}</option>
                                ))}
                            </Select>
                            <Box mt='30px'>
                                <Button 
                                    size='sm' 
                                    w='100%' 
                                    colorScheme={'green'} 
                                    onClick={handleSubmit}
                                    disabled={oldRateId === ''}
                                    >Save</Button>
                            </Box>
                        </Box>
                    </Grid>

                </Box>
            }
            
            
        </Box>
    )
}

export default RateTransferScreen