import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import {
  Box,
  Center,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Spacer,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  InputGroup,
  InputLeftAddon,
  Heading,
} from '@chakra-ui/react'
import { MdSave, MdUndo } from 'react-icons/md'
import { getByIdItem, editItem, deleteItem } from '../actions/itemActions'
import { ITEM_EDIT_RESET, ITEM_DELETE_RESET, ITEM_BYID_RESET } from '../constants/itemConstants'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'

const ItemEditScreen = () => {
  const params = useParams()
  const location = useLocation()
  const itemId = params.id
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [thaiName, setThaiName] = useState('')
  const [brandName, setBrandName] = useState('')
  const [description, setDescription] = useState('')
  const [ingredients, setIngredients] = useState('')
  const [hsCode, setHsCode] = useState('')
  const [manuName, setManuName] = useState('')
  const [manuAddress, setManuAddress] = useState('')
  const [manuCountry, setManuCountry] = useState('')
  const [price, setPrice] = useState(0)
  const [mfg, setMfg] = useState('')
  const [exp, setExp] = useState('')
  const [isError, setIsError] = useState([])
  const {user} = useSelector(state=>state.login)
  const {loading: loadingEdit, error: errorEdit, success} = useSelector(state=> state.item.Edit)
  const {loading: loadingDelete, error: errorDelete, success: successDelete} = useSelector(state=> state.item.Delete)
  const {loading: loadingById, error: errorById, itemDetail} = useSelector(state=> state.item.ById)

  useEffect(()=>{
    
    if(success || successDelete){
      dispatch({type: ITEM_EDIT_RESET})
      dispatch({type: ITEM_DELETE_RESET})
      dispatch({type: ITEM_BYID_RESET})
      navigate(`${location.state.redirect}`)
    } else {      
      if(!itemDetail || itemDetail._id !== itemId){
        dispatch(getByIdItem(itemId))
      } else {
        setName(itemDetail.name || '')
        setThaiName(itemDetail.thaiName || '')
        setBrandName(itemDetail.brandName || '')
        setDescription(itemDetail.description || '')
        setIngredients(itemDetail.ingredients || '')
        setHsCode(itemDetail.hsCode || '')
        setManuName(itemDetail.manuName || '')
        setManuAddress(itemDetail.manuAddress || '')
        setManuCountry(itemDetail.manuCountry || '')
        setPrice(itemDetail.price || 0)
        setMfg(itemDetail.mfg || '')
        setExp(itemDetail.exp || '')
      }
    }
    return()=>{
      dispatch({type: ITEM_EDIT_RESET})
      
    }
  },[dispatch, itemId, success, successDelete, itemDetail, navigate, location])
  

  const handleSubmit = () => {
    const editedItem = {
      name,
      description,
      manuName,
      manuAddress,
      manuCountry,
      price
  }
  for (const [key, value] of Object.entries(editedItem)) {
      if(value === ''){
          isError.push(key)
          setIsError([...isError])
      }
  }
  if(isError.length === 0){
      editedItem.brandName = brandName
      editedItem.thaiName = thaiName
      editedItem.ingredients = ingredients
      editedItem.hsCode = hsCode
      editedItem.mfg = mfg
      editedItem.exp = exp
      // console.log(editedItem)
      dispatch(editItem(itemId, editedItem))
  } else {
      return
  }
  }

  return (
    <Center my={'4'}>
      <Box w='700px'>
        <Stack spacing={4}>
          <Box>
            {(loadingById || loadingEdit || loadingDelete) ? <Spinner label='Please wait' speed='0.1s' thickness='5px' /> : <Heading>Edit Item</Heading> }
          </Box>
          {errorById && 
            <Alert status='error' variant='left-accent'>
            <AlertIcon />
            <AlertTitle mr={2}>Error getting item detail.</AlertTitle>
            <AlertDescription>{errorById}</AlertDescription>
            </Alert>
          }
          {errorEdit && 
            <Alert status='error' variant='left-accent'>
            <AlertIcon />
            <AlertTitle mr={2}>Error updating item detail.</AlertTitle>
            <AlertDescription>{errorEdit}</AlertDescription>
            </Alert>
          }
          {errorDelete && 
            <Alert status='error' variant='left-accent'>
            <AlertIcon />
            <AlertTitle mr={2}>Error deleting item.</AlertTitle>
            <AlertDescription>{errorDelete}</AlertDescription>
            </Alert>
          }
          <FormControl>
              <FormLabel>Brand Name</FormLabel>
              <Input 
                  type='text' 
                  size='sm'
                  value={brandName}
                  onChange={e=>setBrandName(e.target.value)}
                  maxLength={50}
                  
                  />
          </FormControl>

          <FormControl isRequired isInvalid={isError.indexOf('name') !== -1}>
              <FormLabel>General Name</FormLabel>
              <Input 
                  type='text' 
                  size='sm'
                  value={name}
                  onChange={e=>setName(e.target.value)}
                  onKeyPress={()=>setIsError(isError.filter(x => x !== 'name'))}
                  maxLength={50}
                  
                  />
          </FormControl>
          <FormControl>
              <FormLabel>Thai Name</FormLabel>
              <Input 
                  type='text' 
                  size='sm'
                  value={thaiName}
                  onChange={e=>setThaiName(e.target.value)}                  
                  />
          </FormControl>
          <FormControl isRequired isInvalid={isError.indexOf('description') !== -1}>
              <FormLabel>Description</FormLabel>
              <Input 
                  as='textarea' 
                  size='sm'
                  height='100px'
                  value={description}
                  onChange={e=>setDescription(e.target.value)}
                  onKeyPress={()=>setIsError(isError.filter(x => x !== 'description'))}
                  maxLength={100}
                  
                  />
          </FormControl>
          <FormControl>
              <FormLabel>Ingredients/Others</FormLabel>
              <Input 
                  as='textarea' 
                  size='sm'
                  height='100px'
                  value={ingredients}
                  onChange={e=>setIngredients(e.target.value)}
                  maxLength={100}
                  
                  />
          </FormControl>
          <FormControl>
              <FormLabel>HS Code</FormLabel>
              <Input 
                  type='text' 
                  size='sm'
                  value={hsCode}
                  onChange={e=>setHsCode(e.target.value)}                  
                  />
          </FormControl>
          <HStack>
            <Box w={'50%'} >
                <FormControl>
                    <FormLabel>MFG Date</FormLabel>
                    <Input 
                        type='date' 
                        size='sm'
                        value={mfg}
                        onChange={e=>setMfg(e.target.value)}
                        />
                </FormControl>
            </Box>
            <Box w={'50%'} >
                <FormControl>
                    <FormLabel>EXP Date</FormLabel>
                    <Input 
                        type='date' 
                        size='sm'
                        value={exp}
                        onChange={e=>setExp(e.target.value)}
                        />
                </FormControl>
            </Box>
        </HStack>
          <FormControl isRequired isInvalid={isError.indexOf('manuName') !== -1}>
              <FormLabel>Manu,Name</FormLabel>
              <Input 
                  type='text' 
                  size='sm'
                  value={manuName}
                  onChange={e=>setManuName(e.target.value)}
                  onKeyPress={()=>setIsError(isError.filter(x => x !== 'manuName'))}
                  maxLength={50}
                  
                  />
          </FormControl>
          <FormControl isRequired isInvalid={isError.indexOf('manuAddress') !== -1}>
              <FormLabel>Manu,Address</FormLabel>
              <Input 
                  as='textarea' 
                  size='sm'
                  height='100px'
                  value={manuAddress}
                  onChange={e=>setManuAddress(e.target.value)}
                  onKeyPress={()=>setIsError(isError.filter(x => x !== 'manuAddress'))}
                  maxLength={100}
                  
                  />
          </FormControl>
          <FormControl isRequired isInvalid={isError.indexOf('manuCountry') !== -1}>
              <FormLabel>Manu,Country</FormLabel>
              <Input 
                  type='text' 
                  size='sm'
                  value={manuCountry}
                  onChange={e=>setManuCountry(e.target.value)}
                  onKeyPress={()=>setIsError(isError.filter(x => x !== 'manuCountry'))}
                  maxLength={50}
                  
                  />
          </FormControl>

          <FormControl>
              <FormLabel>Price</FormLabel>
              <InputGroup size='sm'>                        
                  <InputLeftAddon children='$ ' />
                  <Input 
                      type='number' 
                      size='sm'
                      value={price}
                      onChange={e=>setPrice(e.target.value)}
                      maxLength={10}                                    
                  />                        
              </InputGroup>
          </FormControl>

          <Box mt={'60px'}></Box>
          <HStack>
            <Button colorScheme='blue' w='150px' size='sm' onClick={()=>navigate('/item')} leftIcon={<MdUndo />}>
                Cancel
            </Button>
            <Spacer />
            <Box>
              <ConfirmDeleteModal deleteFunction={deleteItem} disable={!user.isAdmin} id={itemId} width='150px' />
            </Box>
            <Spacer />
            <Button 
                colorScheme='green' 
                isLoading={loadingEdit}
                w='150px' 
                size='sm' 
                leftIcon={<MdSave />} 
                onClick={handleSubmit}
                >Save</Button>
            </HStack>
            
        </Stack>
      </Box>
    </Center>
  )
}

export default ItemEditScreen