import { 
    ZONE_CREATE_FAIL,
    ZONE_CREATE_REQUEST,
    ZONE_CREATE_RESET,
    ZONE_CREATE_SUCCESS,
    ZONE_DELETE_FAIL,
    ZONE_DELETE_REQUEST,
    ZONE_DELETE_RESET,
    ZONE_DELETE_SUCCESS,
    ZONE_LIST_FAIL,
    ZONE_LIST_REQUEST, 
    ZONE_LIST_RESET, 
    ZONE_LIST_SUCCESS,
    ZONE_RATE_FAIL,
    ZONE_RATE_REQUEST,
    ZONE_RATE_RESET,
    ZONE_RATE_SUCCESS,
    ZONE_UPDATE_FAIL,
    ZONE_UPDATE_REQUEST,
    ZONE_UPDATE_RESET,
    ZONE_UPDATE_SUCCESS,
} from "../constants/zoneConstants"


export const zoneListReducer = (state={zones:[]}, action) => {
    switch(action.type){
        case ZONE_LIST_REQUEST:
            return {loading: true}
        case ZONE_LIST_SUCCESS:
            return {loading: false, success: true, zones: action.payload}
        case ZONE_LIST_FAIL:
            return {loading: false, error: action.payload}
        case ZONE_LIST_RESET:
            return {}
        default: return state
    }
}

export const zoneRateReducer = (state={zone:{zObject:{}, zArray:[], zNumArray:[], essList:[], newRateFormat:[], essCopy:[]}}, action) => {
    switch(action.type){
        case ZONE_RATE_REQUEST:
            return {loading: true}
        case ZONE_RATE_SUCCESS:
            return {
                loading: false, 
                success: true, 
                zone: {
                    zObject: action.payload.zObject,
                    zArray: action.payload.zArray,
                    zNumArray: action.payload.zNumArray,
                    essList: action.payload.essList,
                    newRateFormat: action.payload.newRateFormat,
                    essCopy: action.payload.essCopy
                }}
        case ZONE_RATE_FAIL:
            return {loading: false, error: action.payload}
        case ZONE_RATE_RESET:
            return {}
        default: return state
    }
}

export const zoneCreateReducer = (state={}, action) => {
    switch(action.type){
        case ZONE_CREATE_REQUEST:
            return {loading: true}
        case ZONE_CREATE_SUCCESS:
            return {loading: false, success: true}
        case ZONE_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case ZONE_CREATE_RESET:
            return {}
        default: return state
    }
}

export const zoneUpdateReducer = (state={}, action) => {
    switch(action.type){
        case ZONE_UPDATE_REQUEST:
            return {loading: true}
        case ZONE_UPDATE_SUCCESS:
            return {loading: false, success: true}
        case ZONE_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case ZONE_UPDATE_RESET:
            return {}
        default: return state
    }
}

export const zoneDeleteReducer = (state={}, action) => {
    switch(action.type){
        case ZONE_DELETE_REQUEST:
            return {loading: true}
        case ZONE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case ZONE_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case ZONE_DELETE_RESET:
            return {}
        default: return state
    }
}