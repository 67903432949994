import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box, 
    Button, 
    Center, 
    Divider, 
    Heading, 
    HStack, 
    Input, 
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
    Spinner, 
    Stack, 
    useToast,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    ListItem,
    UnorderedList,
} from '@chakra-ui/react'
import { removeData, getSystemData } from '../actions/systemActions'
import Error from '../components/Error'
import { CLEAR_DATA_RESET } from '../constants/systemConstants'
import { confirmPassword } from '../actions/userActions'
import { USER_CONFIRMPASSWORD_RESET } from '../constants/userConstants'

const ClearDataScreen = () => {
    const dispatch = useDispatch()
    const toast = useToast()
    const [password, setPassword] = useState('')
    const [show, setShow] = useState(false)
    const [month, setMonth] = useState('')
    const {loading, error, success} = useSelector(state=>state.system.clearData)
    const {loading:dataLoading, error:dataError, success:dataSuccess, data} = useSelector(state=>state.system.getData)
    const {loading:loadingPassword, error:errorPassword, success:successPassword} = useSelector(state=>state.user.ConfirmPassword)
    const {user} = useSelector(state=>state.login)

    useEffect(()=>{
        dispatch(getSystemData())
        if(success){
            toast({
                title: 'Success',
                description: "Data deleted.",
                status: 'success',
                duration: 2000,
                isClosable: true,
              })
            setPassword('')
            setMonth('')
            dispatch({type: CLEAR_DATA_RESET})
            dispatch({type: USER_CONFIRMPASSWORD_RESET})
        }
       
        return()=>{
            setMonth('')
            setPassword('')
            dispatch({type: CLEAR_DATA_RESET})
            dispatch({type: USER_CONFIRMPASSWORD_RESET})
        }
    },[success, toast, dispatch])

    useEffect(()=>{
        if(successPassword){            
            setShow(false)
            dispatch(removeData(month))
        }
        // eslint-disable-next-line
    },[successPassword])
    const handlePassword = () => {
        const userData = {
            userId: user._id,
            password
        }
        //console.log(userData)
        dispatch(confirmPassword(userData))
    }
    const handleClose = () => {
        setPassword('')
        setShow(false)
    }

    

    return (
        <Box>
            <>

                <Modal isOpen={show} onClose={handleClose} size='lg' closeOnOverlayClick={false} >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader pl='4'>
                    You are deleting the whole month data of {new Date(month).toLocaleDateString("en-US", {year: 'numeric', month: 'long'})}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>{loadingPassword && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                        <Box>
                            {errorPassword && <Error error={errorPassword} /> }
                        </Box>
                        <Stack spacing={'3'}>  
                            
                            <Stack spacing={'0'}>
                                <Box>Confirm Your Account Password</Box>
                                <Input 
                                    type={'password'}
                                    size='sm'
                                    value={password}
                                    onChange={e=>setPassword(e.target.value)}
                                />
                            </Stack>

                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button size='sm' w='150px' colorScheme={'green'} onClick={handlePassword} >OK</Button>
                    </ModalFooter>
                </ModalContent>
                </Modal>
            </>
            <Center bg='red' mb='5'><Heading>Data</Heading> {(loading || dataLoading) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' /> }</Center>
            {error && <Error error={error} /> }
            {dataError && <Error error={dataError} /> }
            <Box mb={3}>
                <Box my='4'>Select Month to Delete Data</Box>
                <HStack>                       
                    <Input 
                        type="month"
                        size='sm'
                        w='200px'
                        value={month}
                        onChange={e=>setMonth(e.target.value)}
                    /> 
                    <Button
                        colorScheme={'red'}
                        size='sm'
                        w='200px'
                        disabled={month === ''}
                        onClick={()=>setShow(true)}
                    >
                        Delete
                    </Button>
                </HStack>   
            </Box>

            <Divider />
            <Center><Box fontSize={25}>Summary</Box></Center>
            {dataSuccess && 
            <Box>
                <Box style={{width: '400px'}}>
                    <Table size='sm' mb={50}>
                        <Thead>
                            <Tr>
                                <Th>Description</Th>
                                <Th>Qty</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>Address</Td>
                                <Td>{data.addressCount}</Td>
                            </Tr>
                            <Tr>
                                <Td>Agent Rates</Td>
                                <Td>{data.agRateCount}</Td>
                            </Tr>
                            <Tr>
                                <Td>Charges List</Td>
                                <Td>{data.chargesCount}</Td>
                            </Tr>
                            <Tr>
                                <Td>DHL Accounts</Td>
                                <Td>{data.dhlAccCount}</Td>
                            </Tr>
                            <Tr>
                                <Td>Items</Td>
                                <Td>{data.itemCount}</Td>
                            </Tr>
                            <Tr>
                                <Td>Rates</Td>
                                <Td>
                                    <UnorderedList>
                                        <ListItem>Active - {data.rateCount.active}</ListItem>
                                        <ListItem>Inactive - {data.rateCount.inactive}</ListItem>
                                    </UnorderedList>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>Reject Names</Td>
                                <Td>{data.rejectNameCount}</Td>
                            </Tr>
                            <Tr>
                                <Td>Remote Areas</Td>
                                <Td>{data.remoteAreaCount}</Td>
                            </Tr>
                            <Tr>
                                <Td>THP Rates</Td>
                                <Td>{data.thpRateCount}</Td>
                            </Tr>
                            <Tr>
                                <Td>Users</Td>
                                <Td>
                                    <UnorderedList>
                                        <ListItem>Admins - {data.users.admins}</ListItem>
                                        <ListItem>Sales - {data.users.sales}</ListItem>
                                        <ListItem>Agents - {data.users.agents}</ListItem>
                                        <ListItem>Users - {data.users.users}</ListItem>
                                    </UnorderedList>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Box>

                {/* addressCount,
                agRateCount,
                chargesCount,
                dhlAccCount,
                itemCount,
                rateCount,
                rejectNameCount,
                remoteAreaCount,
                thpRateCount, */}

                <Table size='sm' mb={50}>
                    <Thead>
                        <Tr>
                            <Th><Box w={'100px'}>Month</Box></Th>
                            <Th>Pre Shipments</Th>
                            <Th>DHL Shipment</Th>
                            <Th>DHL Invoice</Th>
                            <Th>DHL Payment</Th>
                            <Th>THP Shipment</Th>
                            <Th>THP Invoice</Th>
                            <Th>THP Payment</Th>
                            <Th>DMS Shipment</Th>
                            <Th>DMS Invoice</Th>
                            <Th>DMS Payment</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data.data.map(d=>(
                            <Tr key={d.start}>
                                <Td>{d.start}</Td>
                                <Td>{d.preShipCount}</Td>
                                <Td>{d.dhlShipCount}</Td>
                                <Td>{d.dhlInvCount}</Td>
                                <Td>{d.dhlPayCount}</Td>
                                <Td>{d.thpShipCount}</Td>
                                <Td>{d.thpInvCount}</Td>
                                <Td>{d.thpPayCount}</Td>
                                <Td>{d.dmsShipCount}</Td>
                                <Td>{d.dmsInvCount}</Td>
                                <Td>{d.dmsPayCount}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                        
                </Table>
            </Box>
            }
        </Box>
    )
}

export default ClearDataScreen