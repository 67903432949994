import React, {useEffect} from 'react'
import { Box, Center, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import Error from '../components/Error'
import {useDispatch, useSelector} from 'react-redux'
import { getRemoteAreaList, deleteRemoteArea } from '../actions/remoteAreaActions'
import RemoteAreaCreateModal from '../Modals/RemoteAreaCreateModal'
import RemoteAreaEditModal from '../Modals/RemoteAreaEditModal'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'

const RemoteAreaListScreen = () => {
    const dispatch = useDispatch()
    const { loading, remoteareas, error } = useSelector(state=>state.remoteArea.List)
    const {success: successAdd} = useSelector(state=>state.remoteArea.Create)
    const {success: successUpdate, error: errorUpdate} = useSelector(state=>state.remoteArea.Update)
    const {success: successDelete, error: errorDelete} = useSelector(state=>state.remoteArea.Delete)

    useEffect(()=>{
        dispatch(getRemoteAreaList())
    },[dispatch, successAdd, successUpdate, successDelete])

    return (
        <Center my='4'>
            <Box boxShadow={'lg'} w='700px' p='3'>
                {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                {error && <Error error={error} /> }
                {errorUpdate && <Error error={errorUpdate} /> }
                {errorDelete && <Error error={errorDelete} /> }

                <RemoteAreaCreateModal />
                
                

                <Box>
                  <Table variant='striped' colorScheme='teal' my='4' size={'xs'}>
                      <Thead>
                          <Tr>
                          <Th>No</Th>
                          <Th>Postal Code</Th>
                          <Th>Edit</Th>
                          <Th>Delete</Th>
                          </Tr>
                      </Thead>
                      <Tbody>
                          {remoteareas && remoteareas.map((ac, idx)=>(
                          <Tr key={idx}>
                              <Td>{idx+1}</Td>
                              <Td>{ac.pcode}</Td>
                              <Td><RemoteAreaEditModal acc={ac} /></Td>
                              <Td><ConfirmDeleteModal width={'60px'} lefticon={null} id={ac._id} deleteFunction={deleteRemoteArea} /></Td>
                          </Tr>
                          ))}
                      </Tbody>
                  </Table>
                </Box>              
                
            </Box>
        </Center>
    )
}

export default RemoteAreaListScreen