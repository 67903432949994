import React, {useRef} from 'react'
import { Link } from 'react-router-dom'
import {
    Box,
    Divider,
    HStack,
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Spacer,
  } from '@chakra-ui/react'
import { MdBuild,  MdLocalShipping,  MdPeople, MdLocationPin, MdSell, MdOutlineViewHeadline, MdKeyboardArrowDown, MdDocumentScanner, MdOutlineBeenhere, MdFiberNew } from 'react-icons/md'




const SideMenu = () => {
    //const {user} = useSelector(state=>state.login)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()
    return (
        <>

        <Button size='sm' variant={'ghost'} ref={btnRef} colorScheme='white' onClick={onOpen} rightIcon={<MdKeyboardArrowDown />}>
            <MdOutlineViewHeadline size='25px' />
        </Button>
        
        <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
            finalFocusRef={btnRef}
        >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Side Menu</DrawerHeader>

            <DrawerBody>
                <HStack my='3' ps='4' fontWeight='800' >
                    <MdFiberNew size={25} />
                    <Link to='/preshipment' onClick={onClose}>Pre ship</Link>
                </HStack>
                <Divider />

                <Accordion allowToggle>
                    <AccordionItem>                        
                        <AccordionButton>
                            <HStack my='1' fontWeight='800' >
                                <MdLocalShipping size={25} />
                                <Box>Shipments</Box>
                                <Spacer />
                                <AccordionIcon />
                            </HStack>
                            
                        </AccordionButton>
                        <AccordionPanel>
                            <Box ps='40px'>
                                <Box mb='2'>
                                    <Button variant='link'>
                                        <Link to='/shipment' onClick={onClose} >DHL List</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/status' onClick={onClose}>DHL Status</Link>
                                    </Button>
                                </Box>
                                <Box>                                    
                                    <Button variant='link'>
                                        <Link to='/thailandpost' onClick={onClose}>THP List</Link>
                                    </Button>
                                </Box>
                            </Box>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                
               
                <HStack my='3' ps='4' fontWeight='800' >
                    <MdDocumentScanner size={25} />
                    <Link to='/dailyscan' onClick={onClose}>Scan</Link>
                </HStack>
                <Divider />

                <HStack my='3' ps='4' fontWeight='800' >
                    <MdOutlineBeenhere size={25} />
                    <Link to='/vplusreport' onClick={onClose}>Vplus</Link>
                </HStack>
                <Divider />
                
                
                
                <HStack my='3' ps='4' fontWeight='800' >
                    <MdPeople size={25} />
                    <Link to='/user' onClick={onClose}>Users</Link>
                </HStack>
                <Divider />

             
                <HStack my='3' ps='4' fontWeight='800' >
                    <MdLocationPin size={25} />
                    <Link to='/address' onClick={onClose}>Address</Link>
                </HStack>
                <Divider />

                <HStack my='3' ps='4' fontWeight='800' >
                    <MdSell size={25} />
                    <Link to='/item' onClick={onClose}>Items</Link>
                </HStack>
                <Divider />

                <Accordion allowToggle>
                    <AccordionItem>                        
                        <AccordionButton>
                            <HStack my='1' fontWeight='800' >
                                <MdBuild size={25} />
                                <Box>Others</Box>
                                <Spacer />
                                <AccordionIcon />
                            </HStack>
                            
                        </AccordionButton>
                        <AccordionPanel>
                            <Box ps='40px'>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/dhlaccount' onClick={onClose}>DHL accounts</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/charges' onClick={onClose}>Charges List</Link>
                                    </Button>
                                </Box>
                            </Box>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

            </DrawerBody>

            
            </DrawerContent>
        </Drawer>
        </>
    )
}

export default SideMenu