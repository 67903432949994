import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    Box,
    HStack,
    Stack,
    Input,
    Divider,
    Select,
    Button,
    Spinner,
    useToast,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Checkbox,
    Spacer,
    InputGroup,
    InputRightElement,
    Center,
    Heading,
} from '@chakra-ui/react'
import { listAgent } from '../../../actions/userActions'
import { listInvoiceTHPShipments, createTHPInvoice } from '../../../actions/thailand_post_actions/thpInvoiceActions'
import Error from '../../../components/Error'
import { THPINVOICE_SHIPMENTS_RESET } from '../../../constants/thailand_post_constants/thpInoviceConstants'

const PostInvoiceCreateScreen = () => {
    // function addDays(date, days){
    //     var result = new Date(date)
    //     result.setDay(result.getDay()+days)
    //     var dateString = result.toISOString()
    //     return dateString.substring(0,10)
    // }
    const toast = useToast()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchKeyword, setSearchKeyword] = useState('')
    const [untilDate, setUntilDate] = useState(new Date().toISOString().substring(0,10))
    const [agentId, setAgentId] = useState('')
    const [idList, setIdList] = useState([])
    const [allCheck, setAllCheck] = useState(false)
    const [dueDate, setDueDate] = useState('')
    const [note, setNote] = useState('')
    const {loading, error, agents} = useSelector(state=>state.user.AgentList)
    const {loading: loadingShipments, error: errorShipments, shipments} = useSelector(state=>state.thailandPost.ShipmentsForInvoice)
    const {loading: loadingCreate, error: errorCreate, success: successCreate, invoiceId} = useSelector(state=>state.thailandPost.InvoiceCreate)

    useEffect(()=>{
        dispatch(listAgent())
        if(successCreate){
            navigate(`/thpinvoices/${invoiceId}`)
        }
        return()=>{
            dispatch({type: THPINVOICE_SHIPMENTS_RESET})
        }
    },[dispatch, successCreate, navigate, invoiceId])

    const handleSearchAgent = (e) => {
        if(e.key === 'Enter'){
            dispatch(listAgent(1, 50, searchKeyword))
        }
        return
    }

    const handleCheckBox = async (shipmentId) =>{
        const index = idList.indexOf(shipmentId)
        if(index < 0){
            setIdList([...idList, shipmentId])
            if(idList.length === shipments.length-1){
                setAllCheck(true)
            }
        } else {
            const newIdList = idList
            newIdList.splice(index, 1)
            setIdList([...newIdList])
            if(idList.length === 0){
                setAllCheck(false)
            }
        }
    }

    const handleCheckAll = async () => {
        if(allCheck === false){
            const ids = shipments.map(s=>s._id)
            setIdList([...ids])
            setAllCheck(true)
        } else {
            setIdList([])
            setAllCheck(false)
        }
    }

    const handleSearch = () => {
        if(untilDate === '' || agentId === ''){
            toast({
                status: 'warning',
                position: 'top',
                title: 'Please choose agent and until date.',
                duration: 2000,
                isClosable: true,
            })
            return
        }
        //console.log(agentId, untilDate)
        dispatch(listInvoiceTHPShipments(agentId, untilDate))
    }

    const handleCreateInvoice = async () => {
        if(dueDate === ''){
            toast({
                status: 'warning',
                position: 'top',
                title: 'Please choose due date.',
                duration: 2000,
                isClosable: true,
            })
            return
        }
        let invoiceTotalAmount = 0
        const shipmentIdList = await shipments.map(s=>s._id)
        idList.forEach(id=>{
            const shipmentIndex = shipmentIdList.indexOf(id)
            invoiceTotalAmount = shipments[shipmentIndex].totalAmount + invoiceTotalAmount
        })
        const invoiceData = {
            agentId, 
            idList,
            dueDate,
            totalAmount: invoiceTotalAmount,
            note,
        }
        // console.log(invoiceData)
        dispatch(createTHPInvoice(invoiceData))
    }
    return (
        <Box mt='3'>
            <Divider />
            <Center>
                <Heading bg='blue.200' px='3'>
                    Thailand Post 
                </Heading>
            </Center>
            <Divider />
            <HStack my='2'>
                
                <Stack>
                    <Box>Search Agent</Box>
                    <InputGroup>
                    <Input 
                        type='text'
                        size='sm'
                        w='300px'
                        value={searchKeyword}
                        onChange={e=> setSearchKeyword(e.target.value)}
                        onKeyPress={handleSearchAgent}
                    />
                    <InputRightElement h='2rem' w='4rem' mr='2'>
                        <Button size='xs' w='60px' colorScheme={'blue'} onClick={()=>dispatch(listAgent(1, 50, searchKeyword))}>Go</Button>
                    </InputRightElement>
                    </InputGroup>
                </Stack>
                <Stack>
                    <Box>Agents</Box>
                    <Select w='300px' size='sm' placeholder='All' onChange={e=>setAgentId(e.target.value)}>
                        {agents && agents.map((ag, idx)=>(
                            <option key={idx} value={ag._id}>{ag.name} - {ag.agentCode}</option>
                        ))}
                    </Select>
                </Stack>
                <Stack>
                    <Box>Until</Box>
                    <Input 
                        type='date'
                        size='sm'
                        w='200px'
                        value={untilDate}
                        onChange={e=>setUntilDate(e.target.value)}
                    />
                </Stack>
                <Spacer />
                <Stack>
                    <Box h='20px'></Box>
                    <Button
                        size='sm'
                        w='200px'
                        colorScheme='green'
                        onClick={handleSearch}
                    >Go</Button>
                </Stack>
                <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                <Box>{loadingShipments && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                <Box>{loadingCreate && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
            </HStack>
            <Divider />
            <HStack my='2'>            
                <Stack>
                    <Box>Note</Box>
                    <Input 
                        type='text'
                        size='sm'
                        w='610px'
                        value={note}
                        onChange={e=> setNote(e.target.value)}
                    />
                </Stack>
                <Stack>
                <Box>Due Date</Box>
                    <Input 
                        type='date'
                        size='sm'
                        w='200px'
                        value={dueDate}
                        onChange={e=>setDueDate(e.target.value)}
                    />
                </Stack>
                <Spacer />
                <Stack>
                    <Box h='20px'></Box>
                    <Button
                        size='sm'
                        disabled={idList.length === 0}
                        w='200px'
                        colorScheme='green'
                        onClick={handleCreateInvoice}
                    >Create Invoice</Button>
                </Stack>
                <Box></Box>
                <Box></Box>
                <Box></Box>

            </HStack>
            <Divider />
            <Box>
            {error && <Error error={error} /> }
            {errorShipments && <Error error={errorShipments} /> }
            {errorCreate && <Error error={errorCreate} /> }


                <Table size='md' variant='striped' colorScheme='yellow' mt='4'>
                    <Thead>
                        <Tr>
                        <Th><Checkbox 
                            size='lg' 
                            colorScheme='green'
                            onChange={handleCheckAll}
                            //onClick={()=>setAllCheck(!allCheck)}
                            disabled={shipments && shipments.length === 0}
                            isChecked={shipments && shipments.length === idList.length}
                            >All</Checkbox></Th>
                        <Th>No</Th>
                        <Th>AWB</Th>
                        <Th>Shipped Date</Th>
                        <Th>Total Weight</Th>
                        <Th>Total Amount</Th>
                        
                        </Tr>
                    </Thead>
                    <Tbody>
                        {shipments && shipments.map((s,idx)=>(
                        <Tr key={idx}>
                            <Td><Checkbox 
                                size='lg' 
                                colorScheme='green'
                                onChange={()=>handleCheckBox(s._id)}
                                isChecked={idList.indexOf(s._id) >= 0}
                            ></Checkbox></Td>
                            <Td>{idx+1}</Td>
                            <Td>{s.awb}</Td>
                            <Td>{s.createdAt.substring(0,10)}</Td>
                            <Td>{s.totalWeight}</Td>
                            <Td>{s.totalAmount}</Td>                            
                        </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Box>
    )
}

export default PostInvoiceCreateScreen