import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Spinner,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Stack,
    ListItem,
    OrderedList,
    ModalFooter
} from '@chakra-ui/react'
import { removeShipmentFromInvoice } from '../actions/invoiceActions'
import { INVOICE_REMOVESHIPMENTS_RESET } from '../constants/invoiceConstants'

const RemoveShipmentFromInvoiceModal = ({disabled}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [shipmentList, setShipmentList] = useState([])
    const {invoice} = useSelector(state=>state.invoice.ById)
    const {loading, error, success} = useSelector(state=>state.invoice.RemoveShipment)

    useEffect(()=>{ 
        if(success){
            setShow(false)
            dispatch({type: INVOICE_REMOVESHIPMENTS_RESET})
        }
        if(show){
            setShipmentList([...invoice.shipmentList])
        }
    },[dispatch, success, show, invoice])

    const handleClose = () => { 
        dispatch({type: INVOICE_REMOVESHIPMENTS_RESET})
        setShow(false)
    }

    const hanldeRemove = (sId) => {
        const sList = shipmentList
        const idList = shipmentList.map(s=>s._id)
        const idx = idList.indexOf(sId)
        sList.splice(idx, 1)
        setShipmentList([...sList])
    }

    const handleSubmit = () => {   
        const newList = shipmentList.map(s=>s._id)
        if(newList.length !== 0){
            dispatch(removeShipmentFromInvoice(invoice._id, newList))
        }
        return
    }


    return (
        <>
            <Button size='sm' w='150px' disabled={disabled} colorScheme={'orange'} onClick={()=>setShow(true)} >Remove Shipments</Button>

            <Modal isOpen={show} onClose={handleClose} size='5xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Shipments</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                    </Box>
                    <Box  my='3' >
                        {error && 
                            <Alert status='error' variant='left-accent'>
                            <AlertIcon />
                            <AlertTitle mr={2}>Error removing shipment.</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        }
                    </Box>  

                  
                <Box mt='3'>                
                    <Table size='sm' variant='striped' colorScheme='black' my='4'>
                        <Thead>
                            <Tr>
                            <Th fontSize='md' fontWeight='500' >No</Th>
                            <Th fontSize='md' fontWeight='500' >AWB</Th>
                            <Th fontSize='md' fontWeight='500' >Date</Th>
                            <Th fontSize='md' fontWeight='500' >Receiver</Th>
                            <Th fontSize='md' fontWeight='500' >Weight</Th>
                            <Th fontSize='md' fontWeight='500' >Cost</Th>
                            <Th fontSize='md' fontWeight='500' >Charges</Th>
                            <Th fontSize='md' fontWeight='500' >Total</Th>
                            <Th fontSize='md' fontWeight='500' >Remove</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {shipmentList.map((s, idx)=>(
                            <Tr key={idx}>
                                <Td>{idx+1}</Td>
                                <Td>{s.awb}</Td>
                                <Td>{s.createdAt.substring(0,10)}</Td>
                                <Td>
                                    <Stack>
                                    <Box>{s.customerDetails.receiverDetails.contactInformation.fullName}</Box>
                                    <Box fontSize={'md'}>{s.customerDetails.receiverDetails.postalAddress.countryName}</Box>
                                    </Stack>
                                </Td>
                                <Td>{s.totalWeight}</Td>
                                <Td>{(s.totalAmount - s.charges.reduce((acc, c)=> acc + c.amount, 0)).toLocaleString()}</Td>
                                <Td>
                                    <OrderedList>                                  
                                    {s.charges.map((c,i)=>(
                                        <ListItem key={i}>
                                        {c.name} - {c.amount}
                                        </ListItem>
                                    ))}
                                    </OrderedList>
                                </Td>
                                <Td>{s.totalAmount.toLocaleString()}</Td>
                                <Td>
                                    <Button
                                        size='sm'
                                        disabled={shipmentList.length === 1}
                                        colorScheme={'red'}
                                        onClick={()=>hanldeRemove(s._id)}
                                    >Remove</Button>
                                </Td>
                            </Tr>
                            ))}
                        </Tbody>
                        </Table>
                    </Box>
                
                </ModalBody>
                <ModalFooter>
                    <Button
                        size='sm'
                        w='150px'
                        colorScheme={'green'}
                        onClick={()=>handleSubmit()}
                    >
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

RemoveShipmentFromInvoiceModal.defaultProps = {
    disable: false,
}

export default RemoveShipmentFromInvoiceModal