import axios from 'axios'
import {
    DOMESTICINVOICE_ADDSHIPMENTS_FAIL,
    DOMESTICINVOICE_ADDSHIPMENTS_REQUEST,
    DOMESTICINVOICE_ADDSHIPMENTS_SUCCESS,
    DOMESTICINVOICE_REMOVESHIPMENTS_FAIL,
    DOMESTICINVOICE_REMOVESHIPMENTS_REQUEST,
    DOMESTICINVOICE_REMOVESHIPMENTS_SUCCESS,
    DOMESTICINVOICE_BYID_FAIL,
    DOMESTICINVOICE_BYID_REQUEST,
    DOMESTICINVOICE_BYID_SUCCESS,
    DOMESTICINVOICE_CREATE_FAIL,
    DOMESTICINVOICE_CREATE_REQUEST,
    DOMESTICINVOICE_CREATE_SUCCESS,
    DOMESTICINVOICE_DELETE_FAIL,
    DOMESTICINVOICE_DELETE_REQUEST,
    DOMESTICINVOICE_DELETE_SUCCESS,
    DOMESTICINVOICE_LIST_FAIL,
    DOMESTICINVOICE_LIST_REQUEST,
    DOMESTICINVOICE_LIST_SUCCESS,
    DOMESTICINVOICE_SHIPMENTS_FAIL,
    DOMESTICINVOICE_SHIPMENTS_REQUEST,
    DOMESTICINVOICE_SHIPMENTS_SUCCESS,
    DOMESTICINVOICE_MYLIST_REQUEST,
    DOMESTICINVOICE_MYLIST_SUCCESS,
    DOMESTICINVOICE_MYLIST_FAIL,

} from '../../constants/domestic_constants/domesticInoviceConstants'


export const listDomesticInvoices = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICINVOICE_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/invoices?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: DOMESTICINVOICE_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICINVOICE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listMyDomesticInvoices = (pageNumber = '', itemCount = '', keyword = '', date='') => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICINVOICE_MYLIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/invoices/myinvoices?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}&date=${date}`, config)
        dispatch({type: DOMESTICINVOICE_MYLIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICINVOICE_MYLIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const filterMyDomesticInvoices = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICINVOICE_MYLIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/domestic/invoices/myfilter`, {filterData}, config)
        dispatch({type: DOMESTICINVOICE_MYLIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICINVOICE_MYLIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const filterDomesticInvoices = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICINVOICE_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/domestic/invoices/filter`, {filterData}, config)
        dispatch({type: DOMESTICINVOICE_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICINVOICE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const listInvoiceDomesticShipments = (agentId, date) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICINVOICE_SHIPMENTS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/invoices/shipments?agentId=${agentId}&date=${date}`, config)
        dispatch({type: DOMESTICINVOICE_SHIPMENTS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICINVOICE_SHIPMENTS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const createDomesticInvoice = (invoiceData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICINVOICE_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/domestic/invoices`, {invoiceData}, config)
        dispatch({type: DOMESTICINVOICE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICINVOICE_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getDomesticInvoiceById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICINVOICE_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/invoices/${id}`, config)
        dispatch({type: DOMESTICINVOICE_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICINVOICE_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const deleteDomesticInvoice = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICINVOICE_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/domestic/invoices/${id}`, config)
        dispatch({type: DOMESTICINVOICE_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: DOMESTICINVOICE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addShipmentsToDomesticInvoice = (id, shipmentIdList) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICINVOICE_ADDSHIPMENTS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/domestic/invoices/${id}`, {shipmentIdList}, config)
        dispatch({type: DOMESTICINVOICE_ADDSHIPMENTS_SUCCESS})
    } catch (error) {
        dispatch({
            type: DOMESTICINVOICE_ADDSHIPMENTS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const removeShipmentFromDomesticInvoice = (invId, newIdList) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICINVOICE_REMOVESHIPMENTS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/domestic/invoices/remove`, {invId, newIdList}, config)
        dispatch({type: DOMESTICINVOICE_REMOVESHIPMENTS_SUCCESS})
    } catch (error) {
        dispatch({
            type: DOMESTICINVOICE_REMOVESHIPMENTS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}
