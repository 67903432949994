import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import parse from 'paste-from-excel'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    FormControl, 
    Box,
    Flex,
    Button, 
    Icon,
    Spacer, 
    useToast,
    Center,
    Select,
  } from '@chakra-ui/react'
import {MdSaveAlt} from 'react-icons/md'
import { createRate } from '../../actions/domestic_actions/domesticRateActions'
import { useNavigate } from 'react-router-dom'
import { DOMESTICRATE_CREATE_RESET } from '../../constants/domestic_constants/domesticRateConstants'
import Error from '../../components/Error'
import { carrierList } from '../../data/carrierList'

const DomesticRateAddScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()
    const [carrier, setCarrier] = useState('')
    const [ratelist, setRatelist] = useState({
        rates: [
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            {'value': 0},
            ]
    })

    const {loading, error, success} = useSelector(state=>state.domestic.RateCreate)
 
    const zoneslist = ['value']
    const kglist = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21', '22', '23', '24', '25', '26', '27', '28', '29' , '30']

    
    const handlePaste = (e) => {
        return parse(e)
    }
    const handlePasteRate = (index, z, e, i) => {
        setRatelist((ratelist)=>({
            ...ratelist,
            rates: ratelist.rates.map((item, i)=> index === i ? {...item, [z]: e.target.value} : item)
        }))
    }

    useEffect(()=>{
        if(success){
            toast({
                status: 'success',
                position: 'top',
                title: 'New rate saved successfully.',
                duration: 8000,
                isClosable: true,
            }) 
            navigate('/domesticrate')
        }

        return()=>{
            dispatch({type: DOMESTICRATE_CREATE_RESET})
        }
    },[success, dispatch, navigate, toast])
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        if(carrier === '' || carrier === 'Select CArrier'){
            alert('Carrier is required')
            return
        }
        const newRate = {
            carrier,
            rate: ratelist.rates,
        }
        dispatch(createRate(newRate))
        //console.log(newRate)
    }


    return (
        <Center>
        <Box w='300px' mb={'50px'}>
            <Box my='5'>
                <Flex>
                    <Box w='300px' mr='3'>
                        <FormControl isRequired >
                            <Select placeholder='Select' size='sm' onChange={e=>setCarrier(e.target.value)}>
                                <option>Select Carrier</option>
                                {carrierList.map(c=>(
                                    <option key={c.value} value={c.value}>{c.name}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Spacer />
                    <Box w='200px'>
                        <Button 
                            w='100%'
                            size='sm'
                            isLoading={loading}
                            colorScheme='green'
                            loadingText='Submitting'
                            rightIcon={<Icon as={MdSaveAlt} />} 
                            onClick={handleSubmit}
                            >Save
                        </Button>
                    </Box>              
                </Flex>
            </Box>
            {error && <Error error={error} /> }
            
                <Table size='sm' variant='striped' colorScheme='red' >
                    <Thead>
                        <Tr>
                        <Th textAlign='center'>KG</Th>
                        <Th textAlign='center'>VALUE</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {ratelist.rates.map((res, idx)=>(
                            <Tr key={idx}>
                                <Td textAlign='center' w='100px'>{kglist[idx]}</Td>
                                {zoneslist.map((z, i)=>(
                                    <Td key={i}  p={0}>
                                        <FormControl border='grey' >
                                            <Input 
                                                size='xs' 
                                               
                                                textAlign='center' 
                                                fontSize='15px' 
                                                onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault()}}}
                                                onInput={(e)=> {handlePasteRate(idx, z, e, i)}}
                                                onPaste={e=>{handlePaste(e)}}
                                                />
                                        </FormControl>                                    
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                <Button 
                    mt={5}
                    variant='link'
                    onClick={()=>navigate('/domesticrate')}
                >Cancel / Back</Button>  
            </Box>
           
        </Center>  
    )
}

export default DomesticRateAddScreen