import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
    Select,
    Stack,
    HStack,
    Box,
    Spacer,
    Input,
    Spinner,
    Alert,
    AlertTitle,
    AlertIcon,
    AlertDescription,
  } from '@chakra-ui/react'
  import { MdAddBox } from 'react-icons/md'
  import { boxSizes } from '../data/boxSizes'
  import { boxPreshipment } from '../actions/shipmentActions'

const ShipmentPreAddBoxModal = ({preId, disable}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [length, setLength] = useState(0)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [weight, setWeight] = useState(0)
    const {loading: loadingBoxsize, error: errorBoxsize,  success: successBoxsize} = useSelector(state=>state.preshipment.Boxsize)


    useEffect(()=>{
        if(successBoxsize){
            setWeight(0)
            setLength(0)
            setWidth(0)
            setHeight(0)
            setShow(false)
        }
    },[successBoxsize])
    const onClose = () => {
        setWeight(0)
        setLength(0)
        setWidth(0)
        setHeight(0)
        setShow(false)
    }

    const handleBoxSelect = (i) => {
        setLength(boxSizes[i].dimensions.length)
        setWidth(boxSizes[i].dimensions.width)
        setHeight(boxSizes[i].dimensions.height)
    }

    const handleSubmit = (i) => {
        const boxData = {
          preId,
          boxData: {
              weight,
              dimensions: {
                length,
                width,
                height
              }
              
          },
          add: true
        }
        //console.log(boxData)
        dispatch(boxPreshipment(boxData))
    }

    return (
      <>
        <Button 
            disabled={disable}
            size='sm' 
            colorScheme={'purple'} 
            w='100px' 
            onClick={()=>setShow(true) } 
            leftIcon={<MdAddBox />}
        >Add Box</Button>
  
        <Modal closeOnOverlayClick={false} size='xl' isOpen={show} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Box</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
            {loadingBoxsize && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
            
            {errorBoxsize && 
            <Alert status='error' variant='left-accent'>
            <AlertIcon />
            <AlertTitle mr={2}>Error adding box</AlertTitle>
            <AlertDescription>{errorBoxsize}</AlertDescription>
            </Alert>
            }
                <Stack spacing='5'>
                    <Box>
                        <Text mb='2'>Box Size</Text>
                        <Select placeholder='Select Box Size' size='sm' onChange={(e)=>handleBoxSelect(e.target.value)}>
                        {boxSizes.map((bx, idx)=>(
                            <option key={idx} value={idx}>{bx.name}</option>
                        ))}
                        </Select>
                    </Box>

                    <Box my='5'>
                        <HStack px='3' h='30px'>
                            <Text>Length</Text>
                            <Input 
                                type='number'
                                size='sm' 
                                maxLength='3'
                                value={length} 
                                onChange={(e)=>setLength(e.target.value)} 
                                >
                            </Input>
                            <Text>Width</Text>
                            <Input 
                                type='number'
                                size='sm' 
                                maxLength='3'
                                value={width} 
                                onChange={(e)=>setWidth(e.target.value)}  
                                >
                            </Input>
                            
                            <Text>Height</Text>
                            <Input 
                                type='number'
                                size='sm' 
                                maxLength='3'
                                value={height} 
                                onChange={(e)=>setHeight(e.target.value)} 
                                >
                            </Input>
                        </HStack>
                    </Box>

                    <Box>
                        <Text  mb='2'>Weight</Text>
                        <Input 
                            type='number'
                            size='sm' 
                            maxLength='3'
                            value={weight} 
                            onChange={(e)=>setWeight(e.target.value)}  
                            >
                        </Input>
                    </Box>
                </Stack>
            </ModalBody>
  
            <ModalFooter>
              <Button w={'150px'} size='sm' onClick={onClose}>Cancel</Button>              
              <Spacer />
              <Button 
                colorScheme='green' 
                w={'150px'}
                size='sm'
                onClick={handleSubmit}
                >
                Save
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
}

ShipmentPreAddBoxModal.defaultProps = {
    disable: false,
}

export default ShipmentPreAddBoxModal