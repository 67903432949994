export const THPINVOICE_SHIPMENTS_REQUEST = 'THPINVOICE_SHIPMENTS_REQUEST'
export const THPINVOICE_SHIPMENTS_SUCCESS = 'THPINVOICE_SHIPMENTS_SUCCESS'
export const THPINVOICE_SHIPMENTS_FAIL = 'THPINVOICE_SHIPMENTS_FAIL'
export const THPINVOICE_SHIPMENTS_RESET = 'THPINVOICE_SHIPMENTS_RESET'

export const THPINVOICE_CREATE_REQUEST = 'THPINVOICE_CREATE_REQUEST'
export const THPINVOICE_CREATE_SUCCESS = 'THPINVOICE_CREATE_SUCCESS'
export const THPINVOICE_CREATE_FAIL = 'THPINVOICE_CREATE_FAIL'
export const THPINVOICE_CREATE_RESET = 'THPINVOICE_CREATE_RESET'

export const THPINVOICE_LIST_REQUEST = 'THPINVOICE_LIST_REQUEST'
export const THPINVOICE_LIST_SUCCESS = 'THPINVOICE_LIST_SUCCESS'
export const THPINVOICE_LIST_FAIL = 'THPINVOICE_LIST_FAIL'
export const THPINVOICE_LIST_RESET = 'THPINVOICE_LIST_RESET'

export const THPINVOICE_BYID_REQUEST = 'THPINVOICE_BYID_REQUEST'
export const THPINVOICE_BYID_SUCCESS = 'THPINVOICE_BYID_SUCCESS'
export const THPINVOICE_BYID_FAIL = 'THPINVOICE_BYID_FAIL'
export const THPINVOICE_BYID_RESET = 'THPINVOICE_BYID_RESET'

export const THPINVOICE_DELETE_REQUEST = 'THPINVOICE_DELETE_REQUEST'
export const THPINVOICE_DELETE_SUCCESS = 'THPINVOICE_DELETE_SUCCESS'
export const THPINVOICE_DELETE_FAIL = 'THPINVOICE_DELETE_FAIL'
export const THPINVOICE_DELETE_RESET = 'THPINVOICE_DELETE_RESET'

export const THPINVOICE_ADDSHIPMENTS_REQUEST = 'THPINVOICE_ADDSHIPMENTS_REQUEST'
export const THPINVOICE_ADDSHIPMENTS_SUCCESS = 'THPINVOICE_ADDSHIPMENTS_SUCCESS'
export const THPINVOICE_ADDSHIPMENTS_FAIL = 'THPINVOICE_ADDSHIPMENTS_FAIL'
export const THPINVOICE_ADDSHIPMENTS_RESET = 'THPINVOICE_ADDSHIPMENTS_RESET'

export const THPINVOICE_REMOVESHIPMENTS_REQUEST = 'THPINVOICE_REMOVESHIPMENTS_REQUEST'
export const THPINVOICE_REMOVESHIPMENTS_SUCCESS = 'THPINVOICE_REMOVESHIPMENTS_SUCCESS'
export const THPINVOICE_REMOVESHIPMENTS_FAIL = 'THPINVOICE_REMOVESHIPMENTS_FAIL'
export const THPINVOICE_REMOVESHIPMENTS_RESET = 'THPINVOICE_REMOVESHIPMENTS_RESET'

export const THPINVOICE_MYLIST_REQUEST = 'THPINVOICE_MYLIST_REQUEST'
export const THPINVOICE_MYLIST_SUCCESS = 'THPINVOICE_MYLIST_SUCCESS'
export const THPINVOICE_MYLIST_FAIL = 'THPINVOICE_MYLIST_FAIL'
export const THPINVOICE_MYLIST_RESET = 'THPINVOICE_MYLIST_RESET'