import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import {
    Box,
    Stack,
    Divider,
    Button,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Text,
    Select,
    Input,
    Flex,
    useBreakpointValue,
    SimpleGrid,
    GridItem,
    Center,
    Heading,
} from '@chakra-ui/react'
import { listPreshipment } from '../actions/shipmentActions'
import { listAgent } from '../actions/userActions'
import SearchBox from '../components/SearchBox'
import { PRESHIPMENT_LIST_RESET } from '../constants/shipmentConstants'
import Marks from '../components/Marks'
import Error from '../components/Error'
import useWindowSize from '../components/WindowSize'
import MySpinner from '../components/MySpinner'


const ShipmentPreListScreen = () => {
  const size = useWindowSize()
  const colSpan = useBreakpointValue({base: 2, md: 1})
  const filterSpan = useBreakpointValue({base: 5, md: 1})
  const dispatch = useDispatch()
  const params = useParams()
  const keyword = params.keyword || ''
  const [filterAgent, setFilterAgent] = useState('')
  const [agentKeyword, setAgentKeyword] = useState('')
  const [filterDate, setFilterDate] = useState('')
  const [filterShipped, setFilterShip] = useState(false)
  const {loading, error, preshipments} = useSelector(state=>state.preshipment.List)
  const {loading: loadingAgents, error: errorAgents, agents} = useSelector(state=>state.user.AgentList)
  
  useEffect(()=>{
    dispatch(listPreshipment(keyword))
    dispatch(listAgent('', 1000))
    return()=>{
        dispatch({type: PRESHIPMENT_LIST_RESET})
        setAgentKeyword('')
    }
  },[dispatch, keyword])

  const handleSearchAgent = (e) => {
    if(e.key === 'Enter'){
      dispatch(listAgent('', 1000, agentKeyword))
    }
    return
  }
  const handleFilterSubmit = () => {
    dispatch(listPreshipment(keyword, filterAgent, filterDate, filterShipped))
  }
  return (
    <Box my='4'>

      <Center><Heading>Pre-Shipments {(loading || loadingAgents) && <MySpinner />}</Heading></Center>
      <SimpleGrid columns={2} columnGap={3} rowGap={3} my='3'>
        <GridItem colSpan={colSpan}>
          <SearchBox width='full' />
        </GridItem>
        <GridItem colSpan={colSpan}>
          <Link to='/createpreshipment'>
            <Button size='sm' w='full' variant={'outline'} colorScheme='green' >Make New Pre Shipment</Button>
          </Link>
        </GridItem>
      </SimpleGrid>
      <Divider />

      <SimpleGrid columns={5} columnGap={3} rowGap={3} my='3'>
        <GridItem colSpan={filterSpan}>
          <Input 
            type="text"
            size='sm'
            w='full'
            placeholder='search agent'
            value={agentKeyword}
            onChange={e=>setAgentKeyword(e.target.value)}
            onKeyPress={handleSearchAgent}
          />
        </GridItem>

        <GridItem colSpan={filterSpan}>
          <Select placeholder='Select' size='sm' w='full' onChange={e=>setFilterAgent(e.target.value)}>
            {agents && agents.map((ag, idx)=> (
              <option key={idx} value={ag._id}>{ag.name} : {ag.agentCode}</option>
            ))}
          </Select>
        </GridItem>

        <GridItem colSpan={filterSpan}>
          <Input
            type='date'
            size='sm'
            w='full'
            value={filterDate}
            onChange={e=>setFilterDate(e.target.value)}
          />
        </GridItem>

        <GridItem colSpan={filterSpan}>
          <Select placeholder='Select' size='sm' w='full' onChange={e=>setFilterShip(e.target.value)}>
            <option value={false}>Not ship yet</option>
            <option value={true}>Shipped</option>
          </Select> 
        </GridItem>

        <GridItem colSpan={filterSpan}>
          <Button
            size='sm'
            w='full'
            colorScheme={'green'}
            onClick={handleFilterSubmit}
          >Go</Button>
        </GridItem>
      </SimpleGrid>

      <Divider />
        {error && <Error error={error} />}
        {errorAgents && <Error error={errorAgents} />}

        {size.width > 760 ? 
          <Table size='sm'  colorScheme='black' my='4' overflow={'auto'}>
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>Code</Th>
                <Th>Agent</Th>
                <Th>Sale</Th>
                <Th>Receiver</Th>              
                <Th>Paid Amount</Th>
                <Th>Box</Th>
                <Th>Weight</Th>
                <Th>Items</Th>
                <Th>Created Date</Th>
                <Th>Option</Th>
              </Tr>
            </Thead>
            <Tbody>
              {preshipments && preshipments.map((pres,index)=>(
                <Tr key={index} bg={pres.shipType === 'dhl' ? 'white' : pres.shipType === 'domestic' ? '#FED7D7' : '#BEE3F8'}>
                  <Td>{index+1}</Td>
                  <Td>{pres.code}                   
                      {pres.isReturn && <Marks bgColor='red' text='R' />}
                      {pres.isVplus && <Marks bgColor='purple' text='V' />}
                  </Td>
                  <Td>{pres.agentId?.name ?? 'agent deleted'}</Td>
                  <Td>{pres.agentId?.sale.saleCode ?? 'agent deleted'}</Td>
                  <Td>
                      <Text>{pres.addressId.name}</Text>
                      <Text fontSize={'xs'}>{pres.addressId.address}, {pres.addressId.city}, {pres.addressId.countrycode}</Text>
                  </Td>
                  <Td>{pres.paidAmount}</Td>
                  <Td>{pres.boxes.length}</Td>
                  <Td>{pres.boxes.reduce((acc, b) => acc + b.weight, 0)} kg</Td>
                  <Td>{pres.items.length}</Td>
                  <Td >{(pres.createdAt).substring(0,10)}</Td>
                  <Td>
                    <Link to={pres.shipType === 'dhl' ? `/preshipment/${pres._id}` : pres.shipType === 'domestic' ? `/predomestic/${pres._id}` : `/thailandpostpre/${pres._id}`}>
                      <Button size='sm' variant={'ghost'}>Edit</Button>
                    </Link> 
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          :
          <Table size='sm' variant='striped' colorScheme='blue' my='4' overflow={'auto'}>
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>Summary</Th>
                <Th>Option</Th>
              </Tr>
            </Thead>
            <Tbody>
              {preshipments && preshipments.map((pres,index)=>(
                <Tr key={index} color={pres.shipType !== 'dhl' && 'blue'}>
                  <Td>{index+1}</Td>
                  <Td>
                    <Stack spacing={0}>
                      <Flex>
                        {pres.code}                   
                        {pres.isReturn && <Marks bgColor='red' text='R' />}
                        {pres.isVplus && <Marks bgColor='purple' text='V' />}
                      </Flex>
                      <Box>Agent - {pres.agentId?.name ?? 'agent deleted'}</Box>
                      <Box>Sale - {pres.agentId?.sale.saleCode ?? 'agent deleted'}</Box>
                      <Flex>
                        <Box>Receiver - </Box>
                        <Flex spacing={0} fontSize='xs'>
                          <Box>{pres.addressId.name}</Box>
                          <Box>{pres.addressId.city}, {pres.addressId.countrycode}</Box>
                        </Flex>                        
                      </Flex>
                      <Box>Paid - {pres.paidAmount}</Box>
                      <Box>Box qty - {pres.boxes.length}</Box>
                      <Box>Weight - {pres.boxes.reduce((acc, b) => acc + b.weight, 0)} kg</Box>
                      <Box>Items qty - {pres.items.length}</Box>
                      <Box>Creatd - {(pres.createdAt).substring(0,10)}</Box>
                    </Stack>
                  </Td>
                  <Td>
                    <Link to={pres.shipType === 'dhl' ? `/preshipment/${pres._id}` : pres.shipType === 'domestic' ? `/predomestic/${pres._id}` : `/thailandpostpre/${pres._id}`}>
                      Edit
                    </Link> 
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>          
          }

        
    </Box>
  )
}

export default ShipmentPreListScreen