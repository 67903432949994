import "./App.css";
import { useEffect } from "react";
import { isExpired } from "react-jwt";
//import { logoutUser } from './actions/userActions'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'
import IdleTimerContainer from "./components/IdleTimerContainer";
import { Route, Routes, Navigate } from "react-router-dom";
import CountryZoneView from "./components/CountryZoneView";
//import LoginScreen from "./screens/LoginScreen";
import UserRegisterScreen from "./screens/UserRegisterScreen";
import PullDataScreen from "./screens/PullDataScreen";

import RateEditScreen from "./screens/RateEditScreen";
import RateListScreen from "./screens/RateListScreen";
import RateAddScreen from "./screens/RateAddScreen";
import AddressListScreen from "./screens/AddressListScreen";
import AddressEditScreen from "./screens/AddressEditScreen";
import RateDetailScreen from "./screens/RateDetailScreen";
import UserListScreen from "./screens/UserListScreen";
import UserDetailScreen from "./screens/UserDetailScreen";
import NotFoundPage from "./components/NotFoundPage";
import UserEditScreen from "./screens/UserEditScreen";
import ShipmentCreateScreen from "./screens/ShipmentCreateScreen";
import ShipmentCreateSuccessScreen from "./screens/ShipmentCreateSuccessScreen";
import VPlusReportScreen from "./screens/VPlusReportScreen";
import ShipmentPreCreateScreenNew from "./screens/ShipmentPreCreateScreenNew";
//import ShipmentPreCreateScreen from './screens/ShipmentPreCreateScreen';
import ShipmentListScreen from "./screens/ShipmentListScreen";
import MyProfileScreen from "./screens/MyProfileScreen";
import AdminDashboardScreen from "./screens/AdminDashboardScreen";
import AdminDashboardListScreen from "./screens/AdminDashboardListScreen";
// import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
// import PasswordResetScreen from "./screens/PasswordResetScreen";
import ShipmentPreListScreen from "./screens/ShipmentPreListScreen";
import InvoiceListScreen from "./screens/InvoiceListScreen";
import ItemListScreen from "./screens/ItemListScreen";
import ItemEditScreen from "./screens/ItemEditScreen";
import ShipmentPreDetailScreen from "./screens/ShipmentPreDetailScreen";

//import PDFTest from './components/PDFTest';
import { USER_LOGOUT } from "./constants/userConstants";
import ShipmentDetailScreen from "./screens/ShipmentDetailScreen";
import ShipCountScreen from "./screens/ShipCountScreen";
import InvoiceCreateScreen from "./screens/InvoiceCreateScreen";
import InvoiceDetailScreen from "./screens/InvoiceDetailScreen";
import PaymentListScreen from "./screens/PaymentListScreen";
import PaymentDetailScreen from "./screens/PaymentDetailScreen";
import PrintRateScreen from "./screens/PrintRateScreen";
import {
  //AgentDashboardScreen,
  AgentAccountScreen,
  AgentRateScreen,
  AgentShipmentDetailScreen,
  AgentInvoiceDetailScreen,
  AgentPaymentListScreen,
  AgentPaymentDetailScreen,
  AgentDailyReportScreen,
  AgentShipmentStatusScreen,
  AgentShipmentListScreen,
  AgentInvoiceListScreen,
} from "./screens/AgentScreens";

import AgentRateListScreen from "./screens/AgentRateListScreen";
import AgentRateEditScreen from "./screens/AgentRateEditScreen";
import ScanningScreen from "./screens/ScanningScreen";
import SaleAgentListScreen from "./screens/SalesScreens/SaleAgentListScreen";
import SaleAgentDetailScreen from "./screens/SalesScreens/SaleAgentDetailScreen";
import SaleInvoiceCreateScreen from "./screens/SalesScreens/SaleInvoiceCreateScreen";
import DailyReportScreen from "./screens/DailyReportScreen";
import AddressAddByTypingScreen from "./screens/AddressAddByTypingScreen";
import SaleBillingScreen from "./screens/SaleBillingScreen";
import ShipmentCreatedCountByUser from "./screens/ShipmentCreateCountByUser";
import DeletedShipmentListScreen from "./screens/DeletedShipmentListScreen";
import ShipmentStatusScreen from "./screens/ShipmentStatusScreen";
import SaleShipmentStatusScreen from "./screens/SalesScreens/SaleShipmentStatusScreen";
import AgentBillingScreen from "./screens/AgentBillingScreen";
import SaleMyAgentBillingScreen from "./screens/SalesScreens/SaleMyAgentBillingScreen";
//import SaleDashboardScreen from "./screens/SalesScreens/SaleDashboardScreen";
import DHLAccountScreen from "./screens/DHLAccountScreen";
import RejectNameListScreen from './screens/RejectNameListScreen'
import RemoteAreaListScreen from "./screens/RemoteAreaListScreen";
import RateTransferScreen from "./screens/RateTransferScreen";

import ClearDataScreen from "./screens/ClearDataScreen";
import AgentRateTransferScreen from "./screens/AgentRateTransferScreen";
import RateCreateScreen from "./screens/RateCreateScreen";
import ChargesListScreen from "./screens/ChargesListScreen";
import CountryListScreen from "./screens/CountryListScreen";

import {
  PostPaymentListScreen,
  PostPaymentDetailScreen,
  PostShipmentMyListScreen,
  PostSaleShipmentDetailScreen,
  PostInvoiceMyListScreen,
  PostSaleInvoiceDetailScreen,
  PostPaymentMyListScreen,
  PostSalePaymentDetailScreen,
  PostSaleInvoiceCreateScreen,
  PostPreShipmentScreen,
  PostShipmentCreateScreen,
  PostShipmentListScreen,
  PostShipmentDetailScreen,
  PostRateCreateScreen,
  PostRateListScreen,
  PostRateEditScreen,
  PostInvoiceCreateScreen,
  PostInvoiceListScreen,
  PostInvoiceDetailScreen,
  PostScanningScreen,
} from "./screens/ThailandPost";

import {
  DomesticPreDetailScreen,
  DomesticRateAddScreen,
  DomesticRateListScreen,
  DomesticShipmentListScreen,
  DomesticShipmentCreateScreen,
  DomesticShippingLabel,
  DomesticScanningScreen,
  DomesticShipmentDetailScreen,
  DomesticInvoiceCreateScreen,
  DomesticInvoiceListScreen,
  DomesticInvoiceDetailScreen,
  DomesticPaymentDetailScreen,
  DomesticPaymentListScreen,
  DomesticShipmentMyListScreen,
  DomesticShipmentMyDetailScreen,
  DomesticInvoiceMyListScreen,
  DomesticInvoiceMyCreateScreen,
  DomesticInvoiceMyDetailScreen,
  DomesticPaymentMyListScreen,
  DomesticPaymentMyDetailScreen,
} from './screens/DomesticScreens'
import BalanceLogScreen from "./screens/BalanceLogScreen";
import SalesAccountingScreen from "./screens/AccountingScreens/SalesAccountingScreen";
import UserLogScreen from "./screens/UserLogScreen";
import SalesAccountingDetailScreen from "./screens/AccountingScreens/SalesAccountingDetailScreen";
import SaleShippingChartScreen from "./screens/SaleShippingChartScreen";
import AgentShippingChartScreen from "./screens/AgentShippingChartScreen";
import MyBalanceLogScreen from "./screens/AgentScreens/MyBalanceLogScreen";
import ZoneListScreen from "./screens/ZoneListScreen";
import LoiginScreenNew from "./screens/LoiginScreenNew";
import RemoteAreaCheckComponent from "./components/RemoteAreaCheckComponent";
import ShipmentPreReportScreen from "./screens/ShipmentPreReportScreen";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.login);

  
  useEffect(()=>{
    if(!user){
      navigate('/')
    }
    // console.log('app user check')
    if(user && isExpired(user.token)){
      localStorage.clear()
      dispatch({type: USER_LOGOUT})
      window.location.reload(false)
    }

  },[user, dispatch, navigate])

  // useEffect(() => {
  //   if (user !== null) {
  //     const interval = setInterval(() => {
  //       console.log('This will run every second!');
  //       if (isExpired(user.token)) {
  //         localStorage.clear();
  //         dispatch({ type: USER_LOGOUT });
  //         window.location.reload(false);
  //       }
  //     }, 1000); // 10 mins
  //     return () => clearInterval(interval);
  //   }
  // }, [dispatch, user]);

  return (
    <>
      {user && <IdleTimerContainer></IdleTimerContainer>}
      {user && <RemoteAreaCheckComponent />}
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/" element={<LoiginScreenNew />} />

        {/* <Route path='/pdf' element={<PDFTest />} /> */}
        {/* <Route path='/forgotpassword' element={!user ? <ForgotPasswordScreen /> : <Navigate to='/' /> }/> */}
        {/* <Route path="/forgotpassword" element={<ForgotPasswordScreen />} />
        <Route path="/resetpassword/:token" element={<PasswordResetScreen />} /> */}

        {/* -------common routes ---------- */}
        <Route path="/countryandzone" element={ user ? <CountryZoneView /> : <Navigate to="/" />} />
        <Route path="/dailyscan" element={ user && (user.isAdmin || user.accountType === "user") ? (<ScanningScreen />) : (<Navigate to="/" />)} />
        <Route path="/vplusreport" element={ user && (user.isAdmin || user.accountType === "user") ? ( <VPlusReportScreen /> ) : ( <Navigate to="/" /> ) }  />
        <Route path="/preshipmentreport" element={ user && (user.isAdmin || user.accountType === "user") ? ( <ShipmentPreReportScreen /> ) : ( <Navigate to="/" /> ) }  />
        <Route path="/dhlaccount" element={ user && (user.isAdmin || user.accountType === "user") ? ( <DHLAccountScreen /> ) : (<Navigate to="/" /> )} />
        <Route path="/rejectname" element={ user && (user.isAdmin || user.accountType === "user") ? ( <RejectNameListScreen />) : (<Navigate to="/" />)}/>
        <Route
          path="/remotearea"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <RemoteAreaListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/charges"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ChargesListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/country"
          element={
            user && (user.isAdmin) ? (
              <CountryListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/zone"
          element={
            user && (user.isAdmin) ? (
              <ZoneListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* admin only */}
        <Route
          path="/data"
          element={
            user && user.isAdmin ? <PullDataScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/cleardata"
          element={
            user && user.isAdmin ? <ClearDataScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/printrate"
          element={
            user && user.isAdmin ? <PrintRateScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/agentrate"
          element={
            user && user.isAdmin ? <AgentRateListScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/agentrate/:id"
          element={
            user && user && user.isAdmin ? (
              <AgentRateEditScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/agratetransfer"
          element={
            user && user.isAdmin ? (
              <AgentRateTransferScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/deletedshipments"
          element={
            user && user.isAdmin ? (
              <DeletedShipmentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/deletedshipments/search/:keyword"
          element={
            user && user.isAdmin ? (
              <DeletedShipmentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/usershipmentcount"
          element={
            user && user.isAdmin ? (
              <ShipmentCreatedCountByUser />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ Dashboard ////////////////////////////////////////////// */}
        <Route
          path="/admindashboard"
          element={
            user && user.isAdmin ? (
              <AdminDashboardScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/balancelog"
          element={
            user && user.isAdmin ? (
              <BalanceLogScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/userlog"
          element={
            user && user.isAdmin ? (
              <UserLogScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/admindata"
          element={
            user && user.isAdmin ? (
              <AdminDashboardListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/accounting/sale"
          element={
            user && user.isAdmin ? (
              <SalesAccountingScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/shippingchart"
          element={
            user && user.isAdmin ? (
              <SaleShippingChartScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/shippingchart/:id"
          element={
            user && user.isAdmin ? (
              <AgentShippingChartScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/accounting/sale/detail"
          element={
            user && user.isAdmin ? (
              <SalesAccountingDetailScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/agentdashboard"
          element={
            user && user.accountType === "agent" ? (
              // <AgentDashboardScreen />
              // <Navigate to="/myshipments" />
              <Navigate to="/myrate" />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/saledashboard"
          element={
            user && user.accountType === "sale" ? (
              // <SaleDashboardScreen />
              <Navigate to="/myshipments" />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* ****************************users***************************************** */}
        <Route
          path="/user/register"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <UserRegisterScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/user"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <UserListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/user/search/:keyword"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <UserListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/user/page/:pageNumber"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <UserListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/user/search/:keyword/page/:pageNumber"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <UserListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/user/:id"
          element={user ? <UserDetailScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/user/edit/:id"
          element={
            user && user.isAdmin ? <UserEditScreen /> : <Navigate to="/" />
          }
        />

        {/* -----------------------------------addresses----------------------------------------- */}
        <Route
          path="/address"
          element={
            user &&
            (user.accountType === "admin" || user.accountType === "user") ? (
              <AddressListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/address/search/:keyword"
          element={
            user &&
            (user.accountType === "admin" || user.accountType === "user") ? (
              <AddressListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/address/page/:pageNumber"
          element={
            user &&
            (user.accountType === "admin" || user.accountType === "user") ? (
              <AddressListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/address/search/:keyword/page/:pageNumber"
          element={
            user &&
            (user.accountType === "admin" || user.accountType === "user") ? (
              <AddressListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/address/:id"
          element={
            user &&
            (user.accountType === "admin" || user.accountType === "user") ? (
              <AddressEditScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/address/custom"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <AddressAddByTypingScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* iiiiiiiiiiiiiiiiiiiiiiiiiii-------------------Items------------iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii */}
        <Route
          path="/item"
          element={
            user &&
            (user.accountType === "admin" || user.accountType === "user") ? (
              <ItemListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/item/search/:keyword"
          element={
            user &&
            (user.accountType === "admin" || user.accountType === "user") ? (
              <ItemListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/item/page/:pageNumber"
          element={
            user &&
            (user.accountType === "admin" || user.accountType === "user") ? (
              <ItemListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/item/search/:keyword/page/:pageNumber"
          element={
            user &&
            (user.accountType === "admin" || user.accountType === "user") ? (
              <ItemListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/item/:id"
          element={
            user &&
            (user.accountType === "admin" || user.accountType === "user") ? (
              <ItemEditScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* =============================rates========================================================= */}
        <Route
          path="/rate"
          element={
            user && user.isAdmin ? <RateListScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/rate/:id"
          element={
            user && (user.isAdmin || user.accountType === "sale") ? (
              <RateDetailScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/rate/edit/:id"
          element={
            user && user.isAdmin ? <RateEditScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/addrate"
          element={
            user && user.isAdmin ? <RateAddScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/createrate"
          element={
            user && user.isAdmin ? <RateCreateScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/transferrate"
          element={
            user && user.isAdmin ? <RateTransferScreen /> : <Navigate to="/" />
          }
        />

        {/* +++++++++++++++++++++++++++++++++++++++++ Pre shipment+++++++++++++++++++++++++++++++++++++++++++++ */}
        <Route
          path="/shipment"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/shipment/search/:keyword"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/shipment/page/:pageNumber"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/shipment/search/:keyword/page/:pageNumber"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/shipment/:id"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentDetailScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/createshipment"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentCreateScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/createshipment/:id"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentCreateScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/preshipment"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentPreListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/preshipment/search/:keyword"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentPreListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/createpreshipment"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentPreCreateScreenNew />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/shipmentsuccess"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentCreateSuccessScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/preshipment/:id"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentPreDetailScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/status"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipmentStatusScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* -----------------------------------------------ship counts------------------------------------------------- */}
        <Route
          path="/shipcount"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipCountScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/shipcount/page/:pageNumber"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <ShipCountScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* -----------------------------------------------invoices------------------------------------------------- */}
        <Route
          path="/invoice"
          element={
            user && user.isAdmin ? <InvoiceListScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/createinvoice"
          element={
            user && user.isAdmin ? <InvoiceCreateScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/invoice/search/:keyword"
          element={
            user && user.isAdmin ? <InvoiceListScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/invoice/page/:pageNumber"
          element={
            user && user.isAdmin ? <InvoiceListScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/invoice/search/:keyword/page/:pageNumber"
          element={
            user && user.isAdmin ? <InvoiceListScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/invoice/:id"
          element={
            user && user.isAdmin ? <InvoiceDetailScreen /> : <Navigate to="/" />
          }
        />

        {/* -----------------------------------------------Payments------------------------------------------------- */}
        <Route
          path="/payment"
          element={
            user && user.isAdmin ? <PaymentListScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/payment/:id"
          element={
            user && user.isAdmin ? <PaymentDetailScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/payment/search/:keyword"
          element={
            user && user.isAdmin ? <PaymentListScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/payment/page/:pageNumber"
          element={
            user && user.isAdmin ? <PaymentListScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/payment/search/:keyword/page/:pageNumber"
          element={
            user && user.isAdmin ? <PaymentListScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/salebilling"
          element={
            user && user.isAdmin ? <SaleBillingScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/agentbilling"
          element={
            user && user.isAdmin ? <AgentBillingScreen /> : <Navigate to="/" />
          }
        />

        {/* duplicate */}
        <Route
          path="/myprofile"
          element={user ? <MyProfileScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/myaccount"
          element={user ? <AgentAccountScreen /> : <Navigate to="/" />}
        />
        {/* duplicate */}

        {/* sale and agent */}
        <Route
          path="/report"
          element={
            user && user.accountType === "sale" ? (
              <DailyReportScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/mystatus"
          element={
            user && user.accountType === "sale" ? (
              <SaleShipmentStatusScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/agentmystatus"
          element={
            user && user.accountType === "agent" ? (
              <AgentShipmentStatusScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/myagentstatus"
          element={
            user && user.accountType === "sale" ? (
              <SaleMyAgentBillingScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/agentreport"
          element={
            user && user.accountType === "agent" ? (
              <AgentDailyReportScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/mybalancelog"
          element={
            user && user.accountType === "agent" ? (
              <MyBalanceLogScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* -------Sale Screens--------- */}
        <Route
          path="/myagents"
          element={
            user && user.accountType === "sale" ? (
              <SaleAgentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/myagents/search/:keyword"
          element={
            user && user.accountType === "sale" ? (
              <SaleAgentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/myagents/:id"
          element={
            user && user.accountType === "sale" ? (
              <SaleAgentDetailScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/salecreateinvoice"
          element={
            user && user.accountType === "sale" ? (
              <SaleInvoiceCreateScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* my dhl shipments */}
        <Route
          path="/myshipments"
          element={user ? <AgentShipmentListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/myshipments/search/:keyword"
          element={user ? <AgentShipmentListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/myshipments/page/:pageNumber"
          element={user ? <AgentShipmentListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/myshipments/search/:keyword/page/:pageNumber"
          element={user ? <AgentShipmentListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/myshipments/:id"
          element={user ? <AgentShipmentDetailScreen /> : <Navigate to="/" />}
        />

        {/* my dhl invoices */}
        <Route
          path="/myinvoices"
          element={user ? <AgentInvoiceListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/myinvoices/search/:keyword"
          element={user ? <AgentInvoiceListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/myinvoices/page/:pageNumber"
          element={user ? <AgentInvoiceListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/myinvoices/search/:keyword/page/:pageNumber"
          element={user ? <AgentInvoiceListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/myinvoices/:id"
          element={user ? <AgentInvoiceDetailScreen /> : <Navigate to="/" />}
        />

        {/* my rates */}
        <Route
          path="/myrate"
          element={user ? <AgentRateScreen /> : <Navigate to="/" />}
        />

        {/* my payments */}
        <Route
          path="/mypayments"
          element={user ? <AgentPaymentListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mypayments/search/:keyword"
          element={user ? <AgentPaymentListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mypayments/page/:pageNumber"
          element={user ? <AgentPaymentListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mypayments/search/:keyword/page/:pageNumber"
          element={user ? <AgentPaymentListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mypayments/:id"
          element={user ? <AgentPaymentDetailScreen /> : <Navigate to="/" />}
        />

        {/* -----------------------------------------------thailand post------------------------------------------------- */}
        <Route
          path="/thailandpost"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <PostShipmentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/postscan"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <PostScanningScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thailandpost/search/:keyword"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <PostShipmentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thailandpost/page/:pageNumber"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <PostShipmentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thailandpost/search/:keyword/page/:pageNumber"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <PostShipmentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thailandpost/:id"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <PostShipmentDetailScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thailandpostpre/:id"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <PostPreShipmentScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thailandpostcreate"
          element={
            user && (user.isAdmin || user.accountType === "user") ? (
              <PostShipmentCreateScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thailandpostratecreate"
          element={
            user && user.isAdmin ? (
              <PostRateCreateScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thailandpostrateedit"
          element={
            user && user.isAdmin ? <PostRateEditScreen /> : <Navigate to="/" />
          }
        />
        <Route
          path="/thailandpostrate"
          element={
            user && user.isAdmin ? <PostRateListScreen /> : <Navigate to="/" />
          }
        />

        {/* my thp invoices */}
        <Route
          path="/mythpinvoices"
          element={user ? <PostInvoiceMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythpinvoices/search/:keyword"
          element={user ? <PostInvoiceMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythpinvoices/page/:pageNumber"
          element={user ? <PostInvoiceMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythpinvoices/search/:keyword/page/:pageNumber"
          element={user ? <PostInvoiceMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythpinvoices/:id"
          element={user ? <PostSaleInvoiceDetailScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/salecreatethpinvoice"
          element={user ? <PostSaleInvoiceCreateScreen /> : <Navigate to="/" />}
        />

        {/* my thp payments */}
        <Route
          path="/mythppayments"
          element={user ? <PostPaymentMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythppayments/search/:keyword"
          element={user ? <PostPaymentMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythppayments/page/:pageNumber"
          element={user ? <PostPaymentMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythppayments/search/:keyword/page/:pageNumber"
          element={user ? <PostPaymentMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythppayments/:id"
          element={user ? <PostSalePaymentDetailScreen /> : <Navigate to="/" />}
        />

        {/* my thp shipments */}
        <Route
          path="/mythpshipments"
          element={user ? <PostShipmentMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythpshipments/search/:keyword"
          element={user ? <PostShipmentMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythpshipments/page/:pageNumber"
          element={user ? <PostShipmentMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythpshipments/search/:keyword/page/:pageNumber"
          element={user ? <PostShipmentMyListScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/mythpshipments/:id"
          element={
            user ? <PostSaleShipmentDetailScreen /> : <Navigate to="/" />
          }
        />

        {/* -----------------------------------------------THP invoices------------------------------------------------- */}
        <Route
          path="/thpcreateinvoice"
          element={
            user && user.isAdmin ? (
              <PostInvoiceCreateScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thpinvoices"
          element={
            user && user.isAdmin ? (
              <PostInvoiceListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thpinvoices/page/:pageNumber"
          element={
            user && user.isAdmin ? (
              <PostInvoiceListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thpinvoices/search/:keyword"
          element={
            user && user.isAdmin ? (
              <PostInvoiceListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thpinvoices/search/:keyword/page/:pageNumber"
          element={
            user && user.isAdmin ? (
              <PostInvoiceListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thpinvoices/:id"
          element={
            user && user.isAdmin ? (
              <PostInvoiceDetailScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />

        {/* -----------------------------------------------THP Payments------------------------------------------------- */}
        <Route
          path="/thppayments"
          element={
            user && user.isAdmin ? (
              <PostPaymentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thppayments/:id"
          element={
            user && user.accountType !== "agent" ? (
              <PostPaymentDetailScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thppayments/search/:keyword"
          element={
            user && user.isAdmin ? (
              <PostPaymentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thppayments/page/:pageNumber"
          element={
            user && user.isAdmin ? (
              <PostPaymentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/thppayments/search/:keyword/page/:pageNumber"
          element={
            user && user.isAdmin ? (
              <PostPaymentListScreen />
            ) : (
              <Navigate to="/" />
            )
          }
        />


        {/*================================  Routes for Domastics====================================================== */}
        <Route
          path="/predomestic/:id" 
          element={ user && (user.isAdmin || user.accountType === "user") ? (<DomesticPreDetailScreen />) : (<Navigate to="/" />)}
        />


        {/* Domestic Rate Routes */}
        <Route path="/domesticrate" element={user && user.isAdmin ? (<DomesticRateListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticrate/add" element={user && user.isAdmin ? (<DomesticRateAddScreen />) : (<Navigate to="/" />)} />

        {/* Domestic Shipments Routes */}
        <Route path="/domesticscan" element={user ? (<DomesticScanningScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticshipment" element={user ? (<DomesticShipmentListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticshipment/page/:pageNumber" element={user ? (<DomesticShipmentListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticshipment/search/:keyword" element={user ? (<DomesticShipmentListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticshipment/search/:keyword/page/:pageNumber" element={user ? (<DomesticShipmentListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticshipment/:id" element={user ? (<DomesticShipmentDetailScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticshipment/add" element={user ? (<DomesticShipmentCreateScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticshipment/label" element={user ? (<DomesticShippingLabel />) : (<Navigate to="/" />)} />


        {/* Domestic Invoice Routes */}
        <Route path="/domesticinvoice" element={user && user.isAdmin ? (<DomesticInvoiceListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticinvoice/page/:pageNumber" element={user && user.isAdmin ? (<DomesticInvoiceListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticinvoice/search/:keyword" element={user && user.isAdmin ? (<DomesticInvoiceListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticinvoice/search/:keyword/page/:pageNumber" element={user && user.isAdmin ? (<DomesticInvoiceListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticinvoice/add" element={user && user.isAdmin ? (<DomesticInvoiceCreateScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticinvoice/:id" element={user && user.isAdmin ? (<DomesticInvoiceDetailScreen/>) : (<Navigate to="/" />)} />



        {/* Domestic Payment Routes */}
        <Route path="/domesticpayment" element={user && user.isAdmin ? (<DomesticPaymentListScreen/>) : (<Navigate to="/" />)} />
        <Route path="/domesticpayment/page/:pageNumber" element={user && user.isAdmin ? (<DomesticPaymentListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticpayment/search/:keyword" element={user && user.isAdmin ? (<DomesticPaymentListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticpayment/search/:keyword/page/:pageNumber" element={user && user.isAdmin ? (<DomesticPaymentListScreen />) : (<Navigate to="/" />)} />
        <Route path="/domesticpayment/:id" element={user && user.isAdmin ? (<DomesticPaymentDetailScreen/>) : (<Navigate to="/" />)} />



        {/* My Domestic Shipments Routes */}
        <Route path="/mydomesticshipment" element={user ? (<DomesticShipmentMyListScreen />) : (<Navigate to="/" />)} />
        <Route path="/mydomesticshipment/page/:pageNumber" element={user ? (<DomesticShipmentMyListScreen />) : (<Navigate to="/" />)} />
        <Route path="/mydomesticshipment/search/:keyword" element={user ? (<DomesticShipmentMyListScreen />) : (<Navigate to="/" />)} />
        <Route path="/mydomesticshipment/search/:keyword/page/:pageNumber" element={user ? (<DomesticShipmentMyListScreen />) : (<Navigate to="/" />)} />
        <Route path="/mydomesticshipment/:id" element={user ? (<DomesticShipmentMyDetailScreen />) : (<Navigate to="/" />)} />

        {/* My Domestic Invoice Routes */}
        <Route path="/mydomesticinvoice" element={user ? (<DomesticInvoiceMyListScreen />) : (<Navigate to="/" />)} />
        <Route path="/mydomesticinvoice/page/:pageNumber" element={user ? (<DomesticInvoiceMyListScreen />) : (<Navigate to="/" />)} />
        <Route path="/mydomesticinvoice/search/:keyword" element={user ? (<DomesticInvoiceMyListScreen />) : (<Navigate to="/" />)} />
        <Route path="/mydomesticinvoice/search/:keyword/page/:pageNumber" element={user ? (<DomesticInvoiceMyListScreen />) : (<Navigate to="/" />)} />
        <Route path="/salecreatedomesticinvoice" element={user ? (<DomesticInvoiceMyCreateScreen />) : (<Navigate to="/" />)} />
        <Route path="/mydomesticinvoice/:id" element={user ? (<DomesticInvoiceMyDetailScreen />) : (<Navigate to="/" />)} />

        {/* My Domestic Payment Routes */}
        <Route path="/mydomesticpayment" element={user ? (<DomesticPaymentMyListScreen/>) : (<Navigate to="/" />)} />
        <Route path="/mydomesticpayment/page/:pageNumber" element={user ? (<DomesticPaymentMyListScreen />) : (<Navigate to="/" />)} />
        <Route path="/mydomesticpayment/search/:keyword" element={user ? (<DomesticPaymentMyListScreen />) : (<Navigate to="/" />)} />
        <Route path="/mydomesticpayment/search/:keyword/page/:pageNumber" element={user ? (<DomesticPaymentMyListScreen />) : (<Navigate to="/" />)} />
        <Route path="/mydomesticpayment/:id" element={user ? (<DomesticPaymentMyDetailScreen />) : (<Navigate to="/" />)} />





      </Routes>     
        
    </>
  );
}

export default App;
