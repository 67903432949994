import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { getShippigChart } from '../actions/dashboardActions'
import { Error, MySpinner } from '../components'
import { 
  Box,
  Center,
  Spacer,
  Button,
  HStack,
} from '@chakra-ui/react'
import { Tooltip, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts'

const AgentShippingChartScreen = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const { loading, success, error, dataList } = useSelector((state)=>state.system.shippingChart)

  useEffect(()=>{
    dispatch(getShippigChart(params.id))
  },[dispatch, params])


  return (
    <Box>
      {loading && <Center><MySpinner /></Center>}
      {error && <Error error={error} />}
      <HStack>
        <Box my={4}>
            <Link to={'/shippingchart'}>
                <Button colorScheme='blue'>
                    Back
                </Button>
            </Link>
        </Box>
      </HStack>
      {success && dataList.map((d,i)=>(
        <Box key={i} p={3} m={3} border={'1px'}  style={{width: '1250px'}} borderRadius={5}>
          <HStack fontSize={'lg'} bg={'pink.200'} px='3' py='2'>
            <Box fontWeight={'800'}>{i+1} . {d.user.code} - {d.user.name}</Box>   
            <Spacer />
            <Box>SALE - {d && d.mainSale && d.mainSale.name}</Box>         
          </HStack>
          <Box mt={3}>
            <LineChart width={1200} height={200} data={d.monthData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
              <Line type="monotone" dataKey="shipmentCount" stroke="#9F0D7F" />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="month" style={{fontsize: '10px'}} />
              <YAxis />
              <Tooltip />
            </LineChart>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default AgentShippingChartScreen