export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_RESET = 'USER_LOGIN_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_EDIT_REQUEST = 'USER_EDIT_REQUEST'
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS'
export const USER_EDIT_FAIL = 'USER_EDIT_FAIL'
export const USER_EDIT_RESET = 'USER_EDIT_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_DELETE_RESET = 'USER_DELETE_RESET'

export const USER_BYID_REQUEST = 'USER_BYID_REQUEST'
export const USER_BYID_SUCCESS = 'USER_BYID_SUCCESS'
export const USER_BYID_FAIL = 'USER_BYID_FAIL'
export const USER_BYID_RESET = 'USER_BYID_RESET'

export const USER_MY_REQUEST = 'USER_MY_REQUEST'
export const USER_MY_SUCCESS = 'USER_MY_SUCCESS'
export const USER_MY_FAIL = 'USER_MY_FAIL'
export const USER_MY_RESET = 'USER_MY_RESET'

export const USER_MY_PASSWORD_REQUEST = 'USER_MY_PASSWORD_REQUEST'
export const USER_MY_PASSWORD_SUCCESS = 'USER_MY_PASSWORD_SUCCESS'
export const USER_MY_PASSWORD_FAIL = 'USER_MY_PASSWORD_FAIL'
export const USER_MY_PASSWORD_RESET = 'USER_MY_PASSWORD_RESET'

export const USER_SALES_NAMES_REQUEST = 'USER_SALES_NAMES_REQUEST'
export const USER_SALES_NAMES_SUCCESS = 'USER_SALES_NAMES_SUCCESS'
export const USER_SALES_NAMES_FAIL = 'USER_SALES_NAMES_FAIL'
export const USER_SALES_NAMES_RESET = 'USER_SALES_NAMES_RESET'

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST'
export const USER_FORGOT_PASSWORD_SUCCESS = 'USER_FORGOT_PASSWORD_SUCCESS'
export const USER_FORGOT_PASSWORD_FAIL = 'USER_FORGOT_PASSWORD_FAIL'
export const USER_FORGOT_PASSWORD_RESET = 'USER_FORGOT_PASSWORD_RESET'

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST'
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS'
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL'
export const USER_RESET_PASSWORD_RESET = 'USER_RESET_PASSWORD_RESET'

export const USER_AGENTLIST_REQUEST = 'USER_AGENTLIST_REQUEST'
export const USER_AGENTLIST_SUCCESS = 'USER_AGENTLIST_SUCCESS'
export const USER_AGENTLIST_FAIL = 'USER_AGENTLIST_FAIL'
export const USER_AGENTLIST_RESET = 'USER_AGENTLIST_RESET'

export const USER_SALELIST_REQUEST = 'USER_SALELIST_REQUEST'
export const USER_SALELIST_SUCCESS = 'USER_SALELIST_SUCCESS'
export const USER_SALELIST_FAIL = 'USER_SALELIST_FAIL'
export const USER_SALELIST_RESET = 'USER_SALELIST_RESET'

export const USER_AGENTBYSALEID_REQUEST = 'USER_AGENTBYSALEID_REQUEST'
export const USER_AGENTBYSALEID_SUCCESS = 'USER_AGENTBYSALEID_SUCCESS'
export const USER_AGENTBYSALEID_FAIL = 'USER_AGENTBYSALEID_FAIL'
export const USER_AGENTBYSALEID_RESET = 'USER_AGENTBYSALEID_RESET'

export const USER_ADDBALANCE_REQUEST = 'USER_ADDBALANCE_REQUEST'
export const USER_ADDBALANCE_SUCCESS = 'USER_ADDBALANCE_SUCCESS'
export const USER_ADDBALANCE_FAIL = 'USER_ADDBALANCE_FAIL'
export const USER_ADDBALANCE_RESET = 'USER_ADDBALANCE_RESET'

export const USER_MYAGENTS_REQUEST = 'USER_MYAGENTS_REQUEST'
export const USER_MYAGENTS_SUCCESS = 'USER_MYAGENTS_SUCCESS'
export const USER_MYAGENTS_FAIL = 'USER_MYAGENTS_FAIL'
export const USER_MYAGENTS_RESET = 'USER_MYAGENTS_RESET'

export const USER_MYAGENTDETAIL_REQUEST = 'USER_MYAGENTDETAIL_REQUEST'
export const USER_MYAGENTDETAIL_SUCCESS = 'USER_MYAGENTDETAIL_SUCCESS'
export const USER_MYAGENTDETAIL_FAIL = 'USER_MYAGENTDETAIL_FAIL'
export const USER_MYAGENTDETAIL_RESET = 'USER_MYAGENTDETAIL_RESET'

export const USER_CONFIRMPASSWORD_REQUEST = 'USER_CONFIRMPASSWORD_REQUEST'
export const USER_CONFIRMPASSWORD_SUCCESS = 'USER_CONFIRMPASSWORD_SUCCESS'
export const USER_CONFIRMPASSWORD_FAIL = 'USER_CONFIRMPASSWORD_FAIL'
export const USER_CONFIRMPASSWORD_RESET = 'USER_CONFIRMPASSWORD_RESET'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_ADDTHPDISCOUNT_REQUEST = 'USER_ADDTHPDISCOUNT_REQUEST'
export const USER_ADDTHPDISCOUNT_SUCCESS = 'USER_ADDTHPDISCOUNT_SUCCESS'
export const USER_ADDTHPDISCOUNT_FAIL = 'USER_ADDTHPDISCOUNT_FAIL'
export const USER_ADDTHPDISCOUNT_RESET = 'USER_ADDTHPDISCOUNT_RESET'

export const USER_DELETETHPDISCOUNT_REQUEST = 'USER_DELETETHPDISCOUNT_REQUEST'
export const USER_DELETETHPDISCOUNT_SUCCESS = 'USER_DELETETHPDISCOUNT_SUCCESS'
export const USER_DELETETHPDISCOUNT_FAIL = 'USER_DELETETHPDISCOUNT_FAIL'
export const USER_DELETETHPDISCOUNT_RESET = 'USER_DELETETHPDISCOUNT_RESET'

