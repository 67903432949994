import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Input,
    Box,
    Spinner,
} from '@chakra-ui/react'
import Error from '../components/Error'
import { updateAWBShipment, updateCostShipment } from '../actions/shipmentActions'

const ShipmentAWBUpdateModal = ({shipmentId}) => {
    const dispatch = useDispatch()
    const [newAWB, setNewAWB] = useState('')
    const [show, setShow] = useState(false)
    const {loading, error, success} = useSelector(state=>state.shipment.UpdateAWB)

    useEffect(()=>{
        if(success){
            setShow(false)
        }
    },[success, dispatch])

    const handleClose = () => {        
        setNewAWB(0)
        setShow(false)
    }


    return (
        <>
            <Button size='xs' colorScheme={'blue'} onClick={()=>setShow(true)} >Update</Button>

            <Modal isOpen={show} isCentered onClose={handleClose} size='xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>update awb</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Box my='3'>
                        {error && <Error error={error} />}
                    </Box>                   

                    <Input  
                        type={'text'}
                        value={newAWB}
                        onChange={e=>setNewAWB(e.target.value)}
                        onKeyPress={e=> {
                            if(e.key === 'Enter'){
                                dispatch(updateAWBShipment(e.target.value, shipmentId))
                            }
                        }}
                    />

                </ModalBody>

                <ModalFooter>
                    <Button 
                        size='sm' w='150px' 
                        colorScheme={'green'} 
                        onClick={()=>dispatch(updateAWBShipment(newAWB, shipmentId))} >Submit</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default ShipmentAWBUpdateModal