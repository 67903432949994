import axios from 'axios'
import {
    DOMESTICSHIPMENT_BYID_FAIL,
    DOMESTICSHIPMENT_BYID_REQUEST,
    DOMESTICSHIPMENT_BYID_SUCCESS,
    DOMESTICSHIPMENT_CHARGES_FAIL,
    DOMESTICSHIPMENT_CHARGES_REQUEST,
    DOMESTICSHIPMENT_CHARGES_SUCCESS,
    DOMESTICSHIPMENT_CREATE_FAIL,
    DOMESTICSHIPMENT_CREATE_REQUEST, 
    DOMESTICSHIPMENT_CREATE_SUCCESS,
    DOMESTICSHIPMENT_DELETE_FAIL,
    DOMESTICSHIPMENT_DELETE_REQUEST,
    DOMESTICSHIPMENT_DELETE_SUCCESS,
    DOMESTICSHIPMENT_UPDATE_FAIL,
    DOMESTICSHIPMENT_UPDATE_REQUEST,
    DOMESTICSHIPMENT_UPDATE_SUCCESS,
    DOMESTICSHIPMENT_GETCOST_FAIL,
    DOMESTICSHIPMENT_GETCOST_REQUEST,
    DOMESTICSHIPMENT_GETCOST_SUCCESS,
    DOMESTICSHIPMENT_LIST_FAIL,
    DOMESTICSHIPMENT_LIST_REQUEST,
    DOMESTICSHIPMENT_LIST_SUCCESS,
    DOMESTICSHIPMENT_MYLIST_FAIL,
    DOMESTICSHIPMENT_MYLIST_REQUEST,
    DOMESTICSHIPMENT_MYLIST_SUCCESS,
    DOMESTICSHIPMENT_PRESHIPCONTACT_FAIL,
    DOMESTICSHIPMENT_PRESHIPCONTACT_REQUEST,
    DOMESTICSHIPMENT_PRESHIPCONTACT_SUCCESS,
    DOMESTICSHIPMENT_UPDATECOST_FAIL,
    DOMESTICSHIPMENT_UPDATECOST_REQUEST,
    DOMESTICSHIPMENT_UPDATECOST_SUCCESS,
    DOMESTICSHIPMENT_SCANNED_REQUEST,
    DOMESTICSHIPMENT_SCANNED_SUCCESS,
    DOMESTICSHIPMENT_SCANNED_FAIL,
    DOMESTICSHIPMENT_GET_SCANNED_REQUEST,
    DOMESTICSHIPMENT_GET_SCANNED_SUCCESS,
    DOMESTICSHIPMENT_GET_SCANNED_FAIL,
    DOMESTICSHIPMENT_SHIPANDSCAN_REQUEST,
    DOMESTICSHIPMENT_SHIPANDSCAN_SUCCESS,
    DOMESTICSHIPMENT_SHIPANDSCAN_FAIL,
} from '../../constants/domestic_constants/domesticShipmentConstants'

export const createDomesticShipment = (domesticShipmentData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/domestic/shipments`, domesticShipmentData, config)
        dispatch({type: DOMESTICSHIPMENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listShipments = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/shipments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: DOMESTICSHIPMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const filterShipments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/domestic/shipments/filteredshipments`,{filterData}, config)
        dispatch({type: DOMESTICSHIPMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listMyShipments = (pageNumber = '', itemCount = '', keyword = '', date='') => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_MYLIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/shipments/myshipments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}&date=${date}`, config)
        dispatch({type: DOMESTICSHIPMENT_MYLIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_MYLIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myFilteredShipments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_MYLIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/domestic/shipments/myfilteredshipments`,{filterData}, config)
        dispatch({type: DOMESTICSHIPMENT_MYLIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_MYLIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getDomesticShipment = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/shipments/${id}`, config)
        dispatch({type: DOMESTICSHIPMENT_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const deleteDomesticShipment = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.delete(`/api/domestic/shipments/${id}`, config)
        dispatch({type: DOMESTICSHIPMENT_DELETE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateDomesticShipment = (newData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_UPDATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.put(`/api/domestic/shipments/${newData.shipmentId}`, newData, config)
        dispatch({type: DOMESTICSHIPMENT_UPDATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getCostDomesticShipment = (shipData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_GETCOST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/domestic/rates/getcost`, {shipData}, config)
        dispatch({type: DOMESTICSHIPMENT_GETCOST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_GETCOST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const saveContactDomesticShipments = (contactData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_PRESHIPCONTACT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/domestic/shipments/contact`,{contactData}, config)
        dispatch({type: DOMESTICSHIPMENT_PRESHIPCONTACT_SUCCESS})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_PRESHIPCONTACT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateChargesDomesticShipment = (chargeData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_CHARGES_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/domestic/shipments/charge`, {chargeData}, config)
        dispatch({type: DOMESTICSHIPMENT_CHARGES_SUCCESS})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_CHARGES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateCostDomesticShipment = (cost, shipmentId) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_UPDATECOST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/domestic/shipments/cost`, {cost, shipmentId}, config)
        dispatch({type: DOMESTICSHIPMENT_UPDATECOST_SUCCESS})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_UPDATECOST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const scannedShipment = (awbpreid) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_SCANNED_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/domestic/shipments/scanned`, {awbpreid}, config)
        dispatch({type: DOMESTICSHIPMENT_SCANNED_SUCCESS})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_SCANNED_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getSannedShipment = (date='') => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_GET_SCANNED_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/shipments/scanned?date=${date}`, config)
        dispatch({type: DOMESTICSHIPMENT_GET_SCANNED_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_GET_SCANNED_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const shipScanList = () => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICSHIPMENT_SHIPANDSCAN_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/shipments/shipandscan`, config)
        dispatch({type: DOMESTICSHIPMENT_SHIPANDSCAN_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICSHIPMENT_SHIPANDSCAN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}