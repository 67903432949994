import React, { useEffect, useRef, useState } from 'react'
import { 
    Box,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Input,
    Button,
    Stack,
    Select,
    useToast,
    Text,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    Heading,
    Divider
} from '@chakra-ui/react'
import { getCountryList } from '../actions/countryActions'
import { useSelector, useDispatch } from 'react-redux'
import { checkUSRemoteArea, setRemoteRate, getRemoteRate } from '../actions/systemActions'
import { Error } from '../components'
import { REMOTE_AREA_CHECK_RESET } from '../constants/systemConstants'
import { dhlValidateAddress } from '../actions/addressActions'


const RemoteAreaCheckComponent = () => {
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()
    const toast = useToast()
    const [countryCode, setCountryCode] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [usRemoteRate, setUsRemoteRate] = useState('')
    const [otherRemoteRate, setOtherRemoteRate] = useState('')
    const [cityList, setCityList] = useState([])
    const [selectedCity, setSelectedCity] = useState('')
    const { loading:remoteLoading, error: remoteError, success, isRemote } = useSelector(state=>state.system.usRemoteArea)
    const { loading:remoteRateLoading, error: remoteRateError, success: remoteRateSuccess } = useSelector(state=>state.system.setRemoteRate)
    const { loading:getRemoteRateLoading, error: getRemoteRateError, success: getRemoteRateSuccess, remoterate } = useSelector(state=>state.system.getRemoteRate)
    const { loading:countryLoading, countries, error:countryError } = useSelector(state=>state.country.List)
    const {loading: loadingDHLAddress, error: errorDHLAddress, data: addressData} = useSelector(state=>state.address.Validate)
    const {user} = useSelector(state=> state.login)

    const handleCheckRemoteArea = () => {
        
        if(countryCode === ''){
            toast({position: 'top', variant:'subtle', status:'warning', description:'Country is required', duration: 2000})
            return
        }
        if(postalCode === ''){
            toast({position: 'top', variant:'subtle', status:'warning', description:'Postal Code is required', duration: 2000})
            return
        }
        const pCode = postalCode.toUpperCase()
        dispatch(checkUSRemoteArea({postalCode:pCode, countryCode, selectedCity}))
    }

    useEffect(()=>{
        if(addressData){
            setCityList([...addressData.address])            
        }
        if(remoteError){
            toast({position: 'top', variant:'subtle', status:'warning', description: remoteError, duration: 4000})
        }        
        if(errorDHLAddress){
            toast({position: 'top', variant:'subtle', status:'warning', description: 'Postal code error', duration: 2000})
        }
    },[toast, remoteError, addressData, errorDHLAddress])

    useEffect(()=>{
        if(getRemoteRateSuccess){
            setUsRemoteRate(remoterate.us?.toString())
            setOtherRemoteRate(remoterate.other?.toString())
        }
    },[getRemoteRateSuccess, remoterate])

    const handleAddressValidate = () => {       
        const dataToValidate = {
            countryCode: countryCode,
            postalCode: postalCode
        }
        if(countryCode === '' || postalCode === ''){
            toast({
                status: 'info',
                position: 'top',
                title: 'Select country and type postal code.',
                duration: 2000,
                isClosable: true,
            }) 
            return
        } else {
            dispatch(dhlValidateAddress(dataToValidate))
        }     
    }

    return (
        <Box
            position={'fixed'}
            top={80}
            left={0}
        >
            <Box 
                style={{
                    'writingMode':'vertical-rl', 
                    'textOrientation':'mised',
                    'cursor': 'pointer',
                    'backgroundColor':'lightsalmon',
                }}
                p={3}
                borderRightRadius={10}
                _hover={{
                    transform: 'translateX(7px)', 
                    transitionDuration: '0.4s', 
                    transitionTimingFunction: "ease-in-out",

                }}
                ref={btnRef}  
                onClick={()=>{
                    dispatch(getCountryList())
                    if(user && user.isAdmin) {
                        dispatch(getRemoteRate())
                    }
                    onOpen()
                }}
            >Remote Area Check</Box>

            <Drawer
                isOpen={isOpen}
                placement='left'
                onClose={()=>{
                    dispatch({type: REMOTE_AREA_CHECK_RESET})
                    setCountryCode('')
                    setPostalCode('')
                    setUsRemoteRate('')
                    setOtherRemoteRate('')
                    setCityList([])
                    setSelectedCity('')
                    onClose()
                }}
                finalFocusRef={btnRef}
                
            >
                <DrawerOverlay />
                <DrawerContent >
                <DrawerCloseButton />
                <DrawerHeader>Remote Area Check</DrawerHeader>

                <DrawerBody>
                    {countryLoading ?
                        <Spinner />
                        :
                        <Stack gap={3}>
                            {countryError && <Error error={countryError} />}
                        
                            {success && isRemote && 
                                <Alert status='success'>
                                    <AlertIcon />
                                    <AlertTitle>{postalCode} is remote area.</AlertTitle>
                                </Alert>
                            }
                            {success && !isRemote && 
                                <Alert status='error'>
                                    <AlertIcon />
                                    <AlertTitle>{postalCode} is NOT remote area.</AlertTitle>
                                </Alert>
                            }
                            <Select
                                placeholder='Select Country'
                                onChange={({target})=>{
                                    dispatch({type: REMOTE_AREA_CHECK_RESET})
                                    setCountryCode(target.value)
                                }}
                            >
                                {countries.map((c)=>(
                                    <option key={c.name} value={c.countryCode}>{c.name}</option>
                                ))}
                            </Select>
                            <Box>
                                <Text fontSize={'sm'}>Postal Code</Text>
                                <Input 
                                    id={'postal-code-input'}
                                    value={postalCode}
                                    onChange={({target})=>setPostalCode(target.value)}
                                    onKeyDown={(e)=>{
                                        // if(e.key==='Enter'){
                                        //     handleCheckRemoteArea()
                                        // }
                                        // dispatch({type: REMOTE_AREA_CHECK_RESET})
                                        setCityList([])
                                        setSelectedCity('')
                                        dispatch({type: REMOTE_AREA_CHECK_RESET})
                                    }}
                                />
                            </Box>

                            <Button
                                w={'100%'}
                                isLoading={loadingDHLAddress}
                                colorScheme='green'
                                onClick={handleAddressValidate}
                            >Get Cities</Button>
                            
                            {addressData &&
                                <Select 
                                    placeholder='Select City'
                                    id='city-selection'
                                    onChange={(e)=>{
                                        setSelectedCity(e.target.value)
                                    }}
                                    
                                >
                                    {cityList.map((city,idx)=> (
                                        <option key={idx} value={city.cityName}>{city.cityName}</option>
                                        // <option key={idx} value={JSON.stringify(ctry)}>{ctry.cName}</option>
                                    ))}
                                </Select>  
                            }

                            <Button
                                w={'100%'}
                                isLoading={remoteLoading}
                                disabled={selectedCity===''}
                                onClick={handleCheckRemoteArea}
                            >
                                Check
                            </Button>
                        </Stack>
                    }
                    <Divider my={4} />
                    {user && user.isAdmin &&
                        <Stack gap={3}>
                            <Heading size="md"> Remote Rate </Heading>
                            {remoteRateError && <Error error={remoteRateError} />}
                            {getRemoteRateError && <Error error={getRemoteRateError} />}                            
                            {getRemoteRateLoading && <Spinner />}
                            {remoteRateSuccess && 
                                <Alert status='success'>
                                    <AlertIcon />
                                    <AlertTitle>Remote rate saved.</AlertTitle>
                                </Alert>
                            }
                            <Box display={'flex'}>
                                <Text w={'30%'} lineHeight={'40px'}>US</Text>
                                <Input 
                                    type='number'
                                    value={usRemoteRate}
                                    id={'us-remote-rate'}
                                    onChange={({target})=>{
                                        setUsRemoteRate(target.value)
                                    }}
                                />
                            </Box>
                            <Box display={'flex'}>
                                <Text w={'30%'} lineHeight={'40px'}>Other</Text>
                                <Input 
                                    type='number'
                                    value={otherRemoteRate}
                                    id={'other-remote-rate'}
                                    onChange={({target})=>{
                                        setOtherRemoteRate(target.value)
                                    }}
                                />
                            </Box>

                            <Button
                                w={'100%'}
                                isLoading={remoteRateLoading}
                                colorScheme='green'
                                onClick={()=>{
                                    if(!usRemoteRate || usRemoteRate.trim() === ''){
                                        return
                                    }
                                    if(!otherRemoteRate || otherRemoteRate.trim() === ''){
                                        return
                                    }
                                    const data = {
                                        usRate: Number(usRemoteRate),
                                        otherRate: Number(otherRemoteRate)
                                    }
                                    //console.log(data)
                                    dispatch(setRemoteRate(data))
                                }}
                            >Save</Button>
                        </Stack>                        
                    }
                    
                </DrawerBody>

                
                </DrawerContent>
            </Drawer>

            
        </Box>
    )
}

export default RemoteAreaCheckComponent