import React from 'react'
import {Box, } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'

function View({children}) {
  const {pathname} = useLocation()

  return (
    <Box px={pathname === '/' ? '0' : '5%'} > 
      {children}
    </Box>    
  )
}

export default View