export const THPSHIPMENT_CREATE_REQUEST = 'THPSHIPMENT_CREATE_REQUEST'
export const THPSHIPMENT_CREATE_SUCCESS = 'THPSHIPMENT_CREATE_SUCCESS'
export const THPSHIPMENT_CREATE_FAIL = 'THPSHIPMENT_CREATE_FAIL'
export const THPSHIPMENT_CREATE_RESET = 'THPSHIPMENT_CREATE_RESET'

export const THPSHIPMENT_LIST_REQUEST = 'THPSHIPMENT_LIST_REQUEST'
export const THPSHIPMENT_LIST_SUCCESS = 'THPSHIPMENT_LIST_SUCCESS'
export const THPSHIPMENT_LIST_FAIL = 'THPSHIPMENT_LIST_FAIL'
export const THPSHIPMENT_LIST_RESET = 'THPSHIPMENT_LIST_RESET'


export const THPSHIPMENT_BYID_REQUEST = 'THPSHIPMENT_BYID_REQUEST'
export const THPSHIPMENT_BYID_SUCCESS = 'THPSHIPMENT_BYID_SUCCESS'
export const THPSHIPMENT_BYID_FAIL = 'THPSHIPMENT_BYID_FAIL'
export const THPSHIPMENT_BYID_RESET = 'THPSHIPMENT_BYID_RESET'

export const THPSHIPMENT_DELETE_REQUEST = 'THPSHIPMENT_DELETE_REQUEST'
export const THPSHIPMENT_DELETE_SUCCESS = 'THPSHIPMENT_DELETE_SUCCESS'
export const THPSHIPMENT_DELETE_FAIL = 'THPSHIPMENT_DELETE_FAIL'
export const THPSHIPMENT_DELETE_RESET = 'THPSHIPMENT_DELETE_RESET'

export const THPSHIPMENT_UPDATE_REQUEST = 'THPSHIPMENT_UPDATE_REQUEST'
export const THPSHIPMENT_UPDATE_SUCCESS = 'THPSHIPMENT_UPDATE_SUCCESS'
export const THPSHIPMENT_UPDATE_FAIL = 'THPSHIPMENT_UPDATE_FAIL'
export const THPSHIPMENT_UPDATE_RESET = 'THPSHIPMENT_UPDATE_RESET'

export const THPSHIPMENT_GETCOST_REQUEST = 'THPSHIPMENT_GETCOST_REQUEST'
export const THPSHIPMENT_GETCOST_SUCCESS = 'THPSHIPMENT_GETCOST_SUCCESS'
export const THPSHIPMENT_GETCOST_FAIL = 'THPSHIPMENT_GETCOST_FAIL'
export const THPSHIPMENT_GETCOST_RESET = 'THPSHIPMENT_GETCOST_RESET'

export const THPSHIPMENT_MYLIST_REQUEST = 'THPSHIPMENT_MYLIST_REQUEST'
export const THPSHIPMENT_MYLIST_SUCCESS = 'THPSHIPMENT_MYLIST_SUCCESS'
export const THPSHIPMENT_MYLIST_FAIL = 'THPSHIPMENT_MYLIST_FAIL'
export const THPSHIPMENT_MYLIST_RESET = 'THPSHIPMENT_MYLIST_RESET'

export const THPSHIPMENT_PRESHIPCONTACT_REQUEST = 'THPSHIPMENT_PRESHIPCONTACT_REQUEST'
export const THPSHIPMENT_PRESHIPCONTACT_SUCCESS = 'THPSHIPMENT_PRESHIPCONTACT_SUCCESS'
export const THPSHIPMENT_PRESHIPCONTACT_FAIL = 'THPSHIPMENT_PRESHIPCONTACT_FAIL'
export const THPSHIPMENT_PRESHIPCONTACT_RESET = 'THPSHIPMENT_PRESHIPCONTACT_RESET'

export const THPSHIPMENT_CHARGES_REQUEST = 'THPSHIPMENT_CHARGES_REQUEST'
export const THPSHIPMENT_CHARGES_SUCCESS = 'THPSHIPMENT_CHARGES_SUCCESS'
export const THPSHIPMENT_CHARGES_FAIL = 'THPSHIPMENT_CHARGES_FAIL'
export const THPSHIPMENT_CHARGES_RESET = 'THPSHIPMENT_CHARGES_RESET'

export const THPSHIPMENT_UPDATECOST_REQUEST = 'THPSHIPMENT_UPDATECOST_REQUEST'
export const THPSHIPMENT_UPDATECOST_SUCCESS = 'THPSHIPMENT_UPDATECOST_SUCCESS'
export const THPSHIPMENT_UPDATECOST_FAIL = 'THPSHIPMENT_UPDATECOST_FAIL'
export const THPSHIPMENT_UPDATECOST_RESET = 'THPSHIPMENT_UPDATECOST_RESET'

export const THPSHIPMENT_SCANNED_REQUEST = 'THPSHIPMENT_SCANNED_REQUEST'
export const THPSHIPMENT_SCANNED_SUCCESS = 'THPSHIPMENT_SCANNED_SUCCESS'
export const THPSHIPMENT_SCANNED_FAIL = 'THPSHIPMENT_SCANNED_FAIL'
export const THPSHIPMENT_SCANNED_RESET = 'THPSHIPMENT_SCANNED_RESET'

export const THPSHIPMENT_GET_SCANNED_REQUEST = 'THPSHIPMENT_GET_SCANNED_REQUEST'
export const THPSHIPMENT_GET_SCANNED_SUCCESS = 'THPSHIPMENT_GET_SCANNED_SUCCESS'
export const THPSHIPMENT_GET_SCANNED_FAIL = 'THPSHIPMENT_GET_SCANNED_FAIL'
export const THPSHIPMENT_GET_SCANNED_RESET = 'THPSHIPMENT_GET_SCANNED_RESET'

export const THPSHIPMENT_SHIPANDSCAN_REQUEST = 'THPSHIPMENT_SHIPANDSCAN_REQUEST'
export const THPSHIPMENT_SHIPANDSCAN_SUCCESS = 'THPSHIPMENT_SHIPANDSCAN_SUCCESS'
export const THPSHIPMENT_SHIPANDSCAN_FAIL = 'THPSHIPMENT_SHIPANDSCAN_FAIL'
export const THPSHIPMENT_SHIPANDSCAN_RESET = 'THPSHIPMENT_SHIPANDSCAN_RESET'


