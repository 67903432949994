import React from 'react'
import {
    Box,
    Grid,
    Stack,
    Text,
    HStack,
} from '@chakra-ui/react'

const FromAndToDetail = ({preshipment}) => {
    return (
        <Box>
            {preshipment && 
                <Grid
                templateRows='repeat(1, 1fr)'
                templateColumns='repeat(2, 1fr)'
                gap={4}
                >
                    {/* <Stack spacing={'0'}>
                        <Text fontSize={'2xl'} fontWeight={'700'}>From</Text>
                        <Text fontWeight={'400'}>{preshipment.contact.split(';')[0]}</Text>
                        <Text>{preshipment.contact.split(';')[0]}</Text>
                        <Text>522/3 INTARAKEEREE ROAD</Text>
                        <HStack>
                            <Text>TAK</Text>                        
                            <Text>63110</Text>
                        </HStack>
                        <Text>THAILAND</Text>
                        <Text>+{preshipment.contact.split(';')[1]}</Text>
                        <Text>{preshipment.contact.split(';')[2]}</Text>
                    </Stack> */}

                    {preshipment.fromId &&
                        <Box borderY={'1px'}>
                            <Stack spacing={'0'}>
                                <HStack>
                                    <Text w='150px'>Shipper : </Text>
                                    {/* <Text>{preshipment.isVplus ? 'SIAM MOEI CO.,LTD' : preshipment.fromId.company }</Text> */}
                                    <Text>{preshipment.agentId?.name}</Text>
                                </HStack>
                                
                                <HStack>
                                    <Text w='150px'>Contact : </Text>
                                    <Text>{  preshipment.fromId.name }</Text>
                                </HStack>
                                
                                <HStack>
                                    <Text h='4.5rem' w='150px'>Address : </Text>
                                    <Stack spacing={'0'}>
                                        <Text>{preshipment.isVplus ? '2456 MOO.1 THASAILUAT' : preshipment.fromId.address}</Text>
                                        <Text>{preshipment.fromId.city}</Text>
                                        <Text>{preshipment.fromId.country}, {preshipment.fromId.postalcode}</Text>
                                    </Stack>
                                    
                                </HStack>
                                
                                <HStack>
                                    <Text w='150px'>Phone : </Text>
                                    <Text>{preshipment.fromId.phone}</Text>
                                </HStack>
                                <HStack>
                                    <Text w='150px'>Email : </Text>
                                    <Text>{preshipment.isVplus ? '' : preshipment.fromId.email}</Text>
                                </HStack>
                            </Stack>
                        </Box>
                    }

                    {/* <Stack spacing={'0'}>
                        <Text fontSize={'2xl'} fontWeight={'700'}>Ship To</Text>
                        <Text fontWeight={'400'}>{preshipment.addressId.name}</Text>
                        <Text>{preshipment.addressId.company}</Text>
                        <Text>{preshipment.addressId.address}</Text>
                        {preshipment.addressId.address2 && <Text>{preshipment.addressId.address2}</Text>}
                        {preshipment.addressId.address3 && <Text>{preshipment.addressId.address3}</Text>}
                        <HStack>
                            <Text>{preshipment.addressId.city} </Text>
                            {preshipment.addressId.stateProvince && <Text>{preshipment.addressId.stateProvince} </Text>}
                            <Text>{preshipment.addressId.postalcode}</Text>
                        </HStack>
                         
                        <Text>{preshipment.addressId.country}</Text>
                        <Text>{preshipment.addressId.phone}</Text>
                        {preshipment.addressId.email && <Text>{preshipment.addressId.email}</Text>}
                    </Stack> */}

                    <Box borderY={'1px'}>
                        <Stack spacing={'0'}>
                            <HStack>
                            <Text w='150px'>Consignee : </Text>
                            <Text>{ preshipment.addressId && preshipment.addressId.company}</Text>
                            </HStack>
                            

                            <HStack>
                            <Text w='150px'>Contact : </Text>
                            <Text>{ preshipment.addressId && preshipment.addressId.name}</Text>
                            </HStack>
                            

                            <HStack my='0'>
                            <Text h='5rem' w='150px'>Address : </Text>
                                <Box>
                                { preshipment.addressId.address} <br />                    
                                { preshipment.addressId.address2 && <Text>{preshipment.addressId.address2}<br /></Text>}
                                { preshipment.addressId.address3 && <Text>{preshipment.addressId.address3}<br /></Text>}                  
                                { preshipment.addressId && preshipment.addressId.city}, { preshipment.addressId.stateProvince && preshipment.addressId.stateProvince} <br />
                                { preshipment.addressId && preshipment.addressId.country + ' ' + preshipment.addressId.postalcode}
                                </Box>               
                            </HStack>
                            <HStack>
                            <Text w='150px'>Phone : </Text>
                            <Text>{ preshipment.addressId && preshipment.addressId.phone}</Text>
                            </HStack>              
                            <HStack>
                            <Text w='150px'>Email : </Text>
                            <Text>{ preshipment.addressId.email && preshipment.addressId.email}</Text>
                            </HStack>                
                        </Stack>
                    </Box>
                </Grid>
            }
        </Box>
    )
}

export default FromAndToDetail