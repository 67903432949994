import React, {useRef, useState} from 'react'
import { useDispatch } from 'react-redux'
import IdleTimer from 'react-idle-timer'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    Spacer,
    Button,
  } from '@chakra-ui/react'
  import { logoutUser } from '../actions/userActions'
import { USER_LOGOUT } from '../constants/userConstants'

const IdleTimerContainer = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const idleTimerRef = useRef(null)
    const sessionTimeRef = useRef(null)

    const onIdle = () => {
        setShow(true)
        sessionTimeRef.current = setTimeout(logoutHandle, 30*60*1000) // 30 minutes
    }
    const onClose = () => {
        setShow(false)
        clearTimeout(sessionTimeRef.current)                
    }
    const logoutHandle = () => {
        clearTimeout(sessionTimeRef.current)
        setShow(false)
        dispatch({type: USER_LOGOUT})
        dispatch(logoutUser()) 
    }
    return (
        <div>
            <Modal isOpen={show} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>User idle</ModalHeader>

                <ModalFooter>
                    <Button w='170px' variant='outline' colorScheme='blue' onClick={logoutHandle}>
                        အကောင့်ထွက်မည်
                    </Button>
                    <Spacer />
                    <Button w='170px' variant='outline' colorScheme='green' onClick={onClose}>
                        ဆက်အသုံးပြုမည်
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
            <IdleTimer
                ref = {idleTimerRef}
                timeout={30 * 60 * 1000} // 1hr
                onIdle={onIdle}
                crossTab={true}
            >

            </IdleTimer>

        </div>
    )
}



export default IdleTimerContainer