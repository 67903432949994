import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    Box,
    Stack,
    Select,
    Grid,
    HStack,
    Checkbox,
    InputGroup,
    InputLeftAddon,
    Input,
    Button,
    Text,
    Spacer,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    Center,
  } from '@chakra-ui/react'
  import { MdSave } from 'react-icons/md'
  import { ADDRESS_CREATE_RESET } from '../constants/addressConstants'
  import { createAddress } from '../actions/addressActions'
  import { getCountryList } from '../actions/countryActions' 
import Error from '../components/Error'

const AddressAddByTypingScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [nickname, setNickname] = useState('')
    const [country, setCountry] = useState('')
    const [countrycode, setCountrycode] = useState('')
    const [phonecode, setPhonecode] = useState('')
    const [address, setAddress] = useState('')
    const [address2, setAddress2] = useState('')
    const [address3, setAddress3] = useState('')
    const [postalcode, setPostalcode] = useState('')
    const [city, setCity] = useState('')
    const [stateProvince, setStateProvince] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [typeCode, setTypeCode] = useState(false)
    const [isError, setIsError] = useState([])
    const [nameInvalid, setNameInvalid] = useState(false)
    const {loading: createLoading, error: createError, success: createSuccess} = useSelector(state=>state.address.Create)
    const {loading: loadingCountry, countries, success:successCountry, error:errorCountry} = useSelector(state=>state.country.List)
    const { names } = useSelector(state=>state.rejectName.List)
    useEffect(()=>{
        dispatch(getCountryList())
    },[dispatch])
    useEffect(()=>{
        if(createSuccess){
            navigate('/address')
        }
        return ()=>{
            dispatch({type: ADDRESS_CREATE_RESET}) 
        }
    },[dispatch, navigate, createSuccess])


    const handleCountrySelect = (i) => {
        if(i){
            setCountry(countries[i].name)
            setPhonecode(countries[i].phoneCode)
            setCountrycode(countries[i].countryCode)
            return
        }
        setCountry('')
        setPhonecode('')
        setCountrycode('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const newAddress = {
            name,
            company,
            nickname,            
            country,
            countrycode,
            phonecode,
            phone,
            address,
            address2,
            address3,
            postalcode,
            city,
            stateProvince,
            email,
            typeCode: typeCode === true ? 'from' : 'to',
        }
        //console.log(newAddress)
        dispatch(createAddress(newAddress))
    }

    const handleCheckNames = () => {
        if(name === ''){
            return
        }
        const index = names.findIndex(n => n.name === name)
        if(index !== -1){
            setNameInvalid(true)
        }
    }

    return (
    <Box w='900px' mx='auto' >
        {(createLoading || loadingCountry) && <Spinner label='Please wait' speed='0.1s' thickness='5px' /> }
        <HStack m='3' px='3'>
            <Text fontSize='2xl'>Add New Address</Text>
            <Spacer />
            <Checkbox onChange={()=>setTypeCode(!typeCode)}>From Address</Checkbox>
            <Spacer />
        </HStack>
        <Stack spacing={4}>

            {createError && <Error error={createError} />}
            {errorCountry && <Error error={errorCountry} />}

            <InputGroup size='sm'>                        
                <InputLeftAddon children='Name *' w='20%' minW='150px' />
                <Input 
                    type='text' 
                    value={name} 
                    onChange={e=>{
                        setName(e.target.value)
                        setNameInvalid(false)
                    }}
                    isInvalid={isError.indexOf('name') !== -1}
                    onKeyPress={()=>setIsError(isError.filter(x => x !== 'name'))}
                    onBlur={handleCheckNames}
                    maxLength={45}
                />                        
            </InputGroup>
            {nameInvalid && 
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>!!! Name is in rejected list !!!</AlertTitle>
                </Alert>
            }

            <InputGroup size='sm'>                        
                <InputLeftAddon children='Company *' w='20%' minW='150px' />
                <Input 
                    type='text' 
                    value={company} 
                    onChange={e=>setCompany(e.target.value)} 
                    isInvalid={isError.indexOf('company') !== -1}
                    onKeyPress={()=>setIsError(isError.filter(x => x !== 'company'))}
                    maxLength={60}
                />                        
            </InputGroup>

            <InputGroup size='sm'>                        
                <InputLeftAddon children='Nickname' w='20%' minW='150px' />
                <Input 
                    type='text' 
                    value={nickname} 
                    onChange={e=>setNickname(e.target.value)} 
                    maxLength={40}
                />                        
            </InputGroup>

            {successCountry &&
            <InputGroup size='sm'>                        
                <InputLeftAddon children='Country *' w='20%' minW='150px' />
                <Select 
                    placeholder='Select' 
                    onChange={e=> {
                        handleCountrySelect(e.target.value);
                        setIsError(isError.filter(x => x !== 'country'))
                    }}
                    isInvalid={isError.indexOf('country') !== -1}
                >
                    {countries.map((ctry,idx)=> (
                        <option key={idx} value={idx}>{ctry.name} - Code:{ctry.countryCode}, Phone:{ctry.phoneCode}, Zone:{ctry.zone}</option>
                        // <option key={idx} value={JSON.stringify(ctry)}>{ctry.cName}</option>
                    ))}
                </Select>                       
            </InputGroup>
            }

            <InputGroup size='sm'>                        
                <InputLeftAddon children='Address *' w='20%' minW='150px' />
                <Input 
                    type='text' 
                    value={address} 
                    onChange={e=>setAddress(e.target.value)} 
                    isInvalid={isError.indexOf('address') !== -1}
                    onKeyPress={()=>setIsError(isError.filter(x => x !== 'address'))}
                    maxLength={45}
                />                        
            </InputGroup>

            <InputGroup size='sm'>                        
                <InputLeftAddon children='Address 2' w='20%' minW='150px' />
                <Input type='text' value={address2} onChange={e=>setAddress2(e.target.value)} maxLength={45} />                        
            </InputGroup>

            <InputGroup size='sm'>                        
                <InputLeftAddon children='Address 3' w='20%' minW='150px' />
                <Input type='text' value={address3} onChange={e=>setAddress3(e.target.value)} maxLength={45} />                        
            </InputGroup>

            <Grid templateColumns='repeat(3, 1fr)' gap={4}>
                <InputGroup size='sm'>                        
                    <InputLeftAddon children='Postal Code' />
                    <Input 
                        type='text' 
                        value={postalcode} 
                        onChange={e=>setPostalcode(e.target.value)}  
                        isInvalid={isError.indexOf('postalcode') !== -1}
                        onKeyPress={()=>setIsError(isError.filter(x => x !== 'postalcode'))}
                        maxLength={12}
                    />                      
                </InputGroup>
                <InputGroup size='sm'>                        
                    <InputLeftAddon children='City' />
                    <Input 
                        type='text' 
                        value={city} 
                        onChange={e=>setCity(e.target.value)} 
                        isInvalid={isError.indexOf('city') !== -1}
                        onKeyPress={()=>setIsError(isError.filter(x => x !== 'city'))}
                        maxLength={35}
                    />  
                    {/* <Select 
                        onChange={e=> {setCity(e.target.value);setIsError(isError.filter(x => x !== 'city'))}}
                        isInvalid={isError.indexOf('city') !== -1}
                    >
                        {cityList.map((city,idx)=> (
                            <option key={idx} value={city.cityName}>{city.cityName}</option>
                            // <option key={idx} value={JSON.stringify(ctry)}>{ctry.cName}</option>
                        ))}
                    </Select>                         */}
                </InputGroup>
                <InputGroup size='sm'>                        
                    <InputLeftAddon children='State' />
                    <Input type='text' value={stateProvince} onChange={e=>setStateProvince(e.target.value)} maxLength={35} />                        
                </InputGroup>
            </Grid>

            <HStack>
                <Box w='40%'>
                    <InputGroup size='sm' mr='2' minW='100%'>                        
                        <InputLeftAddon children='Email' />
                        <Input type='email' value={email} onChange={e=>setEmail(e.target.value)} maxLength={50} />                        
                    </InputGroup>
                </Box>
                <Box w='20%'>
                    <InputGroup size='sm' mr='2' minW='100%'>                        
                        <InputLeftAddon children='Code' />
                        <Input type='text' value={phonecode} readOnly/>                      
                    </InputGroup>
                </Box>
                <Box w='40%'>
                    <InputGroup size='sm' minW='100%' >                        
                        <InputLeftAddon children='Phone'/>
                        <Input 
                            type='tel' 
                            value={phone} 
                            onChange={e=>setPhone(e.target.value)}
                            isInvalid={isError.indexOf('phone') !== -1} 
                            onKeyDown={()=>setIsError(isError.filter(x => x !== 'phone'))}
                            onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault()}}} 
                            maxLength={25}
                        />                        
                    </InputGroup>
                </Box>
            </HStack>
        </Stack>
        <Box h='100px'></Box>
        <Center>
            <Button 
                colorScheme='green' 
                w='200px' 
                size='sm' 
                leftIcon={<MdSave />} 
                onClick={handleSubmit}>Save
            </Button>
        </Center>
    </Box>
    )
}

export default AddressAddByTypingScreen