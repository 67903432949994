import React, {useState, useEffect, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Box,
    Spinner,
    HStack,
} from '@chakra-ui/react'
import Error from '../components/Error'
import { MdDragIndicator } from 'react-icons/md'
import { sortItems } from '../actions/shipmentActions'

const ItemSortingModal = ({preshipment}) => {
    const dispatch = useDispatch()
    const dragItem = useRef(null)
    const dragOverItem = useRef(null)
    const [show, setShow] = useState(false)
    const [itemList, setItemList] = useState(preshipment.items)
    const {loading, error, success} = useSelector(state=>state.preshipment.ItemSorting)

    useEffect(()=>{
        if(success){
            setShow(false)
        }
    },[success, dispatch])

    const handleClose = () => {
        
        setShow(false)
    }

    const handleSubmit = () => {
        var newItemList = []
        itemList.forEach(itm => {
            const toPush = {
                item: itm.item._id,
                qty: itm.qty,
                weight: itm.weight,
                price: itm.price,
            }
            if(itm.note){
                toPush['note']=itm.note
            }
            newItemList.push(toPush)
        })
        const sortData = {
            preId: preshipment._id,
            newItemList
        }
        dispatch(sortItems(sortData))
    }

    const onDragEnd = () => {
        let newList = [...itemList]

        const draggedItemContent = newList.splice(dragItem.current, 1)[0]
        newList.splice(dragOverItem.current,0 ,draggedItemContent)
        dragItem.current = null
        dragOverItem.current = null

        setItemList(newList)
    }


    return (
        <>
            <Button size='sm' w='100px' colorScheme={'blue'} onClick={()=>setShow(true)} >Sort Items</Button>

            <Modal isOpen={show} onClose={handleClose} size='3xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Item Sorting</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Box>
                        {error && <Error error={error} /> }
                    </Box>
                      
                        
                        {itemList.map((itm, i) => (
                            <Box 
                                key={i} 
                                draggable 
                                my='1' 
                                p='2' 
                                border='1px' 
                                borderRadius={10} 
                                bg='blue.100'
                                className='dragbox'
                                onDragStart={(e)=>dragItem.current=i}
                                onDragEnter={(e)=>dragOverItem.current=i}
                                onDragEnd={()=>onDragEnd()}
                                onDragOver={e=>e.preventDefault()}
                                >
                                <HStack>
                                    <MdDragIndicator /> 
                                    <Box>{i+1} .</Box>
                                    <Box>{itm.item.name} </Box>  
                                </HStack>
                            </Box>
                        ))}

                    
                </ModalBody>

                <ModalFooter>
                    <Button size='sm' w='150px' colorScheme={'green'} onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default ItemSortingModal