import {
    CLEAR_DATA_REQUEST,
    CLEAR_DATA_SUCCESS,
    CLEAR_DATA_FAIL,
    CLEAR_DATA_RESET,
    BALANCE_LOG_REQUEST,
    BALANCE_LOG_SUCCESS,
    BALANCE_LOG_FAIL,
    BALANCE_LOG_RESET,
    USER_LOG_REQUEST,
    USER_LOG_SUCCESS,
    USER_LOG_FAIL,
    USER_LOG_RESET,
    GET_DATA_REQUEST,
    GET_DATA_SUCCESS,
    GET_DATA_FAIL,
    GET_DATA_RESET,
    REMOTE_AREA_CHECK_REQUEST,
    REMOTE_AREA_CHECK_SUCCESS,
    REMOTE_AREA_CHECK_FAIL,
    REMOTE_AREA_CHECK_RESET,
    SET_REMOTE_RATE_REQUEST,
    SET_REMOTE_RATE_SUCCESS,
    SET_REMOTE_RATE_FAIL,
    SET_REMOTE_RATE_RESET,
    GET_REMOTE_RATE_REQUEST,
    GET_REMOTE_RATE_SUCCESS,
    GET_REMOTE_RATE_FAIL,
    GET_REMOTE_RATE_RESET,

} from '../constants/systemConstants'


export const clearDataReducer = (state={}, action) => {
    switch(action.type){
        case CLEAR_DATA_REQUEST:
            return {loading: true}
        case CLEAR_DATA_SUCCESS:
            return {
                loading: false, 
                success: true
            }
        case CLEAR_DATA_FAIL:
            return {loading: false, error: action.payload}
        case CLEAR_DATA_RESET:
            return {}
        default: return state
    }
}

export const balanceLogReducer = (state={logs:[]}, action) => {
    switch(action.type){
        case BALANCE_LOG_REQUEST:
            return {loading: true}
        case BALANCE_LOG_SUCCESS:
            return {
                loading: false, 
                success: true,
                logs: action.payload
            }
        case BALANCE_LOG_FAIL:
            return {loading: false, error: action.payload}
        case BALANCE_LOG_RESET:
            return {}
        default: return state
    }
}

export const userLogReducer = (state={logs:[]}, action) => {
    switch(action.type){
        case USER_LOG_REQUEST:
            return {loading: true}
        case USER_LOG_SUCCESS:
            return {
                loading: false, 
                success: true,
                logs: action.payload
            }
        case USER_LOG_FAIL:
            return {loading: false, error: action.payload}
        case USER_LOG_RESET:
            return {}
        default: return state
    }
}

export const getDataReducer = (state={data:[]}, action) => {
    switch(action.type){
        case GET_DATA_REQUEST:
            return {loading: true}
        case GET_DATA_SUCCESS:
            return {
                loading: false, 
                success: true,
                data: action.payload
            }
        case GET_DATA_FAIL:
            return {loading: false, error: action.payload}
        case GET_DATA_RESET:
            return {}
        default: return state
    }
}

export const usRemoteAreaReducer = (state={}, action) => {
    switch(action.type){
        case REMOTE_AREA_CHECK_REQUEST:
            return {loading: true}
        case REMOTE_AREA_CHECK_SUCCESS:
            return {
                loading: false, 
                success: true,
                isRemote: action.payload
            }
        case REMOTE_AREA_CHECK_FAIL:
            return {loading: false, error: action.payload}
        case REMOTE_AREA_CHECK_RESET:
            return {}
        default: return state
    }
}

export const setRemoteRateReducer = (state={}, action) => {
    switch(action.type){
        case SET_REMOTE_RATE_REQUEST:
            return {loading: true}
        case SET_REMOTE_RATE_SUCCESS:
            return {
                loading: false, 
                success: true,
            }
        case SET_REMOTE_RATE_FAIL:
            return {loading: false, error: action.payload}
        case SET_REMOTE_RATE_RESET:
            return {}
        default: return state
    }
}

export const getRemoteRateReducer = (state={}, action) => {
    switch(action.type){
        case GET_REMOTE_RATE_REQUEST:
            return {loading: true}
        case GET_REMOTE_RATE_SUCCESS:
            return {
                loading: false, 
                success: true,
                remoterate: action.payload
            }
        case GET_REMOTE_RATE_FAIL:
            return {loading: false, error: action.payload}
        case GET_REMOTE_RATE_RESET:
            return {}
        default: return state
    }
}