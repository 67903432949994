import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, Link } from 'react-router-dom'

import {
    Box,
    Text,
    Stack,
    HStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spacer,
    Button,
    SimpleGrid,
    GridItem,
    useBreakpointValue,
    OrderedList,
    ListItem,
} from '@chakra-ui/react'
import { getDomesticShipment, deleteDomesticShipment, updateChargesDomesticShipment } from '../../actions/domestic_actions/domesticShipmentActions'
import { MdCancel, MdCheckCircle, MdEditNote } from 'react-icons/md'
import { Error, ConfirmDeleteModal, MySpinner } from '../../components'
import { DOMESTICSHIPMENT_DELETE_RESET } from '../../constants/domestic_constants/domesticShipmentConstants'
import { AddChargesModal, DomesticShipmentUpdateModal} from '../../Modals'
import LabelModal from './LabelModal'

const DomesticShipmentDetailScreen = () => {
    const colSpan = useBreakpointValue({base: 2, md: 1})
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {user} = useSelector(state=> state.login)
    const {loading, error, shipment} = useSelector(state=>state.domestic.ShipmentById)
    const {loading: loadingCancel, error: errorCancel, success: successCancel} = useSelector(state=>state.domestic.ShipmentDelete)
    // const {success: successCharges} = useSelector(state=>state.domestic.ShipmentCharges)
    // const {success: successCost} = useSelector(state=>state.domestic.ShipmentUpdateCost)
    const {success: successUpdate} = useSelector(state=>state.domestic.ShipmentUpdate)
    useEffect(()=>{
        if(successCancel){
            navigate('/domesticshipment')            
        }
    },[navigate, successCancel])
    useEffect(()=>{        
        dispatch(getDomesticShipment(params.id))
        return()=>{
            dispatch({type: DOMESTICSHIPMENT_DELETE_RESET})
            // dispatch({type: DOMESTICSHIPMENT_BYID_RESET})
        }
    },[dispatch, params, successUpdate])

    const handleRemoveCharge = (name, amount) => {
        const detail = {
            add: false,
            shipmentId: params.id,
            name,
            amount,
        }
        //console.log(detail)
        dispatch(updateChargesDomesticShipment(detail))
    }


    return (
        <Box my='4'>
            <HStack my='3'>
                <Box>
                    {(loading || loadingCancel)  && <MySpinner />}
                </Box>
                <Spacer />
                {shipment &&  
                <>
                    {user && user.isAdmin && <Box>
                        <DomesticShipmentUpdateModal 
                            shipmentId={shipment._id}
                            carr={shipment.carrier}
                            totalWeight={shipment.totalWeight}
                            totalAmount={shipment.totalAmount-shipment.charges.reduce((acc, ch) => acc + ch.amount, 0)}
                            awb={shipment.awb}
                            agent={shipment.agent}
                            sale={shipment.sale}
                            oldCharges={shipment.charges}
                            isPaid={shipment.isPaid}
                        />
                    </Box>}                
                    <Link to={`/predomestic/${shipment.preId}`}>
                        <Button
                            size='sm' w='100px' colorScheme='yellow'
                        >Go to Pre</Button>
                    </Link>  
                </>                              
                }
                {user && user.isAdmin && 
                    <ConfirmDeleteModal disable={shipment && shipment.isPaid} deleteFunction={deleteDomesticShipment} id={params.id} width='100px' />
                }                
            </HStack>


            <Box>
            {error && <Error error={error} /> }
            {errorCancel && <Error error={errorCancel} />}
            </Box>

            {shipment && 
                <Box boxShadow='xl' my='5' p='3'>

                    <Box bg='red.100' p='3' borderRadius={10} h='full' my='3'>   
                        <HStack fontSize={'2xl'} fontWeight={'700'} mb='3' borderBottom='1px' justifyContent={'space-between'}>                           
                            <Text >Shipment Details</Text>   
                            <Text >{shipment.awb}</Text>       
                        </HStack>                              
                        <Stack spacing={'0'} fontSize='sm' fontWeight={700}>
                            <HStack>
                                <Box>
                                    <Text>Pre - {new Date(shipment.preDate).toDateString()}</Text>
                                    <Text>Ship - {new Date(shipment.createdAt).toDateString()}</Text> 
                                    {shipment.isScanned && <Text>Ship - {new Date(shipment.scannedDate).toDateString()}</Text> }
                                </Box>
                                <Spacer />
                                <Box>
                                    <img style={{height: '40px', objectFit: 'contain'}} src={`/${shipment.carrier}.png`} alt='carrier-logo' />
                                </Box>
                            </HStack>
                                                       
                        </Stack>
                    </Box>

                    <SimpleGrid columns={2} columnGap={3} rowGap={3} w='full' mb='3'>
                        <GridItem colSpan={colSpan}>
                            <Box bg='red.100' p='3' borderRadius={10} h='full'>                                
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>From</Text>                                    
                                <Stack spacing={'0'}>
                                    <Text fontWeight={'400'}>{shipment.sender.name}</Text>                      
                                    <Text>{shipment.sender.address.address}, {shipment.sender.address.city}</Text>                        
                                    <Text>{shipment.sender.address.province}, {shipment.sender.address.postalCode}</Text>                                
                                    <Text>{shipment.sender.tel}</Text>
                                    <Text>{shipment.sender.email}</Text>
                                </Stack>
                            </Box>
                        </GridItem>

                        <GridItem colSpan={colSpan}>                            
                            <Box bg='red.100' p='3' borderRadius={10} h='full'>
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>To</Text>
                                <Stack spacing={'0'}>
                                    <Text fontWeight={'400'}>{shipment.receiver.name}</Text>                      
                                    <Text>{shipment.receiver.address.address} {shipment.receiver.address.address2 && ', '+shipment.receiver.address.address2}</Text>
                                    {shipment.receiver.address.address3 && <Text>{shipment.receiver.address.address3}</Text>}
                                    <Text>{shipment.receiver.address.city}, {shipment.receiver.address.province}, {shipment.receiver.address.postalCode}</Text>                                
                                    <Text>{shipment.receiver.tel}</Text>
                                    <Text>{shipment.receiver.email}</Text>
                                </Stack>
                            </Box>
                        </GridItem>
                    </SimpleGrid>


                    <SimpleGrid columns={2} columnGap={3} rowGap={3} w='full' mb='3'>
                        <GridItem colSpan={colSpan}>
                            <Box bg='red.100' p='3' borderRadius={10} h='full'>                                
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>Packages</Text>                                   
                                <Table size='xs' fontSize={'sm'}>
                                    <Thead>
                                        <Tr>
                                            <Th>No</Th>
                                            <Th>Dimension (cm)</Th>
                                            <Th>Weight (kg)</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {shipment.boxes.map((p, i) => (
                                            <Tr  key={i+1}>
                                                <Td>{i+1}</Td>
                                                <Td>{p.dimensions.length} x {p.dimensions.width} x {p.dimensions.height}</Td>
                                                <Td>{p.weight}</Td>
                                            </Tr>                                            
                                        ))}
                                    </Tbody>
                                </Table>                               
                            </Box>
                        </GridItem>

                        <GridItem colSpan={colSpan}>                            
                            <Box bg='red.100' p='3' borderRadius={10} h='full'>
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>Items</Text>
                                                               
                                <Table size='xs' fontSize={'sm'}>
                                    <Thead>
                                        <Tr>
                                            <Th>No</Th>
                                            <Th>Name</Th>
                                            <Th>Qty</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {shipment && 
                                        shipment.items.map((itm, idx) => (
                                            <Tr  key={idx}>
                                                <Td>{idx +1}</Td>
                                                <Td>{itm.item.name}</Td>
                                                <Td>{itm.qty}</Td>
                                            </Tr>                                            
                                        ))}
                                    </Tbody>
                                </Table>
                            </Box>
                        </GridItem>
                    </SimpleGrid>


                    <SimpleGrid columns={2} columnGap={3} rowGap={3} w='full' mb='3'>
                        <GridItem colSpan={colSpan}>
                            <Box bg='red.100' p='3' borderRadius={10} h='full'>                                
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>Post</Text>                                    
                                <Stack spacing={'0'}>
                                    <HStack>
                                        <Text w='150px'>Shipment Type</Text>
                                        <Text>{shipment.productCode === 'D' ? 'Document' : 'Package'}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Total Weight</Text>
                                        <Text>{shipment.totalWeight} kg</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Total Box</Text>
                                        <Text>{'1'}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Invoice</Text>
                                        <Text>{shipment.dhlInvoiceNo}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Service</Text>
                                        <Text>{shipment.service}</Text>
                                    </HStack>
                                </Stack>
                            </Box>
                        </GridItem>

                        <GridItem colSpan={colSpan}>                            
                            <Box bg='red.100' p='3' borderRadius={10} h='full'>
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>SM</Text>
                                <Stack spacing={'0'}>
                                    <HStack>
                                        <Text w='150px'>Pre Code</Text>
                                        <Text>{shipment.preCode}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Agent</Text>
                                        <HStack>
                                            <Text mr='3'>{shipment.agent.name}</Text>

                                            {/* <ShipmentChangeAgentModal disable={shipment.isPaid || shipment.isInvoiced} shipmentId={shipment._id} />
                                            {shipment && (shipment.isPaid || shipment.isInvoiced) && 
                                                <Popover>
                                                    <PopoverTrigger>
                                                    <IconButton
                                                        variant='ghost'
                                                        colorScheme='red'
                                                        aria-label='Call Sage'
                                                        fontSize='20px'
                                                        icon={<MdWarning />}
                                                    />
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverArrow />
                                                        <PopoverCloseButton />
                                                        <PopoverHeader>Disabled</PopoverHeader>
                                                        <PopoverBody>Changing Agent is disabled if the shipment is invoiced or paid. please remove the shipment from invoice to change agent.</PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                            } */}
                                        </HStack>                                        
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Sale</Text>
                                        <Text>{shipment.sale.name}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Created by</Text>
                                        <Text>{shipment.user.name}</Text>
                                    </HStack>
                                </Stack>
                            </Box>
                        </GridItem>
                    </SimpleGrid>
                    


                    <SimpleGrid columns={2} columnGap={3} rowGap={3} w='full' mb='3'>
                        <GridItem colSpan={colSpan}>
                            <Box bg='red.100' p='3' borderRadius={10} h='full'>   
                                <HStack borderBottom='1px' mb='3'>
                                    <Text fontSize={'2xl'}  fontWeight={'700'} >Billing</Text> 
                                    <Spacer />
                                    
                                    
                                    <Button
                                        size='xs'
                                        h='20px'
                                        variant={'ghost'}
                                        colorScheme={'black'}
                                    >
                                        <MdEditNote size={25} />
                                    </Button>
                                </HStack>                             
                                                                 
                                <Stack spacing={'0'}>
                                    <HStack>
                                        <Text w='150px'>Invoice No</Text>
                                        <HStack>
                                            <Text>{shipment.isInvoiced ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Text>
                                            <Box
                                                className='clickableLink' 
                                                onClick={()=>navigate(`/domesticinvoice/${shipment.invoiceId._id}`)}
                                            >
                                            {shipment.smInvoiceNo}
                                            </Box>
                                        </HStack>
                                    </HStack>                                       
                                    
                                  
                                    <HStack>
                                        <Text w='150px'>Paid</Text>
                                        <Text>{shipment.isPaid ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Text>
                                    </HStack>

                                    {shipment.charges.length !== 0 ?
                                    <HStack>
                                    <HStack mb='3' w='150px' >  
                                        <Text >Charges </Text>  
                                        {user && user.isAdmin && <AddChargesModal disabled={shipment.isPaid} />}
                                        {user && (user._id === shipment.user._id) && <AddChargesModal disabled={shipment.isPaid} />} 
                                    </HStack>  
                                    <OrderedList pl='30px' pr='2' py='1' my='1' fontSize={'sm'} border='1px solid grey' borderRadius={10}>
                                        {shipment.charges.map((ch, idx)=> (
                                            <ListItem key={idx}>
                                                <HStack>
                                                    <Box>{ch.name} - </Box>
                                                    <Box mr='2'>{ch.amount}</Box>
                                                    {user && user.isAdmin &&                                                
                                                    <Button 
                                                        size='xs'
                                                        h='20px'
                                                        colorScheme={'red'}
                                                        variant={'outline'}
                                                        disabled={shipment.isPaid}
                                                        onClick={()=>handleRemoveCharge(ch.name, ch.amount)}
                                                    >x</Button>
                                                    }
                                                </HStack>
                                            </ListItem>
                                        ))}
                                    </OrderedList>                                                                        
                                </HStack>
                                    :
                                    <HStack>  
                                        <Text w='150px' >Charges </Text>  
                                        <Text>No Charges </Text>  
                                        {/* {user && user.isAdmin && <AddChargesModal disabled={shipment.isPaid} />}                                    
                                        {user && (user._id === shipment.user._id) && <AddChargesModal disabled={shipment.isPaid} />}  */}
                                    </HStack>
                                    }
                                    <HStack>
                                        <Text w='150px'>Charges Total</Text>
                                        <Text>{shipment.charges.reduce((acc, ch) => acc + ch.amount, 0)} thb</Text>
                                    </HStack> 
                                    <HStack>
                                        <Text w='150px'>Shipping Cost</Text>
                                        <Text>{(shipment.totalAmount - shipment.charges.reduce((acc, ch) => acc + ch.amount, 0)).toLocaleString()} thb</Text>
                                        {/* { user && user.isAdmin && <THPShipmentCostUpdateModal disable={shipment.isPaid} shipmentId = {shipment._id} />} */}
                                    </HStack>    
                                    <HStack>
                                        <Text w='150px'>Total Amount</Text>
                                        <Text>{shipment.totalAmount.toLocaleString()} thb</Text>
                                    </HStack>
                                   
                                </Stack>
                            </Box>
                        </GridItem>

                        <GridItem colSpan={colSpan} columnGap={3} rowGap={3}>                            
                            <Box bg='red.100' p='3' borderRadius={10} h='full'>
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>Label</Text>
                                <Stack spacing={'0'}>
                                    {/* <Box bg='yellow' w='70px' border={'1px'} px='4' borderRadius={10}>
                                        <a href={`${shipment.fileUrl}`} target="_blank" rel="noopener noreferrer">
                                            View
                                        </a>
                                    </Box> */}
                                    <LabelModal />
                                </Stack>
                            </Box>
                        </GridItem>
                    </SimpleGrid>
                  
                </Box>
            }

        </Box>
    )
}

export default DomesticShipmentDetailScreen