export const ADMIN_DASHBOARD_REQUEST = 'ADMIN_DASHBOARD_REQUEST'
export const ADMIN_DASHBOARD_SUCCESS = 'ADMIN_DASHBOARD_SUCCESS'
export const ADMIN_DASHBOARD_FAIL = 'ADMIN_DASHBOARD_FAIL'
export const ADMIN_DASHBOARD_RESET = 'ADMIN_DASHBOARD_RESET'

export const LIST_DASHBOARD_REQUEST = 'LIST_DASHBOARD_REQUEST'
export const LIST_DASHBOARD_SUCCESS = 'LIST_DASHBOARD_SUCCESS'
export const LIST_DASHBOARD_FAIL = 'LIST_DASHBOARD_FAIL'
export const LIST_DASHBOARD_RESET = 'LIST_DASHBOARD_RESET'

export const DELETE_DASHBOARD_REQUEST = 'DELETE_DASHBOARD_REQUEST'
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS'
export const DELETE_DASHBOARD_FAIL = 'DELETE_DASHBOARD_FAIL'
export const DELETE_DASHBOARD_RESET = 'DELETE_DASHBOARD_RESET'

export const SALE_DASHBOARD_REQUEST = 'SALE_DASHBOARD_REQUEST'
export const SALE_DASHBOARD_SUCCESS = 'SALE_DASHBOARD_SUCCESS'
export const SALE_DASHBOARD_FAIL = 'SALE_DASHBOARD_FAIL'
export const SALE_DASHBOARD_RESET = 'SALE_DASHBOARD_RESET'

export const AGENT_DASHBOARD_REQUEST = 'AGENT_DASHBOARD_REQUEST'
export const AGENT_DASHBOARD_SUCCESS = 'AGENT_DASHBOARD_SUCCESS'
export const AGENT_DASHBOARD_FAIL = 'AGENT_DASHBOARD_FAIL'
export const AGENT_DASHBOARD_RESET = 'AGENT_DASHBOARD_RESET'

export const SALE_SHIPPING_CHART_REQUEST = 'SALE_SHIPPING_CHART_REQUEST'
export const SALE_SHIPPING_CHART_SUCCESS = 'SALE_SHIPPING_CHART_SUCCESS'
export const SALE_SHIPPING_CHART_FAIL = 'SALE_SHIPPING_CHART_FAIL'
export const SALE_SHIPPING_CHART_RESET = 'SALE_SHIPPING_CHART_RESET'

export const AGENT_SHIPPING_CHART_REQUEST = 'AGENT_SHIPPING_CHART_REQUEST'
export const AGENT_SHIPPING_CHART_SUCCESS = 'AGENT_SHIPPING_CHART_SUCCESS'
export const AGENT_SHIPPING_CHART_FAIL = 'AGENT_SHIPPING_CHART_FAIL'
export const AGENT_SHIPPING_CHART_RESET = 'AGENT_SHIPPING_CHART_RESET'