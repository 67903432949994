import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    Box,
    Grid,
    Button,
    Center,
    Stack,
    // HStack,
    // Text,
    // Spacer,
    // GridItem,
} from '@chakra-ui/react'
import printJS from 'print-js'
//import { MdLocalPrintshop } from 'react-icons/md'
//import ReactToPrint from 'react-to-print'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { SHIPMENT_CREATE_RESET } from '../constants/shipmentConstants'

const ShipmentCreateSuccessScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    //const invoiceRef = useRef()
    const {success, shipment} = useSelector(state=>state.shipment.Create)

    // const invoiceStyle = `
    // @page {
    //     size: A4;
    //     margin: 5mm 5mm;
    // }
    // html, body {
    //     font-size: 12px;
    //     font-weight: 400;
    // }
    // .description {
    //   font-size: 10px !important;
    // }
    // `;

    useEffect(()=>{
        if(!success || success === undefined ){
            navigate('/shipment')
        }
        return()=>{
            dispatch({type: SHIPMENT_CREATE_RESET})
        }
    },[success, dispatch, navigate])

    const base64toBlob = (data) => {

        const bytes = atob(data)
        let length = bytes.length
        let out = new Uint8Array(length)

        while (length--) {
            out[length] = bytes.charCodeAt(length)
        }

        return new Blob([out], { type: 'application/pdf' })
    }

    const printBase64 = (base64Data) => {
        printJS({
            printable: base64Data,
            type: 'pdf',
            base64: true
          });
    }
    
    return (
        <Box>
            <Grid
                templateColumns='repeat(2, 1fr)'
                gap={4}
                >
                {shipment &&
                <>
                    <Box p='5%'>
                    <Center>
                        <Button colorScheme={'green'} onClick={()=>printBase64(shipment.documents[0].content)}>
                            Print Label
                        </Button>
                    </Center>
                    <Box w='50%' h='300px' mx='auto' mt='4'>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js"> */}
                            <Viewer fileUrl={URL.createObjectURL(base64toBlob(shipment.documents[0].content))} />
                        </Worker>
                    </Box>
                    </Box>
                    <Box p='5%'>
                        <Center>
                            <Stack direction='row'>
                                {/* <Button colorScheme={'green'} onClick={()=>printBase64(shipment.documents[1].content)}>
                                    Print Invoice
                                </Button> */}
                                <Link to={`/preshipment/${shipment.preId}`}>
                                    <Button colorScheme={'yellow'}>Print Invoice in PreShip</Button>
                                </Link>
                            </Stack>
                        </Center>
                        {/* {shipment.productCode === "P" && 
                            <Box w='50%' mx='auto' mt='4'>
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                                    <Viewer fileUrl={URL.createObjectURL(base64toBlob(shipment.documents[1].content))} />
                                </Worker>
                            </Box>
                        } */}
                    </Box>
                </>
                }
            </Grid>
            <Box w='100%' border='1px'></Box>
        </Box>
    )
}

export default ShipmentCreateSuccessScreen