export const RATE_CREATE_REQUEST = 'RATE_CREATE_REQUEST'
export const RATE_CREATE_SUCCESS = 'RATE_CREATE_SUCCESS'
export const RATE_CREATE_FAIL = 'RATE_CREATE_FAIL'
export const RATE_CREATE_RESET = 'RATE_CREATE_RESET'

export const RATE_LIST_REQUEST = 'RATE_LIST_REQUEST'
export const RATE_LIST_SUCCESS = 'RATE_LIST_SUCCESS'
export const RATE_LIST_FAIL = 'RATE_LIST_FAIL'
export const RATE_LIST_RESET = 'RATE_LIST_RESET'

export const RATE_EDIT_REQUEST = 'RATE_EDIT_REQUEST'
export const RATE_EDIT_SUCCESS = 'RATE_EDIT_SUCCESS'
export const RATE_EDIT_FAIL = 'RATE_EDIT_FAIL'
export const RATE_EDIT_RESET = 'RATE_EDIT_RESET'

export const RATE_DELETE_REQUEST = 'RATE_DELETE_REQUEST'
export const RATE_DELETE_SUCCESS = 'RATE_DELETE_SUCCESS'
export const RATE_DELETE_FAIL = 'RATE_DELETE_FAIL'
export const RATE_DELETE_RESET = 'RATE_DELETE_RESET'

export const RATE_BYID_REQUEST = 'RATE_BYID_REQUEST'
export const RATE_BYID_SUCCESS = 'RATE_BYID_SUCCESS'
export const RATE_BYID_FAIL = 'RATE_BYID_FAIL'
export const RATE_BYID_RESET = 'RATE_BYID_RESET'

export const RATE_BYAGENT_REQUEST = 'RATE_BYAGENT_REQUEST'
export const RATE_BYAGENT_SUCCESS = 'RATE_BYAGENT_SUCCESS'
export const RATE_BYAGENT_FAIL = 'RATE_BYAGENT_FAIL'
export const RATE_BYAGENT_RESET = 'RATE_BYAGENT_RESET'

export const RATE_NAMES_REQUEST = 'RATE_NAMES_REQUEST'
export const RATE_NAMES_SUCCESS = 'RATE_NAMES_SUCCESS'
export const RATE_NAMES_FAIL = 'RATE_NAMES_FAIL'
export const RATE_NAMES_RESET = 'RATE_NAMES_RESET'

export const RATE_MY_REQUEST = 'RATE_MY_REQUEST'
export const RATE_MY_SUCCESS = 'RATE_MY_SUCCESS'
export const RATE_MY_FAIL = 'RATE_MY_FAIL'
export const RATE_MY_RESET = 'RATE_MY_RESET'

export const RATE_AGENTNAMES_REQUEST = 'RATE_AGENTNAMES_REQUEST'
export const RATE_AGENTNAMES_SUCCESS = 'RATE_AGENTNAMES_SUCCESS'
export const RATE_AGENTNAMES_FAIL = 'RATE_AGENTNAMES_FAIL'
export const RATE_AGENTNAMES_RESET = 'RATE_AGENTNAMES_RESET'

export const RATE_TRANSFER_REQUEST = 'RATE_TRANSFER_REQUEST'
export const RATE_TRANSFER_SUCCESS = 'RATE_TRANSFER_SUCCESS'
export const RATE_TRANSFER_FAIL = 'RATE_TRANSFER_FAIL'
export const RATE_TRANSFER_RESET = 'RATE_TRANSFER_RESET'


export const RATE_STATUS_REQUEST = 'RATE_STATUS_REQUEST'
export const RATE_STATUS_SUCCESS = 'RATE_STATUS_SUCCESS'
export const RATE_STATUS_FAIL = 'RATE_STATUS_FAIL'
export const RATE_STATUS_RESET = 'RATE_STATUS_RESET'

export const RATE_SHIPMENTQTY_REQUEST = 'RATE_SHIPMENTQTY_REQUEST'
export const RATE_SHIPMENTQTY_SUCCESS = 'RATE_SHIPMENTQTY_SUCCESS'
export const RATE_SHIPMENTQTY_FAIL = 'RATE_SHIPMENTQTY_FAIL'
export const RATE_SHIPMENTQTY_RESET = 'RATE_SHIPMENTQTY_RESET'

export const RATE_SHIPMENTQTY_ACTIVE = 'RATE_SHIPMENTQTY_ACTIVE'

export const RATE_USER_REQUEST = 'RATE_USER_REQUEST'
export const RATE_USER_SUCCESS = 'RATE_USER_SUCCESS'
export const RATE_USER_FAIL = 'RATE_USER_FAIL'
export const RATE_USER_RESET = 'RATE_USER_RESET'


export const RATE_DISCOUNT_REQUEST = 'RATE_DISCOUNT_REQUEST'
export const RATE_DISCOUNT_SUCCESS = 'RATE_DISCOUNT_SUCCESS'
export const RATE_DISCOUNT_FAIL = 'RATE_DISCOUNT_FAIL'
export const RATE_DISCOUNT_RESET = 'RATE_DISCOUNT_RESET'