import {
    AGENTRATE_CREATE_REQUEST,
    AGENTRATE_CREATE_SUCCESS,
    AGENTRATE_CREATE_FAIL,
    AGENTRATE_CREATE_RESET,
    AGENTRATE_LIST_REQUEST,
    AGENTRATE_LIST_SUCCESS,
    AGENTRATE_LIST_FAIL,
    AGENTRATE_LIST_RESET,
    AGENTRATE_BYID_REQUEST,
    AGENTRATE_BYID_SUCCESS,
    AGENTRATE_BYID_FAIL,
    AGENTRATE_BYID_RESET,
    AGENTRATE_EDIT_REQUEST,
    AGENTRATE_EDIT_SUCCESS,
    AGENTRATE_EDIT_FAIL,
    AGENTRATE_EDIT_RESET,
    AGENTRATE_DELETE_REQUEST,
    AGENTRATE_DELETE_SUCCESS,
    AGENTRATE_DELETE_FAIL,
    AGENTRATE_DELETE_RESET,
    AGENTRATE_NAMES_REQUEST,
    AGENTRATE_NAMES_SUCCESS,
    AGENTRATE_NAMES_FAIL,
    AGENTRATE_NAMES_RESET,
    AGENTRATE_TRANSFER_REQUEST,
    AGENTRATE_TRANSFER_SUCCESS,
    AGENTRATE_TRANSFER_FAIL,
    AGENTRATE_TRANSFER_RESET,
    AGENTRATE_AGENTNAMES_REQUEST,
    AGENTRATE_AGENTNAMES_SUCCESS,
    AGENTRATE_AGENTNAMES_FAIL,
    AGENTRATE_AGENTNAMES_RESET,
} from '../constants/agentRateConstants'

export const agentRateCreateReducer = (state={}, action) => {
    switch(action.type){
        case AGENTRATE_CREATE_REQUEST:
            return {loading: true}
        case AGENTRATE_CREATE_SUCCESS:
            return {loading: false, success: true}
        case AGENTRATE_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case AGENTRATE_CREATE_RESET:
            return {}
        default: return state
    }
}

export const agentRateListReducer = (state={agentrates:[]}, action) => {
    switch(action.type){
        case AGENTRATE_LIST_REQUEST:
            return {loading: true}
        case AGENTRATE_LIST_SUCCESS:
            return {
                loading: false, 
                success: true,
                agentrates: action.payload,
            }
        case AGENTRATE_LIST_FAIL:
            return {loading: false, error: action.payload}
        case AGENTRATE_LIST_RESET:
            return {}
        default: return state
    }
}

export const agentRateNamesReducer = (state={agentratenames:[]}, action) => {
    switch(action.type){
        case AGENTRATE_NAMES_REQUEST:
            return {loading: true}
        case AGENTRATE_NAMES_SUCCESS:
            return {
                loading: false, 
                agentratenames: action.payload,
            }
        case AGENTRATE_NAMES_FAIL:
            return {loading: false, error: action.payload}
        case AGENTRATE_NAMES_RESET:
            return {}
        default: return state
    }
}

export const agentRateEditReducer = (state={}, action) => {
    switch(action.type){
        case AGENTRATE_EDIT_REQUEST:
            return {loading: true}
        case AGENTRATE_EDIT_SUCCESS:
            return {loading: false, success: true}
        case AGENTRATE_EDIT_FAIL:
            return {loading: false, error: action.payload}
        case AGENTRATE_EDIT_RESET:
            return {}
        default: return state
    }
}


export const agentRateDeleteReducer = (state={}, action) => {
    switch(action.type){
        case AGENTRATE_DELETE_REQUEST:
            return {loading: true}
        case AGENTRATE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case AGENTRATE_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case AGENTRATE_DELETE_RESET:
            return {}
        default: return state
    }
}

export const agentRateByIdReducer = (state={}, action) => {
    switch(action.type){
        case AGENTRATE_BYID_REQUEST:
            return {loading: true}
        case AGENTRATE_BYID_SUCCESS:
            return {loading: false, success: true, agentrate: action.payload}
        case AGENTRATE_BYID_FAIL:
            return {loading: false, error: action.payload}
        case AGENTRATE_BYID_RESET:
            return {}
        default: return state
    }
}

export const agentRateTransferReducer = (state={}, action) => {
    switch(action.type){
        case AGENTRATE_TRANSFER_REQUEST:
            return {loading: true}
        case AGENTRATE_TRANSFER_SUCCESS:
            return {loading: false, success: true}
        case AGENTRATE_TRANSFER_FAIL:
            return {loading: false, error: action.payload}
        case AGENTRATE_TRANSFER_RESET:
            return {}
        default: return state
    }
}

export const agentRateAgentNamesReducer = (state={}, action) => {
    switch(action.type){
        case AGENTRATE_AGENTNAMES_REQUEST:
            return {loading: true}
        case AGENTRATE_AGENTNAMES_SUCCESS:
            return {loading: false, agentNames: action.payload}
        case AGENTRATE_AGENTNAMES_FAIL:
            return {loading: false, error: action.payload}
        case AGENTRATE_AGENTNAMES_RESET:
            return {}
        default: return state
    }
}
