export const DOMESTICRATE_CREATE_REQUEST = 'DOMESTICRATE_CREATE_REQUEST'
export const DOMESTICRATE_CREATE_SUCCESS = 'DOMESTICRATE_CREATE_SUCCESS'
export const DOMESTICRATE_CREATE_FAIL = 'DOMESTICRATE_CREATE_FAIL'
export const DOMESTICRATE_CREATE_RESET = 'DOMESTICRATE_CREATE_RESET'

export const DOMESTICRATE_LIST_REQUEST = 'DOMESTICRATE_LIST_REQUEST'
export const DOMESTICRATE_LIST_SUCCESS = 'DOMESTICRATE_LIST_SUCCESS'
export const DOMESTICRATE_LIST_FAIL = 'DOMESTICRATE_LIST_FAIL'
export const DOMESTICRATE_LIST_RESET = 'DOMESTICRATE_LIST_RESET'

export const DOMESTICRATE_BYID_REQUEST = 'DOMESTICRATE_BYID_REQUEST'
export const DOMESTICRATE_BYID_SUCCESS = 'DOMESTICRATE_BYID_SUCCESS'
export const DOMESTICRATE_BYID_FAIL = 'DOMESTICRATE_BYID_FAIL'
export const DOMESTICRATE_BYID_RESET = 'DOMESTICRATE_BYID_RESET'

export const DOMESTICRATE_DELETE_REQUEST = 'DOMESTICRATE_DELETE_REQUEST'
export const DOMESTICRATE_DELETE_SUCCESS = 'DOMESTICRATE_DELETE_SUCCESS'
export const DOMESTICRATE_DELETE_FAIL = 'DOMESTICRATE_DELETE_FAIL'
export const DOMESTICRATE_DELETE_RESET = 'DOMESTICRATE_DELETE_RESET'