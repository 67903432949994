import axios from 'axios'
import { 
    COUNTRY_CREATE_FAIL,
    COUNTRY_CREATE_REQUEST,
    COUNTRY_CREATE_SUCCESS,
    COUNTRY_DELETE_FAIL,
    COUNTRY_DELETE_REQUEST,
    COUNTRY_DELETE_SUCCESS,
    COUNTRY_LIST_FAIL,
    COUNTRY_LIST_REQUEST, 
    COUNTRY_LIST_SUCCESS,
    COUNTRY_UPDATE_FAIL,
    COUNTRY_UPDATE_REQUEST,
    COUNTRY_UPDATE_SUCCESS,


} from '../constants/countryConstants'



export const getCountryList = () => async (dispatch, getState) => {
    try {
        dispatch({type: COUNTRY_LIST_REQUEST})
        // const {login: {user}} = getState()
        // const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/country`)
        dispatch({type: COUNTRY_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: COUNTRY_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addNewCountry = (newCountry) => async (dispatch, getState) => {
    try {
        dispatch({type: COUNTRY_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/country`, newCountry, config)
        dispatch({type: COUNTRY_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: COUNTRY_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateCountry = (countryDetail, countryId) => async (dispatch, getState) => {
    try {
        dispatch({type: COUNTRY_UPDATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/country/${countryId}`, countryDetail, config)
        dispatch({type: COUNTRY_UPDATE_SUCCESS})
    } catch (error) {
        dispatch({
            type: COUNTRY_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const deleteCountry = (countryId) => async (dispatch, getState) => {
    try {
        dispatch({type: COUNTRY_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/country/${countryId}`, config)
        dispatch({type: COUNTRY_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: COUNTRY_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}
