import { 
    PAYMENT_APPROVE_FAIL,
    PAYMENT_APPROVE_REQUEST,
    PAYMENT_APPROVE_RESET,
    PAYMENT_APPROVE_SUCCESS,
    PAYMENT_BYID_FAIL,
    PAYMENT_BYID_REQUEST,
    PAYMENT_BYID_RESET,
    PAYMENT_BYID_SUCCESS,
    PAYMENT_CREATE_FAIL,
    PAYMENT_CREATE_REQUEST,
    PAYMENT_CREATE_RESET,
    PAYMENT_CREATE_SUCCESS,
    PAYMENT_DELETE_FAIL,
    PAYMENT_DELETE_REQUEST,
    PAYMENT_DELETE_RESET,
    PAYMENT_DELETE_SUCCESS,
    PAYMENT_LIST_FAIL,
    PAYMENT_LIST_REQUEST, 
    PAYMENT_LIST_RESET, 
    PAYMENT_LIST_SUCCESS,
    PAYMENT_MY_FAIL,
    PAYMENT_MY_REQUEST,
    PAYMENT_MY_RESET,
    PAYMENT_MY_SUCCESS,
} from '../constants/paymentConstants'

export const paymentListReducer = (state={payments:[]}, action) => {
    switch(action.type){
        case PAYMENT_LIST_REQUEST:
            return {loading: true}
        case PAYMENT_LIST_SUCCESS:
            return {
                loading: false, 
                payments: action.payload.payments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case PAYMENT_LIST_FAIL:
            return {loading: false, error: action.payload}
        case PAYMENT_LIST_RESET:
            return {}
        default: return state
    }
}

export const paymentMyReducer = (state={payments:[]}, action) => {
    switch(action.type){
        case PAYMENT_MY_REQUEST:
            return {loading: true}
        case PAYMENT_MY_SUCCESS:
            return {
                loading: false, 
                payments: action.payload.payments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case PAYMENT_MY_FAIL:
            return {loading: false, error: action.payload}
        case PAYMENT_MY_RESET:
            return {}
        default: return state
    }
}



export const paymentCreateReducer = (state={}, action) => {
    switch(action.type){
        case PAYMENT_CREATE_REQUEST:
            return {loading: true}
        case PAYMENT_CREATE_SUCCESS:
            return {loading: false, success: true}
        case PAYMENT_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case PAYMENT_CREATE_RESET:
            return {}
        default: return state
    }
}

export const paymentByIdReducer = (state={}, action) => {
    switch(action.type){
        case PAYMENT_BYID_REQUEST:
            return {loading: true}
        case PAYMENT_BYID_SUCCESS:
            return {loading: false, payment: action.payload}
        case PAYMENT_BYID_FAIL:
            return {loading: false, error: action.payload}
        case PAYMENT_BYID_RESET:
            return {}
        default: return state
    }
}

export const paymentApproveReducer = (state={}, action) => {
    switch(action.type){
        case PAYMENT_APPROVE_REQUEST:
            return {loading: true}
        case PAYMENT_APPROVE_SUCCESS:
            return {loading: false, success: true}
        case PAYMENT_APPROVE_FAIL:
            return {loading: false, error: action.payload}
        case PAYMENT_APPROVE_RESET:
            return {}
        default: return state
    }
}

export const paymentDeleteReducer = (state={}, action) => {
    switch(action.type){
        case PAYMENT_DELETE_REQUEST:
            return {loading: true}
        case PAYMENT_DELETE_SUCCESS:
            return {loading: false, success: true}
        case PAYMENT_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case PAYMENT_DELETE_RESET:
            return {}
        default: return state
    }
}