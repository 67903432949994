import {
    DOMESTICSHIPMENT_BYID_FAIL,
    DOMESTICSHIPMENT_BYID_REQUEST,
    DOMESTICSHIPMENT_BYID_RESET,
    DOMESTICSHIPMENT_BYID_SUCCESS,
    DOMESTICSHIPMENT_CHARGES_FAIL,
    DOMESTICSHIPMENT_CHARGES_REQUEST,
    DOMESTICSHIPMENT_CHARGES_RESET,
    DOMESTICSHIPMENT_CHARGES_SUCCESS,
    DOMESTICSHIPMENT_CREATE_FAIL,
    DOMESTICSHIPMENT_CREATE_REQUEST, 
    DOMESTICSHIPMENT_CREATE_RESET, 
    DOMESTICSHIPMENT_CREATE_SUCCESS,
    DOMESTICSHIPMENT_DELETE_FAIL,
    DOMESTICSHIPMENT_DELETE_REQUEST,
    DOMESTICSHIPMENT_DELETE_RESET,
    DOMESTICSHIPMENT_DELETE_SUCCESS,
    DOMESTICSHIPMENT_UPDATE_FAIL,
    DOMESTICSHIPMENT_UPDATE_REQUEST,
    DOMESTICSHIPMENT_UPDATE_RESET,
    DOMESTICSHIPMENT_UPDATE_SUCCESS,
    DOMESTICSHIPMENT_GETCOST_FAIL,
    DOMESTICSHIPMENT_GETCOST_REQUEST,
    DOMESTICSHIPMENT_GETCOST_RESET,
    DOMESTICSHIPMENT_GETCOST_SUCCESS,
    DOMESTICSHIPMENT_LIST_FAIL,
    DOMESTICSHIPMENT_LIST_REQUEST,
    DOMESTICSHIPMENT_LIST_RESET,
    DOMESTICSHIPMENT_LIST_SUCCESS,
    DOMESTICSHIPMENT_MYLIST_FAIL,
    DOMESTICSHIPMENT_MYLIST_REQUEST,
    DOMESTICSHIPMENT_MYLIST_RESET,
    DOMESTICSHIPMENT_MYLIST_SUCCESS,
    DOMESTICSHIPMENT_PRESHIPCONTACT_FAIL,
    DOMESTICSHIPMENT_PRESHIPCONTACT_REQUEST,
    DOMESTICSHIPMENT_PRESHIPCONTACT_RESET,
    DOMESTICSHIPMENT_PRESHIPCONTACT_SUCCESS,
    DOMESTICSHIPMENT_UPDATECOST_FAIL,
    DOMESTICSHIPMENT_UPDATECOST_REQUEST,
    DOMESTICSHIPMENT_UPDATECOST_RESET,
    DOMESTICSHIPMENT_UPDATECOST_SUCCESS,
    DOMESTICSHIPMENT_SCANNED_REQUEST,
    DOMESTICSHIPMENT_SCANNED_SUCCESS,
    DOMESTICSHIPMENT_SCANNED_FAIL,
    DOMESTICSHIPMENT_SCANNED_RESET,
    DOMESTICSHIPMENT_GET_SCANNED_REQUEST,
    DOMESTICSHIPMENT_GET_SCANNED_SUCCESS,
    DOMESTICSHIPMENT_GET_SCANNED_FAIL,
    DOMESTICSHIPMENT_GET_SCANNED_RESET,
    DOMESTICSHIPMENT_SHIPANDSCAN_REQUEST,
    DOMESTICSHIPMENT_SHIPANDSCAN_SUCCESS,
    DOMESTICSHIPMENT_SHIPANDSCAN_FAIL,
} from '../../constants/domestic_constants/domesticShipmentConstants'



export const domesticShipmentCreateReducer = (state={shipment:null}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_CREATE_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_CREATE_SUCCESS:
            return {loading: false, success: true, shipment: action.payload}
        case DOMESTICSHIPMENT_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_CREATE_RESET:
            return {success: false}
        default: return state
    }
}

export const domesticShipmentListReducer = (state={shipments: []}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_LIST_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_LIST_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload.shipments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case DOMESTICSHIPMENT_LIST_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_LIST_RESET:
            return {}
        default: return state
    }
}

export const domesticShipmentByIdReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_BYID_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_BYID_SUCCESS:
            return {loading: false, shipment: action.payload}
        case DOMESTICSHIPMENT_BYID_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_BYID_RESET:
            return {}
        default: return state
    }
}


export const domesticShipmentDeleteReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_DELETE_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_DELETE_SUCCESS:
            return {loading: false, success: true}
        case DOMESTICSHIPMENT_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_DELETE_RESET:
            return {success: false}
        default: return state
    }
}

export const domesticShipmentUpdateReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_UPDATE_REQUEST:
            return {loading: true, error: ''}
        case DOMESTICSHIPMENT_UPDATE_SUCCESS:
            return {loading: false, success: true, error: ''}
        case DOMESTICSHIPMENT_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_UPDATE_RESET:
            return {}
        default: return state
    }
}

export const domesticShipmentGetCostReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_GETCOST_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_GETCOST_SUCCESS:
            return {loading: false, cost: action.payload}
        case DOMESTICSHIPMENT_GETCOST_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_GETCOST_RESET:
            return {}
        default: return state
    }
}

export const domesticShipmentMyListReducer = (state={shipments: []}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_MYLIST_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_MYLIST_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload.shipments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case DOMESTICSHIPMENT_MYLIST_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_MYLIST_RESET:
            return {}
        default: return state
    }
}


export const domesticShipmentContactReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_PRESHIPCONTACT_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_PRESHIPCONTACT_SUCCESS:
            return {loading: false, success: true}
        case DOMESTICSHIPMENT_PRESHIPCONTACT_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_PRESHIPCONTACT_RESET:
            return {success: false}
        default: return state
    }
}

export const domesticShipmentChargesReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_CHARGES_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_CHARGES_SUCCESS:
            return {loading: false, success: true}
        case DOMESTICSHIPMENT_CHARGES_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_CHARGES_RESET:
            return {success: false}
        default: return state
    }
}

export const domesticShipmentUpdateCostReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_UPDATECOST_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_UPDATECOST_SUCCESS:
            return {loading: false, success: true}
        case DOMESTICSHIPMENT_UPDATECOST_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_UPDATECOST_RESET:
            return {success: false}
        default: return state
    }
}

export const domesticShipmentScannedReducer = (state={}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_SCANNED_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_SCANNED_SUCCESS:
            return {loading: false, success: true}
        case DOMESTICSHIPMENT_SCANNED_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_SCANNED_RESET:
            return {success: false}
        default: return state
    }
}

export const domesticShipmentScannedListReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_GET_SCANNED_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_GET_SCANNED_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload
            }
        case DOMESTICSHIPMENT_GET_SCANNED_FAIL:
            return {loading: false, error: action.payload}
        case DOMESTICSHIPMENT_GET_SCANNED_RESET:
            return {}
        default: return state
    }
}

export const domesticShipAndScanReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case DOMESTICSHIPMENT_SHIPANDSCAN_REQUEST:
            return {loading: true}
        case DOMESTICSHIPMENT_SHIPANDSCAN_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload,
            }
        case DOMESTICSHIPMENT_SHIPANDSCAN_FAIL:
            return {loading: false, error: action.payload}
        default: return state
    }
}