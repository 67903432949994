import axios from 'axios'
import { 
    REMOTEAREA_CREATE_FAIL,
    REMOTEAREA_CREATE_REQUEST,
    REMOTEAREA_CREATE_SUCCESS,
    REMOTEAREA_DELETE_FAIL,
    REMOTEAREA_DELETE_REQUEST,
    REMOTEAREA_DELETE_SUCCESS,
    REMOTEAREA_LIST_FAIL,
    REMOTEAREA_LIST_REQUEST, 
    REMOTEAREA_LIST_SUCCESS,
    REMOTEAREA_UPDATE_FAIL,
    REMOTEAREA_UPDATE_REQUEST,
    REMOTEAREA_UPDATE_SUCCESS,


} from '../constants/remoteAreaConstants'

const API = '/api/remotearea'

export const getRemoteAreaList = () => async (dispatch, getState) => {
    try {
        dispatch({type: REMOTEAREA_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`${API}`, config)
        dispatch({type: REMOTEAREA_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: REMOTEAREA_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addNewRemoteArea = (pCode) => async (dispatch, getState) => {
    try {
        dispatch({type: REMOTEAREA_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`${API}`, pCode, config)
        dispatch({type: REMOTEAREA_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: REMOTEAREA_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateRemoteArea = (pCode, id) => async (dispatch, getState) => {
    try {
        dispatch({type: REMOTEAREA_UPDATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`${API}/${id}`, pCode, config)
        dispatch({type: REMOTEAREA_UPDATE_SUCCESS})
    } catch (error) {
        dispatch({
            type: REMOTEAREA_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const deleteRemoteArea = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: REMOTEAREA_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`${API}/${id}`, config)
        dispatch({type: REMOTEAREA_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: REMOTEAREA_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}
