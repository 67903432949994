import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ReactToPrint from 'react-to-print'
import { useNavigate } from 'react-router-dom'
import {
  HStack,
  Input,
  Button,
  Box,
  Spacer,
  Spinner,
  Divider,
  Select,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Center,
  Heading,
  Text,
} from '@chakra-ui/react'
import { listAgent } from '../actions/userActions'
import { dailyShipments } from '../actions/shipmentActions'
import Error from '../components/Error'

const DailyReportScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const componentRef = useRef()
  const toast = useToast()
  const [agentSearchKeyword ,setAgentSearchKeyword] = useState('')
  const [agentId, setAgentId] = useState('')
  const [agentName, setAgentName] = useState('')
  const [date, setDate] = useState('')
  const {user} = useSelector(state=>state.login)
  const {loading, error, shipments} = useSelector(state=>state.shipment.Daily)
  const {loading: loadingAgentList, error: errorAgentList, agents} = useSelector(state=>state.user.AgentList)
 
  const pageStyle = `
        @page {
            size: A4 !important;
            margin: 2mm 2mm;
        }
        html, body {
            font-size: 13px;
            font-weight: 400;
        }
        `;
        
  useEffect(()=>{
    if(user && user.accountType !== 'sale'){
        navigate('/')
    }
  },[user, navigate])

  const handleSearchAgent = (e) => {
    if(e.key === 'Enter'){
        dispatch(listAgent(1, 100, agentSearchKeyword, user._id))
    }
    return
  }

  const agentSelectHandle = (e) => {
    setAgentId(e.target.value)
    const idList = agents.map(ag=>ag._id)
    const index = idList.indexOf(e.target.value)
    if(index !== -1){
      const name = agents[index].name
      setAgentName(name)
    } else {
      setAgentName('')
    }    
  }

  const handleSubmitSearch = async () => {
    if(agentId === ''){
      toast({
        status: 'warning',
        position: 'top',
        title: 'Please choose agent.',
        duration: 1000,
        isClosable: true,
      })
    return
    }
    dispatch(dailyShipments(date, agentId))
  }

  return (
    <Box>
        <HStack spacing='4' my='4'>
          <Input 
            type='text'
            size='sm'
            w='200px'
            placeholder='Search Agent'
            value={agentSearchKeyword}
            onChange={e=> setAgentSearchKeyword(e.target.value)}
            onKeyPress={handleSearchAgent}
          />

          <Select w='200px' size='sm' placeholder='Agent List' onChange={agentSelectHandle}>
            {agents && agents.map((ag, idx)=>(
                <option key={idx} value={ag._id}>{ag.name} - {ag.agentCode}</option>
            ))}
         </Select>
         <Input 
            type='date'
            size='sm'
            w='200px'
            value={date}
            onChange={e=> setDate(e.target.value)}
          />
          <Button
            size={'sm'}
            loading={loading}
            colorScheme={'green'}
            onClick={()=>handleSubmitSearch()}
          >Go</Button>

          <Box>
            {(loading || loadingAgentList) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
          </Box>
          <Spacer />
          <Box>   
            <ReactToPrint
                trigger={() => <Button size='sm' w='100px' colorScheme='blue' >Print</Button>}
                content={() => componentRef.current}
                pageStyle={()=>pageStyle}
            />
          </Box>

        </HStack>
        <Divider />

        <Box my={'2'}>
          {error && <Error error={error} />}
          {errorAgentList && <Error error={errorAgentList} />}
        </Box>

        <Box ref={componentRef}>
            <Center><Heading>{agentName} - {agentName && date === '' ? new Date().toISOString().substring(0,10) : date}</Heading></Center>
            <Table size='sm' variant='striped' colorScheme='black' my='4'>
            <Thead>
                <Tr>
                <Th>No</Th>
                <Th>AWB</Th>
                <Th>Receiver</Th>
                <Th>Ship Date</Th>
                <Th>Weight</Th>
                <Th>Charges</Th>
                <Th>Shipment Cost</Th>
                <Th>Total Amount</Th>
                </Tr>
            </Thead>
            <Tbody>
                {shipments && shipments.map((sm,index)=>(
                <Tr key={index}>
                    <Td>{index+1}</Td>
                    <Td>{sm.awb}</Td>
                    <Td>
                        <Text fontWeight={'600'}>{sm.customerDetails.receiverDetails.contactInformation.fullName}</Text>
                        <Text fontSize={'xs'}>{sm.customerDetails.receiverDetails.postalAddress.addressLine1}</Text>
                        <Text fontSize={'xs'}>{sm.customerDetails.receiverDetails.postalAddress.cityName}, {sm.customerDetails.receiverDetails.postalAddress.countryCode}</Text>
                    </Td>
                    <Td>{sm.createdAt.substring(0,10)}</Td>
                    <Td>{sm.totalWeight}</Td>
                    <Td>
                      <Table  size='xs' variant='striped' colorScheme='black'>
                        <Thead>
                            <Tr>
                                <Th>Des</Th>
                                <Th isNumeric>Value</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sm.charges.map((ch, idx)=> (
                                <Tr key={idx}>
                                    <Td>{ch.name}</Td>
                                    <Td isNumeric>{ch.amount}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th>Total</Th>
                                <Th isNumeric>{sm.charges.reduce((acc, ch) => acc + ch.amount, 0)}</Th>
                            </Tr>
                        </Tfoot>
                      </Table>
                    </Td>
                    <Td>{(sm.totalAmount - sm.charges.reduce((acc, c) => acc + c.amount, 0) ).toLocaleString()}</Td>
                    <Td>{sm.totalAmount.toLocaleString()}</Td>
                </Tr>
                ))}
            </Tbody>
            </Table>
            <HStack>
                <Box></Box>
                <Spacer />
                <Box fontSize={'xl'}>
                    Total - {shipments && (shipments.reduce((acc, s) => acc + s.totalAmount, 0)).toLocaleString()}
                </Box>
            </HStack>
        </Box>
    </Box>
  )
}

export default DailyReportScreen