import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import {
  Box,
  HStack,
  Stack,
  Spacer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  OrderedList,
  ListItem,
  Button,
  Text,
  Wrap,  
} from '@chakra-ui/react'
import { getInvoiceById } from '../../actions/invoiceActions'
import { INVOICE_BYID_RESET } from '../../constants/invoiceConstants'
import { MdCheckCircle, MdCancel } from 'react-icons/md'
import PaymentAddModal from '../../Modals/PaymentAddModal'
import AddShipmentToInvoiceModal from '../../Modals/AddShipmentToInvoiceModal'
import RemoveShipmentFromInvoiceModal from '../../Modals/RemoveShipmentFromInvoiceModal'
import Error from '../../components/Error'
import useWindowSize from '../../components/WindowSize'
import MySpinner from '../../components/MySpinner'
import Marks from '../../components/Marks'

const AgentInvoiceDetailScreen = () => {
    const size = useWindowSize()
    const dispatch = useDispatch()
    const params = useParams()
    const componentRef = useRef()
    const {user} = useSelector(state=>state.login)
    const {loading, error, invoice} = useSelector(state=>state.invoice.ById)
    const {success: successCreatePayment} = useSelector(state=>state.payment.Create)

    const pageStyle = `
        @page {
            size: A4 !important;
            margin: 2mm 2mm;
        }
        html, body {
            font-size: 12px;
            font-weight: 400;
        }
        `;

    useEffect(()=>{
      dispatch(getInvoiceById(params.id))
      return()=>{
        dispatch({type: INVOICE_BYID_RESET})
      }
    },[dispatch, successCreatePayment, params])

    function calcDate(date1,date2) {
      var diff = Math.floor(date1.getTime() - date2.getTime());
      var day = 1000 * 60 * 60 * 24;
  
      var days = Math.floor(diff/day);
      //var months = Math.floor(days/31);
      //var years = Math.floor(months/12);
  
      // var message = date2.toDateString();
      // message += " was "
      // message += days + " days " 
      // message += months + " months "
      // message += years + " years ago \n"
  
      return days
    }

    return (
      <Box boxShadow='xl' my='5' p='3'>
      {error && <Error error={error} /> }

      {invoice &&
        <Wrap justify='right'>
          {user && user.accountType === 'sale' && 
          <>        
              <PaymentAddModal                 
                invoiceId={invoice._id} 
                totalAmount={invoice.totalAmount} 
                balance={invoice.totalAmount - (invoice.payments.reduce((acc, p) => acc + p.amount, 0))} 
                isPaid={invoice.isPaid}
                agentBalance = {invoice.agent.balance}
              />             
              <AddShipmentToInvoiceModal agentId={invoice.agent._id} invocieId={invoice._id} disabled={invoice.isPaid} />  
              <RemoveShipmentFromInvoiceModal disabled={invoice.isPaid} />
          </>
           }
            <ReactToPrint
              trigger={() => <Button size='sm' disabled={size.width < 760} w='150px' colorScheme='green' >Print</Button>}
              content={() => componentRef.current}
              pageStyle={()=>pageStyle}
            />
          
        </Wrap>      
      }

          <Box ref={componentRef}>
            {invoice && 
              <Box mt='3'>
                <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full' my='3'>   
                  <HStack fontSize={'2xl'} fontWeight={'700'} mb='3' borderBottom='1px' justifyContent={'space-between'}>                           
                      <Text >Invoice {loading && <MySpinner />}</Text>       
                      <Text >{invoice.code}</Text>       
                  </HStack>      
                  <HStack justifyContent={'space-between'}>
                    <Stack spacing={'0'} fontSize='sm' fontWeight={700}>
                      <Text>Date - {new Date(invoice.createdAt).toDateString()}</Text>
                      <Text>Due - {new Date(invoice.dueDate).toDateString()}</Text>                      
                      <Text color={!invoice.isPaid && 'red'}>Status - 
                        {invoice.isPaid ? 
                        ' Paid' : 
                        calcDate(new Date(), new Date(invoice.dueDate)) > 0 ? 
                        ` ${calcDate(new Date(), new Date(invoice.dueDate))} days over due` : ' Pending'
                        }
                      </Text>                      
                    </Stack>
                    <Stack spacing={'0'} fontSize='sm' fontWeight={700}>
                      <Text>A - {invoice.agent.name}</Text>
                      <Text>S - {invoice.sale.name}</Text>       
                    </Stack>
                  </HStack> 
                </Box>            
                {invoice && invoice.isPaid && <Box className='wartermark'>Paid</Box>}
                  <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full' my='3'>
                    <HStack fontSize={'xl'} fontWeight={'700'} mb='3' borderBottom='1px' justifyContent={'space-between'}>                           
                      <Text >Shipments</Text>       
                      <Text ></Text>       
                    </HStack>   
                    {size.width > 760 ? 
                    <>   
                      
                      <Table size='sm' variant='striped' colorScheme='black' my='4'>
                        <Thead>
                            <Tr>
                            <Th>No</Th>
                            <Th>AWB</Th>
                            <Th>Date</Th>
                            <Th>Receiver</Th>
                            <Th>Weight</Th>
                            <Th>Price</Th>
                            <Th>Charges</Th>
                            <Th>Total</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {invoice.shipmentList.map((s, idx)=>(
                            <Tr key={idx}>
                                <Td>{idx+1}</Td>
                                <Td ><Link to={`/myshipments/${s._id}`}>{s.awb}</Link>
                                <span>{s.isReturned && <Marks bgColor='teal' text='R' />} </span>
                                </Td>
                                <Td>{s.createdAt.substring(0,10)}</Td>
                                <Td py='1'>
                                  <Stack spacing={'0'}>
                                    <Text>{s.customerDetails.receiverDetails.contactInformation.fullName}</Text>
                                    <Text fontSize={'xs'}>
                                        {s.customerDetails.receiverDetails.postalAddress.addressLine1}, 
                                    </Text>
                                    <HStack fontSize={'xs'}>                                    
                                      <Text>
                                        {s.customerDetails.receiverDetails.postalAddress.cityName}, 
                                      </Text>
                                        {s.customerDetails.receiverDetails.postalAddress.postalCode &&
                                          <Text>{s.customerDetails.receiverDetails.postalAddress.postalCode}, </Text>
                                        }
                                      <Text>
                                        {s.customerDetails.receiverDetails.postalAddress.countryCode}
                                      </Text>
                                    </HStack>
                                  </Stack>
                                </Td>
                                <Td>{s.totalWeight}</Td>
                                <Td>{(s.totalAmount - s.charges.reduce((acc, c)=> acc + c.amount, 0)).toLocaleString()}</Td>
                                <Td>
                                  <OrderedList>                                  
                                    {s.charges.map((c,i)=>(
                                      <ListItem key={i}>
                                        {c.name} : {c.amount}
                                      </ListItem>
                                    ))}
                                  </OrderedList>
                                </Td>
                                <Td>{s.totalAmount.toLocaleString()}</Td>
                            </Tr>
                            ))}
                        </Tbody>
                      </Table>   
                        
                    </>
                    :
                    <>  
                      <Table size='sm' variant='striped' colorScheme='black' my='4'>
                        <Thead>
                            <Tr>
                            <Th>No</Th>
                            <Th>SUMMARY</Th>
                            <Th>Total</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {invoice.shipmentList.map((s, idx)=>(
                            <Tr key={idx}>
                                <Td>{idx+1}</Td>
                                <Td>
                                  <Stack spacing={0}>
                                    <Box><Link to={`/shipment/${s._id}`}>Code - {s.awb}</Link></Box>
                                    <Box>DATE - {s.createdAt.substring(0,10)}</Box>
                                    <Box>RECEIVER - {s.customerDetails.receiverDetails.contactInformation.fullName}, {s.customerDetails.receiverDetails.postalAddress.countryCode}</Box>
                                    <Box>WEIGHT - {s.totalWeight}</Box>
                                    <Box>PRICE - {(s.totalAmount - s.charges.reduce((acc, c)=> acc + c.amount, 0)).toLocaleString()}</Box>
                                    <Box>CHARGES - 

                                    <OrderedList ml='50px'>                                  
                                      {s.charges.map((c,i)=>(
                                        <ListItem key={i}>
                                          {c.name} : {c.amount}
                                        </ListItem>
                                      ))}
                                    </OrderedList>
                                    </Box>

                                  </Stack>
                                </Td>
                                <Td>{s.totalAmount.toLocaleString()}</Td>
                            </Tr>
                            ))}
                        </Tbody>
                      </Table>   
                    </>}

                    <HStack pr='50px'>
                      <Box></Box><Spacer />
                      <Stack fontSize={'xl'} fontWeight={600}>
                        <Box>Invoice Total  {invoice.totalAmount.toLocaleString()}</Box>
                        <Box>Balance  {(invoice.totalAmount - (invoice.payments.reduce((acc, p) => acc + p.amount, 0))).toLocaleString()}</Box>
                      </Stack>
                    </HStack>
                  </Box>           
                    
              

                {/* {invoice && invoice.isPaid && <Box className='wartermark'>Paid</Box>} */}
         
                

              {invoice.payments.length !== 0 &&
              <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full' my='3'>
                <HStack fontSize={'xl'} fontWeight={'700'} mb='3' borderBottom='1px' justifyContent={'space-between'}>                           
                  <Text>Payments</Text>       
                  <Text></Text>       
                </HStack> 
                {size.width > 760 ? 
                <>
                  <Table size='sm' variant='striped' colorScheme='black' my='4'>
                    <Thead>
                        <Tr>
                        <Th>No</Th>
                        <Th>Code</Th>
                        <Th>Amount</Th>
                        <Th>Method</Th>
                        <Th>Paid At</Th>
                        <Th>Approved</Th>
                        <Th w='300px'>Note</Th>
                        </Tr>
                    </Thead>
                    <Tbody>                        
                        {invoice.payments.map((p, i)=>(
                        <Tr key={i}>
                            <Td>{i+1}</Td>
                            <Td ><Box color='blue' textDecoration={'underline'}> <Link to={`/mypayments/${p._id}`}>{p.code}</Link></Box></Td>
                            <Td>{p.amount}</Td>
                            <Td>{p.paidMethod}</Td>
                            <Td>{new Date(p.createdAt).toLocaleString('en', { hour12: true, timeZone: 'UTC' })}</Td>
                            {/* <Td><Box bg={p.isApproved ? 'green' : 'red'} h='20px' w='20px'></Box></Td> */}
                            <Td>{p.isApproved ? < MdCheckCircle size={20} color='green' /> :  < MdCancel size={20} color='red' />}</Td>
                            <Td py='1'>{p.note}</Td>
                        </Tr>
                        ))}
                    </Tbody>
                  </Table> 
                    
                </>
                :
                <>
                  <Table size='sm' variant='striped' colorScheme='black' my='4'>
                    <Thead>
                        <Tr>
                        <Th>No</Th>
                        <Th>SUMMARY</Th>   
                        <Th>Approved</Th>
                        </Tr>
                    </Thead>
                    <Tbody>                        
                        {invoice.payments.map((p, i)=>(
                        <Tr key={i}>
                            <Td>{i+1}</Td>
                            <Td>
                              <Stack spacing={0}>
                                <Link to={`/mypayments/${p._id}`}>Code - {p.code}</Link>
                                <Box>Amount - {p.amount}</Box>
                                <Box>Mehtod - {p.paidMethod}</Box>
                                <Box>Paid At - {new Date(p.createdAt).toLocaleDateString()}</Box>
                                <Box>Note - {p.note}</Box>
                              </Stack>
                            </Td>
                            {/* <Td><Box bg={p.isApproved ? 'green' : 'red'} h='20px' w='20px'></Box></Td> */}
                            <Td>{p.isApproved ? < MdCheckCircle size={20} color='green' /> :  < MdCancel size={20} color='red' />}</Td>
                        
                        </Tr>
                        ))}
                    </Tbody>
                  </Table> 
                </>
                }
                <HStack pr='50px'>
                    <Box></Box><Spacer />
                    <Box fontSize={'xl'} fontWeight={'600'}>Payment Total  {(invoice.payments.reduce((acc, p) => acc + p.amount, 0)).toLocaleString()}</Box>
                  </HStack>
                </Box>}

                <Box mt={'20px'} className='no-print'>
                  {invoice.notes.map((n, i)=>(
                    <Box key={i} w='100%' p='2' borderRadius={'20'} backgroundColor={'gray.100'} mb='1'>
                      <Stack spacing={'1'}>
                          <HStack>
                              <HStack>
                              <Text fontSize={'sm'} fontWeight={'700'} >{n.name}</Text>
                              <Text fontSize={'12px'}>{n.date.substring(0,10)}</Text>
                              </HStack>
                              <Spacer />
                              {user._id === n.userId &&
                                  <Button
                                  size='xs'
                                  colorScheme={'red'}
                                  onClick={()=>console.log(`invoice note delete button ${n._id}`)}
                              >x</Button>
                              }                                                
                          </HStack>                                            
                          <Text>{n.text}</Text>
                      </Stack>
                    </Box>
                  ))}
                </Box>



              </Box>
            }
          </Box>
        
          
      </Box>
    )
}

export default AgentInvoiceDetailScreen