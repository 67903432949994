import { 
    INVOICE_ADDSHIPMENTS_FAIL,
    INVOICE_ADDSHIPMENTS_REQUEST,
    INVOICE_ADDSHIPMENTS_RESET,
    INVOICE_ADDSHIPMENTS_SUCCESS,
    INVOICE_BYID_FAIL,
    INVOICE_BYID_REQUEST,
    INVOICE_BYID_RESET,
    INVOICE_BYID_SUCCESS,
    INVOICE_CREATE_FAIL,
    INVOICE_CREATE_REQUEST,
    INVOICE_CREATE_RESET,
    INVOICE_CREATE_SUCCESS,
    INVOICE_DELETE_FAIL,
    INVOICE_DELETE_REQUEST,
    INVOICE_DELETE_RESET,
    INVOICE_DELETE_SUCCESS,
    INVOICE_LIST_FAIL,
    INVOICE_LIST_REQUEST, 
    INVOICE_LIST_RESET, 
    INVOICE_LIST_SUCCESS,
    INVOICE_MY_FAIL,
    INVOICE_MY_REQUEST,
    INVOICE_MY_RESET,
    INVOICE_MY_SUCCESS,
    INVOICE_REMOVESHIPMENTS_FAIL,
    INVOICE_REMOVESHIPMENTS_REQUEST,
    INVOICE_REMOVESHIPMENTS_RESET,
    INVOICE_REMOVESHIPMENTS_SUCCESS,
} from '../constants/invoiceConstants'

export const invoiceListReducer = (state={invoices:[]}, action) => {
    switch(action.type){
        case INVOICE_LIST_REQUEST:
            return {loading: true}
        case INVOICE_LIST_SUCCESS:
            return {
                loading: false, 
                invoices: action.payload.invoices,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case INVOICE_LIST_FAIL:
            return {loading: false, error: action.payload}
        case INVOICE_LIST_RESET:
            return {}
        default: return state
    }
}

export const invoiceMyReducer = (state={invoices:[]}, action) => {
    switch(action.type){
        case INVOICE_MY_REQUEST:
            return {loading: true}
        case INVOICE_MY_SUCCESS:
            return {
                loading: false, 
                invoices: action.payload.invoices,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case INVOICE_MY_FAIL:
            return {loading: false, error: action.payload}
        case INVOICE_MY_RESET:
            return {}
        default: return state
    }
}



export const invoiceCreateReducer = (state={}, action) => {
    switch(action.type){
        case INVOICE_CREATE_REQUEST:
            return {loading: true}
        case INVOICE_CREATE_SUCCESS:
            return {loading: false, success: true, invoiceId: action.payload}
        case INVOICE_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case INVOICE_CREATE_RESET:
            return {}
        default: return state
    }
}

export const invoiceByIdReducer = (state={}, action) => {
    switch(action.type){
        case INVOICE_BYID_REQUEST:
            return {loading: true}
        case INVOICE_BYID_SUCCESS:
            return {loading: false, invoice: action.payload}
        case INVOICE_BYID_FAIL:
            return {loading: false, error: action.payload}
        case INVOICE_BYID_RESET:
            return {}
        default: return state
    }
}


export const invoiceDeleteReducer = (state={}, action) => {
    switch(action.type){
        case INVOICE_DELETE_REQUEST:
            return {loading: true}
        case INVOICE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case INVOICE_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case INVOICE_DELETE_RESET:
            return {}
        default: return state
    }
}

export const invoiceAddShipmentReducer = (state={}, action) => {
    switch(action.type){
        case INVOICE_ADDSHIPMENTS_REQUEST:
            return {loading: true}
        case INVOICE_ADDSHIPMENTS_SUCCESS:
            return {loading: false, success: true}
        case INVOICE_ADDSHIPMENTS_FAIL:
            return {loading: false, error: action.payload}
        case INVOICE_ADDSHIPMENTS_RESET:
            return {}
        default: return state
    }
}

export const invoiceRemoveShipmentReducer = (state={}, action) => {
    switch(action.type){
        case INVOICE_REMOVESHIPMENTS_REQUEST:
            return {loading: true}
        case INVOICE_REMOVESHIPMENTS_SUCCESS:
            return {loading: false, success: true}
        case INVOICE_REMOVESHIPMENTS_FAIL:
            return {loading: false, error: action.payload}
        case INVOICE_REMOVESHIPMENTS_RESET:
            return {}
        default: return state
    }
}