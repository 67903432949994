export const DOMESTICSHIPMENT_CREATE_REQUEST = 'DOMESTICSHIPMENT_CREATE_REQUEST'
export const DOMESTICSHIPMENT_CREATE_SUCCESS = 'DOMESTICSHIPMENT_CREATE_SUCCESS'
export const DOMESTICSHIPMENT_CREATE_FAIL = 'DOMESTICSHIPMENT_CREATE_FAIL'
export const DOMESTICSHIPMENT_CREATE_RESET = 'DOMESTICSHIPMENT_CREATE_RESET'

export const DOMESTICSHIPMENT_LIST_REQUEST = 'DOMESTICSHIPMENT_LIST_REQUEST'
export const DOMESTICSHIPMENT_LIST_SUCCESS = 'DOMESTICSHIPMENT_LIST_SUCCESS'
export const DOMESTICSHIPMENT_LIST_FAIL = 'DOMESTICSHIPMENT_LIST_FAIL'
export const DOMESTICSHIPMENT_LIST_RESET = 'DOMESTICSHIPMENT_LIST_RESET'


export const DOMESTICSHIPMENT_BYID_REQUEST = 'DOMESTICSHIPMENT_BYID_REQUEST'
export const DOMESTICSHIPMENT_BYID_SUCCESS = 'DOMESTICSHIPMENT_BYID_SUCCESS'
export const DOMESTICSHIPMENT_BYID_FAIL = 'DOMESTICSHIPMENT_BYID_FAIL'
export const DOMESTICSHIPMENT_BYID_RESET = 'DOMESTICSHIPMENT_BYID_RESET'

export const DOMESTICSHIPMENT_DELETE_REQUEST = 'DOMESTICSHIPMENT_DELETE_REQUEST'
export const DOMESTICSHIPMENT_DELETE_SUCCESS = 'DOMESTICSHIPMENT_DELETE_SUCCESS'
export const DOMESTICSHIPMENT_DELETE_FAIL = 'DOMESTICSHIPMENT_DELETE_FAIL'
export const DOMESTICSHIPMENT_DELETE_RESET = 'DOMESTICSHIPMENT_DELETE_RESET'

export const DOMESTICSHIPMENT_UPDATE_REQUEST = 'DOMESTICSHIPMENT_UPDATE_REQUEST'
export const DOMESTICSHIPMENT_UPDATE_SUCCESS = 'DOMESTICSHIPMENT_UPDATE_SUCCESS'
export const DOMESTICSHIPMENT_UPDATE_FAIL = 'DOMESTICSHIPMENT_UPDATE_FAIL'
export const DOMESTICSHIPMENT_UPDATE_RESET = 'DOMESTICSHIPMENT_UPDATE_RESET'

export const DOMESTICSHIPMENT_GETCOST_REQUEST = 'DOMESTICSHIPMENT_GETCOST_REQUEST'
export const DOMESTICSHIPMENT_GETCOST_SUCCESS = 'DOMESTICSHIPMENT_GETCOST_SUCCESS'
export const DOMESTICSHIPMENT_GETCOST_FAIL = 'DOMESTICSHIPMENT_GETCOST_FAIL'
export const DOMESTICSHIPMENT_GETCOST_RESET = 'DOMESTICSHIPMENT_GETCOST_RESET'

export const DOMESTICSHIPMENT_MYLIST_REQUEST = 'DOMESTICSHIPMENT_MYLIST_REQUEST'
export const DOMESTICSHIPMENT_MYLIST_SUCCESS = 'DOMESTICSHIPMENT_MYLIST_SUCCESS'
export const DOMESTICSHIPMENT_MYLIST_FAIL = 'DOMESTICSHIPMENT_MYLIST_FAIL'
export const DOMESTICSHIPMENT_MYLIST_RESET = 'DOMESTICSHIPMENT_MYLIST_RESET'

export const DOMESTICSHIPMENT_PRESHIPCONTACT_REQUEST = 'DOMESTICSHIPMENT_PRESHIPCONTACT_REQUEST'
export const DOMESTICSHIPMENT_PRESHIPCONTACT_SUCCESS = 'DOMESTICSHIPMENT_PRESHIPCONTACT_SUCCESS'
export const DOMESTICSHIPMENT_PRESHIPCONTACT_FAIL = 'DOMESTICSHIPMENT_PRESHIPCONTACT_FAIL'
export const DOMESTICSHIPMENT_PRESHIPCONTACT_RESET = 'DOMESTICSHIPMENT_PRESHIPCONTACT_RESET'

export const DOMESTICSHIPMENT_CHARGES_REQUEST = 'DOMESTICSHIPMENT_CHARGES_REQUEST'
export const DOMESTICSHIPMENT_CHARGES_SUCCESS = 'DOMESTICSHIPMENT_CHARGES_SUCCESS'
export const DOMESTICSHIPMENT_CHARGES_FAIL = 'DOMESTICSHIPMENT_CHARGES_FAIL'
export const DOMESTICSHIPMENT_CHARGES_RESET = 'DOMESTICSHIPMENT_CHARGES_RESET'

export const DOMESTICSHIPMENT_UPDATECOST_REQUEST = 'DOMESTICSHIPMENT_UPDATECOST_REQUEST'
export const DOMESTICSHIPMENT_UPDATECOST_SUCCESS = 'DOMESTICSHIPMENT_UPDATECOST_SUCCESS'
export const DOMESTICSHIPMENT_UPDATECOST_FAIL = 'DOMESTICSHIPMENT_UPDATECOST_FAIL'
export const DOMESTICSHIPMENT_UPDATECOST_RESET = 'DOMESTICSHIPMENT_UPDATECOST_RESET'

export const DOMESTICSHIPMENT_SCANNED_REQUEST = 'DOMESTICSHIPMENT_SCANNED_REQUEST'
export const DOMESTICSHIPMENT_SCANNED_SUCCESS = 'DOMESTICSHIPMENT_SCANNED_SUCCESS'
export const DOMESTICSHIPMENT_SCANNED_FAIL = 'DOMESTICSHIPMENT_SCANNED_FAIL'
export const DOMESTICSHIPMENT_SCANNED_RESET = 'DOMESTICSHIPMENT_SCANNED_RESET'

export const DOMESTICSHIPMENT_GET_SCANNED_REQUEST = 'DOMESTICSHIPMENT_GET_SCANNED_REQUEST'
export const DOMESTICSHIPMENT_GET_SCANNED_SUCCESS = 'DOMESTICSHIPMENT_GET_SCANNED_SUCCESS'
export const DOMESTICSHIPMENT_GET_SCANNED_FAIL = 'DOMESTICSHIPMENT_GET_SCANNED_FAIL'
export const DOMESTICSHIPMENT_GET_SCANNED_RESET = 'DOMESTICSHIPMENT_GET_SCANNED_RESET'

export const DOMESTICSHIPMENT_SHIPANDSCAN_REQUEST = 'DOMESTICSHIPMENT_SHIPANDSCAN_REQUEST'
export const DOMESTICSHIPMENT_SHIPANDSCAN_SUCCESS = 'DOMESTICSHIPMENT_SHIPANDSCAN_SUCCESS'
export const DOMESTICSHIPMENT_SHIPANDSCAN_FAIL = 'DOMESTICSHIPMENT_SHIPANDSCAN_FAIL'
export const DOMESTICSHIPMENT_SHIPANDSCAN_RESET = 'DOMESTICSHIPMENT_SHIPANDSCAN_RESET'


