import axios from 'axios'
import {
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_EDIT_REQUEST,
    USER_EDIT_SUCCESS,
    USER_EDIT_FAIL,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_BYID_REQUEST,
    USER_BYID_SUCCESS,
    USER_BYID_FAIL,
    USER_SALES_NAMES_REQUEST,
    USER_SALES_NAMES_SUCCESS,
    USER_SALES_NAMES_FAIL,
    USER_FORGOT_PASSWORD_REQUEST,
    USER_FORGOT_PASSWORD_SUCCESS,
    USER_FORGOT_PASSWORD_FAIL,
    USER_RESET_PASSWORD_REQUEST,
    USER_RESET_PASSWORD_SUCCESS,
    USER_RESET_PASSWORD_FAIL,
    USER_AGENTLIST_REQUEST,
    USER_AGENTLIST_SUCCESS,
    USER_AGENTLIST_FAIL,
    USER_SALELIST_REQUEST,
    USER_SALELIST_SUCCESS,
    USER_SALELIST_FAIL,
    USER_MY_REQUEST,
    USER_MY_SUCCESS,
    USER_MY_FAIL,
    USER_MY_PASSWORD_REQUEST,
    USER_MY_PASSWORD_SUCCESS,
    USER_MY_PASSWORD_FAIL,
    USER_ADDBALANCE_REQUEST,
    USER_ADDBALANCE_SUCCESS,
    USER_ADDBALANCE_FAIL,
    USER_MYAGENTS_REQUEST,
    USER_MYAGENTS_SUCCESS,
    USER_MYAGENTS_FAIL,
    USER_MYAGENTDETAIL_REQUEST,
    USER_MYAGENTDETAIL_SUCCESS,
    USER_MYAGENTDETAIL_FAIL,
    USER_CONFIRMPASSWORD_REQUEST,
    USER_CONFIRMPASSWORD_SUCCESS,
    USER_CONFIRMPASSWORD_FAIL,
    USER_ADDTHPDISCOUNT_REQUEST,
    USER_ADDTHPDISCOUNT_SUCCESS,
    USER_ADDTHPDISCOUNT_FAIL,
    USER_DELETETHPDISCOUNT_REQUEST,
    USER_DELETETHPDISCOUNT_SUCCESS,
    USER_DELETETHPDISCOUNT_FAIL
} from '../constants/userConstants'


export const registerUser = (newUser) => async (dispatch, getState) => {
    try {
        dispatch({type: USER_REGISTER_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/users`, newUser, config)
        dispatch({type: USER_REGISTER_SUCCESS})
    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const loginUser = (credential) => async (dispatch) => {
    try {
        dispatch({type: USER_LOGIN_REQUEST})
        const {data} = await axios.post(`/api/users/login`, credential)
        dispatch({type: USER_LOGIN_SUCCESS, payload: data})
        localStorage.setItem('userInfo', JSON.stringify(data))
        sessionStorage.setItem('token', JSON.stringify(data.token))
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const logoutUser = () => async (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({type: USER_LOGOUT})
}


export const listUser = (pageNumber = '', itemCount = '', keyword = '', sortBy = '') => async (dispatch, getState) => {
    try {
        dispatch({type: USER_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/users?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}&sortBy=${sortBy}`, config)
        dispatch({type: USER_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: USER_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listAgent = (pageNumber = '', itemCount = '',keyword = '', saleId='') => async (dispatch, getState) => {
    try {
        dispatch({type: USER_AGENTLIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/users/agents?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}&saleId=${saleId}`, config)
        dispatch({type: USER_AGENTLIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: USER_AGENTLIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const myAgents = (keyword='') => async (dispatch, getState) => {
    try {
        dispatch({type: USER_MYAGENTS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/users/myagents?keyword=${keyword}`, config)
        dispatch({type: USER_MYAGENTS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: USER_MYAGENTS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myAgentById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: USER_MYAGENTDETAIL_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/users/myagents/${id}`, config)
        dispatch({type: USER_MYAGENTDETAIL_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: USER_MYAGENTDETAIL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const listSale = (keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: USER_SALELIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/users/sales?keyword=${keyword}`, config)
        dispatch({type: USER_SALELIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: USER_SALELIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const editUser = (id, editedUser) => async (dispatch, getState) => {
    try {
        dispatch({type: USER_EDIT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/users/${id}`, editedUser, config)
        dispatch({type: USER_EDIT_SUCCESS})
    } catch (error) {
        dispatch({
            type: USER_EDIT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deleteUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: USER_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/users/${id}`, config)
        dispatch({type: USER_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: USER_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const byIdUser = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: USER_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/users/${id}`, config)
        dispatch({type: USER_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: USER_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myAccount = () => async (dispatch, getState) => {
    try {
        dispatch({type: USER_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/users/myaccount`, config)
        dispatch({type: USER_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: USER_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const changeMyPassword = (passData) => async (dispatch, getState) => {
    try {
        dispatch({type: USER_MY_PASSWORD_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/users/myaccount`, {passData}, config)
        dispatch({type: USER_MY_PASSWORD_SUCCESS})
    } catch (error) {
        dispatch({
            type: USER_MY_PASSWORD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}
 

export const fotgotPassword = (resetData) => async (dispatch) => {
    try {
        dispatch({type: USER_FORGOT_PASSWORD_REQUEST})
        await axios.post(`/api/users/forgotpassword`, resetData)
        dispatch({type: USER_FORGOT_PASSWORD_SUCCESS})
    } catch (error) {
        dispatch({
            type: USER_FORGOT_PASSWORD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const resetPassword = (newPassword) => async (dispatch) => {
    try {
        dispatch({type: USER_RESET_PASSWORD_REQUEST})
        await axios.put(`/api/users/resetpassword`, newPassword)
        dispatch({type: USER_RESET_PASSWORD_SUCCESS})
    } catch (error) {
        dispatch({
            type: USER_RESET_PASSWORD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getSalesNames = () => async (dispatch, getState) => {
    try {
        dispatch({type: USER_SALES_NAMES_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/users/sales/names`, config)
        dispatch({type: USER_SALES_NAMES_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: USER_SALES_NAMES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const addBalance = (detail) => async (dispatch, getState) => {
    try {
        dispatch({type: USER_ADDBALANCE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/users/addbalance`, detail, config)
        dispatch({type: USER_ADDBALANCE_SUCCESS})
    } catch (error) {
        dispatch({
            type: USER_ADDBALANCE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const confirmPassword = (userData) => async (dispatch, getState) => {
    try {
        dispatch({type: USER_CONFIRMPASSWORD_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/users/confirmpassword`, userData, config)
        dispatch({type: USER_CONFIRMPASSWORD_SUCCESS})
    } catch (error) {
        dispatch({
            type: USER_CONFIRMPASSWORD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addThpDiscount = (detail) => async (dispatch, getState) => {
    try {
        dispatch({type: USER_ADDTHPDISCOUNT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/users/thpdiscount`, detail, config)
        dispatch({type: USER_ADDTHPDISCOUNT_SUCCESS})
    } catch (error) {
        dispatch({
            type: USER_ADDTHPDISCOUNT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deleteThpDiscount = (detail) => async (dispatch, getState) => {
    try {
        dispatch({type: USER_DELETETHPDISCOUNT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/users/thpdiscount`, detail, config)
        dispatch({type: USER_DELETETHPDISCOUNT_SUCCESS})
    } catch (error) {
        dispatch({
            type: USER_DELETETHPDISCOUNT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}