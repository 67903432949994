import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Spinner,
    ModalFooter,
    Select,
    useToast,
} from '@chakra-ui/react'
import { addThpDiscount } from '../../../actions/userActions'
import { USER_ADDTHPDISCOUNT_RESET } from '../../../constants/userConstants'
import { getCountryList } from '../../../actions/countryActions'
import Error from '../../../components/Error'

const PostDiscountAddModal = ({userId}) => {
    const dispatch = useDispatch()
    const toast = useToast()
    const [amount, setAmount] = useState(0)
    const [countryName, setCountryName] = useState('')
    const [show, setShow] = useState(false)
    const {loading, error, success} = useSelector(state=>state.user.AddThpDiscount)
    const { loading: loadingCountry, countries, success:successCountry, error:errorCountry } = useSelector(state=>state.country.List)

    useEffect(()=>{
        dispatch(getCountryList())
        if(success){
            setAmount(0)
            setShow(false)
        }
    },[dispatch, success])

    
    const handleClose = () => {
        setCountryName('')
        setAmount(0)
        dispatch({type: USER_ADDTHPDISCOUNT_RESET})
        setShow(false)
    }

    const handleSubmit = () => {
        if(countryName === '' || amount === 0 || !amount || amount === ''){
            toast({
                status: 'error',
                position: 'top',
                title: 'Country Name and Amount can not be 0 or blank.',
                duration: 2000,
                isClosable: true,
            }) 
            return
        }
        const detail = {
            userId,
            amount: Number(amount),
            countryName,
        }
        //console.log(detail)
        dispatch(addThpDiscount(detail))
    }


    return (
        <>
            <Button size='xs' colorScheme={'orange'} onClick={()=>setShow(true)} >Add</Button>

            <Modal isOpen={show} onClose={handleClose} size='lg' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Thailand Post Discount</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{(loading || loadingCountry) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Box>
                        {error && <Error error={error} />}
                        {errorCountry && <Error error={errorCountry} />}
                    </Box>

                    {successCountry &&
                        <Box mb={3}>
                            <Box>Countries</Box>
                            <Select placeholder='Select country' onChange={(e)=>setCountryName(e.target.value)}>
                                {countries.map(c=>(
                                    <option key={c.name} value={c.name}>{c.name}</option>
                                ))}
                                
                            </Select>
                        </Box>
                    }
                    <Box mb='20px'>
                        <Box >Amount</Box>
                        <Input 
                            type={'number'}
                            size='sm'
                            value={amount}
                            onChange={e=>setAmount(e.target.value)}
                        />
                    </Box>
                    
                </ModalBody>

                <ModalFooter>
                    <Button 
                        ms='auto' 
                        size='sm' 
                        colorScheme={'green'}
                        onClick={()=>handleSubmit()}
                        >Submit</Button>
                </ModalFooter>

            </ModalContent>
            </Modal>
        </>
    )
}

export default PostDiscountAddModal