import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Input,
    Box,
    Spinner,
} from '@chakra-ui/react'
import Error from '../../components/Error'
import { updateCostTHPShipment } from '../../actions/thailand_post_actions/thpShipmentActions'

const THPShipmentCostUpdateModal = ({shipmentId, disable}) => {
    const dispatch = useDispatch()
    const [cost, setCost] = useState(0)
    const [show, setShow] = useState(false)
    const {loading, error, success} = useSelector(state=>state.thailandPost.ShipmentUpdateCost)

    useEffect(()=>{
        if(success){
            setShow(false)
        }
    },[success, dispatch])

    const handleClose = () => {        
        setCost(0)
        setShow(false)
    }


    return (
        <>
            <Button size='xs' disabled={disable}  w='50px' colorScheme={'orange'} onClick={()=>setShow(true)} >Update</Button>

            <Modal isOpen={show} isCentered onClose={handleClose} size='xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>update cost</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Box my='3'>
                        {error && <Error error={error} />}
                    </Box>                   

                    <Input  
                        type={'number'}
                        value={cost}
                        onChange={e=>setCost(e.target.value)}
                        onKeyPress={e=> {
                            if(e.key === 'Enter'){
                                dispatch(updateCostTHPShipment(cost, shipmentId))
                            }
                        }}
                    />

                </ModalBody>

                <ModalFooter>
                    <Button 
                        size='sm' w='150px' 
                        colorScheme={'green'} 
                        onClick={()=>dispatch(updateCostTHPShipment(cost, shipmentId))} >Submit</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default THPShipmentCostUpdateModal