// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Styles_mytable__xd-7F {\r\n  font-size: 13px;\r\n  border: 1px solid gray;\r\n  border-collapse: collapse;\r\n  border-spacing: 0px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/screens/AccountingScreens/Styles.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".mytable {\r\n  font-size: 13px;\r\n  border: 1px solid gray;\r\n  border-collapse: collapse;\r\n  border-spacing: 0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mytable": "Styles_mytable__xd-7F"
};
export default ___CSS_LOADER_EXPORT___;
