import {
    SALE_DASHBOARD_REQUEST,
    SALE_DASHBOARD_SUCCESS,
    SALE_DASHBOARD_FAIL,
    AGENT_DASHBOARD_REQUEST,
    AGENT_DASHBOARD_SUCCESS,
    AGENT_DASHBOARD_FAIL,
    ADMIN_DASHBOARD_REQUEST,
    ADMIN_DASHBOARD_SUCCESS,
    ADMIN_DASHBOARD_FAIL,
    ADMIN_DASHBOARD_RESET,
    LIST_DASHBOARD_REQUEST,
    LIST_DASHBOARD_SUCCESS,
    LIST_DASHBOARD_FAIL,
    LIST_DASHBOARD_RESET,
    DELETE_DASHBOARD_REQUEST,
    DELETE_DASHBOARD_SUCCESS,
    DELETE_DASHBOARD_FAIL,
    DELETE_DASHBOARD_RESET,
    SALE_SHIPPING_CHART_REQUEST,
    SALE_SHIPPING_CHART_SUCCESS,
    SALE_SHIPPING_CHART_FAIL,
    SALE_SHIPPING_CHART_RESET,
} from '../constants/dashboardConstants'

export const adminDashboardReducer = (state={}, action) => {
    switch(action.type){
        case ADMIN_DASHBOARD_REQUEST:
            return {loading: true}
        case ADMIN_DASHBOARD_SUCCESS:
            return {
                loading: false, 
                dashboardData: action.payload
            }
        case ADMIN_DASHBOARD_FAIL:
            return {loading: false, error: action.payload}
        case ADMIN_DASHBOARD_RESET:
            return {}
        default: return state
    }
}

export const listDashboardReducer = (state={dataList:[]}, action) => {
    switch(action.type){
        case LIST_DASHBOARD_REQUEST:
            return {loading: true, error: null}
        case LIST_DASHBOARD_SUCCESS:
            return {
                loading: false, 
                dataList: action.payload
            }
        case LIST_DASHBOARD_FAIL:
            return {loading: false, error: action.payload}
        case LIST_DASHBOARD_RESET:
            return {dataList:[]}
        default: return state
    }
}

export const deleteDashboardReducer = (state={}, action) => {
    switch(action.type){
        case DELETE_DASHBOARD_REQUEST:
            return {loading: true, error: null}
        case DELETE_DASHBOARD_SUCCESS:
            return {loading: false, success: true}
        case DELETE_DASHBOARD_FAIL:
            return {loading: false, error: action.payload}
        case DELETE_DASHBOARD_RESET:
            return {}
        default: return state
    }
}

export const saleDashboardReducer = (state={}, action) => {
    switch(action.type){
        case SALE_DASHBOARD_REQUEST:
            return {loading: true}
        case SALE_DASHBOARD_SUCCESS:
            return {loading: false, dashboardData: action.payload}
        case SALE_DASHBOARD_FAIL:
            return {loading: false, error: action.payload}
        default: return state
    }
}

export const agentDashboardReducer = (state={}, action) => {
    switch(action.type){
        case AGENT_DASHBOARD_REQUEST:
            return {loading: true}
        case AGENT_DASHBOARD_SUCCESS:
            return {loading: false, dashboardData: action.payload}
        case AGENT_DASHBOARD_FAIL:
            return {loading: false, error: action.payload}
        default: return state
    }
}

export const shippingChartReducer = (state={dataList:[]}, action) => {
    switch(action.type){
        case SALE_SHIPPING_CHART_REQUEST:
            return {loading: true, error: null, success: false}
        case SALE_SHIPPING_CHART_SUCCESS:
            return {
                loading: false, 
                success: true,
                dataList: action.payload
            }
        case SALE_SHIPPING_CHART_FAIL:
            return {loading: false, success: false, error: action.payload}
        case SALE_SHIPPING_CHART_RESET:
            return {dataList:[]}
        default: return state
    }
}
