import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Stack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Input,
    Box,
    Spinner,
    Text,
    Select,
    HStack,
    UnorderedList,
    ListItem,
    Spacer,
    Center,
} from '@chakra-ui/react'
import { listAgent, listSale } from '../../actions/userActions'
import { carrierList } from '../../data/carrierList'
import { getChargesList } from '../../actions/chargesActions'
import { updateDomesticShipment } from '../../actions/domestic_actions/domesticShipmentActions'
import { Error } from '../../components'
import { DOMESTICSHIPMENT_UPDATE_RESET } from '../../constants/domestic_constants/domesticShipmentConstants'

const DomesticShipmentUpdateModal = ({shipmentId, carr, totalWeight, totalAmount, awb, sale, agent, oldCharges, isPaid}) => {
    const dispatch = useDispatch()
    const [carrier, setCarrier] = useState(carr)
    const [weight, setWeight] = useState(totalWeight)
    const [amount, setAmount] = useState(totalAmount)
    const [newAwb, setNewAwb] = useState(awb)
    const [newSale, setNewSale] = useState(sale)
    const [newAgent, setNewAgent] = useState(agent)
    const [charges, setCharges] = useState(oldCharges)
    const [newChargeName, setNewChargeName] = useState('')
    const [newChargeAmount, setNewChargeAmount] = useState('')
    const [show, setShow] = useState(false)
    const {loading: loadingAgentList, error: errorAgentList, agents} = useSelector(state=>state.user.AgentList)
    const {loading: loadingSaleList, error: errorSaleList, sales} = useSelector(state=>state.user.SaleList)
    const {loading: loadingCharges, charges: chargesList, error: errorCharges } = useSelector(state=>state.charges.List)
    const {loading: loadingUpdate, error: errorUpdate, success: successUpdate} = useSelector(state=>state.domestic.ShipmentUpdate)
    useEffect(()=>{
        dispatch(listSale(''))
        dispatch(getChargesList())
        if(successUpdate){
            setShow(false)
        }

        
    },[successUpdate, dispatch])

    const handleClose = () => {
        dispatch({type:DOMESTICSHIPMENT_UPDATE_RESET})
        setShow(false)
    }

    const handleAddCharges = () => {
        if(newChargeName === '' || newChargeName === 'Select'){return}
        if(newChargeAmount === '' || newChargeAmount === 0){return}
        const existIndex = charges.map(c => c.name).indexOf(newChargeName)
        if(existIndex !== -1){return}
        const newChargeToPush = {
            name: newChargeName,
            amount: Number(newChargeAmount)
        }
        setNewChargeName('')
        setNewChargeAmount('')
        setCharges([...charges, newChargeToPush])
    }
    const handleRemoveCharges = (idx) => {
        const newCharges = charges
        //const existIndex = newCharges.map(c => c.name).indexOf(chargeName)
        newCharges.splice(idx, 1)
        setCharges([...newCharges])
    }

    const handleSubmit = () => {
        const newData = {
            shipmentId,
            carrier,
            totalWeight: weight,
            totalAmount: Math.round((charges.reduce((acc, ch) => acc + ch.amount, 0) + Number(amount))*100)/100,
            awb: newAwb,
            sale: newSale,
            agent: newAgent,
            charges,
        }
        //console.log(newData)
        dispatch(updateDomesticShipment(newData))
    }


    return (
        <>
            <Button size='sm' disabled={isPaid} w='150px' colorScheme={'purple'} onClick={()=>setShow(true)} >Update Shipment</Button>

            <Modal isOpen={show} onClose={handleClose} size='3xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <HStack>
                        <Text>Update Shipment</Text>
                        {loadingSaleList && <Spinner />}
                        {loadingAgentList && <Spinner />}
                        {loadingCharges && <Spinner />}
                        {loadingUpdate && <Spinner />}
                    </HStack>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box> */}
                    <Box>
                        {errorSaleList && <Error error={errorSaleList} />}
                        {errorAgentList && <Error error={errorAgentList} />}
                        {errorCharges && <Error error={errorCharges} />}
                        {errorUpdate && <Error error={errorUpdate} />}
                    </Box>
                    <Stack spacing={'3'}> 
                        
                        <Stack spacing={'0'}>
                            <Box>Carrier - {carr}</Box>
                            <Select placeholder='Select' size='sm' onChange={(e)=>setCarrier(e.target.value)} >
                                <option value={''}></option>
                                {carrierList.map((cl, idx)=>(
                                    <option key={idx} value={cl.value}>{cl.name}</option>
                                ))}
                            </Select>                            
                        </Stack>

                        <Stack spacing={'0'}>
                            <Box>Weight</Box>
                            <Input 
                                type={'number'}
                                size='sm'
                                value={weight}
                                onChange={e=>setWeight(e.target.value)}
                            />
                        </Stack>

                        <Stack spacing={'0'}>
                            <Box>Amount</Box>
                            <Input 
                                type={'number'}
                                size='sm'
                                value={amount}
                                onChange={e=>setAmount(e.target.value)}
                            />
                        </Stack>

                        <Stack spacing={'0'}>
                            <Box>AWB</Box>
                            <Input 
                                type={'text'}
                                size='sm'
                                value={newAwb}
                                onChange={e=>setNewAwb(e.target.value)}
                            />
                        </Stack>

                        <Stack>
                            <Box>Sales</Box>
                            <Select 
                                size='sm' 
                                placeholder={sale.saleCode + ' - ' + sale.name} 
                                onChange={e=>{
                                    setNewSale(sales[e.target.value])
                                    dispatch(listAgent(1, 500, '', sales[e.target.value]._id))
                                }}
                            >
                                {sales && sales.map((sa, idx)=>(
                                    <option key={idx} value={idx}>{sa.saleCode} - {sa.name}</option>
                                ))}
                            </Select>
                        </Stack>

                        <Stack>
                            <Box>Agent</Box>
                            <Select 
                                size='sm' 
                                placeholder='Select' 
                                // placeholder={agent.agentCode + ' - ' + agent.name} 
                                onChange={e=>setNewAgent(agents[e.target.value])}
                            >
                                {agents && agents.map((ag, idx)=>(
                                    <option key={idx} value={idx}>{ag.agentCode} - {ag.name}</option>
                                ))}
                            </Select>
                        </Stack>

                        <Stack>
                            <Box>
                                <Box>Charges</Box>
                                <HStack>
                                    <Select placeholder='Select' size='sm' onChange={(e)=>setNewChargeName(e.target.value)} >
                                        {chargesList && chargesList.map((c, idx)=>(
                                            <option key={idx} value={c.name}>{c.name}</option>
                                        ))}
                                    </Select>
                                    <Input 
                                        type='number'
                                        size='sm'
                                        w='100px'
                                        value={newChargeAmount}
                                        onChange={(e)=>setNewChargeAmount(e.target.value)}
                                    />
                                    <Button
                                        size='sm'
                                        colorScheme={'green'}
                                        onClick={handleAddCharges}
                                    >Add</Button>
                                </HStack>
                            </Box>
                            <Center>
                                <Box style={{width:'300px'}} bg={'red.100'} p={2} rounded={'md'}>
                                    <UnorderedList spacing={3}>
                                        {charges.map((ch, idx)=>(
                                            <ListItem key={idx}>
                                                <HStack>
                                                    <Box w='200px'>{ch.name}</Box>
                                                    <Box>{ch.amount}</Box>
                                                    <Spacer />
                                                    <Button
                                                        size='xs'
                                                        colorScheme={'red'}
                                                        onClick={()=>handleRemoveCharges(idx)}
                                                    >
                                                        x
                                                    </Button>
                                                </HStack>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </Center>
                        </Stack>

                    </Stack>


                </ModalBody>

                <ModalFooter>
                    <Box fontSize={'2xl'}>
                        Total Amount for Shipment - {Math.round((charges.reduce((acc, ch) => acc + ch.amount, 0) + Number(amount))*100)/100}
                    </Box>
                    <Spacer />
                    <Button size='sm' w='150px' colorScheme={'green'} onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default DomesticShipmentUpdateModal