import {
    ADDRESS_CREATE_REQUEST,
    ADDRESS_CREATE_SUCCESS,
    ADDRESS_CREATE_FAIL,
    ADDRESS_CREATE_RESET,
    DHL_ADDRESS_VALIDATE_REQUEST,
    DHL_ADDRESS_VALIDATE_SUCCESS,
    DHL_ADDRESS_VALIDATE_FAIL,
    DHL_ADDRESS_VALIDATE_RESET,
    ADDRESS_LIST_REQUEST,
    ADDRESS_LIST_SUCCESS,
    ADDRESS_LIST_FAIL,
    ADDRESS_LIST_RESET,
    ADDRESS_BYID_REQUEST,
    ADDRESS_BYID_SUCCESS,
    ADDRESS_BYID_FAIL,
    ADDRESS_BYID_RESET,
    ADDRESS_EDIT_REQUEST,
    ADDRESS_EDIT_SUCCESS,
    ADDRESS_EDIT_FAIL,
    ADDRESS_EDIT_RESET,
    ADDRESS_DELETE_REQUEST,
    ADDRESS_DELETE_SUCCESS,
    ADDRESS_DELETE_FAIL,
    ADDRESS_DELETE_RESET,
    ADDRESS_FROM_REQUEST,
    ADDRESS_FROM_SUCCESS,
    ADDRESS_FROM_FAIL,
    ADDRESS_FROM_RESET,
    ADDRESS_FROM_NAME_REQUEST,
    ADDRESS_FROM_NAME_SUCCESS,
    ADDRESS_FROM_NAME_FAIL,
    ADDRESS_FROM_NAME_RESET,
} from '../constants/addressConstants'

export const addressCreateReducer = (state={}, action) => {
    switch(action.type){
        case ADDRESS_CREATE_REQUEST:
            return {loading: true}
        case ADDRESS_CREATE_SUCCESS:
            return {loading: false, success: true, newAddress: action.payload}
        case ADDRESS_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case ADDRESS_CREATE_RESET:
            return {...state, success: false}
        default: return state
    }
}

export const addressListReducer = (state={addresses:[]}, action) => {
    switch(action.type){
        case ADDRESS_LIST_REQUEST:
            return {loading: true}
        case ADDRESS_LIST_SUCCESS:
            return {
                loading: false, 
                addresses: action.payload.addresses,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case ADDRESS_LIST_FAIL:
            return {loading: false, error: action.payload}
        case ADDRESS_LIST_RESET:
            return {}
        default: return state
    }
}

export const addressEditReducer = (state={}, action) => {
    switch(action.type){
        case ADDRESS_EDIT_REQUEST:
            return {loading: true}
        case ADDRESS_EDIT_SUCCESS:
            return {loading: false, success: true}
        case ADDRESS_EDIT_FAIL:
            return {loading: false, error: action.payload}
        case ADDRESS_EDIT_RESET:
            return {}
        default: return state
    }
}


export const addressDeleteReducer = (state={}, action) => {
    switch(action.type){
        case ADDRESS_DELETE_REQUEST:
            return {loading: true}
        case ADDRESS_DELETE_SUCCESS:
            return {loading: false, success: true}
        case ADDRESS_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case ADDRESS_DELETE_RESET:
            return {}
        default: return state
    }
}

export const addressByIdReducer = (state={}, action) => {
    switch(action.type){
        case ADDRESS_BYID_REQUEST:
            return {loading: true}
        case ADDRESS_BYID_SUCCESS:
            return {loading: false, addressDetail: action.payload,
            }
        case ADDRESS_BYID_FAIL:
            return {loading: false, error: action.payload}
        case ADDRESS_BYID_RESET:
            return {}
        default: return state
    }
}

export const addressFromReducer = (state={addresses:[]}, action) => {
    switch(action.type){
        case ADDRESS_FROM_REQUEST:
            return {loading: true}
        case ADDRESS_FROM_SUCCESS:
            return {
                loading: false, 
                addresses: action.payload,
            }
        case ADDRESS_FROM_FAIL:
            return {loading: false, error: action.payload}
        case ADDRESS_FROM_RESET:
            return {}
        default: return state
    }
}

export const addressFromNamesReducer = (state={fromNames:[]}, action) => {
    switch(action.type){
        case ADDRESS_FROM_NAME_REQUEST:
            return {loading: true}
        case ADDRESS_FROM_NAME_SUCCESS:
            return {
                loading: false, 
                fromNames: action.payload,
            }
        case ADDRESS_FROM_NAME_FAIL:
            return {loading: false, error: action.payload}
        case ADDRESS_FROM_NAME_RESET:
            return {}
        default: return state
    }
}

export const DHLAddressValidateReducer = (state={data:null}, action) => {
    switch(action.type){
        case DHL_ADDRESS_VALIDATE_REQUEST:
            return {loading: true}
        case DHL_ADDRESS_VALIDATE_SUCCESS:
            return {loading: false, data: action.payload}
        case DHL_ADDRESS_VALIDATE_FAIL:
            return {loading: false, error: action.payload}
        case DHL_ADDRESS_VALIDATE_RESET:
            return {}
        default: return state
    }
}