import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useParams, useLocation } from 'react-router-dom'
import SearchBox from '../../components/SearchBox'
import Pagination from '../../components/Pagination'
import Error from '../../components/Error'
import Filter from '../../components/Filter'
import {
  HStack,
  Stack,
  Box,
  Spinner,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  SimpleGrid,
  GridItem,
  useBreakpointValue,
  Center,
  Heading,
  Flex,
} from '@chakra-ui/react'
import { listShipments,filterShipments } from '../../actions/domestic_actions/domesticShipmentActions'
import { DOMESTICSHIPMENT_LIST_RESET } from '../../constants/domestic_constants/domesticShipmentConstants'
import { MdCheckCircle, MdCancel } from 'react-icons/md'


const DomesticShipmentListScreen = () => {
  
  const headColSpan = useBreakpointValue({base: 2, md: 1})
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const keyword = params.keyword || ''
  const pageNumber = params.pageNumber || 1
  const [itemCount, setItemCount] = useState(50)

  const {loading, error, shipments, pages, page} = useSelector(state=>state.domestic.ShipmentList)


  useEffect(()=>{
    dispatch(listShipments(pageNumber, itemCount, keyword))
    return()=>{
      dispatch({type: DOMESTICSHIPMENT_LIST_RESET})
    }
  },[dispatch,  pageNumber, itemCount, keyword])
 

  return (
    <Box mt='3'>
      <Center><Heading my='2'>Domestic Shipments</Heading></Center>


      <SimpleGrid columns={2} columnGap={3} w='full' mb={3}>
        <GridItem colSpan={headColSpan} mb='4'>
          <Box>
            <Stack spacing={'0'}>
              <SearchBox width={'full'} />
              <Box fontSize='13px' color='gray'>search: tracking, precode, receiver, address</Box>
            </Stack>
          </Box>
        </GridItem>

        <GridItem colSpan={headColSpan} ml={{base: 0, md: 'auto'}}>
          <HStack>
            <Box>
              <Select placeholder='Select' onChange={e=>setItemCount(e.target.value)} size='sm'>
                {[50, 100, 150, 200].map((v,i)=>(
                  <option key={i} value={v}>{v}</option>
                ))}
              </Select>
            </Box>
            <Box>
              <Pagination keyword = {keyword} pages={pages} page={page} path='domesticshipment' />
            </Box>
          </HStack>
        </GridItem>
      </SimpleGrid>

        

       <Filter filterFunction={filterShipments} scanShow={false} />

        <Box my={'2'}>      
          {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}          
          {error && <Error error={error} />}         
        </Box>


        <Box display={['none', 'none', 'flex', 'flex']}>
          <Table size='sm' variant='striped' colorScheme='red' my='4'>
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>Tracking</Th>
                <Th>PreId</Th>
                <Th>Agent</Th>
                <Th>Ship Date</Th>
                <Th>Receiver</Th>
                <Th>Carrier</Th>
                <Th>Weight</Th>
                {/* <Th>Amount</Th> */}
                <Th>Invoiced</Th>
                <Th>Options</Th>
              </Tr>
            </Thead>
            <Tbody>
              {shipments && shipments.map((sm,index)=>(
                <Tr key={index}>
                  <Td>{pageNumber === 1 ? (index+1) : (((Number(pageNumber)-1) * itemCount) + (index+1))}</Td>
                  <Td>{sm.awb}</Td>
                  <Td>{sm.preCode}</Td>
                  <Td>{sm.agent.name}</Td>
                  <Td>{sm.createdAt.substring(0,10)}</Td>
                  <Td>{sm.receiver.name} - {sm.receiver.address.province}</Td>
                  {/* <Td>{sm.carrier.toUpperCase()}</Td> */}
                  <Td m={0} p={0}><Box><img style={{height: '40px', objectFit: 'contain'}} src={`/${sm.carrier}.png`} alt='carrier-logo' /></Box></Td>
                  <Td>{sm.totalWeight}</Td>
                  {/* <Td>{sm.totalAmount.toLocaleString()}</Td> */}
                  {/* <Td>{new Date(sm.createdAt).toLocaleString('en', { hour12: true, timeZone: 'UTC' })}</Td> */}
                  <Td>
                      {sm.isInvoiced ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}                  
                  </Td>
                  <Td>
                    <Link to={`/domesticshipment/${sm._id}`} state={{redirect: location.pathname}}>Detail</Link>       
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>  
        </Box>

        <Box display={['flex', 'flex', 'none', 'none']}>
          <Table size='sm' variant='striped' colorScheme='red' my='4'>
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>Summary</Th>
                <Th>Options</Th>
              </Tr>
            </Thead>
            <Tbody>
              {shipments && shipments.map((sm,index)=>(
                <Tr key={index}>
                  <Td>{pageNumber === 1 ? (index+1) : (((Number(pageNumber)-1) * itemCount) + (index+1))}</Td>
                  <Td>
                    <Stack spacing={0}>
                      <Box>Tracking - {sm.awb}</Box>
                      <Box>PreID - {sm.preCode}</Box>
                      <Box>Agent - {sm.agent.name}</Box>
                      <Box>Ship Date - {sm.createdAt.substring(0,10)}</Box>
                      <Box>Receiver - {sm.receiver.name}</Box>
                      <Box>Type - {sm.shipType}</Box>
                      <Box>Weight - {sm.totalWeight}</Box>
                      <Flex>Invoiced - {sm.isInvocied ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Flex>
                    </Stack>
                  
                  </Td>
                  <Td>
                    <Link to={`/domesticshipment/${sm._id}`} state={{redirect: location.pathname}}>Detail</Link>       
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
    </Box>
  )
}

export default DomesticShipmentListScreen