import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    SimpleGrid,
    HStack,
    Spacer,
    Text,
    Button,
    Input,
    Stack,
    Heading,
    Center,
    Select,
    Checkbox,
    Flex,
    useToast,
    GridItem,
    Divider,
} from '@chakra-ui/react'
import { MdWifiProtectedSetup, MdEditNote } from 'react-icons/md'
import { listAgent } from '../../actions/userActions'
import { eidtAgentReceiverPreShipment, addNotePreShipment, updateCurrencyPreshipment, markVplus, makeSepcialRoute, updateShipType, saveDhlShipperPreshipment } from '../../actions/shipmentActions'
import { currencies } from '../../data/currencies'
import Error from '../../components/Error'
import { PRESHIPMENT_SHIPTYPE_RESET, PRESHIPMENT_VPLUS_RESET } from '../../constants/shipmentConstants'
import { shipTypes } from '../../data/shipTypes'
import MySpinner from '../../components/MySpinner'

const EditDetail = ({preId, preshipment}) => {
    const dispatch = useDispatch()
    const toast = useToast()
    const [agentId, setAgentId] = useState('')
    const [shipper, setShipper] = useState(preshipment?.shipper ?? '')
    const [agentChange, setAgentChange] = useState(false)
    const [addressUpdate, setAddressUpdate] = useState(false)
    const [agentSearchKeyword, setAgentSearchKeyword] = useState('')    
    const [receiver, setReceiver] = useState({})
    const [isVplus, setIsVplus] = useState(preshipment && preshipment.isVplus)
    const [isSpecialRoute, setIsSpecialRoute] = useState(preshipment && preshipment.isSpecialRoute)
    const [newNote, setNewNote] = useState('')
    const {agents} = useSelector(state=>state.user.AgentList)
    const {error, success} = useSelector(state=>state.preshipment.Edit)
    const {error:errorCurrency} = useSelector(state=>state.preshipment.Currency)
    const {loading: loadingVplus, error: errorVplus, success: successVplus} = useSelector(state=>state.preshipment.Vplus)
    const {loading: loadingSpecialRoute, error: errorSpecialRoute, success: successSpecialRoute} = useSelector(state=>state.preshipment.SpecialRoute)
    const {loading: loadingShipType, error: errorShipType, success: successShipType} = useSelector(state=>state.preshipment.ShipType)
    const {loading: loadingSaveShipper, error: errorSaveShipper, success: successSaveShipper} = useSelector(state=>state.preshipment.SaveDhlShipper)

    useEffect(()=>{
        if(success){
            setAgentChange(false)
            setAddressUpdate(false)
        } 
        if(successVplus){
          toast({
            title: 'Successfully updated vplus status.',
            position: 'top',
            status: 'success',
            duration: 1000,
            isClosable: true,
          })
        }
        if(successSpecialRoute){
          toast({
            title: 'Special Route updated.',
            position: 'top',
            status: 'success',
            duration: 1000,
            isClosable: true,
          })
        }
        if(successShipType){
          toast({
            title: 'Successfully updated ship type.',
            position: 'top',
            status: 'success',
            duration: 1000,
            isClosable: true,
          })
        }
        if(successSaveShipper){
          toast({
            title: 'Successfully updated shipper.',
            position: 'top',
            status: 'success',
            duration: 1000,
            isClosable: true,
          })
        }
        return()=>{
          dispatch({type: PRESHIPMENT_VPLUS_RESET})
          dispatch({type: PRESHIPMENT_SHIPTYPE_RESET})
        }
    },[success, successVplus, successSpecialRoute, successShipType, successSaveShipper, toast, dispatch])

    const handleSearchAgent = (e) => {
        if(e.key === 'Enter'){
            dispatch(listAgent(1, '', agentSearchKeyword, ''))
        }
        return
    }
  
    const handleAgentEdit = () => {
        setAgentChange(true)
        setAgentId(preshipment.agentId._id)
      }
      const handleAddressEdit = () => {
        setAddressUpdate(true)
        setReceiver(preshipment.addressId)
    }

    const handleChangeCurrency = (e) => {
      const currencyData = {
        preId: preshipment._id,
        currency : e.target.value
      }
      dispatch(updateCurrencyPreshipment(currencyData))
      //console.log(currencyData)
    }
  
    const handleEditDetail = () => {
        let data = {}
        if(agentChange){
          data['agent'] = agentId
        }
        if(addressUpdate){
          data['addressId'] = preshipment.addressId._id
          data['updatedAddress'] = receiver
        }
        //console.log(data)
        dispatch(eidtAgentReceiverPreShipment(preId, data))
    }
  
    const handleSaveNote = () => {
        const noteData = {
          preId: preshipment._id,
          noteText: newNote
  
        }
        dispatch(addNotePreShipment(noteData))
    }

  
    return (
        <Box w='700px' mx='auto'>
        {error && <Error error={error} />}
        {errorCurrency && <Error error={errorCurrency} />}
        {errorVplus && <Error error={errorVplus} /> }
        {errorSpecialRoute && <Error error={errorSpecialRoute} /> }
        {errorShipType && <Error error={errorShipType} /> }
        {errorSaveShipper && <Error error={errorSaveShipper} />}
        {preshipment &&
          <Box mb={4} w={'100%'}>
            <Text>Shipper</Text>
            <Box display="flex">
              <Box flex={1} me={3}>
                <Input 
                  id={'shipper'}
                  type={'text'}
                  size='sm'
                  autoFocus
                  border='1px'
                  borderColor="gray.600"
                  borderRadius={5}
                  value={shipper}
                  onChange={({target})=>{
                    setShipper(target.value)
                  }}
                />
              </Box>
              <Box w={'120px'}>
                <Button
                  size='sm'
                  colorScheme='blue'
                  isLoading={loadingSaveShipper}
                  onClick={()=>{
                    dispatch(saveDhlShipperPreshipment({id: preshipment._id, shipper: shipper}))
                  }}
                >Save Shipper</Button>
              </Box>
            </Box>
          </Box>
        }
        {preshipment && 
          <SimpleGrid columns={2} columnGap={8} rowGap={2} mb='5'>            
            <GridItem colSpan={{base: 2, md: 1}}>
              <Box border='solid 1px gray' borderRadius={10} p='2'>
                <Flex>
                  <Checkbox
                    mb='3'
                    size='lg'
                    w='100%'
                    colorScheme={'red'}
                    isChecked={isVplus}
                    onChange={e=>{setIsVplus(e.target.checked); dispatch(markVplus(preId, e.target.checked))}}
                  >Mark as VPlus</Checkbox>
                  {loadingVplus  && <MySpinner />}
                </Flex>   
                <Divider /> 
                <Flex>
                  <Checkbox
                    mt='3'
                    size='lg'
                    w='100%'
                    colorScheme={'purple'}
                    isChecked={isSpecialRoute}
                    onChange={e=>{setIsSpecialRoute(e.target.checked); dispatch(makeSepcialRoute(preId, e.target.checked))}}
                  >Special Route</Checkbox>
                  {loadingSpecialRoute  && <MySpinner />}
                </Flex>              
              </Box>
            </GridItem>
            
            <GridItem colSpan={{base: 2, md: 1}}>
              <Flex>
                <Select placeholder='Update Ship Type' size='lg' borderRadius={10} disabled={preshipment.shipType === 'dhl'} onChange={(e)=>dispatch(updateShipType({preId: preshipment._id, shipType: e.target.value}))}>
                  {shipTypes.map((st, idx)=>(
                    <option key={idx} value={st.code} disabled={preshipment.shipType !== 'dhl' && st.code === 'dhl'}>{st.name}</option>
                  ))}
                </Select>
                {loadingShipType && <MySpinner />}
              </Flex>
            </GridItem>
          </SimpleGrid>
        }
        {preshipment && 
          <SimpleGrid columns={2} spacing={10}>
            <Box bg='yellow.100' p='2' shadow={'lg'}>
              <HStack>
                <Box fontSize='lg'>Agent</Box>
                <Spacer />
                {agentChange ? <Button size='sm' leftIcon={<MdWifiProtectedSetup />} onClick={()=>setAgentChange(false)}>Cancel</Button> : 
                <Button disabled={preshipment.shipped} size='sm' leftIcon={<MdWifiProtectedSetup />} colorScheme={'blue'} onClick={()=>handleAgentEdit()}>Change</Button>
                }
              </HStack>
              <Box my='2' borderBottom={'1px'}></Box>
              <Text>Name - {preshipment.agentId.name}</Text>
              <Text>Code - {preshipment.agentId.agentCode}</Text>
            </Box>
            <Box bg='yellow.100' p='2' shadow={'lg'}>
              <HStack>
                <Box fontSize='lg'>Receiver Address</Box>
                <Spacer />
                {addressUpdate ? <Button size='sm' leftIcon={<MdEditNote />} onClick={()=>setAddressUpdate(false)}>Cancel</Button> : 
                <Button disabled={preshipment.shipped} size='sm' leftIcon={<MdEditNote />} colorScheme={'blue'} onClick={()=>handleAddressEdit()}>Edit</Button>
                }
              </HStack>
              <Box my='2' borderBottom={'1px'}></Box>
              <Text>{preshipment.addressId.name}</Text>
              <Text>{preshipment.addressId.company}</Text>
              <Text>{preshipment.addressId.address}</Text>
              <Text>{preshipment.addressId.address2}</Text>
              <Text>{preshipment.addressId.address3}</Text>
              <Text>{preshipment.addressId.city} {preshipment.addressId.stateProvince} {preshipment.addressId.country}</Text>  
              <Text>{preshipment.addressId.postalcode}</Text>            
              <Text>{preshipment.addressId.email}</Text>
              <Text>{preshipment.addressId.phone}</Text>
            </Box>

            <Box bg='yellow.100' p='2' shadow={'lg'}>
              <Stack>
                <HStack>
                  <Box>Note</Box>
                  <Spacer />
                  <Button disabled={preshipment.shipped} size='sm' leftIcon={<MdEditNote />} colorScheme={'blue'} onClick={()=>handleSaveNote()}>Save</Button>
                </HStack>
                
                <Box my='2' borderBottom={'1px'}></Box>
                <Box>
                  <Input 
                    as='textarea'
                    bg='white'
                    h='200px'
                    defaultValue={preshipment.note}
                    onChange={e=>setNewNote(e.target.value)}
                  />
                </Box>
              </Stack>
            </Box>


            <Box bg='yellow.100' p='2' shadow={'lg'}>
              <Box>Currency</Box>                
              <Box my='2' borderBottom={'1px'}></Box>

              <Heading mb='30px'>{preshipment.currency}</Heading>
              <Box >
                  <Box mb='2'>Change currency</Box>
                  <Select disabled={preshipment.shipped} bg='white' size='sm' placeholder='Currency List' onChange={handleChangeCurrency}>
                    {currencies.map((c, idx)=>(
                        <option key={idx} value={c.code}>{c.code} - {c.name}</option>
                    ))}
                  </Select>
              </Box>
            </Box>
          </SimpleGrid>

          
        }

        <Box h='30px'></Box>
        {agentChange &&         
          <Stack spacing={'3'}>
            <Center><Heading>Agent</Heading></Center>
            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>Search Agent</Box>
              <Input 
                type='text'
                size='sm'
                placeholder='Search Agent'
                value={agentSearchKeyword}
                onChange={e=> setAgentSearchKeyword(e.target.value)}
                onKeyPress={handleSearchAgent}
              />
            </Stack>
            <Stack spacing={'0'}>
              <Box fontSize='14px'>Agent List</Box>
              <Select size='sm' placeholder='Agent List' onChange={e=>setAgentId(e.target.value)}>
                {agents && agents.map((ag, idx)=>(
                    <option key={idx} value={ag._id}>{ag.name} - {ag.agentCode}</option>
                ))}
              </Select>
            </Stack>
          </Stack>
        }
        <Box h='30px'></Box>
        {addressUpdate &&
          <Stack spacing={'3'}>
            <Center><Heading>Address</Heading></Center>
            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>Name</Box>
                <Input 
                  type='text'
                  size='sm'
                  defaultValue={receiver.name}
                  onChange={e=>setReceiver({...receiver, name: e.target.value})}
                />
            </Stack>

            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>Company</Box>
                <Input 
                  type='text'
                  size='sm'
                  defaultValue={receiver.company}
                  onChange={e=>setReceiver({...receiver, company: e.target.value})}
                />
            </Stack>

            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>Address</Box>
                <Input 
                  type='text'
                  size='sm'
                  defaultValue={receiver.address}
                  onChange={e=>setReceiver({...receiver, address: e.target.value})}
                />
            </Stack>
            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>Address 2</Box>
                <Input 
                  type='text'
                  size='sm'
                  defaultValue={receiver.address2}
                  onChange={e=>setReceiver({...receiver, address2: e.target.value})}
                />
            </Stack>
            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>Address 3</Box>
                <Input 
                  type='text'
                  size='sm'
                  defaultValue={receiver.address3}
                  onChange={e=>setReceiver({...receiver, address3: e.target.value})}
                />
            </Stack>
            
            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>City</Box>
                <Input 
                  type='text'
                  size='sm'
                  defaultValue={receiver.city}
                  onChange={e=>setReceiver({...receiver, city: e.target.value})}
                />
            </Stack>
            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>State/Province</Box>
                <Input 
                  type='text'
                  size='sm'
                  defaultValue={receiver.stateProvince}
                  onChange={e=>setReceiver({...receiver, stateProvince: e.target.value})}
                />
            </Stack>
            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>Postal Code</Box>
                <Input 
                  type='text'
                  size='sm'
                  defaultValue={receiver.postalcode}
                  onChange={e=>setReceiver({...receiver, postalcode: e.target.value})}
                />
            </Stack>
            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>Country</Box>
                <Input 
                  type='text'
                  size='sm'
                  readOnly
                  defaultValue={receiver.country}
                />
            </Stack>
            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>Email</Box>
                <Input 
                  type='text'
                  size='sm'
                  defaultValue={receiver.email}
                  onChange={e=>setReceiver({...receiver, email: e.target.value})}
                />
            </Stack>
            <Stack spacing={'0'}>
              <Box fontSize={'14px'}>Phone</Box>
                <Input 
                  type='text'
                  size='sm'
                  defaultValue={receiver.phone}
                  onChange={e=>setReceiver({...receiver, phone: e.target.value})}
                />
            </Stack>
          </Stack> 
        }

        {(addressUpdate || agentChange) && 
        <Center my='5'>
          <Button disabled={preshipment.shipped} size='sm' w='300px' colorScheme={'green'} onClick={()=>handleEditDetail()}>Save</Button>
        </Center>}
    </Box>
    )
}

export default EditDetail