import axios from 'axios'
import {
    ITEM_CREATE_REQUEST,
    ITEM_CREATE_SUCCESS,
    ITEM_CREATE_FAIL,
    ITEM_LIST_REQUEST,
    ITEM_LIST_SUCCESS,
    ITEM_LIST_FAIL,
    ITEM_BYID_REQUEST,
    ITEM_BYID_SUCCESS,
    ITEM_BYID_FAIL,
    ITEM_EDIT_REQUEST,
    ITEM_EDIT_SUCCESS,
    ITEM_EDIT_FAIL,
    ITEM_DELETE_REQUEST,
    ITEM_DELETE_SUCCESS,
    ITEM_DELETE_FAIL,
} from '../constants/itemConstants'


export const createItem = (newItem) => async (dispatch, getState) => {
    try {
        dispatch({type: ITEM_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/items`, newItem, config)
        dispatch({type: ITEM_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ITEM_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listItem = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: ITEM_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/items?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: ITEM_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ITEM_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const getByIdItem = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: ITEM_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/items/${id}`, config)
        dispatch({type: ITEM_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ITEM_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const editItem = (id, editedItem) => async (dispatch, getState) => {
    try {
        dispatch({type: ITEM_EDIT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.put(`/api/items/${id}`, editedItem, config)
        dispatch({type: ITEM_EDIT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ITEM_EDIT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deleteItem = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: ITEM_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/items/${id}`, config)
        dispatch({type: ITEM_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: ITEM_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}
