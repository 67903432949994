
export const CLEAR_DATA_REQUEST = 'CLEAR_DATA_REQUEST'
export const CLEAR_DATA_SUCCESS = 'CLEAR_DATA_SUCCESS'
export const CLEAR_DATA_FAIL = 'CLEAR_DATA_FAIL'
export const CLEAR_DATA_RESET = 'CLEAR_DATA_RESET'

export const BALANCE_LOG_REQUEST = 'BALANCE_LOG_REQUEST'
export const BALANCE_LOG_SUCCESS = 'BALANCE_LOG_SUCCESS'
export const BALANCE_LOG_FAIL = 'BALANCE_LOG_FAIL'
export const BALANCE_LOG_RESET = 'BALANCE_LOG_RESET'

export const USER_LOG_REQUEST = 'USER_LOG_REQUEST'
export const USER_LOG_SUCCESS = 'USER_LOG_SUCCESS'
export const USER_LOG_FAIL = 'USER_LOG_FAIL'
export const USER_LOG_RESET = 'USER_LOG_RESET'


export const GET_DATA_REQUEST = 'GET_DATA_REQUEST'
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS'
export const GET_DATA_FAIL = 'GET_DATA_FAIL'
export const GET_DATA_RESET = 'GET_DATA_RESET'

export const REMOTE_AREA_CHECK_REQUEST = 'REMOTE_AREA_CHECK_REQUEST'
export const REMOTE_AREA_CHECK_SUCCESS = 'REMOTE_AREA_CHECK_SUCCESS'
export const REMOTE_AREA_CHECK_FAIL = 'REMOTE_AREA_CHECK_FAIL'
export const REMOTE_AREA_CHECK_RESET = 'REMOTE_AREA_CHECK_RESET'

export const SET_REMOTE_RATE_REQUEST = 'SET_REMOTE_RATE_REQUEST'
export const SET_REMOTE_RATE_SUCCESS = 'SET_REMOTE_RATE_SUCCESS'
export const SET_REMOTE_RATE_FAIL = 'SET_REMOTE_RATE_FAIL'
export const SET_REMOTE_RATE_RESET = 'SET_REMOTE_RATE_RESET'

export const GET_REMOTE_RATE_REQUEST = 'GET_REMOTE_RATE_REQUEST'
export const GET_REMOTE_RATE_SUCCESS = 'GET_REMOTE_RATE_SUCCESS'
export const GET_REMOTE_RATE_FAIL = 'GET_REMOTE_RATE_FAIL'
export const GET_REMOTE_RATE_RESET = 'GET_REMOTE_RATE_RESET'