import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import SearchBox from '../../components/SearchBox'
import {
  HStack,
  Box,
  Spacer,
  Spinner,
  Divider,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
  Heading,
} from '@chakra-ui/react'

import { myAgents } from '../../actions/userActions'

const SaleAgentListScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const keyword = params.keyword || ''
    const {loading, error, agents} = useSelector(state=>state.user.MyAgents)
    useEffect(()=>{
        dispatch(myAgents(keyword))        
    },[dispatch, keyword])

    return (
        <Box>
            <Center mb='3'><Heading>My Agents</Heading></Center>
            <Divider />
            <HStack spacing='4' my='4'>
            <Box>
                {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
            </Box>
            <Box>
                <SearchBox />
            </Box>
            <Spacer />
            </HStack>
            
            <Divider />

            {error && 
            <Alert status='error' variant='left-accent'>
            <AlertIcon />
            <AlertTitle mr={2}>Error getting user list</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
            </Alert>
            }

            {agents && agents.length === 0 ? 
                <Alert status='info' variant='left-accent'>
                <AlertIcon />
                <AlertTitle mr={2}>No user found</AlertTitle>
                </Alert>    
                :
                <Table size='sm' variant='striped' colorScheme='yellow' my='4'>
                    <Thead>
                        <Tr>
                        <Th>No</Th>
                        <Th>Code</Th>
                        <Th>Name</Th>
                        {/* <Th>Email</Th> */}
                        <Th>Rate</Th>
                        <Th>Next Rate</Th>
                        <Th>Balance</Th>
                        <Th>Options</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {agents && agents.map((user,index)=>(
                        <Tr key={index}>
                            <Td>{index+1}</Td>
                            <Td>{user.agentCode}</Td>
                            <Td>{user.name}</Td>
                            {/* <Td>{user.email}</Td> */}
                            <Td textDecor={'underline'} _hover={{cursor:'pointer'}} onClick={()=>navigate(`/rate/${user.rate._id}`)}>{user.rate.name}</Td>
                            {user.nextRate ? 
                            <Td textDecor={'underline'} _hover={{cursor:'pointer'}} onClick={()=>navigate(`/rate/${user.nextRate?._id ?? ''}`)}>{user.nextRate?.name ?? ''}</Td>
                            :
                            <Td></Td>
                            }
                            <Td>{user.balance}</Td>
                            <Td>
                            <Link to={`/myagents/${user._id}`} state={{redirect: location.pathname}}>
                                <Button size='sm' colorScheme={'green'} >Details</Button>
                            </Link>
                            </Td>
                        </Tr>
                        ))}
                    </Tbody>
                </Table>
            }
        </Box>
    )
}

export default SaleAgentListScreen