import axios from 'axios'
import {
    THPRATE_CREATE_FAIL,
    THPRATE_CREATE_REQUEST,
    THPRATE_CREATE_SUCCESS,
    THPRATE_DELETE_FAIL,
    THPRATE_DELETE_REQUEST,
    THPRATE_DELETE_SUCCESS,
    THPRATE_LIST_FAIL,
    THPRATE_LIST_REQUEST,
    THPRATE_LIST_SUCCESS,
} from '../../constants/thailand_post_constants/thpRateConstants'

export const createRate = (rate) => async (dispatch, getState) => {
    try {
        dispatch({type: THPRATE_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/thp/rates`, {rate}, config)
        dispatch({type: THPRATE_CREATE_SUCCESS})
    } catch (error) {
        dispatch({
            type: THPRATE_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const getRateList = () => async (dispatch, getState) => {
    try {
        dispatch({type: THPRATE_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/rates`, config)
        dispatch({type: THPRATE_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPRATE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deleteRate = (rateId) => async (dispatch, getState) => {
    try {
        dispatch({type: THPRATE_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/thp/rates/${rateId}`, config)
        dispatch({type: THPRATE_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: THPRATE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}
