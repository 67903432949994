import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { MdEmail, MdPhone, MdOpenInNew, MdContacts } from 'react-icons/md'
import {
  Box,
  Text,
  Spinner,
  Stack,
  HStack,
  Button,
  Tag,
  TagLabel,
  TagLeftIcon,
  Heading,
  Spacer,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Tbody,
  Tr,
  Td,
  Wrap,
  WrapItem,
  Thead,
  Th,
  Center,
} from '@chakra-ui/react'
import { myAgentById } from '../../actions/userActions'

const SaleAgentDetailScreen = () => {
  const params = useParams()
  const userId = params.id
  const dispatch = useDispatch()
  const {loading, error, myagent} = useSelector( state=> state.user.MyAgentDetail)

  useEffect(()=>{
    dispatch(myAgentById(userId))   
    return()=>{
      
    } 
  },[dispatch, userId])

  const getInvId = (note) => {
    const a = note.split(' ')
    const Id = a[a.length - 1]
    return Id
  }

  
  return (
    <Box mx='auto' my='50px' w=''>
      <Stack spacing={4}>
        {loading && <Spinner label='Please wait' speed='0.1s' thickness='5px' /> }
        {error && 
          <Alert status='error' variant='left-accent'>
          <AlertIcon />
          <AlertTitle mr={2}>Error getting user details</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
          </Alert>
        }

        {myagent  && 
          <>
            <Box bg='yellow.100' p='3' borderRadius={10}>
            <HStack>
              <Heading size='lg' fontWeight={400}>{myagent.name}</Heading>
              <Spacer />
              <Heading size='lg' fontWeight={400}>{myagent.accountType}</Heading>          
            </HStack>
            </Box>

            <Box w='100%'>
              <Stack>
                <Tag variant='subtle' colorScheme='cyan'>
                  <TagLeftIcon boxSize='12px' as={MdEmail} />
                  <TagLabel>{myagent.email}</TagLabel>
                </Tag>
                <Tag variant='subtle' colorScheme='purple'>
                  <TagLeftIcon boxSize='12px' as={MdPhone} />
                  <TagLabel>{myagent.phone}</TagLabel>
                </Tag>
                {myagent.accountType === 'sale' &&
                  <Tag variant='subtle' colorScheme='orange'>
                    <TagLeftIcon boxSize='12px' as={MdContacts} />
                    <TagLabel>{myagent.saleCode}</TagLabel>
                  </Tag>
                }
              </Stack>
            </Box>

            <Box borderTop='1px'></Box>   
            
            {myagent.accountType === 'agent' &&
            <>
            <Wrap>
              <WrapItem>
                
                <Box w='500px'>
                  <Table variant='simple' size='sm'>
                    <Tbody>
                      <Tr>
                        <Td>Agent Code</Td>
                        <Td>{myagent.agentCode}</Td>
                      </Tr>
                      
                      <Tr>
                        <Td>Rate</Td>
                        <Td>
                          <HStack>
                            <Text mr='4'>{myagent.rate.name}</Text>
                            <Link to={`/rate/${myagent.rate._id}`} target='_blank'>
                              <Button size='xs' colorScheme={'orange'} rightIcon={<MdOpenInNew />}>View</Button>
                            </Link>
                          </HStack>
                        </Td>
                      </Tr>
                      {myagent.nextRate &&
                      <Tr>
                        <Td>Next Rate</Td>
                        <Td>
                          <HStack>
                            <Text mr='4'>{myagent.nextRate?.name}</Text>
                            <Link to={`/rate/${myagent.nextRate?._id}`} target='_blank'>
                              <Button size='xs' colorScheme={'orange'} rightIcon={<MdOpenInNew />}>View</Button>
                            </Link>
                          </HStack>
                        </Td>
                      </Tr>
                      }
                      
                      <Tr>
                        <Td>Created</Td>
                        <Td>{(myagent.createdAt).substring(0,10)}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>

              </WrapItem>

              <Spacer />

              <WrapItem>

                <Box w='500px'>
                  <Table variant='simple' size='sm'>
                    <Tbody>
                      <Tr>
                        <Td>Account Balance</Td>
                        <Td>
                          <HStack>
                            <Box mr='20px'>{myagent.balance}</Box>
                          </HStack>
                          
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Total Shipments</Td>
                        <Td>{myagent.shipmentCount}</Td>
                      </Tr>
                      <Tr>
                        <Td>Total Invoices</Td>
                        <Td>{myagent.invoiceCount}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>


              </WrapItem>
            </Wrap>
             <Box borderTop='1px'></Box>   
             <Center><Heading>Balance Log</Heading></Center>
                <Box mb={5}>
                  <Table size='sm'>
                    <Thead>
                      <Tr>
                        <Th>No</Th>
                        <Th>Amount</Th>
                        <Th>Type</Th>
                        <Th>User</Th>
                        <Th>Note</Th>
                        <Th>Date</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {myagent.balanceLog.map((bl,idx)=>(
                        <Tr key={idx}>
                          <Td>{idx+1}</Td>
                          <Td>{bl.split(',')[0].split('-')[1]}</Td>
                          <Td bg={bl.split(',')[1].split('-')[1] === 'added' ? 'green.200' : bl.split(',')[1].split('-')[1] === 'deduct' ? 'red.200' : ''}>{bl.split(',')[1].split('-')[1]}</Td>
                          <Td>{bl.split(',')[2].split('-')[1]}</Td>
                          <Td>
                            {bl.split(',')[3].split('-')[1].includes('inv') ? <Link to={`/myinvoices/${getInvId(bl.split(',')[3].split('-')[1])}`} target='_blank'>
                              <Text as='u' color='blue.400'>{bl.split(',')[3].split('-')[1]}</Text>
                            </Link> : <Text>{bl.split(',')[3].split('-')[1]}</Text>}
                          </Td>
                          <Td>{bl.split(',')[4].split('-').slice(1).join('-')}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
                </>
            }
 
          </>        
        }
      </Stack>
    </Box>
  )
}

export default SaleAgentDetailScreen