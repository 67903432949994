import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { listShipCount } from '../actions/shipCountActions'
import Pagination from '../components/Pagination'
import {
    Box,
    HStack,
    Spinner,
    Stack,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Select,
    Spacer,
    Divider,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from '@chakra-ui/react'

const ShipCountScreen = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const keyword = params.keyword || ''
    const pageNumber = params.pageNumber || 1
    const [itemCount, setItemCount] = useState(50)

    const {loading, error, shipcounts, pages, page} = useSelector(state=>state.shipment.ShipCountList)

    useEffect(()=>{
        dispatch(listShipCount(pageNumber, itemCount, keyword))
    },[dispatch, pageNumber, itemCount, keyword])

    return (
        <Box mt='3'>
            <Stack>
                <HStack>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Spacer />
                    <Box>
                        <Select placeholder='Select' onChange={e=>setItemCount(e.target.value)} size='sm'>
                        {[50, 100, 150, 200].map((v,i)=>(
                            <option key={i} value={v}>{v}</option>
                        ))}
                        </Select>
                    </Box>
                    <Box>
                        <Pagination keyword = {keyword} pages={pages} page={page} path='shipcount' />
                    </Box>
                </HStack>
                <Divider />
                <Box>
                    {error && 
                        <Alert status='error' variant='left-accent'>
                        <AlertIcon />
                        <AlertTitle mr={2}>Error getting Item list</AlertTitle>
                        <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    }
                </Box>
                <Box>
                    <Table size='sm' variant='striped' colorScheme='yellow' my='4'>
                        <Thead>
                            <Tr>
                            <Th>No</Th>
                            <Th>Date</Th>
                            <Th>PreShip</Th>
                            <Th>Canceled PreShip</Th>
                            <Th>Shipped</Th>
                            <Th>Scanned</Th>
                            <Th>Canceled Shipment</Th>
                            <Th>Remark</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {shipcounts && shipcounts.map((sc, idx)=>(
                            <Tr key={idx}>
                                <Td>{pageNumber === 1 ? (idx+1) : (((Number(pageNumber)-1) * itemCount) + (idx+1))}</Td>
                                <Td>{new Date(sc.date).toLocaleDateString('en-TH', { weekday: 'long', month: 'long', day: 'numeric' })}</Td>
                                <Td>{sc.count}</Td>
                                <Td>{sc.preCancelCount}</Td>
                                <Td>{sc.shippedCount}</Td>
                                <Td>{sc.scannedCount}</Td>
                                <Td>{sc.shipmentCancelCount}</Td>
                                <Td color={((sc.preCancelCount + sc.shippedCount) - sc.count) > 0 ? 'green' : ((sc.preCancelCount + sc.shippedCount) - sc.count) < 0 ? 'red' : ''}>
                                    {((sc.preCancelCount + sc.shippedCount) - sc.count) > 0 && '+'}{(sc.preCancelCount + sc.shippedCount) - sc.count}
                                </Td>
                            </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Stack>
        </Box>
    )
}

export default ShipCountScreen