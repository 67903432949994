import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import parse from 'paste-from-excel'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    FormControl, 
    FormLabel,
    Box,
    Flex,
    Button, 
    Icon,
    Spacer, 
    useToast,
  } from '@chakra-ui/react'
import {MdSaveAlt} from 'react-icons/md'
import { createRate } from '../actions/rateActions'
import { useNavigate } from 'react-router-dom'
import { RATE_CREATE_RESET } from '../constants/rateConstants'
import Error from '../components/Error'

const RateAddScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()
    const {loading, error, success} = useSelector(state=>state.rate.Create)
    const {zone} = useSelector(state=>state.zone.Rate)
    const [name, setName] = useState('')
    const [effectDate, setEffectDate] = useState('')
    const [ratelist, setRatelist] = useState({
        rates: [
                zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,
                zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,
                zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,
                zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,zone.zObject,
            ]
    })
    const [newRatelist, setNewRatelist] = useState({
        rates: [
                zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, 
                zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, 
                zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, 
                zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, 
                zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, 
                zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject, 
                zone.zObject, zone.zObject, zone.zObject, zone.zObject, zone.zObject
            ]
    })
 
    //const zoneslist = ['zone1','zone2','zone3','zone4','zone5','zone6','zone7','zone8','zone9']
    const kglist = ['d5','d10','d15','d20','p5','p10','p20','p30','p40','p50','p60','p70','p80','p90','p100','p110','p120','p130','p140','p150','p160','p170','p180','p190','p200','p210','p220','p230','p240','p250','p260','p270','p280','p290','p300','o300']

    const newkglist = [
        'd5','d10','d15','d20',
        'p5','p10', 'p15', 'p20', 'p25','p30', 'p35', 'p40', 'p45', 'p50', 'p55', 'p60', 'p65', 'p70', 'p75', 'p80', 'p85', 'p90', 'p95', 'p100', 'p105',
        'p110', 'p115', 'p120', 'p125', 'p130', 'p135', 'p140', 'p145', 'p150', 'p155', 'p160', 'p165', 'p170', 'p175', 'p180', 'p185', 'p190', 'p195', 'p200', 'p205', 
        'p210', 'p215', 'p220', 'p225', 'p230', 'p235', 'p240', 'p245', 'p250', 'p255', 'p260', 'p265', 'p270', 'p275', 'p280', 'p285', 'p290', 'p295', 'p300','o300'
    ]

    useEffect(()=>{
        if(zone.zArray.length === 0){
            navigate('/rate')
        }
    },[zone, navigate])

    const nameforkglist = (name) => {
        const first = name.charAt(0)
        const second = name.substr(1)
        let type
        if(first === 'd'){
            type = 'D '
        } else if(first === 'p'){
            type = 'P '
        } else {
            type = 'O '
        }
        const kg = (Number(second) / 10)
        return type + kg
    }
    const handlePaste = (e) => {
        return parse(e)
    }
    const handlePasteRate = (index, z, e, i) => {
        setNewRatelist((ratelist)=>({
            ...ratelist,
            rates: ratelist.rates.map((item, i)=> index === i ? {...item, [z]: Number(e.target.value)} : item)
        }))
    }

    useEffect(()=>{
        if(success){
            toast({
                status: 'success',
                position: 'top',
                title: 'New rate saved successfully.',
                duration: 8000,
                isClosable: true,
            }) 
            navigate('/rate')
        }

        return()=>{
            dispatch({type: RATE_CREATE_RESET})
        }
    },[success, dispatch, navigate, toast])
    
    const handleSubmit = async () => {
        let newList = []
        await Promise.all(newRatelist.rates.map(async (r,idx) => {
            const item = {
                kg: newkglist[idx],
                rate: r
            }
            newList.push(item)
        }))
        var date = (new Date().toISOString()).substring(0,10)
        const newRate = {
            name: name ? name : 'New rate '+Date.now(),
            effectDate: effectDate ? effectDate : date,
            rates: newList
        }
        dispatch(createRate(newRate))
        //console.log(newRate)
    }


    return (
        <Box>
            <Box my='4'>
                <Flex>
                    <Box w='300px' mr='3'>
                        <FormControl isRequired >
                            <FormLabel>Rate Name</FormLabel>
                            <Input 
                                type='text' 
                                size='sm'
                                value={name}
                                onChange={e=>setName(e.target.value)}
                                maxLength={50}                                    
                                />
                        </FormControl>
                    </Box>
                    <Box w='300px'mr='3'>
                        <FormControl isRequired >
                            <FormLabel>Effective Date</FormLabel>
                            <Input 
                                type='date' 
                                size='sm'
                                value={effectDate}
                                onChange={e=>setEffectDate(e.target.value)}                                    
                                />
                        </FormControl>
                    </Box>  

                    <Spacer />
                    <Box mt='4' w='200px'>
                        <Button 
                            w='100%'
                            size='sm'
                            isLoading={loading}
                            colorScheme='green'
                            loadingText='Submitting'
                            rightIcon={<Icon as={MdSaveAlt} />} 
                            onClick={handleSubmit}
                            >Save
                        </Button>
                    </Box>              
                </Flex>
            </Box>
            {error && <Error error={error} />}
            {/* {zRateError && <Error error={zRateError} />}
            {zRateLoading && <Box><Spinner /> getting zone list...</Box>} */}

           
            <Box w={'100%'} overflow={'scroll'}>
                <Table size='sm' variant='striped' colorScheme='yellow' >
                    <Thead>
                        <Tr>
                        <Th textAlign='center'>KG</Th>
                        {zone.zArray.map((z,i)=>(
                            <Th key={i} textAlign='center'>{z}</Th>  
                        ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {newRatelist.rates.map((res, idx)=>(
                            <Tr key={idx}>
                                <Td textAlign='left'><Box style={{width: '70px'}}>{nameforkglist(newkglist[idx])}</Box></Td>
                                {zone.zArray.map((z, i)=>(
                                    <Td key={i} p='0.9'>
                                        <FormControl border='grey'>
                                            <Input 
                                                size='xs' 
                                                textAlign='center' 
                                                fontSize='15px' 
                                                onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault()}}}
                                                onInput={(e)=> {handlePasteRate(idx, z, e, i)}}
                                                onPaste={e=>{handlePaste(e)}}
                                                />
                                        </FormControl>                                    
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
            
            <Box h='50px'></Box>
            
        </Box>
    )
}

export default RateAddScreen