import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { 
    Box, 
    Stack, 
    Center, 
    Avatar, 
    HStack, 
    Table, 
    Tbody, 
    Tr, 
    Td,
} from '@chakra-ui/react'
import UserChangePasswordModal from '../Modals/UserChangePasswordModal'

const MyProfileScreen = () => {
    const navigate = useNavigate()
    const {user} = useSelector(state=>state.login)

    useEffect(()=>{
        if(user.accountType === 'agent'){
            navigate('/')
        }
    },[navigate, user])

    return (
        <Box maxW='600px' minW='400px' mx='auto' mt='40px'>
            <Stack>
                <Center>
                    <HStack p='4'>
                        <Box w='200px' h='5px' my='5' bg='blackAlpha.400' />
                            <Avatar name={user.name} />
                        <Box w='200px' h='5px' mt='10px' bg='blackAlpha.400' />
                    </HStack>
                </Center>


                <Table variant='simple'>
                    <Tbody>
                        <Tr>
                            <Td w='50%' isNumeric>Name</Td>
                            <Td >{user.name}</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>Email</Td>
                            <Td>{user.email}</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>Phone</Td>
                            <Td>{user.phone}</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>Type</Td>
                            <Td>{user.accountType}</Td>
                        </Tr>
                        <Tr>
                            <Td isNumeric>Created</Td>
                            <Td>{(user.createdAt).substring(0,10)}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Stack>      
            <Center mt='50px'>
                <UserChangePasswordModal />
            </Center>    

        </Box>
    )
}

export default MyProfileScreen