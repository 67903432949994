import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import parse from 'paste-from-excel'
import {
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    FormControl, 
    FormLabel,
    Box,
    Flex,
    Button, 
    Icon,
    Spacer, 
    useToast,
    Spinner,
    Center,
    Heading,
  } from '@chakra-ui/react'
import { MdSaveAlt } from 'react-icons/md'
import { editRate, getByIdRate } from '../actions/rateActions'
import { useNavigate, useParams } from 'react-router-dom'
import { RATE_EDIT_RESET } from '../constants/rateConstants'

const RateEditScreen = () => {
    const params = useParams()
    const rateId = params.id
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()
    const {loading, error, rateDetail} = useSelector(state=>state.rate.ById)
    const [name, setName] = useState('')
    const [effectDate, setEffectDate] = useState('')
    const [ratelist, setRatelist] = useState({
        rates: [
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            ]
    })

    const {loading: editLoading, error: editError, success} = useSelector(state=>state.rate.Edit)
    
 
    const zoneslist = ['zone1','zone2','zone3','zone4','zone5','zone6','zone7','zone8','zone9']
    const kglist = ['d5','d10','d15','d20','p5','p10','p20','p30','p40','p50','p60','p70','p80','p90','p100','p110','p120','p130','p140','p150','p160','p170','p180','p190','p200','p210','p220','p230','p240','p250','p260','p270','p280','p290','p300','o300']

    const nameforkglist = (name) => {
        const first = name.charAt(0)
        const second = name.substr(1)
        let type
        if(first === 'd'){
            type = 'D '
        } else if(first === 'p'){
            type = 'P '
        } else {
            type = 'O '
        }
        const kg = (Number(second) / 10)
        return type + kg
    }
    const handlePaste = (e) => {
        return parse(e)
    }
    const handlePasteRate = (index, z, e, i) => {
        setRatelist((ratelist)=>({
            ...ratelist,
            rates: ratelist.rates.map((item, i)=> index === i ? {...item, [z]: e.target.value} : item)
        }))
    }

    useEffect(()=>{
        if(!rateDetail || rateId !== rateDetail._id){
            dispatch(getByIdRate(rateId))
        } else {
            setName(rateDetail.name)
            setEffectDate(rateDetail.effectDate.substring(0,10))
        }
        
        if(success){
            toast({
                status: 'success',
                position: 'top',
                title: 'Rate updated successfully.',
                duration: 2000,
                isClosable: true,
            }) 
            navigate(`/rate/${rateId}`)
        }

        return()=>{
            dispatch({type: RATE_EDIT_RESET})
        }
    },[success, rateDetail, rateId, dispatch, navigate, toast])
    
    const handleSubmit = async () => {
        let newList = []
        await Promise.all(ratelist.rates.map(async (r,idx) => {
            const item = {
                kg: kglist[idx],
                rate: r
            }
            newList.push(item)
        }))
        console.log(newList)
        var date = (new Date().toISOString()).substring(0,10)
        const newRate = {
            name: name ? name : 'edited rate '+Date.now(),
            effectDate: effectDate ? effectDate : date,
            rates: newList
        }
        dispatch(editRate(rateId, newRate))
        //console.log(newRate)
    }


    return (
        <Box maxW='1600px' m='auto'>
            <Box m='auto' my='4' fontSize='3xl'>{(loading || editLoading) && <Spinner label='Please wait' speed='0.1s' thickness='5px' /> }</Box>
            <Center><Heading>Update Rate - {rateDetail && rateDetail.name}</Heading></Center>
            <Box my='4'>
                <Flex>
                    <Box w='300px' mr='3'>
                        <FormControl isRequired >
                            <FormLabel>Rate Name</FormLabel>
                            <Input 
                                type='text' 
                                size='sm'
                                value={name}
                                onChange={e=>setName(e.target.value)}
                                maxLength={50}                                    
                                />
                        </FormControl>
                    </Box>
                    <Box w='300px'mr='3'>
                        <FormControl isRequired >
                            <FormLabel>Effective Date</FormLabel>
                            <Input 
                                type='date' 
                                size='sm'
                                value={effectDate}
                                onChange={e=>setEffectDate(e.target.value)}                                    
                                />
                        </FormControl>
                    </Box>  

                    <Spacer />
                    <Box mt='4' w='200px'>
                        <Button 
                            w='100%'
                            size='sm'
                            isLoading={loading}
                            colorScheme='green'
                            loadingText='Submitting'
                            rightIcon={<Icon as={MdSaveAlt} />} 
                            onClick={handleSubmit}
                            >Save
                        </Button>
                    </Box>              
                </Flex>
            </Box>
            {error && 
                <Alert status='error' variant='left-accent'>
                <AlertIcon />
                <AlertTitle mr={2}>Error getting rate detail.</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
                </Alert>
            }
            {editError && 
                <Alert status='error' variant='left-accent'>
                <AlertIcon />
                <AlertTitle mr={2}>Error saving new rate.</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
                </Alert>
            }
            <Table size='sm' variant='striped' colorScheme='yellow' >
                <Thead>
                    <Tr>
                    <Th textAlign='center'>KG</Th>
                    <Th textAlign='center'>zone1</Th>
                    <Th textAlign='center'>zone2</Th>
                    <Th textAlign='center'>zone3</Th>
                    <Th textAlign='center'>zone4</Th>
                    <Th textAlign='center'>zone5</Th>
                    <Th textAlign='center'>zone6</Th>
                    <Th textAlign='center'>zone7</Th>
                    <Th textAlign='center'>zone8</Th>
                    <Th textAlign='center'>zone9</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {ratelist.rates.map((res, idx)=>(
                        <Tr key={idx}>
                            <Td textAlign='left' w='100px'>{nameforkglist(kglist[idx])}</Td>
                            {zoneslist.map((z, i)=>(
                                <Td key={i} p='0.9'>
                                    <FormControl border='grey'>
                                        <Input 
                                            size='xs' 
                                            textAlign='center' 
                                            fontSize='15px' 
                                            onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault()}}}
                                            onInput={(e)=> {handlePasteRate(idx, z, e, i)}}
                                            onPaste={e=>{handlePaste(e)}}
                                            />
                                    </FormControl>                                    
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            <Box h='50px'></Box>
            
        </Box>
    )
}

export default RateEditScreen