import React, {useEffect} from 'react'
import { Box, Center, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import Error from '../components/Error'
import {useDispatch, useSelector} from 'react-redux'
import { getCountryList, deleteCountry } from '../actions/countryActions'
import CountryCreateModal from '../Modals/CountryCreateModal'
// import CountryEditModal from '../Modals/CountryEditModal'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'


const CountryListScreen = () => {
    const dispatch = useDispatch()

    const { loading, countries, error } = useSelector(state=>state.country.List)
    const {success: successAdd} = useSelector(state=>state.country.Create)
    const {success: successUpdate, error: errorUpdate} = useSelector(state=>state.country.Update)
    const {success: successDelete, error: errorDelete} = useSelector(state=>state.country.Delete)

    useEffect(()=>{
        dispatch(getCountryList())
    },[dispatch, successAdd, successUpdate, successDelete])

    return (
        <Center my='4'>
            <Box boxShadow={'lg'} w='700px' p='3'>
                {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                {error && <Error error={error} /> }
                {errorUpdate && <Error error={errorUpdate} /> }
                {errorDelete && <Error error={errorDelete} /> }

                <CountryCreateModal />
                

                <Box>
                  <Table variant='striped' size='sm' colorScheme='facebook' my='4'>
                      <Thead>
                          <Tr>
                          <Th>No</Th>
                          <Th>Name</Th>
                          <Th>Country Code</Th>
                          <Th>Phone Code</Th>
                          <Th>Zone</Th>
                          {/* <Th>Edit</Th> */}
                          <Th>Delete</Th>
                          </Tr>
                      </Thead>
                      <Tbody>
                          {countries && countries.map((c, idx)=>(
                          <Tr key={idx}>
                              <Td>{idx+1}</Td>
                              <Td>{c.name}</Td>
                              <Td>{c.countryCode}</Td>
                              <Td>{c.phoneCode}</Td>
                              <Td>{c.zone}</Td>
                              {/* <Td><CountryEditModal country={c} /></Td> */}
                              <Td><ConfirmDeleteModal width={'60px'} lefticon={null} id={c._id} deleteFunction={deleteCountry} /></Td>
                          </Tr>
                          ))}
                      </Tbody>
                  </Table>
                </Box>              
                
            </Box>
        </Center>
    )
}

export default CountryListScreen