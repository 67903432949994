import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import { 
    Box,
    Spinner,
    HStack,
    Stack,
    Alert,
    AlertDescription,
    AlertTitle,
    AlertIcon,
    Spacer,
    Divider,
    Button,
    Center,
    Heading,
} from '@chakra-ui/react'
import { getPaymentById, approvePayment, deletePayment } from '../actions/paymentActions'
import { PAYMENT_DELETE_RESET } from '../constants/paymentConstants'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'

const PaymentDetailScreen = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const {loading, error, payment} = useSelector(state=>state.payment.ById)
    const {loading: loadingApprove, error: errorApprove, success: successApprove} = useSelector(state=>state.payment.Approve)
    const {loading: loadingDelete, error: errorDelete, success: successDelete} = useSelector(state=>state.payment.Delete)

    useEffect(()=>{
        
        if(successDelete){ 
            if(location.state && location.state.list){
                navigate('/payment')
            } else if (location.state && location.state.invoice){
                navigate(`/invoice/${location.state.invId}`)
            } else {
                navigate('/payment')
            }
            dispatch({type: PAYMENT_DELETE_RESET})
        }
        dispatch(getPaymentById(params.id))
        return()=>{
            dispatch({type: PAYMENT_DELETE_RESET})
        }
    },[dispatch, params, successApprove, successDelete, navigate, location])

    const handleApprove = () => {
        const invoiceData = {
            approve: true,
            invoiceId: payment.invoice._id,
        }
        dispatch(approvePayment(params.id, invoiceData))
    }

    const cancelApprove = () => {
        const invoiceData = {
            approve: false,
            invoiceId: payment.invoice._id,
        }
        dispatch(approvePayment(params.id, invoiceData))
    }


    return (
        <Box>

            <HStack>
                <Box mx='auto' w='500px' mt='50px'>
                    <Center><Heading>Payment Detail</Heading></Center>
                    {loading  && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                    {loadingApprove  && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                    {loadingDelete  && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                </Box>
            </HStack>

            <Box>
                {error && 
                    <Alert status='error' variant='left-accent'>
                    <AlertIcon />
                    <AlertTitle mr={2}>Error getting payment datail.</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                    </Alert>
                }
                {errorApprove && 
                    <Alert status='error' variant='left-accent'>
                    <AlertIcon />
                    <AlertTitle mr={2}>Error payment approve.</AlertTitle>
                    <AlertDescription>{errorApprove}</AlertDescription>
                    </Alert>
                }
                {errorDelete && 
                    <Alert status='error' variant='left-accent'>
                    <AlertIcon />
                    <AlertTitle mr={2}>Error deleting payment.</AlertTitle>
                    <AlertDescription>{errorDelete}</AlertDescription>
                    </Alert>
                }
            </Box>

            {
                payment && 
                <Box mx='auto' w='500px' mt='10px' shadow={'lg'} p='3' fontSize={'lg'}>
                    <Stack>
                        <HStack>
                            <Box>Code</Box><Spacer />
                            <Box>{payment.code}</Box>
                        </HStack>
                        <Divider />
                        
                        <HStack>
                            <Box>Amount</Box><Spacer />
                            <Box>{payment.amount.toLocaleString()}</Box>
                        </HStack>
                        <Divider />
                        <HStack>
                            <Box>Method</Box><Spacer />
                            <Box>{payment.paidMethod}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Date</Box><Spacer />
                            <Box>{new Date(payment.createdAt).toLocaleString('en', { hour12: true, timeZone: 'UTC' })}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Created By</Box><Spacer />
                            <Box>{payment.createdBy.name}</Box>
                        </HStack>
                        <Box borderBottom={'1px'}></Box>

                        <HStack>
                            <Box>Agent</Box><Spacer />
                            <Box>{payment.agent.name}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Sale</Box><Spacer />
                            <Box>{payment.sale.name}</Box>
                        </HStack>
                        <Box borderBottom={'1px'}></Box>

                        <HStack>                            
                            <Box>Invoice</Box>                            
                            <Spacer />
                            <Link to={`/invoice/${payment.invoice._id}`}>
                                <Button variant={'link'} color='black' >{payment.invoice.code}</Button>
                            </Link>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Invoice Amount</Box><Spacer />
                            <Box>{payment.invoice.totalAmount.toLocaleString()}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Approved</Box><Spacer />
                            <Box>{payment.isApproved ? 'Yes' : 'No'}</Box>
                        </HStack>
                        <Divider />
                        {payment.isApproved &&
                        <>
                            <HStack>
                                <Box>Approved By</Box><Spacer />
                                <Box>{payment.approvedBy.name}</Box>
                            </HStack>
                            <Divider />

                            <HStack>
                                <Box>Approved At</Box><Spacer />
                                <Box>{new Date(payment.approvedAt).toLocaleString('en', { hour12: true, timeZone: 'UTC' })}</Box>
                            </HStack>
                            <Divider />

                        </>
                        }
                        <HStack>
                            <Box>Note</Box><Spacer />
                            <Box>{payment.note}</Box>
                        </HStack>
                        <Box borderBottom={'1px'} ></Box>

                        <HStack py='30px'>
                            {payment.isApproved ? 
                                <Button
                                    size='sm'
                                    colorScheme='red'
                                    onClick={cancelApprove}
                                >Cancel Approve</Button>
                                :
                                <Button
                                    size='sm'                                    
                                    colorScheme='yellow'
                                    onClick={handleApprove}
                                >Approve</Button>                        
                            }
                            <Spacer />
                            <ConfirmDeleteModal deleteFunction={deletePayment} id={params.id} disable={payment.isApproved} width={'100px'} lefticon={null} />

                        </HStack>
                    </Stack>
                </Box>
            }
        </Box>
    )
}

export default PaymentDetailScreen