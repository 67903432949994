import axios from 'axios'
import {
    DOMESTICPAYMENT_APPROVE_FAIL,
    DOMESTICPAYMENT_APPROVE_REQUEST,
    DOMESTICPAYMENT_APPROVE_SUCCESS,
    DOMESTICPAYMENT_BYID_FAIL,
    DOMESTICPAYMENT_BYID_REQUEST,
    DOMESTICPAYMENT_BYID_SUCCESS,
    DOMESTICPAYMENT_CREATE_FAIL,
    DOMESTICPAYMENT_CREATE_REQUEST,
    DOMESTICPAYMENT_CREATE_SUCCESS,
    DOMESTICPAYMENT_DELETE_FAIL,
    DOMESTICPAYMENT_DELETE_REQUEST,
    DOMESTICPAYMENT_DELETE_SUCCESS,
    DOMESTICPAYMENT_LIST_FAIL,
    DOMESTICPAYMENT_LIST_REQUEST, 
    DOMESTICPAYMENT_LIST_SUCCESS,
    DOMESTICPAYMENT_MY_FAIL,
    DOMESTICPAYMENT_MY_REQUEST,
    DOMESTICPAYMENT_MY_SUCCESS,
} from '../../constants/domestic_constants/domesticPaymentConstants'


export const listDomesticPayments = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICPAYMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/payments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: DOMESTICPAYMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICPAYMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const filterDomesticPayments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICPAYMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/domestic/payments/filter`, {filterData}, config)
        dispatch({type: DOMESTICPAYMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICPAYMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myDomesticPayments = (pageNumber = '', itemCount = '', keyword = '', date = '') => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICPAYMENT_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/payments/mypayments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}&date=${date}`, config)
        dispatch({type: DOMESTICPAYMENT_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICPAYMENT_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myFilterDomesticPayments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICPAYMENT_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/domestic/payments/myfilteredpayments`, {filterData}, config)
        dispatch({type: DOMESTICPAYMENT_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICPAYMENT_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const createDomesticPayment = (paymentData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICPAYMENT_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/domestic/payments`, {paymentData}, config)
        dispatch({type: DOMESTICPAYMENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICPAYMENT_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getDomesticPaymentById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICPAYMENT_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/domestic/payments/${id}`, config)
        dispatch({type: DOMESTICPAYMENT_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DOMESTICPAYMENT_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const approveDomesticPayment = (paymentId, invoiceData) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICPAYMENT_APPROVE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/domestic/payments/${paymentId}`, {invoiceData}, config)
        dispatch({type: DOMESTICPAYMENT_APPROVE_SUCCESS})
    } catch (error) {
        dispatch({
            type: DOMESTICPAYMENT_APPROVE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deleteDomesticPayment = (paymentId) => async (dispatch, getState) => {
    try {
        dispatch({type: DOMESTICPAYMENT_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/domestic/payments/${paymentId}`, config)
        dispatch({type: DOMESTICPAYMENT_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: DOMESTICPAYMENT_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}