export const DOMESTICPAYMENT_LIST_REQUEST = 'DOMESTICPAYMENT_LIST_REQUEST'
export const DOMESTICPAYMENT_LIST_SUCCESS = 'DOMESTICPAYMENT_LIST_SUCCESS'
export const DOMESTICPAYMENT_LIST_FAIL = 'DOMESTICPAYMENT_LIST_FAIL'
export const DOMESTICPAYMENT_LIST_RESET = 'DOMESTICPAYMENT_LIST_RESET'

export const DOMESTICPAYMENT_MY_REQUEST = 'DOMESTICPAYMENT_MY_REQUEST'
export const DOMESTICPAYMENT_MY_SUCCESS = 'DOMESTICPAYMENT_MY_SUCCESS'
export const DOMESTICPAYMENT_MY_FAIL = 'DOMESTICPAYMENT_MY_FAIL'
export const DOMESTICPAYMENT_MY_RESET = 'DOMESTICPAYMENT_MY_RESET'

export const DOMESTICPAYMENT_CREATE_REQUEST = 'DOMESTICPAYMENT_CREATE_REQUEST'
export const DOMESTICPAYMENT_CREATE_SUCCESS = 'DOMESTICPAYMENT_CREATE_SUCCESS'
export const DOMESTICPAYMENT_CREATE_FAIL = 'DOMESTICPAYMENT_CREATE_FAIL'
export const DOMESTICPAYMENT_CREATE_RESET = 'DOMESTICPAYMENT_CREATE_RESET'

export const DOMESTICPAYMENT_BYID_REQUEST = 'DOMESTICPAYMENT_BYID_REQUEST'
export const DOMESTICPAYMENT_BYID_SUCCESS = 'DOMESTICPAYMENT_BYID_SUCCESS'
export const DOMESTICPAYMENT_BYID_FAIL = 'DOMESTICPAYMENT_BYID_FAIL'
export const DOMESTICPAYMENT_BYID_RESET = 'DOMESTICPAYMENT_BYID_RESET'

export const DOMESTICPAYMENT_APPROVE_REQUEST = 'DOMESTICPAYMENT_APPROVE_REQUEST'
export const DOMESTICPAYMENT_APPROVE_SUCCESS = 'DOMESTICPAYMENT_APPROVE_SUCCESS'
export const DOMESTICPAYMENT_APPROVE_FAIL = 'DOMESTICPAYMENT_APPROVE_FAIL'
export const DOMESTICPAYMENT_APPROVE_RESET = 'DOMESTICPAYMENT_APPROVE_RESET'

export const DOMESTICPAYMENT_DELETE_REQUEST = 'DOMESTICPAYMENT_DELETE_REQUEST'
export const DOMESTICPAYMENT_DELETE_SUCCESS = 'DOMESTICPAYMENT_DELETE_SUCCESS'
export const DOMESTICPAYMENT_DELETE_FAIL = 'DOMESTICPAYMENT_DELETE_FAIL'
export const DOMESTICPAYMENT_DELETE_RESET = 'DOMESTICPAYMENT_DELETE_RESET'