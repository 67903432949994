import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import { 
    Box,
    Spinner,
    HStack,
    Stack,
    Spacer,
    Divider,
    Button,
    Center,
    Heading,
} from '@chakra-ui/react'
import { getTHPPaymentById, approveTHPPayment, deleteTHPPayment } from '../../../actions/thailand_post_actions/thpPaymentActions'
import { THPPAYMENT_DELETE_RESET } from '../../../constants/thailand_post_constants/thpPaymentConstants'
import ConfirmDeleteModal from '../../../components/ConfirmDeleteModal'
import Error from '../../../components/Error'

const PostPaymentDetailScreen = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()
    const {user} = useSelector(state=>state.login)
    const {loading, error, payment} = useSelector(state=>state.thailandPost.PaymentById)
    const {loading: loadingApprove, error: errorApprove, success: successApprove} = useSelector(state=>state.thailandPost.PaymentApprove)
    const {loading: loadingDelete, error: errorDelete, success: successDelete} = useSelector(state=>state.thailandPost.PaymentDelete)

    useEffect(()=>{
        if(!user.isAdmin){
            navigate('/')
        }
        if(successDelete){    
            if(location.state && location.state.list){
                navigate('/thppayments')
            } else if (location.state && location.state.invoice){
                navigate(`/thpinvoices/${location.state.invId}`)
            } else {
                navigate('/thppayments')
            }        
            dispatch({type: THPPAYMENT_DELETE_RESET})
        }
        dispatch(getTHPPaymentById(params.id))
        return()=>{
            dispatch({type: THPPAYMENT_DELETE_RESET})
        }
    },[dispatch, params, successApprove, successDelete, navigate, user, location])

    const handleApprove = () => {
        const invoiceData = {
            approve: true,
            invoiceId: payment.invoice._id,
        }
        dispatch(approveTHPPayment(params.id, invoiceData))
    }

    const cancelApprove = () => {
        const invoiceData = {
            approve: false,
            invoiceId: payment.invoice._id,
        }
        dispatch(approveTHPPayment(params.id, invoiceData))
    }


    return (
        <Box>

            <HStack>
                <Box mx='auto' w='500px' mt='50px'>
                    <Center><Heading>Payment Detail</Heading></Center>
                    {loading  && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                    {loadingApprove  && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                    {loadingDelete  && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                </Box>
            </HStack>

            <Box>
                {error && <Error error={error} /> }
                {errorApprove && <Error error={errorApprove} /> }
                {errorDelete && <Error error={errorDelete} /> }
            </Box>

            {
                payment && 
                <Box mx='auto' w='500px' mt='10px' shadow={'lg'} p='3' fontSize={'lg'}>
                    <Stack>
                        <HStack>
                            <Box>Code</Box><Spacer />
                            <Box>{payment.code}</Box>
                        </HStack>
                        <Divider />
                        
                        <HStack>
                            <Box>Amount</Box><Spacer />
                            <Box>{payment.amount.toLocaleString()}</Box>
                        </HStack>
                        <Divider />
                        <HStack>
                            <Box>Method</Box><Spacer />
                            <Box>{payment.paidMethod}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Date</Box><Spacer />
                            <Box>{new Date(payment.createdAt).toLocaleString('en', { hour12: true, timeZone: 'UTC' })}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Created By</Box><Spacer />
                            <Box>{payment.createdBy.name}</Box>
                        </HStack>
                        <Box borderBottom={'1px'}></Box>

                        <HStack>
                            <Box>Agent</Box><Spacer />
                            <Box>{payment.agent.name}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Sale</Box><Spacer />
                            <Box>{payment.sale.name}</Box>
                        </HStack>
                        <Box borderBottom={'1px'}></Box>

                        <HStack>                            
                            <Box>Invoice</Box>                            
                            <Spacer />
                            <Link to={`/thpinvoices/${payment.invoice._id}`}>
                                <Button variant={'link'} color='black' >{payment.invoice.code}</Button>
                            </Link>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Invoice Amount</Box><Spacer />
                            <Box>{payment.invoice.totalAmount.toLocaleString()}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Approved</Box><Spacer />
                            <Box>{payment.isApproved ? 'Yes' : 'No'}</Box>
                        </HStack>
                        <Divider />
                        {payment.isApproved &&
                        <>
                            <HStack>
                                <Box>Approved By</Box><Spacer />
                                <Box>{payment.approvedBy.name}</Box>
                            </HStack>
                            <Divider />

                            <HStack>
                                <Box>Approved At</Box><Spacer />
                                <Box>{new Date(payment.approvedAt).toLocaleString('en', { hour12: true, timeZone: 'UTC' })}</Box>
                            </HStack>
                            <Divider />

                        </>
                        }
                        <HStack>
                            <Box>Note</Box><Spacer />
                            <Box>{payment.note}</Box>
                        </HStack>
                        <Box borderBottom={'1px'} ></Box>

                        <HStack py='30px'>
                            {payment.isApproved ? 
                                <Button
                                    size='sm'
                                    colorScheme='red'
                                    onClick={cancelApprove}
                                >Cancel Approve</Button>
                                :
                                <Button
                                    size='sm'                                    
                                    colorScheme='yellow'
                                    onClick={handleApprove}
                                >Approve</Button>                        
                            }
                            <Spacer />
                            <ConfirmDeleteModal deleteFunction={deleteTHPPayment} id={params.id} disable={payment.isApproved} width={'100px'} lefticon={null} />

                        </HStack>
                    </Stack>
                </Box>
            }
        </Box>
    )
}

export default PostPaymentDetailScreen