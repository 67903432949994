import { 
    THPPAYMENT_APPROVE_FAIL,
    THPPAYMENT_APPROVE_REQUEST,
    THPPAYMENT_APPROVE_RESET,
    THPPAYMENT_APPROVE_SUCCESS,
    THPPAYMENT_BYID_FAIL,
    THPPAYMENT_BYID_REQUEST,
    THPPAYMENT_BYID_RESET,
    THPPAYMENT_BYID_SUCCESS,
    THPPAYMENT_CREATE_FAIL,
    THPPAYMENT_CREATE_REQUEST,
    THPPAYMENT_CREATE_RESET,
    THPPAYMENT_CREATE_SUCCESS,
    THPPAYMENT_DELETE_FAIL,
    THPPAYMENT_DELETE_REQUEST,
    THPPAYMENT_DELETE_RESET,
    THPPAYMENT_DELETE_SUCCESS,
    THPPAYMENT_LIST_FAIL,
    THPPAYMENT_LIST_REQUEST, 
    THPPAYMENT_LIST_RESET, 
    THPPAYMENT_LIST_SUCCESS,
    THPPAYMENT_MY_FAIL,
    THPPAYMENT_MY_REQUEST,
    THPPAYMENT_MY_RESET,
    THPPAYMENT_MY_SUCCESS,
} from '../../constants/thailand_post_constants/thpPaymentConstants'

export const thpPaymentListReducer = (state={payments:[]}, action) => {
    switch(action.type){
        case THPPAYMENT_LIST_REQUEST:
            return {loading: true}
        case THPPAYMENT_LIST_SUCCESS:
            return {
                loading: false, 
                payments: action.payload.payments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case THPPAYMENT_LIST_FAIL:
            return {loading: false, error: action.payload}
        case THPPAYMENT_LIST_RESET:
            return {}
        default: return state
    }
}

export const thpPaymentMyReducer = (state={payments:[]}, action) => {
    switch(action.type){
        case THPPAYMENT_MY_REQUEST:
            return {loading: true}
        case THPPAYMENT_MY_SUCCESS:
            return {
                loading: false, 
                payments: action.payload.payments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case THPPAYMENT_MY_FAIL:
            return {loading: false, error: action.payload}
        case THPPAYMENT_MY_RESET:
            return {}
        default: return state
    }
}



export const thpPaymentCreateReducer = (state={}, action) => {
    switch(action.type){
        case THPPAYMENT_CREATE_REQUEST:
            return {loading: true}
        case THPPAYMENT_CREATE_SUCCESS:
            return {loading: false, success: true}
        case THPPAYMENT_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case THPPAYMENT_CREATE_RESET:
            return {}
        default: return state
    }
}

export const thpPaymentByIdReducer = (state={}, action) => {
    switch(action.type){
        case THPPAYMENT_BYID_REQUEST:
            return {loading: true}
        case THPPAYMENT_BYID_SUCCESS:
            return {loading: false, payment: action.payload}
        case THPPAYMENT_BYID_FAIL:
            return {loading: false, error: action.payload}
        case THPPAYMENT_BYID_RESET:
            return {}
        default: return state
    }
}

export const thpPaymentApproveReducer = (state={}, action) => {
    switch(action.type){
        case THPPAYMENT_APPROVE_REQUEST:
            return {loading: true}
        case THPPAYMENT_APPROVE_SUCCESS:
            return {loading: false, success: true}
        case THPPAYMENT_APPROVE_FAIL:
            return {loading: false, error: action.payload}
        case THPPAYMENT_APPROVE_RESET:
            return {}
        default: return state
    }
}

export const thpPaymentDeleteReducer = (state={}, action) => {
    switch(action.type){
        case THPPAYMENT_DELETE_REQUEST:
            return {loading: true}
        case THPPAYMENT_DELETE_SUCCESS:
            return {loading: false, success: true}
        case THPPAYMENT_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case THPPAYMENT_DELETE_RESET:
            return {}
        default: return state
    }
}