import {
    THPRATE_CREATE_FAIL,
    THPRATE_CREATE_REQUEST,
    THPRATE_CREATE_RESET,
    THPRATE_CREATE_SUCCESS,
    THPRATE_LIST_FAIL,
    THPRATE_LIST_REQUEST,
    THPRATE_LIST_RESET,
    THPRATE_LIST_SUCCESS,
    THPRATE_DELETE_REQUEST,
    THPRATE_DELETE_SUCCESS,
    THPRATE_DELETE_FAIL,
    THPRATE_DELETE_RESET,

} from '../../constants/thailand_post_constants/thpRateConstants'

export const thpRateCreateReducer = (state={}, action) => {
    switch(action.type){
        case THPRATE_CREATE_REQUEST:
            return {loading: true}
        case THPRATE_CREATE_SUCCESS:
            return {loading: false, success: true}
        case THPRATE_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case THPRATE_CREATE_RESET:
            return {}
        default: return state
    }
}

export const thpRateListReducer = (state={rates:[]}, action) => {
    switch(action.type){
        case THPRATE_LIST_REQUEST:
            return {loading: true}
        case THPRATE_LIST_SUCCESS:
            return {loading: false, rates: action.payload}
        case THPRATE_LIST_FAIL:
            return {loading: false, error: action.payload}
        case THPRATE_LIST_RESET:
            return {}
        default: return state
    }
}

export const thpRateDeleteReducer = (state={}, action) => {
    switch(action.type){
        case THPRATE_DELETE_REQUEST:
            return {loading: true}
        case THPRATE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case THPRATE_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case THPRATE_DELETE_RESET:
            return {}
        default: return state
    }
}