import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
  HStack,
  Box,
  Spacer,
  Spinner,
  Divider,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  InputGroup,
  Input,
  FormHelperText,
} from '@chakra-ui/react'
import { listAgent } from '../actions/userActions'
import { USER_AGENTLIST_RESET } from '../constants/userConstants'

const AgentSearchModal = (props) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const itemCount = 20
    const {loading, error, pages, agents} = useSelector(state=>state.user.AgentList)
    useEffect(()=>{  
        if(show){
            dispatch(listAgent(pageNumber, itemCount))
        }             
        
        return()=>{
            dispatch({type: USER_AGENTLIST_RESET})
        }
    },[dispatch, show, pageNumber, itemCount])

    const handleClose = () => {
        dispatch({type: USER_AGENTLIST_RESET})
        setKeyword('')
        setShow(false)
    }

    const handleSelect = (i) => {
        props.agentSelected(agents[i])
        setKeyword('')
        dispatch({type: USER_AGENTLIST_RESET})
        setShow(false)
    }

    const handleSearch = (e) => {        
        if(e.key === 'Enter'){
            dispatch(listAgent(pageNumber, itemCount, keyword))
        }
        return  
    }

    return (
        <>
            <Button 
                size='sm' 
                w='150px' 
                colorScheme='yellow'
                onClick={()=>setShow(true)}
            >Search Agent</Button>
            <Modal isOpen={show} onClose={handleClose} size='xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>

                <ModalHeader mx='auto'>Agent List</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <HStack spacing='4' mb='2'>
                    <Box>
                        {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                    </Box>
                    <Spacer />
                    <Box>
                        <HStack>
                            {pages >= 3 ?
                            <>
                                <Button size='sm' bg={pageNumber === 1 ? 'gray.600' : 'gray.300'} color='black' onClick={()=>setPageNumber(1)}>1</Button>
                                <Button size='sm' bg={pageNumber === 2 ? 'gray.600' : 'gray.300'} color='black' onClick={()=>setPageNumber(2)}>2</Button>
                                <Button size='sm' bg={pageNumber === 3 ? 'gray.600' : 'gray.300'} color='black' onClick={()=>setPageNumber(3)}>3</Button>
                            </>
                            : 
                            pages === 2 &&
                            <>
                                <Button size='sm' bg={pageNumber === 1 ? 'gray.600' : 'gray.300'} color='black' onClick={()=>setPageNumber(1)}>1</Button>
                                <Button size='sm' bg={pageNumber === 2 ? 'gray.600' : 'gray.300'} color='black' onClick={()=>setPageNumber(2)}>2</Button>
                            </> 
                        }
                        </HStack>
                    </Box>
                    </HStack>

                    <FormControl my='3'>                                       
                        <InputGroup>                
                            <Input 
                                size='sm' 
                                placeholder='Search'
                                id='receiver-search' 
                                type='text' 
                                value={keyword}
                                onChange={(e)=>setKeyword(e.target.value)}
                                onKeyPress={handleSearch}
                            />
                            
                        </InputGroup>
                        <FormHelperText>Agent code or name and press enter</FormHelperText>
                    </FormControl>    
                    
                    <Divider />

                    {error && 
                        <Alert status='error' variant='left-accent'>
                        <AlertIcon />
                        <AlertTitle mr={2}>Error getting agent list</AlertTitle>
                        <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    }

                    <Table size='sm' variant='striped' colorScheme='yellow' my='4'>
                    <Thead>
                        <Tr>
                        <Th>Code</Th>
                        <Th>Name</Th>
                        <Th>Options</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {agents && agents.map((ag,index)=>(
                        <Tr key={index}>
                            <Td>{ag.agentCode}</Td>
                            <Td>{ag.name}</Td>
                            <Td>
                                <Button
                                    size={'xs'}
                                    w={'100%'}
                                    colorScheme={'green'}
                                    onClick={()=>handleSelect(index)}
                                >Select</Button>    
                            </Td>
                        </Tr>
                        ))}
                    </Tbody>
                    </Table>
            </ModalBody>
            </ModalContent>
            </Modal>
        </>
    )
}

export default AgentSearchModal