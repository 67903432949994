import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useParams, useLocation } from 'react-router-dom'
import AddressAddModal from '../Modals/AddressAddModal'
import SearchBox from '../components/SearchBox'
import Pagination from '../components/Pagination'
import {
  HStack,
  Box,
  Spacer,
  Spinner,
  Divider,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
  Heading,
} from '@chakra-ui/react'
import { getRejectNameList } from '../actions/rejectNameActions'
import { listAddress } from '../actions/addressActions'
import { ADDRESS_LIST_RESET } from '../constants/addressConstants'

const AddressListScreen = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const keyword = params.keyword || ''
  const pageNumber = params.pageNumber || 1
  const viewQty = [50, 100, 150, 200]
  const [itemCount, setItemCount] = useState(50)
  const {loading, error, addresses, pages, page} = useSelector(state=>state.address.List)
  const { loading: loadingNames, error: errorNames } = useSelector(state=>state.rejectName.List)
  const {success: createSuccess} = useSelector(state=>state.address.Create)
  useEffect(()=>{
    dispatch(getRejectNameList())
    dispatch(listAddress(pageNumber, itemCount, keyword))
    return()=>{
      dispatch({type: ADDRESS_LIST_RESET})
    }
  },[dispatch, createSuccess, pageNumber, itemCount, keyword])


  return (
    <Box>
      <Center mb='3'><Heading>Addresses</Heading></Center>
      <Divider />
        <HStack spacing='4' my='4'>
          <Box>
            {(loading || loadingNames) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
          </Box>
          <Box>
            <Select placeholder='Select'  onChange={e=>setItemCount(e.target.value)} size='sm'>
              {viewQty.map((v,i)=>(
                <option key={i} value={v}>{v}</option>
              ))}
            </Select>
          </Box>
          <Box>
            <SearchBox />
          </Box>
          <Box>
            <AddressAddModal />
          </Box>
          <Spacer />
          <Box>
            <Pagination keyword = {keyword} pages={pages} page={page} path='address' />
          </Box>
        </HStack>
        
        <Divider />

        {error && 
          <Alert status='error' variant='left-accent'>
          <AlertIcon />
          <AlertTitle mr={2}>Error getting address list</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
          </Alert>
        }
         {errorNames && 
          <Alert status='error' variant='left-accent'>
          <AlertIcon />
          <AlertTitle mr={2}>Error getting reject name list</AlertTitle>
          <AlertDescription>{errorNames}</AlertDescription>
          </Alert>
        }

        <Table size='sm' variant='striped' colorScheme='teal' my='4'>
          <Thead>
            <Tr>
              <Th>No</Th>
              <Th>Name</Th>
              <Th>Company</Th>
              <Th>Address</Th>
              <Th>City</Th>
              <Th>Country</Th>
              <Th>Options</Th>
            </Tr>
          </Thead>
          <Tbody>
            {addresses && addresses.map((ad,index)=>(
              <Tr key={index}>
                <Td>{pageNumber === 1 ? (index+1) : (((Number(pageNumber)-1) * itemCount) + (index+1))}</Td>
                <Td>{ad.name}</Td>
                <Td>{ad.company}</Td>
                <Td>{ad.address}</Td>
                <Td>{ad.city}</Td>
                <Td>{ad.country}</Td>
                <Td>
                  <Link to={`/address/${ad._id}`} state={{redirect: location.pathname}}>Detail</Link>       
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
    </Box>
  )
}

export default AddressListScreen