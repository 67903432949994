import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { myTHPPayments, myFilterTHPPayments } from '../../../actions/thailand_post_actions/thpPaymentActions'
import Pagination from '../../../components/Pagination'
import SearchBox from '../../../components/SearchBox'
import {
    Box,
    HStack,
    Stack,
    Select,
    Divider,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Center,
    Heading,
    Input,
    Text,
    GridItem,
    SimpleGrid,
    useBreakpointValue,
    Flex,
} from '@chakra-ui/react'
import { MdCheckCircle, MdCancel } from 'react-icons/md'
import Error from '../../../components/Error'
import { myAgents } from '../../../actions/userActions'
import Filter from '../../../components/Filter'
import useWindowSize from '../../../components/WindowSize'
import MySpinner from '../../../components/MySpinner'

const PostPaymentMyListScreen = () => {
    const size = useWindowSize()
    const colSpan = useBreakpointValue({base: 3, md: 1})
    const dispatch = useDispatch()
    const params = useParams()
    const keyword = params.keyword || ''
    const pageNumber = params.pageNumber || 1
    const [date, setDate] = useState('')
    const [itemCount, setItemCount] = useState(50)
    const {user} = useSelector(state=>state.login)
    const {loading, error, payments, pages, page} = useSelector(state=>state.thailandPost.PaymentMy)

    useEffect(()=>{
        dispatch(myTHPPayments(pageNumber, itemCount, keyword, date))
        dispatch(myAgents()) 
    },[dispatch, pageNumber, itemCount, keyword, date])


    return (
        <Box mt='3'>
            <Center mb='3'><Heading bg='blue.300' px='2' borderRadius={10}>THP Payments {loading && <MySpinner />}</Heading></Center>
            {error && <Error error={error} /> }
            <Divider />

            <SimpleGrid columns={3} columnGap={3} rowGap={3} my={3}>
                <GridItem colSpan={colSpan}>
                    <SearchBox width='full' />
                </GridItem>

                
                <GridItem colSpan={colSpan}>
                {user && user.accountType === 'agent' &&
                    <Input 
                        type='date'
                        size='sm'
                        w='full'
                        value={date}
                        onChange={e=>setDate(e.target.value)}
                    />
                }
                </GridItem>
                

                <GridItem colSpan={colSpan} ml='auto'>
                    <HStack>
                        <Select placeholder='Select' onChange={e=>setItemCount(e.target.value)} size='sm'>
                            {[50, 100, 150, 200].map((v,i)=>(
                                <option key={i} value={v}>{v}</option>
                            ))}
                        </Select>
                        <Pagination keyword = {keyword} pages={pages} page={page} path='mythppayments' />
                    </HStack>
                </GridItem>
            </SimpleGrid>


            {user && user.accountType === 'sale' &&
            <Filter
                filterFunction={myFilterTHPPayments} 
                saleShow={false} 
                paymentShow={false} 
                invoiceShow={false} 
                approveShow={true} 
                scanShow={false}
                />
            }            

            {size.width > 760 ? 
                <Table size='sm' variant='striped' colorScheme='pink' my='4'>
                    <Thead>
                        <Tr>
                        <Th>No</Th>
                        <Th>Code</Th>
                        {user && user.accountType === 'sale' && <Th>Agent</Th>}
                        <Th>Invoice</Th>
                        <Th isNumeric>Invoice Amount</Th>
                        <Th isNumeric>Payment Amount</Th>
                        <Th isNumeric>Created Date</Th>
                        <Th>Approved</Th>
                        <Th>Detail</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {payments && payments.map((p, idx)=>(
                        <Tr key={idx}>
                            <Td>{pageNumber === 1 ? (idx+1) : (((Number(pageNumber)-1) * itemCount) + (idx+1))}</Td>
                            <Td>{p.code}</Td>
                            {user && user.accountType === 'sale' && <Td>{p.agent.name}</Td>}
                            <Td>
                                <Link to={`/mythpinvoices/${p.invoice._id}`}>
                                    <Text as='u'>{p.invoice.code}</Text>
                                </Link>
                            </Td>
                            <Td isNumeric>{p.invoice.totalAmount.toLocaleString()}</Td>
                            <Td isNumeric>{p.amount.toLocaleString()}</Td>
                            <Td isNumeric>{p.createdAt.substring(0,10)}</Td>
                            {/* <Td><Box bg={p.isApproved ? 'green' : 'red'} h='20px' w='20px'></Box></Td> */}
                            <Td>{p.isApproved ? < MdCheckCircle size={20} color='green' /> :  < MdCancel size={20} color='red' />}</Td>
                            <Td>
                                <Link to={`/mythppayments/${p._id}`}>
                                <Button size='sm' variant={'link'} >Details</Button>
                                </Link>
                            </Td>
                        </Tr>
                        ))}
                    </Tbody>
                </Table>
                :
                <Table size='sm' variant='striped' colorScheme='pink' my='4'>
                    <Thead>
                        <Tr>
                        <Th>No</Th>
                        <Th>Summary</Th>
                        <Th>Detail</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {payments && payments.map((p, idx)=>(
                        <Tr key={idx}>
                            <Td>{pageNumber === 1 ? (idx+1) : (((Number(pageNumber)-1) * itemCount) + (idx+1))}</Td>
                            <Td>
                                <Stack spacing={0}>
                                    <Box>Code - {p.code}</Box>
                                    <Box>Sale - {p.sale.saleCode}</Box>
                                    <Box>Agent - {p.agent.name}</Box>
                                    <Box as='u'>Invoice - <Link to={`/mythpinvoices/${p.invoice._id}`}>{p.invoice.code}</Link></Box>
                                    <Box>Inv Amount - {p.invoice.totalAmount.toLocaleString()}</Box>
                                    <Box>Payment Amount - {p.amount.toLocaleString()}</Box>
                                    <Box>Created - {p.createdAt.substring(0,10)}</Box>
                                    <Flex>Approved - {p.isApproved ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Flex>
                                </Stack>
                            </Td>
                            <Td>
                                <Link to={`/mythppayments/${p._id}`}>
                                    Details
                                </Link>
                            </Td>
                        </Tr>
                        ))}
                    </Tbody>
                </Table>
            }            
        </Box>
    )
}

export default PostPaymentMyListScreen