import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {
    Box,
    Heading,
    HStack,
    Stack,
    FormControl,
    FormLabel,
    Input,
    Button,
    Spacer,
    Spinner,
    Divider,
    useToast,
    Select,
    Grid,
    UnorderedList,
    ListItem,
    Checkbox,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
} from '@chakra-ui/react'
import Error from '../../../components/Error'
import FromAndToDetail from '../../ShipmentCreateFiles/FromAndToDetail'
import { getPreshipment } from '../../../actions/shipmentActions'
import { services, categories } from '../../../data/postThailand'
import { getCostTHPShipment, createTHPShipment } from '../../../actions/thailand_post_actions/thpShipmentActions'
import { THPSHIPMENT_GETCOST_RESET, THPSHIPMENT_CREATE_RESET } from '../../../constants/thailand_post_constants/thpShipmentConstants'
import { getChargesList } from '../../../actions/chargesActions'

const PostShipmentCreateScreen = () => {
    const toast = useToast()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const preId = location.search ? location.search.split('=')[1] : ''
    const [serviceCode, setServiceCode] = useState(0)
    const [category, setCategory] = useState('Merchandise')
    const [cost, setCost] = useState(0)
    const [takeItemWeight, setTakeItemWeight] = useState(false)
    const [ignoreWeight, setIgnoreWeight] = useState(false)
    const [charges, setCharges] = useState([])
    const [newChargeName, setNewChargeName] = useState('')
    const [newChargeAmount, setNewChargeAmount] = useState('')
    const [cusPaidAmount, setCusPaidAmount] = useState(0)
    const [note, setNote] = useState('')
    const [itemList, setItemList] = useState([])
    const [boxList, setBoxList] = useState([])
    const {loading: loadingPreShipment, error: errorPreShipment, preshipment} = useSelector(state=>state.preshipment.ById)
    const {loading: loadingGetCost, error: errorGetCost, cost: shipmentCost} = useSelector(state=>state.thailandPost.ShipmentGetCost)
    const {loading, error, shipment} = useSelector(state=>state.thailandPost.ShipmentCreate)
    const { charges: chargesList } = useSelector(state=>state.charges.List)
    useEffect(()=>{
        if(preId !== ''){
            if(!preshipment || preshipment._id !== preId){
                dispatch(getPreshipment(preId))
            } else {             
                setItemList([...preshipment.items])
                setBoxList([...preshipment.boxes]) 
                setCusPaidAmount(preshipment.paidAmount) 
                setNote(preshipment.note || '')             
            }            
        }
        if(shipment){
            window.open(shipment.fileUrl, '_blank', 'noopener, noreferrer')
            navigate(`/thailandpost/${shipment.id}`)
        }
        if(shipmentCost){
            setCost(shipmentCost)
        }
        return()=>{
            dispatch({type: THPSHIPMENT_GETCOST_RESET})
            dispatch({type: THPSHIPMENT_CREATE_RESET})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, preshipment, preId, shipmentCost, shipment, toast, navigate])

     useEffect(()=>{
        dispatch(getChargesList())
    },[dispatch])
 
    const handleAddCharges = () => {
        if(newChargeName === '' || newChargeName === 'Select'){return}
        if(newChargeAmount === '' || newChargeAmount === 0){return}
        const existIndex = charges.map(c => c.name).indexOf(newChargeName)
        if(existIndex !== -1){return}
        const newChargeToPush = {
            name: newChargeName,
            amount: Number(newChargeAmount)
        }
        setNewChargeName('')
        setNewChargeAmount('')
        setCharges([...charges, newChargeToPush])
    }
    const handleRemoveCharges = (idx) => {
        const newCharges = charges
        //const existIndex = newCharges.map(c => c.name).indexOf(chargeName)
        newCharges.splice(idx, 1)
        setCharges([...newCharges])
    }
    const handleGetCost = () => {
        if(preshipment === undefined){
            return
        }
        const itemsTotalWeight = itemList.reduce((acc, item) => acc + item.weight, 0)
        const boxesTotalWeight = boxList.reduce((acc, b)=> acc + b.weight, 0)
        let totalWeight
        if(takeItemWeight){
            totalWeight = Math.floor(itemsTotalWeight)
        } else {
            totalWeight = Math.floor(boxesTotalWeight)
        }
        const data = {
            country: preshipment.addressId.country,
            totalWeight,
            shipType: preshipment.shipType,
            agentId: preshipment.agentId._id,
            preshipId: preshipment._id,
        }
        if(data.totalWeight === 0){
            toast({
                status: 'error',
                position: 'top',
                title: 'Total weight can not be 0',
                duration: 4000,
                isClosable: true,
            }) 
            return
        }
        dispatch(getCostTHPShipment(data))       
        
    }
    const removeEmpty = (obj) => {
        return Object.entries(obj)
            .filter(([_, v]) => v != null)
            .reduce(
                (acc, [k, v]) => ({ ...acc, [k]: v === Object(v) ? removeEmpty(v) : v }),
                {}
            )
    }





    const handleShip = (e) => {
        
        e.preventDefault()
        if(category === ''){
          toast({
            status: 'warning',
            position: 'top',
            title: 'Please choose cateogry.',
            duration: 3000,
            isClosable: true,
          }) 
          return
        }
        //const boxTotalWeight = Number(boxList.reduce((acc, box) => acc + box.weight, 0))
        //const itemTotalWeight = Number(itemList.reduce((acc, item) => acc + item.weight, 0))
        
        //customer detail (shipper, receiver) (customerDetails)
        const customerDetailsToCheck = {
            sender: {
                address: {
                    postalCode: '63110',
                    city: 'MAESOT',
                    countryCode: 'TH',
                    province: 'TAK',
                    premises: '522/3 INTARAKEEREE ROAD',
                    country: 'THAILAND',
                },  
                // address: {
                //     postalCode: preshipment.fromId.postalcode,
                //     city: preshipment.fromId.city ? preshipment.fromId.city : 'MAESOT',
                //     countryCode: preshipment.fromId.countrycode,
                //     province: preshipment.fromId.stateProvince ? preshipment.fromId.stateProvince : 'TAK',
                //     premises: preshipment.fromId.address,
                //     addition: preshipment.fromId.address2 || null,
                //     country: preshipment.fromId.country,
                // },            
                email: preshipment.contact.split(';')[2],
                tel: '+' + preshipment.contact.split(';')[1] ?? '0899617262',
                name: preshipment.contact.split(';')[0],
                
            },
            receiver: {
                address: {
                  postalCode: preshipment.addressId.postalcode,
                  city: preshipment.addressId.city,
                  countryCode: preshipment.addressId.countrycode,
                  province: preshipment.addressId.stateProvince || null,
                  premises: preshipment.addressId.address,
                  addition: preshipment.addressId.address2 || null,
                  country: preshipment.addressId.country,
                },
                  email: preshipment.addressId.email || null,
                  tel: '+' + preshipment.addressId.phonecode + preshipment.addressId.phone,
                  name: preshipment.addressId.name,                
            }
        }

        const customerDetails = removeEmpty(customerDetailsToCheck)
        
        const contentPieces = preshipment.items.map(i=>{
          return {
            desc: i.item.name,
            amount: Math.ceil(i.price * i.qty),
            currency: preshipment.currency,
            netWeight: i.weight * 1000,
            unit: i.qty,
            originalLocation: i.item.manuCountry,
            tariff: i.item.hsCode ? i.item.hsCode : '',
          }
        })
       
        var postData = {
            service: serviceCode ? Number(serviceCode) : Number(services[services.map(x=>x.name.toLowerCase()).indexOf(preshipment.shipType)].code) ,
            items: [
              {
                itemId: "THP1",
                categoryItem: category,
                weight: Math.round((boxList.reduce((acc, box) => acc + box.weight, 0))*100)/100,
                sender: customerDetails.sender,
                receiver: customerDetails.receiver,
                contentPieces,
              }
            ]
        }    
        

        var shipT = services[services.map(x=>x.code).indexOf(Number(postData.service))]?.name

        var smData = {          
          preCode: preshipment.code,
          preId: preshipment._id,
          agent: preshipment.agentId._id,            
          productCode: preshipment.productCode,
          shipType: preshipment.shipType === '' ? shipT.toLowerCase() : preshipment.shipType,
          totalWeight: Math.round((boxList.reduce((acc, box) => acc + box.weight, 0))*100)/100,
          totalAmount: Math.round((charges.reduce((acc, ch) => acc + ch.amount, 0) + Number(cost))*100)/100,
          paidAmount: cusPaidAmount,
          dhlInvoiceNo: preshipment.dhlInvoiceNo,
          charges,          
          note,
          postData,
        }  

        if(smData.postData.items[0].receiver.address.countryCode === 'JP' && smData.shipType === 'surface'){
            smData.postData.service = 3280
        }
        if(smData.productCode === 'D' && smData.shipType === 'ems'){
            smData.postData.service = 2585
        }
        //console.log(smData)
        dispatch(createTHPShipment(smData))
    }





    return (
        <Box>  
            
            <Box mx='5%'>
                {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}  
                {loadingPreShipment && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}  
                {error && <Error error={error} />}  
                {errorPreShipment && <Error error={errorPreShipment} />}  
            </Box>  
            <Box  mx='5%' opacity={loading && '0.5'}> 
            <Heading fontSize={'2xl'} my='5'>Addresses</Heading>
            <FromAndToDetail preshipment={preshipment} />
            <Box h='20px'></Box>
            
            
            <Box>
            <Box my='30px' borderBottom={'1px'}></Box>
                <Heading fontSize={'2xl'} mb='4'>Shipment Detail</Heading>
                <Grid
                    templateRows='repeat(1, 1fr)'
                    templateColumns='repeat(4, 1fr)'
                    gap={4}
                    >


                    <FormControl colSpan={2}>
                        <Stack spacing={'0'}>
                            <FormLabel>Service</FormLabel>
                            <Select placeholder='Select' size='sm' onChange={(e)=>setServiceCode(e.target.value)} >
                                <option>{preshipment && preshipment.shipType.toUpperCase()}</option>
                                {services.map((s, idx)=>(
                                    <option key={idx} value={s.code}>{s.name}</option>
                                ))}
                            </Select>                            
                        </Stack>
                    </FormControl>

                    <FormControl colSpan={2}>
                        <Stack spacing={'0'}>
                            <FormLabel>Category</FormLabel>
                            <Select placeholder='Select' size='sm' onChange={(e)=>setCategory(e.target.value)} >
                                <option>Merchandise</option>
                                {categories.map((c, idx)=>(
                                    <option key={idx} value={c}>{c}</option>
                                ))}
                            </Select>
                        </Stack>
                    </FormControl>
                </Grid>
            </Box>

            { preshipment && preshipment.productCode === 'P' && 
                <Box>
                  <Box borderBottom={'1px'} my='30px'></Box>                    
                  <Heading fontSize={'2xl'} >Items</Heading>
                    <Stack>
                      <Table variant='simple' size='sm'>
                          <Thead>
                          <Tr>
                              <Th>No</Th>
                              <Th>Item Name</Th>
                              <Th isNumeric>Weight</Th>
                              <Th isNumeric>Price</Th>
                              <Th isNumeric>Qty</Th>
                              <Th isNumeric>Amount</Th>
                          </Tr>
                          </Thead>

                          <Tbody>
                          {preshipment && itemList.map((item,idx)=>(
                              <Tr key={idx}>
                                  <Td>{idx+1}</Td>
                                  <Td>{item.item.description}</Td>
                                  <Td isNumeric>{item.weight}</Td>
                                  <Td isNumeric>{item.price}</Td>
                                  <Td isNumeric>{item.qty}</Td>
                                  <Td isNumeric>{(item.price*item.qty).toFixed(2)}</Td>
                              </Tr>
                          ))}
                          
                          </Tbody>
                      </Table>
                    
                      <HStack pe='30px'>
                          <Box w='200px'></Box>
                          <Spacer />
                          <Box fontSize={'lg'}>Total Weight - 
                              <span style={{'backgroundColor': 'yellow', 'padding': '0 5px', 'borderRadius': '10px'}}> 
                                  {(itemList.reduce((acc, item) => acc + item.weight, 0)).toFixed(2)} 
                              </span>kg
                          </Box> 
                          <Spacer />
                          <Box fontSize={'lg'}>Total Amount - 
                              {(itemList.reduce((acc, item) => acc + item.price * item.qty, 0)).toFixed(2)} 
                              ({preshipment.currency})
                          </Box>
                      </HStack>
                      </Stack>
                </Box>
            }

            

            <Box>
                <Box borderBottom={'1px'} my='30px' ></Box>
                <Heading fontSize={'2xl'} mb='5'>Boxes</Heading>
                <Stack>
                    {preshipment && boxList.map((box,idx)=>(
                        <Box key={idx} pe='30px'>
                            <HStack mb='2'>
                                <Box mr='30px'>Box {idx + 1} -</Box>
                                <Box>{box.dimensions.length} x {box.dimensions.width} x {box.dimensions.height} cm</Box>
                                <Spacer />
                                <Box>{box.weight} kg</Box>
                            </HStack>
                            
                            <Divider />                           
                        </Box>
                    ))}
                    <HStack pe='30px'>
                        <Box></Box>
                        <Spacer />
                        <Box fontSize={'lg'}>Total - {(boxList.reduce((acc, box) => acc + box.weight, 0)).toFixed(2)} kg</Box>
                    </HStack>
                </Stack>
            </Box>
            

            <Box>
                <Box borderBottom={'1px'} my='30px' ></Box>
                <Heading fontSize={'2xl'} mb='2'>SM Detail</Heading>
                {preshipment && <Box className='quadrat' mb='3' fontSize='lg' fontWeight={'600'}>{preshipment.note}</Box>}
                {errorGetCost && <Error error={errorGetCost} />}  
                <Stack>

                <Grid
                    templateRows='repeat(1, 1fr)'
                    templateColumns='repeat(4, 1fr)'
                    gap={4}
                    >
                    <FormControl colSpan={2}>
                        <Stack>
                            <Box>
                                <Box>Charges</Box>
                                <HStack>
                                    <Select id='new-charges' placeholder='Select' size='sm' onChange={(e)=>setNewChargeName(e.target.value)} >
                                        {chargesList && chargesList.map((c, idx)=>(
                                            <option key={idx} value={c.name}>{c.name}</option>
                                        ))}
                                    </Select>
                                    <Input 
                                        type='number'
                                        id='new-charge-amount'
                                        size='sm'
                                        w='100px'
                                        value={newChargeAmount}
                                        onChange={(e)=>setNewChargeAmount(e.target.value)}
                                    />
                                    <Button
                                        size='sm'
                                        colorScheme={'green'}
                                        onClick={handleAddCharges}
                                    >Add</Button>
                                </HStack>
                            </Box>
                            <Box>
                                <UnorderedList spacing={3}>
                                    {charges.map((ch, idx)=>(
                                        <ListItem key={idx}>
                                            <HStack>
                                                <Box w='200px'>{ch.name}</Box>
                                                <Box>{ch.amount}</Box>
                                                <Spacer />
                                                <Button
                                                    size='xs'
                                                    colorScheme={'red'}
                                                    onClick={()=>handleRemoveCharges(idx)}
                                                >
                                                    x
                                                </Button>
                                            </HStack>
                                        </ListItem>
                                    ))}
                                </UnorderedList>
                            </Box>
                        </Stack>
                    </FormControl>

                    <Stack spacing={'0'} >
                        <Box>Cost <span style={{'color': 'red', 'fontSize': '12px'}}> default: box weight</span></Box>                        
                        <HStack mb='3'>
                        <Input 
                            type={'text'}
                            id='cost'
                            size='sm'
                            readOnly
                            value={cost}
                            onChange={(e)=>setCost(e.target.value)}
                        />
                        <Button
                            size='sm'
                            isLoading={loadingGetCost}
                            disabled={preshipment === undefined}
                            colorScheme={'green'}
                            onClick={handleGetCost}
                        >
                            Compute
                        </Button>
                        </HStack>
                        <Stack>
                            <Checkbox id='ignore-weight' onChange={()=>setIgnoreWeight(!ignoreWeight)}>Ignore Weight</Checkbox>
                            <Checkbox id='item-weight' onChange={()=>setTakeItemWeight(!takeItemWeight)}>Item Weight</Checkbox>
                        </Stack>
                        
                        
                    </Stack>


                    <Stack spacing={'0'} >
                        <Box>Customer Paid</Box>                        
                        <HStack>
                            <Input 
                                type={'text'}
                                id='cus-paid-amount'
                                size='sm'
                                value={cusPaidAmount}
                                onChange={(e)=>setCusPaidAmount(e.target.value)}
                            />
                        </HStack>
                    </Stack>
                    <Stack spacing={'0'} >
                        <Box>Note</Box>                        
                        <HStack>
                            <Input 
                                type={'text'}
                                id='note'
                                size='sm'
                                value={note}
                                onChange={(e)=>setNote(e.target.value)}
                            />
                        </HStack>
                    </Stack>
                </Grid>
                    
                    <Divider />
                    <HStack pe='30px'>
                        <Box></Box>
                        <Spacer />
                        <Box fontSize={'2xl'}>
                        {charges.length !== 0 ? (charges.reduce((acc, ch) => acc + ch.amount, 0) + Number(cost)) : Number(cost)}
                        <span style={{'fontSize': '15px', 'color': 'red'}}> thb</span>
                        </Box>
                    </HStack>
                </Stack>
            </Box>

            </Box> 
                <Box mx='5%' my='5'>
                {error && <Error error={error} />}  
                
                { cost === 0 && <Error error={'Shipment cost can not be 0.'} />}
                
                    <Divider />
                    <HStack mt='3'>

                        <Link to='/shipment'>
                            <Button
                                size='sm'
                                isLoading={loading}
                                w='150px'
                                colorScheme={'blue'}
                            >Cancel</Button>
                        </Link>
                        <Spacer />
                        <Button                            
                            size='sm'
                            isLoading={loading}
                            w='150px'
                            colorScheme={'yellow'}
                            onClick={handleShip}
                        >Ship</Button>
                    </HStack>
                </Box>
        </Box>
    )
}

export default PostShipmentCreateScreen