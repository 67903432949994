import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserLogs } from '../actions/systemActions'
import { Error, MySpinner } from '../components'
import { Box, Heading, 
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    HStack,
    Input,
    Spacer,
} from '@chakra-ui/react'

const UserLogScreen = () => {
  const dispatch = useDispatch()
  const [date, setDate] = useState(new Date().toISOString().substring(0,10))
  const { loading, error, success, logs } = useSelector(state=>state.system.userLog)

  useEffect(()=>{
    dispatch(getUserLogs(''))
  },[dispatch])


  return (
    <div>
      {loading && <MySpinner />}
      {error && <Error error={error} />}
      {success && 
        <Box p={4}>  
            <HStack mb={4}>
                <Box>
                    <Input 
                        type='date'
                        size='sm'
                        w='150px'
                        value={date}
                        onChange={e=>{
                            setDate(e.target.value)
                            dispatch(getUserLogs(e.target.value))
                        }}
                    />
                </Box>
                <Spacer />
                <Heading>USER LOGS</Heading>
                <Spacer />
                <Box>

                </Box> 

            </HStack>  
            <Box>
                <Table size='sm'>
                    <Thead>
                        <Tr>
                        <Th>No</Th>
                        <Th>Name</Th>
                        <Th>Type</Th>
                        <Th>Url</Th>
                        <Th>Method</Th>
                        <Th>Time</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {logs.map((ul,idx)=>(
                            <Tr key={idx}>
                                <Td>{idx+1}</Td>
                                <Td>{ul.log.split('#')[0]}</Td>
                                <Td>{ul.log.split('#')[1]}</Td>
                                <Td>{ul.log.split('#')[2]}</Td>
                                <Td>{ul.log.split('#')[3]}</Td>
                                <Td>{ul.createdAt.substring(11,16)}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Box>
      }
    </div>
  )
}

export default UserLogScreen