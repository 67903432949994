import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Center,
    HStack,
    Spacer,
    Input,
    Spinner,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
} from '@chakra-ui/react'
import { countUserShip } from '../actions/shipmentActions'

const ShipmentCreatedCountByUser = () => {
    const dispatch = useDispatch()
    const [date, setDate] = useState('')
    const { loading, error, countDetail } = useSelector(state=>state.shipment.UserShipCount) 

    useEffect(()=>{
        dispatch(countUserShip(date))
    },[dispatch,date])

    
    return (
        <Box>
            {loading && <Center><Spinner label='Please wait...' speed='0.1s' thickness='5px' /></Center>}
            {error && 
                <Alert my='2' status='error' variant='left-accent'>
                <AlertIcon />
                <AlertTitle mr={2}>Error getting data</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
                </Alert>
            }
            <Center>
                <HStack w='800px' mb='50px' >
                    <Box fontSize={'4xl'}>User Shipment Count By Date</Box>
                    <Spacer />
                    <Box>
                        <Input 
                            type='date'
                            w='200px'
                            size='sm'
                            onChange={e=>setDate(e.target.value)}
                        />
                    </Box>
                </HStack>
            </Center>
            <Center mb='20px'>
                <Box fontSize={'2xl'}>{date === '' ? 'Today' : date}</Box>
            </Center>
            {countDetail && 
                <Center>
                    <Box w='600px'>
                        <TableContainer>
                            <Table variant='simple'>
                                <Thead>
                                <Tr>
                                    <Th>No</Th>
                                    <Th>Name</Th>
                                    <Th isNumeric>Shipment Created Count</Th>
                                </Tr>
                                </Thead>
                                <Tbody>
                                    {countDetail.map((d,i)=>(
                                        <Tr key={i}>
                                            <Th>{i+1}</Th>
                                            <Th>{d.user.name}</Th>
                                            <Th isNumeric bg={d.shipCount !== 0 && 'yellow.400'}>{d.shipCount}</Th>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>

                    </Box>
                </Center>
            }
        </Box>
    )
}

export default ShipmentCreatedCountByUser