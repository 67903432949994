const currencies = [
    {code:"AED",name:"UAE Dirham"},
    {code:"AFN",name:"Afghani"},
    {code:"ALL",name:"Lek"},
    {code:"AMD",name:"Dran"},
    {code:"ANG",name:"Netherlands Antilles Guilder"},
    {code:"AOA",name:"Kwanza"},
    {code:"ARS",name:"Argentine Peso"},
    {code:"AUD",name:"Australian Dollar"},
    {code:"AWG",name:"Aruba Guilder"},
    {code:"AZN",name:"Manat"},
    {code:"BAM",name:"Convertible Marks"},
    {code:"BBD",name:"Barbadian Dollar"},
    {code:"BDT",name:"Taka"},
    {code:"BGN",name:"Bulgarian Lev"},
    {code:"BHD",name:"Bahraini Dinar"},
    {code:"BIF",name:"Burundese Franc"},
    {code:"BMD",name:"Bermudian Dollar"},
    {code:"BND",name:"Brunei Dollar"},
    {code:"BOB",name:"Boliviano"},
    {code:"BRL",name:"Real"},
    {code:"BSD",name:"Bahamian Dollar"},
    {code:"BTN",name:"Ngultrum"},
    {code:"BWP",name:"Pula"},
    {code:"BYN",name:"Belarussian Ruble"},
    {code:"BZD",name:"Belize Dollar"},
    {code:"CAD",name:"Canadian Dollar"},
    {code:"CDF",name:"Franc Congolais"},
    {code:"CHF",name:"Swiss Franc"},
    {code:"CLF",name:"Unidades de fomento (Chile)"},
    {code:"CLP",name:"New Chile Peso"},
    {code:"CNY",name:"Yuan (Ren Min Bi)"},
    {code:"COP",name:"Colombian Peso"},
    {code:"COU",name:"Unidad de Valor Real"},
    {code:"CRC",name:"Costa Rican Colon"},
    {code:"CUC",name:"Peso Convertible"},
    {code:"CUP",name:"Cuban Peso"},
    {code:"CVE",name:"Cape Verde Escudo"},
    {code:"CZK",name:"Czech Koruna"},
    {code:"DJF",name:"Djibouti Franc"},
    {code:"DKK",name:"Danish Krone"},
    {code:"DOP",name:"Dominican Republic Peso"},
    {code:"DZD",name:"Algerian Dinar"},
    {code:"EEK",name:"Kroon"},
    {code:"EGP",name:"Egyptian Pound"},
    {code:"ERN",name:"Nakfa"},
    {code:"ETB",name:"Birr"},
    {code:"EUR",name:"Euro"},
    {code:"FJD",name:"Fijian Dollar"},
    {code:"FKP",name:"Falkland Islands Pound"},
    {code:"GBP",name:"Pound Sterling"},
    {code:"GEK",name:"Georgian Coupon"},
    {code:"GEL",name:"Georgian Lari"},
    {code:"GHS",name:"Cedi"},
    {code:"GIP",name:"Gibraltar Pound"},
    {code:"GMD",name:"Dalasi"},
    {code:"GNF",name:"Guinea Franc"},
    {code:"GTQ",name:"Quetzal"},
    {code:"GYD",name:"Guyanan Dollar"},
    {code:"HKD",name:"Hong Kong Dollar"},
    {code:"HNL",name:"Lempira"},
    {code:"HRK",name:"Croatian Kuna"},
    {code:"HTG",name:"Gourde"},
    {code:"HUF",name:"Forint"},
    {code:"IDR",name:"Rupiah"},
    {code:"ILS",name:"New Israeli Shekel"},
    {code:"INR",name:"Indian Rupee"},
    {code:"IQD",name:"Iraqi Dinar"},
    {code:"IRR",name:"Iranian Rial"},
    {code:"ISK",name:"Icelandic Krona"},
    {code:"JMD",name:"Jamaican Dollar"},
    {code:"JOD",name:"Jordanian Dinar"},
    {code:"JPY",name:"Yen"},
    {code:"KES",name:"Kenyan Shilling"},
    {code:"KGS",name:"Som"},
    {code:"KHR",name:"Khmer Rial"},
    {code:"KMF",name:"Comoros Franc"},
    {code:"KPW",name:"North Korean Won"},
    {code:"KRW",name:"Won"},
    {code:"KWD",name:"Kuwaiti Dinar"},
    {code:"KYD",name:"Cayman Islands Dollar"},
    {code:"KZT",name:"Tenge"},
    {code:"LAK",name:"Kip"},
    {code:"LBP",name:"Lebanese Pound"},
    {code:"LKR",name:"Sri Lankan Rupee"},
    {code:"LRD",name:"Liberian Dollar"},
    {code:"LSL",name:"Loti"},
    {code:"LTL",name:"Lithuanian Litas"},
    {code:"LVL",name:"Latvian Lats"},
    {code:"LYD",name:"Libyan Dinar"},
    {code:"MAD",name:"Moroccan Dirham"},
    {code:"MDL",name:"Leu"},
    {code:"MGA",name:"Ariary"},
    {code:"MKD",name:"Denar"},
    {code:"MMK",name:"Kyat"},
    {code:"MNT",name:"Tugrik"},
    {code:"MOP",name:"Pataca"},
    {code:"MRU",name:"Ouguiya"},
    {code:"MUR",name:"Mauritius Rupee"},
    {code:"MVR",name:"Rufiyaa"},
    {code:"MWK",name:"Kwacha"},
    {code:"MXN",name:"Mexican Nuevo Peso"},
    {code:"MYR",name:"Malaysian Ringgit"},
    {code:"MZN",name:"Mozambique Metical"},
    {code:"NAD",name:"Namibian Dollar"},
    {code:"NGN",name:"Naira"},
    {code:"NIO",name:"Cordoba Oro"},
    {code:"NOK",name:"Norwegian Krone"},
    {code:"NPR",name:"Nepalese Rupee"},
    {code:"NZD",name:"New Zealand Dollar"},
    {code:"OMR",name:"Omani Rial"},
    {code:"PAB",name:"Balboa"},
    {code:"PEN",name:"Nuevo Sol"},
    {code:"PGK",name:"Kina"},
    {code:"PHP",name:"Phillipines Peso"},
    {code:"PKR",name:"Pakistani Rupee"},
    {code:"PLN",name:"Zloty"},
    {code:"PYG",name:"Guarani"},
    {code:"QAR",name:"Qatar Rial"},
    {code:"RON",name:"Leu"},
    {code:"RSD",name:"Serbia, Dinars"},
    {code:"RUB",name:"Russian Ruble"},
    {code:"RWF",name:"Rwanda Franc"},
    {code:"SAR",name:"Saudi Riyal"},
    {code:"SBD",name:"Solomon Islands Dollar"},
    {code:"SCR",name:"Seychelles Rupee"},
    {code:"SDG",name:"Sudanese Pound"},
    {code:"SEK",name:"Swedish Krona"},
    {code:"SGD",name:"Singapore Dollar"},
    {code:"SHP",name:"St. Helena Pound"},
    {code:"SLL",name:"Leone"},
    {code:"SLS",name:"Somaliland Shilling"},
    {code:"SOS",name:"Somali Shilling"},
    {code:"SRD",name:"Suriname Dollar"},
    {code:"SSP",name:"South Sudanese pound"},
    {code:"STD",name:"Dobra"},
    {code:"SYP",name:"Syrian Pound"},
    {code:"SZL",name:"Lilangeni"},
    {code:"THB",name:"Baht"},
    {code:"TJS",name:"Somoni"},
    {code:"TMT",name:"Manat"},
    {code:"TND",name:"Tunisian Dinar"},
    {code:"TOP",name:"Pa'anga"},
    {code:"TRY",name:"New Turkish Lira"},
    {code:"TTD",name:"Trinidad and Tobago Dollar"},
    {code:"TWD",name:"New Taiwan Dollar"},
    {code:"TZS",name:"Tanzanian Shilling"},
    {code:"UAH",name:"Hryvna"},
    {code:"UGX",name:"Ugandan Shilling"},
    {code:"USD",name:"US Dollar"},
    {code:"USN",name:"US Dollar-Next Day"},
    {code:"UYU",name:"Peso Uruguayo"},
    {code:"UZS",name:"Sum"},
    {code:"VES",name:"Bolivar Soberano"},
    {code:"VND",name:"Dong"},
    {code:"VUV",name:"Vanuatu Vatu"},
    {code:"WST",name:"Tala"},
    {code:"XAF",name:"CFA Franc - Central Africa"},
    {code:"XAG",name:"Silver"},
    {code:"XAU",name:"Gold"},
    {code:"XBA",name:"Bonds-European Composite Unit"},
    {code:"XBB",name:"Bonds-European Monetary Unit"},
    {code:"XBC",name:"Bonds-European Unit of Account 9"},
    {code:"XBD",name:"Bonds-European Unit of Account 17"},
    {code:"XCD",name:"East Caribbean Dollar"},
    {code:"XDR",name:"IMF Monetary Unit"},
    {code:"XOF",name:"CFA Franc - West Africa"},
    {code:"XPF",name:"CFP Franc"},
    {code:"XXX",name:"no currency assigned"},
    {code:"YER",name:"Yemeni Riyal"},
    {code:"ZAR",name:"South African Rand"},
    {code:"ZMW",name:"Kwacha"},
    {code:"ZWL",name:"Real Time Gross Settlement dollars"}
]

export {currencies}