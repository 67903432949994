import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useParams, useLocation } from 'react-router-dom'
import SearchBox from '../../components/SearchBox'
import Pagination from '../../components/Pagination'
import {
  HStack,
  Stack,
  Box,
  Divider,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
  Heading,
  Input,
  useBreakpointValue,
  SimpleGrid,
  GridItem,
  Flex,
} from '@chakra-ui/react'
import { myShipments, myFilteredShipments } from '../../actions/shipmentActions'
import { SHIPMENT_MY_RESET } from '../../constants/shipmentConstants'
import { MdCancel, MdCheckCircle } from 'react-icons/md'
import Error from '../../components/Error'
import useWindowSize from '../../components/WindowSize'
import Filter from '../../components/Filter'
import MySpinner from '../../components/MySpinner'

const AgentShipmentListScreen = () => {
  const size = useWindowSize()
  const colSpan = useBreakpointValue({base: 3, md: 1})
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const keyword = params.keyword || ''
  const [date, setDate] = useState('')
  const pageNumber = params.pageNumber || 1
  const [itemCount, setItemCount] = useState(50)
  const {user} = useSelector(state=>state.login)
  const {loading, error, shipments, pages, page} = useSelector(state=>state.shipment.My)

  useEffect(()=>{
    dispatch(myShipments(pageNumber, itemCount, keyword, date))
    return()=>{
      dispatch({type: SHIPMENT_MY_RESET})
    }
  },[dispatch,  pageNumber, itemCount, keyword, date])


  return (
    <Box mt='3'>
      <Center mb='3'><Heading bg='yellow.300' px='2' borderRadius={10}>DHL Shipments {loading && <MySpinner />}</Heading></Center>
      <Divider/>

      <SimpleGrid columns={3} columnGap={3} w='full' my={2}>
        <GridItem colSpan={colSpan} mb='3'>
          <SearchBox text='awb, receiver, phone, address' width='full' />
        </GridItem>

        <GridItem colSpan={colSpan} mb='3'>
          {user && user.accountType === 'agent' && 
            <Input 
              type='date'
              size='sm'
              width='full'
              value={date}
              onChange={e=>setDate(e.target.value)}
            />
          }
        </GridItem>

        <GridItem colSpan={colSpan} mb='3' ml='auto'>
          <HStack>
            <Select placeholder='Select' onChange={e=>setItemCount(e.target.value)} size='sm' w='100px'>
              {[50, 100, 150, 200].map((v,i)=>(
                <option key={i} value={v}>{v}</option>
              ))}
            </Select>
            <Pagination keyword = {keyword} pages={pages} page={page} path='myshipments' />
          </HStack>
        </GridItem>
      </SimpleGrid>      
        
      {error && <Error error={error} /> }

      {user && user.accountType === 'sale' && <Filter filterFunction={myFilteredShipments} saleShow={false} scanShow={false} />}
      {size.width > 760 ? 
        <Table size='sm' variant='striped' colorScheme='yellow' my='4'>
          <Thead>
            <Tr>
              <Th>No</Th>
              <Th>AWB</Th>
              <Th>Ship Date</Th>
              <Th>Receiver</Th>
              {user && user.accountType === 'sale' && <Th>Agent</Th>}
              <Th isNumeric>Weight (kg)</Th>
              <Th isNumeric>Amount (thb)</Th>
              <Th>Invoiced</Th>
              <Th>Paid</Th>
              <Th>Options</Th>
            </Tr>
          </Thead>
          <Tbody>
            {shipments && shipments.map((sm,index)=>(
              <Tr key={index}>
                <Td>{pageNumber === 1 ? (index+1) : (((Number(pageNumber)-1) * itemCount) + (index+1))}</Td>
                <Td>{sm.awb}</Td>
                <Td>{sm.createdAt.substring(0,10)}</Td>
                {/* <Td>{new Date(sm.createdAt).toLocaleString('en', { hour12: true, timeZone: 'UTC' })}</Td> */}
                <Td>
                  <Stack spacing={0}>
                    <Box fontWeight={'500'}>
                      {sm.customerDetails.receiverDetails.contactInformation.fullName}
                    </Box>
                    <Box>
                      <HStack fontSize={'xs'}>
                        <Box>{sm.customerDetails.receiverDetails.postalAddress.addressLine1}, </Box> 
                        <Box>{sm.customerDetails.receiverDetails.postalAddress.cityName}, </Box>
                        <Box>{sm.customerDetails.receiverDetails.postalAddress.countryCode}</Box>
                      </HStack>                      
                    </Box>
                  </Stack>
                </Td>
                {user && user.accountType === 'sale' && <Td>{sm.agent.name}</Td>}
                <Td isNumeric>{sm.totalWeight}</Td>
                <Td isNumeric>{sm.totalAmount.toLocaleString()}</Td>
                <Td>{sm.isInvoiced ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Td>
                <Td>{sm.isPaid ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Td>
                <Td>
                  <Link to={`/myshipments/${sm._id}`} state={{redirect: location.pathname}}>Detail</Link>     
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        :
        <Table size='sm' variant='striped' colorScheme='yellow' my='4'>
          <Thead>
            <Tr>
              <Th>No</Th>
              <Th>Summary</Th>
              <Th>Options</Th>
            </Tr>
          </Thead>
          <Tbody>
            {shipments && shipments.map((sm,index)=>(
              <Tr key={index}>
                <Td>{pageNumber === 1 ? (index+1) : (((Number(pageNumber)-1) * itemCount) + (index+1))}</Td>
                <Td>
                  <Stack spacing={0}>
                    <Box>Tracking - {sm.awb}</Box>
                    <Box>Ship on - {sm.createdAt.substring(0,10)}</Box>
                    <Box>Receiver - {sm.customerDetails.receiverDetails.contactInformation.fullName}, {sm.customerDetails.receiverDetails.postalAddress.countryCode}</Box>
                    {user && user.accountType === 'sale' && <Box>Agent - {sm.agent.name}</Box> }
                    <Box>Weight - {sm.totalWeight} kg</Box>
                    <Box>Amount - {sm.totalAmount.toLocaleString()} thb</Box>
                    <Flex>Invoice - {sm.isInvoiced ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Flex>
                    <Flex>Payment - {sm.isPaid ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Flex>
                  </Stack>
                </Td>                
                <Td>
                    <Link to={`/myshipments/${sm._id}`} state={{redirect: location.pathname}}>Detail</Link>      
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      }
    </Box>
  )
}

export default AgentShipmentListScreen