import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom'
import {
    Box,
    Flex,
    Stack,
    Select,
    Grid,
    HStack,
    InputGroup,
    InputLeftAddon,
    InputRightElement,
    Input,
    Button,
    Spacer,
    Spinner,
    useToast,
    Badge
  } from '@chakra-ui/react'
  import { MdSave, MdUndo } from 'react-icons/md'
  import { getCountryList } from '../actions/countryActions'
  import { dhlValidateAddress } from '../actions/addressActions'
  import { ADDRESS_BYID_RESET, DHL_ADDRESS_VALIDATE_RESET, ADDRESS_EDIT_RESET, ADDRESS_DELETE_RESET } from '../constants/addressConstants'
  import { getByIdAddress, editAddress, deleteAddress } from '../actions/addressActions'
  import ConfirmDeleteModal from '../components/ConfirmDeleteModal'
  import Error from '../components/Error'

const AddressEditScreen = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const toast = useToast()
    const addressId = params.id
    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [nickname, setNickname] = useState('')
    const [country, setCountry] = useState('')
    const [countrycode, setCountrycode] = useState('')
    const [phonecode, setPhonecode] = useState('')
    const [address, setAddress] = useState('')
    const [address2, setAddress2] = useState('')
    const [address3, setAddress3] = useState('')
    const [postalcode, setPostalcode] = useState('')
    const [city, setCity] = useState('')
    const [cityList, setCityList] = useState([])
    const [stateProvince, setStateProvince] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [isError, setIsError] = useState([])

    const {loading: byIdLoading, error: byIdError, addressDetail} = useSelector(state=>state.address.ById)
    const {loading: loadingDHLAddress, error: errorDHLAddress, data: addressData} = useSelector(state=>state.address.Validate)
    const {loading: editLoading, error: editError, success: editSuccess} = useSelector(state=>state.address.Edit)
    const {loading: deleteLoading, error: deleteError, success: deleteSuccess} = useSelector(state=>state.address.Delete)
    const { loading: loadingCountry, countries, success:successCountry, error:errorCountry } = useSelector(state=>state.country.List)

    useEffect(()=>{
        dispatch(getCountryList())
    },[dispatch])
    useEffect(()=>{
        
        if(!addressDetail || addressId !== addressDetail._id){
            dispatch(getByIdAddress(addressId))
        } else {
            setName(addressDetail.name)
            setCompany(addressDetail.company)
            setNickname(addressDetail.nickname)            
            setPhonecode(addressDetail.phonecode)
            setPhone(addressDetail.phone)
            setAddress(addressDetail.address)
            setAddress2(addressDetail.address2)
            setAddress3(addressDetail.address3)
            setPostalcode(addressDetail.postalcode)
            setCity(addressDetail.city)
            setStateProvince(addressDetail.stateProvince)
            setEmail(addressDetail.email)
            setCountry(addressDetail.country)
            setPhonecode(addressDetail.phonecode)
            setCountrycode(addressDetail.countrycode)
            
        }
    },[dispatch, addressId, addressDetail, countries])

    useEffect(()=>{
        if(addressData){            
            setCity(addressData.address[0].cityName)
            setCityList([...addressData.address])
        }
    },[addressData])

    useEffect(()=>{
        if(editSuccess || deleteSuccess) {
            toast({
                status: 'success',
                position: 'top',
                title: 'OK!',
                duration: 4000,
                isClosable: true,
            }) 
            navigate('/address')
        }

        return()=>{
            dispatch({type: ADDRESS_BYID_RESET})
            dispatch({type: DHL_ADDRESS_VALIDATE_RESET})
            dispatch({type: ADDRESS_EDIT_RESET})
            dispatch({type: ADDRESS_DELETE_RESET})
        }
        
    },[dispatch, editSuccess, deleteSuccess, navigate, toast])

    const handleAddressValidate = (e) => {
        if(e.key === 'Enter'|| e.target.innerHTML === 'check'){
            const dataToValidate = {
                countryCode: countrycode,
                postalCode: postalcode
            }
            if(countrycode === '' || postalcode === ''){
                return
            } else {
                dispatch(dhlValidateAddress(dataToValidate))
            }            
        }
        return        
    }

    const handleCountrySelect = (i) => {
        if(i){
            setCountry(countries[i].name)
            setPhonecode(countries[i].phoneCode)
            setCountrycode(countries[i].countryCode)
            setPostalcode('')
            setCity('')
            setStateProvince('')
            setAddress('')
            setAddress2('')
            setAddress3('')
            return
        }
        setCountry('')
        setPhonecode('')
        setCountrycode('')
    }

    const handleSubmit = () => {
        const emptyCheck = {
            name,
            company,           
            country,
            phone,
            address,
            postalcode,
            city,
        }
        for (const [key, value] of Object.entries(emptyCheck)) {
            if(value === ''){
                isError.push(key)
                setIsError([...isError])
            }
        }
        if(isError.length === 0){
            const editedAddress = {
                name,
                company,
                nickname,            
                country,
                countrycode,
                phonecode,
                phone,
                address,
                address2,
                address3,
                postalcode,
                city,
                stateProvince,
                email
            }
            // console.log(editedAddress)
            dispatch(editAddress(addressId, editedAddress))
        } else {
            return
        }
    }

    return (
        <Box w='900px' maxW='100%' m='auto' my='4'>    
            <HStack>
            <Box my='4' fontSize='3xl'>{(byIdLoading || loadingDHLAddress || editLoading || deleteLoading || loadingCountry) ? <Spinner label='Please wait' speed='0.1s' thickness='5px' /> : 'Address Detail' }</Box>  
            <Spacer />
            <Badge variant='solid' colorScheme='green' ml='1' fontSize='0.8em'>{addressDetail && addressDetail.shipCount} - Shipment found for this address</Badge>
            </HStack>  
            <Stack spacing={4}>
                {byIdError && <Error error={byIdError} /> }
                {errorDHLAddress && <Error error={errorDHLAddress} /> }
                {editError && <Error error={editError} /> }
                {deleteError && <Error error={deleteError} /> }
                {errorCountry && <Error error={errorCountry} />}

                <InputGroup size='sm'>                        
                    <InputLeftAddon children='Name *' w='20%' minW='150px' />
                    <Input 
                        type='text' 
                        value={name} 
                        onChange={e=>setName(e.target.value)}
                        isInvalid={isError.indexOf('name') !== -1}
                        onKeyPress={()=>setIsError(isError.filter(x => x !== 'name'))}
                        maxLength={45}
                    />                        
                </InputGroup>

                <InputGroup size='sm'>                        
                    <InputLeftAddon children='Company *' w='20%' minW='150px' />
                    <Input 
                        type='text' 
                        value={company} 
                        onChange={e=>setCompany(e.target.value)} 
                        isInvalid={isError.indexOf('company') !== -1}
                        onKeyPress={()=>setIsError(isError.filter(x => x !== 'company'))}
                        maxLength={60}
                    />                        
                </InputGroup>

                <InputGroup size='sm'>                        
                    <InputLeftAddon children='Nickname' w='20%' minW='150px' />
                    <Input 
                        type='text' 
                        value={nickname} 
                        onChange={e=>setNickname(e.target.value)} 
                        maxLength={40}
                    />                        
                </InputGroup>

                {successCountry &&
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='Country *' w='20%' minW='150px' />
                        <Select 
                            placeholder={country}
                            onChange={e=> {
                                e.preventDefault()
                                handleCountrySelect(e.target.value)
                                setIsError(isError.filter(x => x !== 'country'))}}
                            isInvalid={isError.indexOf('country') !== -1}
                            
                        >
                            {countries.map((ctry,idx)=> (                            
                                <option key={idx} value={idx}>{ctry.name} - Code:{ctry.countryCode}, Phone:{ctry.phoneCode}, Zone:{ctry.zone}</option>
                                // <option key={idx} value={JSON.stringify(ctry)}>{ctry.cName}</option>
                            ))}
                        </Select>                       
                    </InputGroup>
                }

                <InputGroup size='sm'>                        
                    <InputLeftAddon children='Address *' w='20%' minW='150px' />
                    <Input 
                        type='text' 
                        value={address} 
                        onChange={e=>setAddress(e.target.value)} 
                        isInvalid={isError.indexOf('address') !== -1}
                        onKeyPress={()=>setIsError(isError.filter(x => x !== 'address'))}
                        maxLength={45}
                    />                        
                </InputGroup>

                <InputGroup size='sm'>                        
                    <InputLeftAddon children='Address 2' w='20%' minW='150px' />
                    <Input type='text' value={address2} onChange={e=>setAddress2(e.target.value)} maxLength={45} />                        
                </InputGroup>

                <InputGroup size='sm'>                        
                    <InputLeftAddon children='Address 3' w='20%' minW='150px' />
                    <Input type='text' value={address3} onChange={e=>setAddress3(e.target.value)} maxLength={45} />                        
                </InputGroup>

                <Grid templateColumns='repeat(3, 1fr)' gap={4}>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='Postal Code' />
                        <Input 
                            type='text' 
                            value={postalcode} 
                            onChange={e=>setPostalcode(e.target.value)}  
                            isInvalid={isError.indexOf('postalcode') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'postalcode'))}
                            onKeyDown={handleAddressValidate}
                            maxLength={12}
                        />  
                        <InputRightElement width='4.5rem'>
                            <Button h='1.75rem' colorScheme='teal' size='sm' onClick={(e)=>handleAddressValidate(e)}>
                                check
                            </Button>
                        </InputRightElement>                      
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='City' />
                        {/* <Input 
                            type='text' 
                            value={city} 
                            onChange={e=>setCity(e.target.value)} 
                            isInvalid={isError.indexOf('city') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'city'))}
                            maxLength={35}
                        />   */}
                        <Select 
                            placeholder='Select '
                            onChange={e=> {setCity(e.target.value);setIsError(isError.filter(x => x !== 'city'))}}
                            isInvalid={isError.indexOf('city') !== -1}
                        >
                            <option value={city}>{city}</option>
                            {cityList.length !== 0 && cityList.map((city,idx)=> (
                                <option key={idx} value={city.cityName}>{city.cityName}</option>
                                // <option key={idx} value={JSON.stringify(ctry)}>{ctry.cName}</option>
                            ))}
                        </Select>                        
                    </InputGroup>
                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='State' />
                        <Input type='text' value={stateProvince} onChange={e=>setStateProvince(e.target.value)} maxLength={35} />                        
                    </InputGroup>
                </Grid>

                <HStack>
                    <Box w='40%'>
                        <InputGroup size='sm' mr='2' minW='100%'>                        
                            <InputLeftAddon children='Email' />
                            <Input type='text' value={email} onChange={e=>setEmail(e.target.value)} maxLength={50} />                        
                        </InputGroup>
                    </Box>
                    <Box w='20%'>
                        <InputGroup size='sm' mr='2' minW='100%'>                        
                            <InputLeftAddon children='Code' />
                            <Input type='text' value={phonecode} readOnly/>                      
                        </InputGroup>
                    </Box>
                    <Box w='40%'>
                        <InputGroup size='sm' minW='100%' >                        
                            <InputLeftAddon children='Phone'/>
                            <Input 
                                type='text' 
                                value={phone} 
                                onChange={e=>setPhone(e.target.value)}
                                isInvalid={isError.indexOf('phone') !== -1} 
                                onKeyDown={()=>setIsError(isError.filter(x => x !== 'phone'))}
                                onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault()}}} 
                                maxLength={25}
                            />                        
                        </InputGroup>
                    </Box>
                </HStack>
            </Stack>

            <Flex mt='6'>
                <Link to={`${location.state.redirect}`}>
                    <Button colorScheme='blue' w='200px' size='sm' leftIcon={<MdUndo />}>
                        Cancel
                    </Button>
                </Link>
                
                <Spacer />
                <ConfirmDeleteModal deleteFunction={deleteAddress} id={addressId} disable={ addressDetail && addressDetail.shipCount !== 0} />
                
                <Spacer />
                <Button colorScheme='green' w='200px' size='sm' leftIcon={<MdSave />} onClick={handleSubmit}>Save</Button>
            </Flex>
        </Box>
    )
}

export default AddressEditScreen