import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Stack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Input,
    Box,
    Spinner,
    Select,
} from '@chakra-ui/react'
import { addNewCountry } from '../actions/countryActions'
import { COUNTRY_CREATE_RESET } from '../constants/countryConstants'
import Error from '../components/Error'
import { allCountryList } from '../data/zones'

const CountryCreateModal = () => {
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [countryCode, setCountryCode]=useState('')
    const [phoneCode, setPhoneCode]=useState('')
    const [zone, setZone] = useState('')
    const [show, setShow] = useState(false)
    const {loading, error, success} = useSelector(state=>state.country.Create)

    useEffect(()=>{
        if(success){
            setName('')
            setCountryCode('')
            setPhoneCode('')
            setZone('')
            setShow(false)
        }
        return()=>{
            dispatch({type: COUNTRY_CREATE_RESET})
        }
    },[success, dispatch])

    const handleClose = () => {
        setName('')
        setCountryCode('')
        setPhoneCode('')
        setZone('')
        setShow(false)
    }

    const handleSubmit = () => {
        const newCountry = {
            name,
            countryCode,
            phoneCode,
            zone
        }
        dispatch(addNewCountry(newCountry))
    }


    return (
        <>
            <Button size='sm' w='150px' colorScheme={'blue'} onClick={()=>setShow(true)} >Add New Country</Button>

            <Modal isOpen={show} onClose={handleClose} size='lg' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                   Add New Country
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Box>
                        {error && <Error error={error} /> }
                    </Box>
                    <Stack spacing={'3'}>  
                        
                        <Stack spacing={'0'}>
                            <Box>Country Name</Box>
                            <Select placeholder='Select' size='sm' id='country-select' onChange={(e)=>{
                                const country = allCountryList[e.target.value]
                                setName(country.name)
                                setCountryCode(country.countryCode)
                            }}>
                                <option value=''>Select</option>
                                {allCountryList.map((c,i)=>(
                                    <option key={c.name} value={i}>{c.name}</option>
                                ))}
                            </Select>
                        </Stack>
                        <Stack spacing={'0'}>
                            <Box>Country Code</Box>
                            <Input 
                                type={'text'}
                                readOnly
                                id='country-code'
                                size='sm'
                                defaultValue={countryCode}
                                //onChange={e=>setName(e.target.value)}
                            />
                        </Stack>
                        <Stack spacing={'0'}>
                            <Box>Phone Code</Box>
                            <Input 
                                type={'text'}
                                id='phone-code'
                                size='sm'
                                value={phoneCode}
                                onChange={e=>setPhoneCode(e.target.value)}
                            />
                        </Stack>
                        <Stack spacing={'0'}>
                            <Box>Zone</Box>
                            <Input 
                                type={'number'}
                                id='zone'
                                size='sm'
                                value={zone}
                                onChange={e=>setZone(e.target.value)}
                            />
                        </Stack>

                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button size='sm' w='150px' colorScheme={'green'} onClick={handleSubmit} >Save</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default CountryCreateModal