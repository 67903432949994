import React, {useEffect} from 'react'
import { Box, Center, Spinner, Table, Tbody, Td, Th, Thead, Tr, Button } from '@chakra-ui/react'
import Error from '../components/Error'
import {useDispatch, useSelector} from 'react-redux'
import { getListDashboard, deleteDashboardData } from '../actions/dashboardActions'
// import ConfirmDeleteModal from '../components/ConfirmDeleteModal'
import AdminDashboardDataViewModal from './AdminDashboardDataViewModal'

const AdminDashboardListScreen = () => {
    const dispatch = useDispatch()

    const { loading, dataList, error } = useSelector(state=>state.system.listData)
    const { loading:deleteLoading, success, error:deleteError } = useSelector(state=>state.system.deleteData)

    useEffect(()=>{
        dispatch(getListDashboard())
    },[dispatch, success])

    return (
        <Center my='4'>
            <Box boxShadow={'lg'} p='3'>
                {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                {deleteLoading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                {error && <Error error={error} /> }                
                {deleteError && <Error error={deleteError} /> }                

                <Box>
                  <Table variant='striped' size='sm' colorScheme='teal' my='4'>
                      <Thead>
                          <Tr>
                          <Th>NO</Th>
                          <Th>ID</Th>
                          <Th>MONTH</Th>
                          <Th>RUN AT</Th>
                          <Th>VIEW</Th>
                          <Th>DELETE</Th>
                          </Tr>
                      </Thead>
                      <Tbody>
                          {dataList && dataList.map((dt, idx)=>(
                          <Tr key={idx}>
                              <Td>{idx+1}</Td>
                              <Td>{dt._id}</Td>
                              <Td>{dt.month}</Td>
                              <Td>{new Date(dt.createdAt).toLocaleString()}</Td>
                              <Td><AdminDashboardDataViewModal dashboardData={dt} /></Td>
                              <Td>
                                <Button
                                    size={'xs'}
                                    variant='danger'
                                    onClick={()=>dispatch(deleteDashboardData(dt._id))}
                                >Delete</Button>
                              </Td>
                              {/* <Td><ConfirmDeleteModal width={'60px'} lefticon={null} id={dt._id} deleteFunction={deleteCharges} /></Td> */}
                          </Tr>
                          ))}
                      </Tbody>
                  </Table>
                </Box>              
                
            </Box>
        </Center>
    )
}

export default AdminDashboardListScreen