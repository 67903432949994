export const CHARGES_LIST_REQUEST = 'CHARGES_LIST_REQUEST'
export const CHARGES_LIST_SUCCESS = 'CHARGES_LIST_SUCCESS'
export const CHARGES_LIST_FAIL = 'CHARGES_LIST_FAIL'
export const CHARGES_LIST_RESET = 'CHARGES_LIST_RESET'

export const CHARGES_CREATE_REQUEST = 'CHARGES_CREATE_REQUEST'
export const CHARGES_CREATE_SUCCESS = 'CHARGES_CREATE_SUCCESS'
export const CHARGES_CREATE_FAIL = 'CHARGES_CREATE_FAIL'
export const CHARGES_CREATE_RESET = 'CHARGES_CREATE_RESET'

export const CHARGES_UPDATE_REQUEST = 'CHARGES_UPDATE_REQUEST'
export const CHARGES_UPDATE_SUCCESS = 'CHARGES_UPDATE_SUCCESS'
export const CHARGES_UPDATE_FAIL = 'CHARGES_UPDATE_FAIL'
export const CHARGES_UPDATE_RESET = 'CHARGES_UPDATE_RESET'

export const CHARGES_DELETE_REQUEST = 'CHARGES_DELETE_REQUEST'
export const CHARGES_DELETE_SUCCESS = 'CHARGES_DELETE_SUCCESS'
export const CHARGES_DELETE_FAIL = 'CHARGES_DELETE_FAIL'
export const CHARGES_DELETE_RESET = 'CHARGES_DELETE_RESET'