import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
    Box,
    Heading,
    Text,
    Stack,
    HStack,
    Button,
    Container,
} from '@chakra-ui/react'
import {MdExtensionOff, MdHome} from 'react-icons/md'

const NotFoundPage = () => {
    const navigate = useNavigate()
    const {user} = useSelector(state=>state.login)
    
    useEffect(()=>{
        if(!user){
            navigate('/')
        }
    },[user, navigate])

    return (
        <Box maxW='100%' mt='100px' border >
            <Container maxW='container.md'>
                <Stack spacing={6}>
                    <HStack>
                        <MdExtensionOff size='70px' />
                        <Heading as='h1' size='4xl'>404</Heading>
                    </HStack>
                    
                    <Text fontSize='3xl'>PAGE NOT FOUND  စာမျက်နှာရှာမတွေ့ပါ</Text>
                    <Link to='/' >
                        <Button leftIcon={<MdHome />}>Back to Home</Button>
                    </Link>
                </Stack>
            </Container>
        </Box>
    )
}

export default NotFoundPage