import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import Pagination from '../../../components/Pagination'
import SearchBox from '../../../components/SearchBox'
import {
    Box,
    HStack,
    Stack,
    Select,
    Divider,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Center,
    Heading,
    useBreakpointValue,
    SimpleGrid,
    GridItem,
    Flex,

} from '@chakra-ui/react'
import { filterTHPInvoices, listTHPInvoices } from '../../../actions/thailand_post_actions/thpInvoiceActions'
import { MdCheckCircle, MdCancel, MdIncompleteCircle } from 'react-icons/md'
import Error from '../../../components/Error'
import useWindowSize from '../../../components/WindowSize'
import Filter from '../../../components/Filter'
import MySpinner from '../../../components/MySpinner'


const PostInvoiceListScreen = () => {
    const size = useWindowSize()
    const colSpan = useBreakpointValue({base: 2, md: 1})
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const keyword = params.keyword || ''
    const pageNumber = params.pageNumber || 1
    const [itemCount, setItemCount] = useState(50)    


    const {loading, error, invoices, pages, page} = useSelector(state=>state.thailandPost.InvoiceList)

    useEffect(()=>{
        dispatch(listTHPInvoices(pageNumber, itemCount, keyword))
    },[dispatch, pageNumber, itemCount, keyword])


    
    const checkApprovedPayments = (payments) => {
        if(payments.length === 0){
            return 0
        }
        var approvedTotal = 0
        payments.forEach(p=>{
            if(p.isApproved){
                approvedTotal = approvedTotal + p.amount
            }
        })
       
        return Number(approvedTotal)        
    }
    

    return (
        <Box mt='3'>
                <Center mb='3'><Heading bg='blue.200' px='2' borderRadius={10}>Thailand Post Invoices {loading && <MySpinner />}</Heading></Center>
                {error && <Error error={error} /> }
                <Divider/>

                <SimpleGrid columns={2} columnGap={3} rowGap={3} my='3'>
                    <GridItem colSpan={colSpan}>
                        <SearchBox width='full' />
                    </GridItem>
                    <GridItem ml='auto'>
                        <HStack>
                            <Button size='sm' colorScheme={'green'} onClick={()=>navigate('/thpcreateinvoice')}>New Invoice</Button>
                            <Select placeholder='Select' w='80px' onChange={e=>setItemCount(e.target.value)} size='sm'>
                                {[50, 100, 150, 200].map((v,i)=>(
                                    <option key={i} value={v}>{v}</option>
                                ))}
                            </Select>  
                            <Pagination keyword = {keyword} pages={pages} page={page} path='thpinvoices' />
                        </HStack>
                    </GridItem>
                </SimpleGrid>
                
                <Filter filterFunction={filterTHPInvoices} invoiceShow={false} scanShow={false} />                
                
                {size.width > 760 ? 
                    <Table size='sm' variant='striped' colorScheme='purple' my='4'>
                        <Thead>
                            <Tr>
                            <Th>No</Th>                          
                            <Th>Agent</Th>
                            <Th>Inovice</Th>
                            <Th isNumeric>Total Amount</Th>
                            <Th isNumeric>Balance</Th>
                            <Th isNumeric>Due Date</Th>
                            <Th isNumeric>Shipment Qty</Th>
                            <Th>Paid</Th>
                            <Th>Detail</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {invoices && invoices.map((iv, idx)=>(
                            <Tr key={idx}>
                                <Td>{pageNumber === 1 ? (idx+1) : (((Number(pageNumber)-1) * itemCount) + (idx+1))}</Td>
                                <Td>{iv.agent.name}</Td>
                                <Td>{iv.code}</Td>
                                <Td isNumeric>{iv.totalAmount.toLocaleString()}</Td>
                                <Td isNumeric color='red' fontWeight={'600'}>{iv.totalAmount - checkApprovedPayments(iv.payments)}</Td>
                                <Td isNumeric>{iv.dueDate.substring(0,10)}</Td>                              
                                <Td isNumeric>{iv.shipmentList.length}</Td>
                                {/* <Td><Box bg={iv.isPaid ? 'green' : (!iv.isPaid && iv.payments.length !== 0) ? 'orange' : 'red'} h='20px' w='20px'></Box></Td> */}
                                <Td>{iv.isPaid ? < MdCheckCircle size={20} color='green' /> : (!iv.isPaid && iv.payments.length !== 0) ? <MdIncompleteCircle size={20} color='orange' /> : < MdCancel size={20} color='red' />}</Td>
                                <Td>
                                    <Link to={`/thpinvoices/${iv._id}`}>
                                    <Button size='sm' variant={'link'} >Details</Button>
                                    </Link>
                                </Td>
                            </Tr>
                            ))}
                        </Tbody>
                    </Table>
                  :
                    <Table size='sm' variant='striped' colorScheme='purple' my='4'>
                        <Thead>
                            <Tr>
                            <Th>No</Th>                          
                            <Th>Summary</Th>
                            <Th>Detail</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {invoices && invoices.map((iv, idx)=>(
                            <Tr key={idx}>
                                <Td>{pageNumber === 1 ? (idx+1) : (((Number(pageNumber)-1) * itemCount) + (idx+1))}</Td>
                                <Td>
                                    <Stack spacing={0}>
                                        <Box>Agent - {iv.agent.name}</Box>
                                        <Box>Code - {iv.code}</Box>
                                        <Box>Amount - {iv.totalAmount.toLocaleString()}</Box>
                                        <Box color='red' fontWeight={'600'}>Balance - {iv.totalAmount - checkApprovedPayments(iv.payments)}</Box>
                                        <Box>Due Date - {iv.dueDate.substring(0,10)}</Box>
                                        <Box>Shipment Qty - {iv.shipmentList.length}</Box>
                                        <Flex>Paid - {iv.isPaid ? < MdCheckCircle size={20} color='green' /> : (!iv.isPaid && iv.payments.length !== 0) ? <MdIncompleteCircle size={20} color='orange' /> : < MdCancel size={20} color='red' />}</Flex>
                                    </Stack>
                                </Td>
                                <Td>
                                    <Link to={`/thpinvoices/${iv._id}`}>
                                    Details
                                    </Link>
                                </Td>
                            </Tr>
                            ))}
                        </Tbody>
                    </Table>
                }
        </Box>
    )
}

export default PostInvoiceListScreen