import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Input,
    Box,
    Text,
    Stack,
    Spinner,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    InputGroup,
    InputRightElement
} from '@chakra-ui/react'
import { changeMyPassword, logoutUser } from '../actions/userActions'

const UserChangePasswordModal = () => {
    const dispatch = useDispatch()
    const [newPassword, setNewPassword] = useState('')
    const [newPassword2, setNewPassword2] = useState('')
    const [show, setShow] = useState(false)
    const [view, setView] = useState(false)
    const [warning, setWarning] = useState(false)
    const {user} = useSelector(state=>state.login)
    const {loading, error, success} = useSelector(state=>state.user.MyPassword)

    useEffect(()=>{
        if(success){
            setShow(false)
            setNewPassword('')
            setNewPassword2('')
            setView(false)
            setWarning(false)
            dispatch(logoutUser())
        }
    },[success, dispatch])

    const handleClose = () => {        
        setNewPassword('')
        setNewPassword2('')
        setView(false)
        setWarning(false)
        setShow(false)
    }

    const checkPasswordMatch = () => {
        if(newPassword2 === ''){
            return true
        } else {
            if(newPassword === newPassword2){
                return true
            } else {
                return false
            }
        }        
    }

    const handleSubmit = () => {
        //console.log(newPassword)
        if(newPassword === '' || newPassword.length < 8){
            setWarning(true)
            return
        }
        setWarning(false)
        const passData = {
            newPassword,
            userId: user._id
        }
        dispatch(changeMyPassword(passData))
    }


    return (
        <>
            <Button size='sm'  w='150px' colorScheme={'blue'} onClick={()=>setShow(true)} >Change Password</Button>

            <Modal isOpen={show} isCentered onClose={handleClose} size='xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Change Password</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Box my='3'>
                        {error && 
                            <Alert status='error' variant='left-accent'>
                            <AlertIcon />
                            <AlertTitle mr={2}>Error creating Payment.</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        }

                        {warning && 
                            <Alert status='error' variant='left-accent'>
                            <AlertIcon />
                            <AlertTitle mr={2}>Password</AlertTitle>
                            <AlertDescription>Password can not be empty or less than 8 characters </AlertDescription>
                            </Alert>
                        }
                    </Box>       

                    <Alert status='info' variant='left-accent' mb='20px'>
                        <AlertIcon />
                        <AlertTitle mr={2}>Note</AlertTitle>
                        <AlertDescription>You will be logged out after changing to new password.</AlertDescription>
                    </Alert>                

                    <Stack my='3'>                        
                        <Text>New Password</Text>
                        <InputGroup size='sm' my='4'>
                            <Input  
                                type={view ? 'text' : 'password'}
                                value={newPassword}
                                onChange={e=>setNewPassword(e.target.value)}
                                />
                            <InputRightElement width='4.5rem'>
                                <Button  size='xs' onClick={()=>setView(!view)}>
                                    {view ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup> 
                    </Stack>
                    

                    <Stack my='2'>
                        <Text>Confirm New Password</Text>
                        <InputGroup size='sm' my='4'>
                            <Input  
                                type={view ? 'text' : 'password'}
                                value={newPassword2}
                                onChange={e=>setNewPassword2(e.target.value)}
                                onKeyPress={()=>checkPasswordMatch()}
                                />
                            <InputRightElement width='4.5rem'>
                                <Button  size='xs' onClick={()=>setView(!view)}>
                                    {view ? 'Hide' : 'Show'}
                                </Button>
                            </InputRightElement>
                        </InputGroup> 
                    </Stack>

                    {!checkPasswordMatch() &&
                        <Box color='red' mt='20px'>Passwords are not matching</Box>
                    }

                </ModalBody>

                <ModalFooter>
                    <Button 
                        disabled={!checkPasswordMatch()}
                        size='sm' w='150px' 
                        colorScheme={'green'} 
                        onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default UserChangePasswordModal