import axios from 'axios'
import {
    ACCOUNTING_SALE_REQUEST,
    ACCOUNTING_SALE_SUCCESS,
    ACCOUNTING_SALE_FAIL,
    ACCOUNTING_SALE_DETAIL_REQUEST,
    ACCOUNTING_SALE_DETAIL_SUCCESS,
    ACCOUNTING_SALE_DETAIL_FAIL,
} from '../../constants/accounting_constants/accountingConstants'

export const getAccountingSaleData = (monthCount) => async (dispatch, getState) => {
    try {
        dispatch({type: ACCOUNTING_SALE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/accounting/sale?monthCount=${monthCount}`, config)
        dispatch({type: ACCOUNTING_SALE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ACCOUNTING_SALE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getAccountingSaleDetailData = (saleData) => async (dispatch, getState) => {
    try {
        dispatch({type: ACCOUNTING_SALE_DETAIL_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/accounting/sale/detail`, saleData, config)
        dispatch({type: ACCOUNTING_SALE_DETAIL_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ACCOUNTING_SALE_DETAIL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}