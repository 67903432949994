import React, {useEffect} from 'react'
import {
  Box, 
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  Thead,
  Th,
  Heading,
  SimpleGrid,
  GridItem,
  useBreakpointValue,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Divider,
} from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import { getAgentDashboard } from '../../actions/dashboardActions'
import Error from '../../components/Error'
import MySpinner from '../../components/MySpinner'

const AgentDashboardScreen = () => {
  const dispatch = useDispatch()
  const colSpan = useBreakpointValue({base: 3, md: 1})
  const {user} = useSelector(state=>state.login)
  const { error, loading, dashboardData} = useSelector(state=>state.system.agentDashboard)

  useEffect(()=>{
    dispatch(getAgentDashboard(user._id))

  },[dispatch, user])

  const getPrevMonths = (num) => {
    const current = new Date()
    current.setMonth(current.getMonth()-num);
    const month = current.toLocaleString('default', { month: 'long' });
    return month
  }

  return (
    <Box my='5'>

      <Heading mb='3' fontWeight={'700'} borderBottom='1px'>Agent Dashboard {loading && <MySpinner />}</Heading>  
      {error && <Error error={error} /> }
        
      {dashboardData && 
        <>
        <SimpleGrid columns={3} columnGap={3} rowGap={3} my='3' px='3'>
          <GridItem colSpan={colSpan}>
            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
              <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Shipments</Text> 
              <Table size='xs'fontSize={'sm'} colorScheme={'blue'}  >
                <Thead>
                  <Tr>
                    <Th>Description</Th>
                    <Th isNumeric>Qty (awb)</Th>
                    <Th isNumeric>Value (thb)</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Total</Td>
                    <Td isNumeric>{dashboardData.total.totalShipments}</Td>
                    <Td isNumeric>{dashboardData.total.valueShipments.toLocaleString()}</Td>
                  </Tr>
                  <Tr>
                    <Td >Paid</Td>
                    <Td isNumeric>{dashboardData.total.totalPaidShipments}</Td>
                    <Td isNumeric>{dashboardData.total.valuePaidShipments.toLocaleString()}</Td>
                  </Tr>
                  <Tr color='red'>
                    <Td>Unpaid</Td>
                    <Td isNumeric>{dashboardData.total.totalShipments-dashboardData.total.totalPaidShipments}</Td>
                    <Td isNumeric>{(dashboardData.total.valueShipments-dashboardData.total.valuePaidShipments).toLocaleString()}</Td>
                  </Tr>
                  <Tr>
                    <Td>Invoiced</Td>
                    <Td isNumeric>{dashboardData.total.totalInvoicedShipments}</Td>
                    <Td isNumeric>{dashboardData.total.valueInvoicedShipments.toLocaleString()}</Td>
                  </Tr>
                  <Tr color='red'>
                    <Td >Uninvoiced</Td>
                    <Td isNumeric>{dashboardData.total.totalShipments-dashboardData.total.totalInvoicedShipments}</Td>
                    <Td isNumeric>{(dashboardData.total.valueShipments-dashboardData.total.valueInvoicedShipments).toLocaleString()}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </GridItem>

          <GridItem colSpan={colSpan}>
            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
              <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Invoices</Text> 
              <Table size='xs'fontSize={'sm'} colorScheme={'blue'} >
                <Thead>
                  <Tr>
                    <Th>Description</Th>
                    <Th isNumeric>Qty (pcs)</Th>
                    <Th isNumeric>Value (thb)</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Total</Td>
                    <Td isNumeric>{dashboardData.total.totalInvoices}</Td>
                    <Td isNumeric>{dashboardData.total.valueInvoices.toLocaleString()}</Td>
                  </Tr>
                  <Tr>
                    <Td>Paid</Td>
                    <Td isNumeric>{dashboardData.total.totalPaidInvoices}</Td>
                    <Td isNumeric>{dashboardData.total.valuePaidInvoices.toLocaleString()}</Td>
                  </Tr>
                  <Tr color='red'>
                    <Td>Unpaid</Td>
                    <Td isNumeric>{dashboardData.total.totalInvoices-dashboardData.total.totalPaidInvoices}</Td>
                    <Td isNumeric>{(dashboardData.total.valueInvoices-dashboardData.total.valuePaidInvoices).toLocaleString()}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </GridItem>

          <GridItem colSpan={colSpan}>
            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
              <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Payments</Text> 
              <Table size='xs'fontSize={'sm'} colorScheme={'blue'} >
                <Thead>
                  <Tr>
                    <Th>Description</Th>
                    <Th isNumeric>Qty (pcs)</Th>
                    <Th isNumeric>Value (thb)</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>Total</Td>
                    <Td isNumeric>{dashboardData.total.totalPayments}</Td>
                    <Td isNumeric>{dashboardData.total.valuePayments.toLocaleString()}</Td>
                  </Tr>
                  <Tr>
                    <Td>Approved</Td>
                    <Td isNumeric>{dashboardData.total.totalApprovedPayments}</Td>
                    <Td isNumeric>{dashboardData.total.valueApprovedPayments.toLocaleString()}</Td>
                  </Tr>
                  <Tr color='red'>
                    <Td>Unapproved</Td>
                    <Td isNumeric>{dashboardData.total.totalPayments-dashboardData.total.totalApprovedPayments}</Td>
                    <Td isNumeric>{(dashboardData.total.valuePayments-dashboardData.total.valueApprovedPayments).toLocaleString()}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </GridItem>
        </SimpleGrid>

        <Divider />

        <Tabs variant='enclosed' my='5'>
          <TabList>
            <Tab>{getPrevMonths(0)}</Tab>            
            <Tab>{getPrevMonths(1)}</Tab>
            <Tab>{getPrevMonths(2)}</Tab>
          </TabList>
          <TabPanels>

            {/* this month */}
            <TabPanel>
              <SimpleGrid columns={3} columnGap={3} rowGap={3}>
                <GridItem colSpan={colSpan}>
                  <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                    <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Shipments</Text> 
                    <Table size='xs'fontSize={'sm'} colorScheme={'blue'}  >
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th isNumeric>Qty (awb)</Th>
                          <Th isNumeric>Value (thb)</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Total</Td>
                          <Td isNumeric>{dashboardData.thisMonth.totalShipments}</Td>
                          <Td isNumeric>{dashboardData.thisMonth.valueShipments.toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Paid</Td>
                          <Td isNumeric>{dashboardData.thisMonth.totalPaidShipments}</Td>
                          <Td isNumeric>{dashboardData.thisMonth.valuePaidShipments.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td>Unpaid</Td>
                          <Td isNumeric>{dashboardData.thisMonth.totalShipments-dashboardData.thisMonth.totalPaidShipments}</Td>
                          <Td isNumeric>{(dashboardData.thisMonth.valueShipments-dashboardData.thisMonth.valuePaidShipments).toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Invoiced</Td>
                          <Td isNumeric>{dashboardData.thisMonth.totalInvoicedShipments}</Td>
                          <Td isNumeric>{dashboardData.thisMonth.valueInvoicedShipments.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td >Uninvoiced</Td>
                          <Td isNumeric>{dashboardData.thisMonth.totalShipments-dashboardData.thisMonth.totalInvoicedShipments}</Td>
                          <Td isNumeric>{(dashboardData.thisMonth.valueShipments-dashboardData.thisMonth.valueInvoicedShipments).toLocaleString()}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </GridItem>

                <GridItem colSpan={colSpan}>
                  <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                    <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Invoices</Text> 
                    <Table size='xs'fontSize={'sm'} colorScheme={'blue'} >
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th isNumeric>Qty (pcs)</Th>
                          <Th isNumeric>Value (thb)</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Total</Td>
                          <Td isNumeric>{dashboardData.thisMonth.totalInvoices}</Td>
                          <Td isNumeric>{dashboardData.thisMonth.valueInvoices.toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Paid</Td>
                          <Td isNumeric>{dashboardData.thisMonth.totalPaidInvoices}</Td>
                          <Td isNumeric>{dashboardData.thisMonth.valuePaidInvoices.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td>Unpaid</Td>
                          <Td isNumeric>{dashboardData.thisMonth.totalInvoices-dashboardData.thisMonth.totalPaidInvoices}</Td>
                          <Td isNumeric>{(dashboardData.thisMonth.valueInvoices-dashboardData.thisMonth.valuePaidInvoices).toLocaleString()}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </GridItem>

                <GridItem colSpan={colSpan}>
                  <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                    <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Payments</Text> 
                    <Table size='xs'fontSize={'sm'} colorScheme={'blue'} >
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th isNumeric>Qty (pcs)</Th>
                          <Th isNumeric>Value (thb)</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Total</Td>
                          <Td isNumeric>{dashboardData.thisMonth.totalPayments}</Td>
                          <Td isNumeric>{dashboardData.thisMonth.valuePayments.toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Approved</Td>
                          <Td isNumeric>{dashboardData.thisMonth.totalApprovedPayments}</Td>
                          <Td isNumeric>{dashboardData.thisMonth.valueApprovedPayments.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td>Unapproved</Td>
                          <Td isNumeric>{dashboardData.thisMonth.totalPayments-dashboardData.thisMonth.totalApprovedPayments}</Td>
                          <Td isNumeric>{(dashboardData.thisMonth.valuePayments-dashboardData.thisMonth.valueApprovedPayments).toLocaleString()}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </GridItem>
              </SimpleGrid>
            </TabPanel>

            {/* last month */}
            <TabPanel>
            <SimpleGrid columns={3} columnGap={3} rowGap={3}>
                <GridItem colSpan={colSpan}>
                  <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                    <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Shipments</Text> 
                    <Table size='xs'fontSize={'sm'} colorScheme={'blue'}  >
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th isNumeric>Qty (awb)</Th>
                          <Th isNumeric>Value (thb)</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Total</Td>
                          <Td isNumeric>{dashboardData.lastMonth.totalShipments}</Td>
                          <Td isNumeric>{dashboardData.lastMonth.valueShipments.toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Paid</Td>
                          <Td isNumeric>{dashboardData.lastMonth.totalPaidShipments}</Td>
                          <Td isNumeric>{dashboardData.lastMonth.valuePaidShipments.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td>Unpaid</Td>
                          <Td isNumeric>{dashboardData.lastMonth.totalShipments-dashboardData.lastMonth.totalPaidShipments}</Td>
                          <Td isNumeric>{(dashboardData.lastMonth.valueShipments-dashboardData.lastMonth.valuePaidShipments).toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Invoiced</Td>
                          <Td isNumeric>{dashboardData.lastMonth.totalInvoicedShipments}</Td>
                          <Td isNumeric>{dashboardData.lastMonth.valueInvoicedShipments.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td >Uninvoiced</Td>
                          <Td isNumeric>{dashboardData.lastMonth.totalShipments-dashboardData.lastMonth.totalInvoicedShipments}</Td>
                          <Td isNumeric>{(dashboardData.lastMonth.valueShipments-dashboardData.lastMonth.valueInvoicedShipments).toLocaleString()}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </GridItem>

                <GridItem colSpan={colSpan}>
                  <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                    <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Invoices</Text> 
                    <Table size='xs'fontSize={'sm'} colorScheme={'blue'} >
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th isNumeric>Qty (pcs)</Th>
                          <Th isNumeric>Value (thb)</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Total</Td>
                          <Td isNumeric>{dashboardData.lastMonth.totalInvoices}</Td>
                          <Td isNumeric>{dashboardData.lastMonth.valueInvoices.toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Paid</Td>
                          <Td isNumeric>{dashboardData.lastMonth.totalPaidInvoices}</Td>
                          <Td isNumeric>{dashboardData.lastMonth.valuePaidInvoices.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td>Unpaid</Td>
                          <Td isNumeric>{dashboardData.lastMonth.totalInvoices-dashboardData.lastMonth.totalPaidInvoices}</Td>
                          <Td isNumeric>{(dashboardData.lastMonth.valueInvoices-dashboardData.lastMonth.valuePaidInvoices).toLocaleString()}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </GridItem>

                <GridItem colSpan={colSpan}>
                  <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                    <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Payments</Text> 
                    <Table size='xs'fontSize={'sm'} colorScheme={'blue'} >
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th isNumeric>Qty (pcs)</Th>
                          <Th isNumeric>Value (thb)</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Total</Td>
                          <Td isNumeric>{dashboardData.lastMonth.totalPayments}</Td>
                          <Td isNumeric>{dashboardData.lastMonth.valuePayments.toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Approved</Td>
                          <Td isNumeric>{dashboardData.lastMonth.totalApprovedPayments}</Td>
                          <Td isNumeric>{dashboardData.lastMonth.valueApprovedPayments.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td>Unapproved</Td>
                          <Td isNumeric>{dashboardData.lastMonth.totalPayments-dashboardData.lastMonth.totalApprovedPayments}</Td>
                          <Td isNumeric>{(dashboardData.lastMonth.valuePayments-dashboardData.lastMonth.valueApprovedPayments).toLocaleString()}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </GridItem>
              </SimpleGrid>
            </TabPanel>

            {/* previous month */}
            <TabPanel>
              <SimpleGrid columns={3} columnGap={3} rowGap={3}>

                <GridItem colSpan={colSpan}>
                  <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                    <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Shipments</Text> 
                    <Table size='xs'fontSize={'sm'} colorScheme={'blue'}  >
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th isNumeric>Qty (awb)</Th>
                          <Th isNumeric>Value (thb)</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Total</Td>
                          <Td isNumeric>{dashboardData.prevMonth.totalShipments}</Td>
                          <Td isNumeric>{dashboardData.prevMonth.valueShipments.toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Paid</Td>
                          <Td isNumeric>{dashboardData.prevMonth.totalPaidShipments}</Td>
                          <Td isNumeric>{dashboardData.prevMonth.valuePaidShipments.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td>Unpaid</Td>
                          <Td isNumeric>{dashboardData.prevMonth.totalShipments-dashboardData.prevMonth.totalPaidShipments}</Td>
                          <Td isNumeric>{(dashboardData.prevMonth.valueShipments-dashboardData.prevMonth.valuePaidShipments).toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Invoiced</Td>
                          <Td isNumeric>{dashboardData.prevMonth.totalInvoicedShipments}</Td>
                          <Td isNumeric>{dashboardData.prevMonth.valueInvoicedShipments.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td >Uninvoiced</Td>
                          <Td isNumeric>{dashboardData.prevMonth.totalShipments-dashboardData.prevMonth.totalInvoicedShipments}</Td>
                          <Td isNumeric>{(dashboardData.prevMonth.valueShipments-dashboardData.prevMonth.valueInvoicedShipments).toLocaleString()}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </GridItem>

                <GridItem colSpan={colSpan}>
                  <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                    <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Invoices</Text> 
                    <Table size='xs'fontSize={'sm'} colorScheme={'blue'} >
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th isNumeric>Qty (pcs)</Th>
                          <Th isNumeric>Value (thb)</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Total</Td>
                          <Td isNumeric>{dashboardData.prevMonth.totalInvoices}</Td>
                          <Td isNumeric>{dashboardData.prevMonth.valueInvoices.toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Paid</Td>
                          <Td isNumeric>{dashboardData.prevMonth.totalPaidInvoices}</Td>
                          <Td isNumeric>{dashboardData.prevMonth.valuePaidInvoices.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td>Unpaid</Td>
                          <Td isNumeric>{dashboardData.prevMonth.totalInvoices-dashboardData.prevMonth.totalPaidInvoices}</Td>
                          <Td isNumeric>{(dashboardData.prevMonth.valueInvoices-dashboardData.prevMonth.valuePaidInvoices).toLocaleString()}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </GridItem>

                <GridItem colSpan={colSpan}>
                  <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                    <Text fontSize={'xl'} mb='2' fontWeight={'700'} borderBottom='1px inset'>Payments</Text> 
                    <Table size='xs'fontSize={'sm'} colorScheme={'blue'} >
                      <Thead>
                        <Tr>
                          <Th>Description</Th>
                          <Th isNumeric>Qty (pcs)</Th>
                          <Th isNumeric>Value (thb)</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Total</Td>
                          <Td isNumeric>{dashboardData.prevMonth.totalPayments}</Td>
                          <Td isNumeric>{dashboardData.prevMonth.valuePayments.toLocaleString()}</Td>
                        </Tr>
                        <Tr>
                          <Td>Approved</Td>
                          <Td isNumeric>{dashboardData.prevMonth.totalApprovedPayments}</Td>
                          <Td isNumeric>{dashboardData.prevMonth.valueApprovedPayments.toLocaleString()}</Td>
                        </Tr>
                        <Tr color='red'>
                          <Td>Unapproved</Td>
                          <Td isNumeric>{dashboardData.prevMonth.totalPayments-dashboardData.prevMonth.totalApprovedPayments}</Td>
                          <Td isNumeric>{(dashboardData.prevMonth.valuePayments-dashboardData.prevMonth.valueApprovedPayments).toLocaleString()}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </GridItem>

              </SimpleGrid>
            </TabPanel>
          </TabPanels>
        </Tabs>
        </>
        }
        
    </Box>
  )
}

export default AgentDashboardScreen