import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Input,
    Button,
    Spinner,
    useToast,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    FormControl,
    Center,
    Box,
    Divider,
    Heading,
    HStack
  } from '@chakra-ui/react'
import { AGENTRATE_DELETE_RESET, AGENTRATE_EDIT_RESET } from '../constants/agentRateConstants'
import { editAgentRate, getByIdAgentRate, deleteAgentRate } from '../actions/agentRateActions'
import parse from 'paste-from-excel'
import Error from '../components/Error'
import { useNavigate, useParams } from 'react-router-dom'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'

const AgentRateEditScreen = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const toast = useToast()
    const navigate = useNavigate()
    const [agRate, setAgRate] = useState({
        rates: [
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
            {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},           
        ]
    })
    const zoneslist = ['zone1','zone2','zone3','zone4','zone5','zone6','zone7','zone8','zone9']
    const nameList = ['Doc', '0.5-5', '5.1-10','10.1-15','15.1-20','20.1-25','25.1-30','30.1-70','70.1-300','300.1-1000']
    //const kgList = ['doc', 'p0', 'p5', 'p10', 'p15', 'p20', 'p25', 'p30', 'p70', 'p300']
    const zoneNames = ['Zone 1','Zone 2','Zone 3','Zone 4','Zone 5','Zone 6','Zone 7','Zone 8','Zone 9']
    const kgMap = ['doc', 'p0', 'p5','p10','p15','p20','p25','p30','p70','p300']
    const {loading, error, agentrate} = useSelector(state=>state.agentRate.ById)
    const {loading: loadingDelete, error: errorDelete, success: successDelete} = useSelector(state=>state.agentRate.Delete)
    const {loading: editLoading, error: editError, success: editSuccess} = useSelector(state=>state.agentRate.Edit)

    const handlePaste = (e) => {
        return parse(e)
    }
    const handlePasteRate = (index, z, e, i) => {
        setAgRate((agRate)=>({
            ...agRate,
            rates: agRate.rates.map((item, i)=> index === i ? {...item, [z]: e.target.value} : item)
        }))
    }


    useEffect(()=>{
        if(!agentrate.name){
            dispatch(getByIdAgentRate(params.id))
        }
        if(editSuccess){
            toast({
                status: 'success',
                position: 'top',
                title: 'success !',
                duration: 2000,
                isClosable: true,
            })       
            navigate('/agentrate')                 
        }
        if(successDelete){
            navigate('/agentrate')  
        }

        return()=>{
            dispatch({type: AGENTRATE_EDIT_RESET})
            dispatch({type: AGENTRATE_DELETE_RESET})
        }
        // eslint-disable-next-line
    },[editSuccess, dispatch, params, toast, navigate, successDelete])

    const handleSubmit = () => {
        var editedAgentRate = {}
        editedAgentRate.name = agentrate.name        
        var kgList = ['doc', 'p0', 'p5', 'p10', 'p15', 'p20', 'p25', 'p30', 'p70', 'p300']
        for(let i = 0; i < agRate.rates.length ; i++){
            editedAgentRate[kgList[i]]=agRate.rates[i]
        }
        //console.log(editedAgentRate)
        dispatch(editAgentRate(agentrate._id, editedAgentRate))
    }


    return (
        
        <Center mt='50px'>
            
            <Box spacing={4} w='1000px'>
                {(loading || editLoading || loadingDelete) && <Spinner label='Please wait' speed='0.1s' thickness='5px' /> }
                {editError && <Error error={editError} /> }
                {error && <Error error={error} /> }
                {errorDelete && <Error error={errorDelete} /> }
                <Box mb='50px'>
                    <Center><Heading>Old Rate</Heading></Center>
                    <HStack>
                        <Table size='sm' variant='striped' colorScheme='red' w='150px'>
                            <Thead>
                                <Tr>
                                    <Th>KG</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {nameList.map(n=>(
                                    <Tr key={n}><Td>{n}</Td></Tr>
                                ))}                        
                            </Tbody>
                        </Table>
                    
                        <Table size='sm' variant='striped' colorScheme='blue'>
                            <Thead>
                                <Tr>
                                    {zoneNames.map((zn) => (
                                        <Th key={zn}>{zn}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {agentrate && [0,1,2,3,4,5,6,7,8,9].map((v) =>  (
                                    <Tr key={v}>
                                        {[1,2,3,4,5,6,7,8,9].map(a=>(
                                            <Td key={a}>{agentrate[kgMap[v]]['zone'+a]}</Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </HStack>
                </Box>
                <Divider />
                <Box my='50px'>
                    <Center><Heading>New Rate</Heading></Center>
                    <HStack>
                        <Table size='sm' variant='striped' colorScheme='red' w='150px'>
                            <Thead>
                                <Tr>
                                    <Th>KG</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {nameList.map(n=>(
                                    <Tr key={n}><Td>{n}</Td></Tr>
                                ))}                        
                            </Tbody>
                        </Table>

                        <Table size='sm' variant='striped' colorScheme=''>
                            <Thead>
                                <Tr>
                                <Th textAlign='center'>zone1</Th>
                                <Th textAlign='center'>zone2</Th>
                                <Th textAlign='center'>zone3</Th>
                                <Th textAlign='center'>zone4</Th>
                                <Th textAlign='center'>zone5</Th>
                                <Th textAlign='center'>zone6</Th>
                                <Th textAlign='center'>zone7</Th>
                                <Th textAlign='center'>zone8</Th>
                                <Th textAlign='center'>zone9</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {agRate.rates.map((res, idx)=>(
                                    <Tr key={idx}>
                                        {zoneslist.map((z, i)=>(
                                            <Td key={i} p='0.9'>
                                                <FormControl border='1px'>
                                                    <Center>
                                                        <Input 
                                                            size='sm' 
                                                            h='28px'
                                                            w='90px'
                                                            textAlign='center' 
                                                            fontSize='14px' 
                                                            onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault()}}}
                                                            onInput={(e)=> {handlePasteRate(idx, z, e, i)}}
                                                            onPaste={e=>{handlePaste(e)}}
                                                            />
                                                    </Center>
                                                </FormControl>                                    
                                            </Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>    
                    </HStack>
                    
                </Box>

                <Center>
                    {agentrate && <ConfirmDeleteModal id={agentrate._id} deleteFunction={deleteAgentRate} width='100px' />}
                    <Button colorScheme='blue' w='100px' mx='30px' size='sm' onClick={()=>navigate('/agentrate')}>Cancel</Button>
                    <Button colorScheme='green' w='100px' size='sm' onClick={handleSubmit}>Save</Button>
                </Center>            
            </Box>
        </Center>
    )
}

export default AgentRateEditScreen