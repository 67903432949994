const carrierList = [
    {name: 'Nim Express', value: 'nim'},
    {name: 'Kerry Express', value: 'kerry'},
    {name: 'Flash', value: 'flash'},
    {name: 'Maesot', value: 'maesot'},
    {name: 'EMS', value: 'ems'},
    {name:'TEST', value: 'test'}
]

export {
    carrierList
}