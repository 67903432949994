import axios from 'axios'
import { 
    REJECTNAME_CREATE_FAIL,
    REJECTNAME_CREATE_REQUEST,
    REJECTNAME_CREATE_SUCCESS,
    REJECTNAME_DELETE_FAIL,
    REJECTNAME_DELETE_REQUEST,
    REJECTNAME_DELETE_SUCCESS,
    REJECTNAME_LIST_FAIL,
    REJECTNAME_LIST_REQUEST, 
    REJECTNAME_LIST_SUCCESS,
    REJECTNAME_UPDATE_FAIL,
    REJECTNAME_UPDATE_REQUEST,
    REJECTNAME_UPDATE_SUCCESS,


} from '../constants/rejectNameConstants'



export const getRejectNameList = () => async (dispatch, getState) => {
    try {
        dispatch({type: REJECTNAME_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/rejectname`, config)
        dispatch({type: REJECTNAME_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: REJECTNAME_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addNewRejectName = (newName) => async (dispatch, getState) => {
    try {
        dispatch({type: REJECTNAME_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/rejectname`, newName, config)
        dispatch({type: REJECTNAME_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: REJECTNAME_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateRejectName = (newName, id) => async (dispatch, getState) => {
    try {
        dispatch({type: REJECTNAME_UPDATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/rejectname/${id}`, newName, config)
        dispatch({type: REJECTNAME_UPDATE_SUCCESS})
    } catch (error) {
        dispatch({
            type: REJECTNAME_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const deleteRejectName = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: REJECTNAME_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/rejectname/${id}`, config)
        dispatch({type: REJECTNAME_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: REJECTNAME_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}
