import React, {useEffect, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { MdLocalPrintshop, MdMap } from 'react-icons/md'
import ReactToPrint from 'react-to-print'
import {
    Box,
    HStack,
    Spinner,
    Alert,
    AlertDescription,
    AlertTitle,
    AlertIcon,
    Spacer,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Tag,
    Center,
    Heading,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@chakra-ui/react'
import { getRateMy } from '../../actions/rateActions'
import { RATE_MY_RESET } from '../../constants/rateConstants'

const AgentRateScreen = () => {
    const dispatch = useDispatch()
    const componentRef = useRef()
    const nextRateRef = useRef()
    const [tab, setTab] = useState(1)
    const {loading: loadingRate, error: errorRate, success: successRate, rateDetail} = useSelector(state=>state.rate.My)

    useEffect(()=>{
        dispatch(getRateMy())
        return()=>{
            dispatch({type: RATE_MY_RESET})
        }
    },[dispatch])
    const pageStyle = `
        @page {
            size: A4 !important;
            margin: 2mm 2mm;
        }
        html, body {
            font-size: 16px;
            font-weight: 400;
        }
        `;

    const kgName = (kg) => {
        const first = kg.slice(0,1)
        const second = kg.slice(1,4)
        const kilo = (Number(second)/10)               
        return <Text ps='3'>{first + ' ' + kilo}</Text>     
    }
    return (
        <Box>
            <Center><Heading my='3'>My Rate</Heading></Center>
            <HStack my='3'>    
                <Box>
                    {loadingRate && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                </Box>
                <Spacer />
                <Link to='/countryandzone'><Button size='sm' colorScheme='orange' leftIcon={<MdMap />}>Countries</Button></Link>
                <Box>   
                    {tab === 1 && <ReactToPrint
                        trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                        content={() => componentRef.current}
                        pageStyle={()=>pageStyle}
                    />}
                    {tab === 2 && <ReactToPrint
                        trigger={() => <Button size='sm' w='100px' colorScheme='blue' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                        content={() => nextRateRef.current}
                        pageStyle={()=>pageStyle}
                    />}
                </Box>

            </HStack>

            <Box my='2'>
                {errorRate && 
                <Alert status='error' my='2' variant='left-accent'>
                <AlertIcon />
                <AlertTitle mr={2}>Error getting rate</AlertTitle>
                <AlertDescription>{errorRate}</AlertDescription>
                </Alert>
                }
            </Box>

            <Tabs variant='enclosed'>
                <TabList>
                    <Tab onClick={()=>setTab(1)}>Current Rate</Tab>
                    <Tab onClick={()=>setTab(2)}>Next Rate</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Box ref={componentRef}>
                            <Box>
                                <HStack>
                                    <Tag colorScheme='green'>d - စာရွက်စာတမ်း</Tag>
                                    <Tag colorScheme='red'>p - ကုန်ပစ္စည်း</Tag>
                                    <Tag colorScheme='purple'>o - ကီလို၃၀ကျော် (per kg)</Tag>
                                </HStack>
                            </Box>
                            {successRate &&
                            <Box fontSize={'13px'}>
                                <Table size='xs' my='4'>
                                    <Thead>
                                        <Tr>
                                            <Th><Box w={'50px'} ps={2}>KG</Box></Th>
                                            {rateDetail.zNumbers.map((zn,i)=>(
                                                <Th key={i}>
                                                    <Box w={'50px'}>
                                                        {'Z'+zn}
                                                    </Box>
                                                </Th>
                                            ))}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {rateDetail && rateDetail.ratelist.map((r, i)=>(
                                            <Tr key={i} py='1' bg={i%2 === 0 ? 'yellow.100' : 'white'}>
                                                <Td>
                                                    <Box w={'50px'}>
                                                        {kgName(r.kg)}
                                                    </Box>
                                                </Td>
                                                {
                                                    Object.keys(r.rate).map((key, index) => (
                                                        <Td key={key} >
                                                            <Box w={'50px'}>{r.rate[key]}</Box>
                                                        </Td>
                                                    ))
                                                }
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <Box h='50px' w='100%'></Box>
                            </Box>
                            }
                        </Box>
                    </TabPanel>

                    <TabPanel>
                        <Box ref={nextRateRef}>
                            <Box>
                                <HStack>
                                    <Tag colorScheme='green'>d - စာရွက်စာတမ်း</Tag>
                                    <Tag colorScheme='red'>p - ကုန်ပစ္စည်း</Tag>
                                    <Tag colorScheme='purple'>o - ကီလို၃၀ကျော် (per kg)</Tag>
                                </HStack>
                            </Box>
                            {successRate && rateDetail.nextRateList &&
                            <Box fontSize={'13px'}>
                                <Table size='xs' my='4'>
                                    <Thead>
                                        <Tr>
                                            <Th><Box w={'50px'} ps={2}>KG</Box></Th>
                                            {rateDetail.zNumbers.map((zn,i)=>(
                                                <Th key={i}>
                                                    <Box w={'50px'}>
                                                        {'Z'+zn}
                                                    </Box>
                                                </Th>
                                            ))}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {rateDetail && rateDetail.nextRateList.map((r, i)=>(
                                            <Tr key={i} py='1' bg={i%2 === 0 ? 'blue.100' : 'white'}>
                                                <Td>
                                                    <Box w={'50px'}>
                                                        {kgName(r.kg)}
                                                    </Box>
                                                </Td>
                                                {
                                                    Object.keys(r.rate).map((key, index) => (
                                                        <Td key={key} >
                                                            <Box w={'50px'}>{r.rate[key]}</Box>
                                                        </Td>
                                                    ))
                                                }
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                                <Box h='50px' w='100%'></Box>
                            </Box>
                            }
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            



        </Box>
    )
}

export default AgentRateScreen