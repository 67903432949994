export const REMOTEAREA_LIST_REQUEST = 'REMOTEAREA_LIST_REQUEST'
export const REMOTEAREA_LIST_SUCCESS = 'REMOTEAREA_LIST_SUCCESS'
export const REMOTEAREA_LIST_FAIL = 'REMOTEAREA_LIST_FAIL'
export const REMOTEAREA_LIST_RESET = 'REMOTEAREA_LIST_RESET'

export const REMOTEAREA_CREATE_REQUEST = 'REMOTEAREA_CREATE_REQUEST'
export const REMOTEAREA_CREATE_SUCCESS = 'REMOTEAREA_CREATE_SUCCESS'
export const REMOTEAREA_CREATE_FAIL = 'REMOTEAREA_CREATE_FAIL'
export const REMOTEAREA_CREATE_RESET = 'REMOTEAREA_CREATE_RESET'

export const REMOTEAREA_UPDATE_REQUEST = 'REMOTEAREA_UPDATE_REQUEST'
export const REMOTEAREA_UPDATE_SUCCESS = 'REMOTEAREA_UPDATE_SUCCESS'
export const REMOTEAREA_UPDATE_FAIL = 'REMOTEAREA_UPDATE_FAIL'
export const REMOTEAREA_UPDATE_RESET = 'REMOTEAREA_UPDATE_RESET'

export const REMOTEAREA_DELETE_REQUEST = 'REMOTEAREA_DELETE_REQUEST'
export const REMOTEAREA_DELETE_SUCCESS = 'REMOTEAREA_DELETE_SUCCESS'
export const REMOTEAREA_DELETE_FAIL = 'REMOTEAREA_DELETE_FAIL'
export const REMOTEAREA_DELETE_RESET = 'REMOTEAREA_DELETE_RESET'