import React, {useEffect, useState, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdLocalPrintshop } from 'react-icons/md'
import ReactToPrint from 'react-to-print'
import {
    Box,
    Stack,
    Input,
    Select,
    HStack,
    Spinner,
    Alert,
    AlertDescription,
    AlertTitle,
    AlertIcon,
    Spacer,
    Button,
    Divider,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
    Tag,
} from '@chakra-ui/react'
import { listAgent } from '../actions/userActions'
import { getRateByAgent } from '../actions/rateActions'
import { RATE_BYAGENT_RESET } from '../constants/rateConstants'
import { USER_AGENTLIST_RESET } from '../constants/userConstants'

const PrintRateScreen = () => {
    const dispatch = useDispatch()
    const componentRef = useRef()
    const [agentId, setAgentId] = useState('')
    const [agentSearchKeyword, setAgentSearchKeyword] = useState('')

    const {loading, error, agents} = useSelector(state=>state.user.AgentList)
    const {loading: loadingRate, error: errorRate, rateDetail} = useSelector(state=>state.rate.ByAgent)

    useEffect(()=>{
        return()=>{
            dispatch({type: RATE_BYAGENT_RESET})
            dispatch({type: USER_AGENTLIST_RESET})
        }
    },[dispatch])
    const pageStyle = `
        @page {
            size: A4 !important;
            margin: 2mm 2mm;
        }
        html, body {
            font-size: 16px;
            font-weight: 400;
        }
        `;

    const kgName = (kg) => {
        const first = kg.slice(0,1)
        const second = kg.slice(1,4)
        const kilo = (Number(second)/10)               
        return <Text ps='3'>{first + ' ' + kilo}</Text>     
    }

    const handleSearchAgent = (e) => {
        if(e.key === 'Enter'){
            dispatch(listAgent(1, 20, agentSearchKeyword))
        }
        return
    }

    const handleSubmit = () => {
        if(agentId === ''){
            return
        }
        dispatch(getRateByAgent(agentId))
        // console.log(agentId)
    }
    return (
        <Box>
            <HStack my='3'>                
                
                <Stack>
                    <Box>Agent</Box>
                    <Input 
                        type='text'
                        size='sm'
                        w='300px'
                        placeholder='Search Agent'
                        value={agentSearchKeyword}
                        onChange={e=> setAgentSearchKeyword(e.target.value)}
                        onKeyPress={handleSearchAgent}
                    />
                    <Select w='300px' size='sm' placeholder='Agent List' onChange={e=>setAgentId(e.target.value)}>
                        {agents && agents.map((ag, idx)=>(
                            <option key={idx} value={ag._id}>{ag.name} - {ag.agentCode}</option>
                        ))}
                    </Select>
                </Stack>
                <Box pl='20px'>
                    <Button
                        size='sm'
                        w='100px'
                        fontSize={'2xl'}
                        h={'100px'}
                        colorScheme='green'
                        onClick={handleSubmit}
                    >Go</Button>
                </Box>
                <Spacer />
                <Box>
                    {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                    {loadingRate && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                </Box>
                <Spacer />
                <Box>   
                    <ReactToPrint
                        trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                        content={() => componentRef.current}
                        pageStyle={()=>pageStyle}
                    />
                </Box>

            </HStack>
            <Divider />

            <Box my='2'>
                {error && 
                <Alert status='error' my='2' variant='left-accent'>
                <AlertIcon />
                <AlertTitle mr={2}>Error getting agent</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
                </Alert>
                }
                {errorRate && 
                <Alert status='error' my='2' variant='left-accent'>
                <AlertIcon />
                <AlertTitle mr={2}>Error getting rate</AlertTitle>
                <AlertDescription>{errorRate}</AlertDescription>
                </Alert>
                }
            </Box>

            <Box ref={componentRef}>
                <Box>
                    <HStack>
                        <Tag colorScheme='green'>d - စာရွက်စာတမ်း</Tag>
                        <Tag colorScheme='red'>p - ကုန်ပစ္စည်း</Tag>
                        <Tag colorScheme='purple'>o - ကီလို၃၀ကျော် (per kg)</Tag>
                    </HStack>
                </Box>
                <Box>
                    <Table size='xs' variant='striped' colorScheme='' my='4'>
                        <Thead>
                            <Tr>
                                <Th>Kg</Th>
                                <Th>Zone1</Th>
                                <Th>Zone2</Th>
                                <Th>Zone3</Th>
                                <Th>Zone4</Th>
                                <Th>Zone5</Th>
                                <Th>Zone6</Th>
                                <Th>Zone7</Th>
                                <Th>Zone8</Th>
                                <Th>Zone9</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {rateDetail && rateDetail.map((r, i)=>(
                                <Tr key={i} py='1' bg={i%2 === 0 ? 'yellow.100' : 'white'}>
                                {/* <Tr key={i} py='1' d={i%2 === 0 && 'none'}> */}
                                    <Td>{kgName(r.kg)}</Td>
                                    <Td>{r.rate.zone1}</Td>
                                    <Td>{r.rate.zone2}</Td>
                                    <Td>{r.rate.zone3}</Td>
                                    <Td>{r.rate.zone4}</Td>
                                    <Td>{r.rate.zone5}</Td>
                                    <Td>{r.rate.zone6}</Td>
                                    <Td>{r.rate.zone7}</Td>
                                    <Td>{r.rate.zone8}</Td>
                                    <Td>{r.rate.zone9}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    <Box h='50px' w='100%'></Box>
                </Box>
            </Box>



        </Box>
    )
}

export default PrintRateScreen