import React, {useEffect,useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Center, Checkbox, Divider, Heading, HStack, Input, Spacer, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { getVplusShipments } from '../actions/shipmentActions'
import Error from '../components/Error'
import ReactToPrint from 'react-to-print'
import { MdLocalPrintshop } from 'react-icons/md'
import { Link } from 'react-router-dom'

const VPlusReportScreen = () => {
    const dispatch = useDispatch()
    const componentRef = useRef()
    const [ date, setDate ] = useState(new Date().toISOString().substring(0,10))
    const [ isScanned, setIsScanned ] = useState(true)
    const { loading, error, shipments } = useSelector(state=>state.shipment.Vplus)

    const pageStyle = `
        @page {
            size: A4 !important;
            margin: 2mm 2mm;
        }
        html, body {
            font-size: 14px;
            font-weight: 400;
        }
        `;

    useEffect(()=>{
        dispatch(getVplusShipments(date, isScanned))

    },[dispatch, date, isScanned])


    return (
        <Center mt='50px'>
            <Box w='900px'>
                <Center>{loading  && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Center>
                {error && <Error error={error} /> }
                <HStack my='4'>
                    <Box>
                        <Input 
                            type='date'
                            size='sm'
                            w='150px'
                            value={date}
                            onChange={e=>setDate(e.target.value)}
                        />
                    </Box>
                    <Spacer />
                    <Box>
                        <Checkbox
                            size='lg'
                            isChecked={isScanned}
                            onChange={()=>setIsScanned(!isScanned)}
                        >
                            Show Scanned
                        </Checkbox>
                    </Box>
                    <Spacer />
                    <Box>   
                        <ReactToPrint
                            trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                            content={() => componentRef.current}
                            pageStyle={()=>pageStyle}
                        />
                    </Box>
                </HStack>
                <Divider />
                <Box ref={componentRef}>
                    <Center mt='30px'>
                        <Heading>VPlus Summary</Heading>                        
                    </Center>
                    <Box mb='3' fontWeight={'700'} textDecor='underline'>{new Date(date).toDateString()}</Box>

                    <Table size='sm' variant='striped' colorScheme='black'>
                        <Thead>
                            <Tr>
                                <Th>No</Th>
                                <Th>SM PRE</Th>
                                <Th>AWB</Th>
                                <Th>RECEIVER</Th>
                                <Th>WEIGHT</Th>
                                <Th>SALE</Th>
                                <Th>AGENT</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {shipments && shipments.map((s,i)=>(
                                <Tr key={i}>
                                    <Td>{i+1}</Td>
                                    <Td><Link to={`/preshipment/${s.preId}`}>{s.preCode}</Link></Td>
                                    <Td><Link to={`/shipment/${s._id}`}>{s.awb}</Link></Td>
                                    <Td>{s.customerDetails.receiverDetails.contactInformation.fullName}</Td>
                                    <Td>{s.totalWeight} kg</Td>
                                    <Td>{s.sale.name}</Td>
                                    <Td>{s.agent.name}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>

                </Box>
                

            </Box>
        </Center>
    )
}

export default VPlusReportScreen