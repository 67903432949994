import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Center,
    Input,
    Table,
    Tr,
    Th,
    Td,
    Tbody,
    Thead,
    Stack,
    Spinner,
    Grid,
    GridItem,
    HStack,
    Spacer,
} from '@chakra-ui/react'
import { scannedShipment, getSannedShipment, shipScanList } from '../../actions/domestic_actions/domesticShipmentActions'
import Error from '../../components/Error'

const DomesticScanningScreen = () => {
    const dispatch = useDispatch()
    const [awbpreid, setAwbpreid] = useState('')
    const [date, setDate] = useState('')

    const {loading, error, success} = useSelector(state=>state.domestic.Scanned)
    const {loading: loadingList, error: errorList, shipments} = useSelector(state=>state.domestic.ScannedList)
    const {loading: loadingShipScanList, error: errorShipScanList, shipments: shipScanDetail} = useSelector(state=>state.domestic.ShipAndScan)

    useEffect(()=>{
        if(success){
            setAwbpreid('')
        }
        dispatch(getSannedShipment(date))
        dispatch(shipScanList())
    },[success, dispatch, date])

    const handleSubmit = (e) => {
        if(e.key === 'Enter'){
            // console.log(awbpreid)
            dispatch(scannedShipment(awbpreid))
        }
        return
    }
    return (
        <Box my='4'>
            <Center>{(loading || loadingList || loadingShipScanList) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Center>
            <Box my='2'>
                
                {error && <Error error={error} /> }
                {errorList && <Error error={errorList} />}
                {errorShipScanList && <Error error={errorShipScanList} />}
            </Box>
            <Grid templateColumns='repeat(5, 1fr)' gap={4}>
                <GridItem colSpan={2}>
                    <Box>
                        <Box p='3' bg='red.100' border={'1px'} borderRadius='20'>
                            <Stack>
                                <Box><Center fontSize={'70px'}>Total: {shipments && shipments.length}</Center></Box>
                                <Box border='1px' borderRadius='20'>
                                    <Center>
                                        <Input 
                                            type={'text'}
                                            autoFocus
                                            fontSize={'50px'}                                     
                                            w='100%'
                                            h='100px'
                                            bg='white'
                                            boxShadow={'dark-lg'}
                                            borderRadius='20'
                                            value={awbpreid}
                                            onChange={(e)=>setAwbpreid(e.target.value)}
                                            onKeyPress={handleSubmit}
                                        />
                                    </Center>
                                </Box>
                            </Stack>
                        </Box>
                        <Box my='3' fontSize={'2xl'} className='quadrat' >မ scan ရသေးသော shipment များ</Box>
                        
                        <Table size='sm' variant='striped' colorScheme='blue' w='100%'>
                        <Thead>
                            <Tr>
                                <Th>No</Th>
                                <Th>TRACKING</Th>
                                <Th>PRE CODE</Th>
                                <Th>DATE</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {shipScanDetail && shipScanDetail.map((s,i)=>(
                                <Tr key={i}>
                                    <Td>{i+1}</Td>
                                    <Td>{s.awb}</Td>
                                    <Td>{s.preCode}</Td>
                                    <Td>{new Date(s.createdAt).toLocaleDateString()}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    </Box>
                </GridItem>
                <GridItem colSpan={3}>
                <Box px='4'>
                    <Box my='3'>
                        <HStack>
                            <Input 
                                type='date'
                                size='sm'
                                w='230px'
                                value={date}
                                onChange={e=>setDate(e.target.value)}
                            />
                            <Spacer />
                            <Box fontSize={'xl'} fontWeight={'600'}>Total Weight - {shipments && (shipments.reduce((acc, s) => acc + s.totalWeight, 0)).toFixed()} kg</Box>
                        </HStack>
                    </Box>
                    <Table size='sm' variant='striped' colorScheme='gray' w='100%'>
                        <Thead>
                            <Tr>
                                <Th>No</Th>
                                <Th>TRACKING</Th>
                                <Th>PRE CODE</Th>
                                <Th>Time</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {shipments && shipments.map((s,i)=>(
                                <Tr key={i}>
                                    <Td>{i+1}</Td>
                                    <Td>{s.awb}</Td>
                                    <Td>{s.preCode}</Td>
                                    <Td>{new Date(s.scannedDate).toLocaleTimeString('en', { hour12: true, timeZone: 'UTC' })}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                </GridItem>
            </Grid>
        </Box>
    )
}

export default DomesticScanningScreen