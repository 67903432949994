import axios from 'axios'
import {
    SALE_DASHBOARD_REQUEST,
    SALE_DASHBOARD_SUCCESS,
    SALE_DASHBOARD_FAIL,
    AGENT_DASHBOARD_REQUEST,
    AGENT_DASHBOARD_SUCCESS,
    AGENT_DASHBOARD_FAIL,
    ADMIN_DASHBOARD_REQUEST,
    ADMIN_DASHBOARD_SUCCESS,
    ADMIN_DASHBOARD_FAIL,
    LIST_DASHBOARD_REQUEST,
    LIST_DASHBOARD_SUCCESS,
    LIST_DASHBOARD_FAIL,
    DELETE_DASHBOARD_REQUEST,
    DELETE_DASHBOARD_SUCCESS,
    DELETE_DASHBOARD_FAIL,
    SALE_SHIPPING_CHART_REQUEST,
    SALE_SHIPPING_CHART_SUCCESS,
    SALE_SHIPPING_CHART_FAIL,
} from '../constants/dashboardConstants'

export const getAdminDashboard = (month, runJob) => async (dispatch, getState) => {
    try {
        dispatch({type: ADMIN_DASHBOARD_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/dashboard/admin?month=${month}&run=${runJob}`, config)
        dispatch({type: ADMIN_DASHBOARD_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ADMIN_DASHBOARD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getListDashboard = () => async (dispatch, getState) => {
    try {
        dispatch({type: LIST_DASHBOARD_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/dashboard/list`, config)
        dispatch({type: LIST_DASHBOARD_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: LIST_DASHBOARD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deleteDashboardData = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: DELETE_DASHBOARD_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.delete(`/api/dashboard/${id}`, config)
        dispatch({type: DELETE_DASHBOARD_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DELETE_DASHBOARD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getSaleDashboard = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: SALE_DASHBOARD_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/dashboard/sale/${id}`, config)
        dispatch({type: SALE_DASHBOARD_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SALE_DASHBOARD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getAgentDashboard = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: AGENT_DASHBOARD_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/dashboard/agent/${id}`, config)
        dispatch({type: AGENT_DASHBOARD_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: AGENT_DASHBOARD_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getShippigChart = (saleId) => async (dispatch, getState) => {
    try {
        dispatch({type: SALE_SHIPPING_CHART_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/dashboard/shippingchart?saleId=${saleId}`, config)
        dispatch({type: SALE_SHIPPING_CHART_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SALE_SHIPPING_CHART_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

