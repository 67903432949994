const dhlAccountList = [
    {
        account: '566059294',
        company: 'Siam Max Logistics Co.Ltd',
        shortName: 'Siam Max',
        address: '566 Moo.1 Thasailuat',
        city: 'Maesot',
        province: 'Tak',
        country: 'Thailand',
        postalcode: '63110',
        phone: '0899617262'

    },
    {
        account: '560357806',
        company: 'Southeast Express Trading Co.Ltd',
        shortName: 'Southeast Express',
        address: '522/3 Intarakeeree Rd',
        city: 'Maesot',
        province: 'Tak',
        country: 'Thailand',
        postalcode: '63110',
        phone: '0862094944'

    },
    {
        account: '956693152',
        company: 'Hamaya Japan',
        shortName: 'Hamaya Japan',
        address: '522/3 Intarakeeree Rd',
        city: 'Maesot',
        province: 'Tak',
        country: 'Thailand',
        postalcode: '63110',
        phone: '0862094944'

    },
    {
        account: '963170655',
        company: 'Mr Van Thawng',
        shortName: 'Mr Van Thawng',
        address: '522/3 Intarakeeree Rd',
        city: 'Maesot',
        province: 'Tak',
        country: 'Thailand',
        postalcode: '63110',
        phone: '0862094944'

    },
]

const incotermList = [
    {code: 'EXW', description: 'ExWorks'},
    {code: 'FCA', description: 'Free Carrier'},
    {code: 'CPT', description: 'Carriage Paid To'},
    {code: 'CIP', description: 'Carriage and Insurance Paid To'},
    {code: 'DPU', description: 'Delivered at Place Unloaded'},
    {code: 'DAP', description: 'Delivered at Place'},
    {code: 'DDP', description: 'Delivered Duty Paid'},
    {code: 'FAS', description: 'Free Alongside Ship'},
    {code: 'FOB', description: 'Free on Board'},
    {code: 'CFR', description: 'Cost and Freight'},
    {code: 'CIF', description: 'Cost, Insurance and Freight'}
]

export {dhlAccountList, incotermList}