export const ZONE_LIST_REQUEST = 'ZONE_LIST_REQUEST'
export const ZONE_LIST_SUCCESS = 'ZONE_LIST_SUCCESS'
export const ZONE_LIST_FAIL = 'ZONE_LIST_FAIL'
export const ZONE_LIST_RESET = 'ZONE_LIST_RESET'

export const ZONE_RATE_REQUEST = 'ZONE_RATE_REQUEST'
export const ZONE_RATE_SUCCESS = 'ZONE_RATE_SUCCESS'
export const ZONE_RATE_FAIL = 'ZONE_RATE_FAIL'
export const ZONE_RATE_RESET = 'ZONE_RATE_RESET'

export const ZONE_CREATE_REQUEST = 'ZONE_CREATE_REQUEST'
export const ZONE_CREATE_SUCCESS = 'ZONE_CREATE_SUCCESS'
export const ZONE_CREATE_FAIL = 'ZONE_CREATE_FAIL'
export const ZONE_CREATE_RESET = 'ZONE_CREATE_RESET'

export const ZONE_UPDATE_REQUEST = 'ZONE_UPDATE_REQUEST'
export const ZONE_UPDATE_SUCCESS = 'ZONE_UPDATE_SUCCESS'
export const ZONE_UPDATE_FAIL = 'ZONE_UPDATE_FAIL'
export const ZONE_UPDATE_RESET = 'ZONE_UPDATE_RESET'

export const ZONE_DELETE_REQUEST = 'ZONE_DELETE_REQUEST'
export const ZONE_DELETE_SUCCESS = 'ZONE_DELETE_SUCCESS'
export const ZONE_DELETE_FAIL = 'ZONE_DELETE_FAIL'
export const ZONE_DELETE_RESET = 'ZONE_DELETE_RESET'