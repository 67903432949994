import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import {
    Box,
    Heading,
    HStack,
    Stack,
    FormControl,
    FormLabel,
    Input,
    Button,
    Spacer,
    Spinner,
    Divider,
    useToast,
    Select,
    Grid,
    UnorderedList,
    ListItem,
    Checkbox,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
} from '@chakra-ui/react'
import Error from '../../components/Error'
import FromAndToDetail from './FromAndToDetail'
import { getPreshipment } from '../../actions/shipmentActions'
import { getCostDomesticShipment, createDomesticShipment } from '../../actions/domestic_actions/domesticShipmentActions'
import { DOMESTICSHIPMENT_GETCOST_RESET } from '../../constants/domestic_constants/domesticShipmentConstants'
import { getChargesList } from '../../actions/chargesActions'
import { carrierList } from '../../data/carrierList'
import { localAddresses } from '../../data/localAddresses'

const DomesticShipmentCreateScreen = () => {
    const toast = useToast()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const preId = location.search ? location.search.split('=')[1] : ''

    const [carrier, setCarrier] = useState('')
    const [cost, setCost] = useState(0)
    const [takeItemWeight, setTakeItemWeight] = useState(false)
    const [ignoreWeight, setIgnoreWeight] = useState(false)
    const [charges, setCharges] = useState([])
    const [newChargeName, setNewChargeName] = useState('')
    const [newChargeAmount, setNewChargeAmount] = useState('')
    const [cusPaidAmount, setCusPaidAmount] = useState(0)
    const [note, setNote] = useState('')
    const [itemList, setItemList] = useState([])
    const [boxList, setBoxList] = useState([])
    const {loading: loadingPreShipment, error: errorPreShipment, preshipment} = useSelector(state=>state.preshipment.ById)
    const {loading: loadingGetCost, error: errorGetCost, cost: shipmentCost} = useSelector(state=>state.domestic.ShipmentGetCost)
    const {loading, error, shipment} = useSelector(state=>state.domestic.ShipmentCreate)

    const {loading: loadingCharges, charges: chargesList, error: errorCharges } = useSelector(state=>state.charges.List)
    useEffect(()=>{
        if(preId !== ''){
            if(!preshipment || preshipment._id !== preId){
                dispatch(getPreshipment(preId))
            } else {             
                setItemList([...preshipment.items])
                setBoxList([...preshipment.boxes]) 
                setCusPaidAmount(preshipment.paidAmount) 
                setNote(preshipment.note || '')             
            }            
        }
        if(shipment){
            navigate(`/domesticshipment/label`)
        }
        if(shipmentCost){
            setCost(shipmentCost)
        }
        
        return()=>{
            dispatch({type: DOMESTICSHIPMENT_GETCOST_RESET})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch, preshipment, preId, shipmentCost, shipment, toast, navigate])

    useEffect(()=>{
        dispatch(getChargesList())
    },[dispatch])
 
    const handleAddCharges = () => {
        if(newChargeName === '' || newChargeName === 'Select'){return}
        if(newChargeAmount === '' || newChargeAmount === 0){return}
        const existIndex = charges.map(c => c.name).indexOf(newChargeName)
        if(existIndex !== -1){return}
        const newChargeToPush = {
            name: newChargeName,
            amount: Number(newChargeAmount)
        }
        setNewChargeName('')
        setNewChargeAmount('')
        setCharges([...charges, newChargeToPush])
    }
    const handleRemoveCharges = (idx) => {
        const newCharges = charges
        //const existIndex = newCharges.map(c => c.name).indexOf(chargeName)
        newCharges.splice(idx, 1)
        setCharges([...newCharges])
    }
    const handleGetCost = () => {
        if(carrier === ''){
            toast({
                status: 'error',
                position: 'top',
                title: 'Select carrier',
                duration: 4000,
                isClosable: true,
            }) 
            return
        }
        if(preshipment === undefined){
            return
        }
        const itemsTotalWeight = itemList.reduce((acc, item) => acc + item.weight, 0)
        const boxesTotalWeight = boxList.reduce((acc, b)=> acc + b.weight, 0)
        let totalWeight
        if(takeItemWeight){
            totalWeight = Math.floor(itemsTotalWeight)
        } else {
            totalWeight = Math.floor(boxesTotalWeight)
        }
        const data = {
            totalWeight,
            carrier
        }
        if(data.totalWeight === 0){
            toast({
                status: 'error',
                position: 'top',
                title: 'Total weight can not be 0',
                duration: 4000,
                isClosable: true,
            }) 
            return
        }
        dispatch(getCostDomesticShipment(data))       
        //console.log(data)
    }

    const handleShip = (e) => {        
        e.preventDefault()
        
        const customerDetails = {
            sender: {
                address: {
                    postalCode: localAddresses[1].postalCode,
                    city: localAddresses[1].city,
                    country: localAddresses[1].country,
                    province: localAddresses[1].province,
                    address: localAddresses[1].address,
                },           
                email: preshipment.contact.split(';')[2],
                tel: preshipment.contact.split(';')[1],
                name: preshipment.contact.split(';')[0],
                
            },
            receiver: {
                address: {
                  postalCode: preshipment.addressId.postalcode,
                  city: preshipment.addressId.city,
                  province: preshipment.addressId.stateProvince || null,
                  address: preshipment.addressId.address,
                  address2: preshipment.addressId.address2 || null,
                  address3: preshipment.addressId.address3 || null,
                  country: preshipment.addressId.country,
                },
                  email: preshipment.addressId.email || null,
                  tel: '+' + preshipment.addressId.phonecode + preshipment.addressId.phone,
                  name: preshipment.addressId.name,                
            }
        }

        var smData = {          
            preCode: preshipment.code,
            preId: preshipment._id,
            agent: preshipment.agentId._id,            
            productCode: preshipment.productCode,
            shipType: preshipment.shipType,
            totalWeight: Math.round((boxList.reduce((acc, box) => acc + box.weight, 0))*100)/100,
            totalAmount: Math.round((charges.reduce((acc, ch) => acc + ch.amount, 0) + Number(cost))*100)/100,
            paidAmount: cusPaidAmount,
            dhlInvoiceNo: preshipment.dhlInvoiceNo,
            charges,          
            note,
            carrier,
            sender: customerDetails.sender,
            receiver: customerDetails.receiver,
            items: preshipment.items,
            boxes: preshipment.boxes
        }  

        //console.log(smData)
        dispatch(createDomesticShipment(smData))
    }





    return (
        <Box>  
            
            <Box mx='5%'>
                {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}  
                {loadingCharges && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}  
                {loadingPreShipment && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}  
                {error && <Error error={error} />}  
                {errorPreShipment && <Error error={errorPreShipment} />}  
                {errorCharges && <Error error={errorCharges} />}  
            </Box>  
            <Box  mx='5%' opacity={loading && '0.5'}> 
            <Heading fontSize={'2xl'} my='5'>Addresses</Heading>
            <FromAndToDetail preshipment={preshipment} />
            <Box h='20px'></Box>
            
            
            <Box>
            <Box my='30px' borderBottom={'1px'}></Box>
                <Heading fontSize={'2xl'} mb='4'>Shipment Detail</Heading>
                <Grid
                    templateRows='repeat(1, 1fr)'
                    templateColumns='repeat(4, 1fr)'
                    gap={4}
                    >


                    <FormControl colSpan={2}>
                        <Stack spacing={'0'}>
                            <FormLabel>Carrier</FormLabel>
                            <Select placeholder='Select' size='sm' onChange={(e)=>setCarrier(e.target.value)} >
                                <option value=''>Select</option>
                                {carrierList.map((cl, idx)=>(
                                    <option key={idx} value={cl.value}>{cl.name}</option>
                                ))}
                            </Select>                            
                        </Stack>
                    </FormControl>
                    <Spacer />
                    <Box>
                        {carrier !== '' && <img style={{height: '70px', objectFit: 'contain'}} src={`/${carrier}.png`} alt='carrier-logo' />}
                    </Box>

                </Grid>
            </Box>

            { preshipment && preshipment.productCode === 'P' && 
                <Box>
                  <Box borderBottom={'1px'} my='30px'></Box>                    
                  <Heading fontSize={'2xl'} >Items</Heading>
                    <Stack>
                      <Table variant='simple' size='sm'>
                          <Thead>
                          <Tr>
                              <Th>No</Th>
                              <Th>Item Name</Th>
                              <Th isNumeric>Weight</Th>
                              <Th isNumeric>Price</Th>
                              <Th isNumeric>Qty</Th>
                              <Th isNumeric>Amount</Th>
                          </Tr>
                          </Thead>

                          <Tbody>
                          {preshipment && itemList.map((item,idx)=>(
                              <Tr key={idx}>
                                  <Td>{idx+1}</Td>
                                  <Td>{item.item.description}</Td>
                                  <Td isNumeric>{item.weight}</Td>
                                  <Td isNumeric>{item.price}</Td>
                                  <Td isNumeric>{item.qty}</Td>
                                  <Td isNumeric>{(item.price*item.qty).toFixed(2)}</Td>
                              </Tr>
                          ))}
                          
                          </Tbody>
                      </Table>
                    
                      <HStack pe='30px'>
                          <Box w='200px'></Box>
                          <Spacer />
                          <FormLabel fontSize={'lg'}>Total Weight - 
                              <span style={{'backgroundColor': 'yellow', 'padding': '0 5px', 'borderRadius': '10px'}}> 
                                  {(itemList.reduce((acc, item) => acc + item.weight, 0)).toFixed(2)} 
                              </span>kg
                          </FormLabel> 
                          <Spacer />
                          <FormLabel fontSize={'lg'}>Total Amount - 
                              {(itemList.reduce((acc, item) => acc + item.price * item.qty, 0)).toFixed(2)} 
                              ({preshipment.currency})
                          </FormLabel>
                      </HStack>
                      </Stack>
                </Box>
            }

            

            <Box>
                <Box borderBottom={'1px'} my='30px' ></Box>
                <Heading fontSize={'2xl'} mb='5'>Boxes</Heading>
                <Stack>
                    {preshipment && boxList.map((box,idx)=>(
                        <Box key={idx} pe='30px'>
                            <HStack mb='2'>
                                <Box mr='30px'>Box {idx + 1} -</Box>
                                <Box>{box.dimensions.length} x {box.dimensions.width} x {box.dimensions.height} cm</Box>
                                <Spacer />
                                <Box>{box.weight} kg</Box>
                            </HStack>
                            
                            <Divider />                           
                        </Box>
                    ))}
                    <HStack pe='30px'>
                        <Box></Box>
                        <Spacer />
                        <FormLabel fontSize={'lg'}>Total - {(boxList.reduce((acc, box) => acc + box.weight, 0)).toFixed(2)} kg</FormLabel>
                    </HStack>
                </Stack>
            </Box>
            

            <Box>
                <Box borderBottom={'1px'} my='30px' ></Box>
                <Heading fontSize={'2xl'} mb='2'>SM Detail</Heading>
                {preshipment && <Box className='quadrat' mb='3' fontSize='lg' fontWeight={'600'}>{preshipment.note}</Box>}
                {errorGetCost && <Error error={errorGetCost} />}  
                <Stack>

                <Grid
                    templateRows='repeat(1, 1fr)'
                    templateColumns='repeat(4, 1fr)'
                    gap={4}
                    >
                    <FormControl colSpan={2}>
                        <Stack>
                            <Box>
                                <Box>Charges</Box>
                                <HStack>
                                    <Select placeholder='Select' size='sm' onChange={(e)=>setNewChargeName(e.target.value)} >
                                        {chargesList && chargesList.map((c, idx)=>(
                                            <option key={idx} value={c.name}>{c.name}</option>
                                        ))}
                                    </Select>
                                    <Input 
                                        type='number'
                                        size='sm'
                                        w='100px'
                                        value={newChargeAmount}
                                        onChange={(e)=>setNewChargeAmount(e.target.value)}
                                    />
                                    <Button
                                        size='sm'
                                        colorScheme={'green'}
                                        onClick={handleAddCharges}
                                    >Add</Button>
                                </HStack>
                            </Box>
                            <Box>
                                <UnorderedList spacing={3}>
                                    {charges.map((ch, idx)=>(
                                        <ListItem key={idx}>
                                            <HStack>
                                                <Box w='200px'>{ch.name}</Box>
                                                <Box>{ch.amount}</Box>
                                                <Spacer />
                                                <Button
                                                    size='xs'
                                                    colorScheme={'red'}
                                                    onClick={()=>handleRemoveCharges(idx)}
                                                >
                                                    x
                                                </Button>
                                            </HStack>
                                        </ListItem>
                                    ))}
                                </UnorderedList>
                            </Box>
                        </Stack>
                    </FormControl>

                    <Stack spacing={'0'} >
                        <Box>Cost <span style={{'color': 'red', 'fontSize': '12px'}}> default: box weight</span></Box>                        
                        <HStack mb='3'>
                        <Input 
                            type={'text'}
                            size='sm'
                            readOnly
                            value={cost}
                            onChange={(e)=>setCost(e.target.value)}
                        />
                        <Button
                            size='sm'
                            isLoading={loadingGetCost}
                            disabled={preshipment === undefined}
                            colorScheme={'green'}
                            onClick={handleGetCost}
                        >
                            Compute
                        </Button>
                        </HStack>
                        <Stack>
                            <Checkbox onChange={()=>setIgnoreWeight(!ignoreWeight)}>Ignore Weight</Checkbox>
                            <Checkbox onChange={()=>setTakeItemWeight(!takeItemWeight)}>Item Weight</Checkbox>
                        </Stack>
                        
                        
                    </Stack>


                    <Stack spacing={'0'} >
                        <Box>Customer Paid</Box>                        
                        <HStack>
                            <Input 
                                type={'text'}
                                size='sm'
                                value={cusPaidAmount}
                                onChange={(e)=>setCusPaidAmount(e.target.value)}
                            />
                        </HStack>
                    </Stack>
                    <Stack spacing={'0'} >
                        <Box>Note</Box>                        
                        <HStack>
                            <Input 
                                type={'text'}
                                size='sm'
                                value={note}
                                onChange={(e)=>setNote(e.target.value)}
                            />
                        </HStack>
                    </Stack>
                </Grid>
                    
                    <Divider />
                    <HStack pe='30px'>
                        <Box></Box>
                        <Spacer />
                        <FormLabel fontSize={'2xl'}>
                        {charges.length !== 0 ? (charges.reduce((acc, ch) => acc + ch.amount, 0) + Number(cost)) : Number(cost)}
                        <span style={{'fontSize': '15px', 'color': 'red'}}> thb</span>
                        </FormLabel>
                    </HStack>
                </Stack>
            </Box>

            </Box> 
                <Box mx='5%' my='5'>
                {error && <Error error={error} />}  
                {preshipment && preshipment.items.length === 0 && <Error error="No items to ship" />}  
                {carrier === '' && <Error error="Select carrier" />}  

                { cost === 0 && <Error error={'Shipment cost can not be 0.'} />}
                
                    <Divider />
                    <HStack mt='3'>

                        <Link to='/shipment'>
                            <Button
                                size='sm'
                                isLoading={loading}
                                w='150px'
                                colorScheme={'blue'}
                            >Cancel</Button>
                        </Link>
                        <Spacer />
                        <Button                            
                            size='sm'
                            isLoading={loading}
                            w='150px'
                            colorScheme={'yellow'}
                            onClick={handleShip}
                            disabled={preshipment && preshipment.shipped}
                        >Ship</Button>
                    </HStack>
                </Box>
        </Box>
    )
}

export default DomesticShipmentCreateScreen