import React, {useEffect} from 'react'
import { Box, Center, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import Error from '../components/Error'
import {useDispatch, useSelector} from 'react-redux'
import { getRejectNameList, deleteRejectName } from '../actions/rejectNameActions'
import RejectNameCreateModal from '../Modals/RejectNameCreateModal'
import RejectNameEditModal from '../Modals/RejectNameEditModal'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'


const RejectNameListScreen = () => {
    const dispatch = useDispatch()

    const { loading, names, error } = useSelector(state=>state.rejectName.List)
    const {success: successAdd} = useSelector(state=>state.rejectName.Create)
    const {success: successUpdate, error: errorUpdate} = useSelector(state=>state.rejectName.Update)
    const {success: successDelete, error: errorDelete} = useSelector(state=>state.rejectName.Delete)

    useEffect(()=>{
        dispatch(getRejectNameList())
    },[dispatch, successAdd, successUpdate, successDelete])

    return (
        <Center my='4'>
            <Box boxShadow={'lg'} w='700px' p='3'>
                {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                {error && <Error error={error} /> }
                {errorUpdate && <Error error={errorUpdate} /> }
                {errorDelete && <Error error={errorDelete} /> }

                <RejectNameCreateModal />
                

                <Box>
                  <Table variant='striped' colorScheme='teal' my='4' size={'xs'}>
                      <Thead>
                          <Tr>
                          <Th>No</Th>
                          <Th>Name</Th>
                          <Th>Edit</Th>
                          <Th>Delete</Th>
                          </Tr>
                      </Thead>
                      <Tbody>
                          {names && names.map((ac, idx)=>(
                          <Tr key={idx}>
                              <Td>{idx+1}</Td>
                              <Td>{ac.name}</Td>
                              <Td><RejectNameEditModal acc={ac} /></Td>
                              <Td><ConfirmDeleteModal width={'60px'} lefticon={null} id={ac._id} deleteFunction={deleteRejectName} /></Td>
                          </Tr>
                          ))}
                      </Tbody>
                  </Table>
                </Box>              
                
            </Box>
        </Center>
    )
}

export default RejectNameListScreen