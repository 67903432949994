import { 
    CHARGES_CREATE_FAIL,
    CHARGES_CREATE_REQUEST,
    CHARGES_CREATE_RESET,
    CHARGES_CREATE_SUCCESS,
    CHARGES_DELETE_FAIL,
    CHARGES_DELETE_REQUEST,
    CHARGES_DELETE_RESET,
    CHARGES_DELETE_SUCCESS,
    CHARGES_LIST_FAIL,
    CHARGES_LIST_REQUEST, 
    CHARGES_LIST_RESET, 
    CHARGES_LIST_SUCCESS,
    CHARGES_UPDATE_FAIL,
    CHARGES_UPDATE_REQUEST,
    CHARGES_UPDATE_RESET,
    CHARGES_UPDATE_SUCCESS,

} from "../constants/chargesConstants"





export const chargesListReducer = (state={charges:[]}, action) => {
    switch(action.type){
        case CHARGES_LIST_REQUEST:
            return {loading: true}
        case CHARGES_LIST_SUCCESS:
            return {loading: false, charges: action.payload}
        case CHARGES_LIST_FAIL:
            return {loading: false, error: action.payload}
        case CHARGES_LIST_RESET:
            return {}
        default: return state
    }
}

export const chargesCreateReducer = (state={}, action) => {
    switch(action.type){
        case CHARGES_CREATE_REQUEST:
            return {loading: true}
        case CHARGES_CREATE_SUCCESS:
            return {loading: false, success: true}
        case CHARGES_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case CHARGES_CREATE_RESET:
            return {}
        default: return state
    }
}

export const chargesUpdateReducer = (state={}, action) => {
    switch(action.type){
        case CHARGES_UPDATE_REQUEST:
            return {loading: true}
        case CHARGES_UPDATE_SUCCESS:
            return {loading: false, success: true}
        case CHARGES_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case CHARGES_UPDATE_RESET:
            return {}
        default: return state
    }
}

export const chargesDeleteReducer = (state={}, action) => {
    switch(action.type){
        case CHARGES_DELETE_REQUEST:
            return {loading: true}
        case CHARGES_DELETE_SUCCESS:
            return {loading: false, success: true}
        case CHARGES_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case CHARGES_DELETE_RESET:
            return {}
        default: return state
    }
}