import React from 'react'
import { Link } from 'react-router-dom'
import { HStack, IconButton, Button } from '@chakra-ui/react'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdOutlineFirstPage,  MdOutlineLastPage } from 'react-icons/md'

const Pagination = ({keyword = '', pages, page, path}) => {
  return (
        <>
            {pages > 5 ? 
                <HStack spacing='5px' >
                    {page !== 1 && 
                        <>  
                        <Link to={keyword ? `/${path}/search/${keyword}/page/1` : `/${path}/page/1`}>
                            <IconButton aria-label='previous' size='sm' fontSize='25px' bg='gray.300' color='black' icon={<MdOutlineFirstPage />} />
                        </Link>
                        <Link to={keyword ? `/${path}/search/${keyword}/page/${page-1}` : `/${path}/page/${page-1}`}>
                            <IconButton aria-label='previous' size='sm' fontSize='25px' bg='gray.300' color='black' icon={<MdKeyboardArrowLeft />} />
                        </Link>
                        </>
                    }
                        <Button size='sm' bg='gray.300' color='black'>{page}</Button>
                    {page !== pages && 
                    <>
                        <Link to={keyword ? `/${path}/search/${keyword}/page/${page+1}` : `/${path}/page/${page+1}`}>
                            <IconButton aria-label='next' size='sm' fontSize='25px' bg='gray.300' color='black' icon={<MdKeyboardArrowRight />} />
                        </Link>
                        <Link to={keyword ? `/${path}/search/${keyword}/page/${pages}` : `/${path}/page/${pages}`}>
                            <IconButton aria-label='next' size='sm' fontSize='25px' bg='gray.300' color='black' icon={<MdOutlineLastPage />} />
                        </Link>  
                    </> 
                    }

                                         
                </HStack>
                :
                <HStack spacing='5px' >
                    {[...Array(pages).keys()].map(p=>(
                        <Link key={p+1} to={keyword ? `/${path}/search/${keyword}/page/${p+1}` : `/${path}/page/${p+1}`}>
                            <Button size='sm' bg='gray.300' color='black'>{p+1}</Button>
                        </Link>
                    ))}
                </HStack>
            }
        </>
  )
}

export default Pagination