import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
    Box,
    HStack,
    Spacer,
    Spinner,
    Divider,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Center,
    Heading,
} from '@chakra-ui/react'
import { listAgentRate } from '../actions/agentRateActions'
import Error from '../components/Error'
//import AgentRateAddModal from '../Modals/AgentRateAddModal'
import AgentRateAddModalNew from '../Modals/AgentRateAddModalNew'
import AgentRateViewModal from '../Modals/AgentRateViewModal'
import { getZoneRate } from '../actions/zoneActions'

const AgentRateListScreen = () => {

    const dispatch = useDispatch()
    const {loading, error, agentrates} = useSelector(state=> state.agentRate.List)
    const {success: createSuccess} = useSelector(state=>state.agentRate.Create)
    const {success: deleteSuccess} = useSelector(state=>state.agentRate.Delete)
    const {loading: zRateLoading, error: zRateError, success: zRateSuccess, zone} = useSelector(state=>state.zone.Rate)

    useEffect(()=>{
        dispatch(listAgentRate())
        dispatch(getZoneRate())
    },[dispatch, createSuccess, deleteSuccess])

    return (
        <Center>
        <Box w='700px'>
            
                <Center mb='3'><Heading>Agent Rates</Heading></Center>
                <Divider />
                <HStack spacing='4' my='4'>
                    <Box>
                        {(loading || zRateLoading)  && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                    </Box>
                    <Spacer />
                    <Box>
                        {/* <AgentRateAddModal /> */}
                        { zRateSuccess && Object.keys(zone).length !== 0 && <AgentRateAddModalNew zone={zone} />}
                    </Box>
                </HStack>
                <Divider />

                {error && <Error error={error} /> }
                {zRateError && <Error error={zRateError} />}

                <Box>
                    <Table size='sm' variant='striped' colorScheme='gray' my='4'>
                        <Thead>
                            <Tr>
                            <Th>No</Th>
                            <Th>Name</Th>
                            <Th>Zone Qty</Th>
                            <Th>View</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {agentrates && agentrates.map((agr,index)=>(
                            <Tr key={index}>
                                <Td>{index+1}</Td>
                                <Td>{agr.name}</Td>
                                <Td>{Object.keys(agr.p0).length}</Td>
                                {zRateSuccess && zone.length !== 0 && <Td><AgentRateViewModal agRateId={agr._id} zone={zone} /></Td>}
                                {/* <Td>
                                    <Link to={`/agentrate/${agr._id}`}>
                                        <Button size='sm' variant='link'>Edit</Button>
                                    </Link>
                                </Td> */}
                                {/* <Td><ConfirmDeleteModal 
                                    deleteFunction={deleteAgentRate} 
                                    id={agr._id} 
                                    variant='link' 
                                    width='60px' 
                                    lefticon={null} 
                                /></Td> */}
                            </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            
        </Box>
        </Center>
    )
}

export default AgentRateListScreen