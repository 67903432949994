export const PAYMENT_LIST_REQUEST = 'PAYMENT_LIST_REQUEST'
export const PAYMENT_LIST_SUCCESS = 'PAYMENT_LIST_SUCCESS'
export const PAYMENT_LIST_FAIL = 'PAYMENT_LIST_FAIL'
export const PAYMENT_LIST_RESET = 'PAYMENT_LIST_RESET'

export const PAYMENT_MY_REQUEST = 'PAYMENT_MY_REQUEST'
export const PAYMENT_MY_SUCCESS = 'PAYMENT_MY_SUCCESS'
export const PAYMENT_MY_FAIL = 'PAYMENT_MY_FAIL'
export const PAYMENT_MY_RESET = 'PAYMENT_MY_RESET'

export const PAYMENT_CREATE_REQUEST = 'PAYMENT_CREATE_REQUEST'
export const PAYMENT_CREATE_SUCCESS = 'PAYMENT_CREATE_SUCCESS'
export const PAYMENT_CREATE_FAIL = 'PAYMENT_CREATE_FAIL'
export const PAYMENT_CREATE_RESET = 'PAYMENT_CREATE_RESET'

export const PAYMENT_BYID_REQUEST = 'PAYMENT_BYID_REQUEST'
export const PAYMENT_BYID_SUCCESS = 'PAYMENT_BYID_SUCCESS'
export const PAYMENT_BYID_FAIL = 'PAYMENT_BYID_FAIL'
export const PAYMENT_BYID_RESET = 'PAYMENT_BYID_RESET'

export const PAYMENT_APPROVE_REQUEST = 'PAYMENT_APPROVE_REQUEST'
export const PAYMENT_APPROVE_SUCCESS = 'PAYMENT_APPROVE_SUCCESS'
export const PAYMENT_APPROVE_FAIL = 'PAYMENT_APPROVE_FAIL'
export const PAYMENT_APPROVE_RESET = 'PAYMENT_APPROVE_RESET'

export const PAYMENT_DELETE_REQUEST = 'PAYMENT_DELETE_REQUEST'
export const PAYMENT_DELETE_SUCCESS = 'PAYMENT_DELETE_SUCCESS'
export const PAYMENT_DELETE_FAIL = 'PAYMENT_DELETE_FAIL'
export const PAYMENT_DELETE_RESET = 'PAYMENT_DELETE_RESET'