import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Stack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Select,
    Input,
    Box,
    Spinner,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
} from '@chakra-ui/react'
import { createTHPPayment } from '../../actions/thailand_post_actions/thpPaymentActions'
import { THPPAYMENT_CREATE_RESET } from '../../constants/thailand_post_constants/thpPaymentConstants'

const AddTHPPayment = ({invoiceId, totalAmount, balance, isPaid}) => {
    const dispatch = useDispatch()
    const [payMethod, setPayMethod] = useState('')
    const [amount, setAmount] = useState(0)
    const [note, setNote] = useState('')
    const paymentMethods = [
        'Myanmar Bank',
        'Thai Bank',
        'Yangon Cash',
        'Hpa-an Cash',
        'Myawady Cash',
        'Maesot Cash'
        ]
    const [show, setShow] = useState(false)
    const {loading, error, success} = useSelector(state=>state.thailandPost.PaymentCreate)

    useEffect(()=>{
        if(success){
            setAmount(0)
            setNote('')
            setShow(false)
        }
        return()=>{
            dispatch({type: THPPAYMENT_CREATE_RESET})
        }
    },[success, dispatch])

    const handleClose = () => {
        setAmount(0)
        setNote('')
        setShow(false)
    }

    const handleSubmit = () => {
        const newPayment = {
            fromBalance: false,
            invoiceId,
            paidMethod: payMethod,
            amount,
            note,
        }
        dispatch(createTHPPayment(newPayment))
    }


    return (
        <>
            <Button size='sm' disabled={isPaid} w='150px' colorScheme={'blue'} onClick={()=>setShow(true)} >Make Payment</Button>

            <Modal isOpen={show} onClose={handleClose} size='3xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Box>
                        Invoice Total Amount - {totalAmount.toLocaleString()}
                    </Box>
                    <Box>
                        Balance - {balance.toLocaleString()}
                    </Box>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Box>
                        {error && 
                            <Alert status='error' variant='left-accent'>
                            <AlertIcon />
                            <AlertTitle mr={2}>Error creating Payment.</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        }
                    </Box>
                    <Stack spacing={'3'}>  
                        <Stack spacing={'0'}>
                            <Box>Methods</Box>
                            <Select size='sm' placeholder='Select' onChange={(e)=>setPayMethod(e.target.value)} >
                            {paymentMethods.map((p,i)=>(
                                <option key={i} value={p}>{p}</option>
                            ))}
                            </Select>
                        </Stack>
                        
                        
                        <Stack spacing={'0'}>
                            <Box>Amount</Box>
                            <Input 
                                type={'number'}
                                size='sm'
                                value={amount}
                                onChange={e=>setAmount(e.target.value)}
                            />
                        </Stack>

                        <Stack spacing={'0'}>
                            <Box>Note</Box>
                            <Input 
                                as={'textarea'}
                                h='50px'
                                size='sm'
                                value={note}
                                onChange={e=>setNote(e.target.value)}
                            />
                        </Stack>

                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button size='sm' w='150px' colorScheme={'green'} onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default AddTHPPayment