import React from 'react'
import { useDispatch } from 'react-redux'
import {
    Box,
    HStack,
    Heading,
    Spacer,
    Wrap,
    WrapItem,
    Stack,
    Button,
    Text,
    Center,
    Table,
    TableContainer,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Divider,
    Badge,
} from '@chakra-ui/react'
import PrintStickerModal from '../../Modals/PrintStickerModal'
import ShipmentPreAddBoxModal from '../../Modals/ShipmentPreAddBoxModal'
import ShipmentPreAddItemModal from '../../Modals/ShipmentPreAddItemModal'
import ShipmentPreItemEditModal from '../../Modals/ShipmentPreItemEditModal'
import ShipmentPreEditBoxModal from '../../Modals/ShipmentPreEditBoxModal'
import { boxPreshipment, addRemoveItemPreshipment } from '../../actions/shipmentActions'
import { MdClose } from "react-icons/md"
import ItemSortingModal from '../../Modals/ItemSortingModal'
//import { boxSizes } from '../../data/boxSizes'


const ItemListPost = ({preshipment, preId}) => {
    const dispatch = useDispatch()
    const handleRemoveBox = (e) => {
        const boxData = {
          preId,
          add: false,
          idx: e.target.value
        }
        dispatch(boxPreshipment(boxData))
    }

    const handleRemoveItem = (itemId) => {
        const dataToRemove =  {
          preId, 
          itemId,
        } 
        //console.log(dataToRemove)
        dispatch(addRemoveItemPreshipment(dataToRemove))
    }  

    // const computeBoxActualWeight = (boxes) => {
    //     var totalWeight = 0
    //     boxes.forEach(b=>{
    //         const boxDimensions = boxSizes.map(boxsize => 
    //             JSON.stringify(boxsize.dimensions).toLocaleLowerCase()            )
    //         const boxDimenString = JSON.stringify(b.dimensions)
    //         const idx = boxDimensions.indexOf(boxDimenString)
    //         if(idx !== -1){
    //             totalWeight = totalWeight + boxSizes[idx].weight
    //         }
    //     })
    //     var finalTotal = totalWeight.toFixed(2)
    //     return Number(finalTotal)
    // }

    return (
        <Box mx='5'>          
            <Box mb='5'>
                <HStack mb='5'>
                <Heading fontSize='2xl' mb='2'>Packages List - {preshipment && preshipment.shipType.toUpperCase()}</Heading>
                <Spacer />
                <ShipmentPreAddBoxModal disable={preshipment && preshipment.shipped} preId={preId} />
                </HStack>    

                <HStack>
                    <Wrap>
                    {preshipment && preshipment.boxes.map((box, idx) => (
                        <WrapItem key={idx}>
                        <Box bg={'#BEE3F8'} border='1px' fontWeight='600' p='2' h='150px' w='150px' boxShadow='lg'>
                            <Stack spacing={'0'}>
                            <Box mb='30px'>
                                {preshipment.productCode === "P" && <Box fontSize='xl'>Box: {idx+1}</Box>}
                                {preshipment.productCode === "D" && <Box fontSize='xl'>Document</Box>}
                                <Box>{box.dimensions.length} x  {box.dimensions.width} x {box.dimensions.height} cm</Box>
                                <Box>{box.weight} kg </Box>
                            </Box>
                            <HStack>
                                <ShipmentPreEditBoxModal disable={preshipment.shipped} preId={preId} box={box} index={idx} />
                                <Spacer />
                                <Button disabled={preshipment.shipped} bg='red.300' h='1.5rem' variant={'ghost'} value={idx} onClick={handleRemoveBox}><MdClose /></Button>
                            </HStack>
                            </Stack>
                        </Box>                  
                        </WrapItem>
                        ))
                    }
                    </Wrap>
                    <Spacer />
                    
                    <Box p='3' border='1px' >
                        <Stack spacing={'1'}>
                            {preshipment && preshipment.charges && preshipment.charges.map((c,i)=>(
                                <Badge key={i} variant='solid' colorScheme='blue'>
                                    {c.name} - {c.amount}
                                </Badge>
                            ))}
                        </Stack>
                    </Box>
                </HStack>
            </Box>

            <Divider />
            {preshipment && preshipment.productCode === "P" && 
                <>
                <Stack>
                    <HStack my='5'>            
                    <Heading fontSize='2xl'>Packing List Items</Heading>
                    <Spacer />
                    <ShipmentPreAddItemModal disable={preshipment && preshipment.shipped} />
                    <ItemSortingModal preshipment={preshipment} />
                    </HStack>
                    
                    <Box  bg='#BEE3F8' w='100%' h='50px' px='5%'  className=''>
                    <HStack>
                        <Text fontSize='3xl'>{preshipment.items.length} pcs</Text><Spacer />
                        <Text fontSize='3xl'>
                            {(preshipment.items.reduce((acc, itm) => acc + itm.weight, 0)).toFixed(2)} kg 
                        </Text>
                        <Spacer />
                        <Text fontSize='3xl'>{(preshipment.items.reduce((acc, itm) => acc + itm.price * itm.qty, 0)).toFixed(2)} {preshipment.currency}</Text>
                    </HStack>
                    </Box>  
                </Stack>

                {preshipment.items.map((itm,idx)=>(
                    <Box key={idx} p='2' my='3' border='1px' borderRadius='lg' overflow='hidden'>
                    <HStack>
                        <Center fontSize='20px' fontWeight={'500'} w='40px' bg='gray.400' borderRadius={'50'}>{idx+1}</Center>
                        <Text fontWeight={'500'}>{itm.item.brandName ? itm.item.brandName : ''}</Text>
                        <Text fontWeight={'500'}>- {itm.item.name || ''}</Text>
                        <Text fontSize='s' py='1' textOverflow={'inherit'}> - {itm.item.description || ''}</Text>
                        <Spacer />            
                        <Button disabled={preshipment.shipped} bg='red.300' h='1.5rem' variant={'ghost'} onClick={()=>handleRemoveItem(itm.item._id)}><MdClose /></Button>
                    </HStack>
                        <Text fontSize={'s'}>{itm.item.manuName || ''} - {itm.item.manuAddress || ''} - {itm.item.manuCountry || ''}</Text>
                    
                    <TableContainer my='3'>
                        <Table size='sm'>
                        <Thead>
                            <Tr>
                            <Th w='100px'>Option</Th>
                            <Th w='100px'>Qty</Th>
                            <Th w='100px'>Weight</Th>
                            <Th w='100px'>Price</Th>
                            <Th>Remark</Th>
                            <Th w='100px'>Sticker</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td><ShipmentPreItemEditModal item={itm} currency={preshipment.currency} /></Td>
                                <Td>{itm.qty}</Td>
                                <Td>{itm.weight}</Td>
                                <Td>{itm.price}</Td>
                                <Td>{itm.note}</Td>
                                <Td><PrintStickerModal idx={idx} /></Td>
                            </Tr>
                        </Tbody>
                        </Table>
                    </TableContainer>
                    </Box>
                ))}
                </>
            }     
             
        </Box>
    )
}

export default ItemListPost