import { 
    COUNTRY_CREATE_FAIL,
    COUNTRY_CREATE_REQUEST,
    COUNTRY_CREATE_RESET,
    COUNTRY_CREATE_SUCCESS,
    COUNTRY_DELETE_FAIL,
    COUNTRY_DELETE_REQUEST,
    COUNTRY_DELETE_RESET,
    COUNTRY_DELETE_SUCCESS,
    COUNTRY_LIST_FAIL,
    COUNTRY_LIST_REQUEST, 
    COUNTRY_LIST_RESET, 
    COUNTRY_LIST_SUCCESS,
    COUNTRY_UPDATE_FAIL,
    COUNTRY_UPDATE_REQUEST,
    COUNTRY_UPDATE_RESET,
    COUNTRY_UPDATE_SUCCESS,
} from "../constants/countryConstants"





export const countryListReducer = (state={countries:[]}, action) => {
    switch(action.type){
        case COUNTRY_LIST_REQUEST:
            return {loading: true, countries:[]}
        case COUNTRY_LIST_SUCCESS:
            return {loading: false, success: true, countries: action.payload}
        case COUNTRY_LIST_FAIL:
            return {loading: false, countries:[], error: action.payload}
        case COUNTRY_LIST_RESET:
            return {}
        default: return state
    }
}

export const countryCreateReducer = (state={}, action) => {
    switch(action.type){
        case COUNTRY_CREATE_REQUEST:
            return {loading: true}
        case COUNTRY_CREATE_SUCCESS:
            return {loading: false, success: true}
        case COUNTRY_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case COUNTRY_CREATE_RESET:
            return {}
        default: return state
    }
}

export const countryUpdateReducer = (state={}, action) => {
    switch(action.type){
        case COUNTRY_UPDATE_REQUEST:
            return {loading: true}
        case COUNTRY_UPDATE_SUCCESS:
            return {loading: false, success: true}
        case COUNTRY_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case COUNTRY_UPDATE_RESET:
            return {}
        default: return state
    }
}

export const countryDeleteReducer = (state={}, action) => {
    switch(action.type){
        case COUNTRY_DELETE_REQUEST:
            return {loading: true}
        case COUNTRY_DELETE_SUCCESS:
            return {loading: false, success: true}
        case COUNTRY_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case COUNTRY_DELETE_RESET:
            return {}
        default: return state
    }
}