const services = [
    {name: 'EMS', code: 2573},
    {name: 'AIR', code: 3278},
    //{name: 'SURFACE', code: 362061} // email from thp IT team on April 10 2024 said this code is correct one for surface
    {name: 'SURFACE', code: 3280} // email from thp IT team on April 10 2024 said this code is correct one for surface
]

const categories = [
    'Gift',
    'Document',
    'Returned goods',
    'Comercial Sample',
    'Merchandise'
]

const countryList = [
    'AUSTRALIA',
    'NORWAY',
    'USA',
    'SOUTH_KOREA',
    'JAPAN',
    'SWEDEN',
    'CANADA',
    'DENMARK',
    'GERMANY',
    'MALAYSIA',
    'NETHERLANDS',
    'CHINA',
    'UK'
]

export {
    services,
    categories,
    countryList
}