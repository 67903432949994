export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST'
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS'
export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL'
export const COUNTRY_LIST_RESET = 'COUNTRY_LIST_RESET'

export const COUNTRY_CREATE_REQUEST = 'COUNTRY_CREATE_REQUEST'
export const COUNTRY_CREATE_SUCCESS = 'COUNTRY_CREATE_SUCCESS'
export const COUNTRY_CREATE_FAIL = 'COUNTRY_CREATE_FAIL'
export const COUNTRY_CREATE_RESET = 'COUNTRY_CREATE_RESET'

export const COUNTRY_UPDATE_REQUEST = 'COUNTRY_UPDATE_REQUEST'
export const COUNTRY_UPDATE_SUCCESS = 'COUNTRY_UPDATE_SUCCESS'
export const COUNTRY_UPDATE_FAIL = 'COUNTRY_UPDATE_FAIL'
export const COUNTRY_UPDATE_RESET = 'COUNTRY_UPDATE_RESET'

export const COUNTRY_DELETE_REQUEST = 'COUNTRY_DELETE_REQUEST'
export const COUNTRY_DELETE_SUCCESS = 'COUNTRY_DELETE_SUCCESS'
export const COUNTRY_DELETE_FAIL = 'COUNTRY_DELETE_FAIL'
export const COUNTRY_DELETE_RESET = 'COUNTRY_DELETE_RESET'