import React from 'react';
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from './store'
import View from './components/View';
import Header from './components/Header';
import {ChakraProvider} from '@chakra-ui/react'
import App from './App';
import theme from '../src/theme'
import AgentMenu from './components/AgentMenu';
import SaleMenu from './components/SaleMenu';

ReactDOM.render(
  <React.Fragment>
    <ChakraProvider theme = {theme}>
        <Provider store={store}>
          <Router>
            <Header />
            <AgentMenu />
            <SaleMenu />
            <View>
              <App />
           </View>
          </Router>
        </Provider>       
    </ChakraProvider>
  </React.Fragment>,
  document.getElementById('root')
);

