import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { MySpinner, Error } from '../../components'
import { getAccountingSaleDetailData } from '../../actions/accounting_actions/accountingActions'
import { Box, Stat, StatLabel, StatNumber, StatHelpText, Button
  // TableContainer, Table, Th, Tr, Td, Tbody, Thead, 
} from '@chakra-ui/react'
import styles from './Styles.module.css'

const SalesAccountingDetailScreen = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { loading, error, data } = useSelector(state=>state.accounting.accountingSaleDetail)

  useEffect(()=>{
    dispatch(getAccountingSaleDetailData(location.state))
  },[dispatch, location])

  
  return (
    location.state === null ? <div>no data to show</div> : 
    <Box my={5}>
      {error && <Error error={error} />}
      {loading && <MySpinner />}
      <Button
        colorScheme={'blue'}
        mb={2}
        onClick={()=>navigate('/accounting/sale')}
      >Back</Button>
      <Stat border={'1px solid gray'} px={'4'} py={'2'} mb={'2'} borderRadius={'md'}>
        <StatLabel>Main Sale</StatLabel>
        <StatNumber>{location.state.dtl.sale.name}</StatNumber>
        {location.state.shipperView && <StatHelpText>{location.state.dtl.saleList.length} sales</StatHelpText>}
      </Stat>
      {data && 
        <Box>
          <table className={styles.mytable}>
            <tbody>
              {data.map((dt,i)=>(
                <tr key={i} style={{borderBottom: '2px solid black'}}>
                  <td style={{width: '30px', border: '1px solid gray'}}>{i+1}</td>
                  <td style={{width: '150px', border: '1px solid gray'}}>{dt.sale.name}</td>
                  <td>
                    <table className={styles.mytable}>
                      <tbody>
                        {dt.invoiceData.map((invd, idxinvd) => (
                          <tr key={idxinvd}>
                            <td style={{width: '70px', border: '1px solid gray'}}>{invd.timeline.start.substring(0,7)}</td>
                            <td>
                              <table className={styles.mytable}>
                                <tbody>
                                  {invd.invoices.length === 0 ? 
                                    <tr><td style={{width: '150px', border: '1px solid gray'}}>No Invoices</td></tr> : 
                                    invd.invoices.map((inv, invidx)=>(
                                      <tr key={invidx}>
                                        <td style={{width: '150px', border: '1px solid gray'}}>{inv.code}</td>
                                        <td style={{width: '100px', border: '1px solid gray'}}>{inv.totalAmount}</td>
                                        <td>
                                          <table className={styles.mytable}>
                                            <tbody>
                                              {inv.payments.length === 0 ?
                                              <tr><td style={{width: '150px', border: '1px solid gray'}}>No Payments</td></tr> : 
                                              inv.payments.map((pm, idxpmt)=>(
                                                <tr key={idxpmt}>
                                                  <td style={{width: '150px', border: '1px solid gray'}}>{pm.code}</td>
                                                  <td style={{width: '100px', border: '1px solid gray'}}>{pm.amount}</td>
                                                  <td style={{border: '1px solid gray'}}>{pm.note}</td>
                                                </tr>
                                              ))
                                              }
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </Box>
      }
    </Box>
  )
}

export default SalesAccountingDetailScreen