import React,{useEffect, useState} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Center,
  CircularProgress,
  Heading,
  HStack,
  Spacer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Input,
  SimpleGrid,
  GridItem,
  useBreakpointValue,
  Checkbox,
  Button,
} from '@chakra-ui/react'
import Error from '../components/Error'
import { getAdminDashboard } from '../actions/dashboardActions'
import MySpinner from '../components/MySpinner'
import useWindowSize from '../components/WindowSize'


const AdminDashboardScreen = () => {
  const colSpan = useBreakpointValue({base: 4, md: 1})
  const size = useWindowSize()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [runJob, setRunJob] = useState(false)
  const [month, setMonth] = useState(new Date().toISOString().substring(0,7))
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  
  const {user} = useSelector((state=>state.login))
  const {loading, error, dashboardData} = useSelector(state=>state.system.adminDashboard)

  const getPrevMonths = (num) => {
    const current = new Date()
    current.setMonth(current.getMonth()-num);
    const month = current.toLocaleString('default', { month: 'long' });
    return month
  }
  useEffect(()=>{
    if(!user.isAdmin){
      navigate('/')
    }
    //dispatch(getAdminDashboard(month, runJob))
    // eslint-disable-next-line
  },[dispatch, navigate, user])

  const handleSubmit = () => {
    dispatch(getAdminDashboard(month, runJob))
    setRunJob(false)
  }

  return (
    <Box my='5'>
      <HStack borderBottom='1px'>
        <Heading mb='3' fontWeight={'700'} >Admin Dashboard {loading && <MySpinner />}</Heading> 
        <Spacer /> 
        <Checkbox
          isChecked={runJob}
          bg='blackAlpha.100'
          py='1'
          px='2'
          isInvalid
          onChange={()=>setRunJob(!runJob)}
        >Run Data</Checkbox>
        <Input 
          type="month"
          size='sm'
          w='200px'
          value={month}
          onChange={e=>setMonth(e.target.value)}
        /> 
        <Button
          size='sm'
          colorScheme={'blue'}
          onClick={handleSubmit}
        >
          Go
        </Button>
      </HStack>
      
      {error && <Error error={error} /> }

      {dashboardData && 

      <>
      <SimpleGrid columns={4} columnGap={3} rowGap={3} my='3' >
        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'> 
            <HStack>
              <Box>
                Pre-Shipments
              </Box>
              <Spacer />
              <Box>{dashboardData.todayPreShips}</Box>
            </HStack>
          </Box>
        </GridItem>

        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'> 
            <HStack>
              <Box>
                Shipments
              </Box>
              <Spacer />
              <Box>{dashboardData.todayShipments}</Box>
            </HStack>
          </Box>
        </GridItem>

        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'> 
            <HStack>
              <Box>
                Invoices
              </Box>
              <Spacer />
              <Box>{dashboardData.todayInvoices}</Box>
            </HStack>
          </Box>
        </GridItem>

        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'> 
            <HStack>
              <Box>
                Payments
              </Box>
              <Spacer />
              <Box>{dashboardData.todayPayments}</Box>
            </HStack>
          </Box>
        </GridItem>

      </SimpleGrid>
      
      <Box w='100%' mb='30px' borderBottom={'1px'} borderColor={'gray.200'}></Box>
      
      {/* <Heading>{new Date().toLocaleDateString('th', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}</Heading> */}


      <HStack my='3' py='3' fontWeight={'700'} borderBottom='1px'>
        <Heading fontSize={'2xl'} >{months[Number(month.substring(5,7))-1]}</Heading>  
        <Spacer />
        <Box>Last Update - {new Date(dashboardData.createdAt).toLocaleString()}</Box>
      </HStack>

      <SimpleGrid columns={4} columnGap={3} rowGap={3} my='3'>

        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
            <Table size='xs' colorScheme={'gray.500'}  >
              <Thead>
                <Tr>
                  <Th>Total</Th>
                  <Th isNumeric>Qty</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Pre-Ships</Td>
                  <Td isNumeric>{dashboardData.monthPreShips}</Td>
                </Tr>
                <Tr>
                  <Td>Shipments</Td>
                  <Td isNumeric>{dashboardData.monthShipments}</Td>
                </Tr>
                <Tr>
                  <Td>Invoices</Td>
                  <Td isNumeric>{dashboardData.monthInvoices}</Td>
                </Tr>
                <Tr>
                  <Td>Payments</Td>
                  <Td isNumeric>{dashboardData.monthPayments}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </GridItem>

        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
            <Table size='xs' colorScheme={'gray.500'}  >
              <Thead>
                <Tr>
                  <Th>Shipments</Th>
                  <Th isNumeric>Qty</Th>
                  <Th isNumeric>Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Total</Td>
                  <Td isNumeric>{dashboardData.monthShipments}</Td>
                  <Td isNumeric>{dashboardData.shipments.totalValue.toLocaleString()}</Td>
                </Tr>
                <Tr>
                  <Td>Invoiced</Td>
                  <Td isNumeric>{dashboardData.shipments.totalInvoicedQty}</Td>
                  <Td isNumeric>{dashboardData.shipments.totalInvoicedValue.toLocaleString()}</Td>
                </Tr>
                <Tr>
                  <Td>Uninvoiced</Td>
                  <Td isNumeric>{dashboardData.monthShipments -dashboardData.shipments.totalInvoicedQty}</Td>
                  <Td isNumeric>{(dashboardData.shipments.totalValue - dashboardData.shipments.totalInvoicedValue).toLocaleString()}</Td>
                </Tr>
                <Tr>
                  <Td>Paid</Td>
                  <Td isNumeric>{dashboardData.shipments.totalPaidQty}</Td>
                  <Td isNumeric>{dashboardData.shipments.totalPaidValue.toLocaleString()}</Td>
                </Tr>
                <Tr>
                  <Td>Unpaid</Td>
                  <Td isNumeric>{dashboardData.monthShipments -dashboardData.shipments.totalPaidQty}</Td>
                  <Td isNumeric>{(dashboardData.shipments.totalValue - dashboardData.shipments.totalPaidValue).toLocaleString()}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </GridItem>

        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
            <Table size='xs' colorScheme={'gray.500'}  >
              <Thead>
                <Tr>
                  <Th>Invoices</Th>
                  <Th isNumeric>Qty</Th>
                  <Th isNumeric>Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Total</Td>
                  <Td isNumeric>{dashboardData.monthInvoices}</Td>
                  <Td isNumeric>{dashboardData.invoices.totalValue.toLocaleString()}</Td>
                </Tr>
                <Tr>
                  <Td>Paid</Td>
                  <Td isNumeric>{dashboardData.invoices.totalPaidInvoiceQty}</Td>
                  <Td isNumeric>{dashboardData.invoices.totalPaidInvoiceValue.toLocaleString()}</Td>
                </Tr>
                <Tr>
                  <Td>Unpaid</Td>
                  <Td isNumeric>{dashboardData.monthInvoices-dashboardData.invoices.totalPaidInvoiceQty}</Td>
                  <Td isNumeric>{(dashboardData.invoices.totalValue-dashboardData.invoices.totalPaidInvoiceValue).toLocaleString()}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </GridItem>

        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
            <Table size='xs' colorScheme={'gray.500'}  >
              <Thead>
                <Tr>
                  <Th>Payments</Th>
                  <Th isNumeric>Qty</Th>
                  <Th isNumeric>Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Total</Td>
                  <Td isNumeric>{dashboardData.monthPayments}</Td>
                  <Td isNumeric>{dashboardData.payments.totalValue.toLocaleString()}</Td>
                </Tr>
                <Tr>
                  <Td>Approved</Td>
                  <Td isNumeric>{dashboardData.payments.totalPaidPaymentQty}</Td>
                  <Td isNumeric>{dashboardData.payments.totalPaidPaymentValue.toLocaleString()}</Td>
                </Tr>
                <Tr>
                  <Td>Unapproved</Td>
                  <Td isNumeric>{dashboardData.monthPayments-dashboardData.payments.totalPaidPaymentQty}</Td>
                  <Td isNumeric>{(dashboardData.payments.totalValue-dashboardData.payments.totalPaidPaymentValue).toLocaleString()}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </GridItem>

        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
            <Table size='xs' colorScheme={'gray.500'}  >
              <Thead>
                <Tr>
                  <Th>V Plus</Th>
                  <Th isNumeric>Qty</Th>
                  <Th isNumeric>Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{getPrevMonths(0)}</Td>
                  <Td isNumeric>{dashboardData.vplus.thisMonthVPlusCount}</Td>
                  <Td isNumeric>{dashboardData.vplus.thisMonthVPlusValue.toLocaleString()}</Td>
                </Tr>
                <Tr>
                  <Td>{getPrevMonths(1)}</Td>
                  <Td isNumeric>{dashboardData.vplus.lastMonthVPlusCount}</Td>
                  <Td isNumeric>{dashboardData.vplus.lastMonthVPlusValue.toLocaleString()}</Td>
                </Tr>
                <Tr>
                  <Td>{getPrevMonths(2)}</Td>
                  <Td isNumeric>{dashboardData.vplus.prevMonthVPlusCount}</Td>
                  <Td isNumeric>{dashboardData.vplus.prevMonthVPlusValue.toLocaleString()}</Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </GridItem>


        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
            <Table size='xs' colorScheme={'gray.500'}  >
              <Thead>
                <Tr>
                  <Th>Top Agents</Th>
                  <Th isNumeric>Shipments</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dashboardData.topAgents.map((a, i)=>(
                  <Tr key={i}>
                    <Td>{a.agent.name}</Td>
                    <Td isNumeric>{a.count}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </GridItem>

        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
            <Table size='xs' colorScheme={'gray.500'}  >
              <Thead>
                <Tr>
                  <Th>Top Sales</Th>
                  <Th isNumeric>Shipments</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dashboardData.topSales.map((s, i)=>(
                  <Tr key={i}>
                    <Td>{s.sale.name}</Td>
                    <Td isNumeric>{s.count}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </GridItem>

        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h={size.width > 760 ? '200px' : 'full'} overflow={'auto'}>
            <Table size='xs' colorScheme={'gray.500'}  >
              <Thead>
                <Tr>
                  <Th>Agent Balance</Th>
                  <Th>%</Th>
                  <Th isNumeric>Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dashboardData.agentBillingDetail.map((a,i)=>(
                  <Tr key={i}>
                    <Td>{a.agent.name}</Td>
                    <Td>
                      <Center>
                        <CircularProgress value={a.percentage} size='22px' thickness='15px' color='red.500' />                        
                      </Center>
                    </Td>
                    <Td isNumeric>{a.totalUnPaid.toLocaleString()}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </GridItem>

        <GridItem colSpan={colSpan}>
          <Box bg='blackAlpha.50' p='3' borderRadius={10} h={size.width > 760 ? '200px' : 'full'} overflow={'auto'}>
            <Table size='xs' colorScheme={'gray.500'}  >
              <Thead>
                <Tr>
                  <Th>Sale Balance</Th>
                  <Th>%</Th>
                  <Th isNumeric>Value</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dashboardData.saleBillingDetail.map((s,i)=>(
                  <Tr key={i}>
                    <Td>{s.sale.name}</Td>
                    <Td>
                      <Center>
                        <CircularProgress value={s.percentage} size='22px' thickness='15px' color='red.500' />                        
                      </Center>
                    </Td>
                    <Td isNumeric>{s.totalUnPaid.toLocaleString()}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </GridItem>

      </SimpleGrid>
      
      </>
      }
      
    </Box>
  )
}

export default AdminDashboardScreen