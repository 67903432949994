import axios from 'axios'
import {
    ADDRESS_CREATE_REQUEST,
    ADDRESS_CREATE_SUCCESS,
    ADDRESS_CREATE_FAIL,
    DHL_ADDRESS_VALIDATE_REQUEST,
    DHL_ADDRESS_VALIDATE_SUCCESS,
    DHL_ADDRESS_VALIDATE_FAIL,
    ADDRESS_LIST_REQUEST,
    ADDRESS_LIST_SUCCESS,
    ADDRESS_LIST_FAIL,
    ADDRESS_BYID_REQUEST,
    ADDRESS_BYID_SUCCESS,
    ADDRESS_BYID_FAIL,
    ADDRESS_EDIT_REQUEST,
    ADDRESS_EDIT_SUCCESS,
    ADDRESS_EDIT_FAIL,
    ADDRESS_DELETE_REQUEST,
    ADDRESS_DELETE_SUCCESS,
    ADDRESS_DELETE_FAIL,
    ADDRESS_FROM_REQUEST,
    ADDRESS_FROM_SUCCESS,
    ADDRESS_FROM_FAIL,
    ADDRESS_FROM_NAME_REQUEST,
    ADDRESS_FROM_NAME_SUCCESS,
    ADDRESS_FROM_NAME_FAIL,
} from '../constants/addressConstants'


export const createAddress = (newAddress) => async (dispatch, getState) => {
    try {
        dispatch({type: ADDRESS_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/addresses`, newAddress, config)
        dispatch({type: ADDRESS_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ADDRESS_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listAddress = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: ADDRESS_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/addresses?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: ADDRESS_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ADDRESS_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const getByIdAddress = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: ADDRESS_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/addresses/${id}`, config)
        dispatch({type: ADDRESS_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ADDRESS_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const editAddress = (id, editedAddress) => async (dispatch, getState) => {
    try {
        dispatch({type: ADDRESS_EDIT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.put(`/api/addresses/${id}`, editedAddress, config)
        dispatch({type: ADDRESS_EDIT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ADDRESS_EDIT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deleteAddress = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: ADDRESS_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/addresses/${id}`, config)
        dispatch({type: ADDRESS_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: ADDRESS_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const fromAddress = (keyword='') => async (dispatch, getState) => {
    try {
        dispatch({type: ADDRESS_FROM_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/addresses/from?keyword=${keyword}`, config)
        dispatch({type: ADDRESS_FROM_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ADDRESS_FROM_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const fromAddressNames = () => async (dispatch, getState) => {
    try {
        dispatch({type: ADDRESS_FROM_NAME_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/addresses/contactnames`, config)
        dispatch({type: ADDRESS_FROM_NAME_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: ADDRESS_FROM_NAME_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const dhlValidateAddress = (codes) => async (dispatch) => {
    try {
        dispatch({type: DHL_ADDRESS_VALIDATE_REQUEST})
        
        const {data} = await axios.post(`/api/addresses/validate`, codes)
        dispatch({type: DHL_ADDRESS_VALIDATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DHL_ADDRESS_VALIDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}