import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, GridItem, IconButton, Input, InputGroup, InputRightElement, Select, SimpleGrid, Stack, useBreakpointValue } from '@chakra-ui/react'
import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MdOutlineSearch } from 'react-icons/md'
import { listAgent, listSale } from '../actions/userActions'
import MySpinner from './MySpinner'
import Error from './Error'

const Filter = ({filterFunction, saleShow, agentShow, dateShow, paymentShow, invoiceShow, limitShow, scanShow, approveShow}) => {
    const dispatch = useDispatch()
    const colSpan = useBreakpointValue({base: 6, md: 1})
    const [agentSearchKeyword ,setAgentSearchKeyword] = useState('')
    const [agentId, setAgentId] = useState('')
    const [saleSearchKeyword ,setSaleSearchKeyword] = useState('')
    const [saleId, setSaleId] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [isPaid, setIsPaid] = useState('')
    const [isInvoiced, setIsInvoiced] = useState('')
    const [isApproved, setIsApproved] = useState('')
    const [isScanned, setIsScanned] = useState('')
    const [limit, setLimit] = useState(50)

    const {user} = useSelector(state=>state.login)
    const {loading: loadingAgentList, error: errorAgentList, agents} = useSelector(state=>state.user.AgentList)
    const {loading: loadingSaleList, error: errorSaleList, sales} = useSelector(state=>state.user.SaleList)
 
    async function addDays(date, days){
        var result = new Date(date)
        result.setDate(result.getDate()+days)
        var dateString = result.toISOString()
        return dateString.substring(0,10)
    }

    const handleSearchAgent = (e) => {
        if(e.key === 'Enter'){
            if(user.accountType === 'sale'){
                dispatch(listAgent('', 1000, agentSearchKeyword, user._id))
            } else {
                dispatch(listAgent('', 1000, agentSearchKeyword, saleId))
            }       
        }
        return
    }
    const handleSearchAgentButton = (e) => {
        if(user.accountType === 'sale'){
            dispatch(listAgent('', 1000, agentSearchKeyword, user._id))
        } else {
            dispatch(listAgent('', 1000, agentSearchKeyword, saleId))
        }       
    }
    const handleSearchSale = (e) => {
        if(e.key === 'Enter'){
            dispatch(listSale(saleSearchKeyword))
        }
        return
    }
    const handleSearchSaleButton = (e) => {
        dispatch(listSale(saleSearchKeyword))
    }

    const handleSaleSelected = (saleId) => {
        setSaleId(saleId)
        dispatch(listAgent(1, 20, agentSearchKeyword, saleId))
    }

    const handleSubmitSearch = async () => {
    
        const searchData = {
          isPaid,
          isInvoiced,
          isScanned,
          isApproved,
          agentId,
          saleId,
          limit,
          startDate,
          endDate: endDate !== '' ? await addDays(endDate,1) : '',
        }
    
        if(startDate === ''){
          searchData.startDate = '2022-01-01'
        }
        if(endDate === ''){
          var d = new Date()
          var date = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
          var dateISO = date.toISOString()
          var dateFormat = dateISO.substring(0,10)
          searchData.endDate = await addDays(dateFormat,1)
        }
        dispatch(filterFunction(searchData))
        //console.log(searchData)
    }

      
    return (
        <Accordion allowToggle >
            <AccordionItem>
                <AccordionButton _expanded={{ bg: '#323232', color: 'white' }}>
                    <Box flex='1' fontWeight={'700'} textAlign='left'>
                    Filter
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                {errorAgentList && <Error error={errorAgentList} />}
                {errorSaleList && <Error error={errorSaleList} /> }

                <SimpleGrid columns={6} columnGap={3} rowGap={2} w='full'>

                    {saleShow && <GridItem colSpan={colSpan}>
                    <Stack spacing={1}>
                        <Box>Sale {loadingSaleList && <MySpinner size='sm' />}</Box>
                        <InputGroup size='sm'>
                        <Input 
                            type='text'
                            placeholder='Search Sale'
                            value={saleSearchKeyword}
                            onChange={e=> setSaleSearchKeyword(e.target.value)}
                            onKeyPress={handleSearchSale}
                        />
                        <InputRightElement>
                        <IconButton 
                            size='xs' 
                            w='50px'
                            mr='1'
                            colorScheme={'blackAlpha'} 
                            aria-label='Search sale' 
                            icon={<MdOutlineSearch size={25} />} 
                            onClick={()=>handleSearchSaleButton()}
                            />
                        </InputRightElement>
                        </InputGroup>
                        
                        <Select size='sm' placeholder='Sale List' onChange={e=>handleSaleSelected(e.target.value)}>
                            {sales && sales.map((sa, idx)=>(
                                <option key={idx} value={sa._id}>{sa.saleCode} - {sa.name}</option>
                            ))}
                        </Select>
                    </Stack>
                    </GridItem>}

                    {agentShow && <GridItem colSpan={colSpan}>
                    <Stack spacing={1}>
                        <Box>Agent {loadingAgentList && <MySpinner size='sm' />}</Box>
                        <InputGroup size='sm'>
                        <Input 
                            type='text'
                            placeholder='Search Agent'
                            value={agentSearchKeyword}
                            onChange={e=> setAgentSearchKeyword(e.target.value)}
                            onKeyPress={handleSearchAgent}
                        />
                        <InputRightElement>
                            <IconButton 
                            size='xs' 
                            w='50px'
                            mr='1'
                            colorScheme={'blackAlpha'} 
                            aria-label='Search sale' 
                            icon={<MdOutlineSearch size={25} />} 
                            onClick={()=>handleSearchAgentButton()}
                            />
                        </InputRightElement>
                        </InputGroup>
                        
                        <Select size='sm' placeholder='Agent List' onChange={e=>setAgentId(e.target.value)}>
                            {agents && agents.map((ag, idx)=>(
                                <option key={idx} value={ag._id}>{ag.name} - {ag.agentCode}</option>
                            ))}
                        </Select>
                    </Stack>
                    </GridItem>}

                    {dateShow && <GridItem colSpan={colSpan}>
                    <Stack spacing={1}>
                        <Box>Date Range</Box>
                        <Input 
                            type='date'
                            size='sm'
                            placeholder='Start'
                            value={startDate}
                            onChange={e=> setStartDate(e.target.value)}
                        />
                        <Input 
                            type='date'
                            size='sm'
                            placeholder='End'
                            value={endDate}
                            onChange={e=> setEndDate(e.target.value)}
                        />
                    </Stack>
                    </GridItem>}

                    <GridItem colSpan={colSpan}>
                    <Stack spacing={1}>  
                        <Box>Billing</Box>                    
                        {paymentShow && <Select size='sm' placeholder='Payment' onChange={e=>setIsPaid(e.target.value)}>                       
                            <option value='true'>Paid</option>
                            <option value='false'>Not Paid</option>                      
                        </Select> }

                        {invoiceShow && <Select size='sm' mt='27px' placeholder='Invoicing' onChange={e=>setIsInvoiced(e.target.value)}>                       
                            <option value='true'>Invoiced</option>
                            <option value='false'>Not Invoiced</option>                      
                        </Select>}

                        {approveShow && <Select size='sm' mt='27px' placeholder='Approval' onChange={e=>setIsApproved(e.target.value)}>                       
                            <option value='true'>Approved</option>
                            <option value='false'>Not Approved</option>                      
                        </Select>}
                    </Stack>
                    </GridItem>


                    <GridItem colSpan={colSpan}>
                    <Stack spacing={1}>  
                        <Box>Other</Box>                    
                        {scanShow && <Select size='sm' placeholder='Scanning' onChange={e=>setIsScanned(e.target.value)}>                       
                            <option value='true'>Scanned</option>
                            <option value='false'>Not Scanned</option>                      
                        </Select> }

                        {limitShow && <Select placeholder='Limit' onChange={e=>setLimit(e.target.value)} size='sm'>
                            {[50, 100, 150, 200].map((v,i)=>(
                            <option key={i} value={v}>{v}</option>
                            ))}
                        </Select>}

                    </Stack>
                    </GridItem>

                    <GridItem colSpan={colSpan}>
                    <Stack spacing={1}> 
                        <Box>Click</Box>                 
                        <Button
                            size={'sm'}    
                            w='full'                            
                            colorScheme={'green'}
                            onClick={handleSubmitSearch}
                            >Search
                        </Button>
                        
                        <Button
                        size={'sm'}
                        colorScheme={'yellow'}
                        onClick={()=>window.location.reload(false)}
                        >Clear</Button>
                    </Stack>
                    </GridItem>

                </SimpleGrid>

                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}


Filter.defaultProps = {
    saleShow: true,
    agentShow: true,
    dateShow: true,
    paymentShow: true,
    invoiceShow: true,
    limitShow: true,
    scanShow: true,
    approveShow: false,
}

export default Filter