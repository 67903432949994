import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
    Box,
    Button,
    HStack,
    Center,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Spacer,
    MenuGroup,
    MenuDivider
} from '@chakra-ui/react'
import { MdKeyboardArrowDown, MdOutlineViewHeadline } from 'react-icons/md'
import useWindowSize from './WindowSize'

const AgentMenu = () => {
    const navigate = useNavigate()
    const size = useWindowSize()
    const {user} = useSelector(state=>state.login)
    return ( 
        <>
        {user && user.accountType === 'agent' &&
        <Box  bg='gray.200' w='100%' h='50px' px='5%'>
            {size.width > 760 ? 
                <Center py='2'>
                    <HStack spacing={3}>
                        <Link to='/saledashboard'>
                            <Button 
                                size='sm' 
                                variant='ghost'
                            >Dashboard</Button>
                        </Link>

                        <Box>
                            <Menu >
                            <MenuButton size='sm' as={Button} variant={'ghost'} rightIcon={<MdKeyboardArrowDown />}>
                                Shipments
                            </MenuButton>
                            <MenuList>                    
                                <MenuItem onClick={()=>navigate('/myshipments')}>DHL</MenuItem>
                                <MenuItem onClick={()=>navigate('/mythpshipments')}>THP</MenuItem> 
                                <MenuItem onClick={()=>navigate('/mydomesticshipment')}>DOMESTIC</MenuItem> 
                            </MenuList>
                            </Menu>             
                        </Box>


                        <Box>
                            <Menu >
                            <MenuButton size='sm' as={Button} variant={'ghost'} rightIcon={<MdKeyboardArrowDown />}>
                                Invoices
                            </MenuButton>
                            <MenuList>                    
                                <MenuItem onClick={()=>navigate('/myinvoices')}>DHL</MenuItem>
                                <MenuItem onClick={()=>navigate('/mythpinvoices')}>THP</MenuItem> 
                                <MenuItem onClick={()=>navigate('/mydomesticinvoice')}>DOMESTIC</MenuItem> 
                            </MenuList>
                            </Menu>             
                        </Box>

                        <Box>
                            <Menu >
                            <MenuButton size='sm' as={Button} variant={'ghost'} rightIcon={<MdKeyboardArrowDown />}>
                                Payments
                            </MenuButton>
                            <MenuList>                    
                                <MenuItem onClick={()=>navigate('/mypayments')}>DHL</MenuItem>
                                <MenuItem onClick={()=>navigate('/mythppayments')}>THP</MenuItem>
                                <MenuItem onClick={()=>navigate('/mydomesticpayment')}>DOMESTIC</MenuItem>
                            </MenuList>
                            </Menu>             
                        </Box>

                        <Link to='/myrate'>
                            <Button 
                                size='sm' 
                                variant='ghost'
                            >Rate</Button>
                        </Link>
                        
                        <Link to='/myaccount'>
                        <Button 
                            size='sm' 
                            variant='ghost'
                        >Account</Button>
                        </Link>
                    </HStack>
                </Center>
                : 
                <HStack spacing={3} py='1'>
                    <Link to='/saledashboard'>
                        <Button 
                            size='sm' 
                            variant='ghost'
                        >Dashboard</Button>
                    </Link>
                    <Spacer />
                    <Menu> 
                        <MenuButton as={Button} colorScheme='white' variant={'ghost'} rightIcon={<MdKeyboardArrowDown />}>
                            <MdOutlineViewHeadline size='25px' />
                        </MenuButton>

                        <MenuList>
                            <MenuGroup title='Shipments'>
                                <MenuItem onClick={()=>navigate('/myshipments')}>DHL</MenuItem>
                                <MenuItem onClick={()=>navigate('/mythpshipments')}>THP</MenuItem> 
                                <MenuItem onClick={()=>navigate('/mydomesticshipment')}>DMS</MenuItem> 
                            </MenuGroup>
                            <MenuDivider />
                            <MenuGroup title='Invoices'>
                                <MenuItem onClick={()=>navigate('/myinvoices')}>DHL</MenuItem>
                                <MenuItem onClick={()=>navigate('/mythpinvoices')}>THP</MenuItem> 
                                <MenuItem onClick={()=>navigate('/mydomesticinvoice')}>DMS</MenuItem> 
                            </MenuGroup>
                            <MenuDivider />
                            <MenuGroup title='Payments'>
                                <MenuItem onClick={()=>navigate('/mypayments')}>DHL</MenuItem>
                                <MenuItem onClick={()=>navigate('/mythppayments')}>THP</MenuItem>
                                <MenuItem onClick={()=>navigate('/mydomesticpayment')}>DMS</MenuItem>
                            </MenuGroup>
                            <MenuDivider />
                            <MenuItem onClick={()=>navigate('/myrate')}>Rate</MenuItem> 
                            <MenuItem onClick={()=>navigate('/myaccount')}>Account</MenuItem> 
                        </MenuList>
                    </Menu>
                </HStack>
            }
        </Box>
        }</>
    )
}

export default AgentMenu