import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    Stack,
    HStack,
    Spacer,
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Spinner,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    FormControl,
    FormLabel,
    Input,
    Text,
  } from '@chakra-ui/react'
import { MdAddBox } from 'react-icons/md'
import { listItem } from '../actions/itemActions'
import { addRemoveItemPreshipment } from '../actions/shipmentActions'
import { ITEM_LIST_RESET } from '../constants/itemConstants'
import ItemAddModal from '../Modals/ItemAddModal'
import { PRESHIPMENT_ADD_REMOVE_ITEM_RESET } from '../constants/shipmentConstants'
import Error from '../components/Error'

const ShipmentPreAddItemModal = ({disable}) => {
    const params = useParams()
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [keyword, setKeyword] = useState('')
    const [searchKey, setSearchKey] = useState('')
    const {loading, error, items} = useSelector(state=>state.item.List)
    const {loading: loadingAddItem, error: errorAddItem, success: successAddItem} = useSelector(state=>state.preshipment.AddRemoveItem)
    const {success: successCreate} = useSelector(state=>state.item.Create)
    const pageNumber = 1
    const itemCount = 50
    useEffect(()=>{        
        if(successAddItem){
            setSearchKey('')            
            dispatch({type: ITEM_LIST_RESET})
            dispatch({type: PRESHIPMENT_ADD_REMOVE_ITEM_RESET})
            setShow(false)
        }
        dispatch(listItem(pageNumber, itemCount, keyword))
      },[dispatch, successCreate, successAddItem, pageNumber, itemCount, keyword])

    const handleClose = () => {
        setSearchKey('')
        dispatch({type: ITEM_LIST_RESET})
        dispatch({type: PRESHIPMENT_ADD_REMOVE_ITEM_RESET})
        setShow(false)
    }
    const handleSearch = (e) => {
        if(e.key === 'Enter'){
            setKeyword(searchKey)
            dispatch(listItem(pageNumber, itemCount, keyword))
        }
        return
    }
    const handleSubmit = (e) => {
        const dataToAdd = {
            preId: params.id,
            itemId: e.target.value,
            add: true
        }
        dispatch(addRemoveItemPreshipment(dataToAdd))
    }
    return (
        <>
            <Button 
                disabled={disable}
                size='sm' 
                colorScheme='purple' 
                onClick={()=>setShow(true)} 
                leftIcon={<MdAddBox />}
            >Add Item</Button>

            <Modal isOpen={show} onClose={handleClose} size='5xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent maxW='90%' h={'90vh'} overflow='auto'>
                <ModalHeader>{(loading || loadingAddItem) ? <Spinner label='Please wait' speed='0.1s' thickness='5px' /> : 'Add Item To PreShip' }</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={4}>
                        {error && <Error error={error} /> }
                        {errorAddItem && <Error error={errorAddItem} /> }
                        <FormControl>
                            <HStack>
                                <Box>
                                    <FormLabel>Item Search</FormLabel>
                                    <Text as='sup'>Name, Brand, Thai, Description, Manu:Name</Text>
                                </Box>
                                <Spacer />
                                <Box>
                                    <ItemAddModal />
                                </Box>
                            </HStack>
                            <Input 
                                type='text' 
                                autoFocus
                                size='sm'
                                value={searchKey}
                                onChange={e=>setSearchKey(e.target.value)}
                                onKeyPress={handleSearch}                                    
                                />
                        </FormControl>

                        <Table h={'100vh'} size='sm' variant='striped' colorScheme='yellow' my='4'>
                            <Thead>
                                <Tr>
                                <Th>Brand Name</Th>
                                <Th>Name</Th>
                                <Th>Thai</Th>
                                <Th>Description</Th>
                                <Th>HS Code</Th>
                                <Th maxW='300px'>Manufacturer</Th>
                                <Th>Options</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {items && items.map((item,index)=>(
                                <Tr key={index}>
                                    <Td>{item.brandName}</Td>
                                    <Td>{item.name}</Td>
                                    <Td>{item.thaiName}</Td>
                                    <Td>{item.description}</Td>
                                    <Td>{item.hsCode ? item.hsCode : '-'}</Td>
                                    <Td maxW='300px'>
                                        <Stack spacing={0}>
                                            <Box fontWeight={700}>{item.manuName}</Box>
                                            <Box>{item.manuAddress}</Box>
                                        </Stack>
                                    </Td>
                                    <Td>
                                        <Button 
                                            colorScheme='green' 
                                            size='sm'
                                            value={item._id}
                                            onClick={handleSubmit}
                                            >Select
                                        </Button>
                                    </Td>
                                </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Stack>
                </ModalBody>
            </ModalContent>
            </Modal>
        </>
    )
}

ShipmentPreAddItemModal.defaultProps = {
    disable: false,
}

export default ShipmentPreAddItemModal