import axios from 'axios'
import { 
    CHARGES_CREATE_FAIL,
    CHARGES_CREATE_REQUEST,
    CHARGES_CREATE_SUCCESS,
    CHARGES_DELETE_FAIL,
    CHARGES_DELETE_REQUEST,
    CHARGES_DELETE_SUCCESS,
    CHARGES_LIST_FAIL,
    CHARGES_LIST_REQUEST, 
    CHARGES_LIST_SUCCESS,
    CHARGES_UPDATE_FAIL,
    CHARGES_UPDATE_REQUEST,
    CHARGES_UPDATE_SUCCESS,


} from '../constants/chargesConstants'



export const getChargesList = () => async (dispatch, getState) => {
    try {
        dispatch({type: CHARGES_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/charges`, config)
        dispatch({type: CHARGES_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: CHARGES_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addNewCharges = (newCharges) => async (dispatch, getState) => {
    try {
        dispatch({type: CHARGES_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/charges`, newCharges, config)
        dispatch({type: CHARGES_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: CHARGES_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateCharges = (chargesDetail, chargesId) => async (dispatch, getState) => {
    try {
        dispatch({type: CHARGES_UPDATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/charges/${chargesId}`, chargesDetail, config)
        dispatch({type: CHARGES_UPDATE_SUCCESS})
    } catch (error) {
        dispatch({
            type: CHARGES_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const deleteCharges = (chargesId) => async (dispatch, getState) => {
    try {
        dispatch({type: CHARGES_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/charges/${chargesId}`, config)
        dispatch({type: CHARGES_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: CHARGES_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}
