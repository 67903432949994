import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Stack, Text, Center, HStack, Spacer, Button, OrderedList, ListItem } from '@chakra-ui/react'
import ReactToPrint from 'react-to-print'
// import Error from '../../components/Error'
import { MdLocalPrintshop } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Barcode from 'react-barcode'
import { DOMESTICSHIPMENT_CREATE_RESET } from '../../constants/domestic_constants/domesticShipmentConstants'

const DomesticShippingLabel = () => {
    const labelRef = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {shipment} = useSelector(state=>state.domestic.ShipmentCreate)
  

    const labelStyle = `
        @page {
            size: A4;
            margin: 5mm 5mm;
        }
    `;

    return (
        <Box marginY={5}>
            {/* {error && <Error error={error} /> }
            {loading && <Spinner />} */}
            <HStack my='3'>
                

                <Box></Box>
                <Spacer />
                <Box>
                    <Button
                        colorScheme='red'
                        size={'sm'}
                        w={'100px'}
                        mr={'50px'}
                        onClick={()=>{
                            dispatch({type: DOMESTICSHIPMENT_CREATE_RESET})
                            navigate('/domesticshipment')
                        }}
                    >Finish</Button>
                </Box>
                <Box>
                    <ReactToPrint
                        trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                        content={() => labelRef.current}
                        pageStyle={()=> labelStyle}
                    />
                </Box>
            </HStack>
            <Box ref={labelRef} border='1px' height='100vh' p={4} >
                <HStack fontSize='xl' borderBottom={'1px'} pb={3} fontWeight={700} >
                    <Box >Shipping Label</Box>
                    <Spacer />
                    <Box>{shipment.carrier.toUpperCase()}</Box>
                </HStack>

                <HStack  borderBottom={'1px'} pb={3}>
                    <Box></Box>
                    <Spacer />
                    <Box>
                        <Barcode value={shipment.awb} height={40} width={3} />
                    </Box>
                </HStack>

                <Grid
                    templateRows='repeat(1, 1fr)'
                    templateColumns='repeat(2, 1fr)'
                    gap={4}
                    borderBottom={'1px'}
                    fontSize={'2xl'}
                    paddingY={3}
                >
                    <Stack spacing={'0'}>
                        <Text fontWeight={'700'}>ผู้ส่ง</Text>
                        <Text fontWeight={'400'}>{shipment.sender.name}</Text>
                      
                        <Text>{shipment.sender.address.address}, {shipment.sender.address.city}</Text>                        
                        <Text>{shipment.sender.address.province}, {shipment.sender.address.postalCode}</Text>
                       
                        <Text>{shipment.sender.tel}</Text>
                        <Text>{shipment.sender.email}</Text>
                    </Stack>

                    <Stack spacing={'0'}>
                        <Text fontWeight={'700'}>ผู้รับ</Text>
                        <Text fontWeight={'400'}>{shipment.receiver.name}</Text>
                      
                        <Text>{shipment.receiver.address.address} {shipment.receiver.address.address2 && ', '+shipment.receiver.address.address2}</Text>                        
                        {shipment.receiver.address.address3 && <Text>{shipment.receiver.address.address3}</Text>}
                        <Text>{shipment.receiver.address.city}, {shipment.receiver.address.province}, {shipment.receiver.address.postalCode}</Text>
                                                   
                        <Text>{shipment.receiver.tel}</Text>
                        <Text>{shipment.receiver.email}</Text>
                    </Stack>
                </Grid>
                <Box borderBottom={'1px'}>
                    <Center fontSize={'xl'}>Box Detail</Center>
                    {shipment.boxes.map((b,i)=>(
                        <Box key={i} marginY={2}>{b.dimensions.length} x {b.dimensions.width} x {b.dimensions.height} cm - {b.weight} kg </Box>
                    
                    ))}
                </Box>
                
                <Box borderBottom={'1px'}>
                    <Center fontSize={'xl'}>Item Detail</Center>
                    <OrderedList>
                        {shipment.items.map((itm,i)=>(
                            <ListItem key={i} paddingY={2}>
                                <HStack>
                                    <Box>{itm.item.name}</Box>
                                    <Spacer />
                                    <Box>{itm.qty}</Box>
                                </HStack>
                            </ListItem>
                        ))}
                        
                    </OrderedList>
                </Box>
            
            </Box>
            <Spacer />
            <Box></Box>
        
        </Box>
    )
}

export default DomesticShippingLabel