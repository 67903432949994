import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { myInvoices, saleFilterInvoices } from '../../actions/invoiceActions'
import Pagination from '../../components/Pagination'
import SearchBox from '../../components/SearchBox'
import {
    Box,
    HStack,
    Select,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Center,
    Heading,
    Stack,
    Input,
    Text,
    Flex,
    SimpleGrid,
    GridItem,
    useBreakpointValue,
} from '@chakra-ui/react'
import { MdCheckCircle, MdCancel, MdIncompleteCircle } from 'react-icons/md'
import Error from '../../components/Error'
import useWindowSize from '../../components/WindowSize'
import Filter from '../../components/Filter'
import MySpinner from '../../components/MySpinner'

const AgentInvoiceListScreen = () => {
    const colSpan = useBreakpointValue({base: 3, md: 1})
    const size = useWindowSize()
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const keyword = params.keyword || ''
    const [dateForAgent, setDateForAgent] = useState('')
    const pageNumber = params.pageNumber || 1
    const [itemCount, setItemCount] = useState(50)
    const {user} = useSelector(state=>state.login)
    const {loading, error, invoices, pages, page} = useSelector(state=>state.invoice.My)

    useEffect(()=>{
        dispatch(myInvoices(pageNumber, itemCount, keyword, dateForAgent))
    },[dispatch, pageNumber, itemCount, keyword, dateForAgent])


    const computeBalance = (payments) => {
      var total = 0
      payments.forEach(p=>{
        //console.log(p.isApproved)
        if(p.isApproved){
          total = total+p.amount
        }
      })
      return total
    }


    return (
        <Box mt='3'>
          <Center mb='3'><Heading bg='yellow.300' px='2' borderRadius={10}>DHL Invoices {loading && <MySpinner />}</Heading></Center>
          {error && <Error error={error} />}

            <SimpleGrid columns={3} columnGap={3} rowGap={3} my='2'>
              <GridItem colSpan={colSpan}>
                <SearchBox width='full' />
              </GridItem>

              <GridItem colSpan={colSpan}>
                {user && user.accountType === 'agent' &&
                  <Input 
                      type='date'
                      size='sm'
                      w='full'
                      value={dateForAgent}
                      onChange={e=>setDateForAgent(e.target.value)}
                    />
                }
              </GridItem>

              <GridItem ml='auto' colSpan={colSpan}>
                <HStack>
                  {user && user.accountType === 'sale' && <Button size='sm' colorScheme={'green'} onClick={()=>navigate('/salecreateinvoice')}>New Invoice</Button>  }
                  <Select placeholder='Select' w='80px' onChange={e=>setItemCount(e.target.value)} size='sm'>
                    {[50, 100, 150, 200].map((v,i)=>(
                        <option key={i} value={v}>{v}</option>
                    ))}
                  </Select>
                  <Pagination keyword = {keyword} pages={pages} page={page} path='myinvoices' />
                </HStack>
              </GridItem>
            </SimpleGrid>

            {user && user.accountType === 'sale' && 
              <Filter filterFunction={saleFilterInvoices} saleShow={false} scanShow={false} invoiceShow={false} />
            }            


            {size.width > 760 ? 
              <Table size='sm' variant='striped' colorScheme='purple' my='4'>
                <Thead>
                    <Tr>
                    <Th>No</Th>
                    <Th>Inovice</Th>
                    {user && user.accountType === 'sale' && <Th>Agent</Th>}
                    <Th isNumeric>Total Amount</Th>
                    <Th isNumeric>Total Balance</Th>
                    <Th>Created Date</Th>
                    <Th>Due Date</Th>
                    <Th isNumeric>Shipment Qty</Th>
                    <Th>Paid</Th>
                    <Th>Detail</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {invoices && invoices.map((iv, idx)=>(
                    <Tr key={idx}>
                        <Td>{pageNumber === 1 ? (idx+1) : (((Number(pageNumber)-1) * itemCount) + (idx+1))}</Td>
                        <Td>{iv.code}</Td>
                        {user && user.accountType === 'sale' && <Td>{iv.agent.name}</Td>}
                        <Td isNumeric>{iv.totalAmount.toLocaleString()}</Td>
                        <Td isNumeric>{(iv.totalAmount - computeBalance(iv.payments)).toLocaleString()}</Td>
                        <Td>{iv.createdAt.substring(0,10)}</Td>
                        <Td>                               
                          <Text>{iv.dueDate.substring(0,10)}</Text>
                          {!iv.isPaid && new Date().toISOString().substring(0,10) > iv.dueDate && 
                          <Text className={!iv.isPaid && new Date().toISOString().substring(0,10) > iv.dueDate && 'quadrat'}>Over Due</Text>}                                
                        </Td>
                        <Td isNumeric>{iv.shipmentList.length}</Td>
                        <Td>{iv.isPaid ? < MdCheckCircle size={20} color='green' /> : (!iv.isPaid && iv.payments.length !== 0) ? <MdIncompleteCircle size={20} color='orange' /> : < MdCancel size={20} color='red' />}</Td>
                        <Td>
                          <Link to={`/myinvoices/${iv._id}`}>
                            <Button size='sm' colorScheme={'green'} >Details</Button>
                          </Link>
                        </Td>
                    </Tr>
                    ))}
                </Tbody>
              </Table>
            :
            <Table size='sm' variant='striped' colorScheme='purple' my='4'>
              <Thead>
                  <Tr>
                  <Th>No</Th>
                  <Th>Summary</Th>
                  <Th>Detail</Th>
                  </Tr>
              </Thead>
              <Tbody>
                  {invoices && invoices.map((iv, idx)=>(
                  <Tr key={idx}>
                      <Td>{pageNumber === 1 ? (idx+1) : (((Number(pageNumber)-1) * itemCount) + (idx+1))}</Td>
                      <Td>
                        <Stack spacing={0}>
                          <Box>Code - {iv.code}</Box>
                          {user && user.accountType === 'sale' && <Box>Agent - {iv.agent.name}</Box>}
                          <Box>Total Amount - {iv.totalAmount.toLocaleString()}</Box>
                          <Box>Inoice Balance - {(iv.totalAmount - computeBalance(iv.payments)).toLocaleString()}</Box>
                          <Box>Created Date - {iv.createdAt.substring(0,10)}</Box>
                          <Flex>
                            <Box>Due Date - {iv.dueDate.substring(0,10)}</Box>
                            <Box>{!iv.isPaid && new Date().toISOString().substring(0,10) > iv.dueDate && 
                            <Text className={!iv.isPaid && new Date().toISOString().substring(0,10) > iv.dueDate && 'quadrat'}>Over Due</Text>}</Box>
                          </Flex>
                          <Box>Shipment Qty - {iv.shipmentList.length}</Box>
                          <Flex>Payment - {iv.isPaid ? < MdCheckCircle size={20} color='green' />: (!iv.isPaid && iv.payments.length !== 0) ? <MdIncompleteCircle size={20} color='orange' /> : < MdCancel size={20} color='red' />}</Flex>
                        </Stack>
                      </Td>
                      <Td>
                        <Link to={`/myinvoices/${iv._id}`}>
                          Details
                        </Link>
                      </Td>
                  </Tr>
                  ))}
              </Tbody>
            </Table>     
            }

        </Box>
    )
}

export default AgentInvoiceListScreen