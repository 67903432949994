import { 
    DHLACCOUNT_CREATE_FAIL,
    DHLACCOUNT_CREATE_REQUEST,
    DHLACCOUNT_CREATE_RESET,
    DHLACCOUNT_CREATE_SUCCESS,
    DHLACCOUNT_DELETE_FAIL,
    DHLACCOUNT_DELETE_REQUEST,
    DHLACCOUNT_DELETE_RESET,
    DHLACCOUNT_DELETE_SUCCESS,
    DHLACCOUNT_LIST_FAIL,
    DHLACCOUNT_LIST_REQUEST, 
    DHLACCOUNT_LIST_RESET, 
    DHLACCOUNT_LIST_SUCCESS,
    DHLACCOUNT_UPDATE_FAIL,
    DHLACCOUNT_UPDATE_REQUEST,
    DHLACCOUNT_UPDATE_RESET,
    DHLACCOUNT_UPDATE_SUCCESS,

} from "../constants/dhlAccountConstants"





export const dhlAccountListReducer = (state={accounts:[]}, action) => {
    switch(action.type){
        case DHLACCOUNT_LIST_REQUEST:
            return {loading: true}
        case DHLACCOUNT_LIST_SUCCESS:
            return {loading: false, accounts: action.payload}
        case DHLACCOUNT_LIST_FAIL:
            return {loading: false, error: action.payload}
        case DHLACCOUNT_LIST_RESET:
            return {}
        default: return state
    }
}

export const dhlAccountCreateReducer = (state={}, action) => {
    switch(action.type){
        case DHLACCOUNT_CREATE_REQUEST:
            return {loading: true}
        case DHLACCOUNT_CREATE_SUCCESS:
            return {loading: false, success: true}
        case DHLACCOUNT_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case DHLACCOUNT_CREATE_RESET:
            return {}
        default: return state
    }
}

export const dhlAccountUpdateReducer = (state={}, action) => {
    switch(action.type){
        case DHLACCOUNT_UPDATE_REQUEST:
            return {loading: true}
        case DHLACCOUNT_UPDATE_SUCCESS:
            return {loading: false, success: true}
        case DHLACCOUNT_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case DHLACCOUNT_UPDATE_RESET:
            return {}
        default: return state
    }
}

export const dhlAccountDeleteReducer = (state={}, action) => {
    switch(action.type){
        case DHLACCOUNT_DELETE_REQUEST:
            return {loading: true}
        case DHLACCOUNT_DELETE_SUCCESS:
            return {loading: false, success: true}
        case DHLACCOUNT_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case DHLACCOUNT_DELETE_RESET:
            return {}
        default: return state
    }
}