import axios from 'axios'
import {
    THPSHIPMENT_BYID_FAIL,
    THPSHIPMENT_BYID_REQUEST,
    THPSHIPMENT_BYID_SUCCESS,
    THPSHIPMENT_CHARGES_FAIL,
    THPSHIPMENT_CHARGES_REQUEST,
    THPSHIPMENT_CHARGES_SUCCESS,
    THPSHIPMENT_CREATE_FAIL,
    THPSHIPMENT_CREATE_REQUEST, 
    THPSHIPMENT_CREATE_SUCCESS,
    THPSHIPMENT_DELETE_FAIL,
    THPSHIPMENT_DELETE_REQUEST,
    THPSHIPMENT_DELETE_SUCCESS,
    THPSHIPMENT_UPDATE_FAIL,
    THPSHIPMENT_UPDATE_REQUEST,
    THPSHIPMENT_UPDATE_SUCCESS,
    THPSHIPMENT_GETCOST_FAIL,
    THPSHIPMENT_GETCOST_REQUEST,
    THPSHIPMENT_GETCOST_SUCCESS,
    THPSHIPMENT_LIST_FAIL,
    THPSHIPMENT_LIST_REQUEST,
    THPSHIPMENT_LIST_SUCCESS,
    THPSHIPMENT_MYLIST_FAIL,
    THPSHIPMENT_MYLIST_REQUEST,
    THPSHIPMENT_MYLIST_SUCCESS,
    THPSHIPMENT_PRESHIPCONTACT_FAIL,
    THPSHIPMENT_PRESHIPCONTACT_REQUEST,
    THPSHIPMENT_PRESHIPCONTACT_SUCCESS,
    THPSHIPMENT_UPDATECOST_FAIL,
    THPSHIPMENT_UPDATECOST_REQUEST,
    THPSHIPMENT_UPDATECOST_SUCCESS,
    THPSHIPMENT_SCANNED_REQUEST,
    THPSHIPMENT_SCANNED_SUCCESS,
    THPSHIPMENT_SCANNED_FAIL,
    THPSHIPMENT_GET_SCANNED_REQUEST,
    THPSHIPMENT_GET_SCANNED_SUCCESS,
    THPSHIPMENT_GET_SCANNED_FAIL,
    THPSHIPMENT_SHIPANDSCAN_REQUEST,
    THPSHIPMENT_SHIPANDSCAN_SUCCESS,
    THPSHIPMENT_SHIPANDSCAN_FAIL,
} from '../../constants/thailand_post_constants/thpShipmentConstants'

export const createTHPShipment = (postShipmentData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/thp/shipments`, postShipmentData, config)
        dispatch({type: THPSHIPMENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listShipments = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/shipments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: THPSHIPMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const filterShipments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/thp/shipments/filteredshipments`,{filterData}, config)
        dispatch({type: THPSHIPMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listMyShipments = (pageNumber = '', itemCount = '', keyword = '', date='') => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_MYLIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/shipments/myshipments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}&date=${date}`, config)
        dispatch({type: THPSHIPMENT_MYLIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_MYLIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myFilteredShipments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_MYLIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/thp/shipments/myfilteredshipments`,{filterData}, config)
        dispatch({type: THPSHIPMENT_MYLIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_MYLIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getTHPShipment = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/shipments/${id}`, config)
        dispatch({type: THPSHIPMENT_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const deleteTHPShipment = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.delete(`/api/thp/shipments/${id}`, config)
        dispatch({type: THPSHIPMENT_DELETE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateTHPShipment = (newData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_UPDATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.put(`/api/thp/shipments/${newData.shipmentId}`, newData, config)
        dispatch({type: THPSHIPMENT_UPDATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getCostTHPShipment = (shipData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_GETCOST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/thp/rates/getcost`, {shipData}, config)
        dispatch({type: THPSHIPMENT_GETCOST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_GETCOST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const saveContactTHPShipments = (contactData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_PRESHIPCONTACT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/thp/shipments/contact`,{contactData}, config)
        dispatch({type: THPSHIPMENT_PRESHIPCONTACT_SUCCESS})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_PRESHIPCONTACT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateChargesTHPShipment = (chargeData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_CHARGES_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/thp/shipments/charge`, {chargeData}, config)
        dispatch({type: THPSHIPMENT_CHARGES_SUCCESS})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_CHARGES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateCostTHPShipment = (cost, shipmentId) => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_UPDATECOST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/thp/shipments/cost`, {cost, shipmentId}, config)
        dispatch({type: THPSHIPMENT_UPDATECOST_SUCCESS})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_UPDATECOST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const scannedShipment = (awbpreid) => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_SCANNED_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/thp/shipments/scanned`, {awbpreid}, config)
        dispatch({type: THPSHIPMENT_SCANNED_SUCCESS})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_SCANNED_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getSannedShipment = (date='') => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_GET_SCANNED_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/shipments/scanned?date=${date}`, config)
        dispatch({type: THPSHIPMENT_GET_SCANNED_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_GET_SCANNED_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const shipScanList = () => async (dispatch, getState) => {
    try {
        dispatch({type: THPSHIPMENT_SHIPANDSCAN_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/shipments/shipandscan`, config)
        dispatch({type: THPSHIPMENT_SHIPANDSCAN_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPSHIPMENT_SHIPANDSCAN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}