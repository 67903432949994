import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Stack,
    HStack,
    Spacer,
    Input,
    Button,
    Progress
} from '@chakra-ui/react'
import { agentBilling } from '../actions/shipmentActions'
import Error from '../components/Error'
import { MdOutlineCompareArrows, MdOutlineCode } from 'react-icons/md'

const AgentBillingScreen = () => {

    async function addDays(date, days){
        var result = new Date(date)
        result.setDate(result.getDate()+days)
        var dateString = result.toISOString()
        return dateString.substring(0,10)
    }
    const [ shipperSmallView, setShipperSmallView ] = useState(true)
    const dispatch = useDispatch()
    const [month, setMonth] = useState(new Date().toISOString().substring(0,7))
    const { loading, error, billingDetail } = useSelector(state=>state.shipment.AgentBilling)     

    useEffect(()=>{        
        // const start = '2022-06-19'
        // const end = new Date().toISOString().substring(0,10)
        dispatch(agentBilling('', ''))
    },[dispatch])
    

    const handleSubmit = async () => {
        const start = month+'-01'        
        const y = Number(month.substring(0,4))
        const m = Number(month.substring(5,7))
        const e = new Date(y, m, 1).toISOString().substring(0,10)
        const end = await addDays(e, 1)
        dispatch(agentBilling(start, end))
        //console.log(start, end)
    }
    
    return (
        <Box mx='2%' mb='50px'>
            {/* {loading && <Center><Spinner label='Please wait...' speed='0.1s' thickness='5px' /></Center>} */}
            {loading && <Progress size='xs' isIndeterminate />}
            {error && <Error error={error} />}
            {billingDetail && 
                <Box mb='20px' mt='3'>
                    <HStack>                        
                        <Stack>
                            <Box fontSize={'4xl'}>Agent Billing Summary</Box>
                            <HStack>
                                <Input 
                                    type="month"
                                    size='sm'
                                    w='200px'
                                    value={month}
                                    onChange={e=>setMonth(e.target.value)}
                                />
                                <Button
                                    size='sm'
                                    colorScheme={'green'}
                                    onClick={handleSubmit}
                                >Go</Button>
                            </HStack>                            
                        </Stack>
                        <Spacer />
                        <Stack fontWeight={'500'} spacing={'0'} p='2' border='1px' shadow={'dark-lg'} borderRadius='2xl' >
                            <HStack>
                                <Box>Total : </Box><Spacer />
                                <Box>{(billingDetail.reduce((acc, b) => acc + b.total, 0)).toLocaleString()}</Box>
                            </HStack>
                            <HStack>
                                <Box>Invoiced : </Box><Spacer />
                                <Box>{(billingDetail.reduce((acc, b) => acc + b.totalInvoiced, 0)).toLocaleString()}</Box>
                            </HStack>
                            <HStack>
                                <Box>Paid : </Box><Spacer />
                                <Box>{(billingDetail.reduce((acc, b) => acc + b.totalPayment, 0)).toLocaleString()}</Box>
                            </HStack>   
                            <HStack>
                                <Box color='red'>UnInvoiced : </Box><Spacer />
                                <Box color='red'>{((billingDetail.reduce((acc, b) => acc + b.total, 0)) - (billingDetail.reduce((acc, b) => acc + b.totalInvoiced, 0))).toLocaleString()}</Box>
                            </HStack>
                            <HStack>
                                <Box color='red'>UnPaid : </Box><Spacer />
                                <Box color='red'>{((billingDetail.reduce((acc, b) => acc + b.total, 0)) - (billingDetail.reduce((acc, b) => acc + b.totalPayment, 0))).toLocaleString()}</Box>
                            </HStack>
                        </Stack>
                    </HStack>                
                </Box>
            }
            
            {billingDetail && 
                    <Box>
                        <TableContainer>
                            <Table variant='simple' size='sm' style={{fontSize: '13px'}}>
                                <Thead>
                                <Tr>
                                    <Th>No</Th>
                                    <Th>Agent</Th>
                                    <Th>Sale</Th>
                                    <Th>
                                        <HStack>
                                        {shipperSmallView && <Box>Shipper</Box>}
                                        <Box><Button
                                            size={'xs'}
                                            colorScheme='blue'
                                            onClick={()=>setShipperSmallView(prev=>!prev)}
                                        >{shipperSmallView ? <MdOutlineCompareArrows size={23} /> : <MdOutlineCode size={23} />}</Button></Box>
                                        </HStack>
                                    </Th>
                                    <Th isNumeric>Total</Th>
                                    <Th isNumeric>Invoiced</Th>
                                    <Th isNumeric>Paid</Th>
                                    <Th isNumeric>Approved</Th>
                                    <Th isNumeric color='red'>Uninvoiced</Th>
                                    <Th isNumeric color='blue'>Balance</Th>
                                    
                                </Tr>
                                </Thead>
                                <Tbody>
                                    {billingDetail.map((b,i)=>(
                                        <Tr key={i} >
                                            <Td>{i+1}</Td>
                                            <Td>{b.agent.name}</Td>
                                            <Td>{b.agent.sale.name}</Td>  
                                            <Td>{shipperSmallView && (b.agent.sale.addressId.hasOwnProperty("name") ? b.agent.sale.addressId.name : '-')}</Td>                                          
                                            <Td isNumeric>{b.total.toLocaleString()}</Td>
                                            <Td isNumeric>{b.totalInvoiced.toLocaleString()}</Td>
                                            <Td isNumeric>{b.totalPayment.toLocaleString()}</Td>
                                            <Td isNumeric>{b.totalApprovedPayment.toLocaleString()}</Td>
                                            <Td isNumeric color='red'>{(b.total-b.totalInvoiced).toLocaleString()}</Td>
                                            <Td isNumeric color='blue'>{(b.total-b.totalPayment).toLocaleString()}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
            }
        </Box>
    )
}


//preshipment.boxes.reduce((acc, box) => acc + box.weight, 0)
export default AgentBillingScreen