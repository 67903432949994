import React, {useRef} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
    Box,
    Divider,
    HStack,
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Spacer,
  } from '@chakra-ui/react'
import { MdBuild, MdDashboard, MdLocalShipping, MdFlightTakeoff, MdAssignment, MdMonetizationOn, MdPeople, MdRequestQuote, MdOutlineNoteAdd, MdPrint, MdLocationPin, MdSell, MdLocalPostOffice } from 'react-icons/md'




const AdminPanel = () => {
    const {user} = useSelector(state=>state.login)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()
    return (
        <>
        <Button disabled={user && !user.isAdmin} size='sm' ref={btnRef} colorScheme='White' onClick={onOpen}>
            Admin Panel
        </Button>
        <Drawer
            isOpen={isOpen}
            placement='left'
            onClose={onClose}
            finalFocusRef={btnRef}
        >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Admin Control Panel</DrawerHeader>

            <DrawerBody>
                {/* <HStack my='3' ps='4' fontWeight='800' >
                    <MdDashboard size={25} />
                    <Link to='/admindashboard' onClick={onClose}>Dashboard</Link>
                </HStack>
                <Divider /> */}

                <Accordion allowToggle>
                    <AccordionItem>                        
                        <AccordionButton>
                            <HStack my='1' fontWeight='800' >
                                <MdDashboard size={25} />
                                <Box>Admin Data</Box>
                                <Spacer />
                                <AccordionIcon />
                            </HStack>
                            
                        </AccordionButton>
                        <AccordionPanel>
                            <Box ps='40px'>
                                <Box mb='2'>
                                    <Button variant='link'>
                                        <Link to='/admindata' onClick={onClose} >Data List</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/admindashboard' onClick={onClose}>Dashboard</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/accounting/sale' onClick={onClose}>Accounting Sale</Link>
                                    </Button>
                                </Box>
                                <Box>                                    
                                    <Button variant='link'>
                                        <Link to='/shippingchart' onClick={onClose}>Shipping Chart</Link>
                                    </Button>
                                </Box>
                            </Box>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <Accordion allowToggle>
                    <AccordionItem>                        
                        <AccordionButton>
                            <HStack my='1' fontWeight='800' >
                                <MdFlightTakeoff size={25} />
                                <Box>DHL Shipments</Box>
                                <Spacer />
                                <AccordionIcon />
                            </HStack>
                            
                        </AccordionButton>
                        <AccordionPanel>
                            <Box ps='40px'>
                                <Box mb='2'>
                                    <Button variant='link'>
                                        <Link to='/shipment' onClick={onClose} >Shipments List</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/usershipmentcount' onClick={onClose}>User Shipment Count</Link>
                                    </Button>
                                </Box>
                                <Box>                                    
                                    <Button variant='link'>
                                        <Link to='/deletedshipments' onClick={onClose}>Deleted Shipments</Link>
                                    </Button>
                                </Box>
                            </Box>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <Accordion allowToggle>
                    <AccordionItem>                        
                        <AccordionButton>
                            <HStack my='1' fontWeight='800' >
                                <MdLocalPostOffice size={25} />
                                <Box>Thailand Post</Box>
                                <Spacer />
                                <AccordionIcon />
                            </HStack>
                            
                        </AccordionButton>
                        <AccordionPanel>
                            <Box ps='40px'>
                                <Box mb='2'>
                                    <Button variant='link'>
                                        <Link to='/thailandpost' onClick={onClose} >Shipments</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/thpinvoices' onClick={onClose}>Invoices</Link>
                                    </Button>
                                </Box>
                                <Box  mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/thppayments' onClick={onClose}>Payments</Link>
                                    </Button>
                                </Box>                                
                                <Box>                                    
                                    <Button variant='link'>
                                        <Link to='/thailandpostrate' onClick={onClose}>Rates</Link>
                                    </Button>
                                </Box>
                            </Box>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <Accordion allowToggle>
                    <AccordionItem>                        
                        <AccordionButton>
                            <HStack my='1' fontWeight='800' >
                                <MdLocalShipping size={25} />
                                <Box>Domestic</Box>
                                <Spacer />
                                <AccordionIcon />
                            </HStack>
                            
                        </AccordionButton>
                        <AccordionPanel>
                            <Box ps='40px'>
                                <Box mb='2'>
                                    <Button variant='link'>
                                        <Link to='/domesticshipment' onClick={onClose} >Shipments</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/domesticinvoice' onClick={onClose}>Invoices</Link>
                                    </Button>
                                </Box>
                                <Box  mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/domesticpayment' onClick={onClose}>Payments</Link>
                                    </Button>
                                </Box>                                
                                <Box>                                    
                                    <Button variant='link'>
                                        <Link to='/domesticrate' onClick={onClose}>Rates</Link>
                                    </Button>
                                </Box>
                            </Box>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

                <HStack my='3' ps='4' fontWeight='800' >
                    <MdAssignment size={25} />
                    <Link to='/invoice' onClick={onClose}>Invoices</Link>
                </HStack>
                <Divider />

                <Accordion allowToggle>
                    <AccordionItem>
                        <AccordionButton>
                            <HStack my='1' fontWeight='800' >
                                <MdMonetizationOn size={25} />
                                <Box>Payments</Box>
                                <Spacer />
                                <AccordionIcon />
                            </HStack>
                            
                        </AccordionButton>
                        <AccordionPanel>
                            <Box ps='40px'>
                                <Box mb='2'>
                                    <Button variant='link'>
                                        <Link to='/payment' onClick={onClose}>Payments List</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/salebilling' onClick={onClose}>Sale Billing</Link>
                                    </Button>
                                </Box>
                                <Box>                                    
                                    <Button variant='link'>
                                        <Link to='/agentbilling' onClick={onClose}>Agent Billing</Link>
                                    </Button>
                                </Box>
                            </Box>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                
                <HStack my='3' ps='4' fontWeight='800' >
                    <MdPeople size={25} />
                    <Link to='/user' onClick={onClose}>Users</Link>
                </HStack>
                <Divider />

                {/* <HStack my='3' ps='4' fontWeight='800' >
                    <MdRequestQuote size={25} />
                    <Link to='/rate' onClick={onClose}>Rates</Link>
                </HStack>
                <Divider /> */}

                <Accordion allowToggle>
                    <AccordionItem>                        
                        <AccordionButton>
                            <HStack my='1' fontWeight='800' >
                                <MdRequestQuote size={25} />
                                <Box>Rate</Box>
                                <Spacer />
                                <AccordionIcon />
                            </HStack>
                            
                        </AccordionButton>
                        <AccordionPanel>
                            <Box ps='40px'>
                                <Box mb='2'>
                                    <Button variant='link'>
                                        <Link to='/rate' onClick={onClose} >Rate List</Link>
                                    </Button>
                                </Box>
                                <Box>                                    
                                    <Button variant='link'>
                                        <Link to='/transferrate' onClick={onClose}>Transfer</Link>
                                    </Button>
                                </Box>
                            </Box>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>


                {/* <Accordion allowToggle>
                    <AccordionItem>                        
                        <AccordionButton>
                            <HStack my='1' fontWeight='800' >
                                <MdOutlineNoteAdd size={25} />
                                <Box>Agent Rates</Box>
                                <Spacer />
                                <AccordionIcon />
                            </HStack>
                            
                        </AccordionButton>
                        <AccordionPanel>
                            <Box ps='40px'>
                                <Box mb='2'>
                                    <Button variant='link'>
                                        <Link to='/agentrate' onClick={onClose} >Agent Rate List</Link>
                                    </Button>
                                </Box>
                                <Box>                                    
                                    <Button variant='link'>
                                        <Link to='/agratetransfer' onClick={onClose}>Transfer</Link>
                                    </Button>
                                </Box>
                            </Box>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
                <Divider /> */}

                <HStack my='3' ps='4' fontWeight='800' >
                    <MdOutlineNoteAdd size={25} />
                    <Link to='/agentrate' onClick={onClose}>Agent Rates</Link>
                </HStack>
                <Divider />

                <HStack my='3' ps='4' fontWeight='800' >
                    <MdPrint size={25} />
                    <Link to='/printrate' onClick={onClose}>Print Rates</Link>
                </HStack>
                <Divider />

                <HStack my='3' ps='4' fontWeight='800' >
                    <MdLocationPin size={25} />
                    <Link to='/address' onClick={onClose}>Address</Link>
                </HStack>
                <Divider />

                <HStack my='3' ps='4' fontWeight='800' >
                    <MdSell size={25} />
                    <Link to='/item' onClick={onClose}>Items</Link>
                </HStack>
                <Divider />

                <Accordion allowToggle>
                    <AccordionItem>                        
                        <AccordionButton>
                            <HStack my='1' fontWeight='800' >
                                <MdBuild size={25} />
                                <Box>Others</Box>
                                <Spacer />
                                <AccordionIcon />
                            </HStack>
                            
                        </AccordionButton>
                        <AccordionPanel>
                            <Box ps='40px'>
                                <Box mb='2'>
                                    <Button variant='link'>
                                        <Link to='/data' onClick={onClose} >Pull Data</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/dhlaccount' onClick={onClose}>DHL accounts</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/charges' onClick={onClose}>Charges List</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/country' onClick={onClose}>Country List</Link>
                                    </Button>
                                </Box>
                                <Box mb='2'>                                    
                                    <Button variant='link'>
                                        <Link to='/zone' onClick={onClose}>Zones</Link>
                                    </Button>
                                </Box>
                                <Box mb={2}>                                    
                                    <Button variant='link'>
                                        <Link to='/cleardata' onClick={onClose}>Data</Link>
                                    </Button>
                                </Box>
                                <Box>                                    
                                    <Button variant='link'>
                                        <Link to='/balancelog' onClick={onClose}>Balance Log</Link>
                                    </Button>
                                </Box>
                            </Box>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>

            </DrawerBody>

            
            </DrawerContent>
        </Drawer>
        </>
    )
}

export default AdminPanel