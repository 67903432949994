import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Box,
    Spinner,
    Stack,
    RadioGroup,
    Radio,
} from '@chakra-ui/react'
import { updateUserRate } from '../actions/rateActions'

const AgentRateUpdateModal = ({user}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [newRateId, setNewRateId] = useState('')
    const { ratenames} = useSelector(state=>state.rate.Names)
    const { loading, success} = useSelector(state=>state.rate.User)

    useEffect(()=>{
        if(success){
            setNewRateId('')
            setShow(false)
        }
    },[success, dispatch])

    const handleClose = () => {
        setShow(false)
    }

    const handleSubmit = () => {
        //console.log(user._id, newRateId)
        dispatch(updateUserRate(user._id, newRateId))
        setNewRateId('')
        setShow(false)
    }


    return (
        <>
            <Button size='sm'  colorScheme={'blue'} onClick={()=>setShow(true)} >Edit</Button>

            <Modal isOpen={show} onClose={handleClose} size='lg' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                   Update Rate for {user.name}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>

                    <RadioGroup defaultValue={user.rate._id} onChange={setNewRateId}>
                        <Stack spacing={4} direction='column'>
                            {ratenames && ratenames.length !== 0 && ratenames.map(r=>(
                                <Radio size='lg' key={r._id} value={r._id}>{r.name}</Radio>
                            ))}
                        </Stack>
                    </RadioGroup>
                                            
                </ModalBody>

                <ModalFooter>
                    <Button size='sm' w='150px' colorScheme={'green'} onClick={handleSubmit} >Save</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default AgentRateUpdateModal