import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { MySpinner, Error } from '../../components'
import { getAccountingSaleData } from '../../actions/accounting_actions/accountingActions'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { 
    FormControl, 
    FormLabel, 
    Switch, 
    Box, 
    TableContainer, 
    Table, Th, Tr, Td, Tbody, Thead, 
    HStack, 
    Spacer, 
    Button,
    Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow, PopoverCloseButton,
    Menu, MenuButton, MenuItem, MenuList
 } from '@chakra-ui/react'

const SalesAccountingScreen = () => {
    const dispatch = useDispatch()
    const [ term, setTerm ] = useState(2)
    const [shipperView, setShipperView] = useState(false)
    const { loading, error, dataList } = useSelector(state=>state.accounting.accountingSale)

    useEffect(()=>{
        if(!dataList || dataList.length === 0){
            dispatch(getAccountingSaleData(3))
        }
        // eslint-disable-next-line
    },[dispatch])
    
    return (
        <Box my={5}>
            {error && <Error error={error} />}
            {loading && <MySpinner />}
            <HStack>
                <Box></Box>
                <Spacer />
                <Box>
                    <Menu>
                        <MenuButton size='sm' as={Button} rightIcon={<MdKeyboardArrowDown />}>
                            Month Count
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={()=>dispatch(getAccountingSaleData(3))}>3</MenuItem>
                            <MenuItem onClick={()=>dispatch(getAccountingSaleData(4))}>4</MenuItem>
                            <MenuItem onClick={()=>dispatch(getAccountingSaleData(5))}>5</MenuItem>
                            <MenuItem onClick={()=>dispatch(getAccountingSaleData(6))}>6</MenuItem>
                            <MenuItem onClick={()=>dispatch(getAccountingSaleData(7))}>7</MenuItem>
                            <MenuItem onClick={()=>dispatch(getAccountingSaleData(8))}>8</MenuItem>
                            <MenuItem onClick={()=>dispatch(getAccountingSaleData(9))}>9</MenuItem>
                            <MenuItem onClick={()=>dispatch(getAccountingSaleData(10))}>10</MenuItem>
                            <MenuItem onClick={()=>dispatch(getAccountingSaleData(11))}>11</MenuItem>
                            <MenuItem onClick={()=>dispatch(getAccountingSaleData(12))}>12</MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
                <Box>
                    <Menu>
                        <MenuButton size='sm' as={Button} rightIcon={<MdKeyboardArrowDown />}>
                            Credit Terms
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={()=>setTerm(2)}>2 Months</MenuItem>
                            <MenuItem onClick={()=>setTerm(3)}>3 Months</MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
                <Box>
                    <FormControl display='flex' alignItems='center'>
                        <FormLabel htmlFor='shipper-view' mb='0'>
                            Shipper View
                        </FormLabel>
                        <Switch id='shipper-view' onChange={()=>setShipperView(prev=>!prev)} />
                    </FormControl>
                </Box>
            </HStack>
            {dataList && 
                <Box my={4}>

                    <TableContainer>
                        <Table size='sm' colorScheme>
                            <Thead>
                            <Tr>
                                <Th>NO</Th>
                                <Th>SALE</Th>
                                <Th>DETAIL</Th>
                            </Tr>
                            </Thead>
                            <Tbody>
                                {shipperView ? dataList.shipperData.map((dtl,i)=>(
                                    <Tr key={i}>
                                        <Td>{i+1}</Td>
                                        <Td>
                                            <Box my={1}>{dtl.sale.name}</Box>
                                            {/* <Box my={1}>{dtl.sale.saleCode}</Box>
                                            <Box my={1}>{dtl.sale.addressId && dtl.sale.addressId.hasOwnProperty('name') && dtl.sale.addressId.name}</Box> */}
                                            <Box my={1}>
                                                <Link to={'/accounting/sale/detail'} state={{shipperView, dtl}}>
                                                    <Button
                                                        size='xs'
                                                        colorScheme='green'
                                                        mr={3}
                                                    >
                                                        View Detail
                                                    </Button>
                                                </Link>                                            
                                            
                                                {shipperView &&
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Button size='xs' colorScheme='blue'>{dtl.saleList.length} Sales</Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverArrow />
                                                        <PopoverCloseButton />
                                                        <PopoverBody>
                                                            {dtl.saleList.map((sale,index)=>(
                                                                <Box key={index}>{index+1}.{sale.name}</Box>
                                                            ))}
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                                }
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Table size='sm' variant='striped' colorScheme='telegram'>
                                                <Thead>
                                                <Tr>
                                                    <Th>No</Th>
                                                    <Th>Month</Th>
                                                    <Th isNumeric>Amount</Th>
                                                    <Th isNumeric>Payment</Th>
                                                    <Th isNumeric>Different (term {term} months)</Th>
                                                </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {dtl.detailTotal.map((dt,idx)=>(
                                                        <Tr key={idx}>
                                                            <Td>{idx+1}</Td>
                                                            <Td>{dt.timeline.start.substring(0,7)}</Td>
                                                            <Td isNumeric>{dt.total.toLocaleString()}</Td>
                                                            <Td isNumeric>{dt.totalPayment.toLocaleString()}</Td>
                                                            <Td isNumeric color={((idx-term) < 0 ? 0 : (dt.total-dtl.detailTotal[idx-term].totalPayment)) <= 0 ? 'green' : 'red'}>
                                                                <HStack textAlign={'end'}>
                                                                    <Box />
                                                                    <Spacer />
                                                                    <Box>{dt.total} - {(idx-term) < 0 ? 0 : dtl.detailTotal[idx-term].totalPayment}</Box>
                                                                    <Box> = </Box>
                                                                    <Box style={{width: '100px'}}>{(idx-term) < 0 ? 0 : (dt.total-dtl.detailTotal[idx-term].totalPayment).toLocaleString()}</Box>
                                                                </HStack>
                                                            </Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </Td>
                                    </Tr>
                                ))
                                :
                                dataList.saleData.map((dtl,i)=>(
                                    <Tr key={i}>
                                        <Td>{i+1}</Td>
                                        <Td>
                                            <Box my={1}>{dtl.sale.name}</Box>
                                            <Box my={1}>{dtl.sale.saleCode}</Box>
                                            <Box my={1}>{dtl.sale.addressId && dtl.sale.addressId.hasOwnProperty('name') && dtl.sale.addressId.name}</Box>
                                            <Box my={1}>
                                                <Link to={'/accounting/sale/detail'} state={{shipperView, dtl}}>
                                                    <Button
                                                        size='xs'
                                                        colorScheme='green'
                                                        mr={3}
                                                    >
                                                        View Detail
                                                    </Button>
                                                </Link>
                                                {!shipperView &&
                                                <Popover>
                                                    <PopoverTrigger>
                                                        <Button size='xs' colorScheme='orange'>{dtl.agentList.length} Agents</Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent style={{height: '300px', overflow: 'scroll'}}>
                                                        <PopoverArrow />
                                                        <PopoverCloseButton />
                                                        <PopoverBody>
                                                            {dtl.agentList.map((agent,iag)=>(
                                                                <Box key={iag}>{iag+1}.{agent.name}</Box>
                                                            ))}
                                                        </PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                                }
                                            </Box>
                                        </Td>
                                        <Td>
                                            <Table size='sm' variant='striped' colorScheme='telegram'>
                                                <Thead>
                                                <Tr>
                                                    <Th>No</Th>
                                                    <Th>Month</Th>
                                                    <Th isNumeric>Amount</Th>
                                                    <Th isNumeric>Payment</Th>
                                                    <Th isNumeric>Different (term {term} months)</Th>
                                                </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {dtl.detailTotal.map((dt,idx)=>(
                                                        <Tr key={idx}>
                                                            <Td>{idx+1}</Td>
                                                            <Td>{dt.timeline.start.substring(0,7)}</Td>
                                                            <Td isNumeric>{dt.total.toLocaleString()}</Td>
                                                            <Td isNumeric>{dt.totalPayment.toLocaleString()}</Td>
                                                            <Td isNumeric color={((idx-term) < 0 ? 0 : (dt.total-dtl.detailTotal[idx-term].totalPayment)) <= 0 ? 'green' : 'red'}>
                                                                <HStack textAlign={'end'}>
                                                                    <Box />
                                                                    <Spacer />
                                                                    <Box>{dt.total} - {(idx-term) < 0 ? 0 : dtl.detailTotal[idx-term].totalPayment}</Box>
                                                                    <Box> = </Box>
                                                                    <Box style={{width: '100px'}}>{(idx-term) < 0 ? 0 : (dt.total-dtl.detailTotal[idx-term].totalPayment).toLocaleString()}</Box>
                                                                </HStack>
                                                            </Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            }
        </Box>
    )
}

export default SalesAccountingScreen