import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { MdEmail, MdPhone, MdOpenInNew, MdContacts, MdSettings, MdPersonalInjury } from 'react-icons/md'
import { Tooltip, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts'
import {
  Box,
  Text,
  Spinner,
  Stack,
  HStack,
  Button,
  Tag,
  TagLabel,
  TagLeftIcon,
  Heading,
  Divider,
  Spacer,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Wrap,
  WrapItem,
  CircularProgress,
  CircularProgressLabel,
  Center,
} from '@chakra-ui/react'
import { byIdUser, deleteThpDiscount, deleteUser } from '../actions/userActions'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'
import { USER_DELETE_RESET } from '../constants/userConstants'
import AddbalanceToUserModal from '../Modals/AddBalanceToUserModal'
import Error from '../components/Error'
import AgentRateUpdateModal from '../Modals/AgentRateUpdateModal'
import { namesRate } from '../actions/rateActions'
import PostDiscountAddModal from './ThailandPost/THPRateScreens/PostDiscountAddModal'


const UserDetailScreen = () => {
  const navigate = useNavigate()
  const params = useParams()
  const userId = params.id
  const dispatch = useDispatch()
  const {user} = useSelector(state=>state.login)
  const {loading, error, userDetail} = useSelector( state=> state.user.ById)
  const {loading: deleteLoading, error: deleteError, success: deleteSuccess} = useSelector(state=>state.user.Delete)
  const {success: successAddBalance} = useSelector(state=>state.user.AddBalance)
  const { success: rateSuccess, error: rateError} = useSelector(state=>state.rate.User)
  const {success: successAddThpDiscount} = useSelector(state=>state.user.AddThpDiscount)
  const {success: successDeleteThpDiscount} = useSelector(state=>state.user.DeleteThpDiscount)

  useEffect(()=>{
    if(deleteSuccess){
      navigate('/user')
    }
  },[deleteSuccess, user, navigate ])

  useEffect(()=>{
    dispatch(byIdUser(userId))   
    dispatch(namesRate())
    return()=>{
      dispatch({type: USER_DELETE_RESET})
    } 
  },[dispatch, successAddBalance, successAddThpDiscount, successDeleteThpDiscount, userId, rateSuccess, navigate])

  const computeProgress = (total, value) => {
    if(total === 0){
      return 0
    }else{
      return ((value/total)*100).toFixed()
    }    
  }
  
  return (
    <Box mx='auto' my='50px' w=''>
      <Stack spacing={4}>
        <HStack>
          <Box>{(loading || deleteLoading) && <Spinner label='Please wait' speed='0.1s' thickness='5px' /> }</Box>
          <Spacer />
          <HStack> 
              <Link to={`/user/edit/${userId}`}>
                <Button disabled={user && user.accountType !== 'admin'} size='sm' w='150px' colorScheme='blue' leftIcon={<MdSettings />} >Edit</Button>
              </Link>
              <ConfirmDeleteModal  disable={user && user.accountType !== 'admin'} deleteFunction={deleteUser} id={userId} width='150px' />
          </HStack>     
        </HStack>
        
        {error && <Error error={error} />}
        {deleteError && <Error error={deleteError} />}
        {rateError && <Error error={rateError} />}

        {userDetail  && 
          <>
            <Box bg='yellow.100' p='3' borderRadius={10}>
            <HStack>
              <Heading size='lg' fontWeight={400}>{userDetail.name}</Heading>
              <CircularProgress value={computeProgress(userDetail.shipmentCount, userDetail.shipmentCountPaid)} color='green' size='60px' thickness='10px'>
                <CircularProgressLabel>{computeProgress(userDetail.shipmentCount, userDetail.shipmentCountPaid)}%</CircularProgressLabel>
              </CircularProgress>
              <Spacer />
              <Heading size='lg' fontWeight={400}>{userDetail.accountType}</Heading>          
            </HStack>
            </Box>

            <Box w='100%'>
              <Stack>
                <Tag variant='subtle' colorScheme='cyan'>
                  <TagLeftIcon boxSize='12px' as={MdEmail} />
                  <TagLabel>{userDetail.email}</TagLabel>
                </Tag>
                <Tag variant='subtle' colorScheme='purple'>
                  <TagLeftIcon boxSize='12px' as={MdPhone} />
                  <TagLabel>{userDetail.phone}</TagLabel>
                </Tag>
                {userDetail.accountType === 'sale' &&
                <>
                  <Tag variant='subtle' colorScheme='orange'>
                    <TagLeftIcon boxSize='12px' as={MdContacts} />
                    <TagLabel>{userDetail.saleCode}</TagLabel>
                  </Tag>
                  {userDetail.fixedShipper && <Tag variant='subtle' colorScheme='pink'>
                    <TagLeftIcon boxSize='12px' as={MdPersonalInjury} />
                    <TagLabel>{userDetail.shipper.name}, {userDetail.shipper.company}, {userDetail.shipper.address}, {userDetail.shipper.phone}</TagLabel>
                  </Tag>}
                  </>
                }
              </Stack>
            </Box>

            <Box borderTop='1px'></Box>   

            {(userDetail.accountType === 'sale' || userDetail.accountType === 'agent') &&    
            <Box my={5}>
              <Center fontSize={'lg'} fontWeight={700}>Shipment Chart</Center>
              <LineChart width={1200} height={200} data={userDetail.shippingChart} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                <Line type="monotone" dataKey="shipmentCount" stroke="#8884d8" />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="month" style={{fontsize: '10px'}} />
                <YAxis />
                <Tooltip />
              </LineChart>
            </Box>
            }   
            
            {userDetail.accountType === 'agent' &&
            <Wrap>
              <WrapItem>
                
                <Box w='500px'>
                  <Table variant='simple' size='sm'>
                    <Tbody>
                      <Tr>
                        <Td>Agent Code</Td>
                        <Td>{userDetail.agentCode}</Td>
                      </Tr>
                      
                      <Tr>
                        <Td>Rate</Td>
                        <Td>
                          <HStack>
                            <Text mr='4'>{userDetail.rate.name}</Text>
                            <Link to={`/rate/${userDetail.rate._id}`} target='_blank'>
                              <Button disabled={user && user.accountType !== 'admin'} size='xs' colorScheme={'orange'} rightIcon={<MdOpenInNew />}>View</Button>
                            </Link>
                          </HStack>
                        </Td>
                      </Tr>

                      <Tr>
                        <Td>Next Rate</Td>
                        {userDetail.nextRate &&
                        <Td>
                          <HStack>
                            <Text mr='4'>{userDetail.nextRate?.name ?? ''}</Text>
                            <Link to={`/rate/${userDetail.nextRate?._id ?? ''}`} target='_blank'>
                              <Button disabled={user && user.accountType !== 'admin'} size='xs' colorScheme={'orange'} rightIcon={<MdOpenInNew />}>View</Button>
                            </Link>
                          </HStack>
                        </Td>}
                      </Tr>

                      <Tr>
                        <Td>Sale Person</Td>
                        <Td>
                          <HStack>
                            <Text mr='4'>{userDetail.sale.name}</Text>
                            <Link to={`/user/${userDetail.sale._id}`} target='_blank'>
                              <Button disabled={user && user.accountType !== 'admin'} size='xs' colorScheme={'orange'} rightIcon={<MdOpenInNew />}>View</Button>
                            </Link>
                          </HStack>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Created</Td>
                        <Td>{(userDetail.createdAt).substring(0,10)}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>

              </WrapItem>

              <Spacer />

              <WrapItem>

                <Box w='500px'>
                  <Table variant='simple' size='sm'>
                    <Tbody>
                      <Tr>
                        <Td>Account Balance</Td>
                        <Td>
                          <HStack>
                            <Box mr='20px'>{userDetail.balance}</Box>
                            <AddbalanceToUserModal userId={userId} />
                          </HStack>
                          
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>Total Shipments</Td>
                        <Td>{userDetail.shipmentCount}</Td>
                      </Tr>
                      
                      <Tr>
                        <Td>Total Invoices</Td>
                        <Td>{userDetail.invoiceCount}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>

              </WrapItem>
            </Wrap>
            }
            <Divider />


            {userDetail.accountType === 'sale' &&
                <Wrap>
                  <WrapItem>
                    <Box  mr='50px'>
                      <Table size='sm' variant='striped' colorScheme='gray'>
                        <Thead>
                            <Tr>
                            <Th>No</Th>
                            <Th>Agent Code</Th>
                            <Th>Name</Th>
                            <Th>Rate</Th>
                            <Th>Option</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {userDetail && userDetail.agentList.map((u,index)=>(
                            <Tr key={index}>
                                <Td>{index+1}</Td>
                                <Td><Link to={`/user/${u._id}`} target='_blank'>{u.agentCode}</Link></Td>
                                <Td>{u.name}</Td>
                                <Td><Link to={`/rate/${u.rate._id}`} target='_blank'>{u.rate.name}</Link></Td>
                                <Td>
                                  <AgentRateUpdateModal user={u} />
                                </Td>
                            </Tr>
                            ))}
                        </Tbody>
                      </Table>
                    </Box>
                  </WrapItem>

                  <WrapItem>
                    <Box w='300px' >
                      <Table size='sm' variant='simple' >
                        <Thead>
                            <Tr>
                            <Th>Description</Th>
                            <Th>Value</Th>
                            </Tr>
                        </Thead>
                        <Tbody fontWeight={'500'}>                            
                          <Tr >
                              <Td>Shipment Qty</Td>
                              <Td>{userDetail.shipmentCount}</Td>
                          </Tr>  
                          <Tr color='green'>
                              <Td>Paid Shipment Qty</Td>
                              <Td>{userDetail.shipmentCountPaid}</Td>
                          </Tr>
                          <Tr color='red'>
                              <Td>unPaid Shipment Qty</Td>
                              <Td>{userDetail.shipmentCount - userDetail.shipmentCountPaid}</Td>
                          </Tr> 
                          <Tr >
                              <Td>Invoice Qty</Td>
                              <Td>{userDetail.invoiceCount}</Td>
                          </Tr> 
                          <Tr color='green'>
                              <Td>Paid Invoice Qty</Td>
                              <Td>{userDetail.invoiceCountPaid}</Td>
                          </Tr> 
                          <Tr color='red'>
                              <Td>unPaid Invoice Qty</Td>
                              <Td>{userDetail.invoiceCount - userDetail.invoiceCountPaid}</Td>
                          </Tr>   
                          <Tr >
                              <Td>Payment Qty</Td>
                              <Td>{userDetail.paymentCount}</Td>
                          </Tr> 
                          <Tr color='green'>
                              <Td>Approved Payment Qty</Td>
                              <Td>{userDetail.paymentCountApproved}</Td>
                          </Tr>   
                          <Tr color='red'>
                              <Td>unApproved Payment Qty</Td>
                              <Td>{userDetail.paymentCount - userDetail.paymentCountApproved}</Td>
                          </Tr>                     
                        </Tbody>
                      </Table>
                    </Box>
                  </WrapItem>

                  

                </Wrap>
            }       

            {userDetail.accountType === 'agent' && 
              <Wrap>
                <WrapItem>
                    <Box border='1px' p='2' borderRadius={10}>
                      <HStack>
                        <Text fontSize={{ base: "24px", md: "28px", lg: "32px" }} mb={3}>
                          Thailand Post Discount
                        </Text>
                        <Spacer />
                        <PostDiscountAddModal userId={userId} />
                      </HStack>

                      <Box w='500px'  >
                        <Table size='sm' variant='striped' colorScheme='teal' >
                          <Thead>
                              <Tr>
                                <Th>Country</Th>
                                <Th>Amount</Th>
                                <Th>Options</Th>
                              </Tr>
                          </Thead>
                          <Tbody fontWeight={'500'}> 
                            {userDetail.thpDiscount.map((dis,i)=>(
                              <Tr key={i}>
                                <Td>{dis.countryName}</Td>
                                <Td>{dis.amount}</Td>
                                <Td>
                                  <HStack>
                                    <Button 
                                      variant='link' 
                                      size='sm' 
                                      colorScheme='red'
                                      onClick={()=>dispatch(deleteThpDiscount({userId, countryName: dis.countryName}))}
                                    >Delete</Button>
                                  </HStack>
                                </Td>
                              </Tr>  
                            ))}
                                              
                          </Tbody>
                        </Table>
                      </Box>
                    </Box>
                  </WrapItem>
              </Wrap>
            }
          </>        
        }
      </Stack>

      
    </Box>
  )
}

export default UserDetailScreen