import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getShippigChart } from '../actions/dashboardActions'
import { Error, MySpinner } from '../components'
import { 
  Box,
  Center,
  Spacer,
  Button,
  HStack,
} from '@chakra-ui/react'
import { Tooltip, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts'

const SaleShippingChartScreen = () => {

  const dispatch = useDispatch()

  const { loading, success, error, dataList } = useSelector((state)=>state.system.shippingChart)

  useEffect(()=>{
    dispatch(getShippigChart(''))
  },[dispatch])


  return (
    <Box>
      {loading && <Center><MySpinner /></Center>}
      {error && <Error error={error} />}
      {success && dataList.map((d,i)=>(
        <Box key={i} p={3} m={3} border={'1px'}  style={{width: '1250px'}} borderRadius={5}>
          <HStack fontSize={'lg'} bg={'gray.200'} px='3' py='2'>
            <Box fontWeight={'800'}>{d.user.code} - {d.user.name}</Box>
            <Spacer />
            <Box>
              <Link to={`/shippingchart/${d.user._id}`}>
              <Button 
                size='sm'
                colorScheme='yellow'
              >{d.agentCount} Agents</Button>
              </Link>
            </Box>
            
          </HStack>
          <Box>
            <LineChart width={1200} height={200} data={d.monthData} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
              <Line type="monotone" dataKey="shipmentCount" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
              <XAxis dataKey="month" style={{fontsize: '10px'}} />
              <YAxis />
              <Tooltip />
            </LineChart>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default SaleShippingChartScreen