export const DOMESTICINVOICE_SHIPMENTS_REQUEST = 'DOMESTICINVOICE_SHIPMENTS_REQUEST'
export const DOMESTICINVOICE_SHIPMENTS_SUCCESS = 'DOMESTICINVOICE_SHIPMENTS_SUCCESS'
export const DOMESTICINVOICE_SHIPMENTS_FAIL = 'DOMESTICINVOICE_SHIPMENTS_FAIL'
export const DOMESTICINVOICE_SHIPMENTS_RESET = 'DOMESTICINVOICE_SHIPMENTS_RESET'

export const DOMESTICINVOICE_CREATE_REQUEST = 'DOMESTICINVOICE_CREATE_REQUEST'
export const DOMESTICINVOICE_CREATE_SUCCESS = 'DOMESTICINVOICE_CREATE_SUCCESS'
export const DOMESTICINVOICE_CREATE_FAIL = 'DOMESTICINVOICE_CREATE_FAIL'
export const DOMESTICINVOICE_CREATE_RESET = 'DOMESTICINVOICE_CREATE_RESET'

export const DOMESTICINVOICE_LIST_REQUEST = 'DOMESTICINVOICE_LIST_REQUEST'
export const DOMESTICINVOICE_LIST_SUCCESS = 'DOMESTICINVOICE_LIST_SUCCESS'
export const DOMESTICINVOICE_LIST_FAIL = 'DOMESTICINVOICE_LIST_FAIL'
export const DOMESTICINVOICE_LIST_RESET = 'DOMESTICINVOICE_LIST_RESET'

export const DOMESTICINVOICE_BYID_REQUEST = 'DOMESTICINVOICE_BYID_REQUEST'
export const DOMESTICINVOICE_BYID_SUCCESS = 'DOMESTICINVOICE_BYID_SUCCESS'
export const DOMESTICINVOICE_BYID_FAIL = 'DOMESTICINVOICE_BYID_FAIL'
export const DOMESTICINVOICE_BYID_RESET = 'DOMESTICINVOICE_BYID_RESET'

export const DOMESTICINVOICE_DELETE_REQUEST = 'DOMESTICINVOICE_DELETE_REQUEST'
export const DOMESTICINVOICE_DELETE_SUCCESS = 'DOMESTICINVOICE_DELETE_SUCCESS'
export const DOMESTICINVOICE_DELETE_FAIL = 'DOMESTICINVOICE_DELETE_FAIL'
export const DOMESTICINVOICE_DELETE_RESET = 'DOMESTICINVOICE_DELETE_RESET'

export const DOMESTICINVOICE_ADDSHIPMENTS_REQUEST = 'DOMESTICINVOICE_ADDSHIPMENTS_REQUEST'
export const DOMESTICINVOICE_ADDSHIPMENTS_SUCCESS = 'DOMESTICINVOICE_ADDSHIPMENTS_SUCCESS'
export const DOMESTICINVOICE_ADDSHIPMENTS_FAIL = 'DOMESTICINVOICE_ADDSHIPMENTS_FAIL'
export const DOMESTICINVOICE_ADDSHIPMENTS_RESET = 'DOMESTICINVOICE_ADDSHIPMENTS_RESET'

export const DOMESTICINVOICE_REMOVESHIPMENTS_REQUEST = 'DOMESTICINVOICE_REMOVESHIPMENTS_REQUEST'
export const DOMESTICINVOICE_REMOVESHIPMENTS_SUCCESS = 'DOMESTICINVOICE_REMOVESHIPMENTS_SUCCESS'
export const DOMESTICINVOICE_REMOVESHIPMENTS_FAIL = 'DOMESTICINVOICE_REMOVESHIPMENTS_FAIL'
export const DOMESTICINVOICE_REMOVESHIPMENTS_RESET = 'DOMESTICINVOICE_REMOVESHIPMENTS_RESET'

export const DOMESTICINVOICE_MYLIST_REQUEST = 'DOMESTICINVOICE_MYLIST_REQUEST'
export const DOMESTICINVOICE_MYLIST_SUCCESS = 'DOMESTICINVOICE_MYLIST_SUCCESS'
export const DOMESTICINVOICE_MYLIST_FAIL = 'DOMESTICINVOICE_MYLIST_FAIL'
export const DOMESTICINVOICE_MYLIST_RESET = 'DOMESTICINVOICE_MYLIST_RESET'