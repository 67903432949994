import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Stack,
    ModalFooter,
} from '@chakra-ui/react'
import { useLocation } from 'react-router-dom'
import { SHIPMENT_ADDCHARGE_RESET } from '../constants/shipmentConstants'
import { updateChargesShipment } from '../actions/shipmentActions'
//import { getChargesList } from '../actions/chargesActions'
import { updateChargesTHPShipment } from '../actions/thailand_post_actions/thpShipmentActions'
import MySpinner from '../components/MySpinner'
import Error from '../components/Error'
import { THPSHIPMENT_CHARGES_RESET } from '../constants/thailand_post_constants/thpShipmentConstants'

const AddChargesModal = ({shipmentId, disabled}) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [name, setName] = useState('')
    const [amount, setAmount] = useState(0)
    const [show, setShow] = useState(false)
    const {loading, error, success} = useSelector(state=>state.shipment.AddCharge)
    //const {loading: loadingCharges, charges: chargesList, error: errorCharges } = useSelector(state=>state.charges.List)
    const {loading: thpChargeLoading, error: thpChargeError, success: thpChargeSuccess} = useSelector(state=>state.thailandPost.ShipmentCharges)

    useEffect(()=>{
       
        if(success || thpChargeSuccess){
            setName('')
            setAmount(0)
            setShow(false)
        }
       
        return()=>{
            dispatch({type: SHIPMENT_ADDCHARGE_RESET})
            dispatch({type: THPSHIPMENT_CHARGES_RESET})
        }
    },[dispatch, success, thpChargeSuccess])

    // useEffect(()=>{
    //     if(show){
    //         dispatch(getChargesList())
    //     }
    // },[dispatch, show])
    
    const handleClose = () => {
        setName('')
        setAmount(0)
        setShow(false)
        dispatch({type: SHIPMENT_ADDCHARGE_RESET})
        dispatch({type: THPSHIPMENT_CHARGES_RESET})
    }

    const handleSubmit = () => {
        const shipmentType = location.pathname.split('/')[1]
        const shipmentID = location.pathname.split('/')[2]
        if(name === '' || amount === 0){
            return
        } else {
            const detail = {
                add: true,
                shipmentId: shipmentID,
                name,
                amount,
            }
            if(shipmentType === 'thailandpost' || shipmentType === 'mythpshipments'){
                dispatch(updateChargesTHPShipment(detail))
            } else if (shipmentType === 'shipment' || shipmentType === 'myshipments'){
                dispatch(updateChargesShipment(detail))
            } else {
                return
            }            
        }        
    }

    return (
        <>
            <Button disabled={disabled} size='xs' h='20px' fontSize={'xl'} colorScheme={'orange'} onClick={()=>setShow(true)} >+</Button>

            <Modal isOpen={show} onClose={handleClose} size='lg' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Charge {(loading || thpChargeLoading) && <MySpinner />}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>                  
                    
                    {error && <Error error={error} />}
                    {thpChargeError && <Error error={thpChargeError} />}
                    
                    <Stack spacing={'0'} mb='20px'>
                        <Box>Charge Name</Box>
                        <Input 
                            type={'text'}
                            size='sm'
                            value={name}
                            onChange={e=>setName(e.target.value)}
                        />
                        {/* <Select placeholder='Select' size='sm' onChange={(e)=>setName(e.target.value)} >
                            {chargesList && chargesList.map((c, idx)=>(
                                <option key={idx} value={c.name}>{c.name}</option>
                            ))}
                        </Select> */}
                    </Stack>
                    <Stack spacing={'0'}>
                        <Box>Amount</Box>
                        <Input 
                            type={'number'}
                            size='sm'
                            value={amount}
                            onChange={e=>setAmount(e.target.value)}
                        />
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button 
                        ms='auto' 
                        size='sm' 
                        colorScheme={'green'}
                        onClick={()=>handleSubmit()}
                        >Submit</Button>
                </ModalFooter>

            </ModalContent>
            </Modal>
        </>
    )
}

export default AddChargesModal