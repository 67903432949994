import React, {useRef, useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import {
    Box,
    HStack,
    VStack,
    Spacer,
    Button,
    Stack,
    Text,
    Heading,
    Input,
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Center,
    Checkbox,
} from '@chakra-ui/react'
import { MdLocalPrintshop } from 'react-icons/md'
import { getCostShipment } from '../../actions/shipmentActions'


const BillPrint = ({preshipment}) => {
    const reportRef = useRef()
    const dispatch = useDispatch()
    const [billTo, setBillTo] = useState('')
    const [amount,setAmount] = useState('')
    const [paid, setPaid] = useState(false)
    const {cost} = useSelector(state=>state.shipment.GetCost)

    const reportStyle = `
    @page {
        size: A4;
        margin: 10mm 10mm;
    }
    html, body {
        font-size: 13px;
        font-weight: 400;
    }
    `;

    useEffect(()=>{
        dispatch(getCostShipment({
            agentId: preshipment.agentId._id,
            totalWeight: preshipment.boxes.reduce((acc, box) => acc + box.weight, 0),
            productCode: preshipment.productCode,
            countryCode: preshipment.addressId.countrycode
        }))
        // eslint-disable-next-line
    },[dispatch])

    const billToDetail = (e) => {
        if (e.keyCode === 13){
            this.form.submit();
        }
    }
    

    return (
        <Box>
            <HStack my='3'>
                <Box mr='4'>
                    <Stack>
                        <Box>Bill To</Box>
                        <Input 
                            as='textarea'
                            h='100px'
                            size='sm'
                            w='400px'
                            value={billTo}
                            onChange={e=>setBillTo(e.target.value)}
                            onKeyPress={billToDetail}
                        />

                    </Stack>
                </Box>
                <Stack>                    
                    <Box mb='3'>
                        <Box>Amount</Box>
                        <Input 
                            type='number'
                            size='sm'
                            w='200px'
                            value={amount}
                            onChange={e=>setAmount(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <Checkbox size='lg' onChange={()=>setPaid(!paid)}>Paid</Checkbox>
                    </Box>
                </Stack>
                <Spacer />
                <Box>
                <ReactToPrint
                    trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                    content={() => reportRef.current}
                    pageStyle={()=>reportStyle}
                />
                </Box>
            </HStack>

            
            <Box ref={reportRef}>
                <Stack>
                    <Box>
                        <Heading>Invoice</Heading>
                    </Box>
                    <Box>
                        {/* <Text fontSize={'20px'}>Date - {preshipment && preshipment.createdAt.substring(0,10)}</Text> */}
                        <Text fontSize={'20px'}>{new Date(preshipment.createdAt).toLocaleDateString()}</Text>                     
                    </Box>
                    <Box borderBottom='1px'></Box>


                    <Flex>
                        <Box>
                            <VStack alignItems={'left'} p='3'>
                            <Text fontSize='xl' fontWeight={'700'}>Bill To</Text>
                            <Text h='100px' style={{'whiteSpace': 'pre-line'}}>{billTo}</Text>
                            </VStack>

                        </Box>
                        <Spacer />
                        <Box>
                            <VStack alignItems={'left'} p='3'>
                            <Text fontSize='xl' fontWeight={'700'}>Ship To</Text>
                            <Text>{preshipment.addressId.name}</Text>
                            <Text>{preshipment.addressId.address}</Text>
                            <Text>{preshipment.addressId.city}</Text>
                            <Text>{preshipment.addressId.country + ' ' + preshipment.addressId.postalcode}</Text>
                            <Text>{preshipment.addressId.phone}</Text>
                            </VStack>
                        </Box>
                        <Spacer />
                        <Box>
                            <VStack alignItems={'left'} p='3'>
                                <Text fontSize='xl' fontWeight={'700'}>Detail</Text>
                                <Text >PreID - {preshipment.code}</Text>
                                <Text >DHL Inv# - {preshipment.dhlInvoiceNo}</Text>
                            </VStack>
                        </Box>
                    </Flex>
                    
                    <Table colorScheme={''}>
                        <Thead>
                            <Tr>
                            <Th>No</Th>
                            <Th>Description</Th>
                            <Th>Total Weight</Th>
                            <Th isNumeric>Amount</Th>
                            </Tr>
                        </Thead>
                        <Tbody>                            
                            <Tr pb='100px'>
                                <Td>1</Td>
                                <Td>
                                    {preshipment.boxes.map((box, idx)=>(
                                        <Box key={idx} mb='2'>Box-{idx+1} - {box.dimensions.length} x {box.dimensions.width} x {box.dimensions.height} cm, {box.weight} kg</Box>
                                    ))}
                                </Td>
                                <Td>{preshipment.boxes.reduce((acc, box) => acc + box.weight, 0)} kg</Td>
                                <Td isNumeric>{amount !== '' ? Number(amount).toLocaleString() : Number(cost).toLocaleString()}</Td>
                            </Tr>
                            {preshipment.charges.length !== 0 &&
                                <Tr>
                                    <Td>2</Td>
                                    <Td>{
                                        preshipment.charges.map(ch=>(
                                            <Box key={ch.name}>{ch.name} : {ch.amount} thb</Box>
                                        ))
                                    }</Td>
                                    <Td></Td>
                                    <Td isNumeric>{preshipment.charges.reduce((acc, c)=> acc + c.amount, 0)}</Td>
                                </Tr>
                            }                         
                        </Tbody>
                    </Table>
                </Stack>
                {paid && <Box className='wartermark'>Paid</Box>}
                <HStack my='4'>
                    <Box></Box><Spacer />
                    <Heading pr='3' >Total - {amount !== '' ? Number(amount)+preshipment.charges.reduce((acc, c)=> acc + c.amount, 0) : Number(cost)+preshipment.charges.reduce((acc, c)=> acc + c.amount, 0)}</Heading>
                </HStack>
            
                <Center>
                    <Box mt='300px' as='i'>Summit Asia Cargo</Box>
                </Center>
            </Box>        
        </Box>
    )
}

export default BillPrint