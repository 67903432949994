import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Heading,
    HStack,
    Switch,
    Spacer,
    Spinner,
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
    Stack,
} from '@chakra-ui/react'
import Error from '../components/Error'
import { statusShipments, saleStatusShipments, eachStatusShipments } from '../actions/shipmentActions'
import { statusCodes } from '../data/statusCodes'

import ShipmentStatusAwbList from '../components/ShipmentStatusAwbList'

const ShipmentStatusScreen = () => {
    const dispatch = useDispatch()
    const [viewSale, setViewSale] = useState(false)
    const {loading, error, shipments} = useSelector( state=> state.shipment.Status)
    const {loading: loadingEach, error: errorEach, shipments: shipmentEach} = useSelector( state=> state.shipment.EachShipmentStatus)
    const {loading: loadingLaleStatus, error: errorSaleStatus, shipments: saleShipments} = useSelector( state=> state.shipment.SaleShipmentStatus)

    useEffect(()=>{
        if(viewSale){
            dispatch(saleStatusShipments())
        }else{
            dispatch(statusShipments()) 
        }              
    },[dispatch, viewSale])

    return (
        <Box>
            <HStack my='3'>                    
                <Heading>
                    Shipment Status
                </Heading>  
                <Spacer />                  
                <Box>{(loading || loadingLaleStatus) && <Spinner label='Please wait' speed='0.1s' thickness='5px' /> }</Box>
                <Spacer />
                <Box fontSize={'2xl'}>Sale View</Box>
                <Switch  py='3' colorScheme='teal' size='lg' onChange={()=>{
                    setViewSale(!viewSale)
                    
                    }} />
            </HStack>

            {error && <Error error={error} />}
            {errorSaleStatus && <Error error={errorSaleStatus} />}
            {errorEach && <Error error={errorEach} />}

            {viewSale ? 
                <Table size='sm' variant='striped' colorScheme='messenger' my='4'>
                    <Thead>
                        <Tr>
                        <Th>No</Th>
                        <Th>Sale</Th>
                        <Th>List</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                    {saleShipments && saleShipments.map((s,idx)=>(
                        <Tr key={idx}>
                            <Td>{idx+1}</Td>
                            <Td>{s.name}</Td> 
                            <Td>
                                <Stack spacing={'0'} >
                                {s.awbList.map((st, i)=>(
                                    <Popover key={i} mr={'auto'} size='3xl'>
                                        <PopoverTrigger>                                            
                                            <Box className='txt' onClick={()=>dispatch(eachStatusShipments(s.saleId, st._id.statusCode))}>                                                
                                                {statusCodes[st._id.statusCode]} - {st.total}
                                            </Box> 
                                        </PopoverTrigger>
                                        <PopoverContent w='700px'>
                                            <PopoverArrow />
                                            <PopoverCloseButton />
                                            <PopoverHeader>
                                                {statusCodes[st._id.statusCode]} - {st.total}
                                                {loadingEach && <Spinner label='Please wait' speed='0.1s' thickness='5px' />}
                                            </PopoverHeader>
                                            <PopoverBody>
                                                <Table size='sm' variant='striped' >
                                                    <Thead>
                                                        <Tr>
                                                        <Th>AWB</Th>
                                                        <Th>Agent</Th>
                                                        <Th>Receiver</Th>
                                                        <Th>Ship Date</Th>
                                                        </Tr>
                                                    </Thead>

                                                    <Tbody>
                                                    {shipmentEach && shipmentEach.map((s,idx)=>(
                                                        <Tr key={idx}>
                                                            <Td>{s.awb}</Td>
                                                            <Td>{s.agent.name}</Td>
                                                            <Td>{s.customerDetails.receiverDetails.contactInformation.fullName}-{s.customerDetails.receiverDetails.postalAddress.countryCode}</Td>
                                                            <Td>{s.createdAt.substring(0,10)}</Td>
                                                        </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>  
                                ))}        
                                </Stack>                          
                            </Td>                      
                        </Tr>
                        ))}
                    </Tbody>
                </Table>    
                :
                <Table size='sm' variant='striped' colorScheme='messenger' my='4'>
                    <Thead>
                        <Tr>
                        <Th>No</Th>
                        <Th>Status Code</Th>
                        <Th>Description</Th>
                        <Th>Qty</Th>
                        <Th>View List</Th>
                        </Tr>
                    </Thead>

                    <Tbody>
                    {shipments && shipments.map((s,idx)=>(
                        <Tr key={idx}>
                            <Td>{idx+1}</Td>
                            {/* <Td>
                                <Link to={`/shipment/${s._id}`}>
                                    <Text as='u'>{s.awb}</Text>
                                </Link> 
                            </Td> */}
                            <Td>{s.statusCode}</Td>
                            <Td>{statusCodes[s.statusCode]}</Td>
                            <Td>{s.awbList.length}</Td>
                            <Td><ShipmentStatusAwbList awbList={s.awbList} /></Td>
                            {/* <Td><ShipmentTrackingModal awb={s.awb} color='' variant={'link'} /></Td> */}
                        </Tr>
                        ))}
                    </Tbody>
                </Table>
            }
        </Box>
    )
}

export default ShipmentStatusScreen