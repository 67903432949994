import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'
import {useToast, Divider, HStack, Spacer, Spinner, Stack, FormControl, Input, FormLabel, Button, Text, Box, Icon, InputGroup, InputRightElement, Select} from '@chakra-ui/react'
import { MdSaveAlt, MdReply } from 'react-icons/md'
import { editUser, getSalesNames, byIdUser } from '../actions/userActions'
import { namesRate } from '../actions/rateActions'
import { USER_EDIT_RESET, USER_SALES_NAMES_RESET } from '../constants/userConstants'
import { RATE_NAMES_RESET } from '../constants/rateConstants'
import Error from '../components/Error'
import {ChooseFixedShipperForSaleModal} from '../Modals'


const UserEditScreen = () => {
    const params = useParams()
    const userId = params.id
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [type, setType] = useState('')
    const [rate, setRate] = useState('')
    const [sale, setSale] = useState('')
    const [agentCode, setAgentCode] = useState('')
    const [saleCode, setSaleCode] = useState('')
    const [addressId, setAddressId] = useState('')
    const [shipper, setShipper] = useState(null)
    const [show, setShow] = useState(false)
    const [isError, setIsError] = useState([])

    const {loading, error, success} = useSelector(state=>state.user.Edit)
    const {loading: byIdLoading, error: byIdError, userDetail} = useSelector(state=>state.user.ById)
    const {loading: namesRateLoading, error: namesRateError, ratenames} = useSelector(state=>state.rate.Names)
    const {loading: salesNamesLoading, error: salesNamesError, salesnames} = useSelector(state=>state.user.SalesNames)
    

    useEffect(()=>{
        if(success){
            toast({
                status: 'success',
                position: 'top',
                title: 'User account updated successfully',
                duration: 7000,
                isClosable: true,
            })
            navigate(`/user/${userId}`)
        } 
        if(!userDetail || userDetail._id !== userId){
            dispatch(byIdUser(userId))
        } else {
            setName(userDetail.name)
            setEmail(userDetail.email)
            setPhone(userDetail.phone ? userDetail.phone : '')
            setType(userDetail.accountType)
            if(userDetail.accountType === 'agent'){
                setAgentCode(userDetail.agentCode)
                setRate(userDetail.rate._id)
                setSale(userDetail.sale._id)
                dispatch(namesRate())
                dispatch(getSalesNames())
            }
            if(userDetail.accountType === 'sale'){
                setSaleCode(userDetail.saleCode)
                setAddressId(userDetail.addressId)
                if(userDetail.fixedShipper){
                    setShipper(userDetail.shipper)
                }
            }
        }
        return ()=>{
            dispatch({type: USER_EDIT_RESET})
            dispatch({type: USER_SALES_NAMES_RESET})
            dispatch({type: RATE_NAMES_RESET})
        }
    },[success, toast, userDetail, userId, dispatch, navigate])

    const handleTypeSelect = (acctype) => {
        const index = isError.indexOf('type')
        if(index !== -1){
            setIsError(isError.filter(x => x !== 'type'))
        } else {
            setType(acctype)
        }    
        if(acctype === 'agent'){
            //dispatch rate list, agent rate list and sale list
            dispatch(namesRate())
            dispatch(getSalesNames())
        }
    }
    

    const handleClick = () => setShow(!show)
    const handleSubmit = (e) => {
        e.preventDefault()
        let newUser
        if(type === 'agent'){ 
            newUser = {
                name,
                email,
                type,
                agentCode,
                rate,
                sale,
            }
        } else if(type === 'sale') {
            newUser = {
                name,
                email,
                type,
                saleCode,
            }
        } else {
            newUser = {
                name,
                email,
                type
            }
        }
        for (const [key, value] of Object.entries(newUser)) {
            if(value === ''){
                isError.push(key)
                setIsError([...isError])
            }
        }

        if(isError.length === 0){
            newUser.phone = phone
            if( password !== '' ){
                newUser.password = password 
            }
            newUser.addressId = addressId
           
            //console.log(newUser)
            dispatch(editUser(userId, newUser))
        } else {
            return
        }
    }
    return (
        <Box w='800px' maxW='100%' mx='auto' py='7' >
            <Text fontSize='3xl' align='center'>
                {byIdLoading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                Update user detail</Text>
            <Stack spacing={5} py='3'>

                {error && <Error error={error} />}
                {byIdError && <Error error={byIdError} /> }
                {namesRateError && <Error error={namesRateError} />}
                {salesNamesError && <Error error={salesNamesError} /> }
                <FormControl isRequired isInvalid={isError.indexOf('name') !== -1}>
                    <FormLabel>Name</FormLabel>
                    <Input 
                        type='text' 
                        size='sm'
                        value={name}
                        onChange={e=>setName(e.target.value)}
                        onKeyPress={()=>setIsError(isError.filter(x => x !== 'name'))}
                        maxLength={50}
                        
                        />
                </FormControl>
                
                <FormControl isRequired isInvalid={isError.indexOf('email') !== -1}>
                    <FormLabel>Email address</FormLabel>
                    <Input 
                        type='email'
                        size='sm' 
                        value={email}
                        onChange={e=>setEmail(e.target.value)}
                        onKeyPress={()=>setIsError(isError.filter(x => x !== 'email'))}
                        maxLength={40}
                        />
                </FormControl>
                
                <FormControl isRequired isInvalid={isError.indexOf('password') !== -1}>
                    <FormLabel>New Password</FormLabel>
                    <InputGroup size='sm'>
                        <Input  
                            type={show ? 'text' : 'password'}
                            size='sm'
                            value={password}
                            onChange={e=>setPassword(e.target.value)}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'password'))}
                            maxLength={40}
                            />
                        <InputRightElement width='4.5rem'>
                            <Button  size='xs' onClick={handleClick}>
                                {show ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </FormControl>
                

                <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input 
                        type='text' 
                        size='sm'
                        value={phone}
                        onChange={e=>setPhone(e.target.value)}
                        onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault()}}} 
                        maxLength={20}
                        />
                </FormControl>
                
                <FormControl isRequired isInvalid={isError.indexOf('type') !== -1}>
                    <FormLabel>Account type</FormLabel>
                    <Select placeholder={userDetail ? userDetail.accountType : 'Select'} size='sm' defaultValue={type} onChange={e=>handleTypeSelect(e.target.value)}>
                        <option value='agent'>agent</option>
                        <option value='sale'>sale</option>
                        <option value='user'>user</option>
                        <option value='admin'>admin</option>
                        <option value='deleted'>deleted</option>
                    </Select>
                </FormControl>

                {(namesRateLoading || salesNamesLoading) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                {type === 'sale' && 
                <>
                    <Box h='30px'>
                    <Divider />
                    </Box>
                    <FormControl isRequired isInvalid={isError.indexOf('saleCode') !== -1}>
                        <FormLabel>Sale Code</FormLabel>
                        <Input 
                            type='text' 
                            size='sm'
                            value={saleCode}
                            onChange={e=>setSaleCode(e.target.value)}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'saleCode'))}
                            maxLength={20}
                            
                            />
                    </FormControl>
                    <Box p={3} border={'1px'} shadow={3} rounded={'2xl'}>
                        <HStack mb={4}>
                            <ChooseFixedShipperForSaleModal 
                                setAddressId={setAddressId} 
                                setShipper={setShipper}
                            />
                            <Spacer />
                            <Button 
                                size={'sm'} 
                                colorScheme='red'
                                onClick={()=>{
                                    setAddressId('')
                                    setShipper(null)
                                }}
                            >Clear</Button>
                        </HStack>
                        {shipper && 
                        <Box>
                            <Stack>
                                <Box>{shipper.name}</Box> 
                                <Box>{shipper.company}</Box>
                                <Box>{shipper.address}</Box>
                                <Box>{shipper.phone}</Box>
                            </Stack>
                        </Box>
                        }
                    </Box>
                </>
                }

                {type === 'agent' && (
                    <>
                    <Box h='30px'>
                    <Divider />
                    </Box>
                    <FormControl isRequired isInvalid={isError.indexOf('agentCode') !== -1}>
                        <FormLabel>Agent Code</FormLabel>
                        <Input 
                            type='text' 
                            size='sm'
                            value={agentCode}
                            onChange={e=>setAgentCode(e.target.value)}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'agentCode'))}
                            maxLength={20}
                            
                            />
                    </FormControl>
                    <FormControl isRequired isInvalid={isError.indexOf('rate') !== -1}>
                        <FormLabel>Rate</FormLabel>
                        <Select size='sm' placeholder={userDetail && userDetail.rate ? userDetail.rate.name : 'Select'} onChange={e=>{setRate(e.target.value);setIsError(isError.filter(x => x !== 'rate'))}}>
                            {ratenames && ratenames.map((rn,idx)=>(
                                <option key={idx} value={rn._id}>{rn.name}</option>
                            ))}
                        </Select>
                    </FormControl>


                    <FormControl isRequired isInvalid={isError.indexOf('sale') !== -1}>
                    <FormLabel>Sale Person</FormLabel>
                    <Select size='sm' placeholder={userDetail && userDetail.sale ? userDetail.sale.name : 'Select'} onChange={e=>{setSale(e.target.value);setIsError(isError.filter(x => x !== 'sale'))}}>
                        {salesnames && salesnames.map((sn,idx)=>(
                            <option key={idx} value={sn._id}>{sn.name}</option>
                        ))}
                    </Select>
                    </FormControl>
                    </>
                )}
                
                <Box mt='6'></Box>
                <HStack>
                    <Link to={`/user/${userId}`}>
                        <Button 
                            isLoading={loading}
                            size='sm'
                            w='150px'
                            colorScheme='blue'
                            leftIcon={<Icon as={MdReply} />} 
                        >Cancel</Button>
                    </Link>                   
                    <Spacer />
                    <Button 
                        isLoading={loading}
                        size='sm'
                        w='150px'
                        colorScheme='green'
                        loadingText='Submitting'
                        rightIcon={<Icon as={MdSaveAlt} />} 
                        onClick={handleSubmit}
                    >Save</Button>
                    
                </HStack>
                
            </Stack>        
        </Box>
    )
}

export default UserEditScreen