import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Stack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Input,
    Box,
    Spinner,
} from '@chakra-ui/react'
import { updateRemoteArea } from '../actions/remoteAreaActions'
import { REMOTEAREA_UPDATE_RESET } from '../constants/remoteAreaConstants'
import Error from '../components/Error'

const RemoteAreaEditModal = ({acc}) => {
    const dispatch = useDispatch()
    const [pcode, setPcode] = useState(acc.pcode)
    const [show, setShow] = useState(false)
    const {loading, error, success} = useSelector(state=>state.rejectName.Update)

    useEffect(()=>{
        if(success){
            setPcode('')
            setShow(false)
        }
        return()=>{
            dispatch({type: REMOTEAREA_UPDATE_RESET})
        }
    },[success, dispatch])

    const handleClose = () => {
        setPcode('')
        setShow(false)
    }


    return (
        <>
            <Button size='sm'  colorScheme={'blue'} onClick={()=>setShow(true)} >Edit</Button>

            <Modal isOpen={show} onClose={handleClose} size='lg' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                   Update Remote Area
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Box>
                        {error && <Error error={error} /> }
                    </Box>
                    <Stack spacing={'3'}>                  

                        <Stack spacing={'0'}>
                            <Box>Area Postal Code</Box>
                            <Input 
                                type={'text'}
                                size='sm'
                                value={pcode}
                                onChange={e=>setPcode(e.target.value)}
                            />
                        </Stack>

                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button size='sm' w='150px' colorScheme={'green'} onClick={()=>dispatch(updateRemoteArea({pcode}, acc._id))} >Save</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default RemoteAreaEditModal