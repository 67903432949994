// const countryList = [
//     {cName:'Australia',cCode:'AU',pCode:'61', zone: '3.1'},
//     {cName:'Austria',cCode:'AT', pCode: '43', zone: '7.1'},
//     {cName:'Bangladesh',cCode:'BD',pCode:'880', zone: '5'},
//     {cName:'Barbados',cCode:'BB',pCode:'1-246', zone: '9'},
//     {cName:'Cambodia',cCode:'KH',pCode:'855', zone: '3'},
//     {cName:'Canada',cCode:'CA',pCode:'1', zone: '6.1'},
//     {cName:'China',cCode:'CN',pCode:'86', zone: '2'},
//     {cName:'Czech Republic',cCode:'CZ',pCode:'420', zone: '7.1'},
//     {cName:'Denmark',cCode:'DK',pCode:'45', zone: '7.1'},
//     {cName:'Finland',cCode:'FI',pCode:'358', zone: '7.1'},
//     {cName:'France',cCode:'FR',pCode:'33', zone: '7.1'},
//     {cName:'Gambia',cCode:'GM',pCode:'220', zone: '9'},
//     {cName:'Germany',cCode:'DE',pCode:'49', zone: '7.1'},
//     {cName:'Guyana (French)',cCode:'GF',pCode:'594', zone: '9'},
//     {cName:'Hong Kong SAR, China',cCode:'HK',pCode:'852', zone: '1.1'},
//     {cName:'Hungary',cCode:'HU',pCode:'36', zone: '7'},
//     {cName:'India',cCode:'IN',pCode:'91', zone: '5.1'},
//     {cName:'Indonesia',cCode:'ID',pCode:'62', zone: '3.1'},
//     {cName:'Ireland',cCode:'IE',pCode:'353', zone: '7.1'},
//     {cName:'Italy',cCode:'IT',pCode:'39', zone: '7.1'},
//     {cName:'Japan',cCode:'JP',pCode:'81', zone: '4'},
//     {cName:'Kenya',cCode:'KE',pCode:'254', zone: '9'},
//     {cName:'Laos',cCode:'LA',pCode:'856', zone: '3'},
//     {cName:'Macao SAR, China',cCode:'MO',pCode:'853', zone: '2'},
//     {cName:'Malaysia',cCode:'MY',pCode:'60', zone: '1.1'},
//     {cName:'Micronesia',cCode:'FM',pCode:'691', zone: '9'},
//     {cName:'Myanmar',cCode:'MM',pCode:'65', zone: '3'},
//     {cName:'Netherlands',cCode:'NL',pCode:'31', zone: '7.1'},
//     {cName:'New Zealand',cCode:'NZ',pCode:'64', zone: '3.1'},
//     {cName:'Norway',cCode:'NO',pCode:'47', zone: '8.1'},
//     {cName:'Oman',cCode:'OM',pCode:'968', zone: '8'},
//     {cName:'Pakistan',cCode:'PK',pCode:'92', zone: '5.1'},
//     {cName:'Philippines',cCode:'PH',pCode:'63', zone: '3.1'},
//     {cName:'Poland',cCode:'PL',pCode:'48', zone: '7.1'},
//     {cName:'Qatar',cCode:'QA',pCode:'974', zone: '8'},
//     {cName:'Saudi Arabia',cCode:'SA',pCode:'966', zone: '8'},
//     {cName:'Singapore',cCode:'SG',pCode:'65', zone: '1.1'},
//     {cName:'Slovakia',cCode:'SK',pCode:'421', zone: '7'},
//     {cName:'South Korea',cCode:'KR',pCode:'82', zone: '4'},
//     {cName:'Spain',cCode:'ES',pCode:'34', zone: '7.1'},
//     {cName:'Sri Lanka',cCode:'LK',pCode:'94', zone: '5'},
//     {cName:'Sweden',cCode:'SE',pCode:'46', zone: '7.1'},
//     {cName:'Switzerland',cCode:'CH',pCode:'41', zone: '7.1'},
//     {cName:'Taiwan',cCode:'TW',pCode:'886', zone: '3.1'},
//     {cName:'Thailand',cCode:'TH',pCode:'66', zone: '0'},
//     {cName:'United Arab Emirates',cCode:'AE',pCode:'971', zone: '8'},
//     {cName:'United Kingdom',cCode:'GB',pCode:'44', zone: '7.1'},
//     {cName:'United States of America',cCode:'US',pCode:'1', zone: '6'},
//     {cName:'Vietnam',cCode:'VN',pCode:'84', zone: '3.1'},
   

// ]


//paper less trade
const pltNotAllowedList = [
    'AF',
    'AM',
    'AZ',
    'BA',
    'BD',
    'BO',
    'BR',
    'BY',
    'CR',
    'CU',
    'DZ',
    'EC',
    'EG',
    'GE',
    'GL',
    'GT',
    'HN',
    'IN',
    'IQ',
    'IR',
    'KG',
    'KH',
    'KP',
    'KV',
    'KY',
    'KZ',
    'LB',
    'LS',
    'LY',
    'MA',
    'MD',
    'MK',
    'MM',
    'NG',
    'NI',
    'PK',
    'QA',
    'RU',
    'SD',
    'SM',
    'SO',
    'SS',
    'SV',
    'SY',
    'TJ',
    'TN',
    'TR',
    'UA',
    'UZ',
    'VN',
    'XS',
    'YE',
]

export { pltNotAllowedList}