import {
    RATE_CREATE_REQUEST,
    RATE_CREATE_SUCCESS,
    RATE_CREATE_FAIL,
    RATE_CREATE_RESET,
    RATE_LIST_REQUEST,
    RATE_LIST_SUCCESS,
    RATE_LIST_FAIL,
    RATE_LIST_RESET,
    RATE_BYID_REQUEST,
    RATE_BYID_SUCCESS,
    RATE_BYID_FAIL,
    RATE_BYID_RESET,
    RATE_EDIT_REQUEST,
    RATE_EDIT_SUCCESS,
    RATE_EDIT_FAIL,
    RATE_EDIT_RESET,
    RATE_DELETE_REQUEST,
    RATE_DELETE_SUCCESS,
    RATE_DELETE_FAIL,
    RATE_DELETE_RESET,
    RATE_NAMES_REQUEST,
    RATE_NAMES_SUCCESS,
    RATE_NAMES_FAIL,
    RATE_NAMES_RESET,
    RATE_BYAGENT_REQUEST,
    RATE_BYAGENT_SUCCESS,
    RATE_BYAGENT_FAIL,
    RATE_BYAGENT_RESET,
    RATE_MY_REQUEST,
    RATE_MY_SUCCESS,
    RATE_MY_FAIL,
    RATE_MY_RESET,
    RATE_AGENTNAMES_REQUEST,
    RATE_AGENTNAMES_SUCCESS,
    RATE_AGENTNAMES_FAIL,
    RATE_AGENTNAMES_RESET,
    RATE_TRANSFER_REQUEST,
    RATE_TRANSFER_SUCCESS,
    RATE_TRANSFER_FAIL,
    RATE_TRANSFER_RESET,
    RATE_STATUS_REQUEST,
    RATE_STATUS_SUCCESS,
    RATE_STATUS_FAIL,
    RATE_STATUS_RESET,
    RATE_SHIPMENTQTY_REQUEST,
    RATE_SHIPMENTQTY_SUCCESS,
    RATE_SHIPMENTQTY_FAIL,
    RATE_SHIPMENTQTY_RESET,
    RATE_SHIPMENTQTY_ACTIVE,
    RATE_USER_REQUEST,
    RATE_USER_SUCCESS,
    RATE_USER_FAIL,
    RATE_USER_RESET,
    RATE_DISCOUNT_REQUEST,
    RATE_DISCOUNT_SUCCESS,
    RATE_DISCOUNT_FAIL,
    RATE_DISCOUNT_RESET,
} from '../constants/rateConstants'

export const rateCreateReducer = (state={}, action) => {
    switch(action.type){
        case RATE_CREATE_REQUEST:
            return {loading: true}
        case RATE_CREATE_SUCCESS:
            return {loading: false, success: true}
        case RATE_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case RATE_CREATE_RESET:
            return {}
        default: return state
    }
}

export const rateListReducer = (state={rates:[]}, action) => {
    switch(action.type){
        case RATE_LIST_REQUEST:
            return {loading: true}
        case RATE_LIST_SUCCESS:
            return {
                loading: false, 
                success: true,
                rates: action.payload,
            }
        case RATE_LIST_FAIL:
            return {loading: false, success: false, error: action.payload}
        case RATE_LIST_RESET:
            return {}
        default: return state
    }
}

export const rateNamesReducer = (state={ratenames:[]}, action) => {
    switch(action.type){
        case RATE_NAMES_REQUEST:
            return {loading: true}
        case RATE_NAMES_SUCCESS:
            return {
                loading: false, 
                ratenames: action.payload,
            }
        case RATE_NAMES_FAIL:
            return {loading: false, error: action.payload}
        case RATE_NAMES_RESET:
            return {}
        default: return state
    }
}

export const rateEditReducer = (state={}, action) => {
    switch(action.type){
        case RATE_EDIT_REQUEST:
            return {loading: true}
        case RATE_EDIT_SUCCESS:
            return {loading: false, success: true}
        case RATE_EDIT_FAIL:
            return {loading: false, error: action.payload}
        case RATE_EDIT_RESET:
            return {}
        default: return state
    }
}


export const rateDeleteReducer = (state={}, action) => {
    switch(action.type){
        case RATE_DELETE_REQUEST:
            return {loading: true}
        case RATE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case RATE_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case RATE_DELETE_RESET:
            return {}
        default: return state
    }
}

export const rateByIdReducer = (state={}, action) => {
    switch(action.type){
        case RATE_BYID_REQUEST:
            return {loading: true}
        case RATE_BYID_SUCCESS:
            return {loading: false, success: true, rateDetail: action.payload,
            }
        case RATE_BYID_FAIL:
            return {loading: false, success: false, error: action.payload}
        case RATE_BYID_RESET:
            return {}
        default: return state
    }
}

export const rateByAgentReducer = (state={}, action) => {
    switch(action.type){
        case RATE_BYAGENT_REQUEST:
            return {loading: true}
        case RATE_BYAGENT_SUCCESS:
            return {loading: false, success: true, rateDetail: action.payload,
            }
        case RATE_BYAGENT_FAIL:
            return {loading: false, success: false, error: action.payload}
        case RATE_BYAGENT_RESET:
            return {}
        default: return state
    }
}

export const rateMyReducer = (state={}, action) => {
    switch(action.type){
        case RATE_MY_REQUEST:
            return {loading: true}
        case RATE_MY_SUCCESS:
            return {loading: false, success: true, rateDetail: action.payload,
            }
        case RATE_MY_FAIL:
            return {loading: false, success: false, error: action.payload}
        case RATE_MY_RESET:
            return {}
        default: return state
    }
}

export const rateAgentsReducer = (state={}, action) => {
    switch(action.type){
        case RATE_AGENTNAMES_REQUEST:
            return {loading: true}
        case RATE_AGENTNAMES_SUCCESS:
            return {loading: false, agentNames: action.payload,
            }
        case RATE_AGENTNAMES_FAIL:
            return {loading: false, error: action.payload}
        case RATE_AGENTNAMES_RESET:
            return {}
        default: return state
    }
}

export const rateTransferReducer = (state={}, action) => {
    switch(action.type){
        case RATE_TRANSFER_REQUEST:
            return {loading: true}
        case RATE_TRANSFER_SUCCESS:
            return {loading: false, success: true}
        case RATE_TRANSFER_FAIL:
            return {loading: false, error: action.payload}
        case RATE_TRANSFER_RESET:
            return {}
        default: return state
    }
}

export const rateStatusReducer = (state={}, action) => {
    switch(action.type){
        case RATE_STATUS_REQUEST:
            return {loading: true}
        case RATE_STATUS_SUCCESS:
            return {loading: false, success: true}
        case RATE_STATUS_FAIL:
            return {loading: false, error: action.payload}
        case RATE_STATUS_RESET:
            return {}
        default: return state
    }
}

export const rateShipmentQtyReducer = (state={rateShipments: []}, action) => {
    switch(action.type){
        case RATE_SHIPMENTQTY_REQUEST:
            return {loading: true}
        case RATE_SHIPMENTQTY_SUCCESS:
            return {loading: false, rateShipments: action.payload}
        case RATE_SHIPMENTQTY_FAIL:
            return {loading: false, error: action.payload}
        case RATE_SHIPMENTQTY_RESET:
            return {}
        case RATE_SHIPMENTQTY_ACTIVE:
            return {
                ...state,
                rateShipments : state.rateShipments.filter(x=>x.status === true)
            }
        default: return state
    }
}

export const rateUserReducer = (state={}, action) => {
    switch(action.type){
        case RATE_USER_REQUEST:
            return {loading: true}
        case RATE_USER_SUCCESS:
            return {loading: false, success: true}
        case RATE_USER_FAIL:
            return {loading: false, error: action.payload}
        case RATE_USER_RESET:
            return {}
        default: return state
    }
}

export const rateDiscountReducer = (state={}, action) => {
    switch(action.type){
        case RATE_DISCOUNT_REQUEST:
            return {loading: true}
        case RATE_DISCOUNT_SUCCESS:
            return {loading: false, success: true}
        case RATE_DISCOUNT_FAIL:
            return {loading: false, error: action.payload}
        case RATE_DISCOUNT_RESET:
            return {}
        default: return state
    }
}