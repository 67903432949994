import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { 
    Box,
    Spinner,
    HStack,
    Stack,
    Spacer,
    Divider,
    Button,
    Center,
    Heading,
} from '@chakra-ui/react'
import { getTHPPaymentById } from '../../../actions/thailand_post_actions/thpPaymentActions'
import { THPPAYMENT_DELETE_RESET } from '../../../constants/thailand_post_constants/thpPaymentConstants'
import Error from '../../../components/Error'

const PostSalePaymentDetailScreen = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const {loading, error, payment} = useSelector(state=>state.thailandPost.PaymentById)

    useEffect(()=>{
       
        dispatch(getTHPPaymentById(params.id))
        return()=>{
            dispatch({type: THPPAYMENT_DELETE_RESET})
        }
    },[dispatch, params])

    return (
        <Box>

            <HStack>
                <Box mx='auto' w='500px' mt='50px'>
                    <Center><Heading>Payment Detail</Heading></Center>
                    {loading  && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                </Box>
            </HStack>

            <Box>
                {error && <Error error={error} /> }
            </Box>

            {
                payment && 
                <Box mx='auto' w='500px' mt='10px' shadow={'lg'} p='3' fontSize={'lg'}>
                    <Stack>
                        <HStack>
                            <Box>Code</Box><Spacer />
                            <Box>{payment.code}</Box>
                        </HStack>
                        <Divider />
                        
                        <HStack>
                            <Box>Amount</Box><Spacer />
                            <Box>{payment.amount.toLocaleString()}</Box>
                        </HStack>
                        <Divider />
                        <HStack>
                            <Box>Method</Box><Spacer />
                            <Box>{payment.paidMethod}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Date</Box><Spacer />
                            <Box>{new Date(payment.createdAt).toLocaleString('en', { hour12: true, timeZone: 'UTC' })}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Created By</Box><Spacer />
                            <Box>{payment.createdBy.name}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Agent</Box><Spacer />
                            <Box>{payment.agent.name}</Box>
                        </HStack>
                        <Divider />

                        <HStack>                            
                            <Box>Invoice</Box>                            
                            <Spacer />
                            <Link to={`/mythpinvoices/${payment.invoice._id}`}>
                                <Button variant={'link'} color='black' >{payment.invoice.code}</Button>
                            </Link>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Invoice Amount</Box><Spacer />
                            <Box>{payment.invoice.totalAmount.toLocaleString()}</Box>
                        </HStack>
                        <Divider />

                        <HStack>
                            <Box>Approved</Box><Spacer />
                            <Box>{payment.isApproved ? 'Yes' : 'No'}</Box>
                        </HStack>
                        <Divider />
                        {payment.isApproved &&
                        <>
                            <HStack>
                                <Box>Approved By</Box><Spacer />
                                <Box>{payment.approvedBy.name}</Box>
                            </HStack>
                            <Divider />

                            <HStack>
                                <Box>Approved At</Box><Spacer />
                                <Box>{new Date(payment.approvedAt).toLocaleString('en', { hour12: true, timeZone: 'UTC' })}</Box>
                            </HStack>
                            <Divider />

                        </>
                        }
                        <HStack>
                            <Box>Note</Box><Spacer />
                            <Box>{payment.note}</Box>
                        </HStack>
                        
                    </Stack>
                </Box>
            }
        </Box>
    )
}

export default PostSalePaymentDetailScreen