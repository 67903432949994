import axios from 'axios'
import {
    THPPAYMENT_APPROVE_FAIL,
    THPPAYMENT_APPROVE_REQUEST,
    THPPAYMENT_APPROVE_SUCCESS,
    THPPAYMENT_BYID_FAIL,
    THPPAYMENT_BYID_REQUEST,
    THPPAYMENT_BYID_SUCCESS,
    THPPAYMENT_CREATE_FAIL,
    THPPAYMENT_CREATE_REQUEST,
    THPPAYMENT_CREATE_SUCCESS,
    THPPAYMENT_DELETE_FAIL,
    THPPAYMENT_DELETE_REQUEST,
    THPPAYMENT_DELETE_SUCCESS,
    THPPAYMENT_LIST_FAIL,
    THPPAYMENT_LIST_REQUEST, 
    THPPAYMENT_LIST_SUCCESS,
    THPPAYMENT_MY_FAIL,
    THPPAYMENT_MY_REQUEST,
    THPPAYMENT_MY_SUCCESS,
} from '../../constants/thailand_post_constants/thpPaymentConstants'


export const listTHPPayments = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: THPPAYMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/payments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: THPPAYMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPPAYMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const filterTHPPayments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPPAYMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/thp/payments/filter`, {filterData}, config)
        dispatch({type: THPPAYMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPPAYMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myTHPPayments = (pageNumber = '', itemCount = '', keyword = '', date = '') => async (dispatch, getState) => {
    try {
        dispatch({type: THPPAYMENT_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/payments/mypayments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}&date=${date}`, config)
        dispatch({type: THPPAYMENT_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPPAYMENT_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myFilterTHPPayments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPPAYMENT_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/thp/payments/myfilteredpayments`, {filterData}, config)
        dispatch({type: THPPAYMENT_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPPAYMENT_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const createTHPPayment = (paymentData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPPAYMENT_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/thp/payments`, {paymentData}, config)
        dispatch({type: THPPAYMENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPPAYMENT_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getTHPPaymentById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: THPPAYMENT_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/payments/${id}`, config)
        dispatch({type: THPPAYMENT_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPPAYMENT_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const approveTHPPayment = (paymentId, invoiceData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPPAYMENT_APPROVE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/thp/payments/${paymentId}`, {invoiceData}, config)
        dispatch({type: THPPAYMENT_APPROVE_SUCCESS})
    } catch (error) {
        dispatch({
            type: THPPAYMENT_APPROVE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deleteTHPPayment = (paymentId) => async (dispatch, getState) => {
    try {
        dispatch({type: THPPAYMENT_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/thp/payments/${paymentId}`, config)
        dispatch({type: THPPAYMENT_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: THPPAYMENT_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}