const boxSizes = [
    {
        code: 'env',
        name: 'Envelope',
        weight: 0,
        dimensions: {
            length: 35,
            width: 27,
            height: 1,
        }
    },
    {
        code: 'flys',
        name: 'Flyer Standard',
        weight: 0,
        dimensions: {
            length: 30,
            width: 40,
            height: 1,
        }
    },
    {
        code: 'flyl',
        name: 'Flyer Large',
        weight: 0,
        dimensions: {
            length: 47.5,
            width: 37.5,
            height: 1,
        }
    },
    {
        code: 'box2',
        name: 'Box 2',
        weight: 0.19,
        dimensions: {
            length: 33,
            width: 18,
            height: 10,
        }
    },
    {
        code: 'box3',
        name: 'Box 3',
        weight: 0.28,
        dimensions: {
            length: 33,
            width: 32,
            height: 10,
        }
    },
    {
        code: 'box4',
        name: 'Box 4',
        weight: 0.41,
        dimensions: {
            length: 33,
            width: 32,
            height: 18,            
        }
    },
    {
        code: 'box5',
        name: 'Box 5',
        weight: 0.91,
        dimensions: {
            length: 33,
            width: 32,
            height: 34,
        }
    },
    {
        code: 'box6',
        name: 'Box 6',
        weight: 1.18,
        dimensions: {
            length: 41,
            width: 35,
            height: 36,
        }
    },
    {
        code: 'box7',
        name: 'Box 7',
        weight: 1.46,
        dimensions: {
            length: 48,
            width: 40,
            height: 38,
        }
    },
    {
        code: 'box8',
        name: 'Box 8',
        weight: 1.70,
        dimensions: {
            length: 55,
            width: 45,
            height: 41,
        }
    },
    {
        code: 'box8Y',
        name: 'Box 8 Yellow',
        weight: 1.50,
        dimensions: {
            length: 55,
            width: 45,
            height: 41,
        }
    },
    {
        code: 'box11',
        name: 'Box 11',
        weight: 1.6,
        dimensions: {
            length: 65,
            width: 50,
            height: 45,
        }
    },
    {
        code: 'box250',
        name: 'Box 250',
        weight: 0.59,
        dimensions: {
            length: 41,
            width: 31,
            height: 25,
        }
    },
    {
        code: 'box400',
        name: 'Box 400',
        weight: 0,
        dimensions: {
            length: 67,
            width: 50,
            height: 40,
        }
    },
    {
        code: 'box500',
        name: 'Box 500',
        weight: 0,
        dimensions: {
            length: 48,
            width: 33,
            height: 50,
        }
    },
    {
        code: 'other',
        name: 'Other',
        weight: 0,
        dimensions: {
            length: 0,
            width: 0,
            height: 0,
        }
    },
    {
        code: 'box45',
        name: 'Box 45',
        weight: 0,
        dimensions: {
            length: 30,
            width: 40,
            height: 24,
        }
    },
    {
        code: 'box55',
        name: 'Box 55',
        weight: 0,
        dimensions: {
            length: 32,
            width: 46,
            height: 40,
        }
    },
    {
        code: 'box60',
        name: 'Box 60',
        weight: 0,
        dimensions: {
            length: 45,
            width: 35,
            height: 35,
        }
    },
    {
        code: 'box70',
        name: 'Box 70',
        weight: 0,
        dimensions: {
            length: 46,
            width: 54,
            height: 40,
        }
    },
    

]

export { boxSizes }