import React, {useRef} from 'react'
import ReactToPrint from 'react-to-print'
import {
    Box,
    HStack,
    VStack,
    Spacer,
    Button,
    Stack,
    Text,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
} from '@chakra-ui/react'
import { MdLocalPrintshop } from 'react-icons/md'

const Report = ({preshipment}) => {
    const reportRef = useRef()


    const reportStyle = `
    @page {
        size: A4;
        margin: 10mm 10mm;
    }
    html, body {
        font-size: 13px;
        font-weight: 400;
    }
    `;

    return (
        <Box>
            <HStack my='3'>
                <Box></Box>
                <Spacer />
                <Box>
                <ReactToPrint
                    trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                    content={() => reportRef.current}
                    pageStyle={()=>reportStyle}
                />
                </Box>
            </HStack>

            
            <Box ref={reportRef}>
                <Stack>
                    <Box>
                        <HStack>
                        <Text fontSize={'20px'}>Date - {preshipment && preshipment.createdAt.substring(0,10)}</Text><Spacer />
                        <Spacer />
                        <Text fontSize={'20px'}>PreID - {preshipment && preshipment.code}</Text>            
                        </HStack>
                    </Box>
                    <Box borderTop={'1px'}>
                        <Stack>
                            {preshipment && preshipment.boxes.map((box, idx)=>(
                            <Text key={idx}>
                                Box-{idx+1} - {box.dimensions.length} x {box.dimensions.width} x {box.dimensions.height} cm, {box.weight} kg
                            </Text>
                        ))
                        }
                        </Stack>
                    </Box>
                    
                    <HStack spacing={'0'}>
                        <Box w={'50%'} h={'250px'} borderY={'1px'} borderRight='1px'>
                            <VStack alignItems={'left'} p='3'>
                            <Text fontSize='xl' as='u'>Sender</Text>
                            <Text>{preshipment && preshipment.contact}</Text>
                            </VStack>

                        </Box>
                        <Box w={'50%'} h={'250px'} borderY={'1px'}>
                            <VStack alignItems={'left'} p='3'>
                            <Text fontSize='xl' as='u'>Receiver</Text>
                            <Text>{preshipment && preshipment.addressId && preshipment.addressId.name}</Text>
                            <Text>{preshipment && preshipment.addressId && preshipment.addressId.address}</Text>
                            <Text>{preshipment && preshipment.addressId && preshipment.addressId.city}</Text>
                            <Text>{preshipment && preshipment.addressId && preshipment.addressId.country + ' ' + preshipment.addressId.postalcode}</Text>
                            <Text>{preshipment && preshipment.addressId && preshipment.addressId.phone}</Text>
                            </VStack>
                        </Box>
                    </HStack>

                    <TableContainer>
                        <Table variant={'striped'} size='sm'>
                        <Thead>
                            <Tr>
                            <Th w='20px'>No</Th>
                            <Th w='300px'>Name</Th>
                            <Th w='50px'>Qty</Th>
                            <Th w='50px'>Weight</Th>
                            <Th>Remark</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {preshipment && preshipment.items.map((itm, idx)=>(
                            <Tr key={idx}>
                                <Td>{idx+1}</Td>
                                <Td>{itm.item.name}</Td>
                                <Td>{itm.qty}</Td>
                                <Td>{itm.weight}</Td>
                                <Td className='nextline'>{itm.note}</Td>
                            </Tr>
                            ))}
                        </Tbody>
                        </Table>
                    </TableContainer>
                </Stack>
            </Box>        
        </Box>
    )
}

export default Report