import React, { useState} from 'react'
import { Box, Input, Button, InputGroup, InputRightElement, InputLeftAddon} from '@chakra-ui/react'
import { MdSearch } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'

const SearchBox = ({width, text}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [keyword, setKeyword] = useState('')

    const handleSubmit = (e) => { 
        if(e.key === 'Enter'){
            if(keyword !== ''){
                const path = location.pathname.split('/')[1]
                navigate(`/${path}/search/${keyword}`, {replace: true})
            } else {
                const path = location.pathname.split('/')[1]
                navigate(`/${path}`)
            }
        }
        return
    }
    const handleButtonSubmit = () => {
        if(keyword !== ''){
            const path = location.pathname.split('/')[1]
            navigate(`/${path}/search/${keyword}`, {replace: true})
        } else {
            const path = location.pathname.split('/')[1]
            navigate(`/${path}`)
        }
    }

    return (
        <Box w={width}>
            <InputGroup size='sm'>
                <InputLeftAddon 
                    fontSize='20px'
                    children={<MdSearch />}
                />
                <Input 
                    formNoValidate
                    placeholder={text}
                    value={keyword}
                    onChange={e=>setKeyword(e.target.value)}
                    // onKeyPress={(e) => {if (!/[0-9,a-z,A-Z, ]/.test(e.key)) {e.preventDefault()}}}
                    onKeyDown={handleSubmit}
                />
                <InputRightElement width='4.5rem'>
                    <Button
                        size='xs'
                        w='50px'
                        colorScheme={'blackAlpha'}
                        onClick={()=>handleButtonSubmit()}
                    >Go</Button>
                </InputRightElement>
                
            </InputGroup>
        </Box>
    )
}

SearchBox.defaultProps = {
    text: 'search',
    width: '300px'
}

export default SearchBox