import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { listDomesticPayments, filterDomesticPayments } from '../../actions/domestic_actions/domesticPaymentActions'
import {
    Box,
    HStack,
    Stack,
    Select,
    Divider,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    Center,
    Heading,
    Text,
    SimpleGrid,
    GridItem,
    useBreakpointValue,
    Flex,
} from '@chakra-ui/react'
import { MdCheckCircle, MdCancel } from 'react-icons/md'
import { Error, useWindowSize, Filter, MySpinner, Pagination, SearchBox } from '../../components'

const DomesticPaymentListScreen = () => {
    const size = useWindowSize()
    const colSpan = useBreakpointValue({base: 2, md: 1})
    const dispatch = useDispatch()
    const params = useParams()
    const keyword = params.keyword || ''
    const pageNumber = params.pageNumber || 1
    const [itemCount, setItemCount] = useState(50)
    const {loading, error, payments, pages, page} = useSelector(state=>state.domestic.PaymentList)

    useEffect(()=>{
        dispatch(listDomesticPayments(pageNumber, itemCount, keyword))
    },[dispatch, pageNumber, itemCount, keyword])

    return (
        <Box my='3'>
            <Center mb='3'><Heading bg='red.200' px='2' borderRadius={10}>Domestic Payment {loading && <MySpinner />}</Heading></Center>
            {error && <Error error={error} />}
            <Divider />

            <SimpleGrid columns={2} columnGap={3} rowGap={3} my='3'>
                <GridItem colSpan={colSpan}>
                    <SearchBox width='full' />
                </GridItem>

                <GridItem colSpan={colSpan} ml='auto'>
                    <HStack>
                        <Select placeholder='Select' w='80px' onChange={e=>setItemCount(e.target.value)} size='sm'>
                            {[50, 100, 150, 200].map((v,i)=>(
                                <option key={i} value={v}>{v}</option>
                            ))}
                        </Select>
                        <Pagination keyword = {keyword} pages={pages} page={page} path='domesticpayment' />
                    </HStack>
                </GridItem>
            </SimpleGrid>

            <Filter filterFunction={filterDomesticPayments} approveShow={true} paymentShow={false} invoiceShow={false} scanShow={false} />
            
            {size.width > 760 ? 
                <Table size='sm' variant='striped' colorScheme='pink' my='4'>
                    <Thead>
                        <Tr>
                        <Th>No</Th>
                        <Th>Code</Th>
                        <Th>Sale</Th>
                        <Th>Agent</Th>
                        <Th>Invoice</Th>
                        <Th isNumeric>Invoice Amount</Th>
                        <Th isNumeric>Payment Amount</Th>
                        <Th isNumeric>Created Date</Th>
                        <Th>Approved</Th>
                        <Th>Detail</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {payments && payments.map((p, idx)=>(
                        <Tr key={idx}>
                            <Td>{pageNumber === 1 ? (idx+1) : (((Number(pageNumber)-1) * itemCount) + (idx+1))}</Td>
                            <Td>{p.code}</Td>
                            <Td>{p.sale.saleCode}</Td>
                            <Td>{p.agent.name}</Td>
                            {/* <Td>{p.invoice.code}</Td> */}
                            <Td>
                                <Link to={`/domesticinvoice/${p.invoice._id}`}>
                                    <Text as='u'>{p.invoice.code}</Text>
                                </Link>
                            </Td>
                            <Td isNumeric>{p.invoice.totalAmount.toLocaleString()}</Td>
                            <Td isNumeric>{p.amount.toLocaleString()}</Td>
                            <Td isNumeric>{p.createdAt.substring(0,10)}</Td>
                            {/* <Td><Box bg={p.isApproved ? 'green' : 'red'} h='20px' w='20px'></Box></Td> */}
                            <Td>{p.isApproved ? < MdCheckCircle size={20} color='green' /> :  < MdCancel size={20} color='red' />}</Td>
                            <Td>
                                <Link to={`/domesticpayment/${p._id}`} state={{list: true}}>
                                <Button size='sm' variant={'link'} >Details</Button>
                                </Link>
                            </Td>
                        </Tr>
                        ))}
                    </Tbody>
                </Table>
                :
                <Table size='sm' variant='striped' colorScheme='pink' my='4'>
                    <Thead>
                        <Tr>
                        <Th>No</Th>
                        <Th>Summary</Th>
                        <Th>Detail</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {payments && payments.map((p, idx)=>(
                        <Tr key={idx}>
                            <Td>{pageNumber === 1 ? (idx+1) : (((Number(pageNumber)-1) * itemCount) + (idx+1))}</Td>
                            <Td>
                                <Stack spacing={0}>
                                    <Box>Code - {p.code}</Box>
                                    <Box>Sale - {p.sale.saleCode}</Box>
                                    <Box>Agent - {p.agent.name}</Box>
                                    <Box as='u'>Invoice - <Link to={`/domesticinvoice/${p.invoice._id}`}>{p.invoice.code}</Link></Box>
                                    <Box>Inv Amount - {p.invoice.totalAmount.toLocaleString()}</Box>
                                    <Box>Payment Amount - {p.amount.toLocaleString()}</Box>
                                    <Box>Created - {p.createdAt.substring(0,10)}</Box>
                                    <Flex>Approved - {p.isApproved ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Flex>
                                </Stack>
                            </Td>
                            <Td>
                                <Link to={`/domesticpayment/${p._id}`} state={{list: true}}>
                                    Details
                                </Link>
                            </Td>
                        </Tr>
                        ))}
                    </Tbody>
                </Table>
            }
        </Box>
    )
}

export default DomesticPaymentListScreen