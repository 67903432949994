import {
    THPSHIPMENT_BYID_FAIL,
    THPSHIPMENT_BYID_REQUEST,
    THPSHIPMENT_BYID_RESET,
    THPSHIPMENT_BYID_SUCCESS,
    THPSHIPMENT_CHARGES_FAIL,
    THPSHIPMENT_CHARGES_REQUEST,
    THPSHIPMENT_CHARGES_RESET,
    THPSHIPMENT_CHARGES_SUCCESS,
    THPSHIPMENT_CREATE_FAIL,
    THPSHIPMENT_CREATE_REQUEST, 
    THPSHIPMENT_CREATE_RESET, 
    THPSHIPMENT_CREATE_SUCCESS,
    THPSHIPMENT_DELETE_FAIL,
    THPSHIPMENT_DELETE_REQUEST,
    THPSHIPMENT_DELETE_RESET,
    THPSHIPMENT_DELETE_SUCCESS,
    THPSHIPMENT_UPDATE_FAIL,
    THPSHIPMENT_UPDATE_REQUEST,
    THPSHIPMENT_UPDATE_RESET,
    THPSHIPMENT_UPDATE_SUCCESS,
    THPSHIPMENT_GETCOST_FAIL,
    THPSHIPMENT_GETCOST_REQUEST,
    THPSHIPMENT_GETCOST_RESET,
    THPSHIPMENT_GETCOST_SUCCESS,
    THPSHIPMENT_LIST_FAIL,
    THPSHIPMENT_LIST_REQUEST,
    THPSHIPMENT_LIST_RESET,
    THPSHIPMENT_LIST_SUCCESS,
    THPSHIPMENT_MYLIST_FAIL,
    THPSHIPMENT_MYLIST_REQUEST,
    THPSHIPMENT_MYLIST_RESET,
    THPSHIPMENT_MYLIST_SUCCESS,
    THPSHIPMENT_PRESHIPCONTACT_FAIL,
    THPSHIPMENT_PRESHIPCONTACT_REQUEST,
    THPSHIPMENT_PRESHIPCONTACT_RESET,
    THPSHIPMENT_PRESHIPCONTACT_SUCCESS,
    THPSHIPMENT_UPDATECOST_FAIL,
    THPSHIPMENT_UPDATECOST_REQUEST,
    THPSHIPMENT_UPDATECOST_RESET,
    THPSHIPMENT_UPDATECOST_SUCCESS,
    THPSHIPMENT_SCANNED_REQUEST,
    THPSHIPMENT_SCANNED_SUCCESS,
    THPSHIPMENT_SCANNED_FAIL,
    THPSHIPMENT_SCANNED_RESET,
    THPSHIPMENT_GET_SCANNED_REQUEST,
    THPSHIPMENT_GET_SCANNED_SUCCESS,
    THPSHIPMENT_GET_SCANNED_FAIL,
    THPSHIPMENT_GET_SCANNED_RESET,
    THPSHIPMENT_SHIPANDSCAN_REQUEST,
    THPSHIPMENT_SHIPANDSCAN_SUCCESS,
    THPSHIPMENT_SHIPANDSCAN_FAIL,
} from '../../constants/thailand_post_constants/thpShipmentConstants'



export const thpShipmentCreateReducer = (state={}, action) => {
    switch(action.type){
        case THPSHIPMENT_CREATE_REQUEST:
            return {loading: true}
        case THPSHIPMENT_CREATE_SUCCESS:
            return {loading: false, success: true, shipment: action.payload}
        case THPSHIPMENT_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_CREATE_RESET:
            return {success: false}
        default: return state
    }
}

export const thpShipmentListReducer = (state={shipments: []}, action) => {
    switch(action.type){
        case THPSHIPMENT_LIST_REQUEST:
            return {loading: true}
        case THPSHIPMENT_LIST_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload.shipments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case THPSHIPMENT_LIST_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_LIST_RESET:
            return {}
        default: return state
    }
}

export const thpShipmentByIdReducer = (state={}, action) => {
    switch(action.type){
        case THPSHIPMENT_BYID_REQUEST:
            return {loading: true}
        case THPSHIPMENT_BYID_SUCCESS:
            return {loading: false, shipment: action.payload}
        case THPSHIPMENT_BYID_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_BYID_RESET:
            return {}
        default: return state
    }
}


export const thpShipmentDeleteReducer = (state={}, action) => {
    switch(action.type){
        case THPSHIPMENT_DELETE_REQUEST:
            return {loading: true}
        case THPSHIPMENT_DELETE_SUCCESS:
            return {loading: false, success: true}
        case THPSHIPMENT_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_DELETE_RESET:
            return {success: false}
        default: return state
    }
}

export const thpShipmentUpdateReducer = (state={}, action) => {
    switch(action.type){
        case THPSHIPMENT_UPDATE_REQUEST:
            return {loading: true}
        case THPSHIPMENT_UPDATE_SUCCESS:
            return {loading: false, success: true}
        case THPSHIPMENT_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_UPDATE_RESET:
            return {}
        default: return state
    }
}

export const thpShipmentGetCostReducer = (state={}, action) => {
    switch(action.type){
        case THPSHIPMENT_GETCOST_REQUEST:
            return {loading: true}
        case THPSHIPMENT_GETCOST_SUCCESS:
            return {loading: false, cost: action.payload}
        case THPSHIPMENT_GETCOST_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_GETCOST_RESET:
            return {}
        default: return state
    }
}

export const thpShipmentMyListReducer = (state={shipments: []}, action) => {
    switch(action.type){
        case THPSHIPMENT_MYLIST_REQUEST:
            return {loading: true}
        case THPSHIPMENT_MYLIST_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload.shipments,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case THPSHIPMENT_MYLIST_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_MYLIST_RESET:
            return {}
        default: return state
    }
}


export const thpShipmentContactReducer = (state={}, action) => {
    switch(action.type){
        case THPSHIPMENT_PRESHIPCONTACT_REQUEST:
            return {loading: true}
        case THPSHIPMENT_PRESHIPCONTACT_SUCCESS:
            return {loading: false, success: true}
        case THPSHIPMENT_PRESHIPCONTACT_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_PRESHIPCONTACT_RESET:
            return {success: false}
        default: return state
    }
}

export const thpShipmentChargesReducer = (state={}, action) => {
    switch(action.type){
        case THPSHIPMENT_CHARGES_REQUEST:
            return {loading: true}
        case THPSHIPMENT_CHARGES_SUCCESS:
            return {loading: false, success: true}
        case THPSHIPMENT_CHARGES_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_CHARGES_RESET:
            return {success: false}
        default: return state
    }
}

export const thpShipmentUpdateCostReducer = (state={}, action) => {
    switch(action.type){
        case THPSHIPMENT_UPDATECOST_REQUEST:
            return {loading: true}
        case THPSHIPMENT_UPDATECOST_SUCCESS:
            return {loading: false, success: true}
        case THPSHIPMENT_UPDATECOST_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_UPDATECOST_RESET:
            return {success: false}
        default: return state
    }
}

export const thpShipmentScannedReducer = (state={}, action) => {
    switch(action.type){
        case THPSHIPMENT_SCANNED_REQUEST:
            return {loading: true}
        case THPSHIPMENT_SCANNED_SUCCESS:
            return {loading: false, success: true}
        case THPSHIPMENT_SCANNED_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_SCANNED_RESET:
            return {success: false}
        default: return state
    }
}

export const thpShipmentScannedListReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case THPSHIPMENT_GET_SCANNED_REQUEST:
            return {loading: true}
        case THPSHIPMENT_GET_SCANNED_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload
            }
        case THPSHIPMENT_GET_SCANNED_FAIL:
            return {loading: false, error: action.payload}
        case THPSHIPMENT_GET_SCANNED_RESET:
            return {}
        default: return state
    }
}

export const thpShipAndScanReducer = (state={shipments:[]}, action) => {
    switch(action.type){
        case THPSHIPMENT_SHIPANDSCAN_REQUEST:
            return {loading: true}
        case THPSHIPMENT_SHIPANDSCAN_SUCCESS:
            return {
                loading: false, 
                shipments: action.payload,
            }
        case THPSHIPMENT_SHIPANDSCAN_FAIL:
            return {loading: false, error: action.payload}
        default: return state
    }
}