import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useParams, useLocation } from 'react-router-dom'
import SearchBox from '../components/SearchBox'
import Pagination from '../components/Pagination'
import {
  HStack,
  Box,
  Button,
  Spacer,
  Spinner,
  Divider,
  Select,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
  Heading,
} from '@chakra-ui/react'

import { listUser } from '../actions/userActions'

const UserListScreen = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams()
    const keyword = params.keyword || ''
    const pageNumber = params.pageNumber || 1
    const viewQty = [50, 100, 150, 200]
    const [itemCount, setItemCount] = useState(50)
    const [sortBy, setSortBy] = useState('')
    const {loading, error, users, pages, page} = useSelector(state=>state.user.List)
    const {user} = useSelector(state=>state.login)
    useEffect(()=>{
        dispatch(listUser(pageNumber, itemCount, keyword, sortBy))        
    },[dispatch, pageNumber, itemCount, keyword, sortBy])

    return (
        <Box>
            <Center mb='3'><Heading>Users</Heading></Center>
            <Divider />
            <Box fontSize={'xs'} h='12px' ml='4'>Search: name, email, phone, agent code, sale code...</Box>
            <HStack spacing='4' my='4'>
                <Box>
                    {loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                </Box>
                
                <Box>
                    <SearchBox />
                </Box>
                <Box>
                    <Link to='/user/register'>
                        <Button  size='sm' colorScheme='green' >Add User</Button>
                    </Link>
                </Box>
                <Box>
                    <Select placeholder='All' size='sm' w='200px' onChange={(e)=> setSortBy(e.target.value)}>
                        <option value='agent'>Agents</option>
                        <option value='sale'>Sales</option>
                        <option value='user'>Users</option>
                        <option value='deleted'>Deleted</option>
                        <option value='admin' disabled={user && user.accountType !== 'admin'}>Admins</option>
                    </Select>
                </Box>
                <Spacer />
                <Box>
                    <Select placeholder='Select' onChange={e=>setItemCount(e.target.value)} size='sm'>
                    {viewQty.map((v,i)=>(
                        <option key={i} value={v}>{v}</option>
                    ))}
                    </Select>
                </Box>
                <Box>
                    <Pagination keyword = {keyword} pages={pages} page={page} path='user' />
                </Box>
            </HStack>
            
            <Divider />

            {error && 
            <Alert status='error' variant='left-accent'>
            <AlertIcon />
            <AlertTitle mr={2}>Error getting user list</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
            </Alert>
            }

            {users && users.length === 0 ? 
                <Alert status='info' variant='left-accent'>
                <AlertIcon />
                <AlertTitle mr={2}>No user found</AlertTitle>
                </Alert>    
                :
                <Table size='sm' variant='striped' colorScheme='yellow' my='4'>
                    <Thead>
                        <Tr>
                        <Th>No</Th>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Phone</Th>
                        <Th>Type</Th>
                        <Th>Balance</Th>
                        <Th>Rate</Th>
                        <Th>Sale</Th>
                        <Th>Options</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {users && users.map((u,index)=>(
                        <Tr key={index}>
                            <Td>{pageNumber === 1 ? (index+1) : (((Number(pageNumber)-1) * itemCount) + (index+1))}</Td>
                            <Td>{u.name}</Td>
                            <Td>{u.email}</Td>
                            <Td>{u.phone}</Td>
                            <Td>{u.accountType}</Td>
                            <Td>{u.accountType === 'agent' ? u.balance : '-'}</Td>
                            <Td>{u.accountType === 'agent' ? u.rate.name : '-'}</Td>
                            <Td>{u.accountType === 'agent' ? u.sale.name : '-'}</Td>
                            <Td>
                                {user && user.isAdmin && <Link to={`/user/${u._id}`} state={{redirect: location.pathname}}>Detail</Link> }   
                            </Td>
                        </Tr>
                        ))}
                    </Tbody>
                </Table>
            }
        </Box>
    )
}

export default UserListScreen