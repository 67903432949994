import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Table, Thead, Th, Tbody, Tr, Td, Center, Heading, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const MyBalanceLogScreen = () => {

    const { user } = useSelector(state=>state.login)

    const getInvId = (note) => {
        const a = note.split(' ')
        const Id = a[a.length - 1]
        return Id
    }
    return (
        <div>
            <Center><Heading>Balance Log - Current Balance {user.balance}</Heading></Center>
            <Box my={5}>
                <Table size='sm'>
                <Thead>
                    <Tr>
                    <Th>No</Th>
                    <Th>Amount</Th>
                    <Th>Type</Th>
                    <Th>User</Th>
                    <Th>Note</Th>
                    <Th>Date</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {user.balanceLog.map((bl,idx)=>(
                    <Tr key={idx}>
                        <Td>{idx+1}</Td>
                        <Td>{bl.split(',')[0].split('-')[1]}</Td>
                        <Td bg={bl.split(',')[1].split('-')[1] === 'added' ? 'green.200' : bl.split(',')[1].split('-')[1] === 'deduct' ? 'red.200' : ''}>{bl.split(',')[1].split('-')[1]}</Td>
                        <Td>{bl.split(',')[2].split('-')[1]}</Td>
                        <Td>
                        {bl.split(',')[3].split('-')[1].includes('inv') ? <Link to={`/myinvoices/${getInvId(bl.split(',')[3].split('-')[1])}`} target='_blank'>
                            <Text as='u' color='blue.400'>{bl.split(',')[3].split('-')[1]}</Text>
                        </Link> : <Text>{bl.split(',')[3].split('-')[1]}</Text>}
                        </Td>
                        <Td>{bl.split(',')[4].split('-').slice(1).join('-')}</Td>
                    </Tr>
                    ))}
                </Tbody>
                </Table>
            </Box>
        </div>
    )
}

export default MyBalanceLogScreen