import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux'
import { compose } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { 
    adminDashboardReducer, 
    listDashboardReducer, 
    saleDashboardReducer, 
    agentDashboardReducer, 
    deleteDashboardReducer,
    shippingChartReducer,
} from './reducers/dashboardReducers'
import { 
    accountingSaleReducer,
    accountingSaleDetailReducer,
} from './reducers/accountingReducers/accountingReducers'
import {
    registerReducer,
    loginReducer,
    userListReducer,
    userEditReducer,
    userDeleteReducer,
    userByIdReducer,
    userSalesNamesReducer,
    userForgotPasswordReducer,
    userResetPasswordReducer,
    userAgentListReducer,
    userSaleListReducer,
    userMyReducer,
    userMyPasswordReducer,
    userAddBalanceReducer,
    userMyAgentsReducer,
    userMyAgentDetailReducer,
    userConfirmPasswordReducer,
    userAddThpDiscountReducer,
    userDeleteThpDiscountReducer,
} from './reducers/userReducers'
import {
    addressCreateReducer,
    addressListReducer,
    addressByIdReducer,
    addressEditReducer,
    addressDeleteReducer,
    addressFromReducer,
    addressFromNamesReducer,
    DHLAddressValidateReducer
} from './reducers/addressReducers'
import {
    rateListReducer,
    rateByIdReducer,
    rateCreateReducer,
    rateDeleteReducer,
    rateEditReducer,
    rateNamesReducer,
    rateByAgentReducer,
    rateMyReducer,
    rateAgentsReducer,
    rateTransferReducer,
    rateStatusReducer,
    rateShipmentQtyReducer,
    rateUserReducer,
    rateDiscountReducer,
} from './reducers/rateReducers'
import {
    agentRateCreateReducer,
    agentRateListReducer,
    agentRateByIdReducer,
    agentRateEditReducer,
    agentRateDeleteReducer,
    agentRateNamesReducer,
    agentRateTransferReducer,
    agentRateAgentNamesReducer
} from './reducers/agentRateReducers'
import {    
    preshipmentCreateReducer,
    preshipmentListReducer,
    preshipmentCancelReducer,
    preshipmentByIdReducer,
    preshipmentAddRemoveItemReducer,
    preshipmentItemQtyReducer,
    preshipmentBoxsizeReducer,
    preshipmentBoxEditReducer,
    preshipmentSaveContactReducer,
    preshipmentSaveShipperReducer,
    preshipmentSaveDHLShipperReducer,
    preshipmentEditReducer,
    preshipmentNoteReducer,
    preshipmentCurrencyReducer,
    shipmentCreateReducer,
    shipmentGetCostReducer,
    shipmentListReducer,
    shipmentDeletedListReducer,
    shipmentMyReducer,
    shipmentByIdReducer,
    shipmentCancelReducer,
    shipmentAddNoteReducer,
    shipmentAddChargeReducer,
    shipmentDeleteNoteReducer,
    shipmentGetContactsForPreshipReducer,
    shipmentForInvoiceReducer,
    shipmentTrackingReducer,
    shipmentReturnedReducer,
    shipmentScannedReducer,
    shipmentScannedListReducer,
    shipmentSaleBillingReducer,
    shipmentAgentBillingReducer,
    shipmentMyAgentBillingReducer,
    shipAndScanReducer,
    shipmentDailyReducer,
    userShipCountReducer,
    shipmentChangeAgentReducer,
    shipmentStatusReducer,
    eachShipmentStatusReducer,
    saleShipmentStatusReducer,
    myShipmentStatusReducer,
    shipmentUpdateCostReducer,
    shipmentUpdateAWBReducer,
    preshipmentItemSortingReducer,
    preshipmentVplusReducer,
    preshipmentSpecialRouteReducer,
    shipmentVplusReducer,
    preshipmentShipTypeReducer,
} from './reducers/shipmentReducers'
import {
    itemListReducer,
    itemCreateReducer,
    itemEditReducer,
    itemDeleteReducer,
    itemByIdReducer,
} from './reducers/itemReducers'
import {
    shipCountListReducer,
} from './reducers/shipCountReducers'
import {
    invoiceListReducer,
    invoiceCreateReducer,
    invoiceByIdReducer,
    invoiceMyReducer,
    invoiceDeleteReducer,
    invoiceAddShipmentReducer,
    invoiceRemoveShipmentReducer,
} from './reducers/invoiceReducers'
import {
    paymentListReducer,
    paymentCreateReducer,
    paymentByIdReducer,
    paymentApproveReducer,
    paymentDeleteReducer,
    paymentMyReducer,
} from './reducers/paymentReducers'
import {
    balanceLogReducer,
    clearDataReducer,
    getDataReducer,
    userLogReducer,
    usRemoteAreaReducer,
    setRemoteRateReducer,
    getRemoteRateReducer,
} from './reducers/systemReducers'
import {
    thpShipmentByIdReducer,
    thpShipmentCreateReducer,
    thpShipmentListReducer,
    thpShipmentDeleteReducer,
    thpShipmentUpdateReducer,
    thpShipmentGetCostReducer,
    thpShipmentMyListReducer,
    thpShipmentContactReducer,
    thpShipmentChargesReducer,
    thpShipmentUpdateCostReducer,
    thpShipmentScannedReducer,
    thpShipmentScannedListReducer,
    thpShipAndScanReducer,
} from './reducers/thailand_post_reducers/thpShipmentReducers'
import {
    thpRateCreateReducer,
    thpRateListReducer,
    thpRateDeleteReducer,
} from './reducers/thailand_post_reducers/thpRateReducers'
import {
    thpShipmentsForInvoiceReducer,
    thpInvoiceListReducer,
    thpInvoiceCreateReducer,
    thpInvoiceByIdReducer,
    thpInvoiceDeleteReducer,
    thpInvoiceAddShipmentReducer,
    thpInvoiceRemoveShipmentReducer,
    thpInvoiceMyListReducer,
} from './reducers/thailand_post_reducers/thpInvoiceReducers'
import {
    thpPaymentListReducer,
    thpPaymentCreateReducer,
    thpPaymentByIdReducer,
    thpPaymentApproveReducer,
    thpPaymentDeleteReducer,
    thpPaymentMyReducer,
} from './reducers/thailand_post_reducers/thpPaymentReducers'
import {
    dhlAccountCreateReducer,
    dhlAccountDeleteReducer,
    dhlAccountListReducer,
    dhlAccountUpdateReducer,

} from './reducers/dhlAccountReducers'
import {
    rejectNameCreateReducer,
    rejectNameDeleteReducer,
    rejectNameListReducer,
    rejectNameUpdateReducer,

} from './reducers/rejectNameReducers'
import {
    remoteAreaCreateReducer,
    remoteAreaDeleteReducer,
    remoteAreaListReducer,
    remoteAreaUpdateReducer,

} from './reducers/remoteAreaReducers'
import {
    chargesListReducer,
    chargesCreateReducer,
    chargesDeleteReducer,
    chargesUpdateReducer
} from './reducers/chargesReducers'
import {
    countryListReducer,
    countryCreateReducer,
    countryDeleteReducer,
    countryUpdateReducer
} from './reducers/countryReducers'
import {
    zoneListReducer,
    zoneCreateReducer,
    zoneDeleteReducer,
    zoneUpdateReducer,
    zoneRateReducer
} from './reducers/zoneReducers'
import {
    domesticRateCreateReducer,
    domesticRateListReducer,
    domesticRateDeleteReducer,
} from './reducers/domestic_reducers/domesticRateReducers'
import {
    domesticShipmentCreateReducer,
    domesticShipmentListReducer,
    domesticShipmentByIdReducer,
    domesticShipmentDeleteReducer,
    domesticShipmentUpdateReducer,
    domesticShipmentGetCostReducer,
    domesticShipmentMyListReducer,
    domesticShipmentContactReducer,
    domesticShipmentChargesReducer,
    domesticShipmentUpdateCostReducer,
    domesticShipmentScannedReducer,
    domesticShipmentScannedListReducer,
    domesticShipAndScanReducer,
} from './reducers/domestic_reducers/domesticShipmentReducers'
import {
    domesticShipmentsForInvoiceReducer,
    domesticInvoiceListReducer,
    domesticInvoiceMyListReducer,
    domesticInvoiceCreateReducer,
    domesticInvoiceByIdReducer,
    domesticInvoiceDeleteReducer,
    domesticInvoiceAddShipmentReducer,
    domesticInvoiceRemoveShipmentReducer,
} from './reducers/domestic_reducers/domesticInvoiceReducers'
import { 
    domesticPaymentListReducer,
    domesticPaymentMyReducer,
    domesticPaymentCreateReducer,
    domesticPaymentByIdReducer,
    domesticPaymentApproveReducer,
    domesticPaymentDeleteReducer,

} from './reducers/domestic_reducers/domesticPaymentReducers'

const appReducer = combineReducers({
    
    shipment: combineReducers({
        Create: shipmentCreateReducer,
        GetCost: shipmentGetCostReducer,
        List: shipmentListReducer,
        DeletedList: shipmentDeletedListReducer,
        My: shipmentMyReducer,
        ById: shipmentByIdReducer,
        Cancel: shipmentCancelReducer,
        AddNote: shipmentAddNoteReducer,
        UpdateCost: shipmentUpdateCostReducer,
        UpdateAWB: shipmentUpdateAWBReducer,
        AddCharge: shipmentAddChargeReducer,
        DeleteNote: shipmentDeleteNoteReducer,
        GetContactsForPreship: shipmentGetContactsForPreshipReducer,
        ForInvoice: shipmentForInvoiceReducer,
        Tracking: shipmentTrackingReducer,
        Returned: shipmentReturnedReducer,
        Scanned: shipmentScannedReducer,
        ScannedList: shipmentScannedListReducer,
        SaleBilling: shipmentSaleBillingReducer,
        AgentBilling: shipmentAgentBillingReducer,
        MyAgentBilling: shipmentMyAgentBillingReducer,        
        Daily: shipmentDailyReducer,
        ChangeAgent: shipmentChangeAgentReducer,
        Status: shipmentStatusReducer,
        Vplus: shipmentVplusReducer,
        ShipAndScan: shipAndScanReducer,
        SaleShipmentStatus: saleShipmentStatusReducer,
        EachShipmentStatus: eachShipmentStatusReducer,
        MyShipmentStatus: myShipmentStatusReducer,
        UserShipCount: userShipCountReducer,
        ShipCountList: shipCountListReducer,
    }),
    invoice: combineReducers({
        List: invoiceListReducer,
        Create: invoiceCreateReducer,
        ById: invoiceByIdReducer,
        My: invoiceMyReducer,
        Delete: invoiceDeleteReducer,
        AddShipment: invoiceAddShipmentReducer,
        RemoveShipment: invoiceRemoveShipmentReducer,
    }),
    payment: combineReducers({
        List: paymentListReducer,
        Create: paymentCreateReducer,
        ById: paymentByIdReducer,
        Approve: paymentApproveReducer,
        Delete: paymentDeleteReducer,
        My: paymentMyReducer,
    }),
    system: combineReducers({
        adminDashboard: adminDashboardReducer,
        listData: listDashboardReducer,
        deleteData: deleteDashboardReducer,
        saleDashboard: saleDashboardReducer,
        agentDashboard: agentDashboardReducer,
        clearData: clearDataReducer,
        register: registerReducer,
        balanceLog: balanceLogReducer,
        userLog: userLogReducer,
        shippingChart: shippingChartReducer,
        getData: getDataReducer,
        usRemoteArea: usRemoteAreaReducer,
        setRemoteRate: setRemoteRateReducer,
        getRemoteRate: getRemoteRateReducer,
    }),
    accounting: combineReducers({
        accountingSale: accountingSaleReducer,
        accountingSaleDetail: accountingSaleDetailReducer,
    }),
    login: loginReducer,
    user: combineReducers({
        List: userListReducer,
        Edit: userEditReducer,
        Delete: userDeleteReducer,
        ById: userByIdReducer,
        SalesNames: userSalesNamesReducer,
        ForgotPassword: userForgotPasswordReducer,
        ResetPassword: userResetPasswordReducer,
        AgentList: userAgentListReducer,
        SaleList: userSaleListReducer,
        My: userMyReducer,
        MyPassword: userMyPasswordReducer,
        AddBalance: userAddBalanceReducer,
        MyAgents: userMyAgentsReducer,
        MyAgentDetail: userMyAgentDetailReducer,
        ConfirmPassword: userConfirmPasswordReducer,
        AddThpDiscount: userAddThpDiscountReducer,
        DeleteThpDiscount: userDeleteThpDiscountReducer,
    }),
    address: combineReducers({
        Create: addressCreateReducer,
        List: addressListReducer,
        ById: addressByIdReducer,
        Edit: addressEditReducer,
        Delete: addressDeleteReducer,
        From: addressFromReducer,
        FromNames: addressFromNamesReducer,
        Validate: DHLAddressValidateReducer,
    }),
    item: combineReducers({
        Create: itemCreateReducer,
        List: itemListReducer,
        ById: itemByIdReducer,
        Edit: itemEditReducer,
        Delete: itemDeleteReducer,
    }),
    rate: combineReducers({
        List: rateListReducer,
        ById: rateByIdReducer,
        Create: rateCreateReducer,
        Delete: rateDeleteReducer,
        Edit: rateEditReducer,
        Names: rateNamesReducer,
        ByAgent: rateByAgentReducer,
        My: rateMyReducer,
        Agents: rateAgentsReducer,
        Transfer: rateTransferReducer,
        Status: rateStatusReducer,
        ShipmentQty: rateShipmentQtyReducer,
        User: rateUserReducer,
        Discount: rateDiscountReducer,
    }),
    agentRate: combineReducers({
        Create: agentRateCreateReducer,
        List: agentRateListReducer,
        ById: agentRateByIdReducer,
        Edit: agentRateEditReducer,
        Delete: agentRateDeleteReducer,
        Names: agentRateNamesReducer,
        Transfer: agentRateTransferReducer,
        AgentNames: agentRateAgentNamesReducer,
    }),   
    preshipment: combineReducers({
        Create: preshipmentCreateReducer,
        List: preshipmentListReducer,
        Cancel: preshipmentCancelReducer,
        ById: preshipmentByIdReducer,
        AddRemoveItem: preshipmentAddRemoveItemReducer,
        ItemQty: preshipmentItemQtyReducer,
        Boxsize: preshipmentBoxsizeReducer,
        BoxEdit:  preshipmentBoxEditReducer,
        SaveContact: preshipmentSaveContactReducer,
        SaveShipper: preshipmentSaveShipperReducer,
        SaveDhlShipper: preshipmentSaveDHLShipperReducer,
        Edit: preshipmentEditReducer,
        Note: preshipmentNoteReducer,
        Currency: preshipmentCurrencyReducer,
        ItemSorting: preshipmentItemSortingReducer,
        Vplus: preshipmentVplusReducer,
        SpecialRoute: preshipmentSpecialRouteReducer,
        ShipType: preshipmentShipTypeReducer,
    }),    
    thailandPost: combineReducers({
        ShipmentCreate: thpShipmentCreateReducer,
        ShipmentList: thpShipmentListReducer,
        ShipmentById: thpShipmentByIdReducer,
        ShipmentDelete: thpShipmentDeleteReducer,
        ShipmentUpdate: thpShipmentUpdateReducer,
        ShipmentGetCost: thpShipmentGetCostReducer,
        ShipmentCharges: thpShipmentChargesReducer,
        ShipmentUpdateCost: thpShipmentUpdateCostReducer,
        MyList: thpShipmentMyListReducer,
        Contact: thpShipmentContactReducer,
        Scanned: thpShipmentScannedReducer,
        ScannedList: thpShipmentScannedListReducer,        
        ShipAndScan: thpShipAndScanReducer,
    
        RateCreate: thpRateCreateReducer,
        RateList: thpRateListReducer,
        RateDelete: thpRateDeleteReducer,
    
        ShipmentsForInvoice: thpShipmentsForInvoiceReducer,
        InvoiceList: thpInvoiceListReducer,
        InvoiceCreate: thpInvoiceCreateReducer,
        InvoiceById: thpInvoiceByIdReducer,
        InvoiceDelete: thpInvoiceDeleteReducer,
        InvoiceAddShipment: thpInvoiceAddShipmentReducer,
        InvoiceRemoveShipment: thpInvoiceRemoveShipmentReducer,
        InvoiceMyList: thpInvoiceMyListReducer,
    
        PaymentList: thpPaymentListReducer,
        PaymentCreate: thpPaymentCreateReducer,
        PaymentById: thpPaymentByIdReducer,
        PaymentApprove: thpPaymentApproveReducer,
        PaymentDelete: thpPaymentDeleteReducer,
        PaymentMy: thpPaymentMyReducer,
    }),
    domestic: combineReducers({
        ShipmentCreate: domesticShipmentCreateReducer,
        ShipmentList: domesticShipmentListReducer,
        ShipmentById: domesticShipmentByIdReducer,
        ShipmentDelete: domesticShipmentDeleteReducer,
        ShipmentUpdate: domesticShipmentUpdateReducer,
        ShipmentGetCost: domesticShipmentGetCostReducer,
        ShipmentCharges: domesticShipmentChargesReducer,
        ShipmentUpdateCost: domesticShipmentUpdateCostReducer,
        MyList: domesticShipmentMyListReducer,
        Contact: domesticShipmentContactReducer,
        Scanned: domesticShipmentScannedReducer,
        ScannedList: domesticShipmentScannedListReducer,        
        ShipAndScan: domesticShipAndScanReducer,
    
        RateCreate: domesticRateCreateReducer,
        RateList: domesticRateListReducer,
        RateDelete: domesticRateDeleteReducer,
    
        ShipmentsForInvoice: domesticShipmentsForInvoiceReducer,
        InvoiceList: domesticInvoiceListReducer,
        InvoiceCreate: domesticInvoiceCreateReducer,
        InvoiceById: domesticInvoiceByIdReducer,
        InvoiceDelete: domesticInvoiceDeleteReducer,
        InvoiceAddShipment: domesticInvoiceAddShipmentReducer,
        InvoiceRemoveShipment: domesticInvoiceRemoveShipmentReducer,
        InvoiceMyList: domesticInvoiceMyListReducer,
    
        PaymentList: domesticPaymentListReducer,
        PaymentCreate: domesticPaymentCreateReducer,
        PaymentById: domesticPaymentByIdReducer,
        PaymentApprove: domesticPaymentApproveReducer,
        PaymentDelete: domesticPaymentDeleteReducer,
        PaymentMy: domesticPaymentMyReducer,
    }),
    dhlAccount: combineReducers({
        List: dhlAccountListReducer,
        Create: dhlAccountCreateReducer,
        Update: dhlAccountUpdateReducer,
        Delete: dhlAccountDeleteReducer,
    }),   
    rejectName: combineReducers({
        List: rejectNameListReducer,
        Create: rejectNameCreateReducer,
        Update: rejectNameUpdateReducer,
        Delete: rejectNameDeleteReducer,
    }),    
    remoteArea: combineReducers({
        List: remoteAreaListReducer,
        Create: remoteAreaCreateReducer,
        Update: remoteAreaUpdateReducer,
        Delete: remoteAreaDeleteReducer,
    }),
    charges: combineReducers({
        List: chargesListReducer,
        Create: chargesCreateReducer,
        Update: chargesUpdateReducer,
        Delete: chargesDeleteReducer,
    }),
    country: combineReducers({
        List: countryListReducer,
        Create: countryCreateReducer,
        Update: countryUpdateReducer,
        Delete: countryDeleteReducer,
    }),
    zone: combineReducers({
        List: zoneListReducer,
        Create: zoneCreateReducer,
        Update: zoneUpdateReducer,
        Delete: zoneDeleteReducer,
        Rate: zoneRateReducer,
    }),
})

const rootReducer = (state, action) => {
    if( action.type === 'USER_LOGOUT'){
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

const userInfoFromStroage = localStorage.getItem('userInfo') 
    ? JSON.parse(localStorage.getItem('userInfo')) 
    : null
    
const initialState = { 
    login: {user: userInfoFromStroage}, 
}
const middleware = [thunk]

//const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware)))
const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store
