import axios from 'axios'
import {
    PAYMENT_APPROVE_FAIL,
    PAYMENT_APPROVE_REQUEST,
    PAYMENT_APPROVE_SUCCESS,
    PAYMENT_BYID_FAIL,
    PAYMENT_BYID_REQUEST,
    PAYMENT_BYID_SUCCESS,
    PAYMENT_CREATE_FAIL,
    PAYMENT_CREATE_REQUEST,
    PAYMENT_CREATE_SUCCESS,
    PAYMENT_DELETE_FAIL,
    PAYMENT_DELETE_REQUEST,
    PAYMENT_DELETE_SUCCESS,
    PAYMENT_LIST_FAIL,
    PAYMENT_LIST_REQUEST, 
    PAYMENT_LIST_SUCCESS,
    PAYMENT_MY_FAIL,
    PAYMENT_MY_REQUEST,
    PAYMENT_MY_SUCCESS,
} from '../constants/paymentConstants'


export const listPayments = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: PAYMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/payments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: PAYMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: PAYMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const filterPayments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: PAYMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/payments/filter`, {filterData}, config)
        dispatch({type: PAYMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: PAYMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myPayments = (pageNumber = '', itemCount = '', keyword = '', date = '') => async (dispatch, getState) => {
    try {
        dispatch({type: PAYMENT_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/payments/mypayments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}&date=${date}`, config)
        dispatch({type: PAYMENT_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: PAYMENT_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myFilterPayments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: PAYMENT_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/payments/myfilteredpayments`, {filterData}, config)
        dispatch({type: PAYMENT_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: PAYMENT_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const createPayment = (paymentData) => async (dispatch, getState) => {
    try {
        dispatch({type: PAYMENT_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/payments`, {paymentData}, config)
        dispatch({type: PAYMENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: PAYMENT_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getPaymentById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: PAYMENT_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/payments/${id}`, config)
        dispatch({type: PAYMENT_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: PAYMENT_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const approvePayment = (paymentId, invoiceData) => async (dispatch, getState) => {
    try {
        dispatch({type: PAYMENT_APPROVE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/payments/${paymentId}`, {invoiceData}, config)
        dispatch({type: PAYMENT_APPROVE_SUCCESS})
    } catch (error) {
        dispatch({
            type: PAYMENT_APPROVE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deletePayment = (paymentId) => async (dispatch, getState) => {
    try {
        dispatch({type: PAYMENT_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/payments/${paymentId}`, config)
        dispatch({type: PAYMENT_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: PAYMENT_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}