import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Stack,
    Button,
    Center,
    Modal,
    Divider,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Spinner,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
} from '@chakra-ui/react'
import { SHIPMENT_TRACKING_RESET } from '../constants/shipmentConstants'
import { trackShipment } from '../actions/shipmentActions'

const ShipmentTrackingModal = ({awb, color, variant, text, width}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const {loading, error, trackingDetail} = useSelector(state=>state.shipment.Tracking)

    useEffect(()=>{
        if(show){
            dispatch(trackShipment(awb))
        }
        
        return()=>{
            dispatch({type: SHIPMENT_TRACKING_RESET})
        }
    },[dispatch, awb, show])

    
    const handleClose = () => {
        setShow(false)
    }


    return (
        <>
            <Button size='sm' w={width} colorScheme={color}  onClick={()=>setShow(true)} >{text}</Button>

            <Modal isOpen={show} onClose={handleClose} size='3xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Tracking Detail - AWB {awb}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Box>
                        {error && 
                            <Alert status='error' variant='left-accent'>
                            <AlertIcon />
                            <AlertTitle mr={2}>Error tracking shipment.</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        }
                    </Box>
                    <Stack spacing={'4'}> 
                        {trackingDetail && trackingDetail.length === 0 ? 
                        <Box>Shipment Created</Box>   : 
                        <Box>
                            {trackingDetail && trackingDetail.slice(0).reverse().map((e,i)=>(
                                <Box key={i} shadow='lg' p='2' >
                                    <Center fontSize={'2xl'} fontWeight={'500'} mb='4'>
                                    {new Date(e.date).toLocaleString('en', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}
                                    </Center>
                                    {e.evs.reverse().map((d,idx)=>(
                                        <Box key={idx} mb='3'>
                                            <Box>{d.description}</Box>
                                            <Box fontSize={'sm'} mb='2' >{d.time}, {d.location}</Box>
                                            <Divider />
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                        </Box> 
                        }
                    </Stack>
                </ModalBody>

            </ModalContent>
            </Modal>
        </>
    )
}

ShipmentTrackingModal.defaultProps = {
    color: "orange",
    variant: "",
    text: "Track Shipment",
    width: "150px"
}

export default ShipmentTrackingModal