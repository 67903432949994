import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Stack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Select,
    Input,
    Box,
    Spinner,
} from '@chakra-ui/react'
import { createPayment } from '../actions/paymentActions'
import { PAYMENT_CREATE_RESET } from '../constants/paymentConstants'
import Error from '../components/Error'

const PaymentAddModal = ({invoiceId, totalAmount, balance, isPaid, agentBalance}) => {
    const dispatch = useDispatch()
    const [payMethod, setPayMethod] = useState('')
    const [amount, setAmount] = useState(0)
    const [note, setNote] = useState('')
    const paymentMethodsWithAgentBalance = [
        'Myanmar Bank',
        'Thai Bank',
        'Yangon Cash',
        'Hpa-an Cash',
        'Myawady Cash',
        'Maesot Cash',
        'Agent Balance'
        ]
    const paymentMethods = [
        'Myanmar Bank',
        'Thai Bank',
        'Yangon Cash',
        'Hpa-an Cash',
        'Myawady Cash',
        'Maesot Cash',
        ]
    const [show, setShow] = useState(false)
    const {loading, error, success} = useSelector(state=>state.payment.Create)

    useEffect(()=>{
        if(success){
            setAmount(0)
            setNote('')
            setPayMethod('')
            setShow(false)
        }
        return()=>{
            setAmount(0)
            setNote('')
            setPayMethod('')
            dispatch({type: PAYMENT_CREATE_RESET})
        }
    },[success, dispatch])

    const handleClose = () => {
        setAmount(0)
        setNote('')
        setPayMethod('')
        setShow(false)
    }

    const handleSubmit = () => {
        var newPayment = {}        
        if(payMethod === 'Agent Balance'){
            newPayment = {
                fromBalance: true,
                invoiceId,
                paidMethod: payMethod,
                amount: agentBalance >= balance ? balance : agentBalance,
                note: note !== '' ? note : 'Pay from agent balance'
            }
        } else {
            newPayment = {
                fromBalance: false,
                invoiceId,
                paidMethod: payMethod,
                amount,
                note,
            }
        }
        dispatch(createPayment(newPayment))
    }


    return (
        <>
            <Button size='sm' disabled={isPaid} w='150px' colorScheme={'blue'} onClick={()=>setShow(true)} >Make Payment</Button>

            <Modal isOpen={show} onClose={handleClose} size='3xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Box>
                        Invoice Total Amount - {totalAmount.toLocaleString()}
                    </Box>
                    <Box>
                        Invoice Balance - {balance.toLocaleString()}
                    </Box>
                    <Box>
                        Agent Account Balance - {agentBalance.toLocaleString()}
                    </Box>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Box>
                        {error && <Error error={error} />}
                    </Box>
                    <Stack spacing={'3'}>  
                        <Stack spacing={'0'}>
                            <Box>Methods</Box>
                            <Select size='sm' placeholder='Select' onChange={(e)=>setPayMethod(e.target.value)} >
                            {agentBalance !== 0 ? 
                                paymentMethodsWithAgentBalance.map((p,i)=>(
                                    <option key={i} value={p}>{p}</option>
                                )) :
                                paymentMethods.map((p,i)=>(
                                    <option key={i} value={p}>{p}</option>
                                )) 
                            }
                            </Select>
                        </Stack>
                        
                        
                        <Stack spacing={'0'}>
                            <Box>Amount</Box>
                            {payMethod === 'Agent Balance' ?
                            <Input 
                                type={'number'}
                                size='sm'
                                readOnly
                                value={agentBalance >= balance ? balance : agentBalance}
                            />
                            :
                            <Input 
                                type={'number'}
                                size='sm'
                                value={amount}
                                onChange={e=>setAmount(e.target.value)}
                            />
                            }
                        </Stack>

                        <Stack spacing={'0'}>
                            <Box>Note</Box>
                            <Input 
                                as={'textarea'}
                                h='50px'
                                size='sm'
                                value={note}
                                onChange={e=>setNote(e.target.value)}
                            />
                        </Stack>

                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button size='sm' w='150px' colorScheme={'green'} onClick={handleSubmit} >Submit</Button>
                </ModalFooter>
            </ModalContent>
            </Modal>
        </>
    )
}

export default PaymentAddModal