import React, {useState, useEffect} from 'react'
import { getCountryList } from '../actions/countryActions'
import { useDispatch, useSelector } from 'react-redux'
import Error from './Error'

import {
    Box,
    Text,
    Center,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    FormControl,
    FormLabel,
    Input,
    Spinner,
  } from '@chakra-ui/react'
  

const CountryZoneView = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState('')
  const [newZonePrint, setNewZonePrint] = useState([])
  const { loading, countries, success, error } = useSelector(state=>state.country.List)
  useEffect(()=>{
    dispatch(getCountryList())
  },[dispatch])
  useEffect(()=>{
    if(success){
      setNewZonePrint([...countries])
    }
    // eslint-disable-next-line
  },[success])


  function capitalize(s){
    if(s.length === 0){return}
    return s[0].toUpperCase() + s.slice(1);
  }
  const handleSearchCountry = (e) => {
      if(e.key === 'Backspace' && search === ''){
        setNewZonePrint([...countries])
        return
      }
      const newList = countries.filter(function (str) { return str.name.includes(capitalize(search)) })
      setNewZonePrint([...newList])
  }
  return (
    <Box m='auto' w='500px' maxW='100%'>
        <Center><Text fontSize='3xl'>Country and Zones list</Text> {loading && <Spinner />}</Center>
        {error && <Error error={error} />}
        <Box my='4'>
            <FormControl mb='4'>
              <FormLabel fontSize='2xl' htmlFor='country-search'>Search</FormLabel>                
                  <Input 
                    size='sm' 
                    id='country-search' 
                    type='text' 
                    value={search}
                    onChange={(e)=>setSearch(e.target.value)}
                    onKeyDown={handleSearchCountry}
                    />                  
            </FormControl>

            <Table size='sm' variant='striped' colorScheme='yellow' my='4'>
                <Thead>
                    <Tr>
                        <Th>No</Th>
                        <Th>Country Name</Th>
                        <Th>Zone</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {newZonePrint.map((z,i)=>(
                        <Tr key={i}>
                            <Td>{i+1}</Td>
                            <Td>{z.name}</Td>
                            <Td>{z.zone}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Box>
    </Box>
  )
}

export default CountryZoneView