import React, {useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Spinner,
    Text,
    HStack,
    Stack,
    Spacer,
    Button,
    Center,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react'
import { 
  cancelPreshipment, 
  getPreshipment, 
} from '../../actions/shipmentActions'
import ConfirmDeleteModal from '../../components/ConfirmDeleteModal'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { PRESHIPMENT_CANCEL_RESET } from '../../constants/shipmentConstants'

import { Report, InvoiceDomestic, ItemListDomestic, Label, EditDetail, BillPrint } from '../ShipmentPreDetailFiles'
import Error from '../../components/Error'

const DomesticPreDetailScreen = () => {
    const params = useParams()
    const preId = params.id
    const [view, setView] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {loading, error, preshipment} = useSelector(state=>state.preshipment.ById)
    const {loading: loadingEdit, error: errorEdit, success: successEdit} = useSelector(state=>state.preshipment.Edit)
    const {success: successCancel, error: errorCancel} = useSelector(state=>state.preshipment.Cancel)
    const {loading: loadingItemRemove, error: errorItemRemove, success: successItemRemove} = useSelector(state=>state.preshipment.AddRemoveItem)
    const {success: successQty} = useSelector(state=>state.preshipment.ItemQty)
    const {loading: loadingBoxsize, error: errorBoxsize,  success: successBoxsize} = useSelector(state=>state.preshipment.Boxsize)
    const {success: successSaveContact} = useSelector(state=>state.preshipment.SaveContact)
    const {loading: loadingSaveShipper, error: errorSaveShipper, success: successSaveShipper} = useSelector(state=>state.preshipment.SaveShipper)
    const {success: successBoxEdit} = useSelector(state=>state.preshipment.BoxEdit)
    const {loading: loadingAgentList, error: errorAgentList} = useSelector(state=>state.user.AgentList)
    const {success: preNoteSuccess} = useSelector(state=>state.preshipment.Note)    
    const {loading: loadingContact, error: errorContact } = useSelector(state=>state.shipment.GetContactsForPreship)
    const {loading: loadingFromNames, error: errorFromNames} = useSelector(state=>state.address.FromNames)
    const {success: successCurrency} = useSelector(state=>state.preshipment.Currency)
    const {success: successSorting} = useSelector(state=>state.preshipment.ItemSorting)

    useEffect(()=>{
      if(successCancel){
        navigate('/preshipment')
      }      
    },[successCancel, navigate])

    useEffect(()=>{       
      dispatch(getPreshipment(preId))
      return()=>{
          dispatch({type: PRESHIPMENT_CANCEL_RESET})
      }

    },[dispatch, successEdit, preId, preNoteSuccess, successCurrency, successItemRemove, successBoxsize, successBoxEdit, successSaveContact, successSaveShipper, successQty, successSorting])


    return (
      <>        
        <Box  bg='#FED7D7' w='100%' h='55px' px='5%'  className=''>
            <HStack spacing={5}>
              <Stack spacing={'0'}>
                <Text fontSize='2xl'>{preshipment && preshipment.code}</Text>
                <Text fontSize='xs'>created by : {preshipment && preshipment.createdBy && preshipment.createdBy.name}</Text>
              </Stack>
              <Spacer />
              <Box>
                <img width='80' src='/thailand.svg' alt='DHL Logo' />
              </Box>
              <Spacer />
              <Box>
                <HStack>
                <Button size='lg' disabled={preshipment && preshipment.shipped} variant='ghost' onClick={()=>navigate(`/domesticshipment/add?preId=${preId}`)}>Ship</Button>                
                <Button size='lg' variant='ghost' onClick={()=>setView('items')}>Items</Button>
                <Button size='lg' variant='ghost' onClick={()=>setView('invoice')}>Invoice</Button>
                
                <Box>
                  <Menu >
                    <MenuButton as={Button} variant='ghost' rightIcon={<MdKeyboardArrowDown />}>
                    More Menu
                    </MenuButton>
                    <MenuList>
                      <MenuItem 
                        color='black'
                        onClick={()=>setView('edit')}
                        disabled={preshipment && preshipment.shipped}
                      >Edit</MenuItem>

                      <MenuItem 
                        color='black'
                        onClick={()=>setView('label')}
                        disabled={preshipment && preshipment.shipped}
                      >Label</MenuItem>

                      <MenuItem 
                        color='black'
                        onClick={()=>setView('bill')}
                      >Bill Print</MenuItem>

                      <MenuItem 
                        color='black'
                        onClick={()=>setView('report')}
                      >Report</MenuItem>
                         
                    </MenuList>
                  </Menu>
                </Box>
                <ConfirmDeleteModal disable={preshipment && preshipment.shipped} deleteFunction={cancelPreshipment} id={preId} width={'100px'} variant='ghost' size={'lg'} lefticon={null} />

                </HStack>
              </Box>
            </HStack>
        </Box>

        <Box>
          <Box>
            {(loading || 
              loadingItemRemove || 
              loadingBoxsize || 
              loadingEdit ||
              loadingSaveShipper ||
              loadingAgentList ||
              loadingContact ||
              loadingFromNames
            ) && <Center><Spinner label='Please wait...' speed='0.1s' thickness='5px' /></Center>}
          </Box>
          {error && <Error error={error} />}
          {errorEdit && <Error error={errorEdit} />}
          {errorItemRemove && <Error error={errorItemRemove} />}
          {errorAgentList && <Error error={errorAgentList} />}
          {errorBoxsize && <Error error={errorBoxsize} />}
          {errorCancel && <Error error={errorCancel} />}
          {errorSaveShipper && <Error error={errorSaveShipper} />}
          {errorContact && <Error error={errorContact} />}
          {errorFromNames && <Error error={errorFromNames} />}
        </Box>

        <Box w='90%' mx='auto' my='5'>         
            {            
              view === 'items' ?
                <ItemListDomestic preId={preId} preshipment={preshipment} /> :   
              view === 'edit' ?
                <EditDetail preId={preId} preshipment={preshipment} /> :
              view === 'invoice' ?
                <InvoiceDomestic preId={preId} preshipment={preshipment} /> :
              view === 'label' ? 
                <Label preshipment={preshipment} /> : 
              view === 'bill' ? 
                <BillPrint preshipment={preshipment} /> : 
              view === 'report' ? 
                <Report preshipment={preshipment} /> :
              view === '' && preshipment &&
              <ItemListDomestic preId={preId} preshipment={preshipment} />
            }
            
        </Box>
      </>
    )
}

export default DomesticPreDetailScreen