import React, {useState, useEffect, useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Stack,
    InputGroup,
    InputLeftAddon,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Spacer,
    Spinner,
    useToast,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    FormControl,
    Center,
    Box,
  } from '@chakra-ui/react'
import { MdSave, MdClose, MdAddBox } from 'react-icons/md'
import { AGENTRATE_CREATE_RESET } from '../constants/agentRateConstants'
import { createAgentRate } from '../actions/agentRateActions'
import Error from '../components/Error'
import parse from 'paste-from-excel'

const AgentRateAddModalNew = ({zone}) => {
    const dispatch = useDispatch()
    const toast = useToast()
    const [show, setShow] = useState(false)
    const [name, setName] = useState('')
    // const [agRate, setAgRate] = useState({
    //     rates: [
    //         {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
    //         {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
    //         {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
    //         {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
    //         {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
    //         {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
    //         {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
    //         {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
    //         {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},
    //         {'zone1': 0,'zone2': 0, 'zone3': 0, 'zone4': 0, 'zone5': 0, 'zone6': 0, 'zone7': 0, 'zone8': 0, 'zone9': 0},           
    //     ]
    // })
    const [agRate, setAgRate] = useState({
        rates: [
            zone.zObject,
            zone.zObject,
            zone.zObject,
            zone.zObject,
            zone.zObject,
            zone.zObject,
            zone.zObject,
            zone.zObject,
            zone.zObject,
            zone.zObject,      
        ]
    })
    //const zoneslist = ['zone1','zone2','zone3','zone4','zone5','zone6','zone7','zone8','zone9']
    const nameList = ['Doc', '0.5-5', '5.1-10','10.1-15','15.1-20','20.1-25','25.1-30','30.1-70','70.1-300','300.1-1000']
    //const kgList = ['doc', 'p0', 'p5', 'p10', 'p15', 'p20', 'p25', 'p30', 'p70', 'p300']

    const [isError, setIsError] = useState([])
    const {loading: createLoading, error: createError, success: createSuccess} = useSelector(state=>state.agentRate.Create)
    //const {loading: zoneLoading, error: zoneError, success: zoneSuccess, zones} = useSelector(state=>state.zone.List)
    //const {zone} = useSelector(state=>state.zone.Rate)


    const handlePaste = (e) => {
        return parse(e)
    }
    const handlePasteRate = (index, z, e, i) => {
        setAgRate((agRate)=>({
            ...agRate,
            rates: agRate.rates.map((item, i)=> index === i ? {...item, [z]: Number(e.target.value)} : item)
        }))
    }

    const handleSetStates = useCallback(()=>{        
        setName('')
        setIsError([])
        setAgRate({
            rates: [
                zone.zObject,
                zone.zObject,
                zone.zObject,
                zone.zObject,
                zone.zObject,
                zone.zObject,
                zone.zObject,
                zone.zObject,
                zone.zObject,
                zone.zObject,           
            ]
        })
        dispatch({type: AGENTRATE_CREATE_RESET})  
        setShow(false) 
    },[dispatch, zone])

    useEffect(()=>{
        if(createSuccess){
            toast({
                status: 'success',
                position: 'top',
                title: 'success !',
                duration: 2000,
                isClosable: true,
            })                       
        }
        return()=>{
            dispatch({type: AGENTRATE_CREATE_RESET})
        }
    },[createSuccess, toast, dispatch])

    const handleSubmit = () => {
        if(name === ''){
            toast({
                status: 'warning',
                position: 'top',
                title: 'Agent rate name is required !',
                duration: 2000,
                isClosable: true,
            })  
            return
        }
        var newAgentRate = {}
        newAgentRate.name = name        
        var kgList = ['doc', 'p0', 'p5', 'p10', 'p15', 'p20', 'p25', 'p30', 'p70', 'p300']
        for(let i = 0; i < agRate.rates.length ; i++){
            newAgentRate[kgList[i]]=agRate.rates[i]
        }
        //console.log(newAgentRate)
        dispatch(createAgentRate(newAgentRate))       
    }

    const handleClose = () => {
        handleSetStates()
        setShow(false)        
    }

    return (
    <>
        <Button size='sm' colorScheme='blue' onClick={()=>setShow(true)} leftIcon={<MdAddBox />}>Add New</Button>

        <Modal isOpen={show} onClose={handleClose}  size={'full'} >
        <ModalOverlay />
        {/* <ModalContent maxH="700px" maxW="1000px"> */}
        <ModalContent>
            <ModalHeader>{createLoading  ? <Spinner label='Please wait' speed='0.1s' thickness='5px' /> : 'Add New Agent Rate' }</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Stack spacing={4} >

                    {createError && <Error error={createError} />}

                    <InputGroup size='sm'>                        
                        <InputLeftAddon children='Rate Name' w='20%' minW='150px' />
                        <Input 
                            type='text' 
                            autoFocus
                            border={'1px'}
                            borderColor={'black'}
                            value={name} 
                            onChange={e=>setName(e.target.value)}
                            isInvalid={isError.indexOf('name') !== -1}
                            onKeyPress={()=>setIsError(isError.filter(x => x !== 'name'))}
                            maxLength={30}
                        />                        
                    </InputGroup>
                    <Box w={'100%'} overflow={'scroll'}>
                    <Table size='sm' variant='striped' colorScheme='yellow'>
                        <Thead>
                            <Tr>
                            <Th style={{width: '100px'}}>KG</Th>
                            {zone.zArray.map((za,i)=>(
                                <Th key={i} textAlign='center'>{za}</Th>
                            ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {agRate.rates.map((res, idx)=>(
                                <Tr key={idx}>
                                    <Td isNumeric><Box style={{width:'80px'}}>{nameList[idx]}</Box></Td>
                                    {zone.zArray.map((z, i)=>(
                                        <Td key={i} p='0.9'>
                                            <FormControl border='grey'>
                                                <Center>
                                                    <Input 
                                                        size='xs' 
                                                        w='60px'
                                                        textAlign='center' 
                                                        fontSize='14px' 
                                                        border='1px'
                                                        onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault()}}}
                                                        onInput={(e)=> {handlePasteRate(idx, z, e, i)}}
                                                        onPaste={e=>{handlePaste(e)}}
                                                        />
                                                </Center>
                                            </FormControl>                                    
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    </Box>


                </Stack>
            </ModalBody>

            <ModalFooter>
            <Button colorScheme='blue' w='100px' size='sm' onClick={handleClose} leftIcon={<MdClose />}>
                Close
            </Button>
            <Spacer />
            <Button colorScheme='green' w='100px' size='sm' leftIcon={<MdSave />} onClick={handleSubmit}>Save</Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
    </>
    )
}

export default AgentRateAddModalNew