import React, {useEffect, useState, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import { MdLocalPrintshop, MdMap, MdBrightness1 } from 'react-icons/md'
import Moment from 'react-moment'
// import { toPng } from 'html-to-image'
import {
    Box,
    Spinner,
    Stack,
    HStack,
    Spacer,
    Tag,
    Button,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Switch,
    Wrap,
    WrapItem,
    Center,
    useBreakpointValue,
    SimpleGrid,
    GridItem,
    useDisclosure,
    Collapse,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Select,
} from '@chakra-ui/react'
import { getByIdRate, deleteRate, changeRateStatus, setDiscountRate } from '../actions/rateActions'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'
import { RATE_BYID_RESET, RATE_DELETE_RESET, RATE_DISCOUNT_RESET } from '../constants/rateConstants'
import Error from '../components/Error'
import RateViewUserList from '../Modals/RateViewUserList'


const RateDetailScreen = () => {
    const colSpan = useBreakpointValue({base: 2, md: 1})
    const { isOpen:discountIsOpen, onOpen, onClose } = useDisclosure()
    const { isOpen, onToggle } = useDisclosure()
    const {loading, error, success, rateDetail} = useSelector(state=>state.rate.ById)
    const params = useParams()
    const rateId = params.id
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const componentRef = useRef()
    const ref = useRef()
    const [dstatus, setDstatus] = useState('')
    const [dpackage, setDpackage] = useState('')
    const [damount, setDamount] = useState(0)
    const [dweight, setDweight] = useState(0)
    const {user} = useSelector(state=>state.login)

    const dPackageList = {
        'p1':"30=100/31=200",
        'p2':"30=100/31=150",
        'p3':"30=100/31=100",
        'p4':"30=0/31=200",
        'p5':"30=0/31=150",
        'p6':"30=0/31=100",
    }    
    const {loading: loadingDelete, error: errorDelete, success: successDelete} = useSelector(state=>state.rate.Delete)
    const {loading: loadingStatus, error: errorStatus, success: successStatus} = useSelector(state=>state.rate.Status)
    const {loading: loadingDiscount, error: errorDiscount, success: successDiscount} = useSelector(state=>state.rate.Discount)

    useEffect(()=>{
        if(successDelete){
            dispatch({type: RATE_DELETE_RESET})
            navigate('/rate')
        }
        if(successDiscount){
            onClose()
            dispatch({type: RATE_DISCOUNT_RESET})
        }
        dispatch(getByIdRate(rateId))
        return()=>{
            dispatch({type: RATE_BYID_RESET})
        }
    },[dispatch, rateId, navigate, successDelete, successStatus, onClose, successDiscount])

    const pageStyle = `
        @page {
            size: landscape !important;
            margin: 2mm 2mm;
        }
        html, body {
            font-size: 14px;
            font-weight: 400;
        }
        `;

    const kgName = (kg) => {
        const first = kg.slice(0,1)
        const second = kg.slice(1,4)
        const kilo = (Number(second)/10)               
        return <Text ps='3'>{first + ' ' + kilo}</Text>     
    }

    const handleActive = (s) => {
        let status
        if(s){
            status = 1
        } else {
            status = 2
        }
        dispatch(changeRateStatus(rateId, status))
    }

    return (
        <Box my='4'>
            <Stack spacing={4}>
                {(loading || loadingDelete || loadingStatus || loadingDiscount) && 
                    <Center>
                        <Spinner 
                            label='Please wait' 
                            size='xl' 
                            speed='0.65s' 
                            emptyColor='gray.200'
                            color='blue.500' 
                            thickness='4px' 
                        />
                    </Center> 
                }

                {error && <Error error={error} /> }
                {errorDelete && <Error error={errorDelete} /> }
                {errorStatus && <Error error={errorStatus} /> }
                

                {rateDetail &&
                <>
                    <SimpleGrid columns={2} columnGap={3} rowGap={3} w='full' mb='3'>
                        <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>Detail</Text>
                                <Stack spacing={'0'}>
                                    <Box borderBottom={'1px'} borderColor={'blackAlpha.300'}>
                                        <HStack>
                                            <Box>Name</Box>
                                            <Spacer />
                                            <Box>{rateDetail.name}</Box>
                                        </HStack>
                                    </Box>
                                    <Box borderBottom={'1px'} borderColor={'blackAlpha.300'}>
                                        <HStack>
                                            <Box>Effective</Box>
                                            <Spacer />
                                            <Box><Moment format="D MMM YYYY">{(rateDetail.effectDate).substring(0,10)}</Moment></Box>
                                        </HStack>
                                    </Box>
                                    <Box borderBottom={'1px'} borderColor={'blackAlpha.300'}>
                                        <HStack>
                                            <Box>Created</Box>
                                            <Spacer />
                                            <Box>
                                                <Moment format="D MMM YYYY">
                                                    {(rateDetail.createdAt).substring(0,10)}
                                                </Moment>
                                                </Box>
                                        </HStack>
                                    </Box>
                                    {user && user.isAdmin &&
                                    <>
                                        <Box borderBottom={'1px'} borderColor={'blackAlpha.300'}>
                                            <HStack>
                                                <HStack>
                                                    <Box color={rateDetail.discount.status ? 'green' : 'red'}>Discount</Box>
                                                    <Box><MdBrightness1 color={rateDetail.discount.status ? 'green' : 'red'} /></Box>
                                                    {rateDetail.discount?.status &&
                                                        <Box>
                                                            {rateDetail.discount.package &&
                                                                <Box>
                                                                    {dPackageList[rateDetail.discount.package]}
                                                                </Box>
                                                            }
                                                            {/* {rateDetail.discount.startWeight} kg, {rateDetail.discount.amount} thb */}
                                                        </Box>
                                                    }

                                                </HStack>
                                                <Spacer />
                                                <Box>
                                                    <Button
                                                        size='sm'
                                                        variant='link'
                                                        colorScheme='blue'
                                                        onClick={()=>{
                                                            setDstatus(rateDetail?.discount?.status ? 'yes' : 'no')
                                                            setDpackage(dPackageList[rateDetail.discount.name] ?? '-')
                                                            setDamount(rateDetail?.discount?.amount ? rateDetail.discount.amount : 0)
                                                            setDweight(rateDetail?.discount?.startWeight ? rateDetail.discount.startWeight : 0)
                                                            onOpen()
                                                        }}
                                                    >Set</Button>
                                                </Box>
                                            </HStack>
                                        </Box>
                                        <Box borderBottom={'1px'} borderColor={'blackAlpha.300'}>
                                            <HStack>
                                                <Box>VAT</Box>
                                                <Spacer />
                                                <Box>{rateDetail.vat ? rateDetail.vat : 0}</Box>
                                            </HStack>
                                        </Box>
                                        <Box borderBottom={'1px'} borderColor={'blackAlpha.300'}>
                                            <HStack>
                                                <Box>Fuel</Box>
                                                <Spacer />
                                                <Box>{rateDetail.fuel ? rateDetail.fuel : 0}</Box>
                                            </HStack>
                                        </Box>
                                        <Box borderBottom={'1px'} borderColor={'blackAlpha.300'}>
                                            <HStack>
                                                <Box>Agent Rate</Box>
                                                <Spacer />
                                                <Box>{rateDetail.agentRate?.name}</Box>
                                            </HStack>
                                        </Box>
                                        <Box borderBottom={'1px'} borderColor={'blackAlpha.300'}>
                                            <HStack>
                                                <Box>Ess</Box>
                                                <Spacer />
                                                <Box>
                                                    <Button
                                                        size='sm'
                                                        variant='link'
                                                        colorScheme='blue'
                                                        onClick={onToggle}
                                                    >View</Button>
                                                </Box>
                                            </HStack>
                                        </Box>
                                    </>
                                    }
                                </Stack>
                            </Box>
                        </GridItem>

                        <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>Options</Text>
                                <Stack spacing={'0'} fontSize='sm' fontWeight={700}>
                                    {user && user.isAdmin && 
                                    <Box mb='2'>
                                        <Box borderBottom={'1px'} borderColor={'blackAlpha.300'} pb={'2'} mb={'2'}>
                                            <HStack>
                                                <Text fontSize={'lg'}>Active</Text>
                                                <Spacer />
                                                <Switch size={'lg'} id={'rate-status'} isChecked={rateDetail.status ? 1 : 0} onChange={e=>handleActive(e.target.checked)} />
                                            </HStack>
                                        </Box>
                                        <Box borderBottom={'1px'} borderColor={'blackAlpha.300'} pb={'2'} mb={'2'}>
                                            <HStack>
                                                <Text fontSize='lg' fontWeight='500'>{rateDetail.userCount} agents using this rate</Text>
                                                <Spacer />
                                                {rateDetail.userList.length !== 0 && <RateViewUserList agents={rateDetail.userList} />}
                                            </HStack>
                                        </Box>
                                    </Box>
                                    }

                                    <Wrap justify={'right'}>
                                        {user && user.isAdmin &&
                                            <WrapItem>
                                                {/* <Button size='sm' w='100px' colorScheme='cyan' leftIcon={<MdSettingsApplications />} onClick={()=>navigate(`/rate/edit/${rateId}`)}>Edit</Button> */}
                                                <ConfirmDeleteModal width={'100px'} deleteFunction={deleteRate} id={rateId} />
                                            </WrapItem>
                                        }
                                        <WrapItem>
                                            <Link to='/countryandzone'><Button size='sm' colorScheme='orange' leftIcon={<MdMap />}>Countries</Button></Link>
                                        </WrapItem>
                                        <WrapItem>
                                            <ReactToPrint
                                                trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                                                content={() => componentRef.current}
                                                pageStyle={()=>pageStyle}
                                            />
                                        </WrapItem>
                                    </Wrap>
                                    
                                </Stack>
                            </Box>
                        </GridItem>
                    </SimpleGrid>

               

                    <Collapse in={isOpen} animateOpacity>
                        <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full' my='3'> 
                        <HStack fontSize={'2xl'} fontWeight={'700'} mb='3' borderBottom='1px' justifyContent={'space-between'}>                           
                            <Text>ESS</Text>       
                            <Text></Text>       
                        </HStack>
                        {rateDetail.ess.length !== 0 &&
                            <Box w={'100%'} overflow={'scroll'} fontWeight={'thin'} fontSize={'12px'}>
                                <Table size='xs' variant='striped' colorScheme='' my='4'>
                                    <Thead>
                                        <Tr>
                                            {rateDetail.ess.map((e,i)=>(
                                                <Th key={i}><Box style={{width: '40px'}}>{e.zone.replace('one','')}</Box></Th>
                                            ))}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr>
                                            {rateDetail.ess.map((e,i)=>(
                                                <Td key={i}><Box style={{width: '40px'}}>{e.value}</Box></Td>
                                            ))}
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </Box>
                        }
                        </Box>
                    </Collapse>
                </>
                }


            
            <Box ref={componentRef}>
            <Box>
                <HStack>
                    <Tag colorScheme='green'>d - စာရွက်စာတမ်း</Tag>
                    <Tag colorScheme='red'>p - ကုန်ပစ္စည်း</Tag>
                    <Tag colorScheme='purple'>o - ကီလို၃၀ကျော် (per kg)</Tag>
                </HStack>
            </Box>
            <Box ref={ref} >
            {success &&
            <Box w={'100%'} overflow={'scroll'} fontWeight={'thin'} fontSize={'12px'}>
                <Table size='xs' variant='striped' colorScheme='' my='4'>
                    <Thead>
                        <Tr>
                            <Th><Box style={{width: '40px'}} paddingLeft={3}>Kg</Box></Th>
                            
                            {rateDetail.zNumbers.map((zn,i)=>(
                                <Th key={i}><Box w={'50px'}>{'Z'+zn}</Box></Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rateDetail.ratelist.map((r, i)=>(
                            <Tr key={i} py='1' bg={i%2 === 0 ? 'yellow.100' : 'white'}>
                                <Td>{kgName(r.kg)}</Td>
                                {
                                    Object.keys(r.rate).map((key, index) => (
                                        <Td key={key}>{r.rate[key]}</Td>
                                    ))
                                }
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
            }
            <Box h='50px' w='100%'></Box>
            </Box>
            </Box>
            </Stack>

            <Modal 
                isOpen={discountIsOpen}
                onClose={()=>{
                    onClose()
                    setDstatus('')
                    setDpackage('')
                    setDweight(0)
                    setDamount(0)
                    dispatch({type: RATE_DISCOUNT_RESET})
                }}>
                <ModalOverlay />
                <ModalContent>
                <ModalCloseButton />
                <ModalBody mt={3}>
                    <Stack gap={3} mt={5}>
                        {errorDiscount && <Error error={errorDiscount} /> }
                        <Box>
                            <Text>Discount applied ?</Text>
                            <Select placeholder='Select' id={'discount-status'} value={dstatus} onChange={e=>{
                                if(e.target.value === 'no'){
                                    setDamount(0)
                                    setDweight(0)
                                }
                                setDstatus(e.target.value)
                            }}>
                                <option value='yes'>Yes</option>
                                <option value='no'>No</option>
                            </Select>
                        </Box>
                        {dstatus === 'yes' &&
                            <Box>
                                <Text>Select package</Text>
                                <Select 
                                    placeholder='Select' 
                                    id={'discount-package'} 
                                    onChange={e=>{                                    
                                        setDpackage(e.target.value)
                                    }
                                }>
                                    {Object.keys(dPackageList).map(pn=>(
                                        <option key={pn} value={pn}>{dPackageList[pn]}</option>
                                    ))}
                                </Select>
                            </Box>
                        }
                        {/* <Stack gap={0}>
                            <Box color='red'>Current rule </Box>
                            <Box color='red'>0.5 to 29.9 = 0 discount </Box>
                            <Box color='red'>30 to 30.9 = 100 discount</Box>
                            <Box color='red'>31 and above = 200 discount</Box>
                        </Stack> */}
                        {/* <Box>
                            <Text>Amount</Text>
                            <Input
                                type='number'
                                min={0}
                                value={damount}
                                id='d-amount'
                                onChange={e=>setDamount(e.target.value)}
                            />
                        </Box>
                        <Box>
                            <Text>Start Weight</Text>
                            <Input
                                type='number'
                                min={0}
                                value={dweight}
                                id='d-weight'
                                onChange={e=>setDweight(e.target.value)}
                            />
                        </Box> */}
                    </Stack>
                </ModalBody>

                <ModalFooter>
                    <Button 
                        variant='outline'
                        colorScheme='green'
                        onClick={()=>{
                            const data = {
                                rateId,
                                dstatus,
                                dpackage,
                                damount: dstatus === 'no' ? 0 : damount,
                                dweight: dstatus ===  'no' ? 0 : dweight,
                            }
                            dispatch(setDiscountRate(data))
                        }}
                    >Save</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>

        </Box>
    )
}

export default RateDetailScreen