import axios from 'axios'
import {
    AGENTRATE_CREATE_REQUEST,
    AGENTRATE_CREATE_SUCCESS,
    AGENTRATE_CREATE_FAIL,
    AGENTRATE_LIST_REQUEST,
    AGENTRATE_LIST_SUCCESS,
    AGENTRATE_LIST_FAIL,
    AGENTRATE_BYID_REQUEST,
    AGENTRATE_BYID_SUCCESS,
    AGENTRATE_BYID_FAIL,
    AGENTRATE_EDIT_REQUEST,
    AGENTRATE_EDIT_SUCCESS,
    AGENTRATE_EDIT_FAIL,
    AGENTRATE_DELETE_REQUEST,
    AGENTRATE_DELETE_SUCCESS,
    AGENTRATE_DELETE_FAIL,
    AGENTRATE_NAMES_REQUEST,
    AGENTRATE_NAMES_SUCCESS,
    AGENTRATE_NAMES_FAIL,
    AGENTRATE_AGENTNAMES_REQUEST,
    AGENTRATE_AGENTNAMES_SUCCESS,
    AGENTRATE_AGENTNAMES_FAIL,
    AGENTRATE_TRANSFER_REQUEST,
    AGENTRATE_TRANSFER_SUCCESS,
    AGENTRATE_TRANSFER_FAIL,
} from '../constants/agentRateConstants'


export const createAgentRate = (newAgentRate) => async (dispatch, getState) => {
    try {
        dispatch({type: AGENTRATE_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/agentrates`, newAgentRate, config)
        dispatch({type: AGENTRATE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: AGENTRATE_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listAgentRate = () => async (dispatch, getState) => {
    try {
        dispatch({type: AGENTRATE_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/agentrates`, config)
        dispatch({type: AGENTRATE_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: AGENTRATE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const namesAgentRate = () => async (dispatch, getState) => {
    try {
        dispatch({type: AGENTRATE_NAMES_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/agentrates/names`, config)
        dispatch({type: AGENTRATE_NAMES_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: AGENTRATE_NAMES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const getByIdAgentRate = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: AGENTRATE_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/agentrates/${id}`, config)
        dispatch({type: AGENTRATE_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: AGENTRATE_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const editAgentRate = (id, editedAgentRate) => async (dispatch, getState) => {
    try {
        dispatch({type: AGENTRATE_EDIT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/agentrates/${id}`, editedAgentRate, config)
        dispatch({type: AGENTRATE_EDIT_SUCCESS})
    } catch (error) {
        dispatch({
            type: AGENTRATE_EDIT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deleteAgentRate = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: AGENTRATE_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/agentrates/${id}`, config)
        dispatch({type: AGENTRATE_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: AGENTRATE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getAgentRateAgents = (rateId) => async (dispatch, getState) => {
    try {
        dispatch({type: AGENTRATE_AGENTNAMES_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/agentrates/agentnames/${rateId}`, config)
        dispatch({type: AGENTRATE_AGENTNAMES_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: AGENTRATE_AGENTNAMES_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const trasferAgentRate = (rateData) => async (dispatch, getState) => {
    try {
        dispatch({type: AGENTRATE_TRANSFER_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/agentrates/transfer`, rateData, config)
        dispatch({type: AGENTRATE_TRANSFER_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: AGENTRATE_TRANSFER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}