import React, {useRef, useState, useEffect} from 'react'
import ReactToPrint from 'react-to-print'
import { useDispatch, useSelector } from 'react-redux'
import {Helmet} from 'react-helmet'
import {
    HStack,
    Box,
    Button,
    Stack,
    Text,
    Grid,
    GridItem,
    Spacer,
    Center,
    Input,
    Divider,
} from '@chakra-ui/react'
import { MdLocalPrintshop } from 'react-icons/md'
//import ShipmentFromAddressModal from '../../Modals/ShipmentFromAddressModal'
import { saveContactTHPShipments } from '../../actions/thailand_post_actions/thpShipmentActions'
import { useParams } from 'react-router-dom'
import Error from '../../components/Error'
import { THPSHIPMENT_PRESHIPCONTACT_RESET } from '../../constants/thailand_post_constants/thpShipmentConstants'

const InvoicePost = ({preshipment}) => {
    const dispatch = useDispatch()
    const invoiceRef = useRef()
    const params = useParams()
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    // const {contacts} = useSelector(state=>state.shipment.GetContactsForPreship)
    const {loading, error, success} = useSelector(state=>state.thailandPost.Contact)

    const invoiceStyle = `
    @page {
        size: A4;
        margin: 5mm 5mm;
    }
    html, body {
        font-size: 11px;
        font-weight: 400;
    }
    .description {
      font-size: 10px !important;
    }
    @media all {
        .pagebreak {
          display: none;
        }
    }
    @media print {
      .pagebreak {
        page-break-before: always;
      }
    }
    `;

    useEffect(()=>{
        if(success){
            setName('')
            setEmail('')
            setPhone('')
        }
        return()=>{
            dispatch({type: THPSHIPMENT_PRESHIPCONTACT_RESET})
        }
    },[success, dispatch])

    const handleSaveContact = () => {
        if(name=== '' || phone===''){
            return
        }
        const contactData = {
            preId: params.id,
            contact: `${name};${phone};${email}`
        }
        //console.log(contactData)
        dispatch(saveContactTHPShipments(contactData))
    }


    return (
        <>
        <Helmet>
            <title>{preshipment && preshipment.code + '-' + preshipment.addressId.name}</title>
        </Helmet>
        <HStack my='3'>
            {error && <Error error={error} /> }
            {/* {preshipment && !preshipment.shipped &&
            <>
            <Box>
                <ShipmentFromAddressModal />
            </Box>
            <Box></Box>
            <Box>
                <Popover>
                    <PopoverTrigger>
                    <Button disabled = {preshipment.shipped} size='sm'>Shipment Count</Button>
                    </PopoverTrigger>
                    <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader>Today Shipment Count</PopoverHeader>
                    <PopoverBody>
                        <Stack>
                        {contacts && contacts.map((c,i)=>(
                            <Box key={i}>
                            <HStack>
                                <Box>{c.name}</Box>
                                <Spacer />
                                <Box>{c.shipCount}</Box>
                            </HStack>
                            </Box>
                        ))}
                        </Stack>
                    </PopoverBody>
                    </PopoverContent>
                </Popover>
            </Box>
            </>
            } */}

            <Box></Box>
            <Spacer />
            <Box>
                <ReactToPrint
                    trigger={() => <Button size='sm' w='100px' colorScheme='green' leftIcon={<MdLocalPrintshop />}>Print</Button>}
                    content={() => invoiceRef.current}
                    pageStyle={()=> invoiceStyle}
                />
            </Box>
        </HStack>

        <Divider />
        <HStack my='3'>
            <Box>
                <Stack spacing={0}>
                    <Box fontSize={'sm'}>Name</Box>
                    <Input 
                        type='text'
                        size='sm'
                        w='250px'
                        placeholder='Name'
                        value={name}
                        onChange={e=>setName(e.target.value)}
                    />
                </Stack>                
            </Box>

            <Box>
                <Stack spacing={0}>
                    <Box fontSize={'sm'}>Phone</Box>
                    <Input 
                        type='phone'
                        size='sm'
                        w='250px'
                        placeholder='Phone'
                        value={phone}
                        onChange={e=>setPhone(e.target.value)}
                    />
                </Stack>                
            </Box>

            <Box>
                <Stack spacing={0}>
                    <Box fontSize={'sm'}>Email</Box>
                    <Input 
                        type='email'
                        size='sm'
                        w='250px'
                        placeholder='Email'
                        value={email}
                        onChange={e=>setEmail(e.target.value)}
                    />
                </Stack>                
            </Box>
            
            <Box pt='5'>
                <Button
                    type='button'
                    size='sm'
                    w='100px'
                    colorScheme={'green'}
                    isLoading={loading}
                    onClick={handleSaveContact}
                >Save</Button>
            </Box>
        </HStack>
        <Divider />

        {preshipment &&
            <Box ref={invoiceRef}>
                <Stack spacing={'0'}>
                    <Text fontSize={'20px'} mx='auto'>Packing List</Text>  
                    <Grid
                        templateRows='repeat(1, 1fr)'
                        templateColumns='repeat(2, 1fr)'
                        gap={0}
                        >
                        {/* {preshipment.fromId ? 
                            <Box borderY={'1px'}>
                                <Stack spacing={'0'}>
                                    <HStack>
                                        <Text w='150px'>Shipper : </Text>
                                        <Text>{ preshipment.fromId.company }</Text>
                                    </HStack>
                                    
                                    <HStack>
                                        <Text w='150px'>Contact : </Text>
                                        <Text>{  preshipment.fromId.name }</Text>
                                    </HStack>
                                    
                                    <HStack>
                                        <Text h='4.5rem' w='150px'>Address : </Text>
                                        <Stack spacing={'0'}>
                                            <Text>{preshipment.fromId.address}</Text>
                                            <Text>{preshipment.fromId.city}</Text>
                                            <Text>{preshipment.fromId.country}, {preshipment.fromId.postalcode}</Text>
                                        </Stack>
                                        
                                    </HStack>
                                    
                                    <HStack>
                                        <Text w='150px'>Phone : </Text>
                                        <Text>{preshipment.fromId.phone}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Email : </Text>
                                        <Text>{preshipment.fromId.email}</Text>
                                    </HStack>
                                </Stack>
                            </Box>
                            :
                            <Box borderY={'1px'}>
                                <Stack spacing={'0'}>                                
                                    <Text w='150px'>Shipper : </Text>
                                    <Text w='150px'>Contact : </Text>
                                    <Text h='4.5rem' w='150px'>Address : </Text>
                                    <Text w='150px'>Phone : </Text>
                                    <Text w='150px'>Email : </Text>
                                </Stack>
                            </Box>                
                        } */}

                        {preshipment &&
                            <Box borderY={'1px'}>
                                <Stack spacing={'0'}>
                                    <HStack>
                                        <Text w='150px'>Shipper : </Text>
                                        <Text>{ preshipment.contact.split(';')[0] }</Text>
                                    </HStack>
                                    
                                    <HStack>
                                        <Text w='150px'>Contact : </Text>
                                        <Text>{ preshipment.contact.split(';')[0] }</Text>
                                    </HStack>
                                    
                                    <HStack>
                                        <Text h='4.5rem' w='150px'>Address : </Text>
                                        <Stack spacing={'0'}>
                                            <Text>566 MOO. 1 THASAILUAT</Text>
                                            <Text>TAK</Text>
                                            <Text>Thailand, 63110</Text>
                                        </Stack>
                                        
                                    </HStack>
                                    
                                    <HStack>
                                        <Text w='150px'>Phone : </Text>
                                        <Text>{preshipment.contact.split(';')[1]}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Email : </Text>
                                        <Text>{preshipment.contact.split(';')[2]}</Text>
                                    </HStack>
                                </Stack>
                            </Box>                                           
                        }
                        

                        <Box borderY={'1px'}>
                        <Stack spacing={'0'}>
                            <HStack>
                            <Text w='150px'>Consignee : </Text>
                            <Text>{ preshipment.addressId && preshipment.addressId.company}</Text>
                            </HStack>
                            

                            <HStack>
                            <Text w='150px'>Contact : </Text>
                            <Text>{ preshipment.addressId && preshipment.addressId.name}</Text>
                            </HStack>
                            

                            <HStack my='0'>
                            <Text h='5rem' w='150px'>Address : </Text>
                                <Box>
                                { preshipment.addressId.address} <br />                    
                                { preshipment.addressId.address2 && <Text>{preshipment.addressId.address2}<br /></Text>}
                                { preshipment.addressId.address3 && <Text>{preshipment.addressId.address3}<br /></Text>}                  
                                { preshipment.addressId && preshipment.addressId.city}, { preshipment.addressId.stateProvince && preshipment.addressId.stateProvince} <br />
                                { preshipment.addressId && preshipment.addressId.country + ' ' + preshipment.addressId.postalcode}
                                </Box>               
                            </HStack>
                            <HStack>
                            <Text w='150px'>Phone : </Text>
                            <Text>{ preshipment.addressId && preshipment.addressId.phone}</Text>
                            </HStack>              
                            <HStack>
                            <Text w='150px'>Email : </Text>
                            <Text>{ preshipment.addressId.email && preshipment.addressId.email}</Text>
                            </HStack>                
                        </Stack>
                        </Box>
                    </Grid> 
                

                    <Box h='20px'></Box>
                    <Box p='2' border={'1px'}>
                    <Grid
                    templateColumns='repeat(23, 1fr)'
                    gap={0}
                    h='60px'
                    fontSize={'13px'}
                    fontWeight={'600'}
                    >
                        <GridItem borderBottom='1px' colSpan={1} >No</GridItem>
                        <GridItem borderBottom='1px' colSpan={6} >Description</GridItem>
                        <GridItem borderBottom='1px' colSpan={6} >Manufacture</GridItem>
                        <GridItem borderBottom='1px' colSpan={2} >
                            <Box>COO</Box>
                        </GridItem>
                        <GridItem borderBottom='1px' colSpan={1} >
                            <Stack spacing={0}>
                                <Box>Qty</Box>
                                <Box fontSize={'10px'} className='description'>(PCS)</Box>
                            </Stack>
                        </GridItem>
                        <GridItem borderBottom='1px' colSpan={2} >
                        <Center>
                            <Stack spacing={'0'}>
                            <Box>Net/Gross</Box>
                            <Box>Weight<span style={{'fontSize': '10px'}}>&nbsp;(kg)</span></Box>
                            </Stack>
                        </Center>
                        </GridItem>                        
                        <GridItem borderBottom='1px' colSpan={3} >
                        <Center>
                            <Stack spacing={0}>
                                <Center>Unit Price</Center>
                                <Center fontSize={'10px'} className='description'>({preshipment.currency})</Center>
                            </Stack>
                        </Center></GridItem>
                        <GridItem borderBottom='1px' colSpan={2} >
                            <Stack spacing={0}>
                                <Center>Amount</Center>
                                <Center fontSize={'10px'} className='description'>({preshipment.currency})</Center>
                            </Stack>
                        </GridItem>

                    </Grid>


                    <Grid
                    templateColumns='repeat(23, 1fr)'
                    fontSize={'13px'}
                    className="page-break"
                    >
                    { preshipment.items.map((itm, idx)=>(                
                        <React.Fragment key={idx}>
                        <GridItem borderBottom='1px' mt={'1'} colSpan={1} >{idx+1}</GridItem>
                        <GridItem borderBottom='1px' mt={'1'} colSpan={6} pe='2' >
                            <Stack spacing={'0'}>
                            <Text>{itm.item.name}</Text>
                            {itm.item.thaiName && <Text>{itm.item.thaiName}</Text>}
                            <Text fontSize={'13px'} className='description'>{itm.item.description}</Text>
                            {itm.item.hsCode && <Text fontSize={'13px'} className='description'>HS Code:{itm.item.hsCode}</Text>}
                            {/* <Text fontSize={'13px'} className='description'>{itm.item.ingredients}</Text> */}
                            </Stack>
                        </GridItem>
                        <GridItem borderBottom='1px' mt={'1'} colSpan={6} pe='2' >
                            <Stack spacing={0}>
                                <Box>{itm.item.manuName}</Box>
                                <Box fontSize={'13px'} className='description'>{itm.item.manuAddress}</Box>
                            </Stack>
                        </GridItem>
                        {/* <GridItem borderBottom='1px' mt={'1'} colSpan={4} ><Box>{itm.item.manuName}</Box></GridItem>
                        <GridItem borderBottom='1px' mt={'1'} colSpan={4} ><Box pe='2' fontSize={'13px'} className='description'>{itm.item.manuAddress}</Box></GridItem> */}
                        <GridItem borderBottom='1px' mt={'1'} colSpan={2} >{itm.item.manuCountry}</GridItem>
                        <GridItem borderBottom='1px' mt={'1'} colSpan={1} >{itm.qty}</GridItem>
                        <GridItem borderBottom='1px' mt={'1'} colSpan={2} >
                            <Stack spacing={0}>
                                <Center>{itm.weight.toFixed(2)}</Center>
                                <Center>{itm.weight.toFixed(2)}</Center>
                            </Stack>
                        </GridItem>                        
                        <GridItem borderBottom='1px' mt={'1'} colSpan={3} ><Center>{ itm.price.toFixed(2)}</Center></GridItem>
                        <GridItem borderBottom='1px' mt={'1'} colSpan={2} ><HStack><Box mx='auto' >{(itm.price * itm.qty).toFixed(2)}</Box></HStack></GridItem>
                        
                        </React.Fragment>
                    ))}
                    </Grid>

                    <Grid templateColumns='repeat(23, 1fr)' fontSize={'xl'} fontWeight={'600'} h='40px' >
                    <GridItem mt={'1'} colSpan={1} ></GridItem>
                    <GridItem mt={'1'} colSpan={6} >Total</GridItem>
                    <GridItem mt={'1'} colSpan={6} ></GridItem>
                    {/* <GridItem mt={'1'} colSpan={4} ></GridItem>
                    <GridItem mt={'1'} colSpan={4} >TOTAL </GridItem> */}
                    <GridItem mt={'1'} colSpan={2} ></GridItem>
                    <GridItem mt={'1'} colSpan={1} >
                        { (preshipment.items.reduce((acc, item) => acc + item.qty, 0))}
                    </GridItem>
                    <GridItem mt={'1'} colSpan={2} >
                        <Center>
                        { (preshipment.items.reduce((acc, item) => acc + item.weight, 0)).toFixed(2)}
                        <span style={{'fontSize': '13px'}}>&nbsp;(kg)</span>
                        </Center>
                    </GridItem>
                    <GridItem mt={'1'} colSpan={3} ></GridItem>
                    <GridItem mt={'1'} colSpan={2} >
                        <Center>
                        { (preshipment.items.reduce((acc, item) => acc + item.price * item.qty, 0)).toFixed(2)}  
                        <span style={{'fontSize': '13px'}}>&nbsp;({preshipment.currency})</span>
                        </Center>
                    </GridItem>                  
                    </Grid>
                    </Box>

                </Stack>
            </Box>
        }
        </>
    )
}

export default InvoicePost