
import {
    THPINVOICE_SHIPMENTS_REQUEST,
    THPINVOICE_SHIPMENTS_SUCCESS,
    THPINVOICE_SHIPMENTS_FAIL,
    THPINVOICE_SHIPMENTS_RESET,
    THPINVOICE_LIST_REQUEST,
    THPINVOICE_LIST_SUCCESS,
    THPINVOICE_LIST_FAIL,
    THPINVOICE_LIST_RESET,
    THPINVOICE_CREATE_REQUEST,
    THPINVOICE_CREATE_SUCCESS,
    THPINVOICE_CREATE_FAIL,
    THPINVOICE_CREATE_RESET,
    THPINVOICE_BYID_REQUEST,
    THPINVOICE_BYID_SUCCESS,
    THPINVOICE_BYID_FAIL,
    THPINVOICE_BYID_RESET,
    THPINVOICE_DELETE_REQUEST,
    THPINVOICE_DELETE_SUCCESS,
    THPINVOICE_DELETE_FAIL,
    THPINVOICE_DELETE_RESET,
    THPINVOICE_ADDSHIPMENTS_REQUEST,
    THPINVOICE_ADDSHIPMENTS_SUCCESS,
    THPINVOICE_ADDSHIPMENTS_FAIL,
    THPINVOICE_ADDSHIPMENTS_RESET,
    THPINVOICE_REMOVESHIPMENTS_REQUEST,
    THPINVOICE_REMOVESHIPMENTS_SUCCESS,
    THPINVOICE_REMOVESHIPMENTS_FAIL,
    THPINVOICE_REMOVESHIPMENTS_RESET,
    THPINVOICE_MYLIST_REQUEST,
    THPINVOICE_MYLIST_SUCCESS,
    THPINVOICE_MYLIST_FAIL,
    THPINVOICE_MYLIST_RESET,

} from '../../constants/thailand_post_constants/thpInoviceConstants'

export const thpShipmentsForInvoiceReducer = (state={}, action) => {
    switch(action.type){
        case THPINVOICE_SHIPMENTS_REQUEST:
            return {loading: true}
        case THPINVOICE_SHIPMENTS_SUCCESS:
            return {loading: false, shipments: action.payload}
        case THPINVOICE_SHIPMENTS_FAIL:
            return {loading: false, error: action.payload}
        case THPINVOICE_SHIPMENTS_RESET:
            return {success: false}
        default: return state
    }
}


export const thpInvoiceListReducer = (state={invoices:[]}, action) => {
    switch(action.type){
        case THPINVOICE_LIST_REQUEST:
            return {loading: true}
        case THPINVOICE_LIST_SUCCESS:
            return {
                loading: false, 
                invoices: action.payload.invoices,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case THPINVOICE_LIST_FAIL:
            return {loading: false, error: action.payload}
        case THPINVOICE_LIST_RESET:
            return {}
        default: return state
    }
}

export const thpInvoiceMyListReducer = (state={invoices:[]}, action) => {
    switch(action.type){
        case THPINVOICE_MYLIST_REQUEST:
            return {loading: true}
        case THPINVOICE_MYLIST_SUCCESS:
            return {
                loading: false, 
                invoices: action.payload.invoices,
                pages: action.payload.pages,
                page: action.payload.page
            }
        case THPINVOICE_MYLIST_FAIL:
            return {loading: false, error: action.payload}
        case THPINVOICE_MYLIST_RESET:
            return {}
        default: return state
    }
}

export const thpInvoiceCreateReducer = (state={}, action) => {
    switch(action.type){
        case THPINVOICE_CREATE_REQUEST:
            return {loading: true}
        case THPINVOICE_CREATE_SUCCESS:
            return {loading: false, success: true, invoiceId: action.payload}
        case THPINVOICE_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case THPINVOICE_CREATE_RESET:
            return {}
        default: return state
    }
}

export const thpInvoiceByIdReducer = (state={}, action) => {
    switch(action.type){
        case THPINVOICE_BYID_REQUEST:
            return {loading: true}
        case THPINVOICE_BYID_SUCCESS:
            return {loading: false, invoice: action.payload}
        case THPINVOICE_BYID_FAIL:
            return {loading: false, error: action.payload}
        case THPINVOICE_BYID_RESET:
            return {}
        default: return state
    }
}

export const thpInvoiceDeleteReducer = (state={}, action) => {
    switch(action.type){
        case THPINVOICE_DELETE_REQUEST:
            return {loading: true}
        case THPINVOICE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case THPINVOICE_DELETE_FAIL:
            return {loading: false, error: action.payload}
        case THPINVOICE_DELETE_RESET:
            return {}
        default: return state
    }
}


export const thpInvoiceAddShipmentReducer = (state={}, action) => {
    switch(action.type){
        case THPINVOICE_ADDSHIPMENTS_REQUEST:
            return {loading: true}
        case THPINVOICE_ADDSHIPMENTS_SUCCESS:
            return {loading: false, success: true}
        case THPINVOICE_ADDSHIPMENTS_FAIL:
            return {loading: false, error: action.payload}
        case THPINVOICE_ADDSHIPMENTS_RESET:
            return {}
        default: return state
    }
}

export const thpInvoiceRemoveShipmentReducer = (state={}, action) => {
    switch(action.type){
        case THPINVOICE_REMOVESHIPMENTS_REQUEST:
            return {loading: true}
        case THPINVOICE_REMOVESHIPMENTS_SUCCESS:
            return {loading: false, success: true}
        case THPINVOICE_REMOVESHIPMENTS_FAIL:
            return {loading: false, error: action.payload}
        case THPINVOICE_REMOVESHIPMENTS_RESET:
            return {}
        default: return state
    }
}