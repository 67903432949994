import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Link, useParams, useLocation } from 'react-router-dom'
import SearchBox from '../components/SearchBox'
import Pagination from '../components/Pagination'
import {
  HStack,
  Stack,
  Box,
  Divider,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  SimpleGrid,
  GridItem,
  useBreakpointValue,
  Center,
  Heading,
  Flex,
} from '@chakra-ui/react'
import { listShipments, filterShipments } from '../actions/shipmentActions'
import { SHIPMENT_LIST_RESET } from '../constants/shipmentConstants'
import { MdCheckCircle, MdCancel } from 'react-icons/md'
import Error from '../components/Error'
import Marks from '../components/Marks'
import MySpinner from '../components/MySpinner'
import Filter from '../components/Filter'

const ShipmentListScreen = () => {
  const headColSpan = useBreakpointValue({base: 2, md: 1})
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const keyword = params.keyword || ''
  const pageNumber = params.pageNumber || 1
  const [itemCount, setItemCount] = useState(50)
  const {loading, error, shipments, pages, page} = useSelector(state=>state.shipment.List)

  useEffect(()=>{
    dispatch(listShipments(pageNumber, itemCount, keyword))
    return()=>{
      dispatch({type: SHIPMENT_LIST_RESET})
    }
  },[dispatch,  pageNumber, itemCount, keyword])


 

  return (
    <Box>
        <Center><Heading mt='2'>DHL Shipments {loading && <MySpinner thinkness='5px' />} </Heading></Center>
        <SimpleGrid columns={2} columnGap={3} w='full' my={3}>
          <GridItem colSpan={headColSpan} mb='4'>
            <Box>
              <Stack spacing={'0'}>
                <SearchBox width={'full'} />
                <Box fontSize='13px' color='gray'>search: tracking, precode, receiver, address</Box>
              </Stack>
            </Box>
          </GridItem>

          <GridItem colSpan={headColSpan} ml={{base: 0, md: 'auto'}}>
            <HStack>
              <Box>
                <Select placeholder='Select' onChange={e=>setItemCount(e.target.value)} size='sm'>
                  {[50, 100, 150, 200].map((v,i)=>(
                    <option key={i} value={v}>{v}</option>
                  ))}
                </Select>
              </Box>
              <Box>
                <Pagination keyword = {keyword} pages={pages} page={page} path='shipment' />
              </Box>
            </HStack>
          </GridItem>
        </SimpleGrid>
        <Divider />

        <Filter filterFunction={filterShipments} />
        
        <Divider />

        <Box my={'2'}>
          {error && <Error error={error} /> }
        </Box>

        <Box display={['none', 'none', 'flex', 'flex']}>
          <Table size='sm' variant='striped' colorScheme='yellow' my='4'>
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>AWB</Th>
                <Th>PreId</Th>
                <Th>Agent</Th>
                <Th>Ship Date</Th>
                <Th>Receiver</Th>
                <Th>Weight</Th>
                {/* <Th>Amount</Th> */}
                <Th>Invoiced</Th>
                <Th>Options</Th>
              </Tr>
            </Thead>
            <Tbody>
              {shipments && shipments.map((sm,index)=>(
                <Tr key={index}>
                  <Td>{pageNumber === 1 ? (index+1) : (((Number(pageNumber)-1) * itemCount) + (index+1))}</Td>
                  <Td>{sm.awb}</Td>
                  <Td>
                    {sm.preCode}
                    {sm.isReturned && <Marks bgColor='teal' text='R' />}  
                    {sm.isVplus && <Marks bgColor='purple' text='V' />}              
                  </Td>
                  <Td>{sm.agent.name}</Td>
                  <Td>{sm.createdAt.substring(0,10)}</Td>
                  <Td>{sm.customerDetails.receiverDetails.contactInformation.fullName} - {sm.customerDetails.receiverDetails.postalAddress.countryCode}</Td>
                  <Td>{sm.totalWeight}</Td>
                  {/* <Td>{sm.totalAmount.toLocaleString()}</Td> */}
                  {/* <Td>{new Date(sm.createdAt).toLocaleString('en', { hour12: true, timeZone: 'UTC' })}</Td> */}
                  <Td>
                      {sm.isInvoiced ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}                 
                  </Td>
                  <Td>
                    <Link to={`/shipment/${sm._id}`} state={{redirect: location.pathname}}>Detail</Link>       
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>


        <Box display={['flex', 'flex', 'none', 'none']}>
          <Table size='sm' variant='striped' colorScheme='yellow' my='4'>
            <Thead>
              <Tr>
                <Th>No</Th>
                <Th>Summary</Th>
                <Th>Options</Th>
              </Tr>
            </Thead>
            <Tbody>
              {shipments && shipments.map((sm,index)=>(
                <Tr key={index}>
                  <Td>{pageNumber === 1 ? (index+1) : (((Number(pageNumber)-1) * itemCount) + (index+1))}</Td>
                  <Td>
                    <Stack spacing={0}>
                      <Box>AWB - {sm.awb}</Box>
                      <Box>PreId - 
                        {sm.preCode}
                        {sm.isReturned && <Marks bgColor='red' text='R' />} 
                        {sm.isVplus && <Marks bgColor='purple' text='V' /> }
                      </Box>
                      <Box>Agent - {sm.agent.name}</Box>
                      <Box>Ship Date - {sm.createdAt.substring(0,10)}</Box>
                      <Box>Receiver - {sm.customerDetails.receiverDetails.contactInformation.fullName}</Box>
                      <Box>Weight - {sm.totalWeight} kg</Box>
                      <Flex>Invoiced - {sm.isInvoiced ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' /> }</Flex>
                    </Stack>
                  </Td>
                  <Td>
                    <Link to={`/shipment/${sm._id}`} state={{redirect: location.pathname}}>Detail</Link>       
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
        
    </Box>
  )
}

export default ShipmentListScreen