import axios from 'axios'
import {
    THPINVOICE_ADDSHIPMENTS_FAIL,
    THPINVOICE_ADDSHIPMENTS_REQUEST,
    THPINVOICE_ADDSHIPMENTS_SUCCESS,
    THPINVOICE_REMOVESHIPMENTS_FAIL,
    THPINVOICE_REMOVESHIPMENTS_REQUEST,
    THPINVOICE_REMOVESHIPMENTS_SUCCESS,
    THPINVOICE_BYID_FAIL,
    THPINVOICE_BYID_REQUEST,
    THPINVOICE_BYID_SUCCESS,
    THPINVOICE_CREATE_FAIL,
    THPINVOICE_CREATE_REQUEST,
    THPINVOICE_CREATE_SUCCESS,
    THPINVOICE_DELETE_FAIL,
    THPINVOICE_DELETE_REQUEST,
    THPINVOICE_DELETE_SUCCESS,
    THPINVOICE_LIST_FAIL,
    THPINVOICE_LIST_REQUEST,
    THPINVOICE_LIST_SUCCESS,
    THPINVOICE_SHIPMENTS_FAIL,
    THPINVOICE_SHIPMENTS_REQUEST,
    THPINVOICE_SHIPMENTS_SUCCESS,
    THPINVOICE_MYLIST_REQUEST,
    THPINVOICE_MYLIST_SUCCESS,
    THPINVOICE_MYLIST_FAIL,

} from '../../constants/thailand_post_constants/thpInoviceConstants'


export const listTHPInvoices = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: THPINVOICE_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/invoices?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: THPINVOICE_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPINVOICE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listMyTHPInvoices = (pageNumber = '', itemCount = '', keyword = '', date='') => async (dispatch, getState) => {
    try {
        dispatch({type: THPINVOICE_MYLIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/invoices/myinvoices?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}&date=${date}`, config)
        dispatch({type: THPINVOICE_MYLIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPINVOICE_MYLIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const filterMyTHPInvoices = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPINVOICE_MYLIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/thp/invoices/myfilter`, {filterData}, config)
        dispatch({type: THPINVOICE_MYLIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPINVOICE_MYLIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const filterTHPInvoices = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPINVOICE_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/thp/invoices/filter`, {filterData}, config)
        dispatch({type: THPINVOICE_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPINVOICE_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const listInvoiceTHPShipments = (agentId, date) => async (dispatch, getState) => {
    try {
        dispatch({type: THPINVOICE_SHIPMENTS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/invoices/shipments?agentId=${agentId}&date=${date}`, config)
        dispatch({type: THPINVOICE_SHIPMENTS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPINVOICE_SHIPMENTS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const createTHPInvoice = (invoiceData) => async (dispatch, getState) => {
    try {
        dispatch({type: THPINVOICE_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/thp/invoices`, {invoiceData}, config)
        dispatch({type: THPINVOICE_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPINVOICE_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getTHPInvoiceById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: THPINVOICE_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/thp/invoices/${id}`, config)
        dispatch({type: THPINVOICE_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: THPINVOICE_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const deleteTHPInvoice = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: THPINVOICE_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/thp/invoices/${id}`, config)
        dispatch({type: THPINVOICE_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: THPINVOICE_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addShipmentsToTHPInvoice = (id, shipmentIdList) => async (dispatch, getState) => {
    try {
        dispatch({type: THPINVOICE_ADDSHIPMENTS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/thp/invoices/${id}`, {shipmentIdList}, config)
        dispatch({type: THPINVOICE_ADDSHIPMENTS_SUCCESS})
    } catch (error) {
        dispatch({
            type: THPINVOICE_ADDSHIPMENTS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const removeShipmentFromTHPInvoice = (invId, newIdList) => async (dispatch, getState) => {
    try {
        dispatch({type: THPINVOICE_REMOVESHIPMENTS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/thp/invoices/remove`, {invId, newIdList}, config)
        dispatch({type: THPINVOICE_REMOVESHIPMENTS_SUCCESS})
    } catch (error) {
        dispatch({
            type: THPINVOICE_REMOVESHIPMENTS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}
