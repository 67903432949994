import React, {useRef} from 'react'
import { Link } from 'react-router-dom'
import {
    Box,
    HStack,
    Button,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Stack,
    Text,
  } from '@chakra-ui/react'
  import ShipmentTrackingModal from '../Modals/ShipmentTrackingModal'

const ShipmentStatusAwbList = ({awbList}) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()
    return (
        <>
        <Button size='sm' h='30px' ref={btnRef} variant='link' onClick={onOpen}>
            View
        </Button>
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onClose}
            finalFocusRef={btnRef}
        >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>AWB List</DrawerHeader>

            <DrawerBody>

                <Stack spacing={'1'}>
                    {awbList.map((a,i)=>(
                        <Box key={i}>
                            <HStack>
                                <Box w='30px' mr='2'>{i+1}. </Box>
                                <Link to={`/shipment/${a.id}`}>
                                    <Text as='u'>{a.awb}</Text>
                                </Link> 
                                <Box><ShipmentTrackingModal awb={a.awb} color='' variant={'ghosts'} text={'Track'} /></Box>
                            </HStack>
                        </Box>
                    ))}
                </Stack>

            </DrawerBody>

            
            </DrawerContent>
        </Drawer>
        </>
    )
}

export default ShipmentStatusAwbList