import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    HStack,
    Divider,
    Box,
    Spinner,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Center,
    Heading,
    Spacer,
    Checkbox,
  } from '@chakra-ui/react'
  import { MdAddBox } from 'react-icons/md'
  import { listRate } from '../actions/rateActions'
  import Error from '../components/Error'
  import { MdCheckCircle, MdCancel, MdGroupWork, MdBrightness1 } from 'react-icons/md'
  import RateShipmentQtyModal from '../Modals/RateShipmentQtyModal'
  import { getZoneRate } from '../actions/zoneActions'

const RateListScreen = () => {

    const dispatch = useDispatch()
    const [active, setActive] = useState(false)
    const {loading, error, success, rates} = useSelector(state=>state.rate.List)
    const {loading: zRateLoading, error: zRateError, success: zRateSuccess, zone} = useSelector(state=>state.zone.Rate)
    

    useEffect(()=>{
        dispatch(listRate(active))
        dispatch(getZoneRate())
    },[dispatch, active])

    return (
        <Box>
            <Center mb='3'><Heading>Rates</Heading></Center>
            <Divider />

            <HStack spacing='4' my='4'>
                <Box>
                    {(loading && zRateLoading) && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}
                </Box>

                {zRateSuccess && zone.zArray.length !== 0 &&
                <>
                    <Box>
                        <Link to='/addrate'>
                            <Button size='sm' colorScheme='green' leftIcon={<MdAddBox />}>Add New</Button>
                        </Link>
                    </Box>
                    <Box>
                        <Link to='/createrate'>
                            <Button size='sm' colorScheme='blue' leftIcon={<MdGroupWork />}>Create New</Button>
                        </Link>
                    </Box>
                </>
                }

                <Box>
                    <RateShipmentQtyModal />
                </Box>
                <Spacer />
                <Box>
                    <Checkbox size='lg' id='active-rate-switch' isChecked={active} onChange={()=>setActive(!active)}>Show only active</Checkbox>
                </Box>
            </HStack>
            
            <Divider />
            
            {error && <Error error={error} />}
            {zRateError && <Error error={zRateError} />}

            
            <Table size='sm' variant='striped' colorScheme='yellow' my='4' w='100%'>
                <Thead>
                    <Tr>
                        <Th>No</Th>
                        <Th>Name</Th>
                        <Th>Zone Qty</Th>
                        <Th>Discount</Th>
                        <Th>Effective Date</Th>
                        <Th>Created Date</Th>
                        <Th>Number of Agents</Th>
                        <Th>Active</Th>
                        <Th>Options</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {success && rates.map((r, i)=>(
                        <Tr key={i}>
                            <Td>{i+1}</Td>
                            <Td>{r.name}</Td>
                            <Td>{r.zoneQty}</Td>
                            <Td><MdBrightness1 color={r.discount?.status ? 'green' : 'red'} /></Td>
                            <Td>{r.effectDate.slice(0,10)}</Td>
                            <Td>{r.createdAt.slice(0,10)}</Td>
                            <Td>{r.userCount}</Td>
                            <Td>{r.status ? < MdCheckCircle size={23} color='green' /> :  < MdCancel size={23} color='red' />}</Td>
                            <Td>
                                <Link to={`/rate/${r._id}`}>
                                    <Button size='sm' variant='link'>Detail</Button>
                                </Link>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            
        </Box>
    )
}

export default RateListScreen