import React from 'react'
import { Text } from '@chakra-ui/react'

const Marks = ({text, bgColor}) => {
  return (
    <Text 
        style={{
        'fontSize': '10px',
        'color': 'white', 
        'background': bgColor, 
        'borderRadius':'20px', 
        'padding': '3px 5px', 
        'marginLeft': '5px',
        'display': 'inline',
        }}>{text}</Text>
  )
}


Marks.defaultProps = {
    text: 'M',
    bgColor: 'red'
}


export default Marks