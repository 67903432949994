import React, {useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Stack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Spinner,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    ModalFooter,
    Box,
  } from '@chakra-ui/react'
import { deleteAgentRate, getByIdAgentRate } from '../actions/agentRateActions'
import Error from '../components/Error'
//import { Link } from 'react-router-dom'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'

const AgentRateViewModal = ({agRateId, zone}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const {loading, error, success, agentrate} = useSelector(state=>state.agentRate.ById)
    
    //const zoneNames = ['KG', 'Zone 1','Zone 2','Zone 3','Zone 4','Zone 5','Zone 6','Zone 7','Zone 8','Zone 9']
    //const kglist = ['0.5-2.0','0.5-5.0','5.1-10.0','10.1-15.0','15.1-20.0','20.1-25.0','25.1-30.0','30.1-70.0','70.1-300.0', '300.1-1000']
    //const kgName = ['Doc ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ', 'Pkg ']
    const kgMap = ['doc', 'p0', 'p5','p10','p15','p20','p25','p30','p70','p300']

    useEffect(()=>{
        if(show){
            dispatch(getByIdAgentRate(agRateId))
        } 
    },[dispatch, show, agRateId])

    const handleClose = () => {    
       
        setShow(false)        
    }

    return (
    <>
        <Button size='sm'  variant='link' onClick={()=>setShow(true)} >View</Button>

        <Modal isOpen={show} onClose={handleClose} size='full' closeOnOverlayClick={false} minW='800px'>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>{loading ? <Spinner label='Please wait' speed='0.1s' thickness='5px' /> : 'View Agent Rate' }</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Stack spacing={4}>
                    {error && <Error error={error} /> }
                    
                    {success &&
                    <Box w={'100%'} overflow={'scroll'}>
                    <Table size='sm' variant='striped' colorScheme='yellow' mb='30px'>
                        <Thead>
                            <Tr>
                            {/* {zone.zArray.map((zn) => (
                                <Th key={zn}>{zn}</Th>
                            ))} */}
                            <Th>KG</Th>
                            {
                                Object.keys(agentrate.doc).map((key, index) => (
                                    <Th key={key}>{key}</Th>
                                ))
                            }
                            </Tr>
                        </Thead>
                        <Tbody>
                        {/* {agentrate && [0,1,2,3,4,5,6,7,8,9].map((v) =>  (
                            <Tr key={v}>
                                <Td>{kgName[v]+kglist[v]}</Td>
                                {[1,2,3,4,5,6,7,8,9].map(a=>(
                                    <Td key={a}>{agentrate[kgMap[v]]['zone'+a]}</Td>
                                ))}
                            </Tr>
                        ))} */}
                        {/* 0 to 9 = doc to p300 - 10 lines */}
                        {[0,1,2,3,4,5,6,7,8,9].map((num) => (
                            <Tr key={num}>
                                <Td>{kgMap[num]}</Td>
                                {agentrate.zNumbers.map((zn,i) =>(<Td key={i}>{agentrate[kgMap[num]]['zone'+zn]}</Td>))}
                            </Tr>
                        ))}
                        </Tbody>
                    </Table>
                    </Box>
                    }

                </Stack>
            </ModalBody>

            <ModalFooter>
                {/* <Link to={`/agentrate/${agRateId}`}>
                    <Button 
                        size='sm'
                        mr='20px'
                        width={'100px'}
                        colorScheme={'blue'}
                    >Edit</Button>
                </Link> */}
                {success && <ConfirmDeleteModal id={agentrate._id} deleteFunction={deleteAgentRate} width='100px' />}
            </ModalFooter>
        </ModalContent>
        </Modal>
    </>
    )
}

export default AgentRateViewModal