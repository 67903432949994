import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { getShipmentById } from '../actions/shipmentActions'
import printJS from 'print-js'
import {
    Box,
    Text,
    Stack,
    HStack,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    Spacer,
    Divider,
    Button,
    SimpleGrid,
    GridItem,
    useBreakpointValue,
    OrderedList,
    ListItem,
    Flex,

} from '@chakra-ui/react'
import { SHIPMENT_ADDNOTE_RESET, SHIPMENT_BYID_RESET, SHIPMENT_CANCEL_RESET, SHIPMENT_DELETENOTE_RESET, SHIPMENT_RETURNED_RESET } from '../constants/shipmentConstants'
import ViewDocumentModal from '../Modals/ViewDocumentModal'
import ConfirmDeleteModal from '../components/ConfirmDeleteModal'
import ShipmentCostUpdateModal from '../Modals/ShipmentCostUpdateModal'
//returnedShipment from shipmentActions
import { cancelShipment, deleteNoteShipment, updateChargesShipment } from '../actions/shipmentActions'
import ShipmentAddNoteModal from '../Modals/ShipmentAddNoteModal'
import ShipmentTrackingModal from '../Modals/ShipmentTrackingModal'
import { MdCancel, MdCheckCircle, MdEditNote } from 'react-icons/md'
import AddChargesModal from '../Modals/AddChargesModal'
import ShipmentChangeAgentModal from '../Modals/ShipmentChangeAgentModal'
import { statusCodes } from '../data/statusCodes'
import Error from '../components/Error'
import MySpinner from '../components/MySpinner'
import ShipmentAWBUpdateModal from '../Modals/ShipmentAWBUpdateModal'

const ShipmentDetailScreen = () => {
    const colSpan = useBreakpointValue({base: 2, md: 1})
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {user} = useSelector(state=> state.login)
    const {loading, error, shipment} = useSelector(state=>state.shipment.ById)
    const {success: successAddNote} = useSelector(state=>state.shipment.AddNote)
    const {loading: loadingDeleteNote, error: errorDeleteNote, success: successDeleteNote} = useSelector(state=>state.shipment.DeleteNote)
    const {loading: loadingCancel, error: errorCancel, success: successCancel} = useSelector(state=>state.shipment.Cancel)
    const {loading: loadingReturned, error: errorReturned, success: successReturned} = useSelector(state=>state.shipment.Returned)
    const {success: successCharge} = useSelector(state=>state.shipment.AddCharge)
    const {success: successAgent} = useSelector(state=>state.shipment.ChangeAgent)
    const {success: successCost} = useSelector(state=>state.shipment.UpdateCost)
    const {success: successAWB} = useSelector(state=>state.shipment.UpdateAWB)
    useEffect(()=>{
        if(successCancel){
            navigate('/shipment')            
        }
    },[navigate, successCancel])
    useEffect(()=>{        
        dispatch(getShipmentById(params.id))
        return()=>{
            dispatch({type: SHIPMENT_CANCEL_RESET})
            dispatch({type: SHIPMENT_BYID_RESET})            
            dispatch({type: SHIPMENT_ADDNOTE_RESET})
            dispatch({type: SHIPMENT_RETURNED_RESET})
            dispatch({type: SHIPMENT_DELETENOTE_RESET})
        }
    },[dispatch, successCharge, successReturned, successAddNote, successAgent, successDeleteNote, successCost, successAWB, params])

    const printBase64 = (base64Data) => {
        printJS({
            printable: base64Data,
            type: 'pdf',
            base64: true
          });
    }
    
    // const hanldeMarkReturn = () => {
    //     if(window.confirm('Are you sure?')){
    //         dispatch(returnedShipment(shipment._id))
    //     }        
    // }

    const handleDeleteNote = (noteId) => {
        const noteData = {
            shipmentId: params.id,
            userId: user._id,
            noteId
        }
        dispatch(deleteNoteShipment(noteData))
    }

    const handleRemoveCharge = (name, amount) => {
        const detail = {
            add: false,
            shipmentId: params.id,
            name,
            amount,
        }
        //console.log(detail)
        dispatch(updateChargesShipment(detail))
    }
    
    return (
        <Box>       
            
            <HStack my='3'>
                <Box>
                    {(loading || loadingCancel || loadingReturned)  && <MySpinner />}
                </Box>
                <Spacer />
                {shipment && 
                    <>
                    <ShipmentTrackingModal awb={shipment.awb} text='Track' width='100px' />
                    <Link to={`/preshipment/${shipment.preId}`}>
                        <Button
                            size='sm' w='100px' colorScheme='yellow'
                        >Go to Pre</Button>
                    </Link>
                    </>                    
                }
                {user && user.isAdmin && 
                    <ConfirmDeleteModal disable={shipment && shipment.isPaid} deleteFunction={cancelShipment} id={params.id} width='100px' />
                }                
            </HStack>

            <Box>
            {error && <Error error={error} /> }
            {errorCancel && <Error error={errorCancel} />}
            {errorReturned && <Error error={errorReturned} /> }
            </Box>

            {shipment && 
                <Box boxShadow='xl' mb='5' p='3'>

                    <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full' my='3'>   
                        <HStack fontSize={'2xl'} fontWeight={'700'} mb='3' borderBottom='1px' justifyContent={'space-between'}>                           
                            <Text >Shipment Details</Text>       
                            <Box display={'flex'} gap={3} alignItems={'center'}>
                                <Text >{shipment.awb}</Text>   
                                <ShipmentAWBUpdateModal shipmentId = {shipment._id} />
                            </Box>    
                        </HStack>                              
                        <Stack spacing={'0'} fontSize='sm' fontWeight={700}>
                            <Text>Pre - {new Date(shipment.preDate).toDateString()}</Text>
                            <Text>Ship - {new Date(shipment.createdAt).toDateString()}</Text>
                            <Text>Scan - {shipment.isScanned && new Date(shipment.scannedDate).toDateString()}</Text>
                            <Text>Status - {statusCodes[shipment.statusCode]}</Text>
                            
                        </Stack>
                    </Box>

                    <SimpleGrid columns={2} columnGap={3} rowGap={3} w='full' mb='3'>
                        <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>From</Text>                                    
                                <Stack spacing={'0'}>
                                    {/* <Text fontWeight={'400'}>{shipment.customerDetails.shipperDetails.contactInformation.fullName}</Text> */}
                                    <Text fontWeight={'400'}>{shipment.agent?.name}</Text>
                                    {/* <Text>{shipment.customerDetails.shipperDetails.contactInformation.companyName}</Text> */}
                                    <Text>{shipment.customerDetails.shipperDetails.contactInformation.fullName}</Text>
                                    <Text>{shipment.customerDetails.shipperDetails.postalAddress.addressLine1}</Text>
                                    {shipment.customerDetails.shipperDetails.postalAddress.addressLine2 && <Text>{shipment.customerDetails.shipperDetails.postalAddress.addressLine2}</Text>}
                                    {shipment.customerDetails.shipperDetails.postalAddress.addressLine3 && <Text>{shipment.customerDetails.shipperDetails.postalAddress.addressLine3}</Text>}
                                    <HStack>
                                        <Text>{shipment.customerDetails.shipperDetails.postalAddress.cityName},</Text>
                                        {shipment.customerDetails.shipperDetails.postalAddress.provinceCode && <Text>{shipment.customerDetails.shipperDetails.postalAddress.provinceCode},</Text>}
                                        <Text>{shipment.customerDetails.shipperDetails.postalAddress.postalCode},</Text>
                                        <Text>{shipment.customerDetails.shipperDetails.postalAddress.countryName}</Text>
                                    </HStack> 
                                    <Text>{shipment.customerDetails.shipperDetails.contactInformation.phone}</Text>
                                    {shipment.customerDetails.shipperDetails.contactInformation.email && <Text>{shipment.customerDetails.shipperDetails.contactInformation.email}</Text>}
                                </Stack>
                            </Box>
                        </GridItem>

                        <GridItem colSpan={colSpan}>                            
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>To</Text>
                                <Stack spacing={'0'}>
                                    <Text fontWeight={'400'}>{shipment.customerDetails.receiverDetails.contactInformation.fullName}</Text>
                                    <Text>{shipment.customerDetails.receiverDetails.contactInformation.companyName}</Text>
                                    <Text>{shipment.customerDetails.receiverDetails.postalAddress.addressLine1}</Text>
                                    {shipment.customerDetails.receiverDetails.postalAddress.addressLine2 && <Text>{shipment.customerDetails.receiverDetails.postalAddress.addressLine2}</Text>}
                                    {shipment.customerDetails.receiverDetails.postalAddress.addressLine3 && <Text>{shipment.customerDetails.receiverDetails.postalAddress.addressLine3}</Text>}
                                    <HStack>
                                        <Text>{shipment.customerDetails.receiverDetails.postalAddress.cityName},</Text>
                                        {shipment.customerDetails.receiverDetails.postalAddress.provinceCode && <Text>{shipment.customerDetails.receiverDetails.postalAddress.provinceCode},</Text>}
                                        <Text>{shipment.customerDetails.receiverDetails.postalAddress.postalCode},</Text>
                                        <Text>{shipment.customerDetails.receiverDetails.postalAddress.countryName}</Text>
                                    </HStack>
                                    <Text>{shipment.customerDetails.receiverDetails.contactInformation.phone}</Text>
                                    {shipment.customerDetails.receiverDetails.contactInformation.email && <Text>{shipment.customerDetails.receiverDetails.contactInformation.email}</Text>}
                                </Stack>
                            </Box>
                        </GridItem>
                    </SimpleGrid>

                    <SimpleGrid columns={2} columnGap={3} rowGap={3} w='full' mb='3'>
                        <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>Packages</Text>                                   
                        
                                <Table size='xs' fontSize={'sm'}>
                                    <Thead>
                                        <Tr>
                                            <Th>No</Th>
                                            <Th>Dimension (cm)</Th>
                                            <Th>Weight (kg)</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {shipment.content.packages.map((p, i) => (
                                            <Tr  key={i+1}>
                                                <Td>{i+1}</Td>
                                                <Td>{p.dimensions.length} x {p.dimensions.width} x {p.dimensions.height}</Td>
                                                <Td>{p.weight}</Td>
                                            </Tr>                                            
                                        ))}
                                    </Tbody>
                                    <Tfoot>
                                        <Tr>
                                            <Th></Th>
                                            <Th></Th>
                                            <Th>Total {shipment.totalWeight} kg</Th>
                                        </Tr>
                                    </Tfoot>
                                </Table>
                            </Box>
                        </GridItem>

                        <GridItem colSpan={colSpan}>                            
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Flex borderBottom='1px'>
                                    <Box>
                                        <Text fontSize={'2xl'} >Item List</Text>
                                    </Box>
                                    <Spacer />
                                    {shipment.productCode === 'P' && <Box>
                                        <Text >
                                            total - {shipment.content.exportDeclaration?.lineItems?.length}
                                        </Text>
                                    </Box>}
                                </Flex>
                                
                                {shipment.productCode === 'P' ?    
                                <Box maxH={'300px'} overflow={'scroll'}>                             
                                <Table size='xs' fontSize={'sm'}>
                                    <Thead>
                                        <Tr>
                                            <Th>No</Th>
                                            <Th>Name</Th>
                                            <Th>Qty</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {shipment.content.exportDeclaration && 
                                        shipment.content.exportDeclaration.lineItems &&
                                        shipment.content.exportDeclaration.lineItems.length !== 0 ?
                                        shipment.content.exportDeclaration.lineItems.map(i => (
                                            <Tr  key={i.number}>
                                                <Td>{i.number}</Td>
                                                <Td>{i.description.split('-')[0]}</Td>
                                                <Td>{i.quantity.value}</Td>
                                            </Tr>                                            
                                        )):
                                        <Tr>
                                            <Td>No Items</Td>
                                            <Td></Td>
                                            <Td></Td>
                                        </Tr>     
                                        }
                                    </Tbody>
                                </Table>
                                </Box>
                            :
                                <Text fontSize={'sm'}>Document</Text>}
                            </Box>
                        </GridItem>
                    </SimpleGrid>




                    <SimpleGrid columns={2} columnGap={3} rowGap={3} w='full' mb='3'>
                        <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>                                
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>DHL</Text>                                    
                                <Stack spacing={'0'}>
                                    <HStack>
                                        <Text w='150px'>Invoice</Text>
                                        <Text>{shipment.dhlInvoiceNo}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Value</Text>
                                        <Text>{shipment.content.declaredValue}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Currency</Text>
                                        <Text>{shipment.content.declaredValueCurrency}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Term</Text>
                                        <Text>{shipment.content.incoterm}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Shipper Account</Text>
                                        <Text>{shipment.accounts[0].number}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Payer Account</Text>
                                        <Text>{shipment.accounts[1].number}</Text>
                                    </HStack>
                                    {Object.keys(shipment.valueAddedServices).length > 1 &&
                                        <HStack>
                                            <Text w='150px'>Insurance</Text>
                                            <Text>{shipment.valueAddedServices[shipment.valueAddedServices.map(s=>s.serviceCode).indexOf('II')].value} {shipment.valueAddedServices[shipment.valueAddedServices.map(s=>s.serviceCode).indexOf('II')].currency}</Text>
                                        </HStack>
                                    }
                                    <HStack>
                                        <Text w='150px'>Reference</Text>
                                        <Text>{shipment.customerReferences.length !== 0 && shipment.customerReferences[0].value}</Text>
                                    </HStack>
                                </Stack>
                            </Box>
                        </GridItem>

                        <GridItem colSpan={colSpan}>                            
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>SM</Text>
                                <Stack spacing={'0'}>
                                    <HStack>
                                        <Text w='150px'>Pre Code</Text>
                                        <Text>{shipment.preCode}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Agent</Text>
                                        <HStack>
                                            <Text mr='3'>{shipment.agent.name}</Text>

                                            <ShipmentChangeAgentModal disable={shipment.isPaid || shipment.isInvoiced} shipmentId={shipment._id} />
                                            {/* {shipment && (shipment.isPaid || shipment.isInvoiced) && 
                                                <Popover>
                                                    <PopoverTrigger>
                                                    <IconButton
                                                        variant='ghost'
                                                        colorScheme='red'
                                                        aria-label='Call Sage'
                                                        fontSize='20px'
                                                        icon={<MdWarning />}
                                                    />
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <PopoverArrow />
                                                        <PopoverCloseButton />
                                                        <PopoverHeader>Disabled</PopoverHeader>
                                                        <PopoverBody>Changing Agent is disabled if the shipment is invoiced or paid. please remove the shipment from invoice to change agent.</PopoverBody>
                                                    </PopoverContent>
                                                </Popover>
                                            } */}
                                        </HStack>                                        
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Sale</Text>
                                        <Text>{shipment.sale.name}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Created by</Text>
                                        <Text>{shipment.user.name}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>VPlus</Text>
                                        <Text>{shipment.isVplus ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Special Route</Text>
                                        <Text>{shipment.isSpecialRoute ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Text>
                                    </HStack>
                                </Stack>
                            </Box>
                        </GridItem>
                    </SimpleGrid>


                    <SimpleGrid columns={2} columnGap={3} rowGap={3} w='full' mb='3'>
                        <GridItem colSpan={colSpan}>
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>   
                                <HStack borderBottom='1px' mb='3'>
                                    <Text fontSize={'2xl'}  fontWeight={'700'} >Billing</Text> 
                                    <Spacer />
                                    
                                    
                                    <Button
                                        size='xs'
                                        h='20px'
                                        variant={'ghost'}
                                        colorScheme={'black'}
                                    >
                                        <MdEditNote size={25} />
                                    </Button>
                                </HStack>                             
                                                                 
                                <Stack spacing={'0'}>
                                    <HStack>
                                        <Text w='150px'>Invoice No</Text>
                                        <HStack>
                                            <Text>{shipment.isInvoiced ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Text>
                                            <Box
                                                className='clickableLink' 
                                                onClick={()=>navigate(`/invoice/${shipment.invoiceId._id}`)}
                                            >
                                            {shipment.smInvoiceNo}
                                            </Box>
                                        </HStack>
                                    </HStack>                                       
                                    
                                  
                                    <HStack>
                                        <Text w='150px'>Paid</Text>
                                        <Text>{shipment.isPaid ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}</Text>
                                    </HStack>

                                    {shipment.charges.length !== 0 ?
                                    <HStack>
                                        <HStack mb='3' w='150px' >  
                                            <Text >Charges </Text>  
                                            {user && user.isAdmin && <AddChargesModal disabled={shipment.isPaid} />}    
                                            {user && (user._id === shipment.user._id) && <AddChargesModal disabled={shipment.isPaid} />}                                    
                                            
                                        </HStack>  
                                        <OrderedList pl='30px' pr='2' py='1' my='1' fontSize={'sm'} border='1px solid grey' borderRadius={10}>
                                            {shipment.charges.map((ch, idx)=> (
                                                <ListItem key={idx}>
                                                    <HStack>
                                                        <Box>{ch.name} - </Box>
                                                        <Box mr='2'>{ch.amount}</Box>
                                                        {user && user.isAdmin &&                                                
                                                        <Button 
                                                            size='xs'
                                                            h='20px'
                                                            colorScheme={'red'}
                                                            variant={'outline'}
                                                            disabled={shipment.isPaid || ch.name === 'Special Route'}
                                                            onClick={()=>handleRemoveCharge(ch.name, ch.amount)}
                                                        >x</Button>
                                                        }
                                                    </HStack>
                                                </ListItem>
                                            ))}
                                        </OrderedList>                                                                        
                                    </HStack>
                                    :
                                    <HStack>  
                                        <Text w='150px' >Charges </Text>  
                                        <Text>No Charges </Text>  
                                        {user && user.isAdmin && <AddChargesModal disabled={shipment.isPaid} />  }                                  
                                        {user && (user._id === shipment.user._id) && <AddChargesModal disabled={shipment.isPaid} />}  
                                    </HStack>
                                    }
                                    <HStack>
                                        <Text w='150px'>Charges Total</Text>
                                        <Text>{shipment.charges.reduce((acc, ch) => acc + ch.amount, 0)} thb</Text>
                                    </HStack> 
                                    {shipment.discounted > 0 &&
                                    <HStack bg='green.200'>
                                        <Text w='150px'>Discounted</Text>
                                        <Text>{shipment.discounted} thb</Text>
                                    </HStack>
                                    }
                                    <HStack>
                                        <Text w='150px'>Shipping Cost</Text>
                                        <Text>{(shipment.discounted + shipment.totalAmount - shipment.charges.reduce((acc, ch) => acc + ch.amount, 0)).toLocaleString()} thb</Text>
                                        { user && user.isAdmin && <ShipmentCostUpdateModal disable={shipment.isPaid} shipmentId = {shipment._id} />}
                                    </HStack>    
                                    <HStack>
                                        <Text w='150px'>Total Amount</Text>
                                        <Text>{shipment.totalAmount.toLocaleString()} thb</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Rate Used</Text>
                                        <Text>{shipment.usedRate ? shipment.usedRate : '-'} thb</Text>
                                    </HStack>
                                   
                                </Stack>
                            </Box>
                        </GridItem>

                        <GridItem colSpan={colSpan} columnGap={3} rowGap={3}>                            
                            <Box bg='red.100' p='3' borderRadius={10} h='full'>
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>Return</Text>
                                <Stack spacing={'0'}>
                                   {shipment && shipment.isReturned ?
                                   <>
                                   <HStack>
                                        <Text w='150px'>AWB</Text>
                                        <Text>{shipment.returnData.shipment.awb}</Text>
                                    </HStack>
                                    <HStack>
                                        <Text w='150px'>Shipped Date</Text>
                                        <Text>{shipment.returnData.shipment.shippedDate.substring(0,10)}</Text>
                                    </HStack>  
                                    <HStack>
                                        <Text w='150px'>Deleted Date</Text>
                                        <Text>{shipment.returnData.shipment.deletedDate.substring(0,10)}</Text>
                                    </HStack>   
                                    <HStack>
                                        <Text w='150px'>Weight</Text>
                                        <Text>{shipment.returnData.shipment.weight} kg</Text>
                                    </HStack>   
                                    <HStack>
                                        <Text w='150px'>Amount</Text>
                                        <Text>{shipment.returnData.shipment.totalAmount} thb</Text>
                                    </HStack> 
                                    <HStack>
                                        <Text w='150px'>Invoice No</Text>
                                        <Text>{shipment.returnData.invoice.code}</Text>
                                    </HStack>    
                                    <HStack>
                                        <Text w='150px'>Invoice Amount</Text>
                                        <Text>{shipment.returnData.invoice.totalAmount} thb</Text>
                                    </HStack> 
                                    <HStack>
                                        <Text w='150px'>Note</Text>
                                        <Text>{shipment.returnData.note}</Text>
                                    </HStack>    
                                    </>  :
                                    <Text>No Return Data</Text> 
                                   }
                                </Stack>
                            </Box>
                        </GridItem>
                    </SimpleGrid>
 
                    

                    <SimpleGrid columns={2} columnGap={3} rowGap={3}>
                        <GridItem colSpan={colSpan}>                            
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <Text fontSize={'2xl'} mb='3' fontWeight={'700'} borderBottom='1px'>Documents</Text>
                                <Stack spacing={'0'}>
                                    {!shipment.documents ? <div>Data is cleared</div> : shipment.documents.map((d,i)=>(
                                        <Box key={i}>
                                            <HStack mb='2'>
                                                <Text fontSize={'lg'}>{d.typeCode && d.typeCode.toUpperCase()}</Text>
                                                <Spacer />
                                                <Box>
                                                    <Button
                                                        size='sm' h='25px' w='' mx='1' colorScheme='yellow'
                                                        onClick={()=>printBase64(d.content)}
                                                    >Print</Button>

                                                    <ViewDocumentModal doc = {d} fileName={shipment.awb + '-' + shipment.preCode} />

                                                    <a download={d.typeCode + '-' + shipment.awb + '-' + shipment.preCode} href={'data:application/pdf;base64,'+ d.content} >
                                                        <Button size='sm' h='25px' w='' mx='1' colorScheme='blue' >Download</Button>    
                                                    </a> 
                                                </Box>
                                            </HStack>
                                            <Divider />
                                        </Box>
                                    ))}
                                </Stack>
                            </Box>
                        </GridItem>

                        <GridItem colSpan={colSpan}>                            
                            <Box bg='blackAlpha.50' p='3' borderRadius={10} h='full'>
                                <HStack mb='3' borderBottom='1px' justifyContent={'space-between'}>
                                    <Text fontSize={'2xl'}  fontWeight={'700'} >Notes</Text>
                                    {loadingDeleteNote  && <MySpinner />}  
                                    <ShipmentAddNoteModal shipmentId={params.id} />
                                </HStack>
                                
                                <Stack spacing={'0'}>
                                    {errorDeleteNote && <Error error={errorDeleteNote} /> }
                                    {shipment.notes.map((n,i)=>(
                                        <Box key={i} w='100%' p='2' borderRadius={'20'} backgroundColor={'green.100'} mb='1'>
                                            <Stack spacing={'1'}>
                                                <HStack>
                                                    <HStack>
                                                    <Text fontSize={'sm'} fontWeight={'700'} >{n.name}</Text>
                                                    <Text fontSize={'12px'}>{n.date.substring(0,10)}</Text>
                                                    </HStack>
                                                    <Spacer />
                                                    {user._id === n.userId &&
                                                        <Button
                                                        size='xs'
                                                        colorScheme={'red'}
                                                        onClick={()=>handleDeleteNote(n._id)}
                                                    >x</Button>
                                                    }                                                
                                                </HStack>                                            
                                                <Text>{n.text}</Text>
                                            </Stack>
                                        </Box>
                                    ))}  
                                        
                                </Stack>
                            </Box>
                        </GridItem>

                    </SimpleGrid>
                  
                </Box>
            }

        </Box>
    )
}

export default ShipmentDetailScreen