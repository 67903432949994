export const INVOICE_LIST_REQUEST = 'INVOICE_LIST_REQUEST'
export const INVOICE_LIST_SUCCESS = 'INVOICE_LIST_SUCCESS'
export const INVOICE_LIST_FAIL = 'INVOICE_LIST_FAIL'
export const INVOICE_LIST_RESET = 'INVOICE_LIST_RESET'

export const INVOICE_CREATE_REQUEST = 'INVOICE_CREATE_REQUEST'
export const INVOICE_CREATE_SUCCESS = 'INVOICE_CREATE_SUCCESS'
export const INVOICE_CREATE_FAIL = 'INVOICE_CREATE_FAIL'
export const INVOICE_CREATE_RESET = 'INVOICE_CREATE_RESET'

export const INVOICE_BYID_REQUEST = 'INVOICE_BYID_REQUEST'
export const INVOICE_BYID_SUCCESS = 'INVOICE_BYID_SUCCESS'
export const INVOICE_BYID_FAIL = 'INVOICE_BYID_FAIL'
export const INVOICE_BYID_RESET = 'INVOICE_BYID_RESET'

export const INVOICE_MY_REQUEST = 'INVOICE_MY_REQUEST'
export const INVOICE_MY_SUCCESS = 'INVOICE_MY_SUCCESS'
export const INVOICE_MY_FAIL = 'INVOICE_MY_FAIL'
export const INVOICE_MY_RESET = 'INVOICE_MY_RESET'

export const INVOICE_DELETE_REQUEST = 'INVOICE_DELETE_REQUEST'
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS'
export const INVOICE_DELETE_FAIL = 'INVOICE_DELETE_FAIL'
export const INVOICE_DELETE_RESET = 'INVOICE_DELETE_RESET'

export const INVOICE_ADDSHIPMENTS_REQUEST = 'INVOICE_ADDSHIPMENTS_REQUEST'
export const INVOICE_ADDSHIPMENTS_SUCCESS = 'INVOICE_ADDSHIPMENTS_SUCCESS'
export const INVOICE_ADDSHIPMENTS_FAIL = 'INVOICE_ADDSHIPMENTS_FAIL'
export const INVOICE_ADDSHIPMENTS_RESET = 'INVOICE_ADDSHIPMENTS_RESET'

export const INVOICE_REMOVESHIPMENTS_REQUEST = 'INVOICE_REMOVESHIPMENTS_REQUEST'
export const INVOICE_REMOVESHIPMENTS_SUCCESS = 'INVOICE_REMOVESHIPMENTS_SUCCESS'
export const INVOICE_REMOVESHIPMENTS_FAIL = 'INVOICE_REMOVESHIPMENTS_FAIL'
export const INVOICE_REMOVESHIPMENTS_RESET = 'INVOICE_REMOVESHIPMENTS_RESET'