import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Spinner,
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    ModalFooter,
} from '@chakra-ui/react'
import { addBalance } from '../actions/userActions'

const AddbalanceToUserModal = ({userId}) => {
    const dispatch = useDispatch()
    const [amount, setAmount] = useState(0)
    const [note, setNote] = useState('')
    const [show, setShow] = useState(false)
    const {loading, error, success} = useSelector(state=>state.user.AddBalance)

    useEffect(()=>{
        if(success){
            setAmount(0)
            setShow(false)
        }
    },[dispatch, success])

    
    const handleClose = () => {
        setShow(false)
    }

    const handleSubmit = () => {
        const detail = {
            userId,
            amount,
            note,
        }
        dispatch(addBalance(detail))
    }


    return (
        <>
            <Button size='xs' colorScheme={'orange'} onClick={()=>setShow(true)} >Add Balance</Button>

            <Modal isOpen={show} onClose={handleClose} size='lg' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Balance</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>{loading && <Spinner label='Please wait...' speed='0.1s' thickness='5px' />}</Box>
                    <Box>
                        {error && 
                            <Alert status='error' variant='left-accent'>
                            <AlertIcon />
                            <AlertTitle mr={2}>Error</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        }
                    </Box>
                    <Box mb='20px'>
                        <Box >Amount</Box>
                        <Input 
                            type={'number'}
                            size='sm'
                            value={amount}
                            onChange={e=>setAmount(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <Box>Note</Box>
                        <Input 
                            type={'text'}
                            size='sm'
                            value={note}
                            onChange={e=>setNote(e.target.value.replace('-', '').replace(',',''))}
                        />
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button 
                        ms='auto' 
                        size='sm' 
                        colorScheme={'green'}
                        onClick={()=>handleSubmit()}
                        >Submit</Button>
                </ModalFooter>

            </ModalContent>
            </Modal>
        </>
    )
}

export default AddbalanceToUserModal