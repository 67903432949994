import React, {useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Spinner,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Input,
    Checkbox,
    Spacer,
    HStack,
  } from '@chakra-ui/react'
import Error from '../components/Error'
import { shipmentQty } from '../actions/rateActions'
import { MdCancel, MdCheckCircle } from 'react-icons/md'

const RateShipmentQtyModal = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [month, setMonth] = useState(new Date().toISOString().substring(0,7))
    const [active, setActive] = useState(false)
    const {loading, error, rateShipments} = useSelector(state=>state.rate.ShipmentQty)

    useEffect(()=>{
        if(show === true){
            dispatch(shipmentQty(month, active))
        }
        
    },[dispatch, month, show, active])

    const handleClose = () => {    
        setMonth(new Date().toISOString().substring(0,7))
        setActive(false)
        setShow(false)        
    }

    return (
    <>
        <Button size='sm' colorScheme={'orange'} onClick={()=>setShow(true)} >View Ship Qty</Button>

        <Modal isOpen={show} onClose={handleClose} size='md' closeOnOverlayClick={false} minW='800px'>
        <ModalOverlay />
        <ModalContent  maxW="1000px">
            <ModalHeader>{loading ? <Spinner label='Please wait' speed='0.1s' thickness='5px' /> : 'Rate Shipment Qty' }</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <HStack>
                <Input
                    type="month"
                    size='sm'
                    w='200px'
                    value={month}
                    onChange={e=>setMonth(e.target.value)}
                /> 
                <Spacer />
                <Checkbox
                    size='lg'
                    variant={'green'}
                    onChange={()=>setActive(!active)}
                >Show Active Only</Checkbox>
                </HStack>
                {error && <Error error={error} />}

                <Table size='sm' variant='striped' colorScheme='yellow' my='4' w='100%'>
                    <Thead>
                        <Tr>
                            <Th>No</Th>
                            <Th>Name</Th>
                            <Th>Effective Date</Th>
                            <Th>Created Date</Th>
                            <Th>Number of Agents</Th>
                            <Th>Shipment Qty</Th>
                            <Th>Active</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rateShipments && rateShipments.map((r, i)=>(
                            <Tr key={i}>
                                <Td>{i+1}</Td>
                                <Td>{r.name}</Td>
                                <Td>{r.effectDate.slice(0,10)}</Td>
                                <Td>{r.createdAt.slice(0,10)}</Td>
                                <Td>{r.userCount}</Td>
                                <Td>{r.shipCount}</Td>
                                <Td>
                                    {r.status ? < MdCheckCircle size={20} color='green' /> : < MdCancel size={20} color='red' />}                 
                                </Td>
                            </Tr>
                            
                        ))}
                    </Tbody>
                </Table>
                
            </ModalBody>

            
        </ModalContent>
        </Modal>
    </>
    )
}

export default RateShipmentQtyModal