import React from 'react'
import {
    Box,
    Alert,
    AlertDescription,
    AlertTitle,
    AlertIcon
} from '@chakra-ui/react'

const Error = ({error}) => {
    return (
        <Box my='2'>
            <Alert status='error' variant='left-accent'>
                <AlertIcon />
                <AlertTitle mr={2}>Error: </AlertTitle>
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        </Box>
    )
}

export default Error