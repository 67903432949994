import React from 'react'
import { Box, Button, Image, Show, Spacer, Text, Wrap, WrapItem,
    useDisclosure,
    Input,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useToast,
    InputGroup,
    InputRightElement,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Center,
    Divider,
} from '@chakra-ui/react'
import { MdArchive, MdAddModerator, MdDepartureBoard, MdMonetizationOn } from 'react-icons/md'
import {useState, useEffect} from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {loginUser} from '../actions/userActions'
import { checkUSRemoteArea } from '../actions/systemActions'
//import { REMOTE_AREA_CHECK_RESET } from '../constants/systemConstants'
import { getCountryList } from '../actions/countryActions'
import { trackShipment } from '../actions/shipmentActions'
import { SHIPMENT_TRACKING_RESET } from '../constants/shipmentConstants'

const LoiginScreenNew = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const toast = useToast()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [countryCode, setCountryCode] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [awb, setAwb] = useState('')
    const [show, setShow] = useState(false)
    const [awbWarning, setAwbWarning] = useState(false)
    const { loading:remoteLoading, error: remoteError, success, isRemote } = useSelector(state=>state.system.usRemoteArea)
    const {loading, error, user} = useSelector(state=> state.login)
    const {loading:countryLoading, countries, success: successCountry, error:countryError } = useSelector(state=>state.country.List)
    const {loading: trackingLoading, error: trackingError, trackingDetail} = useSelector(state=>state.shipment.Tracking)

    useEffect(()=>{
        dispatch(getCountryList())
        if(user && user.accountType === 'agent'){
            navigate('/agentdashboard')
        } else if (user && user.accountType === 'user') {
            navigate('/preshipment')
        } else if (user && user.accountType === 'sale'){
            navigate('/saledashboard')
        } else if(user && user.isAdmin) {
            navigate('/preshipment')
            //navigate('/admindashboard')
        } else {
            navigate('/')
        }        
    },[user, navigate, dispatch])

    useEffect(()=>{
        if(success){
            if(isRemote){
                toast({position: 'top', variant:'subtle', status:'success', description: `${postalCode} is remote area`, duration: 2000})
            } else {
                toast({position: 'top', variant:'subtle', status:'error', description: `${postalCode} is not remote area`, duration: 2000})
            }
        }
        if(error){
            toast({position: 'top', variant:'subtle', status:'warning', description: 'Invalid credential', duration: 2000})
        }
        if(remoteError){
            toast({position: 'top', variant:'subtle', status:'warning', description: 'Remote area check error', duration: 2000})
        }
        if(countryError){
            toast({position: 'top', variant:'subtle', status:'warning', description: 'Error getting country list', duration: 2000})
        }
        if(trackingError){
            toast({position: 'top', variant:'subtle', status:'warning', description: trackingError, duration: 5000})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[error, toast, remoteError, countryError, success, isRemote, trackingError, dispatch])

    

    const handleLogin = () => {
        if(email === ''){
            toast({position: 'top', variant:'subtle', status:'warning', description:'Email is required', duration: 2000})
            return
        } else if (password === ''){
            toast({position: 'top', variant:'subtle', status:'warning', description:'Password is required', duration: 2000})
            return
        } else {
            const credential = {
                email: email.toLowerCase(),
                password
            }
            dispatch(loginUser(credential))
        }        
    }

    const handleCheckRemoteArea = (e) => {
        e.preventDefault()
        if(countryCode === ''){
            toast({position: 'top', variant:'subtle', status:'warning', description:'Country is required', duration: 2000})
            return
        }
        if(postalCode === ''){
            toast({position: 'top', variant:'subtle', status:'warning', description:'Postal Code is required', duration: 2000})
            return
        }
        const pCode = postalCode.toUpperCase()
        dispatch(checkUSRemoteArea({postalCode:pCode, countryCode}))
    }

    return (
        <Box
            className='login-background'  
        >

                <Box 
                    display={'flex'} 
                    alignItems={'center'}
                    mx={'auto'}
                    p={4}
                    mb={3}
                    width={'90%'}
                >
                    <Text 
                        className='login-smv2'
                        textAlign={'center'}
                        fontWeight={{base: 500, md: 700, xl: 900}}
                        fontSize={{base:'30px', md: '35px', xl:'40px'}} 
                        // bg={'rgba(0,0,0,2)'}
                        // backdropFilter={'auto'} 
                        // backdropBlur={'10px'}
                    >SMV2</Text>
                    <Spacer />
                    {/* <Box>
                        <Button 
                            //colorScheme='yellow'
                            bg={'#F6CA9F'}
                            color={'gray.700'}
                            _hover={{backgroundColor:'yellow.500'}}
                            ref={btnRef}
                            onClick={onOpen}
                            mr={3}
                            //size={buttonSize}
                            size={'sm'}
                        >Tracking Shipment</Button>
                        <Button 
                            bg={'#263751'}
                            color={'#f6ca9f'}
                            _hover={{backgroundColor:'gray.700'}}
                            w={'100px'}
                            size={'sm'}
                        >Login</Button>
                    </Box> */}
                </Box>


                <Box 
                    maxW={'1300px'}
                    mx={'auto'}
                    display={'flex'}                     
                    alignItems={'center'}
                    justifyContent={'space-between'} 
                    paddingTop={{base: '50px', lg: '80px'}}
                    color={'gray.500'}
                >
                    <Box ps={5} >
                        <Text                             
                            className='login-heading'
                            fontSize={{base: '30px', md: '40px', lg: '50px'}}
                        >We Deliver Your Box</Text>
                        <Text fontSize={{base: '1.2rem', md: '1.3rem', lg: '1.4rem'}}>With 20 years of experience in international air, sea shipping.</Text>
                        <Text fontSize={{base: '1.3rem', md: '1.4rem', lg: '1.5rem'}}>Shipped over <span style={{color:'#f6ca9f'}} >500,000+</span> boxes and still counting.</Text>
                    </Box>
                    <Show above='md'>
                        <Box w={'50%'} display={'flex'} justifyContent={'center'}>
                            <Image src={'./cargo2.png'} alt='cargo' w={'700px'}  h={'auto'} className='login-image' />
                        </Box>
                    </Show>
                </Box>

                <Box 
                    my={'50px'}
                    mx={'auto'}
                    w={{base: '90%',sm: '85%', md:'80%', lg:'75%', xl:'70%'}}
                    display={'flex'}
                    flexDir={{base: 'column', lg: 'row'}}
                    justifyContent={'space-between'}                    
                >
                    {/* <Box flex={1} p={4} bg={'#f6ca9f'} color={'gray.800'}>
                        <Box mb={'30px'} textAlign={'center'} fontSize={'lg'} fontWeight={800} >REMOTE AREA CHECK</Box>
                        <Box w={'90%'} mx={'auto'} mb={5}>
                            <Box fontSize='sm'>{countryLoading ? 'Loading' : 'Country'}</Box>
                            <Select 
                                id={'country-select'}
                                placeholder='Select Country' 
                                variant='outline' 
                                borderRadius={5} 
                                borderColor={'gary.800'} 
                                _hover={{borderColor:'gray.800'}}
                                _focus={{borderColor:'gray.800'}}
                                mb={4} 
                                onChange={({target})=>setCountryCode(target.value)}
                            >
                                {successCountry && countries.map((c)=>(
                                    <option key={c.name} value={c.countryCode}>{c.name}</option>
                                ))}
                            </Select>
                            <Box fontSize='sm'>Postal Code</Box>
                            <Input 
                                id={'postal-code-input'}
                                variant='outline' 
                                borderRadius={5} 
                                borderColor={'gray.800'}
                                _hover={{borderColor:'gray.800'}}
                                _focus={{borderColor:'gray.800'}}
                                mb={4}
                                // onKeyDown={(e)=>{
                                //     if(e.key === 'Enter'){
                                //         dispatch(checkUSRemoteArea(e.target.value))
                                //     }
                                //     dispatch({type: REMOTE_AREA_CHECK_RESET})
                                //     return
                                // }}
                                onChange={({target})=>setPostalCode(target.value)}
                            />
                            <Button
                                isLoading={remoteLoading}
                                w={'100%'}
                                variant='outline'
                                borderColor={'gray.800'}
                                _hover={{bg:'#263751', color:'gray.300'}}
                                onClick={handleCheckRemoteArea}
                            >Check</Button>
                        </Box>
                    </Box> */}

                    <Box flex={1} p={4} bg={'#f6ca9f'} color={'gray.800'}>
                        <Box mb={'30px'} textAlign={'center'} fontSize={'lg'} fontWeight={800} >DHL Tracking</Box>
                        <Box w={'90%'} mx={'auto'} mb={5}>
                           
                            <Box fontSize='sm'>Type AWB</Box>
                            <Box display={'flex'}>
                                <Input 
                                    type={'text'}
                                    value={awb}
                                    maxLength={10}
                                    flex={1}
                                    id={'postal-code-input'}
                                    variant='outline' 
                                    borderRadius={5} 
                                    borderColor={'gray.800'}
                                    _hover={{borderColor:'gray.800'}}
                                    _focus={{borderColor:'gray.800'}}
                                    onChange={({target})=>{
                                        setAwbWarning(false)
                                        dispatch({type: SHIPMENT_TRACKING_RESET})
                                        setAwb(target.value.replace(/\D/g, ""))
                                    }}
                                />
                                <Button
                                    isLoading={trackingLoading}
                                    w={'100px'}
                                    ms={3}
                                    variant='outline'                                    
                                    borderColor={'gray.800'}
                                    _hover={{bg:'#263751', color:'gray.300'}}
                                    onClick={()=>{
                                        if(awb.length !== 10){
                                            setAwbWarning(true)
                                        }
                                        dispatch(trackShipment(awb))
                                    }}
                                >Track</Button>
                            </Box>
                            {awbWarning && <Box fontSize={'xs'} color='red.700'>awb must be 10 digit numbers</Box>}
                          
                            {trackingDetail && trackingDetail?.length === 0 ? 
                            <Box shadow='lg' p='2' mt={4}>Shipment Created</Box>   : 
                            <Box mt={4}>
                                {trackingDetail && <Box shadow='lg' p='2' >
                                    <Center fontSize={'2xl'} fontWeight={'500'} mb='4'>
                                    {new Date(trackingDetail[trackingDetail.length-1].date)?.toLocaleString('en', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}
                                    </Center>
                                    {trackingDetail[trackingDetail.length-1].evs.reverse().map((d,idx)=>(
                                        <Box key={idx} mb='3'>
                                            <Box>{d.description}</Box>
                                            <Box fontSize={'sm'} mb='2' >{d.time}, {d.location}</Box>
                                            <Divider />
                                        </Box>
                                    ))}
                                </Box>}
                                {/* {trackingDetail && trackingDetail.slice(0).reverse().map((e,i)=>(
                                    <Box key={i} shadow='lg' p='2' >
                                        <Center fontSize={'2xl'} fontWeight={'500'} mb='4'>
                                        {new Date(e.date).toLocaleString('en', { weekday:"long", year:"numeric", month:"short", day:"numeric"})}
                                        </Center>
                                        {e.evs.reverse().map((d,idx)=>(
                                            <Box key={idx} mb='3'>
                                                <Box>{d.description}</Box>
                                                <Box fontSize={'sm'} mb='2' >{d.time}, {d.location}</Box>
                                                <Divider />
                                            </Box>
                                        ))}
                                    </Box>
                                ))} */}

                            </Box> 
                            }
                        </Box>
                    </Box>


                    <Box flex={1} bg={'#263751'}  p={4}>
                        <Box mb={'30px'} textAlign={'center'} fontSize={'lg'} fontWeight={800}>LOGIN</Box>
                        
                        <Box w={'90%'} mx={'auto'} mb={5}>

                            {/* {error && 
                                <Box
                                    p={3}
                                    bg='red.200'
                                    borderRadius={5}
                                    color={'gray.800'}
                                    mb={3}
                                    display={'flex'}
                                ><MdHelp size={23} />{error}</Box>
                            } */}
                            <Box fontSize='sm'>Email</Box>
                            <Input 
                                mb={4}
                                type='email' 
                                borderRadius={5} 
                                variant='outline'
                                borderColor={'#f6ca9f'}
                                _hover={{borderColor:'#f6ca9f'}}
                                _focus={{borderColor:'#f6ca9f'}}
                                id='email'
                                autoComplete='false'
                                value={email}
                                onChange={e=>setEmail(e.target.value)}
                            />                          

                            <Box fontSize='sm'>Password</Box>
                            <InputGroup mb={4}>
                                <Input  
                                    variant='outline'
                                    borderRadius={5} 
                                    borderColor={'#f6ca9f'}
                                    _hover={{borderColor:'#f6ca9f'}}
                                    _focus={{borderColor:'#f6ca9f'}}
                                    type={show ? 'text' : 'password'}
                                    id='password'
                                    autoComplete='false'
                                    value={password}
                                    onChange={e=>setPassword(e.target.value)}
                                    onKeyDown={e=>{
                                        if(e.key === 'Enter'){
                                            handleLogin()
                                        }
                                    }}
                                />
                                <InputRightElement width='4.5rem'>
                                    <Button  
                                        size='xs' 
                                        mr={2}
                                        w={'80px'}
                                        onClick={()=>setShow(prev=>!prev)} 
                                        variant='ghost'
                                        _focus={{borderColor:'#f6ca9f'}}
                                        _hover={{bg:'#f6ca9f', color:'gray.800'}}
                                    >
                                        {show ? 'Hide' : 'Show'}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>      

                           
                            <Button
                                w={'100%'}
                                isLoading={loading} 
                                variant='outline'
                                borderColor={'#f6ca9f'}
                                _focus={{borderColor:'#f6ca9f'}}
                                _hover={{bg:'#f6ca9f', color:'gray.800'}}
                                onClick={handleLogin}
                                
                            >Login</Button>
                        </Box>

                    </Box>
                </Box>

                <Box width={'95%'} mx={'auto'}  marginTop={'50px'} py={'30px'} >
                    <Wrap justify='center'>
                        <WrapItem>
                            <Box w={'300px'} mx={'30px'} my={'20px'} display={'flex'} className='login-list-items' borderRadius={10}>
                                <MdArchive size={70} color='#b70000' />
                                <Box display={'flex'} ps={2} alignItems={'center'}>
                                <Text  color={'gray.300'}>Re-packing with safety standard</Text>
                                </Box>
                            </Box>
                        </WrapItem>
                        <WrapItem>
                            <Box w={'300px'} mx={'30px'} my={'20px'} display={'flex'} className='login-list-items' borderRadius={10}>
                                <MdDepartureBoard size={70} color='#b70000' />
                                <Box display={'flex'} ps={2} alignItems={'center'}>
                                <Text  color={'gray.300'}>Deliver on time</Text>
                                </Box>
                            </Box>
                        </WrapItem>
                        <WrapItem>
                            <Box w={'300px'} mx={'30px'} my={'20px'} display={'flex'} className='login-list-items' borderRadius={10}>
                                <MdAddModerator size={70} color='#b70000' />
                                <Box display={'flex'} ps={2} alignItems={'center'}>
                                <Text  color={'gray.300'}>Insured and secured</Text>
                                </Box>
                            </Box>
                        </WrapItem>
                        
                        <WrapItem>
                            <Box w={'300px'} mx={'30px'} my={'20px'} display={'flex'} className='login-list-items' borderRadius={10}>
                                <MdMonetizationOn size={70} color='#b70000' />
                                <Box display={'flex'} ps={2} alignItems={'center'}>
                                <Text  color={'gray.300'}>Affordable and fair rate</Text>
                                </Box>
                            </Box>
                        </WrapItem>
                        
                    </Wrap>
                </Box>

                <Modal
                    onClose={onClose}
                    isOpen={isOpen}
                    motionPreset='slideInBottom'
                >
                    <ModalOverlay />
                    <ModalContent bg={'gray.800'} color={'gray.200'}>
                    <ModalHeader>Shipment Tracking</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody color={'gray.900'}>
                        <Alert status='info'>
                            <AlertIcon />
                            <AlertTitle>This feature is coming soon.</AlertTitle>
                            <AlertDescription>Stay tuned!</AlertDescription>
                        </Alert>
                    </ModalBody>
                    
                    <ModalFooter>
                        {/* <Button variant='outline' w={'100%'}>Track</Button> */}
                    </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* <Box className='login-footer' mt={'50px'}>
                    <Box w={{ sm: '90%', md: '60%', lg:'70%', xl:'80%'}} px={'5%'}>
                        <Box display={'flex'} gap={4} mb={4}>
                            <Image src={'./fb.png'} alt={'fblogo'} w={'50px'} borderRadius={10} p={'1px'} />
                            <Image src={'./vb.png'} alt={'vblogo'} w={'50px'} />
                            <Image src={'./ph.png'} alt={'phlogo'} w={'50px'} p={'1px'} />

                        </Box>
                        <Box>Summit Asia Cargo {new Date().getFullYear()}</Box>
                    </Box>
                </Box> */}
          
        </Box>
    )
}

export default LoiginScreenNew