import axios from 'axios'
import {
    PRESHIPMENT_CREATE_REQUEST,
    PRESHIPMENT_CREATE_SUCCESS,
    PRESHIPMENT_CREATE_FAIL,
    PRESHIPMENT_LIST_REQUEST,
    PRESHIPMENT_LIST_SUCCESS,
    PRESHIPMENT_LIST_FAIL,
    PRESHIPMENT_CANCEL_REQUEST,
    PRESHIPMENT_CANCEL_SUCCESS,
    PRESHIPMENT_CANCEL_FAIL,
    PRESHIPMENT_BYID_REQUEST,
    PRESHIPMENT_BYID_SUCCESS,
    PRESHIPMENT_BYID_FAIL,
    PRESHIPMENT_ADD_REMOVE_ITEM_REQUEST,
    PRESHIPMENT_ADD_REMOVE_ITEM_SUCCESS,
    PRESHIPMENT_ADD_REMOVE_ITEM_FAIL,
    PRESHIPMENT_ITEM_QTY_REQUEST,
    PRESHIPMENT_ITEM_QTY_SUCCESS,
    PRESHIPMENT_ITEM_QTY_FAIL,
    PRESHIPMENT_BOXSIZE_REQUEST,
    PRESHIPMENT_BOXSIZE_SUCCESS,
    PRESHIPMENT_BOXSIZE_FAIL,
    SHIPMENT_CREATE_REQUEST,
    SHIPMENT_CREATE_SUCCESS,
    SHIPMENT_CREATE_FAIL,
    SHIPMENT_GET_COST_REQUEST,
    SHIPMENT_GET_COST_SUCCESS,
    SHIPMENT_GET_COST_FAIL,
    SHIPMENT_LIST_REQUEST,
    SHIPMENT_LIST_SUCCESS,
    SHIPMENT_LIST_FAIL,
    SHIPMENT_BYID_REQUEST,
    SHIPMENT_BYID_SUCCESS,
    SHIPMENT_BYID_FAIL,
    PRESHIPMENT_BOXEDIT_REQUEST,
    PRESHIPMENT_BOXEDIT_SUCCESS,
    PRESHIPMENT_BOXEDIT_FAIL,
    SHIPMENT_CANCEL_REQUEST,
    SHIPMENT_CANCEL_SUCCESS,
    SHIPMENT_CANCEL_FAIL,
    SHIPMENT_GETCONTACTFOR_PRESHIP_REQUEST,
    SHIPMENT_GETCONTACTFOR_PRESHIP_SUCCESS,
    SHIPMENT_GETCONTACTFOR_PRESHIP_FAIL,
    PRESHIPMENT_SAVECONTACT_REQUEST,
    PRESHIPMENT_SAVECONTACT_SUCCESS,
    PRESHIPMENT_SAVECONTACT_FAIL,
    SHIPMENT_ADDNOTE_REQUEST,
    SHIPMENT_ADDNOTE_SUCCESS,
    SHIPMENT_ADDNOTE_FAIL,
    SHIPMENT_DELETENOTE_REQUEST,
    SHIPMENT_DELETENOTE_SUCCESS,
    SHIPMENT_DELETENOTE_FAIL,
    SHIPMENT_FORINVOICE_REQUEST,
    SHIPMENT_FORINVOICE_SUCCESS,
    SHIPMENT_FORINVOICE_FAIL,
    SHIPMENT_TRACKING_REQUEST,
    SHIPMENT_TRACKING_SUCCESS,
    SHIPMENT_TRACKING_FAIL,
    SHIPMENT_MY_REQUEST,
    SHIPMENT_MY_SUCCESS,
    SHIPMENT_MY_FAIL,
    SHIPMENT_RETURNED_REQUEST,
    SHIPMENT_RETURNED_SUCCESS,
    SHIPMENT_RETURNED_FAIL,
    PRESHIPMENT_AGENT_RECEIVER_UPDATE_REQUEST,
    PRESHIPMENT_AGENT_RECEIVER_UPDATE_SUCCESS,
    PRESHIPMENT_AGENT_RECEIVER_UPDATE_FAIL,
    SHIPMENT_SCANNED_REQUEST,
    SHIPMENT_SCANNED_SUCCESS,
    SHIPMENT_SCANNED_FAIL,
    SHIPMENT_GET_SCANNED_REQUEST,
    SHIPMENT_GET_SCANNED_SUCCESS,
    SHIPMENT_GET_SCANNED_FAIL,
    PRESHIPMENT_NOTE_UPDATE_REQUEST,
    PRESHIPMENT_NOTE_UPDATE_SUCCESS,
    PRESHIPMENT_NOTE_UPDATE_FAIL,
    PRESHIPMENT_SAVESHIPPER_REQUEST,
    PRESHIPMENT_SAVESHIPPER_SUCCESS,
    PRESHIPMENT_SAVESHIPPER_FAIL,
    PRESHIPMENT_SAVE_DHL_SHIPPER_REQUEST,
    PRESHIPMENT_SAVE_DHL_SHIPPER_SUCCESS,
    PRESHIPMENT_SAVE_DHL_SHIPPER_FAIL,
    SHIPMENT_ADDCHARGE_REQUEST,
    SHIPMENT_ADDCHARGE_SUCCESS,
    SHIPMENT_ADDCHARGE_FAIL,
    SHIPMENT_SALEBILLING_REQUEST,
    SHIPMENT_SALEBILLING_SUCCESS,
    SHIPMENT_SALEBILLING_FAIL,
    SHIPMENT_USERSHIPCOUNT_REQUEST,
    SHIPMENT_USERSHIPCOUNT_SUCCESS,
    SHIPMENT_USERSHIPCOUNT_FAIL,
    SHIPMENT_DELETED_LIST_REQUEST,
    SHIPMENT_DELETED_LIST_SUCCESS,
    SHIPMENT_DELETED_LIST_FAIL,
    SHIPMENT_SHIPANDSCAN_REQUEST,
    SHIPMENT_SHIPANDSCAN_SUCCESS,
    SHIPMENT_SHIPANDSCAN_FAIL,
    SHIPMENT_DAILYREPORT_REQUEST,
    SHIPMENT_DAILYREPORT_SUCCESS,
    SHIPMENT_DAILYREPORT_FAIL,
    SHIPMENT_CHANGEAGENT_REQUEST,
    SHIPMENT_CHANGEAGENT_SUCCESS,
    SHIPMENT_CHANGEAGENT_FAIL,
    SHIPMENT_STATUS_REQUEST,
    SHIPMENT_STATUS_SUCCESS,
    SHIPMENT_STATUS_FAIL,
    PRESHIPMENT_CURRENCY_UPDATE_REQUEST,
    PRESHIPMENT_CURRENCY_UPDATE_SUCCESS,
    PRESHIPMENT_CURRENCY_UPDATE_FAIL,
    SHIPMENT_STATUS_SALE_REQUEST,
    SHIPMENT_STATUS_SALE_SUCCESS,
    SHIPMENT_STATUS_SALE_FAIL,
    SHIPMENT_STATUS_EACH_REQUEST,
    SHIPMENT_STATUS_EACH_SUCCESS,
    SHIPMENT_STATUS_EACH_FAIL,
    SHIPMENT_STATUS_MY_REQUEST,
    SHIPMENT_STATUS_MY_SUCCESS,
    SHIPMENT_STATUS_MY_FAIL,
    SHIPMENT_AGENTBILLING_REQUEST,
    SHIPMENT_AGENTBILLING_SUCCESS,
    SHIPMENT_AGENTBILLING_FAIL,
    SHIPMENT_MYAGENTBILLING_REQUEST,
    SHIPMENT_MYAGENTBILLING_SUCCESS,
    SHIPMENT_MYAGENTBILLING_FAIL,
    SHIPMENT_UPDATE_COST_REQUEST,
    SHIPMENT_UPDATE_COST_SUCCESS,
    SHIPMENT_UPDATE_COST_FAIL,
    PRESHIPMENT_ITEM_SORTING_REQUEST,
    PRESHIPMENT_ITEM_SORTING_SUCCESS,
    PRESHIPMENT_ITEM_SORTING_FAIL,
    PRESHIPMENT_VPLUS_REQUEST,
    PRESHIPMENT_VPLUS_SUCCESS,
    PRESHIPMENT_VPLUS_FAIL,
    SHIPMENT_VPLUS_REQUEST,
    SHIPMENT_VPLUS_SUCCESS,
    SHIPMENT_VPLUS_FAIL,
    PRESHIPMENT_SHIPTYPE_REQUEST,
    PRESHIPMENT_SHIPTYPE_SUCCESS,
    PRESHIPMENT_SHIPTYPE_FAIL,
    PRESHIPMENT_SPECIALROUTE_REQUEST,
    PRESHIPMENT_SPECIALROUTE_SUCCESS,
    PRESHIPMENT_SPECIALROUTE_FAIL,
    SHIPMENT_UPDATE_AWB_REQUEST,
    SHIPMENT_UPDATE_AWB_SUCCESS,
    SHIPMENT_UPDATE_AWB_FAIL
} from '../constants/shipmentConstants'


export const createPreshipment = (newPreShipment) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/preshipments`, newPreShipment, config)
        dispatch({type: PRESHIPMENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listPreshipment = (keyword='', agent='', date='', shipped='') => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/preshipments?keyword=${keyword}&agent=${agent}&date=${date}&shipped=${shipped}`, config)
        dispatch({type: PRESHIPMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const cancelPreshipment = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_CANCEL_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/preshipments/${id}`, config)
        dispatch({type: PRESHIPMENT_CANCEL_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_CANCEL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getPreshipment = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/preshipments/${id}`, config)
        dispatch({type: PRESHIPMENT_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addRemoveItemPreshipment = (dataToAdd) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_ADD_REMOVE_ITEM_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/preshipments/item`, dataToAdd, config)
        dispatch({type: PRESHIPMENT_ADD_REMOVE_ITEM_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_ADD_REMOVE_ITEM_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const itemQtyPreshipment = (qtyData) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_ITEM_QTY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/preshipments/qty`, qtyData, config)
        dispatch({type: PRESHIPMENT_ITEM_QTY_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_ITEM_QTY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const boxPreshipment = (boxData) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_BOXSIZE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/preshipments/boxsize`, {boxData}, config)
        dispatch({type: PRESHIPMENT_BOXSIZE_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_BOXSIZE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const sortItems = (sortData) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_ITEM_SORTING_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/preshipments/sorting`, {sortData}, config)
        dispatch({type: PRESHIPMENT_ITEM_SORTING_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_ITEM_SORTING_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const markVplus = (preId, isVplus) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_VPLUS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/preshipments/vplus`, {preId, isVplus}, config)
        dispatch({type: PRESHIPMENT_VPLUS_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_VPLUS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const makeSepcialRoute = (preId, isSpecialRoute) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_SPECIALROUTE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/preshipments/specialroute`, {preId, isSpecialRoute}, config)
        dispatch({type: PRESHIPMENT_SPECIALROUTE_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_SPECIALROUTE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateShipType = (shipType) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_SHIPTYPE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/preshipments/shiptype`, {shipType}, config)
        dispatch({type: PRESHIPMENT_SHIPTYPE_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_SHIPTYPE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const editBoxPreshipment = (boxData) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_BOXEDIT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/preshipments/boxedit`, {boxData}, config)
        dispatch({type: PRESHIPMENT_BOXEDIT_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_BOXEDIT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const saveContactPreshipment = (contactData) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_SAVECONTACT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/preshipments/savecontact`, {contactData}, config)
        dispatch({type: PRESHIPMENT_SAVECONTACT_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_SAVECONTACT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const saveShipperPreshipment = (contactData) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_SAVESHIPPER_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/preshipments/saveshipper`, {contactData}, config)
        dispatch({type: PRESHIPMENT_SAVESHIPPER_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_SAVESHIPPER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const saveDhlShipperPreshipment = (data) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_SAVE_DHL_SHIPPER_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/preshipments/savedhlshipper`, data, config)
        dispatch({type: PRESHIPMENT_SAVE_DHL_SHIPPER_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_SAVE_DHL_SHIPPER_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const updateCurrencyPreshipment = (currencyData) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_CURRENCY_UPDATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/preshipments/currency`, {currencyData}, config)
        dispatch({type: PRESHIPMENT_CURRENCY_UPDATE_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_CURRENCY_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const eidtAgentReceiverPreShipment = (id, editedData) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_AGENT_RECEIVER_UPDATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/preshipments/${id}`, {editedData}, config)
        dispatch({type: PRESHIPMENT_AGENT_RECEIVER_UPDATE_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_AGENT_RECEIVER_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const createShipment = (dhlData, smData) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/shipments`, {dhlData, smData}, config)
        dispatch({type: SHIPMENT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addNoteShipment = (noteData) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_ADDNOTE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/shipments/note`, {noteData}, config)
        dispatch({type: SHIPMENT_ADDNOTE_SUCCESS})
    } catch (error) {
        dispatch({
            type: SHIPMENT_ADDNOTE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateChargesShipment = (chargeData) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_ADDCHARGE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/shipments/charge`, {chargeData}, config)
        dispatch({type: SHIPMENT_ADDCHARGE_SUCCESS})
    } catch (error) {
        dispatch({
            type: SHIPMENT_ADDCHARGE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addNotePreShipment = (noteData) => async (dispatch, getState) => {
    try {
        dispatch({type: PRESHIPMENT_NOTE_UPDATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/preshipments/note`, {noteData}, config)
        dispatch({type: PRESHIPMENT_NOTE_UPDATE_SUCCESS})
    } catch (error) {
        dispatch({
            type: PRESHIPMENT_NOTE_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const deleteNoteShipment = (noteData) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_DELETENOTE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/shipments/note`, {noteData}, config)
        dispatch({type: SHIPMENT_DELETENOTE_SUCCESS})
    } catch (error) {
        dispatch({
            type: SHIPMENT_DELETENOTE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const getCostShipment = (costData) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_GET_COST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/shipments/getcost`, costData, config)
        dispatch({type: SHIPMENT_GET_COST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_GET_COST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const listShipments = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: SHIPMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const listDeletedShipments = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_DELETED_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/deleted?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: SHIPMENT_DELETED_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_DELETED_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myShipments = (pageNumber = '', itemCount = '', keyword = '', date = '') => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/myshipments?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}&date=${date}`, config)
        dispatch({type: SHIPMENT_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myFilteredShipments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/shipments/myfilteredshipments`,{filterData}, config)
        dispatch({type: SHIPMENT_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const filterShipments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/shipments/filter`, {filterData}, config)
        dispatch({type: SHIPMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const pullDataShipments = (filterData) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/shipments/pulldata`, {filterData}, config)
        dispatch({type: SHIPMENT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const listInvoiceShipments = (agentId, date) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_FORINVOICE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/invoice?agentId=${agentId}&date=${date}`, config)
        dispatch({type: SHIPMENT_FORINVOICE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_FORINVOICE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getShipmentById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_BYID_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/${id}`, config)
        dispatch({type: SHIPMENT_BYID_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_BYID_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const trackShipment = (awb) => async (dispatch, getState) => {
    try {
        
        dispatch({type: SHIPMENT_TRACKING_REQUEST})
        // const {login: {user}} = getState()
        // const config = {headers: {Authorization: `Bearer ${user.token}`}}
        // const {data} = await axios.get(`/api/shipments/tracking/${awb}`, config)
        const {data} = await axios.get(`/api/shipments/tracking/${awb}`)
        dispatch({type: SHIPMENT_TRACKING_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_TRACKING_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getContactsForPreship = () => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_GETCONTACTFOR_PRESHIP_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/contactforpreship`, config)
        dispatch({type: SHIPMENT_GETCONTACTFOR_PRESHIP_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_GETCONTACTFOR_PRESHIP_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const cancelShipment = (id, deleteNote) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_CANCEL_REQUEST})
        const {login: {user}} = getState()
        const config = {data: deleteNote, headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/shipments/${id}`, config)
        dispatch({type: SHIPMENT_CANCEL_SUCCESS})
    } catch (error) {
        dispatch({
            type: SHIPMENT_CANCEL_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const returnedShipment = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_RETURNED_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/shipments/returned/${id}`, {}, config)
        dispatch({type: SHIPMENT_RETURNED_SUCCESS})
    } catch (error) {
        dispatch({
            type: SHIPMENT_RETURNED_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const scannedShipment = (awbpreid) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_SCANNED_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.post(`/api/shipments/scanned`, {awbpreid}, config)
        dispatch({type: SHIPMENT_SCANNED_SUCCESS})
    } catch (error) {
        dispatch({
            type: SHIPMENT_SCANNED_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getSannedShipment = (date='') => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_GET_SCANNED_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/scanned?date=${date}`, config)
        dispatch({type: SHIPMENT_GET_SCANNED_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_GET_SCANNED_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const saleBilling = (start='', end='') => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_SALEBILLING_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/salebilling?start=${start}&end=${end}`, config)
        dispatch({type: SHIPMENT_SALEBILLING_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_SALEBILLING_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const agentBilling = (start='', end='') => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_AGENTBILLING_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/agentbilling?start=${start}&end=${end}`, config)
        dispatch({type: SHIPMENT_AGENTBILLING_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_AGENTBILLING_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myAgentBilling = (start='', end='') => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_MYAGENTBILLING_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/myagentbilling?start=${start}&end=${end}`, config)
        dispatch({type: SHIPMENT_MYAGENTBILLING_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_MYAGENTBILLING_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const countUserShip = (date='') => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_USERSHIPCOUNT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/shipcountperuser?date=${date}`, config)
        dispatch({type: SHIPMENT_USERSHIPCOUNT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_USERSHIPCOUNT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const shipScanList = () => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_SHIPANDSCAN_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/shipandscan`, config)
        dispatch({type: SHIPMENT_SHIPANDSCAN_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_SHIPANDSCAN_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const dailyShipments = (date='', agentId='') => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_DAILYREPORT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/daily?date=${date}&agentId=${agentId}`, config)
        dispatch({type: SHIPMENT_DAILYREPORT_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_DAILYREPORT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const editAgentShipment = (newAgent) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_CHANGEAGENT_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/shipments/agentupdate`, {newAgent}, config)
        dispatch({type: SHIPMENT_CHANGEAGENT_SUCCESS})
    } catch (error) {
        dispatch({
            type: SHIPMENT_CHANGEAGENT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const statusShipments = () => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_STATUS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/shipmentstatus`, config)
        dispatch({type: SHIPMENT_STATUS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_STATUS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const saleStatusShipments = () => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_STATUS_SALE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/saleshipmentstatus`, config)
        dispatch({type: SHIPMENT_STATUS_SALE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_STATUS_SALE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const eachStatusShipments = (saleId, statusCode) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_STATUS_EACH_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/eachshipmentstatus?saleId=${saleId}&statusCode=${statusCode}`, config)
        dispatch({type: SHIPMENT_STATUS_EACH_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_STATUS_EACH_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const myShipmentsStatus = () => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_STATUS_MY_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/mystatus`, config)
        dispatch({type: SHIPMENT_STATUS_MY_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_STATUS_MY_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateCostShipment = (newCost, shipmentId) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_UPDATE_COST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/shipments/cost`, {newCost, shipmentId}, config)
        dispatch({type: SHIPMENT_UPDATE_COST_SUCCESS})
    } catch (error) {
        dispatch({
            type: SHIPMENT_UPDATE_COST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateAWBShipment = (newAWB, shipmentId) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_UPDATE_AWB_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/shipments/awb`, {newAWB, shipmentId}, config)
        dispatch({type: SHIPMENT_UPDATE_AWB_SUCCESS})
    } catch (error) {
        dispatch({
            type: SHIPMENT_UPDATE_AWB_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const getVplusShipments = (date='', isScanned) => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPMENT_VPLUS_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipments/vplus?date=${date}&isScanned=${isScanned}`, config)
        dispatch({type: SHIPMENT_VPLUS_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPMENT_VPLUS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}