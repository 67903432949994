import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Spacer,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    FormControl,
    FormLabel,
    Input,
    Spinner,
    Alert,
    AlertIcon,
    AlertDescription,
    AlertTitle,
    Box
  } from '@chakra-ui/react'
import { itemQtyPreshipment } from '../actions/shipmentActions'
import { PRESHIPMENT_ITEM_QTY_RESET } from '../constants/shipmentConstants'
import { MdOutlineSettings } from 'react-icons/md'


const ShipmentPreItemEditModal = ({ disable, item, currency}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const preId = params.id
    const itemId = item.item._id
    const [show, setShow] = useState(false)
    const [totalQty, setTotalQty] = useState(item.qty || 0)
    const [note, setNote] = useState(item.note || '')
    const [weight, setWeight] = useState(item.weight || 0)
    const [price, setPrice] = useState(item.price || 0)

    const {loading, error, success} = useSelector(state=>state.preshipment.ItemQty)

    useEffect(()=>{
        if(success){
            dispatch({type: PRESHIPMENT_ITEM_QTY_RESET})
            setShow(false)
        }
    },[dispatch, success])
    const onClose = () => {
        setShow(false)
    }

    const handleSubmit = () => {
        const newQty = {
            preId,
            itemId,
            qty: totalQty === "" ? 0 : totalQty,
            weight: weight === "" ? 0 : weight,
            price: price === "" ? 0 : price,
            note
        }
        // console.log(newQty)
        dispatch(itemQtyPreshipment(newQty))
    }
    return (
        <>
            <Button 
                disabled={disable}
                bg='blue.300' 
                h='1.5rem' 
                variant={'ghost'} 
                onClick={()=>setShow(true)}
            ><MdOutlineSettings /></Button>

            <Modal isOpen={show} onClose={onClose} size='xl' closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>{item.item.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    { loading  && <Spinner label='Please wait' speed='0.1s' thickness='5px' /> }
                    {error && 
                        <Alert status='error' variant='left-accent'>
                        <AlertIcon />
                        <AlertTitle mr={2}>Error saving item qty.</AlertTitle>
                        <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    }
                    <Box my='3'>
                        <FormLabel htmlFor='totalQty'>Total Qty</FormLabel>
                        <NumberInput min={0} value={totalQty} onChange={(num)=>setTotalQty(num)} >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Box>

                    <Box my='3'>
                        <FormLabel htmlFor='weight'>Weight (kg)</FormLabel>
                        <NumberInput min={0} value={weight} onChange={(num)=>setWeight(num)} >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Box>
                    <Box my='3'>
                        <FormLabel htmlFor='price'>Price ({currency}) </FormLabel>
                        <NumberInput min={0} value={price} onChange={(num)=>setPrice(num)} >
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Box>

                    <FormControl my='3'>
                    <FormLabel htmlFor='note'>Remark</FormLabel>
                    <Input 
                        id='note' 
                        as='textarea' 
                        h='200px'
                        value={note}
                        onChange={e=>setNote(e.target.value)}
                    />
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button size='sm' w='100px' colorScheme='blue' mr={3} onClick={onClose}>Close</Button>
                    <Spacer />
                    <Button 
                        size='sm' w='100px' 
                        colorScheme='green'
                        onClick={handleSubmit}    
                    >Save</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

ShipmentPreItemEditModal.defaultProps = {
    disable: false,
}

export default ShipmentPreItemEditModal