

import React from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const RateViewUserList = ({agents}) => {
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <div>
            <Button size='sm' colorScheme='pink' onClick={onOpen}>View Agents</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Agent List</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box h={'80vh'} overflow={'scroll'}>
                        <Table size='sm'>
                            <Thead>
                                <Tr>
                                    <Th>No</Th>
                                    <Th>Name</Th>
                                    <Th>View</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {agents.map((ag,i)=>(
                                    <Tr key={i}>
                                        <Td>{i+1}</Td>
                                        <Td>{ag.name}</Td>
                                        <Td>
                                            <Button
                                                size='sm'
                                                variant='link'
                                                onClick={()=>navigate(`/user/${ag._id}`)}
                                            >
                                                Detail
                                            </Button>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default RateViewUserList