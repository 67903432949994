import axios from 'axios'
import { 
    DHLACCOUNT_CREATE_FAIL,
    DHLACCOUNT_CREATE_REQUEST,
    DHLACCOUNT_CREATE_SUCCESS,
    DHLACCOUNT_DELETE_FAIL,
    DHLACCOUNT_DELETE_REQUEST,
    DHLACCOUNT_DELETE_SUCCESS,
    DHLACCOUNT_LIST_FAIL,
    DHLACCOUNT_LIST_REQUEST, 
    DHLACCOUNT_LIST_SUCCESS,
    DHLACCOUNT_UPDATE_FAIL,
    DHLACCOUNT_UPDATE_REQUEST,
    DHLACCOUNT_UPDATE_SUCCESS,


} from '../constants/dhlAccountConstants'



export const getDHLAccountList = () => async (dispatch, getState) => {
    try {
        dispatch({type: DHLACCOUNT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/dhlaccount`, config)
        dispatch({type: DHLACCOUNT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DHLACCOUNT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const addNewDHLAccount = (newAccount) => async (dispatch, getState) => {
    try {
        dispatch({type: DHLACCOUNT_CREATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.post(`/api/dhlaccount`, newAccount, config)
        dispatch({type: DHLACCOUNT_CREATE_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: DHLACCOUNT_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}

export const updateDHLAccount = (accDetail, accId) => async (dispatch, getState) => {
    try {
        dispatch({type: DHLACCOUNT_UPDATE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.put(`/api/dhlaccount/${accId}`, accDetail, config)
        dispatch({type: DHLACCOUNT_UPDATE_SUCCESS})
    } catch (error) {
        dispatch({
            type: DHLACCOUNT_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}


export const deleteDHLAccount = (accId) => async (dispatch, getState) => {
    try {
        dispatch({type: DHLACCOUNT_DELETE_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        await axios.delete(`/api/dhlaccount/${accId}`, config)
        dispatch({type: DHLACCOUNT_DELETE_SUCCESS})
    } catch (error) {
        dispatch({
            type: DHLACCOUNT_DELETE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}
