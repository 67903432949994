import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
    Box,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Stack,
    HStack,
    Spacer,
    Input,
    Button,
    Progress
} from '@chakra-ui/react'
import { myAgentBilling } from '../../actions/shipmentActions'
import Error from '../../components/Error'

const SaleMyAgentBillingScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [month, setMonth] = useState(new Date().toISOString().substring(0,7))
    const { loading, error, billingDetail } = useSelector(state=>state.shipment.MyAgentBilling) 

    useEffect(()=>{
        const start = '2022-06-19'
        const end = new Date().toISOString().substring(0,10)
        dispatch(myAgentBilling(start, end))
    },[dispatch])

    async function addDays(date, days){
        var result = new Date(date)
        result.setDate(result.getDate()+days)
        var dateString = result.toISOString()
        return dateString.substring(0,10)
    }

    const handleSubmit = async () => {
        const start = month+'-01'        
        const y = Number(month.substring(0,4))
        const m = Number(month.substring(5,7))
        const e = new Date(y, m, 1).toISOString().substring(0,10)
        const end = await addDays(e, 1)
        dispatch(myAgentBilling(start, end))
        //console.log(start, end)
    }
    
    return (
        <Box mx='5%' mb='50px'>
            {/* {loading && <Center><Spinner label='Please wait...' speed='0.1s' thickness='5px' /></Center>} */}
            {loading && <Progress size='xs' isIndeterminate />}
            {error && <Error error={error} />}
            {billingDetail && 
                <Box mb='20px' mt='3'>
                    <HStack>                        
                        <Stack>
                            <Box fontSize={'4xl'}>Agent Billing Summary</Box>
                            <HStack>
                                <Input 
                                    type="month"
                                    size='sm'
                                    w='200px'
                                    value={month}
                                    onChange={e=>setMonth(e.target.value)}
                                />
                                <Button
                                    size='sm'
                                    colorScheme={'green'}
                                    onClick={handleSubmit}
                                >Go</Button>
                            </HStack>                            
                        </Stack>
                        <Spacer />
                        <Stack fontWeight={'500'} spacing={'0'} p='2' border='1px' shadow={'dark-lg'} borderRadius='2xl'>
                            <HStack>
                                <Box>Total : </Box><Spacer />
                                <Box>{(billingDetail.reduce((acc, b) => acc + b.total, 0)).toLocaleString()}</Box>
                            </HStack>
                            <HStack>
                                <Box>Paid : </Box><Spacer />
                                <Box>{((billingDetail.reduce((acc, b) => acc + b.total, 0)) - (billingDetail.reduce((acc, b) => acc + b.totalUnPaid, 0))).toLocaleString()}</Box>
                            </HStack>
                            <HStack>
                                <Box color='red'>UnPaid : </Box><Spacer />
                                <Box color='red'>{(billingDetail.reduce((acc, b) => acc + b.totalUnPaid, 0)).toLocaleString()}</Box>
                            </HStack>
                            <HStack>
                                <Box>Invoiced : </Box><Spacer />
                                <Box>{((billingDetail.reduce((acc, b) => acc + b.totalUnPaid, 0)) - (billingDetail.reduce((acc, b) => acc + b.totalUnInvoiced, 0))).toLocaleString()}</Box>
                            </HStack>
                            <HStack>
                                <Box color='red'>UnInvoiced : </Box><Spacer />
                                <Box color='red'>{(billingDetail.reduce((acc, b) => acc + b.totalUnInvoiced, 0)).toLocaleString()}</Box>
                            </HStack>
                        </Stack>
                    </HStack>                
                </Box>
            }
            
            {billingDetail && 
                <Box>
                    <TableContainer>
                        <Table variant='simple'>
                            <Thead>
                            <Tr>
                                <Th>No</Th>
                                <Th>Name</Th>
                                <Th>Code</Th>
                                <Th isNumeric>Total</Th>
                                <Th isNumeric>Paid</Th>
                                <Th isNumeric color='red'>UnPaid</Th>
                                <Th isNumeric>Invoiced</Th>
                                <Th isNumeric color='red'>Uninvoiced</Th>
                                <Th isNumeric>Approved PM</Th>
                                
                            </Tr>
                            </Thead>
                            <Tbody>
                                {billingDetail.map((b,i)=>(
                                    <Tr key={i}>
                                        <Th>{i+1}</Th>
                                        <Th>
                                            <Button
                                                variant='link'
                                                size='xs'
                                                color={'black'}
                                                onClick={()=>navigate(`/myagents/${b.agent._id}`)}
                                            >
                                            {b.agent.name}
                                            </Button>
                                        </Th>
                                        <Th>
                                            <Button
                                                variant='link'
                                                size='xs'
                                                color={'black'}
                                                onClick={()=>navigate(`/myagents/${b.agent._id}`)}
                                            >
                                            {b.agent.agentCode}
                                            </Button>
                                        </Th>                                           
                                        <Th isNumeric>{b.total.toLocaleString()}</Th>
                                        <Th isNumeric>{(b.total - b.totalUnPaid).toLocaleString()}</Th>
                                        <Th isNumeric color='red'>{b.totalUnPaid.toLocaleString()}</Th>
                                        <Th isNumeric>{(b.total - b.totalUnInvoiced).toLocaleString()}</Th>
                                        <Th isNumeric color='red'>{b.totalUnInvoiced.toLocaleString()}</Th>
                                        <Th isNumeric>{b.totalApprovedPayment.toLocaleString()}</Th>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            }
        </Box>
    )
}


//preshipment.boxes.reduce((acc, box) => acc + box.weight, 0)
export default SaleMyAgentBillingScreen