import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import parse from 'paste-from-excel'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    FormControl, 
    Box,
    Flex,
    Button, 
    Icon,
    Spacer, 
    useToast,
    Center,
    Select,
    Spinner,
  } from '@chakra-ui/react'
import {MdSaveAlt} from 'react-icons/md'
import { createRate } from '../../../actions/thailand_post_actions/thpRateActions'
import { useNavigate } from 'react-router-dom'
import { THPRATE_CREATE_RESET } from '../../../constants/thailand_post_constants/thpRateConstants'
import Error from '../../../components/Error'
import { getCountryList } from '../../../actions/countryActions'

const PostRateCreateScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()
    const [country, setCountry] = useState('')
    const [ratelist, setRatelist] = useState({
        rates: [
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            {'ems': 0,'air': 0, 'surface': 0},
            ]
    })

    const {loading, error, success} = useSelector(state=>state.thailandPost.RateCreate)
    const { loading: loadingCountry, countries, success:successCountry, error:errorCountry } = useSelector(state=>state.country.List)
 
    const zoneslist = ['ems','air','surface']
    const kglist = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21', '22', '23', '24', '25', '26', '27', '28', '29' , '30']

    
    const handlePaste = (e) => {
        return parse(e)
    }
    const handlePasteRate = (index, z, e, i) => {
        setRatelist((ratelist)=>({
            ...ratelist,
            rates: ratelist.rates.map((item, i)=> index === i ? {...item, [z]: e.target.value} : item)
        }))
    }

    useEffect(()=>{
        dispatch(getCountryList())
    },[dispatch])

    useEffect(()=>{
        if(success){
            toast({
                status: 'success',
                position: 'top',
                title: 'New rate saved successfully.',
                duration: 8000,
                isClosable: true,
            }) 
            navigate('/thailandpostrate')
        }

        return()=>{
            dispatch({type: THPRATE_CREATE_RESET})
        }
    },[success, dispatch, navigate, toast])
    
    const handleSubmit = async () => {
        if(country === '' || country === 'Select Country'){
            alert('Country is required')
            return
        }
        const newRate = {
            country,
            rate: ratelist.rates,
        }
        dispatch(createRate(newRate))
        //console.log(newRate)
    }


    return (
        <Center>
        <Box w='500px' mb={'50px'}>
            {loadingCountry && <Spinner />}
            {errorCountry && <Error error={errorCountry} />}
            <Box my='5'>
                <Flex>
                    {successCountry &&
                        <Box w='300px' mr='3'>
                            <FormControl isRequired >
                                <Select placeholder='Select' size='sm' onChange={e=>setCountry(e.target.value)}>
                                    <option>Select Country</option>
                                    {countries.map(c=>(
                                        <option key={c.name} value={c.name}>{c.name}</option>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    }

                    <Spacer />
                    <Box w='200px'>
                        <Button 
                            w='100%'
                            size='sm'
                            isLoading={loading}
                            colorScheme='green'
                            loadingText='Submitting'
                            rightIcon={<Icon as={MdSaveAlt} />} 
                            onClick={handleSubmit}
                            >Save
                        </Button>
                    </Box>              
                </Flex>
            </Box>
            {error && <Error error={error} /> }
            
                <Table size='sm' variant='striped' colorScheme='yellow' >
                    <Thead>
                        <Tr>
                        <Th textAlign='center'>KG</Th>
                        <Th textAlign='center'>ems</Th>
                        <Th textAlign='center'>air</Th>
                        <Th textAlign='center'>surface</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {ratelist.rates.map((res, idx)=>(
                            <Tr key={idx}>
                                <Td textAlign='center' w='100px'>{kglist[idx]}</Td>
                                {zoneslist.map((z, i)=>(
                                    <Td key={i} p='0.9'>
                                        <FormControl border='grey'>
                                            <Input 
                                                size='xs' 
                                                textAlign='center' 
                                                fontSize='15px' 
                                                onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault()}}}
                                                onInput={(e)=> {handlePasteRate(idx, z, e, i)}}
                                                onPaste={e=>{handlePaste(e)}}
                                                />
                                        </FormControl>                                    
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                      
            </Box>
        </Center>  
    )
}

export default PostRateCreateScreen