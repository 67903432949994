import axios from 'axios'
import {
    SHIPCOUNT_LIST_REQUEST,
    SHIPCOUNT_LIST_SUCCESS,
    SHIPCOUNT_LIST_FAIL,
} from '../constants/shipCountConstants'

export const listShipCount = (pageNumber = '', itemCount = '', keyword = '') => async (dispatch, getState) => {
    try {
        dispatch({type: SHIPCOUNT_LIST_REQUEST})
        const {login: {user}} = getState()
        const config = {headers: {Authorization: `Bearer ${user.token}`}}
        const {data} = await axios.get(`/api/shipcounts?pageNumber=${pageNumber}&itemCount=${itemCount}&keyword=${keyword}`, config)
        dispatch({type: SHIPCOUNT_LIST_SUCCESS, payload: data})
    } catch (error) {
        dispatch({
            type: SHIPCOUNT_LIST_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message        
        })
    }
}