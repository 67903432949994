import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Stack,
    Box,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    FormControl,
    FormLabel,
    Input,
  } from '@chakra-ui/react'
import { MdAddBox } from 'react-icons/md'
import { addNoteShipment } from '../actions/shipmentActions'

const ShipmentAddNoteModal = ({shipmentId}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [noteText, setNoteText] = useState('')
    const {loading, error, success} = useSelector(state=>state.shipment.AddNote)
    useEffect(()=>{        
        if(success){
            setNoteText('')
            setShow(false)
        }
      },[success])

    const handleClose = () => {
        setNoteText('')
        setShow(false)
    }

    const handleSubmit = () => {
        if(noteText === ''){
            return
        }
        const noteData = {
            shipmentId: shipmentId,
            noteText: noteText

        }

        dispatch(addNoteShipment(noteData))
    }
    return (
        <>
            <Button size='sm' colorScheme='purple' h='25px' onClick={()=>setShow(true)} leftIcon={<MdAddBox />}>Add Note</Button>

            <Modal isOpen={show} onClose={handleClose} size='xl' closeOnOverlayClick={false} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{loading  ? <Spinner label='Please wait' speed='0.1s' thickness='5px' /> : 'Add New Note' }</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack spacing={4}>
                        {error && 
                            <Alert status='error' variant='left-accent'>
                            <AlertIcon />
                            <AlertTitle mr={2}>Error saving new note.</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        }
                        <FormControl>
                            <FormLabel>Your Note</FormLabel>
                            <Input 
                                as='textarea' 
                                h='100px'
                                size='sm'
                                value={noteText}
                                onChange={e=>setNoteText(e.target.value)}                                  
                                />
                        </FormControl>
                        <Box>
                            <Button
                                size='sm'
                                w='100%'
                                colorScheme={'green'}
                                onClick={handleSubmit}
                            >Submit</Button>
                        </Box>
                       
                    </Stack>
                </ModalBody>
            </ModalContent>
            </Modal>
        </>
    )
}

export default ShipmentAddNoteModal